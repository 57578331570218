import { DownloadCloudIcon, UploadCloudIcon, User03, UserIcon02 } from 'assets/icons';
import classNames from 'classnames';
import TopBar from 'components/TopBar';
import CustomButton from 'components/UI/CustomButton';
import CustomPopover from 'components/UI/Popover';
import Beneficiaries from 'pages/Beneficiaries';
import BulkMemberCreation from 'pages/Beneficiaries/BulkMemberCreation';
import MemberCreationUpload from 'pages/Beneficiaries/MemberCreationUpload';
import RolesAndPermission from 'pages/Roles';
import Teams from 'pages/Teams';
import { useEffect, useState } from 'react';
import { Col, Container, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getBeneficiaries } from 'redux/actions/BeneficiariesAction';
import { getBudgets } from 'redux/actions/BudgetsAction';
import { getTeams } from 'redux/actions/TeamsAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { ReactComponent as DownArrow } from '../../assets/icons/down-arrow.svg';
import './styles.scss';
import UploadMemberDialog from './UploadMemberDialog';

const People = () => {
  const location = useLocation();
  const locationArray = location.pathname.split('/');

  const history = useHistory();
  const dispatch = useDispatch();
  const [key, setKey] = useState(
    locationArray.includes('members') &&
      (locationArray.includes('create') || locationArray.includes('upload'))
      ? 'people'
      : locationArray[locationArray.length - 1],
  );

  const memberTemplate =
    'https://bujeti-public.s3.eu-west-1.amazonaws.com/Bujeti_Member_Template.xlsx';

  const { permissions, isAdmin, isPremium, onboardingStatus } = allPermissions();
  const canCreate = hasPermission({
    permissions,
    scopes: ['employee-*', 'team-*', 'employee-create', 'team-create'],
  });

  const viewEmployee = hasPermission({
    permissions,
    scopes: ['employee-*', 'employee-view', 'employee-create'],
  });

  const viewTeam = hasPermission({
    permissions,
    scopes: ['team-*', 'team-create', 'team-view'],
  });

  const viewBudget = hasPermission({
    permissions,
    scopes: ['budget-*', 'budget-view'],
  });

  const viewRole = hasPermission({
    permissions,
    scopes: ['role-*', 'role-create', 'role-view'],
  });
  const pendingOnbording = ['pending', 'rejected'].includes(onboardingStatus);

  const [isOpen, setIsOpen] = useState(0);
  const [isBeneficiaryOpen, setIsBeneficiaryOpen] = useState(false);
  const [isCustomRolesOpen, setIsCustomRolesOpen] = useState(false);
  const [isButtonToggle, setIsButtonToggle] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);

  const {
    getBeneficiaries: { data: beneficiaryData = {}, loading },
  } = useSelector(({ beneficiaries }) => beneficiaries);
  const { beneficiaries = [], meta = {} } = beneficiaryData;

  const {
    getTeams: { data: teamData = {} },
  } = useSelector(({ teams }) => teams);

  const {
    getBudget: { data: budgetData = {} },
  } = useSelector(({ budgets }) => budgets);

  const {
    getRoles: { data: rolesData = [] },
  } = useSelector(({ roles }) => roles);

  const { teams = [], meta: teamsMeta = {} } = teamData;

  useEffect(() => {
    if (!beneficiaries?.length && viewEmployee)
      dispatch(getBeneficiaries({ excludeInactiveBudgets: true }));
    if (!teams?.length && viewTeam) dispatch(getTeams());
    if (!budgetData?.budgets?.length && viewBudget)
      dispatch(getBudgets({ status: 'active' }));
  }, []);

  const handleSelect = (tabKey) => {
    setKey(tabKey);
    if (!tabKey.includes('teams')) {
      history.push(`/teams/${tabKey}`);
      dispatch(getBeneficiaries());
    } else {
      history.push(`/teams`);
    }
  };

  const toggleHandler = (val) => {
    setIsOpen(val);
  };

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  const toggleActionButton = (key) => {
    if (key === 'team') {
      toggleHandler(2);
      handleSelect('teams');
    } else if (key === 'roles') {
      setIsCustomRolesOpen(!isCustomRolesOpen);
      handleSelect('roles');
    } else {
      setIsBeneficiaryOpen(!isBeneficiaryOpen);
      handleSelect('people');
    }
  };

  const activeKey =
    key === 'teams' && viewEmployee
      ? 'beneficiary'
      : key === 'roles' && viewRole
      ? 'roles'
      : 'team';

  const title =
    key === 'teams' && viewEmployee
      ? 'Add member'
      : viewTeam || viewRole
      ? 'Create team'
      : '';

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() => {
            toggleActionButton('people');
          }}
        >
          <User03 width="16" height="16" /> Add single
        </div>
        <div
          className="actionLink"
          onClick={() => {
            history.push('/teams/members/create');
          }}
        >
          <UserIcon02 /> Add multiple
        </div>
        <div
          className="actionLink"
          onClick={() => {
            setShowUploadModal(true);
          }}
        >
          <UploadCloudIcon /> Upload multiple
        </div>
        <a
          href={memberTemplate}
          className="text-decoration-none text-reset"
          download
          rel="noreferrer"
        >
          <div className="actionLink">
            <DownloadCloudIcon /> Download template
          </div>
        </a>
      </div>
    );
  };

  return (
    <div>
      <TopBar
        headerText="People"
        subText="Manage your people: invite members, assign roles and permissions, create teams, departments, and locations."
        isRightBar
        addButton
        dropDownBtn={
          ((canCreate && key !== 'roles') ||
            (canCreate && key === 'roles' && isPremium)) && (
            <>
              {['roles', 'teams'].includes(key) ? (
                <CustomButton
                  className="add-button"
                  onClick={() =>
                    toggleActionButton(
                      key === 'teams' && viewTeam
                        ? 'team'
                        : key === 'roles' && viewRole
                        ? 'roles'
                        : 'people',
                    )
                  }
                >
                  {key === 'teams' && viewTeam
                    ? 'Create team'
                    : key === 'roles' && viewRole
                    ? 'Create custom role'
                    : 'Add member'}
                </CustomButton>
              ) : (
                <CustomPopover
                  zIndex="1"
                  showPopover={isButtonToggle}
                  clickOutside={handleButtonToggle}
                  content={<Actions />}
                >
                  <CustomButton className="add-button" onClick={handleButtonToggle}>
                    {key === 'teams' && viewTeam
                      ? 'Create team'
                      : key === 'roles' && viewRole
                      ? 'Create custom role'
                      : 'Add member'}{' '}
                    <DownArrow />
                  </CustomButton>
                </CustomPopover>
              )}
            </>
          )
        }
        addActionButton={canCreate && activeKey !== 'roles' ? title : false}
        toggleActionButton={() => toggleActionButton(activeKey)}
        breadcrumbs={
          locationArray.includes('members') &&
          (locationArray.includes('create') || locationArray.includes('upload')) && [
            { title: 'People', action: () => history.push('/people') },
            {
              title: 'Add multiple beneficiaries',
              action: () => {
                null;
              },
            },
          ]
        }
      />

      <Container>
        <Row>
          <Col xs={12} className="mt-4">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={handleSelect}
              className="mb-2"
            >
              {viewEmployee && (
                <Tab
                  eventKey="people"
                  title={
                    <div className="d-flex">
                      Members
                      <div className="count">
                        <span className="m-auto">{meta?.total || 0}</span>
                      </div>
                    </div>
                  }
                  tabClassName="new-tab"
                >
                  {locationArray.includes('members') &&
                  locationArray.includes('create') ? (
                    <BulkMemberCreation />
                  ) : locationArray.includes('members') &&
                    locationArray.includes('upload') ? (
                    <MemberCreationUpload />
                  ) : (
                    <Beneficiaries
                      isOpen={isBeneficiaryOpen}
                      setIsOpen={setIsBeneficiaryOpen}
                    />
                  )}
                </Tab>
              )}

              {viewTeam && (
                <Tab
                  eventKey="teams"
                  disabled={pendingOnbording}
                  title={
                    <div className="d-flex">
                      Teams
                      <div className="count">
                        <span className="m-auto">{teamsMeta?.total || 0}</span>
                      </div>
                    </div>
                  }
                  tabClassName={classNames('new-tab', {
                    ['disabled-tab opacity-50']: pendingOnbording,
                  })}
                >
                  <Teams
                    toggleHandler={toggleHandler}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                  />
                </Tab>
              )}
              {viewRole && (
                <Tab
                  eventKey="roles"
                  title={
                    <div className="d-flex">
                      Roles
                      <div className="count">
                        <span className="m-auto">{rolesData.length || 0}</span>
                      </div>
                    </div>
                  }
                  tabClassName="new-tab"
                >
                  <RolesAndPermission
                    setIsOpen={setIsCustomRolesOpen}
                    isOpen={isCustomRolesOpen}
                    handleSelect={setKey}
                  />
                </Tab>
              )}
            </Tabs>
          </Col>
        </Row>
      </Container>
      <Modal show={showUploadModal} centered dialogClassName="custom-dialog">
        <UploadMemberDialog setShowUploadModal={setShowUploadModal} />
      </Modal>
    </div>
  );
};

export default People;
