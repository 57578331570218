import BusinessCard from 'components/BusinessCard';
import { useState } from 'react';
import TodoItems from './TodoItems';
import './style.less';

const Onboarding = ({ menus, setMenus }) => {
  const [selectedItem, setSectedItem] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const toggleHandler = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (title) => {
    setSectedItem(title);
    toggleHandler();
  };

  const handleStep = (val) => {
    const updateMenu = menus?.map((m, i) => {
      return {
        ...m,
        isActive: i === val - 1,
        isComplete: i <= val - 1,
      };
    });
    setMenus(updateMenu);
  };

  return (
    <div className="container onboarding">
      <ul className="menus">
        <TodoItems menus={menus} handleSelect={handleSelect} />
      </ul>
      <BusinessCard
        isOpen={isOpen}
        selectedItem={selectedItem}
        toggleHandler={toggleHandler}
        setSelectStep={handleStep}
      />
    </div>
  );
};

export default Onboarding;
