import { CloseOutlined } from '@ant-design/icons';
import CustomButton from 'components/UI/CustomButton';
import CustomCreatableSelect from 'components/UI/CustomCreatableSelect';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import CustomTextarea from 'components/UI/CustomTextarea';
import FileUpload from 'components/UI/FileUpload';
import Modal from 'components/UI/Modal';
import { toastError } from 'components/UI/toast';
import { setHours, setMinutes } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getCard } from 'redux/actions/CardAction';
import { getCountries, getState } from 'redux/actions/CompaniesAction';

import classNames from 'classnames';
import AccountName from 'components/UI/AccountName';
import CategoryDropdown from 'components/UI/CustomSelect/CategoryDropdown';
import { useDebounce } from 'hooks/useDebounce';
import useTextCounter from 'hooks/useTextCounter';
import { getAllBanks, verifyBankAccount } from 'redux/actions/PaymentAction';
import { createRequests, getRequests } from 'redux/actions/RequestsAction';
import { RESET_BLOCK_REQUESTS } from 'redux/reducers/RequestsReducer';
import { RESET_BLOCK_VENDOR } from 'redux/reducers/VendorsReducer';
import { MAXLENGTH } from 'utils';
import { getAvailableBalance, groupSourceOptions } from 'utils/helper';
import {
  getBalances,
  getTransferableBalance,
} from '../../../redux/actions/BudgetsAction';
import {
  createVendors,
  getVendors,
  updateVendors,
} from '../../../redux/actions/VendorsAction';

const MAX_LENGTH = MAXLENGTH.requestDescription;

const RequestFunds = ({
  isOpen,
  toggleHandler,
  newBudget = true,
  payVendor = true,
  topUpBudget = true,
  topUpAccount = true,
  topUpCard = true,
  source = undefined,
  bankAccount = undefined,
  bankAccountName = '',
  selectedRequestChannel = null,
  setIsOtherPopoverOpen = () => null,
}) => {
  if (!isOpen) return <div />;

  const dispatch = useDispatch();

  const {
    getVendor: { loading, data: vendorData = {} },
    createVendor: { success: successVendors, loading: loadingCreateVendor },
    updateVendor: { success: successUpdateVendor, loading: loadingUpdateVendor },
  } = useSelector(({ vendors }) => vendors);

  const {
    getCard: { data: cardData = {}, data = {}, loading: loadingCards },
  } = useSelector(({ card }) => card);

  const {
    getBeneficiaryBank: { data: beneficiaryBankData = {} },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const { vendors = [] } = vendorData;
  const { cards = [] } = cardData;

  const {
    createRequest: { loading: loadingCreateRequest, success: successCreateRequest },
  } = useSelector(({ requests }) => requests);

  useEffect(() => {
    if (successCreateRequest) {
      handleClose();
      if (beneficiaryBankData.length > 0) {
        dispatch(getRequests());
      }
    }
  }, [successCreateRequest]);

  const [uploadingFile, setUploadingFile] = useState(true);
  const [addNewVendor, setAddNewVendor] = useState(false);
  const [hasAccount, setHasAccount] = useState(false);
  const [isAccountSet, setIsAccountSet] = useState(false);
  const [vendorDetails, setVendorDetails] = useState({
    name: '',
    city: '',
    country: '',
    state: '',
    street: '',
    accountNumber: '',
    accountName: '',
    bankName: '',
    currency: 'NGN',
  });

  const [getVendorCode, setGetVendorCode] = useState(false);
  const [requestDetails, setrequestDetails] = useState({
    amount: '',
    currency: '',
    description: '',
    budget: '',
    budgetName: '',
    date: setHours(setMinutes(new Date(), 30), 16),
    receipt: undefined,
    vendor: '',
    bankAccount: { value: bankAccount, label: bankAccountName },
    source,
  });

  const handleDescriptionChange = (value) => {
    setrequestDetails({
      ...requestDetails,
      description: value,
    });
  };

  const { text, charCount, handleCharChange } = useTextCounter(
    requestDetails?.description,
    MAX_LENGTH,
    handleDescriptionChange,
  );

  const [countries, setCountries] = useState([]);
  const [forBudget, setForBudget] = useState(false);
  const [forNewBudget, setForNewBudget] = useState(false);
  const [forBank, setForBank] = useState(false);
  const [forPayment, setForPayment] = useState(false);
  const [forCard, setForCard] = useState(false);

  const [value, setValue] = useState({ vendor: '', card: '' });
  const debouncedVendorValue = useDebounce(value.vendor, 200);
  const debouncedCardValue = useDebounce(value.card, 200);
  const handleSelectChange = (val, type) => setValue({ ...value, [type]: val });

  const {
    getCountry: { data: countryData = {} },
    getState: { data: states = {} },
    getCompany: { data: companyData = {} },
  } = useSelector(({ companies }) => companies);

  const {
    verifyBankAccount: {
      data: accName,
      loading: accountNameLoading,
      success: accountNameSuccess,
      error: accountNameError,
    },
    getAllBanks: {
      data: banksData,
      loading: loadingBanks,
      success: successBanks,
      error: errorBanks,
    },
  } = useSelector(({ payments }) => payments);

  const {
    searchRecipients: { data: searchRecipient = [] },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  useEffect(() => {
    dispatch(getCountries());
    if (!banksData) dispatch(getAllBanks());
  }, []);

  const [stateData, setStateData] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      setVendorDetails({
        ...vendorDetails,
        country: countries.find(
          (country) => country.label === companyData?.address?.country,
        ),
      });
    }, 1000);
    const countryDetails = countries.find(
      (country) => country.label === companyData?.address?.country,
    );
    if (countryDetails) {
      dispatch(getState(countryDetails.value));
    }
  }, []);

  useEffect(() => {
    if (states) {
      const stateOptions = states.states?.map((value) => ({
        label: value,
        value: value,
      }));

      setStateData(stateOptions);
    }
  }, [states]);

  const getCountrySates = async (val) => {
    dispatch(getState(val.value));
  };

  const {
    getBalances: { data: balances, loading: loadingBalances },
  } = useSelector(({ budgets }) => budgets);

  useEffect(() => {
    dispatch(getVendors());
    dispatch(getBalances());
    dispatch(getCard({ status: 'active' }));
  }, []);

  // if (debouncedValue) dispatch(getBeneficiaries())

  const updateAccount = requestDetails.vendor && !hasAccount;

  // Get All banks starts here

  const [bankValue, setBankValue] = useState('');
  const bankValuedebounced = useDebounce(bankValue, 200);

  const handleGetBankOnChange = (val) => setBankValue(val);

  const mappedBanks = banksData?.map((item) => item.name);

  const allBanks = useMemo(() => {
    return banksData?.map((item) => ({
      label: item.label,
      value: item.bankCode,
    }));
  }, [successBanks, errorBanks, mappedBanks]);

  useEffect(() => {
    dispatch(getAllBanks());
  }, []);

  useEffect(() => {
    const banks = allBanks?.find((option) =>
      option?.label?.toLowerCase().includes(bankValuedebounced?.toLowerCase()),
    );
    if (!banks && bankValuedebounced) {
      dispatch(getAllBanks({ search: bankValuedebounced?.toLowerCase() }));
    }
  }, [bankValuedebounced]);

  const onMenuCloseBanks = () => {
    if (bankValuedebounced) dispatch(getAllBanks());
  };

  // Get All banks ends here

  const onRequestChanelChange = ({ code, key, name, label, value }) => {
    const isCreateBudget = code === 'create-budget-option';
    setrequestDetails({
      ...requestDetails,
      budgetName: undefined,
      channel: { code, key, name, label, value },
      type: key?.toLowerCase().replace('for', ''),
      receipt: isCreateBudget ? undefined : requestDetails.receipt,
    });
    setForBudget(false);
    setForBank(false);
    setForNewBudget(false);
    setForPayment(false);
    setForCard(false);
    if (key === 'top_up') {
      switch (code) {
        case 'bank-option':
          setForBank(true);
          break;
        case 'budget-option':
          setForBudget(true);
          break;
        case 'card-option':
          setForCard(true);
          break;
      }
    } else if (key === 'budget') {
      switch (code) {
        case 'create-budget-option':
          setForNewBudget(true);
          break;
      }
    } else if (key === 'payment') {
      switch (code) {
        case 'vendor-option':
          setForPayment(true);
          break;
      }
    }
  };

  useEffect(() => {
    if (selectedRequestChannel) {
      setrequestDetails({
        ...requestDetails,
        type: 'top_up',
        channel: requestChanel.find((item) => item.code === selectedRequestChannel),
      });
      if (selectedRequestChannel === 'bank-option') setForBank(true);
    }
  }, []);

  //ends here

  const selectedCurrency = ['budget-option', 'card-option', 'bank-option'].includes(
    requestDetails?.channel?.code,
  );

  useEffect(() => {
    if (requestDetails?.budget?.value)
      dispatch(getTransferableBalance(requestDetails?.budget?.value));
  }, [requestDetails?.budget]);

  const handleClose = () => {
    toggleHandler();
    dispatch({ type: RESET_BLOCK_REQUESTS, blockType: 'requestFunds' });
    dispatch({ type: RESET_BLOCK_VENDOR, blockType: 'updateVendor' });
    dispatch({ type: RESET_BLOCK_VENDOR, blockType: 'createVendor' });
  };

  const defaultCurrency = () => {
    if (requestDetails?.channel?.code === 'budget-option')
      return requestDetails?.budget?.currency;
    if (requestDetails?.channel?.code === 'bank-option')
      return requestDetails?.bankAccount?.currency;
    if (requestDetails?.channel?.code === 'card-option')
      return requestDetails?.card?.currency;
  };

  const requestChanel = [
    {
      key: 'budget',
      label: 'Create a budget',
      name: 'Create a budget',
      code: 'create-budget-option',
      value: 'create-budget-option',
      on: !!newBudget,
    },
    {
      key: 'top_up',
      label: 'Top up a budget',
      name: 'Top up a budget',
      code: 'budget-option',
      value: 'budget-option',
      on: !!topUpBudget,
    },
    {
      key: 'top_up',
      label: 'Top up account',
      name: 'Top up an account',
      code: 'bank-option',
      value: 'bank-option',
      on: !!topUpAccount,
    },
    {
      key: 'payment',
      label: 'Pay a vendor',
      name: 'Pay a vendor',
      code: 'vendor-option',
      value: 'vendor-option',
      on: !!payVendor,
    },
    {
      key: 'top_up',
      label: 'Top up a card',
      name: 'Top up a card',
      code: 'card-option',
      value: 'card-option',
      on: !!topUpCard,
    },
  ];

  const handleGetSign = (currency) => {
    return setrequestDetails({ ...requestDetails, currency: currency });
  };

  useEffect(() => {
    if (countryData.length > 0) {
      const countryOptions = countryData?.map(({ code: value, name: label }) => ({
        value,
        label,
        isDisabled: label !== 'NIGERIA' ? true : false,
      }));

      setCountries(countryOptions);
    }
  }, [countryData]);

  useEffect(() => {
    if (vendors.length > 0) {
      const vendorsOptions = vendors?.map(({ code: value, name: label }) => ({
        value,
        label,
      }));

      const selected = vendorsOptions.find(
        (vendor) => vendor.label === vendorDetails.name,
      );
      if (selected !== undefined) {
        setrequestDetails({ ...requestDetails, vendor: selected.value });
      }
    }
  }, [vendors]);

  const vendorsList = useMemo(
    () =>
      vendors?.map(({ code: value, name: label, ...rest }) => ({
        value,
        label,
        rest,
      })),
    [vendors],
  );

  const cardsList = useMemo(
    () =>
      cards?.map(({ code: value, name: label, currency }) => ({
        value,
        label,
        currency,
      })),
    [cardData],
  );

  useEffect(() => {
    const vendors = vendorsList?.find((option) =>
      option.label.toLowerCase().includes(debouncedVendorValue.toLowerCase()),
    );
    if (!vendors && debouncedVendorValue) {
      dispatch(getVendors({ search: debouncedVendorValue?.toLowerCase() }));
    }
  }, [debouncedVendorValue]);

  useEffect(() => {
    const cards = cardsList?.find((option) =>
      option.label.toLowerCase().includes(debouncedCardValue.toLowerCase()),
    );
    if (!cards && debouncedCardValue) {
      dispatch(getCard({ search: debouncedCardValue?.toLowerCase() }));
    }
  }, [debouncedCardValue]);

  useEffect(() => {
    if (successVendors || successUpdateVendor) {
      setAddNewVendor(false);
      setGetVendorCode(true);
      setHasAccount(true);
    }
  }, [successVendors, successUpdateVendor]);

  const handleChange = (newValue, actionMeta) => {
    if (debouncedVendorValue) dispatch(getVendors());

    if (actionMeta.action === 'create-option') {
      setAddNewVendor(true);
      setVendorDetails({
        ...vendorDetails,
        name: newValue.value,
        accountName: undefined,
        accountNumber: '',
        bank: '',
      });
      setrequestDetails((prevPaymentData) => ({
        ...prevPaymentData,
        vendor: '',
      }));

      setHasAccount(false);
    } else {
      if (newValue) {
        const filterBeneficiary = vendors?.find(({ code }) => code === newValue.value);
        if (filterBeneficiary && filterBeneficiary?.bankAccounts?.[0]?.bankCode) {
          const { bankCode, number, accountName } = filterBeneficiary?.bankAccounts[0];
          const [bank] = allBanks?.filter(({ value }) => value === bankCode);
          const { label = '' } = bank || {};
          setrequestDetails((prevPaymentData) => ({
            ...prevPaymentData,
            vendor: newValue.value,
            accountNumber: number,
            accountName,
            bank: { value: bankCode, label },
          }));
          setHasAccount(true);
        } else {
          setrequestDetails({
            ...requestDetails,
            vendor: newValue.value,
            accountName: undefined,
            accountNumber: '',
            bank: '',
          });
          setHasAccount(false);
        }
      } else {
        setrequestDetails({ ...requestDetails, vendor: '' });
      }
      setAddNewVendor(false);
    }
  };

  const handleChangeVendorDetails = ({ target: { name, value, validity, rawValue } }) => {
    if (['accountName', 'accountNumber'].includes(name))
      return validity.valid && setVendorDetails({ ...vendorDetails, [name]: value });
    return setVendorDetails({ ...vendorDetails, [name]: rawValue ? rawValue : value });
  };

  useEffect(() => {
    if (requestDetails) {
      const { date, amount, vendor, description, currency } = requestDetails;
      setUploadingFile(date && amount && vendor && description && currency);
    }
  }, [requestDetails]);

  const createNewVendor = () => {
    if (!vendorDetails.name && !updateAccount) return toastError('Please enter name');
    const { country, city, state, street } = vendorDetails;
    const hasAddress = country?.value && city && state?.value && street;

    if (vendorDetails.accountNumber) {
      if (vendorDetails.accountNumber.length < 10)
        return toastError('Account number must be 10 digits');
    }
    const hasAccount =
      vendorDetails.bankName.value &&
      vendorDetails.accountName &&
      vendorDetails.accountNumber;

    const payload = {
      name: vendorDetails.name ? vendorDetails.name : undefined,
      ...(hasAccount && {
        bankAccount: {
          bankName: vendorDetails.bankName.label,
          bankCode: vendorDetails.bankName.value,
          accountName: vendorDetails.accountName,
          number: vendorDetails.accountNumber,
          currency: vendorDetails.currency,
        },
      }),
      ...(hasAddress && {
        address: {
          country: vendorDetails.country.value,
          city: vendorDetails.city,
          stateOrProvince: vendorDetails.state.value,
          street: vendorDetails.street,
        },
      }),
    };

    if (updateAccount)
      dispatch(updateVendors({ ...payload, code: requestDetails.vendor }));
    else dispatch(createVendors(payload));
  };

  const handleChangerequestDetails = ({ target: { name, value, rawValue } }) => {
    setrequestDetails({
      ...requestDetails,
      [name]: rawValue ? rawValue : value,
    });
  };

  const createRequest = () => {
    if (getVendorCode) {
      const vendor = vendorData.vendors.find(
        (vendor) => vendor.name === vendorDetails.name,
      );
      setrequestDetails({ ...requestDetails, vendor: vendor?.code });
      setGetVendorCode(false);
    }

    if (!requestDetails.type) return toastError('Please select a type');
    if (
      requestDetails?.type === 'top_up' &&
      !requestDetails?.budget?.value &&
      !requestDetails?.card &&
      !requestDetails?.bankAccount?.value
    )
      return toastError('Please select what to topup');
    if (requestDetails.type === 'budget' && !requestDetails.budgetName)
      return toastError('Please enter budget name');
    if (requestDetails.type === 'payment' && !requestDetails.vendor)
      return toastError('Please select a vendor');
    if (!requestDetails.amount) return toastError('Please enter amount');
    if (+requestDetails.amount <= 0) return toastError('Please enter a valid amount');
    if (!requestDetails.currency) return toastError('Please select currency');
    if (!requestDetails.description)
      return toastError('Please explain what you are asking a request for');
    if (updateAccount) return toastError('Please complete vendor account details');

    const payload = {
      amount: requestDetails.amount * 100,
      currency: requestDetails.currency,
      description: requestDetails.description ? requestDetails.description : undefined,
      receipt: requestDetails.receipt,
      budget: requestDetails?.budget?.value,
      type: requestDetails.type,
      category: requestDetails?.category?.value,
      source,
    };

    if (requestDetails.vendor) {
      payload.vendor = requestDetails.vendor;
      payload.budget = undefined;
      payload.card = undefined;
    }
    if (requestDetails.card) {
      payload.card = requestDetails.card?.value;
      payload.budget = undefined;
      payload.vendor = undefined;
    }

    if (requestDetails.budgetName) {
      payload.meta = {
        ...requestDetails.meta,
        budget: requestDetails.budgetName,
      };
    }

    if (requestDetails.bankAccount && !payload.vendor) {
      payload.bankAccount = requestDetails.bankAccount.value;
      if (topUpAccount) payload.source = undefined;
    }

    dispatch(createRequests(payload));
  };

  useEffect(() => {
    if (vendorDetails.accountNumber.length === 10 && vendorDetails.bankName.value) {
      const { accountNumber, bankName } = vendorDetails;
      dispatch(verifyBankAccount({ accountNumber, bankCode: bankName.value }));
    }
    if (vendorDetails.accountNumber.length < 10) {
      setIsAccountSet(false);
      setVendorDetails({ ...vendorDetails, accountName: undefined });
    }
  }, [vendorDetails.accountNumber, vendorDetails.bankName.value]);

  useEffect(() => {
    if (accountNameSuccess) {
      setVendorDetails({ ...vendorDetails, accountName: accName.account_name });
      setIsAccountSet(true);
    }
    if (accountNameError) setVendorDetails({ ...vendorDetails, accountName: undefined });
    if (accountNameLoading)
      setVendorDetails({ ...vendorDetails, accountName: undefined });
  }, [accountNameSuccess, accountNameError, accountNameLoading]);

  const visible = accountNameLoading || accountNameError;

  //
  const [loadPage, setLoadPage] = useState(0);

  useEffect(() => {
    if (loadPage > 1) dispatch(getBalances({ page: loadPage }));
  }, [loadPage]);

  const [hasMore, setHasMore] = useState(false);
  const [budgetList, setBudgetList] = useState([]);
  const [accountList, setAccountList] = useState([]);

  useEffect(() => {
    if (balances?.budgets?.length || balances?.balances?.length) {
      const budget = getAvailableBalance(balances?.budgets, 'budgets');
      const available_balance = getAvailableBalance(balances?.balances, 'balances');
      setBudgetList((prevOptions) => [...budget]);
      setAccountList((prevOptions) => [...available_balance]);
    }
  }, [balances?.budgets]);

  async function loadOptions(search, loadedOptions, { page }) {
    setLoadPage(page);

    let filteredOption = budgetList.filter((item) =>
      item?.name?.toLowerCase().includes(search?.toLowerCase()),
    );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  async function loadAccounts(search, loadedOptions, { page }) {
    setLoadPage(page);

    let filteredOption = accountList.filter((item) =>
      item?.name?.toLowerCase().includes(search?.toLowerCase()),
    );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  const cancelAddVendor = () => {
    setAddNewVendor(false);
    setVendorDetails({
      name: '',
      city: '',
      country: '',
      state: '',
      street: '',
      accountNumber: '',
      accountName: '',
      bankName: '',
      currency: 'NGN',
    });
    setrequestDetails((prev) => ({ ...prev, vendor: '' }));
  };
  return (
    <div>
      <Modal show={isOpen} onClose={toggleHandler}>
        <div className="content">
          <div className="card-modal-header">
            <>
              <div className="d-flex align-items-center cursor" onClick={handleClose}>
                <CloseOutlined />
                <span className="ps-1">Cancel</span>
              </div>
            </>
          </div>

          <div className="card-modal-body">
            <div className="beneficiaries">
              <h2 className="card-title w-100">Request funds</h2>

              <>
                <Row className="mb-2">
                  <CustomSelect
                    label="Requesting funds to"
                    name="chanel"
                    placeholder="Select fund channel"
                    onChange={onRequestChanelChange}
                    value={requestDetails.channel}
                    options={requestChanel.filter(({ on }) => on)}
                    isDisabled={selectedRequestChannel}
                  />
                </Row>
                {forBank && (
                  <Row className="mb-2">
                    <CustomSelectRadio
                      label="Which bank account?"
                      name="bankAccount"
                      placeholder="Select an account"
                      onChange={(val) =>
                        setrequestDetails({ ...requestDetails, bankAccount: val })
                      }
                      value={requestDetails.bankAccount}
                      isLoading={loadingBalances}
                      isDisabled={loadingBalances || selectedRequestChannel}
                      loadOptions={loadAccounts}
                    />
                  </Row>
                )}
                {forBudget && (
                  <Row className="mb-2">
                    <CustomSelectRadio
                      label="Which budget?"
                      name="budget"
                      placeholder="Select a budget"
                      onChange={(val) =>
                        setrequestDetails({ ...requestDetails, budget: val })
                      }
                      value={requestDetails.budget}
                      isLoading={loadingBalances}
                      isDisabled={loadingBalances}
                      loadOptions={loadOptions}
                    />
                  </Row>
                )}
                {forPayment && (
                  <Row className="mb-2">
                    {!addNewVendor && (
                      <CustomCreatableSelect
                        isClearable
                        label="Which vendor?"
                        placeholder="Enter vendor/merchant's name"
                        onChange={handleChange}
                        onInputChange={(value) => handleSelectChange(value, 'vendor')}
                        defaultValue={
                          vendorDetails.name ? { label: vendorDetails.name } : ''
                        }
                        options={vendorsList}
                        noOptionsMessage={'No vendor, you can type and create a new one'}
                        isDisabled={loading && !debouncedVendorValue}
                        isLoading={loading}
                      />
                    )}
                    {(addNewVendor || updateAccount) && (
                      <div className="container margin-top">
                        <div className="card">
                          <div className="card-body">
                            <Row style={{ placeContent: 'center' }}>
                              <h6>
                                {updateAccount
                                  ? 'Update vendor bank account'
                                  : 'Create New Vendor/Merchant'}
                              </h6>
                              <Row className="mb-2">
                                <hr style={{ width: '94%' }} />
                              </Row>
                            </Row>
                            {!updateAccount && (
                              <>
                                <Row className="mb-2">
                                  <CustomInput
                                    label="Vendor or merchant name *"
                                    placeholder="Enter name"
                                    type="text"
                                    name="name"
                                    onChange={handleChangeVendorDetails}
                                    value={vendorDetails.name}
                                    maxLength="100"
                                  />
                                </Row>
                                <Row className="mb-2">
                                  <CustomSelect
                                    label="Country"
                                    name="country"
                                    placeholder="Select country"
                                    // onChange={(val) => setVendorDetails({ ...vendorDetails, country: val })}
                                    onChange={(val) => {
                                      setVendorDetails({
                                        ...vendorDetails,
                                        country: val,
                                      });
                                      getCountrySates(val);
                                    }}
                                    // value={vendorDetails.country}
                                    options={countries}
                                  />
                                </Row>
                                <Row className="mb-2">
                                  <CustomInput
                                    label="Address"
                                    placeholder="Enter street address"
                                    type="text"
                                    name="street"
                                    onChange={handleChangeVendorDetails}
                                    value={vendorDetails.vendorDetails}
                                    maxLength="100"
                                  />
                                </Row>
                                <Row className="mb-2 align-items-center">
                                  <CustomSelect
                                    isDisabled={!vendorDetails.country?.label}
                                    label="State"
                                    name="state"
                                    placeholder="State or Region"
                                    // onChange={handleChangeVendorDetails}
                                    onChange={(val) =>
                                      setVendorDetails({ ...vendorDetails, state: val })
                                    }
                                    // value={vendorDetails.state}
                                    options={stateData}
                                    md={6}
                                  />
                                  <CustomInput
                                    label="City"
                                    placeholder="Enter name"
                                    type="text"
                                    name="city"
                                    onChange={handleChangeVendorDetails}
                                    // onChange={handleChangeBusinessAddress}
                                    // value={vendorDetails.city}
                                    maxLength="50"
                                    md={6}
                                  />
                                </Row>
                              </>
                            )}
                            <Row className="mb-3">
                              <CustomSelect
                                label="Bank"
                                name="bankName"
                                placeholder="Select Bank"
                                onChange={(val) =>
                                  setVendorDetails({ ...vendorDetails, bankName: val })
                                }
                                value={vendorDetails.bankName}
                                options={allBanks}
                                onMenuClose={onMenuCloseBanks}
                                onInputChange={handleGetBankOnChange}
                                isDisabled={loadingBanks && !bankValuedebounced}
                                isLoading={loadingBanks}
                              />
                            </Row>
                            <Row className="mb-3">
                              <div
                                className={classNames('relative', {
                                  ['mb-5']: visible,
                                })}
                              >
                                <CustomInput
                                  type="text"
                                  label="Account number"
                                  placeholder="Enter number"
                                  name="accountNumber"
                                  onChange={handleChangeVendorDetails}
                                  value={vendorDetails.accountNumber}
                                  maxLength="10"
                                  pattern="[0-9]*"
                                />
                                <AccountName
                                  setValue={(value) =>
                                    setVendorDetails({
                                      ...vendorDetails,
                                      accountName: value,
                                    })
                                  }
                                  visible={visible}
                                  accountNameError={accountNameError}
                                  accountNameLoading={accountNameLoading}
                                />
                              </div>
                            </Row>
                            <Row className="mb-3">
                              <CustomInput
                                type="text"
                                label="Account name"
                                placeholder="Add account name"
                                name="accountName"
                                onChange={handleChangeVendorDetails}
                                value={vendorDetails.accountName}
                                maxLength="50"
                                pattern="[a-zA-Z -]*"
                                disabled
                              />
                            </Row>
                            <div className="w-100 mt-3 batch-footer d-flex gap-2 justify-content-end">
                              <button
                                onClick={cancelAddVendor}
                                className="add-custom add-button button-dimension-fit-content cursor"
                                disabled={loadingCreateVendor || loadingUpdateVendor}
                              >
                                Cancel
                              </button>

                              <CustomButton
                                className="add-button button-dimension-fit-content"
                                onClick={createNewVendor}
                                loading={loadingCreateVendor || loadingUpdateVendor}
                              >
                                Save
                              </CustomButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Row>
                )}
                {forNewBudget && (
                  <Row className="mb-2">
                    <CustomInput
                      label="Budget name"
                      placeholder="Enter name"
                      type="text"
                      name="budgetName"
                      onChange={handleChangerequestDetails}
                      value={requestDetails.budgetName}
                      maxLength="100"
                    />
                  </Row>
                )}
                {forCard && (
                  <Row className="mb-2">
                    <CustomCreatableSelect
                      isClearable
                      label="Which card?"
                      placeholder="Select Card"
                      onChange={(val) =>
                        setrequestDetails({ ...requestDetails, card: val })
                      }
                      options={cardsList}
                      onInputChange={(value) => handleSelectChange(value, 'card')}
                      isDisabled={loadingCards && !debouncedCardValue}
                      isLoading={loadingCards}
                    />
                  </Row>
                )}
                <Row className="mb-2">
                  <CustomInput
                    label="How much?"
                    placeholder="0.00"
                    type="number"
                    otherCurrency
                    isAmount
                    name="amount"
                    existAction={selectedCurrency ? defaultCurrency() : null}
                    isUpdate={selectedCurrency}
                    onChange={handleChangerequestDetails}
                    value={requestDetails.amount}
                    setIsOtherPopoverOpen={setIsOtherPopoverOpen}
                    getTypeVal={handleGetSign}
                  />
                </Row>
                {forPayment && (
                  <Row className="mb-2">
                    <CategoryDropdown
                      label="Category"
                      placeholder="Select a category"
                      onChange={(val) =>
                        setrequestDetails({ ...requestDetails, category: val })
                      }
                      value={requestDetails.category}
                      name="category"
                    />
                  </Row>
                )}
                <Row className="mb-2">
                  <CustomTextarea
                    label="Funding reason"
                    name="description"
                    placeholder="Enter a description of the expense"
                    onChange={handleCharChange}
                    value={text || requestDetails?.description}
                    maxLength={MAX_LENGTH}
                    showCounter={true}
                    charCount={charCount}
                  />
                </Row>

                {!['create-budget-option'].includes(requestDetails?.channel?.code) && (
                  <Row className="mb-5">
                    <FileUpload
                      label="Supporting documents"
                      placeholder="Upload certificate (PDF, JPEG, PNG)"
                      name="file"
                      setUploadingFile={setUploadingFile}
                      onChange={(value) =>
                        setrequestDetails({
                          ...requestDetails,
                          receipt: value?.map((item) => item.assetCode),
                        })
                      }
                      multiple
                    />
                  </Row>
                )}

                <div className="modal-footer">
                  <CustomButton
                    onClick={handleClose}
                    fullWidth={true}
                    className="custom-button ghost-button"
                  >
                    Cancel
                  </CustomButton>
                  <CustomButton
                    onClick={createRequest}
                    fullWidth={true}
                    className="custom-button primary-button"
                    loading={loadingCreateRequest}
                    disabled={loadingCreateRequest}
                  >
                    Continue
                  </CustomButton>
                </div>
              </>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default RequestFunds;
