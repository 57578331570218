import IconWrap from '../IconWrap';
import './index.styles.scss';

/**
 * @param {Object}: main: {title, body, action} //title string, body: string, action: a render prop that returns a component
 * @returns
 */
const EmptyState = ({ main, childrenData }) => {
  return (
    <section className="emptystate-wrapper mb-5">
      <section className="emptystate-main d-flex">
        <div className="emptystate-main-image">
          <img src={main?.image} />
        </div>
        <div className="emptystate-body">
          <h6>{main?.title}</h6>
          <p>{main?.body}</p>
          {main?.action && <div className="emptystate-action">{main?.action()}</div>}
        </div>
      </section>

      {childrenData && (
        <section className="d-flex children-wrapper">
          {childrenData?.map((child, index) => {
            return <ChildComponent data={child} key={index} />;
          })}
        </section>
      )}
    </section>
  );
};

export default EmptyState;

const ChildComponent = ({ data }) => {
  return (
    <section className="child-empty-items">
      <IconWrap icon={data?.icon} />
      <h6>{data?.title}</h6>
      <p>{data?.body}</p>
      <span onClick={data?.action}>{data?.actionTitle}</span>
    </section>
  );
};
