import { CloseOutlined } from '@ant-design/icons';
import AssetViewer from 'components/AssetViewer';
import CustomButton from 'components/UI/CustomButton';
import CustomDrawer from 'components/UI/CustomDrawer';
import Loading from 'components/UI/Loading';
import Modal from 'components/UI/Modal';
import ItemsDetails from 'components/bujetiPayModal/ItemsDetails';
import { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllTransactionAsset,
  getSingleScheduledTransaction,
} from 'redux/actions/TransactionsAction';
import { RESET_BLOCK_TRANSACTIONS } from 'redux/reducers/TransactionsReducer';
import { Banks } from 'utils/banks';
import {
  capitalizeFirstLetter,
  getCurrency,
  getFormattedDate,
  getPaidTo,
} from 'utils/helper';

const ViewScheduledTransactionModal = ({
  selectedTransaction,
  setSelectedTransaction,
  setToggle,
}) => {
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [assetViewer, setAssetViewer] = useState(false);

  const dispatch = useDispatch();
  const handleClose = () => {
    setSelectedTransaction(null);
    setToggle(false);
    dispatch({
      type: RESET_BLOCK_TRANSACTIONS,
      blockType: 'getSingleScheduledTransaction',
    });
    dispatch({ type: RESET_BLOCK_TRANSACTIONS, blockType: 'transactionReceipts' });
  };

  const {
    getSingleScheduledTransaction: {
      data: singleTransaction = {},
      loading: singleLoading,
      success: singleSuccess,
      error: singleError,
    },
    transactionReceipts: { data: receiptAssets },
  } = useSelector(({ transaction }) => transaction);

  const { transactionData: selectedData = {} } = selectedTransaction ?? {};

  const toggleAssetViewer = () => {
    setAssetViewer(!assetViewer);
  };

  useEffect(() => {
    if (receiptAssets && receiptAssets.length > 0) {
      setSelectedAsset(receiptAssets[0]);
      setAssetViewer(true);
    }
  }, [receiptAssets]);

  useEffect(() => {
    if (!singleSuccess && !singleLoading && selectedData?.code) {
      dispatch(getSingleScheduledTransaction(selectedData?.code));
    }
  }, [selectedData?.code]);

  useEffect(() => {
    if (!singleLoading && singleSuccess) {
      dispatch(getAllTransactionAsset(singleTransaction?.receipts));
    }
    if (!singleLoading && !singleSuccess && singleError) {
      handleClose();
    }
  }, [singleLoading, singleSuccess]);

  const transactionData = singleTransaction;

  const getBankName = Banks.find(
    (item) => item.value === transactionData?.bankAccount?.bankCode,
  );

  return (
    <Modal show={selectedTransaction}>
      <CustomDrawer show>
        {assetViewer && (
          <AssetViewer
            toggleVisibility={toggleAssetViewer}
            data={receiptAssets && receiptAssets}
            selectedAsset={selectedAsset}
          />
        )}
        <div className="content d-flex flex-column">
          {singleLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: '100vh' }}
            >
              <Loading color="#d2b28b" size={32} />
            </div>
          ) : (
            <>
              <div className="card-modal-header">
                <div className="d-flex align-items-center cursor" onClick={handleClose}>
                  <CloseOutlined />
                  <span className="ps-1">Close</span>
                </div>
              </div>
              <div className="card-modal-body mt-0">
                <div className="information-wrapper">
                  <h2 className="card-title w-100 mb-2">Transaction details</h2>
                  <div className="w-100">
                    <div>
                      <ItemsDetails
                        className="mb-1"
                        title={<span className="header__transfer">Transfer details</span>}
                      />

                      <ItemsDetails
                        title="Total amount we'll send"
                        value={
                          <CurrencyFormat
                            prefix={getCurrency(transactionData?.currency)}
                            value={transactionData?.amount / 100}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        }
                      />
                      <ItemsDetails
                        title="Transaction fees"
                        value={
                          <CurrencyFormat
                            prefix={getCurrency(transactionData?.currency)}
                            value={(transactionData?.processor_fee ?? 0) / 100}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        }
                      />
                      <ItemsDetails
                        className="mt-1"
                        title="Amount"
                        value={
                          <span className="fs-5 text-dark">
                            <CurrencyFormat
                              prefix={getCurrency(transactionData?.currency)}
                              value={
                                ((transactionData?.processor_fee ?? 0) +
                                  transactionData?.amount) /
                                100
                              }
                              displayType="text"
                              thousandSeparator={true}
                            />
                          </span>
                        }
                      />
                      <div className="divider"></div>
                      <ItemsDetails
                        className="mb-1"
                        title={
                          <span className="header__transfer">Recipient details</span>
                        }
                      />
                      <ItemsDetails title="Name" value={getPaidTo(transactionData)} />
                      <ItemsDetails title="Bank name" value={getBankName?.label ?? '-'} />
                      <ItemsDetails
                        title="Account number"
                        value={transactionData?.bankAccount?.number ?? '-'}
                      />

                      <div className="divider"></div>

                      <ItemsDetails
                        className="mb-1"
                        title={<span className="header__transfer">Schedule details</span>}
                      />
                      <ItemsDetails
                        title="Frequency"
                        value={
                          capitalizeFirstLetter(transactionData?.frequency) ?? 'One time'
                        }
                      />
                      <ItemsDetails
                        title="Next transfer"
                        value={getFormattedDate(transactionData?.schedule?.nextDate)}
                      />
                      <ItemsDetails
                        title="Start date"
                        value={getFormattedDate(transactionData?.start_date)}
                      />
                      <ItemsDetails
                        title="End date"
                        value={getFormattedDate(transactionData?.expiry_date)}
                      />

                      <div className="divider"></div>

                      <ItemsDetails
                        className="mb-1"
                        title={<span className="header__transfer">Other</span>}
                      />
                      {transactionData?.balance && (
                        <ItemsDetails
                          title={<span className="text-dark">Account</span>}
                          isLink={
                            transactionData?.balance?.code
                              ? `/accounts/${transactionData?.balance.code}`
                              : false
                          }
                          withAction
                          value={
                            <span className="value__transfer">
                              {transactionData?.balance?.name ?? 'N/A'}
                            </span>
                          }
                        />
                      )}
                      {transactionData?.budget && (
                        <ItemsDetails
                          title={<span className="text-dark">Budget</span>}
                          isLink={
                            transactionData?.budget?.code
                              ? `/expenses/budgets/${transactionData?.budget.code}/overview`
                              : false
                          }
                          withAction
                          value={
                            <span className="value__transfer">
                              {transactionData?.budget?.name ?? 'N/A'}
                            </span>
                          }
                        />
                      )}
                      <ItemsDetails
                        title="Expense Category"
                        value={transactionData?.category?.name}
                      />
                      <ItemsDetails
                        title="Description"
                        value={transactionData?.description}
                      />
                      <ItemsDetails
                        title="Schedule ID"
                        value={transactionData?.code ?? '-'}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {transactionData?.status === 'active' && (
                <div className="card-modal-footer py-3">
                  <div className="w-100 batch-footer d-flex gap-2 justify-content-end">
                    <CustomButton
                      className="border-btn button-dimension-fit-content"
                      onClick={() => {
                        setSelectedTransaction(null);
                        setToggle(false);
                      }}
                    >
                      Cancel
                    </CustomButton>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </CustomDrawer>
    </Modal>
  );
};

export default ViewScheduledTransactionModal;
