export const AddTeamMember = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.1665 12.5H6.6665C5.11337 12.5 4.3368 12.5 3.72423 12.7537C2.90747 13.092 2.25855 13.741 1.92024 14.5577C1.6665 15.1703 1.6665 15.9469 1.6665 17.5M12.9165 2.7423C14.1381 3.23679 14.9998 4.43443 14.9998 5.83333M9.99973 17.9167L11.6873 17.5792C11.8344 17.5497 11.908 17.535 11.9766 17.5081C12.0375 17.4842 12.0954 17.4533 12.149 17.4159C12.2095 17.3737 12.2625 17.3206 12.3686 17.2145L17.9165 11.6667C18.3768 11.2065 18.3768 10.4602 17.9165 9.99998C17.4563 9.53975 16.7101 9.53976 16.2498 10L10.7019 15.5479C10.5958 15.654 10.5428 15.707 10.5006 15.7675C10.4632 15.8211 10.4323 15.879 10.4084 15.9399C10.3815 16.0085 10.3668 16.082 10.3373 16.2292L9.99973 17.9167ZM11.2498 5.83333C11.2498 7.67428 9.75745 9.16667 7.9165 9.16667C6.07555 9.16667 4.58317 7.67428 4.58317 5.83333C4.58317 3.99238 6.07555 2.5 7.9165 2.5C9.75745 2.5 11.2498 3.99238 11.2498 5.83333Z"
        stroke="#D28B28"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CreateTeams = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8332 8.33325V3.33325M13.3332 5.83325H18.3332M13.3332 17.4999V16.4999C13.3332 15.0998 13.3332 14.3997 13.0607 13.8649C12.821 13.3945 12.4386 13.0121 11.9681 12.7724C11.4334 12.4999 10.7333 12.4999 9.33317 12.4999H5.6665C4.26637 12.4999 3.56631 12.4999 3.03153 12.7724C2.56112 13.0121 2.17867 13.3945 1.93899 13.8649C1.6665 14.3997 1.6665 15.0998 1.6665 16.4999V17.4999M10.4165 6.24992C10.4165 7.86075 9.11067 9.16659 7.49984 9.16659C5.88901 9.16659 4.58317 7.86075 4.58317 6.24992C4.58317 4.63909 5.88901 3.33325 7.49984 3.33325C9.11067 3.33325 10.4165 4.63909 10.4165 6.24992Z"
        stroke="#D28B28"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ManageRoles = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8332 17.5L18.3332 15M18.3332 15L15.8332 12.5M18.3332 15H13.3332M9.99984 12.9167H6.24984C5.08687 12.9167 4.50538 12.9167 4.03222 13.0602C2.96688 13.3834 2.1332 14.217 1.81004 15.2824C1.6665 15.7555 1.6665 16.337 1.6665 17.5M12.0832 6.25C12.0832 8.32107 10.4042 10 8.33317 10C6.2621 10 4.58317 8.32107 4.58317 6.25C4.58317 4.17893 6.2621 2.5 8.33317 2.5C10.4042 2.5 12.0832 4.17893 12.0832 6.25Z"
        stroke="#D28B28"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const FundRequest = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.74976 11.2501L17.4998 2.50014M8.85608 11.5235L11.0462 17.1552C11.2391 17.6513 11.3356 17.8994 11.4746 17.9718C11.5951 18.0346 11.7386 18.0347 11.8592 17.972C11.9983 17.8998 12.095 17.6518 12.2886 17.1559L17.7805 3.08281C17.9552 2.63516 18.0426 2.41133 17.9948 2.26831C17.9533 2.1441 17.8558 2.04663 17.7316 2.00514C17.5886 1.95736 17.3647 2.0447 16.9171 2.21939L2.84398 7.71134C2.34808 7.90486 2.10013 8.00163 2.02788 8.14071C1.96524 8.26129 1.96532 8.40483 2.0281 8.52533C2.10052 8.66433 2.34859 8.7608 2.84471 8.95373L8.47638 11.1438C8.57708 11.183 8.62744 11.2026 8.66984 11.2328C8.70742 11.2596 8.74028 11.2925 8.76709 11.3301C8.79734 11.3725 8.81692 11.4228 8.85608 11.5235Z"
        stroke="#D28B28"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CreateBudget = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8332 17.5V12.5M13.3332 15H18.3332M18.3332 8.33329H1.6665M18.3332 9.99996V6.83329C18.3332 5.89987 18.3332 5.43316 18.1515 5.07664C17.9917 4.76304 17.7368 4.50807 17.4232 4.34828C17.0666 4.16663 16.5999 4.16663 15.6665 4.16663H4.33317C3.39975 4.16663 2.93304 4.16663 2.57652 4.34828C2.26292 4.50807 2.00795 4.76304 1.84816 5.07664C1.6665 5.43316 1.6665 5.89987 1.6665 6.83329V13.1666C1.6665 14.1 1.6665 14.5668 1.84816 14.9233C2.00795 15.2369 2.26292 15.4918 2.57652 15.6516C2.93304 15.8333 3.39975 15.8333 4.33317 15.8333H9.99984"
        stroke="#D28B28"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ManageVendor = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.91667 12.5V7.5M12.0833 12.5V7.5M6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V6.5C17.5 5.09987 17.5 4.3998 17.2275 3.86502C16.9878 3.39462 16.6054 3.01217 16.135 2.77248C15.6002 2.5 14.9001 2.5 13.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5Z"
        stroke="#D28B28"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SpendingPolicy = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99984 17.5L9.91646 17.3749C9.33759 16.5066 9.04816 16.0725 8.66576 15.7582C8.32722 15.4799 7.93714 15.2712 7.51784 15.1438C7.04421 15 6.52243 15 5.47886 15H4.33317C3.39975 15 2.93304 15 2.57652 14.8183C2.26292 14.6586 2.00795 14.4036 1.84816 14.09C1.6665 13.7335 1.6665 13.2668 1.6665 12.3333V5.16667C1.6665 4.23325 1.6665 3.76654 1.84816 3.41002C2.00795 3.09641 2.26292 2.84144 2.57652 2.68166C2.93304 2.5 3.39975 2.5 4.33317 2.5H4.6665C6.53335 2.5 7.46677 2.5 8.17981 2.86331C8.80701 3.18289 9.31695 3.69282 9.63653 4.32003C9.99984 5.03307 9.99984 5.96649 9.99984 7.83333M9.99984 17.5V7.83333M9.99984 17.5L10.0832 17.3749C10.6621 16.5066 10.9515 16.0725 11.3339 15.7582C11.6725 15.4799 12.0625 15.2712 12.4818 15.1438C12.9555 15 13.4772 15 14.5208 15H15.6665C16.5999 15 17.0666 15 17.4232 14.8183C17.7368 14.6586 17.9917 14.4036 18.1515 14.09C18.3332 13.7335 18.3332 13.2668 18.3332 12.3333V5.16667C18.3332 4.23325 18.3332 3.76654 18.1515 3.41002C17.9917 3.09641 17.7368 2.84144 17.4232 2.68166C17.0666 2.5 16.5999 2.5 15.6665 2.5H15.3332C13.4663 2.5 12.5329 2.5 11.8199 2.86331C11.1927 3.18289 10.6827 3.69282 10.3631 4.32003C9.99984 5.03307 9.99984 5.96649 9.99984 7.83333"
        stroke="#D28B28"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ConnectBankAccount = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 17.5V13C12.5 12.5333 12.5 12.2999 12.4092 12.1217C12.3293 11.9649 12.2018 11.8374 12.045 11.7575C11.8667 11.6667 11.6334 11.6667 11.1667 11.6667H8.83333C8.36662 11.6667 8.13327 11.6667 7.95501 11.7575C7.79821 11.8374 7.67072 11.9649 7.59083 12.1217C7.5 12.2999 7.5 12.5333 7.5 13V17.5M15.8333 17.5V5.16667C15.8333 4.23325 15.8333 3.76654 15.6517 3.41002C15.4919 3.09641 15.2369 2.84144 14.9233 2.68166C14.5668 2.5 14.1001 2.5 13.1667 2.5H6.83333C5.89991 2.5 5.4332 2.5 5.07668 2.68166C4.76308 2.84144 4.50811 3.09641 4.34832 3.41002C4.16667 3.76654 4.16667 4.23325 4.16667 5.16667V17.5M17.5 17.5H2.5M7.91667 6.66667H7.925M12.0833 6.66667H12.0917M8.33333 6.66667C8.33333 6.89679 8.14679 7.08333 7.91667 7.08333C7.68655 7.08333 7.5 6.89679 7.5 6.66667C7.5 6.43655 7.68655 6.25 7.91667 6.25C8.14679 6.25 8.33333 6.43655 8.33333 6.66667ZM12.5 6.66667C12.5 6.89679 12.3135 7.08333 12.0833 7.08333C11.8532 7.08333 11.6667 6.89679 11.6667 6.66667C11.6667 6.43655 11.8532 6.25 12.0833 6.25C12.3135 6.25 12.5 6.43655 12.5 6.66667Z"
        stroke="#D28B28"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PaymentVendor = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 10L7.5 10M17.5 5.00002L7.5 5.00002M17.5 15L7.5 15M4.16667 10C4.16667 10.4603 3.79357 10.8334 3.33333 10.8334C2.8731 10.8334 2.5 10.4603 2.5 10C2.5 9.53978 2.8731 9.16669 3.33333 9.16669C3.79357 9.16669 4.16667 9.53978 4.16667 10ZM4.16667 5.00002C4.16667 5.46026 3.79357 5.83335 3.33333 5.83335C2.8731 5.83335 2.5 5.46026 2.5 5.00002C2.5 4.53978 2.8731 4.16669 3.33333 4.16669C3.79357 4.16669 4.16667 4.53978 4.16667 5.00002ZM4.16667 15C4.16667 15.4603 3.79357 15.8334 3.33333 15.8334C2.8731 15.8334 2.5 15.4603 2.5 15C2.5 14.5398 2.8731 14.1667 3.33333 14.1667C3.79357 14.1667 4.16667 14.5398 4.16667 15Z"
        stroke="#D28B28"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CollectPayment = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3332 15L14.9998 16.6667L18.3332 13.3334M18.3332 8.33335H1.6665M18.3332 10V6.83335C18.3332 5.89993 18.3332 5.43322 18.1515 5.0767C17.9917 4.7631 17.7368 4.50813 17.4232 4.34834C17.0666 4.16669 16.5999 4.16669 15.6665 4.16669H4.33317C3.39975 4.16669 2.93304 4.16669 2.57652 4.34834C2.26292 4.50813 2.00795 4.7631 1.84816 5.0767C1.6665 5.43322 1.6665 5.89993 1.6665 6.83335V13.1667C1.6665 14.1001 1.6665 14.5668 1.84816 14.9233C2.00795 15.2369 2.26292 15.4919 2.57652 15.6517C2.93304 15.8334 3.39975 15.8334 4.33317 15.8334H9.99984"
        stroke="#D28B28"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CreateNew = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.50016 8.75L9.16683 10.4167L12.9168 6.66667M16.6668 17.5V6.5C16.6668 5.09987 16.6668 4.3998 16.3943 3.86502C16.1547 3.39462 15.7722 3.01217 15.3018 2.77248C14.767 2.5 14.067 2.5 12.6668 2.5H7.3335C5.93336 2.5 5.2333 2.5 4.69852 2.77248C4.22811 3.01217 3.84566 3.39462 3.60598 3.86502C3.3335 4.3998 3.3335 5.09987 3.3335 6.5V17.5L5.62516 15.8333L7.7085 17.5L10.0002 15.8333L12.2918 17.5L14.3752 15.8333L16.6668 17.5Z"
        stroke="#D28B28"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const MultipleCards = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3332 8.33333H1.6665M9.1665 15.8333L15.6665 15.8333C16.5999 15.8333 17.0666 15.8333 17.4232 15.6517C17.7368 15.4919 17.9917 15.2369 18.1515 14.9233C18.3332 14.5668 18.3332 14.1001 18.3332 13.1667V6.83333C18.3332 5.89991 18.3332 5.4332 18.1515 5.07668C17.9917 4.76308 17.7368 4.50811 17.4232 4.34832C17.0666 4.16667 16.5999 4.16667 15.6665 4.16667H14.1665M9.1665 15.8333L10.8332 17.5M9.1665 15.8333L10.8332 14.1667M5.83317 15.8333H4.33317C3.39975 15.8333 2.93304 15.8333 2.57652 15.6517C2.26292 15.4919 2.00795 15.2369 1.84816 14.9233C1.6665 14.5668 1.6665 14.1001 1.6665 13.1667V6.83333C1.6665 5.89991 1.6665 5.4332 1.84816 5.07668C2.00795 4.76308 2.26292 4.50811 2.57652 4.34832C2.93304 4.16667 3.39975 4.16667 4.33317 4.16667H10.8332M10.8332 4.16667L9.1665 5.83333M10.8332 4.16667L9.1665 2.5"
        stroke="#D28B28"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SingleCard = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.6665 8.33329H18.3332V6.83329C18.3332 5.89987 18.3332 5.43316 18.1515 5.07664C17.9917 4.76304 17.7368 4.50807 17.4232 4.34828C17.0666 4.16663 16.5999 4.16663 15.6665 4.16663H4.33317C3.39975 4.16663 2.93304 4.16663 2.57652 4.34828C2.26292 4.50807 2.00795 4.76304 1.84816 5.07664C1.6665 5.43316 1.6665 5.89987 1.6665 6.83329V13.1666C1.6665 14.1 1.6665 14.5668 1.84816 14.9233C2.00795 15.2369 2.26292 15.4918 2.57652 15.6516C2.93304 15.8333 3.39975 15.8333 4.33317 15.8333H9.1665M12.0832 17.5L13.7706 17.1625C13.9178 17.133 13.9914 17.1183 14.06 17.0914C14.1208 17.0675 14.1787 17.0366 14.2324 16.9991C14.2928 16.957 14.3459 16.9039 14.452 16.7978L17.9165 13.3333C18.3767 12.8731 18.3767 12.1269 17.9165 11.6666C17.4563 11.2064 16.7101 11.2064 16.2498 11.6666L12.7853 15.1312C12.6792 15.2373 12.6261 15.2903 12.584 15.3508C12.5466 15.4044 12.5156 15.4623 12.4917 15.5232C12.4648 15.5918 12.4501 15.6653 12.4207 15.8125L12.0832 17.5Z"
        stroke="#D28B28"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SingleTransaction = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99984 14.1667C9.99984 16.4679 11.8653 18.3333 14.1665 18.3333C16.4677 18.3333 18.3332 16.4679 18.3332 14.1667C18.3332 11.8655 16.4677 10 14.1665 10C11.8653 10 9.99984 11.8655 9.99984 14.1667ZM9.99984 14.1667C9.99984 13.2285 10.3099 12.3627 10.8332 11.6663V4.16667M9.99984 14.1667C9.99984 14.8545 10.1665 15.5033 10.4616 16.0751C9.75956 16.6681 8.13804 17.0833 6.24984 17.0833C3.71853 17.0833 1.6665 16.3371 1.6665 15.4167V4.16667M10.8332 4.16667C10.8332 5.08714 8.78114 5.83333 6.24984 5.83333C3.71853 5.83333 1.6665 5.08714 1.6665 4.16667M10.8332 4.16667C10.8332 3.24619 8.78114 2.5 6.24984 2.5C3.71853 2.5 1.6665 3.24619 1.6665 4.16667M1.6665 11.6667C1.6665 12.5871 3.71853 13.3333 6.24984 13.3333C8.07401 13.3333 9.64927 12.9458 10.387 12.3848M10.8332 7.91667C10.8332 8.83714 8.78114 9.58333 6.24984 9.58333C3.71853 9.58333 1.6665 8.83714 1.6665 7.91667"
        stroke="#D28B28"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SchedulePayment = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 6.66675H2.5M13.3333 1.66675V4.16675M6.66667 1.66675V4.16675M10 15.0001V10.0001M7.5 12.5001H12.5M6.5 18.3334H13.5C14.9001 18.3334 15.6002 18.3334 16.135 18.0609C16.6054 17.8212 16.9878 17.4388 17.2275 16.9684C17.5 16.4336 17.5 15.7335 17.5 14.3334V7.33342C17.5 5.93328 17.5 5.23322 17.2275 4.69844C16.9878 4.22803 16.6054 3.84558 16.135 3.6059C15.6002 3.33341 14.9001 3.33341 13.5 3.33341H6.5C5.09987 3.33341 4.3998 3.33341 3.86502 3.6059C3.39462 3.84558 3.01217 4.22803 2.77248 4.69844C2.5 5.23322 2.5 5.93328 2.5 7.33341V14.3334C2.5 15.7335 2.5 16.4336 2.77248 16.9684C3.01217 17.4388 3.39462 17.8212 3.86502 18.0609C4.3998 18.3334 5.09987 18.3334 6.5 18.3334Z"
        stroke="#D28B28"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ApprovalRule = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0835 4.5834L13.3335 5.8334M9.58347 7.0834L10.8335 8.3334M7.08347 9.5834L8.33347 10.8334M4.58347 12.0834L5.83347 13.3334M2.13817 14.6382L5.36203 17.862C5.52704 18.027 5.60954 18.1095 5.70468 18.1405C5.78836 18.1676 5.87851 18.1676 5.96219 18.1405C6.05733 18.1095 6.13983 18.027 6.30484 17.862L17.862 6.30484C18.027 6.13983 18.1095 6.05733 18.1405 5.96219C18.1676 5.87851 18.1676 5.78836 18.1405 5.70468C18.1095 5.60954 18.027 5.52704 17.862 5.36203L14.6382 2.13817C14.4732 1.97316 14.3907 1.89066 14.2955 1.85975C14.2118 1.83256 14.1217 1.83256 14.038 1.85975C13.9429 1.89066 13.8604 1.97316 13.6954 2.13817L2.13817 13.6954C1.97316 13.8604 1.89066 13.9429 1.85975 14.038C1.83256 14.1217 1.83256 14.2118 1.85975 14.2955C1.89066 14.3907 1.97316 14.4732 2.13817 14.6382Z"
        stroke="#D28B28"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
