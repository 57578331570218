import { ReactComponent as Logo } from 'assets/logos/appLogo.svg';
import { ReactComponent as Twitter } from 'assets/icons/twitter.svg';
import { ReactComponent as Linkedin } from 'assets/icons/linkedin.svg';
import cs from 'classnames';
import { Container, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './styles.scss';

const BusinessOwnerNavBar = () => {
  return (
    <>
      <div className="header-space" />
      <Navbar className={cs('top-navbar fixed')} expand="lg">
        <Container className="mx-0 px-5 py-3">
          <Navbar.Brand className="me-auto ">
            <Link className="logo-link">
              <Logo className="header-logo" />
            </Link>
          </Navbar.Brand>
        </Container>
        <div className="d-flex justify-content-center align-items-center">
          <div style={{ marginRight: '24px' }}>
            © Bujeti Ltd. 2022, All Rights Reserved
          </div>
          <div className="d-flex justify-content-center align-items-center space-x-2">
            <Twitter />
            <Linkedin />
          </div>
        </div>
      </Navbar>
    </>
  );
};
export default BusinessOwnerNavBar;
