import Loading from 'components/UI/Loading';
import { Cell, Label, Legend, Pie, PieChart } from 'recharts';
import CurrencyFormat from 'react-currency-format';
import { capitalizeFirstLetter, getCurrency } from 'utils/helper';
import { numFormatter } from 'utils/utility';
import './styles.scss';

const COLORS = [
  '#AF7421',
  '#D28B28',
  '#DEA250',
  '#E5B777',
  '#ECCC9E',
  '#F4E0C5',
  '#FBF5EC',
];

function CustomLabel({ viewBox, value1, value2, currency }) {
  const { cx, cy } = viewBox;
  return (
    <svg
      className="recharts-text recharts-label"
      textAnchor="middle"
      dominantBaseline="central"
    >
      <text x={cx} y={85} fill="#3d405c">
        <tspan x={cx} dy="0em" alignmentBaseline="middle" fontSize="12" color="#586068">
          {value1}
        </tspan>
        <tspan x={cx} dy="1em" fontSize="16" fontWeight={600} color="#242628">
          {getCurrency(currency === 'NGN' ? '₦' : '$')}
          {numFormatter(value2, true)}
        </tspan>
      </text>
    </svg>
  );
}

const renderLegend = (props) => {
  const { payload } = props;

  return (
    <ul className="grid-col-2">
      {payload.map((entry, index) => (
        <li key={`item-${index}`} style={{ listStyle: 'none' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 8,
              fontSize: 12,
              color: '#586068',
            }}
          >
            <span
              style={{ width: 10, height: 10, background: entry.payload.fill }}
            ></span>
            <span className="text-wrap text-break lh-1">
              {entry?.value === 'object' && !Array.isArray(entry?.value)
                ? capitalizeFirstLetter(entry?.value)
                : capitalizeFirstLetter(entry?.value) ?? 'Others'}{' '}
            </span>
          </div>
          <CurrencyFormat
            prefix={getCurrency(entry?.payload?.currency)}
            value={numFormatter(entry?.payload?.value, true)}
            displayType="text"
            thousandSeparator={true}
            isNumericString
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 8,
              fontSize: 14,
              color: '#000',
            }}
          />
        </li>
      ))}
    </ul>
  );
};

const PieChartView = ({ isLoading, data, title, totalSpent, currency, success }) => {
  return (
    <div className="stats__card bg-transparent">
      {isLoading && <Loading size={20} color="#D28B28" className="mt-4" />}
      {!isLoading && !data?.analytics?.length && (
        <div className="empty-state">
          <svg
            width="231"
            height="231"
            viewBox="0 0 231 231"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M115.5 231C179.289 231 231 179.289 231 115.5C231 51.7112 179.289 0 115.5 0C51.7112 0 0 51.7112 0 115.5C0 179.289 51.7112 231 115.5 231ZM116 178C150.242 178 178 150.242 178 116C178 81.7583 150.242 54 116 54C81.7583 54 54 81.7583 54 116C54 150.242 81.7583 178 116 178Z"
              fill="#E5E8E3"
            />
          </svg>

          <span>No Data for this period</span>
        </div>
      )}

      {!isLoading && !!data?.analytics?.length && (
        <div className="wrapper">
          <div className="stats__card--charts">
            <PieChart width={220} height={200}>
              <Legend
                layout="vertical"
                content={renderLegend}
                verticalAlign="top"
                align="right"
              />
              <Pie
                data={data?.analytics}
                cx={90}
                cy={90}
                innerRadius={45}
                outerRadius={95}
                fill="#8884d8"
                paddingAngle={0}
                dataKey="value"
              >
                <Label
                  width={30}
                  position="center"
                  content={
                    <CustomLabel
                      value1={'Total spent'}
                      value2={totalSpent}
                      currency={currency}
                    />
                  }
                ></Label>
                {COLORS.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
            </PieChart>
          </div>
        </div>
      )}
    </div>
  );
};

export default PieChartView;
