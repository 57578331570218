import NoData from 'components/NoData';
import PolicyDetailsModal from 'components/PolicyDetailModal';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import Loading from 'components/UI/Loading';
import { useDebounce } from 'hooks/useDebounce';
import ExistingPolicyModal from 'pages/Policy/ExistingPolicyModal';
import PolicyModal from 'pages/Policy/PolicyModal';
import { useEffect, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PlusCircleOutlined, LinkOutlined } from '@ant-design/icons';
import {
  getSingleBudgetPolicies,
  unlinkBudgetPolicy,
} from 'redux/actions/PoliciesAction';
import { buildPolicyTableData, policiesType } from 'utils/helper';
import { policyColumns } from 'utils/mockData';
import ConfirmDialog from 'components/ConfirmDialog';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import PendingOnboardingNotice from '../PendingOnboardingNotice';
import MigrateOldBudgetInfo from '../MigrateOldBudgetInfo';
import CustomButton from 'components/UI/CustomButton';
import PeopleImage from 'assets/images/emptystate/people-image.png';
import CustomPopover from 'components/UI/Popover';
import { ReactComponent as DownArrow } from 'assets/icons/down-arrow.svg';
import EmptyState from 'components/UI/EmptyState';
import useIsFiltered from 'hooks/useIsFiltered';
import { DeleteIcon, EditIcon } from 'assets/icons';

const BudgetPolicy = () => {
  const dispatch = useDispatch();
  const { budgetId } = useParams();
  const { permissions } = allPermissions();

  const canManage = hasPermission({
    permissions,
    scopes: ['policy-*', 'policy-create', 'policy-edit'],
  });

  const canEdit = hasPermission({
    permissions,
    scopes: ['policy-*', 'policy-edit'],
  });
  const canDelete = hasPermission({
    permissions,
    scopes: ['policy-*', 'policy-delete'],
  });

  const [filterData, setFilterData] = useState([]);

  const [isOpen, setIsOpen] = useState(0);
  const [isOtherPopoverOpen, setIsOtherPopoverOpen] = useState(false);
  const [selectPolicy, setSelectPolicy] = useState(null);
  const [isFillData, setIsFillData] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isDelete, setIsDelete] = useState(null);
  const [singleBudget, setSingleBudget] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [search, setSearch] = useState('');
  const debouncedValue = useDebounce(search, 600);
  const { filtered, isFiltered, filteredQuery, setFilteredQuery } = useIsFiltered();

  const [isButtonToggle, setIsButtonToggle] = useState(false);

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  // filter out column not neccessary for budget view
  const budgetPolicyColumn = policyColumns.filter(
    (item) => item.accessor != 'assignedBudget',
  );

  // filter out column not neccessary for beneficiary view
  const beneficiaryPolicyColumn = policyColumns.filter(
    (item) => item.accessor != 'assignedBudget' && item.accessor != 'modify',
  );

  const {
    getSingleBudget: { data: budget = {} },
  } = useSelector(({ budgets }) => budgets);

  const {
    getSingleBudgetPolicies: {
      data: { policies = [], meta = {} } = {},
      loading: loadingPolicy,
    },
    getPolicyTypes: { data: policyTypes = [] },
    unlinkBudgetPolicy: { loading: deleteLoading, success: deleteSuccess },
  } = useSelector(({ policies }) => policies);

  const { page = 1, total, hasMore = false, perPage = 50, nextPage = 2 } = meta;

  const isClosed = budget?.status === 'closed';

  const toggleHandler = (val) => {
    setIsOpen(val);
  };

  useEffect(() => {
    if (!!policies?.length) {
      if (!isFillData) {
        setIsFillData(true);

        const filteredPolicy = [];
        policyTypes
          .filter((item) => item.code != policiesType.INDIVIDUAL_ALLOCATION)
          .forEach((policy) => {
            const { code, name } = policy;
            filteredPolicy.push({
              value: code,
              label: name,
              isSelected: false,
            });
          });

        // setFilterData([{ title: 'Type', list: filteredPolicy }, ...filterData]);
      }
    }
  }, [policies]);

  const handleFilter = (query) => {
    dispatch(getSingleBudgetPolicies({ budget: budgetId, ...query }));
    setFilteredQuery({ budget: budgetId, ...query });
    isFiltered.current = !!Object.keys(query).length;
  };

  // Handle search change after debounce
  useEffect(() => {
    if (debouncedValue) {
      filteredQuery.search = debouncedValue;
      dispatch(
        getSingleBudgetPolicies({
          ...filteredQuery,
          budget: budgetId,
          search: debouncedValue,
        }),
      );
    }
    if (!debouncedValue && filtered) {
      delete filteredQuery.search;
      dispatch(getSingleBudgetPolicies({ budget: budgetId, ...filteredQuery }));
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  const handleClose = () => {
    if (!isOtherPopoverOpen)
      return (
        toggleHandler(0),
        setSelectPolicy(null),
        setIsUpdate(false),
        setSingleBudget(false)
      );
  };

  const handleRowClick = (row) => {
    setSelectPolicy(row);
  };

  const handleEditClick = (data) => {
    setSelectPolicy(data);
    setIsUpdate(true);
    toggleHandler(2);
    setSingleBudget(true);
  };

  useEffect(() => {
    if (deleteSuccess) {
      setIsDelete(null);
      dispatch(getSingleBudgetPolicies({ budget: budgetId }));
    }
  }, [deleteSuccess]);

  const handleDeleteClick = () => {
    dispatch(
      unlinkBudgetPolicy({ code: isDelete.code, budgetCode: isDelete.budget[0].code }),
    );
  };

  const handlePreviousPage = (page) => {
    dispatch(getSingleBudgetPolicies({ perPage, page, ...filteredQuery }));
  };

  const handleNextPage = (page) => {
    dispatch(getSingleBudgetPolicies({ perPage, page, ...filteredQuery }));
  };

  const handleDeleteModal = ({ policyData }) => {
    setIsDelete(policyData);
  };

  const Actions = (popover) => {
    return (
      <div className="actions-dialog">
        <div
          onClick={() => {
            toggleHandler(2);
            popover();
          }}
          className="actionLink"
        >
          <PlusCircleOutlined /> Create new policy
        </div>
        <div
          onClick={() => {
            toggleHandler(1);
            popover();
          }}
          className="actionLink"
        >
          <LinkOutlined /> Link existing policy
        </div>
      </div>
    );
  };

  const mainData = {
    title: 'Collaborate with team members or employees on budget management.',
    body: 'Add members, manage teams, manage team member roles and access.. See how it works here.',
    image: PeopleImage,
    action: () => (
      <CustomPopover
        zIndex="1"
        showPopover={isButtonToggle}
        clickOutside={handleButtonToggle}
        content={Actions(handleButtonToggle)}
      >
        <CustomButton className="add-button w-auto" onClick={handleButtonToggle}>
          Link or create policy <DownArrow />
        </CustomButton>
      </CustomPopover>
    ),
  };

  // condition table column
  const tableColumn = canManage ? budgetPolicyColumn : beneficiaryPolicyColumn;

  if (loadingPolicy && !filtered) return <Loading isPage color="#D28B28" />;

  const show = !!policies?.length || (filtered && !policies?.length);

  // const handleEditClick = (data) => {
  //   setSelectPolicy(data);
  //   setIsUpdate(true);
  //   toggleHandler();
  // };

  // const handleDeleteModal = ({ policyData }) => {
  //   setIsDelete(policyData);
  // };

  const actionHandler = (event, type, data) => {
    event?.stopPropagation();
    event?.preventDefault();
    if (type === 'edit') handleEditClick(data);
    if (type === 'delete') handleDeleteModal(data);
    setIsPopoverOpen(true);
  };

  const ActionsList = ({ list: selectedData }) => {
    return (
      <div className="actions-dialog">
        {canEdit && (
          <div
            className="actionLink"
            onClick={(event) => actionHandler(event, 'edit', selectedData)}
          >
            <EditIcon stroke="#79716B" width={16} height={16} className="mr-4" /> Edit
            policy
          </div>
        )}

        {canDelete && (
          <div
            className="actionLink svg-danger text-danger"
            onClick={(event) => actionHandler(event, 'delete', selectedData)}
          >
            <DeleteIcon className="mr-4" width={15} height={16} /> Delete policy
          </div>
        )}
      </div>
    );
  };

  const policyList = () => {
    return (
      <>
        {policies.length ? (
          <Container className="p-0">
            <Row className="mt-4">
              <Col xs={12} className="spaced-table">
                <Table
                  columns={tableColumn}
                  data={buildPolicyTableData(policies, true)}
                  pagination
                  hasMore={hasMore}
                  hasCheckBox={false}
                  currentPage={page}
                  nextPage={() => handleNextPage(nextPage)}
                  previousPage={() => handlePreviousPage(page - 1)}
                  totalPage={Math.ceil(total / perPage)}
                  onRowClick={handleRowClick}
                  onDeleteClick={handleDeleteModal}
                  onEditClick={handleEditClick}
                  type="budgets"
                  popoverAction={ActionsList}
                  popoverState={isPopoverOpen}
                  setPopoverState={setIsPopoverOpen}
                  modify={canManage && !isClosed}
                />
              </Col>
            </Row>
          </Container>
        ) : (
          <div>
            {filtered ? (
              <div className="tabinnerWrapper">
                <NoData
                  headerText="You have no policies for this filter"
                  bodyText="Alter filter to see policies"
                  withButton={false}
                />
              </div>
            ) : (
              <div className="mt-5">
                <EmptyState main={mainData} />
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="budgets-wrapper">
      <PendingOnboardingNotice />
      <MigrateOldBudgetInfo />

      <TopBar
        addIconDropDown
        showFilter={show && !isClosed}
        searchVal={search}
        setSearchVal={setSearch}
        inputPlaceholder="Search for policies"
        filterData={filterData}
        withOutSearch
        showBarSearch={show}
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        addIcon={
          canManage && !isClosed && (policies?.length || (filtered && !policies?.length))
        }
        exportLoading={true}
        handleFilterApply={handleFilter}
        actions={Actions}
      />

      {policyList()}

      <PolicyModal
        isOpen={isOpen === 2}
        isUpdate={isUpdate}
        IsOtherPopoverOpen={isOtherPopoverOpen}
        setIsOtherPopoverOpen={setIsOtherPopoverOpen}
        handleClose={handleClose}
        selectPolicy={isUpdate && selectPolicy}
        singleBudget={singleBudget}
        selectedBudget={{
          value: budget?.code,
          label: budget?.name,
        }}
      />

      <ExistingPolicyModal isOpen={isOpen === 1} handleClose={handleClose} />

      <PolicyDetailsModal
        editPolicy={handleEditClick}
        deletePolicy={handleDeleteModal}
        selectPolicy={!isUpdate && selectPolicy}
        setSelectPolicy={setSelectPolicy}
      />

      <Modal show={isDelete} centered dialogClassName="custom-dialog">
        <ConfirmDialog
          title="Remove budget policy"
          subTitle="Are you sure you want to remove this policy? This action cannot be undone."
          onConfirm={handleDeleteClick}
          loading={deleteLoading}
          onCancel={handleDeleteModal}
        />
      </Modal>
    </div>
  );
};

export default BudgetPolicy;
