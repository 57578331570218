import { Tooltip } from 'antd';
import { ChevronDown, ChevronUp } from 'assets/icons';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import AllowedTo, { returnPermissions } from 'utils/AllowedTo';

export const RenderMenu = ({
  lists,
  isSubLink,
  openDropdowns,
  locationArray,
  fileterd,
  toggleDropdown,
  permissions,
}) => {
  const renderMenuItems = (lists, isSubLink) => {
    return lists?.map((item, index) => {
      const checkMatchedLinks = returnPermissions({
        permissions,
        scopes: item?.permissions,
      });

      const uniqueItems = [
        ...new Set(checkMatchedLinks.map((item) => item.split('-')[0])),
      ];

      if (item.links) {
        const findLink = item?.links.find((obj) => uniqueItems.includes(obj.permission));
        item.link = findLink?.link;
      }

      const linkLocation = item?.link?.split('/')[1];

      const Icon = item?.icon;
      const BadgeIcon = item?.badge;

      return (
        <div className="nav-item-wrap" key={index}>
          {item.subLink ? (
            <>
              <AllowedTo scopes={item.permissions}>
                <div
                  className={classNames(
                    'd-flex justify-content-between cursor',
                    'isMenu-not-active',
                    {
                      'isMenu-active':
                        !openDropdowns.includes(index) && fileterd.includes(index),
                    },
                  )}
                  onClick={() => toggleDropdown(index)}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: item?.icon ? '12px' : '6px' }}
                  >
                    <div>{item?.icon && <Icon />}</div>
                    <span>{item.name}</span>
                  </div>
                  {openDropdowns.includes(index) ? (
                    <ChevronUp
                      className="h-4 w-4 text-primary-700"
                      width={16}
                      height={16}
                      color={'#D7D3D0'}
                    />
                  ) : (
                    <ChevronDown
                      className="h-4 w-4 text-primary-700"
                      width={16}
                      height={16}
                      color={'#D7D3D0'}
                    />
                  )}
                </div>
              </AllowedTo>
              {openDropdowns.includes(index) && renderMenuItems(item?.subLink, true)}
            </>
          ) : (
            <>
              {item.permissions ? (
                <AllowedTo scopes={item.permissions}>
                  <NavLink
                    to={item.link}
                    onClick={item?.action}
                    // exact
                    activeClassName={classNames(
                      {
                        ['isMenu-active']: locationArray[1] === linkLocation,
                      },
                      {
                        ['ml-40']: isSubLink,
                      },
                    )}
                    className={classNames(
                      'isMenu-not-active d-flex justify-content-between',
                      {
                        ['isMenu-active']:
                          locationArray[1] === linkLocation && !isSubLink,
                      },
                      {
                        ['ml-40']: isSubLink,
                      },
                    )}
                  >
                    <div>
                      <div
                        className="d-flex align-items-center"
                        style={{ gap: item?.icon ? '12px' : '6px' }}
                      >
                        <div>{item?.icon && <Icon />}</div>
                        <span>{item.name}</span>
                      </div>
                    </div>
                    {BadgeIcon && <BadgeIcon />}
                  </NavLink>
                </AllowedTo>
              ) : (
                <NavLink
                  to={item.link}
                  onClick={item?.action}
                  // exact
                  activeClassName={classNames(
                    {
                      ['isMenu-active']: locationArray[1] === linkLocation,
                    },
                    {
                      ['ml-40']: isSubLink,
                    },
                  )}
                  className={classNames(
                    'isMenu-not-active d-flex justify-content-between',
                    {
                      ['isMenu-active']: locationArray[1] === linkLocation && !isSubLink,
                    },
                    {
                      ['ml-40']: isSubLink,
                    },
                  )}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: item?.icon ? '12px' : '6px' }}
                  >
                    <div>{item?.icon && <Icon />}</div>
                    <span>{item.name}</span>
                  </div>
                  {BadgeIcon && <BadgeIcon />}
                </NavLink>
              )}
            </>
          )}
        </div>
      );
    });
  };

  return renderMenuItems(lists, isSubLink);
};
