import { all, call, delay, put, takeLatest } from 'redux-saga/effects';

import { Axios } from 'api/axios';
import { toastError, toastSuccess } from 'components/UI/toast';
import { GET_BENEFICIARIES_BANK_REQUEST } from 'redux/reducers/BeneficiariesReducer';
import {
  GET_COMPANY_REQUEST,
  GET_COMPANIES_REQUEST,
} from 'redux/reducers/CompaniesReducer';
import { GET_ROLES_REQUEST } from 'redux/reducers/RolesReducer';
import { getSimplifiedError } from 'utils/error';
import {
  encryptAndSaveToLocalStorage,
  retrieveAndDecryptFromLocalStorage,
} from 'utils/utility';
import {
  GET_USER_PROFILE_ERROR,
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  NEW_PASSWORD_ERROR,
  NEW_PASSWORD_REQUEST,
  NEW_PASSWORD_SUCCESS,
  OTP_ERROR,
  OTP_REQUEST,
  OTP_SUCCESS,
  RESEND_VERIFICATION_CODE_ERROR,
  RESEND_VERIFICATION_CODE_REQUEST,
  RESEND_VERIFICATION_CODE_SUCCESS,
  RESET_BLOCK_AUTH,
  RESET_FLAGS_AUTH,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_STATE,
  SET_BENEFICIARY_PASSWORD_ERROR,
  SET_BENEFICIARY_PASSWORD_REQUEST,
  SET_BENEFICIARY_PASSWORD_SUCCESS,
  SET_INVITED_USER_PASSWORD_ERROR,
  SET_INVITED_USER_PASSWORD_REQUEST,
  SET_INVITED_USER_PASSWORD_SUCCESS,
  SIGNUP_ERROR,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  UPDATE_USER_PASSWORD_ERROR,
  UPDATE_USER_PASSWORD_REQUEST,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PROFILE_ERROR,
  UPDATE_USER_PROFILE_REQUEST,
  UPDATE_USER_PROFILE_SUCCESS,
  VERIFY_INVITED_USER_ERROR,
  VERIFY_INVITED_USER_REQUEST,
  VERIFY_INVITED_USER_SUCCESS,
} from '../reducers/AuthReducer';
import { GET_ALL_BANKS_REQUEST } from 'redux/reducers/PaymentReducer';

async function signup(payload) {
  return await Axios.post('/users/register', payload);
}
function* handleSignup({ payload }) {
  try {
    const response = yield call(signup, payload);
    if (response) {
      encryptAndSaveToLocalStorage('hash', response.data.hash);
      yield put({
        type: SIGNUP_SUCCESS,
      });

      yield delay(400);
      yield put({
        type: RESET_FLAGS_AUTH,
        blockType: 'signup',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: SIGNUP_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function login(payload) {
  return await Axios.post('/users/login', payload);
}
function* handleLogin({ payload }) {
  try {
    const response = yield call(login, payload);
    if (response.data) {
      if (response.data.token) {
        const {
          data: { user },
        } = response;

        const { company } = user;
        const currentTime = new Date();
        const setTimeExp = currentTime.setTime(currentTime.getTime() + 58 * 60 * 1000);

        localStorage.removeItem('vendor-app-session');
        const lastLogin = retrieveAndDecryptFromLocalStorage('lastLogin');
        yield put({
          type: RESET_STATE,
        });
        if (response.data.user.email != lastLogin) {
          const authRecall = localStorage.getItem('auth-recall');
          const expAuthRecall = localStorage.getItem('exp-auth-recall');
          localStorage.clear();
          localStorage.setItem('auth-recall', authRecall);
          localStorage.setItem('exp-auth-recall', expAuthRecall);
        }

        encryptAndSaveToLocalStorage('app-session', response.data.token);

        if (response.data.refreshToken)
          encryptAndSaveToLocalStorage('auth-recall', response.data.refreshToken);

        encryptAndSaveToLocalStorage('exp-app-session', setTimeExp);

        if (company?.code) {
          yield put({
            type: GET_ROLES_REQUEST,
          });

          yield put({
            type: GET_BENEFICIARIES_BANK_REQUEST,
          });

          yield put({
            type: GET_COMPANY_REQUEST,
            payload: company?.code,
          });
        }

        yield delay(100);
        yield put({
          type: OTP_SUCCESS,
          data: response.data,
        });
      } else {
        encryptAndSaveToLocalStorage('hash', response.data.data.hash);
        yield put({
          type: LOGIN_SUCCESS,
          data: response.data,
        });
      }
    }
  } catch (error) {
    const errors = getSimplifiedError(error);
    if (errors === 'Please verify your email/phone number') {
      encryptAndSaveToLocalStorage('hash', error.response.data.data.data.hash);
    }

    if (error) {
      toastError(errors);
    }
    yield put({
      type: LOGIN_ERROR,
      error: errors,
    });

    yield delay(400);
    yield put({
      type: RESET_FLAGS_AUTH,
      blockType: 'login',
    });
  }
}

async function get_user() {
  return await Axios.get('/users/');
}
function* get_user_profile() {
  try {
    const response = yield call(get_user);
    if (response) {
      const {
        data: { user },
      } = response;

      const { company } = user;

      yield put({
        type: GET_USER_PROFILE_SUCCESS,
        data: response,
      });

      if (company?.code) {
        yield put({
          type: GET_ROLES_REQUEST,
        });

        yield put({
          type: GET_COMPANY_REQUEST,
          payload: company?.code,
        });
      }
    }
  } catch (error) {
    yield put({
      type: GET_USER_PROFILE_ERROR,
      error: getSimplifiedError(error),
    });
    if (error) {
      toastError(getSimplifiedError(error));
    }
  }
}

async function verifyOtpApi(payload) {
  return await Axios.post('/users/verify-otp', payload);
}
function* handleVerifyOtp({ payload }) {
  try {
    const response = yield call(verifyOtpApi, payload);
    if (response.data.token) {
      encryptAndSaveToLocalStorage('app-session', response.data.token);
      if (response.data.refreshToken)
        encryptAndSaveToLocalStorage('auth-recall', response.data.refreshToken);

      const {
        data: { user },
      } = response;

      const { company } = user;
      const currentTime = new Date();
      const setTimeExp = currentTime.setTime(currentTime.getTime() + 58 * 60 * 1000);
      encryptAndSaveToLocalStorage('exp-app-session', setTimeExp);

      yield put({
        type: RESET_STATE,
      });

      if (company?.code) {
        yield put({
          type: GET_ROLES_REQUEST,
        });

        yield put({
          type: GET_BENEFICIARIES_BANK_REQUEST,
        });

        yield put({
          type: GET_ALL_BANKS_REQUEST,
        });

        yield put({
          type: GET_COMPANY_REQUEST,
          payload: company?.code,
        });
      }

      yield delay(100);
      yield put({
        type: OTP_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: OTP_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function resetPasswordApi(payload) {
  return await Axios.post('/users/forgot-password', payload);
}
function* handleResetPassword({ payload }) {
  try {
    const response = yield call(resetPasswordApi, payload);
    if (response) {
      yield put({
        type: RESET_PASSWORD_SUCCESS,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: RESET_PASSWORD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function newPasswordApi(payload) {
  return await Axios.post('/users/reset-password', payload);
}
function* handleNewPassword({ payload }) {
  try {
    const response = yield call(newPasswordApi, payload);
    if (response) {
      yield put({
        type: NEW_PASSWORD_SUCCESS,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: NEW_PASSWORD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateUserPassword(payload) {
  return await Axios.post('/users/reset-password', payload);
}
function* handleUpdateUserPassword({ payload }) {
  try {
    const response = yield call(updateUserPassword, payload);
    if (response) {
      yield put({
        type: UPDATE_USER_PASSWORD_SUCCESS,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: UPDATE_USER_PASSWORD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function resendVerificationCode(payload) {
  return await Axios.post('/users/resend-verification', payload);
}
function* handleResendVerificationCode({ payload }) {
  try {
    const response = yield call(resendVerificationCode, payload);
    if (response) {
      yield put({
        type: RESEND_VERIFICATION_CODE_SUCCESS,
      });
      if (response.data) {
        encryptAndSaveToLocalStorage('hash', response.data.hash);
      }
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: RESEND_VERIFICATION_CODE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updatedUserApi(payload) {
  return await Axios.put('/users', payload);
}
function* handleUpdateUser({ payload }) {
  try {
    const response = yield call(updatedUserApi, payload);
    if (response.data) {
      yield put({
        type: UPDATE_USER_PROFILE_SUCCESS,
        data: response.data,
      });

      yield delay(300);
      yield put({
        type: RESET_BLOCK_AUTH,
        blockType: 'updatedUser',
      });

      yield put({
        type: GET_USER_PROFILE_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: UPDATE_USER_PROFILE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function setBeneficiaryPassword(payload) {
  return await Axios.post('/users/beneficiary-password', payload);
}
function* handleSetBeneficiaryPassword({ payload }) {
  try {
    const response = yield call(setBeneficiaryPassword, payload);
    if (response.data.token) {
      encryptAndSaveToLocalStorage('app-session', response.data.token);

      const currentTime = new Date();
      const setTimeExp = currentTime.setTime(currentTime.getTime() + 58 * 60 * 1000);
      encryptAndSaveToLocalStorage('exp-app-session', setTimeExp);

      yield put({
        type: SET_BENEFICIARY_PASSWORD_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    const errors = getSimplifiedError(error);
    if (error) {
      toastError(errors);
    }
    yield put({
      type: SET_BENEFICIARY_PASSWORD_ERROR,
      error: errors,
    });

    yield delay(400);
    yield put({
      type: RESET_FLAGS_AUTH,
      blockType: 'login',
    });
  }
}

async function verifyInvitedUser(code) {
  return await Axios.get('/users/verify-user-invite?token=' + code);
}
function* handleVerifyInvitedUser({ payload }) {
  try {
    const response = yield call(verifyInvitedUser, payload);
    if (response) {
      yield put({
        type: VERIFY_INVITED_USER_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: VERIFY_INVITED_USER_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function setInvitedUserPassword(payload) {
  return await Axios.post('users/register?registrationToken=' + payload.token, payload);
}
function* handleSetInvitedUserPassword({ payload }) {
  try {
    const response = yield call(setInvitedUserPassword, payload);
    if (response.data.token) {
      encryptAndSaveToLocalStorage('app-session', response.data.token);

      const currentTime = new Date();
      const setTimeExp = currentTime.setTime(currentTime.getTime() + 58 * 60 * 1000);
      encryptAndSaveToLocalStorage('exp-app-session', setTimeExp);

      yield put({
        type: SET_INVITED_USER_PASSWORD_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    const errors = getSimplifiedError(error);
    if (errors === 'Please verify your email/phone number') {
      encryptAndSaveToLocalStorage('hash', error.response.data.data.data.hash);
    }

    if (error) {
      toastError(errors);
    }
    yield put({
      type: SET_INVITED_USER_PASSWORD_ERROR,
      error: errors,
    });

    yield delay(400);
    yield put({
      type: RESET_FLAGS_AUTH,
      blockType: 'login',
    });
  }
}

export default all([
  takeLatest(SIGNUP_REQUEST, handleSignup),
  takeLatest(GET_USER_PROFILE_REQUEST, get_user_profile),
  takeLatest(LOGIN_REQUEST, handleLogin),
  takeLatest(SET_BENEFICIARY_PASSWORD_REQUEST, handleSetBeneficiaryPassword),
  takeLatest(OTP_REQUEST, handleVerifyOtp),
  takeLatest(RESET_PASSWORD_REQUEST, handleResetPassword),
  takeLatest(NEW_PASSWORD_REQUEST, handleNewPassword),
  takeLatest(UPDATE_USER_PASSWORD_REQUEST, handleUpdateUserPassword),
  takeLatest(UPDATE_USER_PROFILE_REQUEST, handleUpdateUser),
  takeLatest(VERIFY_INVITED_USER_REQUEST, handleVerifyInvitedUser),
  takeLatest(SET_INVITED_USER_PASSWORD_REQUEST, handleSetInvitedUserPassword),
  takeLatest(RESEND_VERIFICATION_CODE_REQUEST, handleResendVerificationCode),
]);
