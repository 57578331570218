/* eslint-disable no-console */
import createVitrine from 'assets/images/create-vitrine.png';
import { AuthFormHeader, AuthFormWrapper } from 'components/Auth';
import VitrineSidebar from 'components/Auth/LeftVitrine';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CheckPasswordStrength from 'components/UI/PasswordStrength';
import { toastError } from 'components/UI/toast';
import React, { useEffect, useMemo, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  setBeneficiaryPassword,
  setInvitedUserPasswordAction,
  signup,
  verifyInvitedUserAction,
} from 'redux/actions/AuthAction';
import { getInvitedBeneficiaryDetails } from 'redux/actions/BeneficiariesAction';
import '../style.less';

const Register = () => {
  const leftImage = (
    <>
      <img src={createVitrine} className="signup-vitrine-img" />
    </>
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [validatePassword, setValidatePassword] = useState(false);
  const [hasReferralCode, sethasReferralCode] = useState(false);
  const [userHash, setUserHash] = useState('');
  const [userToken, setUserToken] = useState('');
  const [authData, setAuthData] = React.useState({
    email: '',
    password: '',
    confirm_password: '',
    referral: undefined,
  });

  const {
    signup: { success, loading },
    verifyInvitedUser: { data: invitedUser },
    login: { loading: loadingBeneficiaryLogin },
  } = useSelector(({ auth }) => auth);

  const {
    getInvitedBeneficiaryDetails: { data },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  useEffect(() => {
    if (success) {
      history.push({
        pathname: '/verification',
        state: { authData: authData.email },
      });
    }
  }, [success]);

  const hashID = searchParams.get('hash');
  const tokenID = searchParams.get('token');
  const referralCode = searchParams.get('referralCode');

  useEffect(() => {
    if (hashID) dispatch(getInvitedBeneficiaryDetails(hashID));
    if (tokenID) dispatch(verifyInvitedUserAction(tokenID));
    if (referralCode) {
      setAuthData({ ...authData, referral: referralCode });
      sethasReferralCode(true);
    }
  }, []);

  useMemo(() => {
    if (data) {
      setAuthData({ ...authData, email: data.email });
      setUserHash(hashID);
    }
    if (invitedUser) {
      setUserToken(tokenID);
    }
  }, [data, invitedUser]);

  const handleChange = ({ target: { name, value } }) => {
    setAuthData({ ...authData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!authData.email) return toastError('Please enter your email');
    if (!authData.password) return toastError('Please enter your password');
    if (!authData.confirm_password) return toastError('Please enter confirm password');
    if (!validatePassword) return toastError('Please enter a strong password');
    if (authData.password !== authData.confirm_password) {
      return toastError('Your password does not match');
    }

    if (hashID && userHash) {
      dispatch(
        setBeneficiaryPassword({
          hash: userHash,
          password: authData.password,
          confirm_password: authData.confirm_password,
        }),
      );
      return false;
    } else if (tokenID && userToken) {
      dispatch(
        setInvitedUserPasswordAction({
          token: userHash,
          email: authData.email,
          password: authData.password,
          confirm_password: authData.confirm_password,
          ...(authData.referral && { referral: authData.referral }),
        }),
      );
      return false;
    } else {
      dispatch(signup(authData));
      return false;
    }
  };

  const toggleReferralInput = () => sethasReferralCode(!hasReferralCode);

  const authForm = (
    <AuthFormWrapper>
      <AuthFormHeader
        titlle="Create your account"
        subtitle="Lets help you get started on Bujeti"
      />
      <Form>
        <Row className="mb-2">
          <CustomInput
            label="What is your work email?"
            error="enter valid email"
            placeholder="Enter email"
            value={authData.email}
            type="email"
            required
            name="email"
            onChange={handleChange}
          />
        </Row>
        <Row className="mb-2">
          <CustomInput
            label="Choose a strong password"
            error="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
            placeholder="Enter password"
            type="password"
            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
            value={authData.password}
            onChange={handleChange}
            name="password"
            maxlength={70}
            required
          />
        </Row>

        <Row className="mb-2">
          <CustomInput
            label="Re-enter the same password"
            placeholder="Confirm password"
            type="password"
            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
            onChange={handleChange}
            value={authData.confirm_password}
            name="confirm_password"
            maxlength={70}
            required
          />
        </Row>

        {hasReferralCode && (
          <Row className="mb-2">
            <CustomInput
              label="Enter your referral code"
              placeholder="INSIGHTS17"
              type="text"
              onChange={handleChange}
              value={authData.referral}
              name="referral"
              maxlength={10}
              disabled={!!referralCode}
            />
          </Row>
        )}

        <Row className="mb-2">
          <CheckPasswordStrength
            password={authData.password}
            validated={validatePassword}
            validate={setValidatePassword}
          />
        </Row>

        <h4 className="secondary-action mb-2" onClick={toggleReferralInput}>
          <span className="font-weight-normal">Have a referral code?</span>
        </h4>
        <CustomButton
          type="submit"
          onClick={handleSubmit}
          loading={loading || loadingBeneficiaryLogin}
          disabled={loading || loadingBeneficiaryLogin}
        >
          Create account
        </CustomButton>
      </Form>
    </AuthFormWrapper>
  );

  return (
    <div className="page-wrapper">
      <div className="vitrinesb-col">
        <VitrineSidebar
          message="Few clicks away from starting your journey of smart spending."
          imageComponent={leftImage}
          widthLink
        />
      </div>

      <div className="page-content with-vit">
        <h4 className="secondary-action ms-auto">
          <span className="font-weight-normal"> Already have an account? </span>
          <Link to="/login"> Sign in </Link>
        </h4>
        {authForm}
        {/* {(hashID && data) || (tokenID && invitedUser) ? authForm : errorMsg} */}
      </div>
    </div>
  );
};

export default Register;
