import CustomInput from 'components/UI/CustomInput';
import FileUpload from 'components/UI/FileUpload';
import { useState } from 'react';
import { Row } from 'react-bootstrap';
import '../../styles.scss';

const Partnership = ({
  handleChangeBusinessDoc,
  businessDoc,
  setUploadingFile,
  uploadingFile,
  setDocuments,
  documents,
}) => {
  const [idName, setIdName] = useState('ID Number');
  const [disableIdNumber, setDisableIdNumber] = useState(true);
  const [marginTop, setMarginTop] = useState('0px');

  const showIdNumber = (idType) => {
    if (idType.value === 'ip') {
      setMarginTop('20px');
    } else {
      setMarginTop('0px');
    }
    if (idType.value === 'nin') {
      setIdName(`NIN`);
    } else {
      setIdName(`${idType.label} Number`);
    }
    setDisableIdNumber(false);
  };

  return (
    <>
      <div className="information">Company Information</div>
      <Row className="mb-3">
        <CustomInput
          label="BN Number *"
          placeholder="Enter number"
          type="text"
          name="bnNumber"
          onChange={handleChangeBusinessDoc}
          value={businessDoc?.bnNumber}
          maxLength="11"
        />
      </Row>

      <Row className="mb-3">
        <FileUpload
          label="BN document from CAC *"
          placeholder="Upload certificate (PDF, JPEG, PNG)"
          name="file"
          uploadedFile={documents.bnDocument}
          onChange={({ assetCode }) =>
            setDocuments({ ...documents, bnDocument: assetCode })
          }
          setUploadingFile={setUploadingFile}
          uploadingFile={uploadingFile}
        />
      </Row>
      <Row className="mb-3">
        <FileUpload
          label="CAC-BN1 *"
          placeholder="Upload document (PDF, JPEG, PNG)"
          name="file"
          uploadedFile={documents.cacBn1}
          onChange={({ assetCode }) => setDocuments({ ...documents, cacBn1: assetCode })}
          setUploadingFile={setUploadingFile}
          uploadingFile={uploadingFile}
        />
      </Row>
    </>
  );
};
export default Partnership;
