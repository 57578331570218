import { useState } from 'react';

import TransactionImage from 'assets/images/emptystate/transaction-image.png';
import EmptyState from 'components/UI/EmptyState';

import CustomButton from 'components/UI/CustomButton';
import CustomPopover from 'components/UI/Popover';
import AllowedTo, { allPermissions, hasPermission } from 'utils/AllowedTo';

import {
  ApprovalRule,
  SchedulePayment,
  SingleTransaction,
} from 'assets/icons/empty-state-icons';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const CategoryEmptyState = ({ parentCode = null, type = 'category' }) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const [isButtonToggle, setIsButtonToggle] = useState(false);

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  const isSchedule = () => {
    localStorage.setItem('schedule', 'on');
  };
  const { permissions } = allPermissions();
  const canCreateTransaction = hasPermission({
    permissions,
    scopes: ['transaction-*', 'transaction-create'],
  });

  const handleSupport = () => {
    window.open('https://www.bujeti.com/bank-payments', '_blank');
  };

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() => history.push('/compliances/categories/create')}
        >
          Create a category
        </div>
        {/* <div className="actionLink">Create multiple</div> */}
        <div className="actionLink">Upload CSV</div>
      </div>
    );
  };

  const childrenData = [
    {
      title: 'Make Single or Batch Payments',
      body: 'Initiate individual or grouped payments to vendors, suppliers, or colleagues.',
      actionTitle: 'View more',
      action: handleSupport,
      icon: <SingleTransaction />,
    },

    {
      title: 'Schedule a Payment',
      body: 'Plan and schedule payments in advance to ensure timeliness and avoid likely penalties.',
      actionTitle: 'View more',
      action: handleSupport,
      icon: <SchedulePayment />,
    },
    {
      title: 'Create Approval Rules',
      body: "Maintain control and compliance with your organization's policies and guidelines.",
      actionTitle: 'View more',
      action: handleSupport,
      icon: <ApprovalRule />,
    },
  ];

  const mainData = {
    title: 'Review and analyse individual financial transactions with ease.',
    body: 'Manage your financial transactions, make single and batch payments, and set approval rules. See how it works here',
    image: TransactionImage,
    action: () =>
      canCreateTransaction &&
      (type === 'category' ? (
        <AllowedTo scopes={['transaction-*', 'transaction-create']}>
          <CustomPopover
            zIndex="1"
            showPopover={isButtonToggle}
            clickOutside={handleButtonToggle}
            content={<Actions />}
          >
            <CustomButton className="empty-add-button" onClick={handleButtonToggle}>
              Create a {type}
            </CustomButton>
          </CustomPopover>
        </AllowedTo>
      ) : (
        <button
          className="add-action black-button"
          onClick={() => {
            history.push({
              pathname: '/compliances/categories/subcategories/create',
              state: { parentCode },
            });
          }}
        >
          Create a {type}
        </button>
      )),
  };

  return <EmptyState main={mainData} childrenData={childrenData} />;
};

export default CategoryEmptyState;
