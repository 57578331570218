import { CloseOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import { ReactComponent as LeftIcon } from 'assets/icons/arrow-left.svg';
import cs from 'classnames';
import AccountName from 'components/UI/AccountName';
import CustomButton from 'components/UI/CustomButton';
import CustomDatePicker from 'components/UI/CustomDatePicker';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import CategoryDropdown from 'components/UI/CustomSelect/CategoryDropdown';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import CustomTextarea from 'components/UI/CustomTextarea';
import FileUpload from 'components/UI/FileUpload';
import Modal from 'components/UI/Modal';
import Saving from 'components/UI/ModalSaving/Saving';
import Success from 'components/UI/ModalSaving/Success';
import LowBalanceUi from 'components/UI/low-balance-ui';
import { toastError } from 'components/UI/toast';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import { useDebounce } from 'hooks/useDebounce';
import useTextCounter from 'hooks/useTextCounter';
import numeral from 'numeral';
import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { searchRecipients } from 'redux/actions/BeneficiariesAction';
import { getBalances } from 'redux/actions/BudgetsAction';
import { scanFile } from 'redux/actions/InvoiceAction';
import { getAllBanks, logExpense, verifyBankAccount } from 'redux/actions/PaymentAction';
import { toggleExpenseAction } from 'redux/actions/ToggleExpenseAction';
import {
  getScheduledTransactions,
  getTransaction,
} from 'redux/actions/TransactionsAction';
import { RESET_BLOCK_PAYMENTS } from 'redux/reducers/PaymentReducer';
import { MAXLENGTH } from 'utils';
import { Banks } from 'utils/banks';
import {
  getAvailableBalance,
  getCurrency,
  groupSourceOptions,
  recurrencePaymentOption,
} from 'utils/helper';
import { calculateFees } from '../../redux/actions/FeesAction';
import Loading from '../UI/Loading/index';
import ItemsDetails from './ItemsDetails';
import './styles.scss';

const MAX_LENGTH = MAXLENGTH?.singlePaymentDescription;

const initailState = {
  budget: { value: undefined, label: 'Select a source' },
  currency: '',
  amount: '',
  recipientName: '',
  bank: '',
  accountNumber: '',
  receipt: undefined,
  description: undefined,
  accountName: undefined,
  vendorAddress: undefined,
  startDate: undefined,
  repeat: '',
  endDate: undefined,
  tax: 0,
  fees: 0,
  start_date: '',
};
const createOption = (label) => ({
  label,
  value: label,
});
const ExpenseModal = ({ selectedBudget, setSelectedBudget }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const {
    getBalances: { data: balances, loading: loadingBalances },
  } = useSelector(({ budgets }) => budgets);

  const {
    calculateFees: { data: feesData, loading: isFeesLoading, success: isFeesSuccess },
  } = useSelector(({ fees }) => fees);

  const { toggleExpense, payload: dataAction = {} } = useSelector(
    ({ toggleExpense }) => toggleExpense,
  );
  const { payload: selectedAccount } = dataAction || {};
  const {
    searchRecipients: { data: searchRecipient = [] },
  } = useSelector(({ beneficiaries }) => beneficiaries);
  const {
    createPayment: { success, loading },
    verifyBankAccount: {
      data: accName,
      loading: accountNameLoading,
      success: accountNameSuccess,
      error: accountNameError,
    },
    getAllBanks: {
      data: banksData,
      loading: loadingBanks,
      success: successBanks,
      error: errorBanks,
    },
  } = useSelector(({ payments }) => payments);

  const [notifyRecipient, setNotifyRecipient] = useState({
    checked: false,
    email: undefined,
  });
  const [payModal, setPayModal] = useState(false);
  const [options, setOptions] = useState([]);
  const [paymentData, setPaymentData] = useState({
    ...initailState,
    ...(selectedAccount && {
      budget: {
        label: selectedAccount.name || selectedAccount.accountName,
        value: selectedAccount.code,
        amount: selectedAccount.balance,
        currency: selectedAccount.currency,
      },
    }),
  });
  const [uploadingFile, setUploadingFile] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isAccountSet, setIsAccountSet] = useState(false);
  const [hasAccount, setHasAccount] = useState(false);
  const [receipientName, setReceipientName] = useState();
  const [step, setStep] = useState(1);
  const [createable, setCreateable] = useState({
    isLoading: false,
    options: [],
    value: undefined,
  });

  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, 200);

  // Get All banks starts here

  const [bankValue, setBankValue] = useState('');
  const bankValuedebounced = useDebounce(bankValue, 200);

  const handleGetBankOnChange = (val) => setBankValue(val);

  const mappedBanks = banksData?.map((item) => item.name);

  const allBanks = useMemo(() => {
    return banksData?.map((item) => ({
      label: item.label,
      value: item.bankCode,
    }));
  }, [successBanks, errorBanks, mappedBanks]);

  useEffect(() => {
    dispatch(getAllBanks());
  }, []);

  useEffect(() => {
    const banks = allBanks?.find((option) =>
      option?.label?.toLowerCase().includes(bankValuedebounced?.toLowerCase()),
    );
    if (!banks && bankValuedebounced) {
      dispatch(getAllBanks({ search: bankValuedebounced?.toLowerCase() }));
    }
  }, [bankValuedebounced]);

  // Get All banks ends here

  const handleDescriptionChange = (value) => {
    setPaymentData({
      ...paymentData,
      description: value,
    });
  };

  const { text, charCount, handleCharChange } = useTextCounter(
    paymentData?.description,
    MAX_LENGTH,
    handleDescriptionChange,
  );

  const isAvailableBalance = paymentData.budget?.value?.startsWith('blc_');
  const isAvailableBankAccount = paymentData.budget?.value?.startsWith('bnk_');
  const hasDirectDebit = paymentData.budget?.isDirectDebit;
  const currency = paymentData?.currency;

  useEffect(() => {
    if (selectedBudget) setPaymentData({ ...paymentData, budget: selectedBudget });
    if (selectedAccount)
      setPaymentData({
        ...paymentData,
        budget: {
          label: selectedAccount.name || selectedAccount.accountName,
          value: selectedAccount.code,
          amount: selectedAccount.balance,
          currency: selectedAccount.currency,
        },
      });
  }, [selectedBudget, selectedAccount]);

  //ends here

  const handleSelectChange = (val) => setValue(val);

  // Handle Change After Debounce
  useEffect(() => {
    if (!searchRecipient?.recipients?.length) dispatch(searchRecipients());
  }, []);

  useEffect(() => {
    const searched = searchRecipient?.recipients?.filter(
      ({ status }) => status === 'active',
    );
    const opt = searched?.map(({ name: label, code: value, email }) => ({
      label,
      value,
      email,
    }));

    if (opt) setOptions(opt);
  }, [searchRecipient]);

  useEffect(() => {
    setCreateable({ ...createable, options: options });
  }, [options]);

  // Handle Createable
  const handleCreate = (inputValue) => {
    if (inputValue.length < 2) return toastError('Name is too short');
    setCreateable({ isLoading: true });
    setTimeout(() => {
      const { options } = createable;
      const newOption = createOption(inputValue);
      setCreateable({
        value: newOption,
        options: [...options, newOption],
        isLoading: false,
      });
      setPaymentData({
        ...paymentData,
        recipientName: newOption,
        accountName: undefined,
        accountNumber: '',
        bank: '',
      });
      setHasAccount(false);
    }, 1000);
  };

  const removeIsSchedule = () => {
    localStorage.removeItem('schedule');
  };

  const getIsScheduled = localStorage.getItem('schedule');

  //goback
  const goBack = () => {
    setStep(step - 1);
  };

  // Toggle action
  const payToggleHandler = () => {
    if (step === 2 && !isLoaded) return goBack();
    if (selectedBudget) {
      setSelectedBudget('');
    } else {
      dispatch(toggleExpenseAction());
      removeIsSchedule();
    }

    dispatch({ type: RESET_BLOCK_PAYMENTS, blockType: 'verifyBankAccount' });
    dispatch({ type: RESET_BLOCK_PAYMENTS, blockType: 'createPayment' });
    setIsLoaded(false);
    setPaymentData({
      ...initailState,
      ...(selectedAccount && {
        budget: {
          label: selectedAccount.name || selectedAccount.accountName,
          value: selectedAccount.code,
          amount: selectedAccount.balance,
          currency: selectedAccount.currency,
        },
      }),
    });
    setCreateable({ ...createable, value: undefined });
    if (success) dispatch(getBalances());
  };

  useEffect(() => {
    if (selectedBudget) {
      setPayModal(!!selectedBudget);
    } else {
      setPayModal(toggleExpense);
    }
    if (selectedAccount)
      setPaymentData({
        ...paymentData,
        budget: {
          label: selectedAccount.name || selectedAccount.accountName,
          value: selectedAccount.code,
          amount: selectedAccount.balance,
          currency: selectedAccount.currency,
        },
      });
  }, [toggleExpense, selectedBudget, selectedAccount]);
  //

  useEffect(() => {
    if (selectedAccount) {
      setPaymentData({
        ...paymentData,
        budget: {
          label: selectedAccount.name || selectedAccount.accountName,
          value: selectedAccount.code,
          amount: selectedAccount.balance,
          currency: selectedAccount.currency,
        },
      });
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (success) {
      setIsLoaded(true);
      setPaymentData({
        ...initailState,
        ...(selectedAccount && {
          budget: {
            label: selectedAccount.name || selectedAccount.accountName,
            value: selectedAccount.code,
            amount: selectedAccount.balance,
            currency: selectedAccount.currency,
          },
        }),
      });
      setCreateable({ ...createable, value: undefined });
      dispatch(getTransaction());
      dispatch(getScheduledTransactions());
    }
  }, [success]);

  // handle input state changes
  const handleChange = ({ target: { name, value, validity, rawValue } }) => {
    if (name === 'accountNumber')
      return validity.valid && setPaymentData({ ...paymentData, [name]: value });
    return setPaymentData({ ...paymentData, [name]: rawValue ? rawValue : value });
  };
  //

  const handleSelect = (value) => {
    if (!value)
      return (
        setCreateable({ ...createable, value: undefined }),
        setPaymentData({
          ...paymentData,
          bank: '',
          recipientName: value,
          accountNumber: '',
        })
      );
    const filterBeneficiary = searchRecipient?.recipients?.filter(
      ({ code }) => code === value.value,
    );

    if (filterBeneficiary && filterBeneficiary[0]?.bankAccount?.bankCode) {
      const { bankCode, number, accountName } = filterBeneficiary[0]?.bankAccount;
      const [bank] = Banks.filter(({ value }) => value === bankCode);
      const { label = '' } = bank || {};
      setPaymentData({
        ...paymentData,
        recipientName: value,
        accountNumber: number,
        accountName,
        bank: { value: bankCode, label },
      });
      setCreateable({ ...createable, value });
      setHasAccount(true);
    } else {
      setPaymentData({
        ...paymentData,
        bank: '',
        accountName: undefined,
        recipientName: value,
        accountNumber: '',
      });
      setCreateable({ ...createable, value });
      setHasAccount(false);
    }
  };

  // set vendor address
  useEffect(() => {
    if (paymentData.recipientName?.value) {
      const { recipientName } = paymentData;
      const vendorAddress = searchRecipient?.recipients?.filter(
        ({ code, accountType }) =>
          code === recipientName?.value && accountType === 'vendor',
      );

      if (vendorAddress) {
        const { street = undefined, state = undefined } = vendorAddress[0]?.address || {};
        setPaymentData({
          ...paymentData,
          vendorAddress: street && `${street}, ${state}.`,
        });
      }
    }
  }, [paymentData.recipientName]);

  useEffect(() => {
    if (!balances?.budgets?.length) dispatch(getBalances());
  }, []);

  const paymentAmount = Number(paymentData.amount) * 100;

  const checkBalance = paymentData.budget.amount * 100 - paymentAmount < 0;

  const isNotOneTime =
    paymentData.repeat.value && paymentData.repeat.value !== 'one-time';

  // handle next step
  const handleNextStep = () => {
    if (!paymentData?.budget?.value) return toastError('Please select a source of funds');
    if (!paymentData.amount) return toastError('Please enter an amount');
    if (+paymentData.amount <= 0) return toastError('Please enter a valid amount');
    if (!paymentData.recipientName.label)
      return toastError('Please enter recipient name');
    if (!paymentData.bank) return toastError('Please select a bank');
    if (!paymentData.accountNumber) return toastError('Please enter account number');
    if (paymentData.accountNumber.length < 10)
      return toastError('Please enter a valid account number');

    if (checkBalance && !paymentData.schedule && !hasDirectDebit) {
      if (!isAvailableBalance)
        return toastError('Budget balance is low please topup or change the budget');
      return toastError('Available balance is low please topup or use a budget');
    }

    if (paymentData.schedule) {
      if (!paymentData.startDate) return toastError('Please enter a start date');
    }

    const data = {
      amount: paymentData?.amount * 100,
      currency: paymentData?.currency,
    };
    dispatch(calculateFees(data));
    setStep(2);
  };
  // handle submit
  const handleSubmit = () => {
    if (!paymentData.description) return toastError('Please enter a description');

    const startDateObject = dayjs(paymentData.startDate);

    const budget =
      isAvailableBalance || isAvailableBankAccount ? undefined : paymentData.budget.value;

    const payload = {
      amount: Number(paymentAmount.toFixed(2)),
      currency: paymentData.currency,
      budget,
      balance: !budget && isAvailableBalance ? paymentData.budget.value : undefined,
      recipient:
        paymentData.accountName && hasAccount
          ? paymentData.recipientName.value
          : paymentData.accountName,
      bank_account: {
        bankName: paymentData.bank.label,
        bankCode: paymentData.bank.value,
        accountName: paymentData.accountName ?? paymentData.recipientName.label,
        number: paymentData.accountNumber,
        currency: paymentData.currency,
      },
      description: paymentData.description,
      receipt: paymentData.receipt,
    };

    if (expenseCategories.value) {
      payload.category = expenseCategories.value;
    }
    dispatch(logExpense(payload));
  };
  //

  const [loadPage, setLoadPage] = useState(0);

  useEffect(() => {
    if (loadPage > 1) dispatch(getBalances({ page: loadPage }));
  }, [loadPage]);

  const [hasMore, setHasMore] = useState(false);
  const [budgetList, setBudgetList] = useState([]);

  useEffect(() => {
    if (balances?.budgets?.length || balances?.balances?.length) {
      const budget = getAvailableBalance(balances?.budgets, 'budgets');
      const available_balance = getAvailableBalance(balances?.balances, 'balance');
      setBudgetList((prevOptions) => [...available_balance].concat([...budget]));
    }
  }, [balances?.budgets?.length, balances?.balances?.length]);

  async function loadOptions(search, loadedOptions, { page }) {
    setLoadPage(page);

    let filteredOption = budgetList.filter((item) =>
      item?.name?.toLowerCase().includes(search?.toLowerCase()),
    );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  useEffect(() => {
    if (paymentData.accountNumber.length === 10 && paymentData.bank.value) {
      const { accountNumber, bank } = paymentData;
      dispatch(verifyBankAccount({ accountNumber, bankCode: bank.value }));
    }
    if (paymentData.accountNumber.length < 10) {
      setIsAccountSet(false);
      setPaymentData({ ...paymentData, accountName: undefined });
    }
  }, [paymentData.accountNumber, paymentData.bank.value]);

  useEffect(() => {
    if (accountNameSuccess) {
      setPaymentData({ ...paymentData, accountName: accName.account_name });
      setCreateable({
        ...createable,
        value: { value: accName.account_name, label: accName.account_name },
      });
      setIsAccountSet(true);
    }
    if (accountNameError) setPaymentData({ ...paymentData, accountName: undefined });
    if (accountNameLoading) setPaymentData({ ...paymentData, accountName: undefined });
  }, [accountNameSuccess, accountNameError, accountNameLoading]);

  useEffect(() => {
    if (receipientName) {
      setCreateable({
        ...createable,
        value: { value: receipientName, label: receipientName },
      });
      setPaymentData({
        ...paymentData,
        recipientName: {
          value: receipientName,
          label: receipientName,
        },
        accountName: undefined,
      });
      setReceipientName();
    }
  }, [receipientName]);

  useEffect(() => {
    if (paymentData.receipt && paymentData.receipt.length) {
      dispatch(scanFile({ code: paymentData.receipt[0] }));
    }
  }, [paymentData.receipt]);

  // auto set expense category for vendor
  useEffect(() => {
    const getVendorCategory = searchRecipient?.recipients?.find(
      ({ code, accountType }) =>
        code === paymentData.recipientName?.value && accountType == 'vendor',
    );
    if (getVendorCategory?.categories.length === 1) {
      const { code: value, name: label } = getVendorCategory?.categories[0]?.category;
      setPaymentData({ ...paymentData, category: { value, label } });
    }
  }, [paymentData.recipientName?.value]);

  const onToggleRecipient = (event, type) => {
    setNotifyRecipient({
      ...notifyRecipient,
      [type]: event.target.checked,
    });
  };

  useEffect(() => {
    setNotifyRecipient({
      ...notifyRecipient,
      checked: !!paymentData.recipientName?.email,
    });
  }, [paymentData.recipientName?.email]);

  const visible = accountNameLoading || accountNameError;

  const handleRoute = () => {
    const route =
      isAvailableBankAccount || isAvailableBalance
        ? `/accounts/${paymentData.budget.value}`
        : `/expenses/budgets/${paymentData.budget.value}/overview`;

    push({
      pathname: route,
      state: { fund: true },
    });
    dispatch(toggleExpenseAction());
  };

  const formatOptionLabel = (props) => {
    const { value, label, details, isDisabled } = props;
    return (
      <div className={cs('format-recurrence-option')}>
        <h1 className={cs({ ['text-muted']: isDisabled })}>{label}</h1>
        <span className={cs({ ['text-muted']: isDisabled })}>{details}</span>
      </div>
    );
  };

  useEffect(() => {
    if (paymentData.startDate)
      setPaymentData({
        ...paymentData,
        repeat: recurrencePaymentOption(paymentData.startDate)[0],
      });
  }, [paymentData.startDate]);

  const budgetCard = () => (
    <div className="information-wrapper">
      <h2 className="card-title w-100">Log an expense</h2>
      <div className="form-normal">
        {paymentData.budget.amount === 0 && !hasDirectDebit && (
          <LowBalanceUi
            currency={paymentData.currency}
            amount={paymentData.budget.amount}
            isBalanceLow={true}
            route={handleRoute}
          />
        )}

        <Row className="mb-4">
          <FileUpload
            label="Upload the invoice(s) or receipt(s)"
            placeholder="Upload certificate (PDF, JPEG, PNG)"
            name="file"
            onChange={(value) =>
              setPaymentData({
                ...paymentData,
                receipt: value?.map((item) => item.assetCode),
              })
            }
            setUploadingFile={setUploadingFile}
            multiple
          />
        </Row>

        <Row className="mb-3 mt-3 align-items-center">
          <CustomSelectRadio
            label="Where have you paid from?"
            name="budget"
            placeholder="Select a source"
            onChange={(val) => setPaymentData({ ...paymentData, budget: val })}
            value={paymentData.budget}
            isLoading={loadingBalances}
            isDisabled={!!selectedBudget || !!selectedAccount || loadingBalances}
            loadOptions={loadOptions}
          />
        </Row>

        <Row className="mb-3 my-2 align-items-center">
          <Col className="col-sm-6">
            <CustomInput
              label="Amount"
              isAmount
              placeholder="100,000.00"
              type="text"
              name="amount"
              className="amount"
              onChange={handleChange}
              value={paymentData.amount}
              getTypeVal={(val) => setPaymentData({ ...paymentData, currency: val })}
            />
          </Col>
          <Col className="col-sm-6">
            <CustomDatePicker
              label="Payment date"
              placeholderText="Select date"
              peekNextMonth
              onChange={(value) => {
                setPaymentData({
                  ...paymentData,
                  start_date: format(value, 'yyyy-MM-dd'),
                });
              }}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              name="start_date"
              selected={
                paymentData?.start_date && dayjs(paymentData?.start_date).toDate()
              }
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Checkbox
            onChange={(event) => onToggleRecipient(event, 'checked')}
            // defaultChecked={!!paymentData.recipientName.email}
            checked={notifyRecipient?.checked}
          >
            Include taxes and fees
          </Checkbox>
          {notifyRecipient.checked && (
            <Row>
              <Col className="col-sm-6">
                <CustomInput
                  label="Tax"
                  isAmount
                  placeholder="2200.00"
                  type="text"
                  name="tax"
                  className="amount"
                  onChange={handleChange}
                  value={paymentData.tax}
                  getTypeVal={(val) => setPaymentData({ ...paymentData, currency: val })}
                />
              </Col>
              <Col className="col-sm-6">
                <CustomInput
                  label="Fees(transfer, shipping)"
                  isAmount
                  placeholder="1000.00"
                  type="text"
                  name="fees"
                  className="amount"
                  onChange={handleChange}
                  value={paymentData.fees}
                  getTypeVal={(val) => setPaymentData({ ...paymentData, currency: val })}
                />
              </Col>
            </Row>
          )}
        </Row>

        <Row className="mb-3">
          <CustomSelect
            label="Who have you paid?"
            name="recipientName"
            placeholder="Type their name"
            noOptionsMessage={() => null}
            isDisabled={createable.isLoading}
            isLoading={createable.isLoading}
            options={createable.options}
            value={createable.value}
            onCreateOption={handleCreate}
            onInputChange={handleSelectChange}
            onChange={(val) => handleSelect(val)}
            createable={true}
          />
        </Row>
        {createable.value && !hasAccount && (
          <>
            <Row className="mb-3">
              <CustomSelect
                label="What is their bank account?"
                name="bankName"
                placeholder="Select a bank"
                onChange={(val) => setPaymentData({ ...paymentData, bank: val })}
                value={paymentData.bank}
                options={allBanks}
                onInputChange={handleGetBankOnChange}
                isDisabled={loadingBanks && !bankValuedebounced}
                isLoading={loadingBanks && !bankValuedebounced}
              />
            </Row>

            <Row className="mb-3">
              <div className={cs('relative', { ['mb-5']: visible })}>
                <CustomInput
                  type="text"
                  label="What is their account number?"
                  placeholder="Enter the number"
                  name="accountNumber"
                  onChange={handleChange}
                  value={paymentData.accountNumber}
                  maxLength="10"
                  pattern="[0-9]*"
                />
                <AccountName
                  // setValue={setReceipientName}
                  visible={visible}
                  accountNameError={accountNameError}
                  accountNameLoading={accountNameLoading}
                />
              </div>
            </Row>
          </>
        )}

        <Row className="mb-3">
          <CategoryDropdown
            label="What are you paying for?"
            placeholder="Select a category"
            onChange={(value) => handleChange({ target: { value, name: 'category' } })}
            value={paymentData.category}
            name="category"
          />
        </Row>

        <CustomButton
          onClick={handleNextStep}
          fullWidth={true}
          disabled={loading || uploadingFile || !isAccountSet}
        >
          Review details
        </CustomButton>
      </div>
    </div>
  );

  const PreviewCard = () => {
    return (
      <div className="information-wrapper">
        <h2 className="card-title w-100 mb-4">Review your expense details</h2>
        <div className="form-normal">
          <div className="preview-card__transfer mb-3">
            <div className="px-4 pt-1 pb-2 w-100">
              <ItemsDetails
                className="mb-1"
                title={<span className="header__transfer">Payment details</span>}
                value={
                  <span
                    className="edit-action__transfer cursor"
                    onClick={payToggleHandler}
                  >
                    Edit
                  </span>
                }
              />

              <ItemsDetails
                title="Total amount we'll send"
                value={`${getCurrency(currency)}${numeral(paymentData.amount).format(
                  '0,0.00',
                )}`}
              />
              {isFeesLoading && <Loading color="#D28B28" size={18} />}
              {!isFeesLoading && feesData && (
                <>
                  <ItemsDetails
                    title="Transaction fees"
                    value={
                      <CurrencyFormat
                        prefix={!feesData.fee ? '' : getCurrency(currency)}
                        value={!feesData.fee ? 'Free' : feesData.fee / 100}
                        displayType="text"
                        isNumericString
                        thousandSeparator={true}
                      />
                    }
                  />

                  {!feesData.fee ? null : (
                    <ItemsDetails
                      className="mt-1"
                      title="Amount"
                      value={
                        <span className="fs-5 text-dark">
                          <CurrencyFormat
                            prefix={getCurrency(currency)}
                            value={Number(
                              feesData.fee / 100 + Number(paymentData.amount),
                            ).toFixed(2)}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </span>
                      }
                    />
                  )}
                </>
              )}

              {paymentData.vendorAddress && (
                <>
                  <hr style={{ backgroundColor: '#EFF0ED' }} />
                  <ItemsDetails
                    className="mb-1"
                    title={<span className="header__transfer">Account Summary</span>}
                    value={
                      <span
                        className="edit-action__transfer cursor"
                        onClick={payToggleHandler}
                      >
                        Change
                      </span>
                    }
                  />
                  <ItemsDetails
                    title="Company"
                    value={paymentData.recipientName?.label}
                  />
                  <ItemsDetails title="Address" value={paymentData.vendorAddress} />
                </>
              )}

              <hr />
              <ItemsDetails
                className="mb-1"
                title={<span className="header__transfer">Recipient details</span>}
                value={
                  <span
                    className="edit-action__transfer cursor"
                    onClick={payToggleHandler}
                  >
                    Change
                  </span>
                }
              />
              <ItemsDetails
                title="Name"
                value={paymentData.accountName || paymentData?.recipientName?.label}
              />
              <ItemsDetails title="Bank name" value={paymentData.bank.label} />
              <ItemsDetails title="Account number" value={paymentData.accountNumber} />
              {paymentData.schedule && (
                <>
                  <hr />
                  <ItemsDetails
                    className="mb-1"
                    title={<span className="header__transfer">Schedule details</span>}
                    value={
                      <span
                        className="edit-action__transfer cursor"
                        onClick={payToggleHandler}
                      >
                        Edit
                      </span>
                    }
                  />
                  <ItemsDetails
                    title="Date"
                    value={dayjs(paymentData.startDate).format('MMMM DD, YYYY, HH:mm:ss')}
                  />
                  <ItemsDetails title="Repeats" value={paymentData.repeat.details} />
                  {isNotOneTime && (
                    <ItemsDetails
                      title="End date"
                      value={dayjs(paymentData.endDate).format('MMMM DD, YYYY, HH:mm:ss')}
                    />
                  )}
                </>
              )}
              <hr />
              <ItemsDetails
                className="mb-1"
                title={<span className="header__transfer">Other</span>}
                value={
                  <span
                    className="edit-action__transfer cursor"
                    onClick={payToggleHandler}
                  >
                    Edit
                  </span>
                }
              />
              {!isAvailableBalance && (
                <ItemsDetails title="Budget" value={paymentData?.budget?.name} />
              )}
              {isAvailableBalance && (
                <ItemsDetails title="Account" value={paymentData?.budget?.label} />
              )}
              <ItemsDetails
                title="Expense category"
                value={paymentData?.expenseCategory}
              />
            </div>
          </div>

          <div>
            <Row className="mb-3">
              <CustomTextarea
                rowSize={3}
                label="Description *"
                name="description"
                onChange={handleCharChange}
                value={text || paymentData.description}
                placeholder="Description"
                maxLength={MAX_LENGTH}
                showCounter={true}
                charCount={charCount}
              />
            </Row>

            <Row className="mb-4">
              <FileUpload
                label="Receipt"
                placeholder="Upload certificate (PDF, JPEG, PNG)"
                name="file"
                onChange={(value) =>
                  setPaymentData({
                    ...paymentData,
                    receipt: value?.map((item) => item.assetCode),
                  })
                }
                setUploadingFile={setUploadingFile}
                multiple
              />
            </Row>
          </div>

          <CustomButton onClick={handleSubmit} disabled={uploadingFile} fullWidth={true}>
            Confirm and {paymentData.schedule ? 'schedule' : 'pay'}
          </CustomButton>
          <CustomButton withoutBg fullWidth className="mt-3" onClick={() => setStep(1)}>
            Cancel
          </CustomButton>
        </div>
      </div>
    );
  };

  const PageView = () => {
    if (step > 1) return PreviewCard();
    return budgetCard();
  };

  const msg = paymentData.schedule
    ? 'Your payment has been scheduled and will be processed when due...'
    : 'Your payment is being processed...';
  return (
    <>
      <Modal show={payModal}>
        <div className="content">
          <div className="card-modal-header">
            <div className="d-flex align-items-center cursor" onClick={payToggleHandler}>
              {step === 1 || isLoaded ? (
                <CloseOutlined />
              ) : (
                <LeftIcon className="cursor" onClick={payToggleHandler} />
              )}
              <span className="ps-1">{step === 1 || isLoaded ? 'Close' : 'Back'}</span>
            </div>
          </div>
          <div className="card-modal-body">
            {loading ? (
              <Saving />
            ) : isLoaded ? (
              <Success title="Thank you!" message={msg} />
            ) : (
              PageView()
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ExpenseModal;
