import { useEffect, useRef, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'hooks/useDebounce';

import { Modal } from 'react-bootstrap';
import TopBar from 'components/TopBar';

import Table from 'components/Table';
import NoData from 'components/NoData';
import Loading from 'components/UI/Loading';
import {
  deleteCustomer,
  fetchAllCustomersAction,
} from '../../redux/actions/InvoiceAction';

import { customerStatusType } from 'components/FilterModal/FilterHelper';
import { customersColumn } from 'utils/mockData';
import { buildCustomersData } from '../../utils/helper';
import CustomerModal from './components/CustomerModal';
import PendingOnboardingNotice from 'pages/Budgets/PendingOnboardingNotice';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { DeleteOutlined } from '@ant-design/icons';

import { RESET_FLAGS_INVOICE } from 'redux/reducers/InvoiceReducer';
import DeleteDialog from 'components/DeleteDialog';
import AddCustomerModal from 'pages/Invoices/components/AddCustomerModal';
import { useHistory, useLocation } from 'react-router-dom';
import CollectionEmptyState from 'pages/Collections/CollectionEmptyState';
import CustomButton from 'components/UI/CustomButton';
import { PlusIcon } from 'assets/icons';
import '../Collections/index.style.scss';
import CustomPopover from 'components/UI/Popover';

const Customers = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;

  const [filterData, setFilterData] = useState([...customerStatusType]);
  const [filteredQuery, setFilteredQuery] = useState({});
  const [selected, setSelected] = useState({
    isModalVisible: false,
    code: null,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(null);
  const [isPopOver, setIsPopOver] = useState(false);
  const { push } = useHistory();

  const {
    customers: { loading, success, data: { meta = {} } = {}, data = {} },
    deleteCustomer: { loading: deleteLoading, success: deleteSuccess },
  } = useSelector(({ invoices }) => invoices);

  const {
    fetchInvoices: { data: {} = {}, data: invoices = {} },
    invoiceCustomers: { data: customers = [], loading: customersLoading },
  } = useSelector(({ invoices }) => invoices);

  const { permissions } = allPermissions();

  const canViewCustomer = hasPermission({
    permissions,
    scopes: ['customer-*', 'customer-view'],
  });

  const canDeleteCustomer = hasPermission({
    permissions,
    scopes: ['customer-*', 'customer-delete'],
  });

  const canCreate = hasPermission({
    permissions,
    scopes: ['customer-*', 'invoice-*', 'customer-create', 'invoice-create'],
  });

  const closeModal = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (canViewCustomer) dispatch(fetchAllCustomersAction());
  }, []);

  const { page, total, hasMore, perPage, nextPage } = meta;

  const [search, setSearch] = useState(null);

  const debouncedValue = useDebounce(search, 300);

  const handleRowClick = (row) => {
    if (row?.status === 'deleted') return;
    history.push(`/receivables/customers/profile/${row?.code}`);
  };

  const clearFilters = () => {
    setFilteredQuery({});
    setFilterData([...customerStatusType]);
    dispatch(fetchAllCustomersAction());
  };

  const handlePopOver = () => setIsPopOver(!isPopOver);

  useEffect(() => {
    if (debouncedValue && canViewCustomer) {
      filteredQuery.search = debouncedValue;
      dispatch(fetchAllCustomersAction({ ...filteredQuery, search: debouncedValue }));
    }
    if (!debouncedValue && filtered && canViewCustomer) {
      delete filteredQuery.search;
      dispatch(fetchAllCustomersAction({ ...filteredQuery }));
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  const onHandleNextPage = (page) => {
    dispatch(fetchAllCustomersAction({ perPage, page, ...filteredQuery }));
  };

  const onHandlePreviousPage = (page) => {
    if (page === 1) return;
    dispatch(fetchAllCustomersAction({ perPage, page, ...filteredQuery }));
  };

  useEffect(() => {
    if (deleteSuccess) {
      setDeleteModal(null);
      dispatch(fetchAllCustomersAction({ ...filteredQuery }));
      dispatch({ type: RESET_FLAGS_INVOICE, blockType: 'deleteCustomer' });
    }
  }, [deleteSuccess]);

  const handleFilter = (query) => {
    const queryPayload = {
      ...query,
      ...(query['type'] ? { type: query['type'][0] } : {}),
    };

    if (query['invoice status']) {
      queryPayload.hasUnpaidInvoice = 'true';
      delete queryPayload['invoice status'];
    }

    if (debouncedValue) {
      queryPayload.search = debouncedValue;
    }
    setFilteredQuery(queryPayload);
    dispatch(fetchAllCustomersAction(queryPayload));
    isFiltered.current = !!Object.keys(query).length;
  };

  const handleCreateInvoice = (value, isRecurring = false) => {
    const { invoice, outstanding, ...rest } = value;
    const name = value?.name?.value?.props?.name;
    const email = value?.email?.value?.props?.name;

    const payload = {
      label: name,
      value: {
        ...rest,
        name,
        email,
      },
    };

    push({
      pathname: '/receivables/invoices/create',
      search: isRecurring ? '?isRecurring=true' : '',
      state: { customerPayload: payload },
    });
  };

  const actionHandler = (event, type, value) => {
    const { code } = value;
    event?.stopPropagation();
    event?.preventDefault();
    setIsPopoverOpen(true);

    if (type === 'edit') {
      onHandleShareClick(value);
    }

    if (type === 'createInvoice') {
      handleCreateInvoice(value);
    }

    if (type === 'createRecurringInvoice') {
      handleCreateInvoice(value, true);
    }

    if (type === 'viewInvoices') {
      push(`/receivables/invoices?customer=${code}`);
    }

    if (type === 'delete') {
      setDeleteModal(code);
    }

    if (type === 'view') {
      history.push(`/receivables/customers/profile/${code}`);
    }
  };

  const openCustomerModal = () => {
    setIsPopOver(false);
  };

  const Actions = ({ list }) => {
    return (
      <div className="actions-dialog">
        {/* <div
          className="actionLink "
          onClick={(event) => actionHandler(event, 'edit', list)}
        >
          <EditOutlined style={{ fontSize: '16px', marginLeft: '-5px' }} /> Edit customer
        </div> */}

        {list?.status === 'active' ? (
          <>
            <div
              className="actionLink"
              style={{ fontWeight: 400, padding: '10px 8px', margin: '0 5px' }}
              onClick={(event) => actionHandler(event, 'createInvoice', list)}
            >
              Create invoice
            </div>

            <div
              className="actionLink"
              style={{ fontWeight: 400, padding: '10px 8px', margin: '0 5px' }}
              onClick={(event) => actionHandler(event, 'createRecurringInvoice', list)}
            >
              Set up recurring invoice
            </div>

            <div
              className="actionLink"
              style={{ fontWeight: 400, padding: '10px 8px', margin: '0 5px' }}
              onClick={(event) => actionHandler(event, 'viewInvoices', list)}
            >
              View invoices
            </div>

            <div style={{ borderTop: '1px solid #E7E5E4' }} className="my-1" />

            {canDeleteCustomer && (
              <div
                className="actionLink text-danger"
                style={{
                  fontWeight: 400,
                  padding: '10px 8px',
                  margin: '0 5px',
                  gap: '0px',
                }}
                onClick={(event) => actionHandler(event, 'delete', list)}
              >
                <DeleteOutlined style={{ fontSize: '16px', marginLeft: '-5px' }} />
                Archive customer
              </div>
            )}
          </>
        ) : (
          <div
            className="actionLink"
            style={{ fontWeight: 400, padding: '10px 8px', margin: '0 5px' }}
            onClick={(event) => actionHandler(event, 'activate', list)}
          >
            Unarchive Customer
          </div>
        )}
      </div>
    );
  };
  const handleRoute = (path, search = '') => {
    history.push({
      pathname: path,
      search: search,
      state: { isNew: true },
    });
  };

  const InvoicePopOver = () => {
    return (
      <div className="actions-dialog invoice">
        <div className="actionLink mx-1" onClick={closeModal}>
          <span>New Customer</span>
        </div>

        <div
          className="actionLink mx-1"
          onClick={() => handleRoute('/receivables/customers/create-multiple')}
        >
          <span> Create multiple customers</span>
        </div>
      </div>
    );
  };

  const handleDelete = () => {
    dispatch(deleteCustomer(deleteModal));
  };

  const show = !!data?.customers?.length || (filtered && !data?.customers?.length);
  if (loading && !filtered) return <Loading isPage={true} color="#d28b28" />;
  return (
    <div className="position-relative">
      <TopBar
        isRightBar
        headerText="Customers"
        subText="Manage your earnings effortlessly. Create, send, and track all invoices you've issued to your customers."
        addButton={!isCreateOpen && canCreate}
        dropDownBtn={
          <CustomPopover
            zIndex="100"
            content={<InvoicePopOver />}
            showPopover={isPopOver}
            clickOutside={handlePopOver}
          >
            <CustomButton className="add-button" onClick={handlePopOver}>
              <PlusIcon stroke="#ffffff" height="14" width="14" />
              <span>Create customer</span>
            </CustomButton>
          </CustomPopover>
        }
      />

      <Container>
        <Row>
          <Col xs={12} className="mt-4">
            <section>
              <PendingOnboardingNotice />

              <div>
                <TopBar
                  showBarSearch={show}
                  withOutSearch
                  inputPlaceholder="Search customers"
                  showFilter={show}
                  filterData={filterData}
                  handleFilterSelect={(updateVal) => {
                    setFilterData(updateVal);
                  }}
                  clearFilters={clearFilters}
                  setSearchVal={setSearch}
                  handleFilterApply={handleFilter}
                  withDate
                />
              </div>

              {!data?.customers?.length && (
                <div>
                  {filtered ? (
                    <div className="tabinnerWrapper">
                      <NoData
                        headerText="You have no customers for this filter"
                        bodyText="There are no customers for this filter, use the button below to view all your available customers"
                        withButton={true}
                        buttonLabel={
                          <span className="d-flex justify-content-center">
                            Show all Customers
                          </span>
                        }
                        onClickHandler={() => dispatch(fetchAllCustomersAction())}
                      />
                    </div>
                  ) : (
                    <CollectionEmptyState
                      onClickHandler={() => history.push('/receivables/invoices/create')}
                    />
                  )}
                </div>
              )}

              {data?.customers?.length ? (
                <>
                  <Container className="px-0 approval-table">
                    <Row className="pt-4 pb-3">
                      <Col xs={12} className="spaced-table">
                        <Table
                          columns={customersColumn}
                          pagination
                          data={buildCustomersData(data?.customers)}
                          onRowClick={handleRowClick}
                          hasMore={hasMore}
                          hasCheckBox={false}
                          currentPage={page}
                          nextPage={() => onHandleNextPage(nextPage)}
                          previousPage={() => onHandlePreviousPage(page - 1)}
                          totalPage={Math.ceil(total / perPage)}
                          popoverAction={Actions}
                          popoverState={isPopoverOpen}
                          setPopoverState={setIsPopoverOpen}
                        />
                      </Col>
                    </Row>
                  </Container>
                </>
              ) : null}
            </section>
          </Col>
        </Row>
      </Container>

      <CustomerModal
        isOpen={selected.isModalVisible}
        toggleHandler={() => setSelected({ ...selected, isModalVisible: false })}
        selected={selected?.code}
      />

      <Modal show={isOpen} centered dialogClassName="custom-dialog">
        <AddCustomerModal isOpen={isOpen} handleClose={closeModal} />
      </Modal>

      <Modal show={!!deleteModal} centered dialogClassName="custom-dialog">
        <DeleteDialog
          title="Archive customer"
          subTitle="Are you sure you want archive this customer?"
          onCancel={() => setDeleteModal(null)}
          onDelete={handleDelete}
          deleteText="Archive"
          isLoading={deleteLoading}
        />
      </Modal>
    </div>
  );
};

export default Customers;
