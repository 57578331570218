import { useContext } from 'react';
import './integrations.styles.scss';

import Loading from 'components/UI/Loading';
import { IntegrationsContext } from 'pages/Settings';
import IntegrationConnectProcess from './components/IntegrationConnectProcess';
import IntegrationOptions from './components/Options';

const Integrations = () => {
  const { active, fetchingApps, apps } = useContext(IntegrationsContext);

  const IntegrationStepSelector = (state) => {
    switch (state) {
      case 'list':
        return <IntegrationOptions />;
      case 'connectionModal':
        return <IntegrationConnectProcess />;
      default:
        return <IntegrationOptions />;
    }
  };

  if (fetchingApps && !apps?.length) return <Loading color="#D28B28" isPage={true} />;
  return (
    <section className="integrations">
      <div className="header">
        <h6 className="base-title">Integrations and connected apps</h6>
        <p>Supercharge your workflow and conect the tool you use every day.</p>
        {IntegrationStepSelector(active)}
      </div>
    </section>
  );
};

export default Integrations;
