import Table from 'components/Table';
import 'jspdf-autotable';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  exportTransaction,
  getSingleTransaction,
} from 'redux/actions/TransactionsAction';
import {
  buildExportTransactionData,
  buildSubAccountTransactionsTableData,
} from 'utils/helper';
import {
  columns,
  columnsTransactionCSV,
  subAccountTransactionsColumns,
} from 'utils/mockData';
// import NoData from './NoData';
import { TransactionLogo } from 'assets/images';
import { SubAccountDirection } from 'components/FilterModal/FilterHelper';
import TopBar from 'components/TopBar';
import Loading from 'components/UI/Loading';
import { jsPDF, PDFDownloadLink } from 'components/UIHooks/JsPDF';
import { useDebounce } from 'hooks/useDebounce';
import NoData from 'pages/Cash/Overview/components/NoData';
import { getSubAccountTransactions } from 'redux/actions/BudgetsAction';
import { getCompany } from 'redux/actions/CompaniesAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import TransactionDrawer from 'components/TransactionModal/TransactionDrawer';
import ReceiptPdf from 'components/ReceiptPdf';
import { DownloadIcon } from 'assets/icons';
import { toastSuccess } from 'components/UI/toast';
// import TableLoading from './TableLoading';

const TransactionTable = ({
  accountCode,
  handleSwapping,
  permissionForOwnersAndManagers,
  pageSource,
  onRowSelect,
  openStatementModal,
}) => {
  const dispatch = useDispatch();

  const {
    exportTransaction: {
      data: { transactions: exportData = [] } = {},
      loading: exportLoading,
    },
    massAssignTransaction: { success: successMassAssign },
    getSingleTransaction: { data: singleTransaction = {} },
  } = useSelector(({ transaction }) => transaction);

  const {
    getSubAccountTransactions: {
      data: { meta = {}, data: transactions = [] } = {},
      loading,
    },
  } = useSelector(({ budgets }) => budgets);

  const { page, total, hasMore, perPage, nextPage } = meta;

  const { user } = useSelector(({ auth }) => auth);

  const { permissions } = allPermissions();

  const canViewTransaction = hasPermission({
    permissions,
    scopes: ['transaction-*', 'transaction-view'],
  });

  const {
    getCompany: { data: companyData = {} },
  } = useSelector(({ companies }) => companies);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectTransaction, setSelectTransaction] = useState(null);
  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;
  const [filteredQuery, setFilteredQuery] = useState({ code: accountCode, pageSource });
  const [filterData, setFilterData] = useState([...SubAccountDirection]);
  const [assets, setAssets] = useState({
    active: {},
    assetsList: [],
    visible: false,
  });
  const [search, setSearch] = useState('');
  const [payerFilter, setPayerFilter] = useState({});
  const debouncedValue = useDebounce(search, 600);

  const canExport = hasPermission({
    permissions,
    scopes: ['statement-export', 'statement-*'],
  });

  useEffect(() => {
    if (debouncedValue) {
      filteredQuery.search = debouncedValue;
      dispatch(getSubAccountTransactions({ ...filteredQuery, search: debouncedValue }));
    }
    if (!debouncedValue && filtered && canViewTransaction) {
      delete filteredQuery.search;
      dispatch(getSubAccountTransactions({ ...filteredQuery }));
      // history.replace({ state: {} });
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  useEffect(() => {
    const companyCode = user?.data?.user?.company?.code;

    if (!companyData?.code && companyCode) {
      dispatch(getCompany(companyCode));
    }
  }, [user]);

  useEffect(() => {
    setFilteredQuery({ code: accountCode, pageSource });
  }, []);

  useEffect(() => {
    if (successMassAssign) {
      dispatch(getSubAccountTransactions({ code: accountCode, pageSource }));
    }
  }, [successMassAssign]);

  useEffect(() => {
    if (transactions.length) {
      const allTransactions = transactions.map((transaction) => {
        const { transaction: relatedTransaction } = transaction;
        return {
          value: relatedTransaction?.payer ? relatedTransaction?.payer?.code : null,
          label: relatedTransaction?.payer
            ? `${relatedTransaction?.payer.firstName} ${relatedTransaction?.payer?.lastName}`
            : null,
          isSelected: false,
        };
      });
      const uniqueArray = allTransactions.filter(
        (initialValue, index, array) =>
          array.findIndex((v2) => v2.value === initialValue.value) === index,
      );

      const newItem = { title: 'Payer', list: uniqueArray.filter((item) => item.label) };

      if (newItem.list.length) {
        setPayerFilter({ ...newItem });

        if (!filterData.some((item) => item.title === newItem.title)) {
          setFilterData([...filterData, newItem]);
        }
      }
    }
  }, [transactions]);

  const rows = useMemo(
    () => buildSubAccountTransactionsTableData(transactions),
    [transactions],
  );

  const generatePdfSuccess = async (event, instance) => {
    event?.stopPropagation();
    if (instance.loading || !instance.url) return;
    toastSuccess('Receipt downloaded');
  };

  const handlePagination = (page) => {
    dispatch(getSubAccountTransactions({ code: accountCode, perPage, page, pageSource }));
  };

  const tableColumn = useMemo(() => subAccountTransactionsColumns, [transactions]);

  const activeTxCode = useRef(null);

  const fetchSingleData = useCallback(
    (data) => {
      const { status = '', code = '' } = data?.transactionData || {};

      if (
        code === activeTxCode.current ||
        !['success', 'declined', 'failed', 'processed'].includes(status)
      )
        return;

      activeTxCode.current = code;
      if (activeTxCode.current) dispatch(getSingleTransaction(code));
    },
    [activeTxCode.current],
  );

  const Actions = useCallback(
    ({ list: selectedData }) => {
      const status = selectedData?.status?.value.toLowerCase();

      useMemo(() => {
        if (selectedData?.transactionData?.code !== activeTxCode.current)
          fetchSingleData({
            transactionData: {
              code: selectedData?.transactionData?.code,
              status,
            },
          });
      }, []);

      return (
        <div className="actions-dialog">
          {['success', 'processed'].includes(status) && (
            <PDFDownloadLink
              document={
                <ReceiptPdf
                  data={{
                    ...selectedData?.transactionData,
                    senderAccount: singleTransaction?.data?.senderAccount,
                    bank_account: singleTransaction?.data?.bank_account,
                    created_at: singleTransaction?.data?.created_at,
                    paidOn: singleTransaction?.data?.paidOn,
                  }}
                  companyData={companyData}
                />
              }
              fileName={`Transaction receipt for ${selectedData?.description}.pdf`}
              style={{
                textDecoration: 'none',
                height: 40,
                display: 'flex',
                width: '100%',
                fontFamily: 'Inter var !important',
                color: '#212529',
                alignItems: 'center',
              }}
              className="actionLink"
              onClick={(event, instance) => generatePdfSuccess(event, instance)}
            >
              <DownloadIcon className="mr-4" width={16} height={16} />
              Download receipt
            </PDFDownloadLink>
          )}
        </div>
      );
    },
    [singleTransaction],
  );

  const csvData = [
    columnsTransactionCSV
      .filter((item) => item.Header !== 'Files')
      .map((item) => item.Header),
    ...buildExportTransactionData(exportData),
  ];

  const exportPDF = () => {
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'portrait'; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const columnStyles = {};
    // const title = 'Transactions Report';
    const headers = [
      columnsTransactionCSV
        .filter((item) => item.Header !== 'Files')
        .map((item) => item.Header),
    ];

    columns.forEach((column, index) => {
      columnStyles[index] = { cellWidth: 60 }; // Adjust the width as needed
    });

    const body = buildExportTransactionData(exportData);

    let content = {
      startY: 80,
      head: headers,
      body,
      columnStyles,
    };

    doc.addImage(TransactionLogo, 'JPEG', marginLeft, 25, 90, 50.5);
    // doc.text(title, 80, 40);
    doc.autoTable(content);
    doc.save('Transaction Statement.pdf');
  };

  const handleFilter = (query) => {
    // const amount = filterData.filter(({ title }) => title === 'Amount');
    // const [min, max] = amount[0]?.value || [];
    // const min_amount = min ? min * 100 : undefined;
    // const max_amount = max ? max * 100 : undefined;
    const queryPayload = {
      ...query,
      code: accountCode,
      pageSource,
      // min_amount,
      // max_amount,
    };

    setFilteredQuery(queryPayload);

    dispatch(getSubAccountTransactions(queryPayload));
    isFiltered.current = !!Object.keys(query).length;
  };

  const handleExport = () => {
    dispatch(
      exportTransaction({ ...filteredQuery, search: debouncedValue ?? undefined }),
    );
  };

  const handleRowClick = (row) => {
    setSelectTransaction(row);
    setAssets({
      ...assets,
      assetsList: row?.transactionData?.receipts,
    });
  };

  const onHandleAssetViewer = () => {
    setAssets({
      ...assets,
      visible: true,
    });
  };

  const clearFilters = () => {
    setFilteredQuery({ code: accountCode, pageSource });
    setFilterData([
      // { title: 'Amount', type: 'slider' },
      ...SubAccountDirection,
      payerFilter,
    ]);
  };

  const show = !!transactions?.length || (filtered && !transactions?.length);

  // if (loading) return <Loading size={45} className={'loading-state'} color="#D28B28" />;

  if (loading && !filtered) return <Loading isPage color="#D28B28" />;

  return (
    <>
      <TopBar
        showFilter={show}
        inputPlaceholder="Search"
        filterData={filterData}
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        searchVal={search}
        setSearchVal={setSearch}
        showBarSearch={show}
        withOutSearch
        // addExport={show}
        exportLoading={exportLoading}
        handleFilterApply={handleFilter}
        // withDate
        dateTitle="Transaction Date"
        handleExport={handleExport}
        csvData={csvData}
        exportPDF={exportPDF}
        clearFilters={clearFilters}
        showRetry={false}
        addIcon={show && canExport}
        customAddButton={
          <button
            style={{
              border: '1px solid #D7D3D0',
              color: '#57534E',
              background: 'transparent',
            }}
            onClick={openStatementModal}
            className="add-button"
          >
            <span style={{ fontWeight: 400 }} className="ms-1">
              Generate statement
            </span>
          </button>
        }
      />
      {!transactions.length ? (
        <div className="tabinnerWrapper">
          <NoData
            headerText="Your transactions will show here."
            buttonLabel="Make an internal transfer"
            bodyText="Find here all your transactions, such as card payments, transfers, direct debits. Start with a first transfer to add funds."
            onClickHandler={permissionForOwnersAndManagers ? handleSwapping : null}
            withButton={permissionForOwnersAndManagers ? true : false}
          />
        </div>
      ) : (
        <Container className="px-0">
          <Row className="pt-4 pb-3">
            <Col xs={12} className="spaced-table">
              <Table
                columns={tableColumn}
                data={rows}
                pagination
                onRowClick={handleRowClick}
                hasCheckBox={onRowSelect ? true : false}
                hasMore={hasMore}
                currentPage={page}
                onRowSelect={onRowSelect}
                nextPage={() => handlePagination(nextPage)}
                previousPage={() => handlePagination(page - 1)}
                totalPage={Math.ceil(total / perPage)}
                popoverAction={Actions}
                popoverState={isPopoverOpen}
                setPopoverState={setIsPopoverOpen}
              />
            </Col>
          </Row>
        </Container>
      )}

      {selectTransaction && (
        <TransactionDrawer
          selectTransaction={selectTransaction}
          setSelectTransaction={setSelectTransaction}
          handlePdfViewer={onHandleAssetViewer}
        />
      )}
    </>
  );
};

export default TransactionTable;
