import React from 'react';
import cs from 'classnames';

const defaultLabels = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];
const CustomPills = ({
  status,
  setStatus,
  labels = defaultLabels,
  height = 32,
  tabClass,
  containerClass,
}) => {
  const handleTab = (newStatus) => {
    if (status === newStatus) setStatus(null);
    else setStatus(newStatus);
  };

  return (
    <div
      className={cs('d-flex align-items-center justify-content-center', {
        [containerClass]: containerClass,
      })}
      style={{ fontSize: 16 }}
    >
      {labels.map(({ label, value, tooltip }, index) => (
        <span
          title={tooltip}
          style={{ height }}
          onClick={() => handleTab(value)}
          key={index}
          className={cs(
            'border p-2 text-center small d-flex align-items-center  justify-content-center cursor tab-hover-effect',
            { ['active']: status === value },
            { ['rounded-start']: index === 0 },
            { ['rounded-end']: index === labels.length - 1 },
            { [tabClass]: tabClass },
          )}
        >
          {label}
        </span>
      ))}
    </div>
  );
};

export default CustomPills;
