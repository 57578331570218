export const FETCH_CATEGORY_REPORT_REQUEST = 'FETCH_CATEGORY_REPORT_REQUEST';
export const FETCH_CATEGORY_REPORT_SUCCESS = 'FETCH_CATEGORY_REPORT_SUCCESS';
export const FETCH_CATEGORY_REPORT_ERROR = 'FETCH_CATEGORY_REPORT_ERROR';

export const FETCH_BUDGET_REPORT_REQUEST = 'FETCH_BUDGET_REPORT_REQUEST';
export const FETCH_BUDGET_REPORT_SUCCESS = 'FETCH_BUDGET_REPORT_SUCCESS';
export const FETCH_BUDGET_REPORT_ERROR = 'FETCH_BUDGET_REPORT_ERROR';

export const FETCH_CARD_REPORT_REQUEST = 'FETCH_CARD_REPORT_REQUEST';
export const FETCH_CARD_REPORT_SUCCESS = 'FETCH_CARD_REPORT_SUCCESS';
export const FETCH_CARD_REPORT_ERROR = 'FETCH_CARD_REPORT_ERROR';

export const FETCH_VENDOR_REPORT_REQUEST = 'FETCH_VENDOR_REPORT_REQUEST';
export const FETCH_VENDOR_REPORT_SUCCESS = 'FETCH_VENDOR_REPORT_SUCCESS';
export const FETCH_VENDOR_REPORT_ERROR = 'FETCH_VENDOR_REPORT_ERROR';

export const FETCH_TEAM_REPORT_REQUEST = 'FETCH_TEAM_REPORT_REQUEST';
export const FETCH_TEAM_REPORT_SUCCESS = 'FETCH_TEAM_REPORT_SUCCESS';
export const FETCH_TEAM_REPORT_ERROR = 'FETCH_TEAM_REPORT_ERROR';

export const FETCH_CASH_FLOW_REPORT_REQUEST = 'FETCH_CASH_FLOW_REPORT_REQUEST';
export const FETCH_CASH_FLOW_REPORT_SUCCESS = 'FETCH_CASH_FLOW_REPORT_SUCCESS';
export const FETCH_CASH_FLOW_REPORT_ERROR = 'FETCH_CASH_FLOW_REPORT_ERROR';

export const FETCH_SPENDER_REPORT_REQUEST = 'FETCH_SPENDER_REPORT_REQUEST';
export const FETCH_SPENDER_REPORT_SUCCESS = 'FETCH_SPENDER_REPORT_SUCCESS';
export const FETCH_SPENDER_REPORT_ERROR = 'FETCH_SPENDER_REPORT_ERROR';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getCategoriesReport: { ...block },
  getBudgetsReport: { ...block },
  getVendorsReport: { ...block },
  getCardsReport: { ...block },
  getTeamsReport: { ...block },
  getCashFlowReport: { ...block },
  getSpendersReport: { ...block },
};

export const ReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORY_REPORT_REQUEST:
      return {
        ...state,
        getCategoriesReport: {
          ...state.getCategoriesReport,
          loading: true,
        },
      };
    case FETCH_CATEGORY_REPORT_SUCCESS:
      return {
        ...state,
        getCategoriesReport: {
          ...state.getCategoriesReport,
          success: true,
          loading: false,
          error: '',
          data: action.data,
        },
      };
    case FETCH_CATEGORY_REPORT_ERROR:
      return {
        ...state,
        getCategoriesReport: {
          ...state.getCategoriesReport,
          success: false,
          error: action.error,
        },
      };

    case FETCH_BUDGET_REPORT_REQUEST:
      return {
        ...state,
        getBudgetsReport: {
          ...state.getBudgetsReport,
          loading: true,
        },
      };
    case FETCH_BUDGET_REPORT_SUCCESS:
      return {
        ...state,
        getBudgetsReport: {
          ...state.getBudgetsReport,
          success: true,
          loading: false,
          error: '',
          data: action.data,
        },
      };
    case FETCH_BUDGET_REPORT_ERROR:
      return {
        ...state,
        getBudgetsReport: {
          ...state.getBudgetsReport,
          success: false,
          error: action.error,
        },
      };

    case FETCH_CARD_REPORT_REQUEST:
      return {
        ...state,
        getCardsReport: {
          ...state.getCardsReport,
          loading: true,
        },
      };
    case FETCH_CARD_REPORT_SUCCESS:
      return {
        ...state,
        getCardsReport: {
          ...state.getCardsReport,
          success: true,
          loading: false,
          error: '',
          data: action.data,
        },
      };
    case FETCH_CARD_REPORT_ERROR:
      return {
        ...state,
        getCardsReport: {
          ...state.getCardsReport,
          success: false,
          error: action.error,
        },
      };

    case FETCH_VENDOR_REPORT_REQUEST:
      return {
        ...state,
        getVendorsReport: {
          ...state.getVendorsReport,
          loading: true,
        },
      };
    case FETCH_VENDOR_REPORT_SUCCESS:
      return {
        ...state,
        getVendorsReport: {
          ...state.getVendorsReport,
          success: true,
          loading: false,
          error: '',
          data: action.data,
        },
      };
    case FETCH_VENDOR_REPORT_ERROR:
      return {
        ...state,
        getVendorsReport: {
          ...state.getVendorsReport,
          success: false,
          error: action.error,
        },
      };

    case FETCH_TEAM_REPORT_REQUEST:
      return {
        ...state,
        getTeamsReport: {
          ...state.getTeamsReport,
          loading: true,
        },
      };
    case FETCH_TEAM_REPORT_SUCCESS:
      return {
        ...state,
        getTeamsReport: {
          ...state.getTeamsReport,
          success: true,
          loading: false,
          error: '',
          data: action.data,
        },
      };
    case FETCH_TEAM_REPORT_ERROR:
      return {
        ...state,
        getTeamsReport: {
          ...state.getTeamsReport,
          success: false,
          error: action.error,
        },
      };

    case FETCH_CASH_FLOW_REPORT_REQUEST:
      return {
        ...state,
        getCashFlowReport: {
          ...state.getCashFlowReport,
          loading: true,
        },
      };
    case FETCH_CASH_FLOW_REPORT_SUCCESS:
      return {
        ...state,
        getCashFlowReport: {
          ...state.getCashFlowReport,
          success: true,
          loading: false,
          error: '',
          data: action.data,
        },
      };
    case FETCH_CASH_FLOW_REPORT_ERROR:
      return {
        ...state,
        getCashFlowReport: {
          ...state.getCashFlowReport,
          success: false,
          error: action.error,
        },
      };

    case FETCH_SPENDER_REPORT_REQUEST:
      return {
        ...state,
        getSpendersReport: {
          ...state.getSpendersReport,
          loading: true,
        },
      };
    case FETCH_SPENDER_REPORT_SUCCESS:
      return {
        ...state,
        getSpendersReport: {
          ...state.getSpendersReport,
          success: true,
          loading: false,
          error: '',
          data: action.data,
        },
      };
    case FETCH_SPENDER_REPORT_ERROR:
      return {
        ...state,
        getSpendersReport: {
          ...state.getSpendersReport,
          success: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
};
