import '../styles.scss';
import Loading from 'components/UI/Loading';
// import * as fs from 'fs';

const Saving = () => {
  return (
    <>
      <div className="d-flex justify-content-center pt-5">
        <div className="text-center" style={{ marginTop: '50%' }}>
          <Loading />
          <span className="savingText">Saving account information, please wait...</span>
        </div>
      </div>
    </>
  );
};
export default Saving;
