export const AUTHORIZE_QUICKBOOKS_REQUEST = 'AUTHORIZE_QUICKBOOKS_REQUEST';
export const AUTHORIZE_QUICKBOOKS_ERROR = 'AUTHORIZE_QUICKBOOKS_ERROR';
export const AUTHORIZE_QUICKBOOKS_SUCCESS = 'AUTHORIZE_QUICKBOOKS_SUCCESS';

export const FETCH_ALL_INTEGRATION_APPS_REQUEST = 'FETCH_ALL_INTEGRATION_APPS_REQUEST';
export const FETCH_ALL_INTEGRATION_APPS_ERROR = 'FETCH_ALL_INTEGRATION_APPS_ERROR';
export const FETCH_ALL_INTEGRATION_APPS_SUCCESS = 'FETCH_ALL_INTEGRATION_APPS_SUCCESS';

//TODO: create a dynamic actions for app integrated
export const FETCH_QUICKBOOKS_CATEGORY_REQUEST = 'FETCH_QUICKBOOKS_CATEGORY_REQUEST';
export const FETCH_QUICKBOOKS_CATEGORY_SUCCESS = 'FETCH_QUICKBOOKS_CATEGORY_SUCCESS';
export const FETCH_QUICKBOOKS_CATEGORY_ERROR = 'FETCH_QUICKBOOKS_CATEGORY_ERROR';

export const DISCONNECT_QUICKBOOKS_REQUEST = 'DISCONNECT_QUICKBOOKS_REQUEST';
export const DISCONNECT_QUICKBOOKS_SUCCESS = 'DISCONNECT_QUICKBOOKS_SUCCESS';
export const DISCONNECT_QUICKBOOKS_ERROR = 'DISCONNECT_QUICKBOOKS_ERROR';

export const CREATE_CATEGORY_MAPPER_REQUEST = 'CREATE_CATEGORY_MAPPER_REQUEST';
export const CREATE_CATEGORY_MAPPER_SUCCESS = 'CREATE_CATEGORY_MAPPER_SUCCESS';
export const CREATE_CATEGORY_MAPPER_ERROR = 'CREATE_CATEGORY_MAPPER_ERROR';

export const FETCH_MAPPED_CATEGORY_REQUEST = 'FETCH_MAPPED_CATEGORY_REQUEST';
export const FETCH_MAPPED_CATEGORY_SUCCESS = 'FETCH_MAPPED_CATEGORY_SUCCESS';
export const FETCH_MAPPED_CATEGORY_ERROR = 'FETCH_MAPPED_CATEGORY_ERROR';

export const RESET_BLOCK_QUICKBOOKS = 'RESET_BLOCK_QUICKBOOKS';

export const RESET_FLAGS_CATEGORY_MAPPER = 'RESET_FLAGS_CATEGORY_MAPPER';
export const RESET_BLOCK_CATEGORY_MAPPER = 'RESET_BLOCK_CATEGORY_MAPPER';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  authorizingQuickbooks: { ...block },
  quickbooksCategory: { ...block },
  integrationApps: { ...block },
  disconnectApps: { ...block },
  createCategoryMapper: { ...block },
  mappedCategory: { ...block },
};

export const IntegrationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHORIZE_QUICKBOOKS_REQUEST:
      return {
        ...state,
        authorizingQuickbooks: { ...state.authorizingQuickbooks, loading: true },
      };
    case AUTHORIZE_QUICKBOOKS_SUCCESS:
      return {
        ...state,
        authorizingQuickbooks: {
          ...state.authorizingQuickbooks,
          loading: false,
          error: false,
          success: true,
          data: action.data,
        },
      };
    case AUTHORIZE_QUICKBOOKS_ERROR:
      return {
        ...state,
        authorizingQuickbooks: {
          ...state.authorizingQuickbooks,
          loading: false,
          error: action.error,
        },
      };

    case FETCH_ALL_INTEGRATION_APPS_REQUEST:
      return {
        ...state,
        integrationApps: { ...state.integrationApps, loading: true },
      };
    case FETCH_ALL_INTEGRATION_APPS_SUCCESS:
      return {
        ...state,
        integrationApps: {
          ...state.integrationApps,
          loading: false,
          error: false,
          success: true,
          data: action.data,
        },
      };
    case FETCH_ALL_INTEGRATION_APPS_ERROR:
      return {
        ...state,
        integrationApps: {
          ...state.integrationApps,
          loading: false,
          error: action.error,
        },
      };

    case CREATE_CATEGORY_MAPPER_REQUEST:
      return {
        ...state,
        createCategoryMapper: { ...state.createCategoryMapper, loading: true },
      };
    case CREATE_CATEGORY_MAPPER_SUCCESS:
      return {
        ...state,
        createCategoryMapper: {
          ...state.createCategoryMapper,
          loading: false,
          error: false,
          success: true,
          data: action.data,
        },
      };
    case CREATE_CATEGORY_MAPPER_ERROR:
      return {
        ...state,
        createCategoryMapper: {
          ...state.createCategoryMapper,
          loading: false,
          error: action.error,
        },
      };

    case FETCH_QUICKBOOKS_CATEGORY_REQUEST:
      return {
        ...state,
        quickbooksCategory: { ...state.quickbooksCategory, loading: true },
      };
    case FETCH_QUICKBOOKS_CATEGORY_SUCCESS:
      return {
        ...state,
        quickbooksCategory: {
          ...state.quickbooksCategory,
          loading: false,
          error: false,
          success: true,
          data: action.data,
        },
      };
    case FETCH_QUICKBOOKS_CATEGORY_ERROR:
      return {
        ...state,
        quickbooksCategory: {
          ...state.quickbooksCategory,
          loading: false,
          error: action.error,
        },
      };

    case FETCH_MAPPED_CATEGORY_REQUEST:
      return {
        ...state,
        mappedCategory: { ...state.mappedCategory, loading: true },
      };
    case FETCH_MAPPED_CATEGORY_SUCCESS:
      return {
        ...state,
        mappedCategory: {
          ...state.mappedCategory,
          loading: false,
          error: false,
          success: true,
          data: action.data,
        },
      };
    case FETCH_MAPPED_CATEGORY_ERROR:
      return {
        ...state,
        mappedCategory: {
          ...state.mappedCategory,
          loading: false,
          error: action.error,
        },
      };

    case DISCONNECT_QUICKBOOKS_REQUEST:
      return {
        ...state,
        disconnectApps: { ...state.disconnectApps, loading: true },
      };
    case DISCONNECT_QUICKBOOKS_SUCCESS:
      return {
        ...state,
        disconnectApps: {
          ...state.disconnectApps,
          loading: false,
          error: false,
          success: true,
          data: action.data,
        },
      };
    case DISCONNECT_QUICKBOOKS_ERROR:
      return {
        ...state,
        disconnectApps: {
          ...state.disconnectApps,
          loading: false,
          error: action.error,
        },
      };

    case RESET_BLOCK_CATEGORY_MAPPER: {
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...initialState[action.blockType],
        },
      };
    }

    case RESET_FLAGS_CATEGORY_MAPPER:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    //reset block with flag and data
    case RESET_BLOCK_QUICKBOOKS:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };

    default:
      return state;
  }
};
