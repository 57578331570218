import { useState } from 'react';

import CustomButton from 'components/UI/CustomButton';
import CustomDrawer from 'components/UI/CustomDrawer';
import CustomTextarea from 'components/UI/CustomTextarea';
import { toastError } from 'components/UI/toast';
import { Row } from 'react-bootstrap';

const DeclineMultipleRequestModal = ({
  closeModal,
  handleDecline,
  action = 'declined',
  loading,
}) => {
  const [input, setInput] = useState({ reason: '' });
  const onHandleValueChange = (event) => {
    setInput({
      reason: event.target.value,
    });
  };

  return (
    <section>
      <CustomDrawer show={true}>
        <div className="content d-flex flex-column justify-content-between">
          <section className="information-wrapper decline-request-container">
            <div className="d-flex flex-column justify-content-between">
              <h2 className="color-grey-900 text-ds-xs font-semibold">
                Reason for decline
              </h2>
              <Row className="mb-3">
                <CustomTextarea
                  row={5}
                  name="reason"
                  value={input?.reason}
                  onChange={onHandleValueChange}
                  placeholder="Specify the reason for the decline"
                  disabled={loading}
                />
              </Row>
              <CustomButton
                fullWidth
                className="mt-3"
                onClick={() => {
                  if (input.reason) {
                    handleDecline('declined', input);
                  } else {
                    toastError('Please enter a reason');
                  }
                }}
                disabled={loading}
                loading={loading}
              >
                Decline
              </CustomButton>
              <CustomButton
                disabled={loading}
                withoutBg
                fullWidth
                className="mt-3"
                onClick={closeModal}
              >
                Cancel
              </CustomButton>
            </div>
          </section>
        </div>
      </CustomDrawer>
    </section>
  );
};

export default DeclineMultipleRequestModal;
