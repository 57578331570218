import { Skeleton } from 'antd';
import { Col } from 'react-bootstrap';
import SpaceWrapper from 'components/UI/SpaceWrapper';

const OnboardingLoader = () => {
  return (
    <SpaceWrapper>
      <div className="dashboard-section row px-3">
        <Col md={5}>
          <Skeleton.Avatar avatar active size={80} />
          <div className="mt-4">
            <Skeleton.Input size={24} style={{ borderRadius: 4, width: '22rem' }} />

            <Skeleton.Input
              className="mt-2"
              active
              size={24}
              style={{ borderRadius: 4, width: '22rem' }}
            />

            <Skeleton.Input
              className="mt-2"
              active
              size={24}
              style={{ borderRadius: 4, width: '11rem' }}
            />

            <Skeleton.Input
              className="mt-3"
              active
              size={12}
              style={{ borderRadius: 4, width: '22rem' }}
            />
          </div>
        </Col>

        <Col md={{ span: 7 }}>
          <div className="onboarding">
            <ul className="menus">
              <div className="todo__container py-3 ">
                <section className="border-bottom d-flex flex-column">
                  {Array.from({ length: 2 }).map((_, i) => (
                    <Skeleton.Input
                      active
                      key={i}
                      size={12}
                      style={{ borderRadius: 4, width: i === 1 && '90%' }}
                    />
                  ))}
                </section>

                {Array.from({ length: 4 }).map((_, i) => (
                  <section
                    key={i}
                    className={`mt-3 py-4 d-flex gap-3 ${
                      i !== 3 ? 'border-bottom' : ''
                    } `}
                  >
                    <Skeleton.Avatar avatar active size={40} />
                    <div className="d-flex flex-column">
                      <Skeleton.Input
                        active
                        size={12}
                        style={{ borderRadius: 4, width: '12rem' }}
                      />

                      <Skeleton.Input
                        active
                        size={12}
                        style={{ borderRadius: 4, width: '16rem' }}
                      />
                    </div>

                    <div className="ms-auto">
                      <Skeleton.Button
                        active
                        shape="square"
                        style={{ borderRadius: 8, height: '42px', width: '8rem' }}
                      />
                    </div>
                  </section>
                ))}
              </div>
            </ul>
          </div>
        </Col>
      </div>
    </SpaceWrapper>
  );
};

export default OnboardingLoader;
