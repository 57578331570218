import React from 'react';
import emptyProfileImg from 'assets/images/empty-profile.jpg';
import '../../styles.scss';
import { Form, Row } from 'react-bootstrap';
import CustomSelect from 'components/UI/CustomSelect';
import { businessTypes } from 'utils/helper';
import CustomTextarea from 'components/UI/CustomTextarea';
import GroupedInput from 'components/UI/CustomInput/GroupedInput';
import { HintAlertIcon } from 'assets/icons';

const AdditionalDetails = () => {
  return (
    <div className="additional-details-container">
      <div className="title">
        <h3>Additional business details</h3>
        <p>
          Tell us a bit more about your business so we can tailor the product to your
          needs.
        </p>
      </div>

      <div className="additional-detail-fields">
        <div className="px-4">
          <div className="profile-image border-bottom bg-transparent py-4">
            <img src={emptyProfileImg} alt="img" className="bg-black" />
            <div className="upload-container">
              <h6>Upload Logo</h6>
              <p>Images should be at least 400x400px, PNG or JPEG</p>
              <button className="btn border xxs w-fit" type="button">
                Upload
              </button>
            </div>
          </div>
        </div>

        <div className="py-3 px-4 ">
          <Row className="mb-2">
            <CustomSelect
              label="Industry"
              name="industry"
              placeholder="Select your industry"
              // onChange={(val) => handleChangeBusinessType({ val })}
              // value={businessDoc.type}
              options={businessTypes}
            />
          </Row>
          <Row className="mb-2">
            <CustomSelect
              label="Company size"
              name="companySize"
              placeholder="Select company size"
              // onChange={(val) => handleChangeBusinessType({ val })}
              // value={businessDoc.type}
              options={businessTypes}
            />
          </Row>
          <Row className="mb-2">
            <GroupedInput
              label="Company website URL"
              name="companyWebsite"
              placeholder="www.bujeti.com"
              // onChange={(val) => handleChangeBusinessType({ val })}
              // value={businessDoc.type}
              options={businessTypes}
            />
            <Form.Text className="text-muted gap-1 d-flex align-items-center">
              <HintAlertIcon />
              <span className="text-xs">
                Your home page, blog, social media or company site.
              </span>
            </Form.Text>
          </Row>

          <Row className="mb-2">
            <CustomTextarea
              label="Company description"
              name="companyDescription"
              placeholder="Describe your company..."
              rowSize={3}
              // onChange={(val) => handleChangeBusinessType({ val })}
              // value={businessDoc.type}
            />
            <Form.Text className="text-muted gap-1 d-flex align-items-center">
              <HintAlertIcon />
              <span className="text-xs">You can describe your company briefly.</span>
            </Form.Text>
          </Row>
        </div>

        <div className="border-top button-container w-100 px-3 pt-3 me-md-auto pb-2 d-md-flex align-items-center gap-3">
          <button className="btn border sm ms-md-auto mb-md-0 mb-2">Cancel</button>
          <button className="btn black sm">Save and submit</button>
        </div>
      </div>
    </div>
  );
};

export default AdditionalDetails;
