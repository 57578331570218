import {
  FETCH_BUDGET_REPORT_REQUEST,
  FETCH_CARD_REPORT_REQUEST,
  FETCH_TEAM_REPORT_REQUEST,
  FETCH_VENDOR_REPORT_REQUEST,
  FETCH_CATEGORY_REPORT_REQUEST,
  FETCH_CASH_FLOW_REPORT_REQUEST,
  FETCH_SPENDER_REPORT_REQUEST,
} from '../reducers/ReportsReducer';

export const getCategoriesReport = (payload) => ({
  type: FETCH_CATEGORY_REPORT_REQUEST,
  payload,
});

export const getBudgetsReport = (payload) => ({
  type: FETCH_BUDGET_REPORT_REQUEST,
  payload,
});

export const getVendorsReport = (payload) => ({
  type: FETCH_VENDOR_REPORT_REQUEST,
  payload,
});

export const getCardsReport = (payload) => ({
  type: FETCH_CARD_REPORT_REQUEST,
  payload,
});

export const getSpendersReport = (payload) => ({
  type: FETCH_SPENDER_REPORT_REQUEST,
  payload,
});

export const getTeamsReport = (payload) => ({
  type: FETCH_TEAM_REPORT_REQUEST,
  payload,
});

export const getCashFlowReport = (payload) => ({
  type: FETCH_CASH_FLOW_REPORT_REQUEST,
  payload,
});
