import CustomButton from 'components/UI/CustomButton';
import CustomTextarea from 'components/UI/CustomTextarea';
import Success from 'components/UI/ModalSaving/Success';
import { toastError } from 'components/UI/toast';
import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { reviewRequest } from 'redux/actions/ApprovalAction';
import { declineRequests } from 'redux/actions/RequestsAction';
import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';

const DeclineRequest = ({
  setStep,
  isLoaded,
  selectRequest,
  hideHeader,
  userCode,
  onClose,
  step,
  kebabAction = false,
  approvalCode,
  getReason,
}) => {
  const [value, setValue] = useState('');
  const history = useHistory();

  const handleValueChange = (e) => {
    setValue(e.target.value);
  };

  const dispatch = useDispatch();
  const {
    reviewRequest: { loading, success },
  } = useSelector(({ approval }) => approval);

  let yourApprovalRequest = selectRequest?.relatedApprovalRequests?.find((request) => {
    const foundApprover =
      request.status === 'pending' &&
      request.approvalStages?.some((stage) => {
        return (
          stage.status === 'pending' &&
          stage.approvers.some((approver) => approver.user.code === userCode)
        );
      });
    return foundApprover && request;
  });
  yourApprovalRequest = Array.isArray(yourApprovalRequest)
    ? yourApprovalRequest.length && yourApprovalRequest[0]
    : yourApprovalRequest;

  const { code: requestCode } = yourApprovalRequest || {};

  const handleSubmit = () => {
    if (!value) return toastError('Enter Description');

    if (getReason) {
      return getReason('decline', value);
    }
    const payload = {
      note: value,
      code: selectRequest?.code,
    };
    if ((yourApprovalRequest && requestCode) || approvalCode) {
      return dispatch(
        reviewRequest({
          code: approvalCode ?? requestCode,
          status: 'declined',
          reason: value,
        }),
      );
    }
    dispatch(declineRequests(payload));
  };

  const handleCloseDecline = () => {
    if (hideHeader) hideHeader(false);
    if (setStep) setStep(1);
    if (kebabAction) {
      return onClose();
    }
  };

  const closeModal = () => {
    if (step == 2) return handleCloseDecline();
    return onClose();
  };
  useEffect(() => {
    if (!loading && success && !kebabAction) {
      history.push('/requests/funds');
    }
  }, [loading, success]);

  const infoCard = () => {
    return (
      <div className="information-wrapper decline-request-container">
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="color-grey-900 text-ds-xs font-semibold">Decline request</h2>
        </div>
        <Row className="mb-3">
          <CustomTextarea
            row={5}
            label="Reason for declining"
            name="reason"
            onChange={handleValueChange}
            value={value}
            placeholder="Specify the reason for the decline"
          />
        </Row>
        <Row className="mt-3">
          <div className="col-sm-6 col-md-6">
            <CustomButton
              withoutBg
              fullWidth
              className="add-button"
              onClick={handleCloseDecline}
              disabled={loading}
            >
              Cancel
            </CustomButton>
          </div>
          <div className="col-sm-6 col-md-6">
            <CustomButton
              fullWidth
              className="add-button"
              onClick={handleSubmit}
              disabled={loading}
              loading={loading}
            >
              Decline
            </CustomButton>
          </div>
        </Row>
      </div>
    );
  };

  return (
    <>
      <div className="card-modal-header" onClick={closeModal}>
        <div className="d-flex align-items-center cursor">
          {isLoaded || kebabAction ? <CloseOutlined /> : <ArrowLeftOutlined />}
          <span className="ps-1">{isLoaded || kebabAction ? 'Close' : 'Back'}</span>
        </div>
      </div>
      {isLoaded ? (
        <Success
          title="Thank you!"
          message="Your rejection has been taken into account and the applicant will be informed"
        />
      ) : (
        infoCard()
      )}
    </>
  );
};

export default DeclineRequest;
