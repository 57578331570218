import CustomDatePicker from 'components/UI/CustomDatePicker';
import CustomInput from 'components/UI/CustomInput';
import FileUpload from 'components/UI/FileUpload';
import { addYears, format } from 'date-fns';
import { Row } from 'react-bootstrap';
import '../../styles.scss';
import dayjs from 'dayjs';

const LimitedLiability = ({
  handleChangeBusinessDoc,
  businessDoc,
  setUploadingFile,
  uploadingFile,
  setBusinessDoc,
  setDocuments,
  documents,
}) => {
  const year = new Date(businessDoc.company_registration_date);
  const pre2017Date = new Date('2016-12-31');
  const post2017Date = new Date('2020-11-30');
  const pre2017 = year <= pre2017Date && !!businessDoc.company_registration_date;
  const post2017 =
    year > pre2017Date && year <= post2017Date && !!businessDoc.company_registration_date;
  const post2020 = year > post2017Date && !!businessDoc.company_registration_date;

  const handleChangeDate = (value) => {
    setBusinessDoc({
      ...businessDoc,
      company_registration_date: dayjs(value).format('YYYY-MM-DD'),
    });
    setDocuments({
      ...documents,
      cacForm3: '',
      cacStatusReport: '',
      cacForm1: '',
      cacForm7: '',
      cacForm2: '',
    });
  };

  return (
    <>
      <div className="information">Company Information</div>
      <Row className="mb-3">
        <CustomInput
          label="RC Number *"
          placeholder="Enter RC Number - RC000000"
          type="text"
          name="rcNumber"
          onChange={handleChangeBusinessDoc}
          value={businessDoc?.rcNumber}
          maxLength="9"
        />
      </Row>
      <Row className="mb-3">
        <CustomDatePicker
          label="Company Registration Date *"
          onChange={handleChangeDate}
          peekNextMonth
          maxDate={addYears(new Date(), 0)}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          placeholderText="Company Registration Date"
          name="company_registration_date"
          selected={
            businessDoc.company_registration_date &&
            dayjs(businessDoc.company_registration_date).toDate()
          }
        />
      </Row>
      <Row className="mb-3">
        <CustomInput
          label="Tax Identification Number *"
          placeholder="Enter Tax Identification Number"
          type="text"
          name="tin"
          onChange={handleChangeBusinessDoc}
          value={businessDoc.tin}
        />
      </Row>
      <Row className="mb-3">
        <FileUpload
          label="Certificate of Incorporation *"
          placeholder="Upload certificate (PDF, JPEG, PNG)"
          name="file"
          uploadedFile={documents.incorporationCertificate}
          onChange={({ assetCode }) =>
            setDocuments({ ...documents, incorporationCertificate: assetCode })
          }
          setUploadingFile={setUploadingFile}
          uploadingFile={uploadingFile}
        />
      </Row>
      {pre2017 && (
        <>
          <Row className="mb-3">
            <FileUpload
              label="CAC 2 / Statement of Share Capital and Return of Allotment *"
              placeholder="Upload certificate (PDF, JPEG, PNG)"
              name="file"
              uploadedFile={documents.cacForm2}
              onChange={({ assetCode }) =>
                setDocuments({ ...documents, cacForm2: assetCode })
              }
              setUploadingFile={setUploadingFile}
              uploadingFile={uploadingFile}
            />
          </Row>
          <Row className="mb-3">
            <FileUpload
              label="CAC 3 / Notification of Registered address *"
              placeholder="Upload document (PDF, JPEG, PNG)"
              name="file"
              uploadedFile={documents.cacForm3}
              onChange={({ assetCode }) =>
                setDocuments({ ...documents, cacForm3: assetCode })
              }
              setUploadingFile={setUploadingFile}
              uploadingFile={uploadingFile}
            />
          </Row>
          <Row className="mb-3">
            <FileUpload
              label="CAC 7 / Particulars of Directors *"
              placeholder="Upload document (PDF, JPEG, PNG)"
              name="file"
              uploadedFile={documents.cacForm7}
              onChange={({ assetCode }) =>
                setDocuments({ ...documents, cacForm7: assetCode })
              }
              setUploadingFile={setUploadingFile}
              uploadingFile={uploadingFile}
            />
          </Row>
        </>
      )}
      {post2017 && (
        <>
          <Row className="mb-3">
            <FileUpload
              label="CAC 1.1 / Application for Registration *"
              placeholder="Upload document (PDF, JPEG, PNG)"
              name="file"
              uploadedFile={documents.cacForm1}
              onChange={({ assetCode }) =>
                setDocuments({ ...documents, cacForm1: assetCode })
              }
              setUploadingFile={setUploadingFile}
              uploadingFile={uploadingFile}
            />
          </Row>
        </>
      )}
      {post2020 && (
        <>
          <Row className="mb-3">
            <FileUpload
              label="CAC Status Report *"
              placeholder="Upload document (PDF, JPEG, PNG)"
              name="file"
              uploadedFile={documents.cacStatusReport}
              onChange={({ assetCode }) =>
                setDocuments({ ...documents, cacStatusReport: assetCode })
              }
              setUploadingFile={setUploadingFile}
              uploadingFile={uploadingFile}
            />
          </Row>
        </>
      )}
      <Row className="mb-3">
        <FileUpload
          label="Memorandum and Articles of Association *"
          placeholder="Upload MOA (PDF, JPEG, PNG)"
          name="file"
          uploadedFile={documents.memorandumOfAssociation}
          onChange={({ assetCode }) =>
            setDocuments({ ...documents, memorandumOfAssociation: assetCode })
          }
          setUploadingFile={setUploadingFile}
          uploadingFile={uploadingFile}
        />
      </Row>
    </>
  );
};
export default LimitedLiability;
