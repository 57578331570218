import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { get_user_profile } from 'redux/actions/AuthAction';
import { useHistory } from 'react-router-dom';
import { allPermissions } from 'utils/AllowedTo';
import * as Sentry from '@sentry/react';
import { retrieveAndDecryptFromLocalStorage } from 'utils/utility';

const DefaultAPI = () => {
  const token = retrieveAndDecryptFromLocalStorage('app-session');
  const tokenExp = parseInt(retrieveAndDecryptFromLocalStorage('exp-app-session'));

  const {
    user: { data = {} },
  } = useSelector(({ auth }) => auth);
  const {
    getCompany: { data: companyData = {} },
  } = useSelector(({ companies }) => companies);
  // Stack tracing with additional information
  if (process.env.NODE_ENV === 'production') {
    const userFeedback = {
      id: `${data?.user?.firstName} ${data?.user?.lastName}` || '-',
      company: data?.user?.company?.name || '-',
      email: data?.user?.email || '-',
    };
    Sentry.setUser(userFeedback);
  }
  // end stack tracing

  const { isAdmin } = allPermissions();

  const history = useHistory();

  const dispatch = useDispatch();
  useEffect(() => {
    if (token && tokenExp) {
      dispatch(get_user_profile());
    }
  }, [token, tokenExp]);

  useEffect(() => {
    if (companyData?.status) {
      if (!!companyData?.director?.dob) {
        localStorage.setItem('onboardingAlert', false && isAdmin);
      } else {
        localStorage.setItem('onboardingAlert', true && isAdmin);
      }

      localStorage.setItem('completedBusinessInfo', !!companyData?.address && isAdmin);
    }
  }, [companyData?.director?.dob]);
  return <></>;
};
export default DefaultAPI;
