import '../styles.scss';
import { Skeleton } from 'antd';
import { DotChartOutlined } from '@ant-design/icons';

const OverviewLoader = () => {
  return (
    <section className="page-wrapper flex-column">
      <div className="d-md-flex align-items-center w-100 justify-content-between pt-5 mt-4 pb-5">
        <Skeleton.Input active size={24} style={{ borderRadius: 4 }} />

        <div className="d-flex gap-3">
          {Array.from({ length: 2 }).map((_, i) => (
            <Skeleton.Button
              key={i}
              active
              shape="square"
              style={{ borderRadius: 8, height: '42px', width: '8rem' }}
            />
          ))}
        </div>
      </div>

      <div className="overview-data position-relative">
        {Array.from({ length: 3 }).map((_, i) => (
          <div key={i} className="total-amount-wrapper">
            <Skeleton.Button
              active
              size={12}
              style={{ borderRadius: 4, width: i === 1 ? '10rem' : '' }}
            />
            <Skeleton.Button
              active
              className="mt-1"
              size={24}
              style={{ borderRadius: 4, width: i === 2 ? '12rem' : '9rem' }}
            />
            <Skeleton.Button
              active
              className="mt-2"
              size={12}
              style={{ borderRadius: 4, width: '7rem' }}
            />
          </div>
        ))}

        {/* <div className="total-amount-wrapper">22</div>
        <div className="total-amount-wrapper open-box-announcement pinky black-lines">
          
        </div> */}
      </div>

      <div className="row">
        <div className="budget-analytics">
          <div className="grid-col-2 gap-4 mt-4">
            {Array.from({ length: 2 }).map((_, i) => (
              <div
                key={i}
                className="px-3 py-2 border overview-card-view"
                style={{ height: '350px' }}
              >
                <div className="py-3 border-bottom">
                  <Skeleton.Input active size={20} style={{ borderRadius: 4 }} />
                </div>

                {Array.from({ length: 3 }).map((_, i) => (
                  <div
                    key={i + 'item'}
                    className={`d-flex align-items-center gap-2 py-3 ${
                      i !== 2 && 'border-bottom'
                    } `}
                  >
                    <Skeleton.Avatar avatar active size={40} />
                    <div className="d-flex pt-1 flex-column">
                      <Skeleton.Input active size={12} style={{ borderRadius: 4 }} />
                      <Skeleton.Input active size={12} style={{ borderRadius: 4 }} />
                    </div>

                    <div className="ms-auto">
                      <Skeleton.Avatar avatar active size={10} />
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div className="d-flex flex-wrap justify-content-between align-items-center insights-title">
            <div className="d-flex  flex-column">
              <Skeleton.Input active size={16} style={{ borderRadius: 4 }} />
              <Skeleton.Input
                active
                size={16}
                style={{ borderRadius: 4, width: '20rem' }}
              />
            </div>

            <div>
              <Skeleton.Button
                active
                shape="square"
                style={{ borderRadius: 8, height: '42px', width: '8rem' }}
              />
            </div>
          </div>

          <div className="grid-col-2 gap-4 mt-4">
            {Array.from({ length: 4 }).map((_, i) => (
              <Skeleton.Node
                key={i}
                active
                style={{ borderRadius: 12, width: '100%', height: '350px' }}
              >
                <DotChartOutlined style={{ fontSize: 60, color: '#bfbfbf' }} />
              </Skeleton.Node>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OverviewLoader;
