import { CloseOutlined } from '@ant-design/icons';
import { CopyIcon } from 'assets/icons';
import Modal from 'components/UI/Modal';
import { toastSuccess } from 'components/UI/toast';
import LabeledInfo from 'components/bujetiPayModal/LabeledInfo';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RESET_BLOCK_COMPANY } from 'redux/reducers/CompaniesReducer';

const AccountDetailsModal = ({ details, closeHandler, isModalOpen }) => {
  if (!isModalOpen) return <div />;

  const handleBack = () => {
    return closeHandler();
  };

  const dispatch = useDispatch();

  const {
    createBankAccount: { success: accountCreated },
  } = useSelector(({ companies }) => companies);

  useEffect(() => {
    if (accountCreated) {
      closeHandler(true);
      toastSuccess('Account is being generated');
      dispatch({ type: RESET_BLOCK_COMPANY, blockType: 'createBankAccount' });
    }
  }, [accountCreated]);

  const account = `Account name: ${details?.accountName} \nBank name: ${
    details?.bankName
  } \nAccount number: ${details?.number} ${
    details?.routingNumber ? `\nRouting number: ${details?.routingNumber}` : ''
  }`;
  const copyAll = async () => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(account);
      toastSuccess('Copied successfully');
    }
  };

  return (
    <Modal show={isModalOpen} onClose={closeHandler}>
      <div className="content">
        <div className="card-modal-header">
          <div className="d-flex align-items-center cursor" onClick={handleBack}>
            <CloseOutlined />
            <span className="ps-1">Close</span>
          </div>
        </div>

        <div className="card-modal-body">
          <div className="beneficiaries">
            <h2 className="card-title w-100">Account details</h2>

            {details.currency === 'NGN' && (
              <div className="form-normal mt-2">
                <div className="preview-card__transfer mb-4">
                  <div className="px-4 pt-1 w-100">
                    <h6 className="text-sm">Outgoing Transaction Limits</h6>
                    <div style={{ color: '#79716B' }} className="text-sm mt-3">
                      <p> • Per transaction: ₦10,000,000</p>{' '}
                      <p>• Daily limit: ₦25,000,000</p>{' '}
                      <p>
                        {' '}
                        Please note that these limits apply to all outgoing transactions
                        from your account. If you need to process a transaction exceeding
                        these limits, please contact our support team for assistance.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="form-normal mt-2">
              <div className="preview-card__transfer mb-3">
                <div className="px-4 pt-1 pb-2 w-100">
                  <div className="d-flex align-items-center justify-content-between ">
                    <h6>Share your account details easily</h6>
                    <div
                      className="copy-box gap-2 d-flex align-items-center cursor"
                      onClick={copyAll}
                    >
                      <CopyIcon stroke="#D28B28" width="20" height="20" />{' '}
                      <span style={{ color: '#D28B28' }}>Copy</span>
                    </div>
                  </div>

                  <LabeledInfo label="Bank name" value={details.bankName} />
                  <LabeledInfo label="Account name" value={details.accountName} />

                  <LabeledInfo
                    className="mt-1"
                    label="Account number"
                    value={details.number}
                  />

                  {details?.routingNumber && (
                    <LabeledInfo
                      className="mt-1"
                      label="Routing number"
                      value={details.routingNumber}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default AccountDetailsModal;
