import EmptyState from 'components/UI/EmptyState';
import ExpenseImage from '../../assets/images/emptystate/expense-image.png';

import CustomButton from 'components/UI/CustomButton';

import {
  CreateBudget,
  ManageVendor,
  SpendingPolicy,
} from 'assets/icons/empty-state-icons';
import { useHistory, useLocation } from 'react-router-dom';

const ExpensesEmptyStateData = ({
  openBudgetModal,
  openVendorModal,
  openPolicyModal,
  createCategorization,
  showAction,
}) => {
  const location = useLocation();
  const locationArray = location.pathname.split('/');

  const key = locationArray[locationArray.length - 1];

  const isExpense = key === 'budgets';
  const isVendor = key === 'vendors';
  const isPolicy = key === 'policies';
  const isCategorization = key === 'categorization-rules';

  const handleOpenModal = () => {
    if (isExpense) openBudgetModal();
    if (isVendor) openVendorModal();
    if (isPolicy) openPolicyModal();
    if (isCategorization) createCategorization();
  };

  const handleSupport = () => {
    window.open('https://www.bujeti.com/expense-management', '_blank');
  };

  const buttonName = isExpense
    ? 'Create a budget'
    : isVendor
    ? 'Create a Vendor'
    : isPolicy
    ? 'Create a Policy'
    : isCategorization
    ? 'Create new rule'
    : null;

  const childrenData = [
    {
      title: 'Create budgets',
      body: 'Organise transactions by assigning them to specific expense categories.',
      actionTitle: 'View more',
      action: handleSupport,
      icon: <CreateBudget />,
    },

    {
      title: 'Manage your vendors',
      body: 'Create vendors, manage and track vendor-related expenses.',
      actionTitle: 'View more',
      action: handleSupport,
      icon: <ManageVendor />,
    },
    {
      title: 'Create spending policies',
      body: 'Establish policies to govern and control team spending.',
      actionTitle: 'View more',
      action: handleSupport,
      icon: <SpendingPolicy />,
    },
  ];

  const mainData = {
    title: 'Dive deep into budgets, vendors, and spending policies.',
    body: `Manage your company's expenses by organising transactions, tracking vendors, and implementing spending policies.`,
    image: ExpenseImage,
    action: () =>
      showAction && (
        <CustomButton className="add-button w-auto" onClick={handleOpenModal}>
          {buttonName}
        </CustomButton>
      ),
  };

  return <EmptyState main={mainData} childrenData={childrenData} />;
};

export default ExpensesEmptyStateData;
