import { ChevronDown, ChevronRight } from 'assets/icons';
import { IndeterminateCheckbox } from 'components/Table';
import { getColor } from 'utils/helper';
import { ReactComponent as RightArrow } from '../assets/icons/arrow-right.svg';

export const BulkTransactionColumns = [
  {
    // Build our expander column
    type: 'expander', // Make sure it has an ID
    id: 'expander', // Make sure it has an ID
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
      <span {...getToggleAllRowsExpandedProps()}>
        <span
          style={{
            display: 'inline-block',
            transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
            transform: isAllRowsExpanded ? 'rotate(0deg)' : 'rotate(-90deg)',
          }}
        >
          <ChevronDown width="18" height="18" color="black" />
        </span>
      </span>
    ),
    Cell: ({ row }) =>
      // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
      // to build the toggle for expanding a row
      row.canExpand ? (
        <span
          {...row?.getToggleRowExpandedProps({
            style: {
              // We can even use the row.depth property
              // and paddingLeft to indicate the depth
              // of the row
              paddingLeft: `${row.depth * 2}rem`,
            },
          })}
        >
          <span
            style={{
              display: 'inline-block',
              transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
              transform: row.isExpanded ? 'rotate(0deg)' : 'rotate(-90deg)',
            }}
          >
            <ChevronDown width="18" height="18" color="black" />
          </span>
        </span>
      ) : null,
  },
  {
    Header: 'Batch name',
    accessor: 'batchName',
    type: 'string',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    type: 'string-bold',
  },
  {
    Header: 'Paid by',
    accessor: 'by',
    type: 'string',
    withImg: true,
  },
  {
    Header: 'Category',
    accessor: 'category',
    type: 'badge',
    withIcon: false,
  },
  {
    Header: 'Paid on ',
    accessor: 'date',
    type: 'string',
  },
  {
    Header: 'Files',
    accessor: 'evidence',
    type: 'string',
  },
  {
    Header: 'Status',
    accessor: 'statusList',
    withIcon: true,
    link: true,
    withNumber: true,
    numberList: true,
    isDropDown: true,
    type: 'string-badge',
  },
  // {
  //   Header: 'Status',
  //   accessor: 'status',
  //   type: 'badge',
  // },
  {
    Header: 'Action',
    accessor: 'modify',
    withPopover: true,
    type: 'modify',
  },
];

export const PendingTransactionColumns = [
  // Let's make a column for selection

  {
    type: 'selection',
    id: 'selection',
    // The header can use the table's getToggleAllRowsSelectedProps method
    // to render a checkbox
    Header: ({ getToggleAllRowsSelectedProps }) => (
      <div>
        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
      </div>
    ),
    // The cell can use the individual row's getToggleRowSelectedProps method
    // to the render a checkbox
    Cell: ({ row }) => (
      <div>
        <IndeterminateCheckbox {...row?.getToggleRowSelectedProps()} />
      </div>
    ),
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    type: 'string-bold',
  },
  {
    Header: 'Paid to',
    accessor: 'paidTo',
    type: 'string',
  },
  {
    Header: 'Paid by',
    accessor: 'by',
    type: 'string',
    withIcon: true,
  },
  {
    Header: 'Category',
    accessor: 'category',
    type: 'badge',
    withIcon: false,
  },
  {
    Header: 'Description',
    accessor: 'description',
    type: 'string',
  },
  {
    Header: 'Paid on ',
    accessor: 'date',
    type: 'string',
  },
  {
    Header: 'Files',
    accessor: 'evidence',
    type: 'string',
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
];

export const columns = [
  {
    Header: 'Paid to',
    accessor: 'paidTo',
    type: 'string',
    withImg: true,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    type: 'string-bold',
  },
  {
    Header: 'Paid by',
    accessor: 'by',
    type: 'string',
    withImg: true,
  },
  {
    Header: 'Category',
    accessor: 'category',
    type: 'badge',
  },
  {
    Header: 'Paid on',
    accessor: 'date',
    type: 'string',
  },
  {
    Header: 'Files',
    accessor: 'evidence',
    type: 'string',
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
  {
    Header: 'Action',
    accessor: 'modify',
    withPopover: true,
    type: 'modify',
  },
];

export const categoryTransactionColumns = [
  {
    Header: 'To',
    accessor: 'paidTo',
    type: 'string',
    withImg: true,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    type: 'string-bold',
  },
  {
    Header: 'By',
    accessor: 'by',
    type: 'string',
    withImg: true,
  },
  {
    Header: 'Account',
    accessor: 'account',
    type: 'string',
  },
  {
    Header: 'Date',
    accessor: 'date',
    type: 'string',
  },
  {
    Header: 'File',
    accessor: 'evidence',
    type: 'string',
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
  {
    Header: 'Action',
    accessor: 'modify',
    withPopover: true,
    type: 'modify',
  },
];

export const categoryColumns = [
  // Build our expander column
  {
    type: 'expander', // Make sure it has an ID
    id: 'expander', // Make sure it has an ID
    Header: 'Category name',
    Cell: ({ row }) =>
      // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
      // to build the toggle for expanding a row
      row?.original?.isSubCategory === false ? (
        <span
          {...row?.getToggleRowExpandedProps({
            style: {
              // We can even use the row.depth property
              // and paddingLeft to indicate the depth
              // of the row
              paddingLeft: `${
                !!row?.subRows?.length ? `${row.depth * 2}rem` : `${1.25}rem`
              }`,
            },
          })}
          onClick={
            !!row?.original?.subRows?.length
              ? (e) => {
                  e.stopPropagation();
                  row?.toggleRowExpanded();
                }
              : undefined
          }
        >
          {!!row?.subRows?.length && (
            <>
              <span
                style={{
                  display: 'inline-block',
                  transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                  transform: row.isExpanded ? 'rotate(0deg)' : 'rotate(-90deg)',
                }}
              >
                <ChevronDown width="16" height="16" color="#A9A29D" />
              </span>
            </>
          )}
          {row.original.name}
        </span>
      ) : (
        <span style={{ paddingLeft: `${row.depth * 1.5}rem` }}>
          {row.original?.name ?? ''}
        </span>
      ),
  },
  {
    Header: 'Description',
    accessor: 'description',
    type: 'string',
    truncateLength: 60,
  },
  {
    Header: 'Amount spent',
    accessor: 'spent',
    type: 'string',
  },
  {
    Header: 'Limit',
    accessor: 'limit',
    type: 'string',
  },
  {
    Header: '',
    accessor: 'modify',
    withPopover: true,
    type: 'modify',
  },
];

export const exportCategoriesColumns = [
  {
    Header: 'Category Name',
    accessor: 'name',
    type: 'string',
  },
  {
    Header: 'Description',
    accessor: 'description',
    type: 'string',
    truncateLength: 60,
  },
  {
    Header: 'Limit',
    accessor: 'limit',
    type: 'string',
  },
  {
    Header: 'Buffer Amount',
    accessor: 'bufferAmount',
    type: 'string',
  },
  {
    Header: 'Amount spent',
    accessor: 'spent',
    type: 'string',
  },
  {
    Header: 'Available',
    accessor: 'available',
    type: 'string',
  },
];

export const profileTransactionColumns = [
  {
    Header: 'Paid by',
    accessor: 'by',
    type: 'string',
    withImg: true,
  },
  {
    Header: 'Paid to',
    accessor: 'to',
    type: 'string',
    withImg: true,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    type: 'string-bold',
  },
  {
    Header: 'Method',
    accessor: 'method',
    type: 'double-string',
    withImg: true,
    width: 192,
  },
  {
    Header: 'Date',
    accessor: 'date',
    type: 'string',
  },
];

export const customerTransactionColumns = [
  {
    Header: 'Amount',
    accessor: 'amount',
    type: 'string-bold',
  },

  {
    Header: 'Method',
    accessor: 'method',
    type: 'string',
  },

  {
    Header: 'Date',
    accessor: 'date',
    type: 'string',
  },
];

export const vendorApproversColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    type: 'string',
    withImg: true,
  },
  {
    Header: 'Email Address',
    accessor: 'email',
    type: 'string',
  },
  {
    Header: 'Last Activity',
    accessor: 'date',
    type: 'string',
  },
];

export const subAccountsColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    type: 'string',
    truncate: false,
    truncateLength: 50,
  },
  {
    Header: 'Type',
    accessor: 'type',
    type: 'badge',
    //withIcon: false,
  },
  {
    Header: 'Balance',
    accessor: 'balance',
    type: 'string-bold',
    withColor: true,
  },
  // {
  //   Header: 'Managed by',
  //   accessor: 'manager',
  //   type: 'string',
  // },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
    //withIcon: false,
  },
  {
    Header: 'Created on',
    accessor: 'date',
    type: 'string',
  },
];
export const columnsPayins = [
  {
    Header: 'Source',
    accessor: 'description',
    type: 'string',
    truncate: false,
    truncateLength: 35,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    type: 'string-bold',
    withColor: true,
  },
  {
    Header: 'Type',
    accessor: 'type',
    type: 'badge',
    //withIcon: false,
  },
  {
    Header: 'Balance before',
    accessor: 'balanceBefore',
    type: 'string',
  },
  {
    Header: 'Balance after',
    accessor: 'balanceAfter',
    type: 'string',
  },
  {
    Header: 'Credited on',
    accessor: 'date',
    type: 'string',
  },
];
export const columnsPayouts = [
  {
    Header: 'Paid to',
    accessor: 'paidTo',
    type: 'string',
    withImg: true,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    type: 'string-bold',
    withColor: true,
  },
  {
    Header: 'Type',
    accessor: 'type',
    type: 'badge',
  },
  {
    Header: 'Category',
    accessor: 'category',
    type: 'badge',
    //withIcon: false,
  },
  {
    Header: 'Balance before',
    accessor: 'balanceBefore',
    type: 'string',
  },
  {
    Header: 'Balance after',
    accessor: 'balanceAfter',
    type: 'string',
  },
  {
    Header: 'Paid on',
    accessor: 'date',
    type: 'string',
  },
];

export const scheduledTransactionColumn = [
  {
    Header: 'Paid to',
    accessor: 'paidTo',
    type: 'string',
    withImg: true,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    type: 'string-bold',
  },
  {
    Header: 'Paid by',
    accessor: 'by',
    type: 'string',
    withImg: true,
  },
  {
    Header: 'Category',
    accessor: 'category',
    type: 'badge',
  },
  {
    Header: 'Start date',
    accessor: 'start_date',
    type: 'string',
  },
  {
    Header: 'Next transfer',
    accessor: 'next_transfer',
    type: 'string',
  },
  {
    Header: 'Files',
    accessor: 'evidence',
    type: 'string',
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
  {
    Header: 'Action',
    accessor: 'modify',
    withPopover: true,
    type: 'modify',
  },
];

export const budgetLedgercolumns = [
  {
    Header: 'Paid to',
    accessor: 'paidTo',
    type: 'string',
    withImg: true,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    type: 'string-bold',
    withColor: true,
  },
  {
    Header: 'Paid by',
    accessor: 'by',
    type: 'string',
    withImg: true,
  },
  {
    Header: 'Operation',
    accessor: 'paymentMethod',
    type: 'string',
  },
  {
    Header: 'Category',
    accessor: 'category',
    type: 'badge',
  },
  {
    Header: 'Paid on ',
    accessor: 'date',
    type: 'string',
  },
  {
    Header: 'Files',
    accessor: 'evidence',
    type: 'string',
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
  {
    Header: 'Action',
    accessor: 'modify',
    withPopover: true,
    type: 'modify',
  },
];

export const subAccountTransactionsColumns = [
  {
    Header: 'Paid to',
    accessor: 'paidTo',
    type: 'string',
    withImg: true,
  },
  {
    Header: 'Paid by',
    accessor: 'paidBy',
    type: 'string',
    withImg: true,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    type: 'string-bold',
    withColor: true,
  },
  {
    Header: 'Type',
    accessor: 'type',
    type: 'string',
  },
  {
    Header: 'Category',
    accessor: 'category',
    type: 'badge',
    //withIcon: false,
  },
  {
    Header: 'Balance after',
    accessor: 'balanceAfter',
    type: 'string',
  },
  {
    Header: 'Paid on',
    accessor: 'date',
    type: 'string',
  },
  {
    Header: 'Action',
    accessor: 'modify',
    withPopover: true,
    type: 'modify',
  },
];

export const columnsTransactionCSV = [
  {
    Header: 'Paid to',
    accessor: 'paidTo',
    type: 'string',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    type: 'string-bold',
  },
  {
    Header: 'Paid by',
    accessor: 'by',
    type: 'string',
    withIcon: true,
  },
  {
    Header: 'Category',
    accessor: 'category',
    type: 'badge',
    withIcon: false,
  },
  {
    Header: 'Method',
    accessor: 'paymentMethod',
    type: 'string',
    withIcon: true,
  },
  {
    Header: 'Budget',
    accessor: 'budget',
    type: 'string',
    withIcon: true,
    link: true,
    isDropDown: false,
  },
  {
    Header: 'Description',
    accessor: 'description',
    type: 'string',
  },
  {
    Header: 'Paid on',
    accessor: 'date',
    type: 'string',
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
];

export const columnsTransactionXero = [
  {
    Header: 'Date',
    accessor: 'date',
    type: 'string',
  },
  {
    Header: 'Description',
    accessor: 'description',
    type: 'string',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    type: 'string-bold',
  },
  {
    Header: 'Payee',
    accessor: 'paidTo',
    type: 'string',
  },
  {
    Header: 'Account',
    accessor: 'category',
    type: 'badge',
    withIcon: false,
  },
];

export const columnsTransactionQuickBooks = [
  {
    Header: 'Date',
    accessor: 'date',
    type: 'string',
  },
  {
    Header: 'Description',
    accessor: 'description',
    type: 'string',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    type: 'string-bold',
  },
];

export const TeamsColumns = [
  {
    Header: 'Role group',
    accessor: 'role',
    type: 'dotted-badge',
  },
  {
    Header: 'Name',
    accessor: 'name',
    type: 'string',
  },
  {
    Header: 'Email',
    accessor: 'emailAddress',
    type: 'string',
  },
  {
    Header: 'Created on',
    accessor: 'createdDate',
    type: 'string',
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
  {
    Header: 'Action',
    accessor: 'modify',
    withPopover: true,
    type: 'modify',
  },
];

export const vendorColumns = [
  {
    Header: 'Vendor info',
    accessor: 'nameAndAddressOrEmail',
    type: 'double-string',
    withImg: true,
  },
  {
    Header: 'Categories',
    accessor: 'categories',
    withIcon: true,
    link: true,
    withNumber: true,
    numberList: true,
    isDropDown: false,
    type: 'string-badge',
  },
  {
    Header: 'Contact',
    accessor: 'contact',
    type: 'string',
  },
  {
    Header: 'Added on',
    accessor: 'createdDate',
    type: 'string',
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
  {
    Header: 'Action',
    accessor: 'modify',
    withPopover: true,
    type: 'modify',
  },
];

export const policyColumns = [
  {
    Header: 'Name & type',
    accessor: 'nameAndType',
    type: 'string-bold',
  },
  {
    Header: 'Description',
    accessor: 'description',
    type: 'string',
    truncateLength: 60,
  },
  // {
  //   Header: 'Enforced on',
  //   accessor: 'assignedBudget',
  //   withIcon: true,
  //   link: true,
  //   withNumber: true,
  //   numberList: true,
  //   isDropDown: true,
  //   type: 'string-badge',
  // },
  {
    Header: 'Last modified by',
    accessor: 'lastModifiedBy',
    type: 'string',
    withImg: true,
  },
  {
    Header: 'File',
    accessor: 'file',
    type: 'file',
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
  {
    Header: '',
    accessor: 'modify',
    withPopover: true,
    type: 'modify',
  },
];

export const categorizationRulesColumns = [
  {
    Header: 'Rule name',
    accessor: 'name',
    type: 'string-bold',
  },
  {
    Header: 'Description',
    accessor: 'description',
    type: 'string',
    truncateLength: 60,
  },
  {
    Header: 'Created on',
    accessor: 'date',
    type: 'string',
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
  {
    Header: '',
    accessor: 'modify',
    withPopover: true,
    type: 'modify',
  },
];

export const BatchColumn = [
  {
    Header: 'Recipient name',
    accessor: 'recipientName',
    type: 'string',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    type: 'string',
  },
  {
    Header: 'Account number',
    accessor: 'accountNumber',
    type: 'string',
  },
  {
    Header: 'Bank name',
    accessor: 'bankName',
    type: 'string',
  },
  {
    Header: 'Category',
    accessor: 'category',
    type: 'badge',
  },
  {
    Header: 'Description',
    accessor: 'description',
    type: 'string',
  },
  {
    Header: 'Action',
    accessor: 'modify',
    withPopover: true,
    type: 'modify',
  },
];

export const tableData = [
  {
    paidTo: 'Jumia Food',
    amount: '$12.99',
    by: {
      type: 'beneficiary',
      code: 'bnf_ifodfdofd',
      value: 'Adewale Akande',
      icon: <RightArrow />,
    },
    paymentMethod: {
      type: 'card',
      code: 'crd_92kd203DMod02',
      value: 'Bank Transfer',
      icon: <RightArrow />,
    },
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Office Maintenance',
      icon: <RightArrow />,
    },
    description: 'Restaurants',
    date: 'Today, 7.15 PM',
    status: { value: 'SUCCESS', color: getColor('success') },
  },
  {
    paidTo: 'Netflix',
    amount: '$.99',
    by: {
      type: 'beneficiary',
      code: 'bnf_ifodfdofd',
      value: 'Chalanmadiya Solomon',
      icon: <RightArrow />,
    },
    paymentMethod: {
      type: 'card',
      code: 'crd_92kd203DMod02',
      value: 'Virtual ••5549',
      icon: <RightArrow />,
    },
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Dubai Retreeat ‘22',
      icon: <RightArrow />,
    },
    description: 'Entertainment',
    date: 'Tuesday, 3.37 PM',
    status: { value: 'declined', color: getColor('declined') },
  },
  {
    paidTo: 'Amplitude Inc.',
    amount: '$299.99',
    by: {
      type: 'beneficiary',
      code: 'bnf_ifodfdofd',
      value: 'Berenger Zantangni',
      icon: <RightArrow />,
    },
    paymentMethod: {
      type: 'card',
      code: 'crd_92kd203DMod02',
      value: 'Virtual ••8333',
      icon: <RightArrow />,
    },
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Engineering Tools',
      icon: <RightArrow />,
    },
    description: 'SaaS / Software',
    date: 'Tuesday, 10.30 AM',
    status: { value: 'pending', color: getColor('pending') },
  },
  {
    paidTo: 'Jumia Food',
    amount: '$12.99',
    by: {
      type: 'beneficiary',
      code: 'bnf_ifodfdofd',
      value: 'Adewale Akande',
      icon: <RightArrow />,
    },
    paymentMethod: {
      type: 'card',
      code: 'crd_92kd203DMod02',
      value: 'Virtual ••5549',
      icon: <RightArrow />,
    },
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Office Maintenance',
      icon: <RightArrow />,
    },
    description: 'Restaurants',
    date: 'Today, 7.15 PM',
    status: { value: 'SUCCESS', color: getColor('success') },
  },
  {
    paidTo: 'Netflix',
    amount: '$.99',
    by: {
      type: 'beneficiary',
      code: 'bnf_ifodfdofd',
      value: 'Chalanmadiya Solomon',
      icon: <RightArrow />,
    },
    paymentMethod: {
      type: 'card',
      code: 'crd_92kd203DMod02',
      value: 'Virtual ••5549',
      icon: <RightArrow />,
    },
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Dubai Retreeat ‘22',
      icon: <RightArrow />,
    },
    description: 'Entertainment',
    date: 'Tuesday, 3.37 PM',
    status: { value: 'declined', color: getColor('declined') },
  },
  {
    paidTo: 'Amplitude Inc.',
    amount: '$299.99',
    by: {
      type: 'beneficiary',
      code: 'bnf_ifodfdofd',
      value: 'Berenger Zantangni',
      icon: <RightArrow />,
    },
    paymentMethod: {
      type: 'card',
      code: 'crd_92kd203DMod02',
      value: 'Virtual ••8333',
      icon: <RightArrow />,
    },
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Engineering Tools',
      icon: <RightArrow />,
    },
    description: 'SaaS / Software',
    date: 'Tuesday, 10.30 AM',
    status: { value: 'pending', color: getColor('pending') },
  },
  {
    paidTo: 'Jumia Food',
    amount: '$12.99',
    by: {
      type: 'beneficiary',
      code: 'bnf_ifodfdofd',
      value: 'Adewale Akande',
      icon: <RightArrow />,
    },
    paymentMethod: {
      type: 'card',
      code: 'crd_92kd203DMod02',
      value: 'Virtual ••5549',
      icon: <RightArrow />,
    },
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Office Maintenance',
      icon: <RightArrow />,
    },
    description: 'Restaurants',
    date: 'Today, 7.15 PM',
    status: { value: 'SUCCESS', color: getColor('SUCCESS') },
  },
  {
    paidTo: 'Netflix',
    amount: '$.99',
    by: {
      type: 'beneficiary',
      code: 'bnf_ifodfdofd',
      value: 'Chalanmadiya Solomon',
      icon: <RightArrow />,
    },
    paymentMethod: {
      type: 'card',
      code: 'crd_92kd203DMod02',
      value: 'Virtual ••5549',
      icon: <RightArrow />,
    },
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Dubai Retreeat ‘22',
      icon: <RightArrow />,
    },
    description: 'Entertainment',
    date: 'Tuesday, 3.37 PM',
    status: { value: 'declined', color: getColor('declined') },
  },
  {
    paidTo: 'Amplitude Inc.',
    amount: '$299.99',
    by: {
      type: 'beneficiary',
      code: 'bnf_ifodfdofd',
      value: 'Berenger Zantangni',
      icon: <RightArrow />,
    },
    paymentMethod: {
      type: 'card',
      code: 'crd_92kd203DMod02',
      value: 'Virtual ••8333',
      icon: <RightArrow />,
    },
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Engineering Tools',
      icon: <RightArrow />,
    },
    description: 'SaaS / Software',
    date: 'Tuesday, 10.30 AM',
    status: { value: 'pending', color: getColor('pending') },
  },
];

export const tableData2 = [
  {
    paidTo: 'Netflix',
    amount: '$.99',
    by: {
      type: 'beneficiary',
      code: 'bnf_ifodfdofd',
      value: 'Chalanmadiya Solomon',
      icon: <RightArrow />,
    },
    paymentMethod: {
      type: 'card',
      code: 'crd_92kd203DMod02',
      value: 'Virtual ••5549',
      icon: <RightArrow />,
    },
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Dubai Retreeat ‘22',
      icon: <RightArrow />,
    },
    description: 'Entertainment',
    date: 'Tuesday, 3.37 PM',
    status: { value: 'declined', color: getColor('declined') },
  },
  {
    paidTo: 'Amplitude Inc.',
    amount: '$299.99',
    by: {
      type: 'beneficiary',
      code: 'bnf_ifodfdofd',
      value: 'Berenger Zantangni',
      icon: <RightArrow />,
    },
    paymentMethod: {
      type: 'card',
      code: 'crd_92kd203DMod02',
      value: 'Virtual ••8333',
      icon: <RightArrow />,
    },
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Engineering Tools',
      icon: <RightArrow />,
    },
    description: 'SaaS / Software',
    date: 'Tuesday, 10.30 AM',
    status: { value: 'pending', color: getColor('pending') },
  },
  {
    paidTo: 'Amplitude Inc.',
    amount: '$299.99',
    by: {
      type: 'beneficiary',
      code: 'bnf_ifodfdofd',
      value: 'Berenger Zantangni',
      icon: <RightArrow />,
    },
    paymentMethod: {
      type: 'card',
      code: 'crd_92kd203DMod02',
      value: 'Virtual ••8333',
      icon: <RightArrow />,
    },
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Engineering Tools',
      icon: <RightArrow />,
    },
    description: 'SaaS / Software',
    date: 'Tuesday, 10.30 AM',
    status: { value: 'pending', color: getColor('pending') },
  },
  {
    paidTo: 'Netflix',
    amount: '$.99',
    by: {
      type: 'beneficiary',
      code: 'bnf_ifodfdofd',
      value: 'Chalanmadiya Solomon',
      icon: <RightArrow />,
    },
    paymentMethod: {
      type: 'card',
      code: 'crd_92kd203DMod02',
      value: 'Virtual ••5549',
      icon: <RightArrow />,
    },
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Dubai Retreeat ‘22',
      icon: <RightArrow />,
    },
    description: 'Entertainment',
    date: 'Tuesday, 3.37 PM',
    status: { value: 'declined', color: getColor('declined') },
  },
  {
    paidTo: 'Amplitude Inc.',
    amount: '$299.99',
    by: {
      type: 'beneficiary',
      code: 'bnf_ifodfdofd',
      value: 'Berenger Zantangni',
      icon: <RightArrow />,
    },
    paymentMethod: {
      type: 'card',
      code: 'crd_92kd203DMod02',
      value: 'Virtual ••8333',
      icon: <RightArrow />,
    },
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Engineering Tools',
      icon: <RightArrow />,
    },
    description: 'SaaS / Software',
    date: 'Tuesday, 10.30 AM',
    status: { value: 'pending', color: getColor('pending') },
  },
];

export const columnsCards = [
  {
    Header: 'Name',
    accessor: 'name',
    type: 'string',
    withImg: true,
    width: 192,
  },

  {
    Header: 'Card holder',
    accessor: 'user',
    type: 'string',
    withImg: true,
    width: 192,
  },

  {
    Header: 'Card',
    accessor: 'card',
    type: 'double-string',
    withImg: true,
    width: 192,
  },

  {
    Header: 'Budget',
    accessor: 'budget',
    type: 'string',
    link: true,
    withImg: true,
    width: 192,
  },

  {
    Header: 'Payment limit',
    accessor: 'limit',
    type: 'progress-bar',
    width: 352,
  },

  {
    Header: 'Date',
    accessor: 'dateCreated',
    type: 'string',
    width: 192,
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
    width: 64,
  },
  {
    Header: 'Action',
    accessor: 'modify',
    withPopover: true,
    type: 'modify',
  },
];

export const requestedCards = [
  {
    Header: 'Card Requester',
    accessor: 'requester',
    type: 'string',
    withImg: true,
    width: 192,
  },

  {
    Header: 'Card',
    accessor: 'card',
    type: 'string',
    withImg: true,
    width: 192,
  },

  {
    Header: 'Budget',
    accessor: 'budget',
    type: 'string',
    link: true,
    withImg: true,
    width: 192,
  },

  {
    Header: 'Requested On',
    accessor: 'date',
    type: 'string',
    width: 192,
  },

  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
    width: 64,
  },
  {
    Header: 'Action',
    accessor: 'modify',
    withPopover: true,
    type: 'modify',
  },
];

export const tableDataCards = [
  {
    type: { value: 'physical', color: getColor('physical') },
    cardHolder: {
      type: 'cardHolder',
      code: 'bnf_ifodfdofd',
      value: 'Chalanmadiya Solomon',
      icon: <RightArrow />,
    },
    paymentMethod: {
      type: 'card',
      code: 'bnf_ifodfdofd',
      value: 'Mastercard ••• •••',
      icon: null,
    },
    expiry: '•• / ••',
    attachedBudget: {
      type: 'attachedBudget',
      code: 'bnf_ifodfdofd',
      value: 'Office Maintenance',
      icon: <RightArrow />,
    },
    totalSpent: '$0.00',
    dateCreated: '5 mins ago',
    status: { value: 'processing', color: getColor('processing') },
  },
  {
    type: { value: 'virtual', color: getColor('virtual') },
    cardHolder: {
      type: 'cardHolder',
      code: 'bnf_ifodfdofd',
      value: 'Adewale Akande',
      icon: <RightArrow />,
    },
    paymentMethod: {
      type: 'card',
      code: 'bnf_ifodfdofd',
      value: 'VISA ••5549',
      icon: <RightArrow />,
    },
    expiry: '02 / 26',
    attachedBudget: {
      type: 'attachedBudget',
      code: 'bnf_ifodfdofd',
      value: 'Engineering Tools',
      icon: <RightArrow />,
    },
    totalSpent: '$1,254.93',
    dateCreated: '21 Mar, 12.15 PM',
    status: { value: 'active', color: getColor('active') },
  },

  {
    type: { value: 'virtual', color: getColor('virtual') },
    cardHolder: {
      type: 'cardHolder',
      code: 'bnf_ifodfdofd',
      value: 'Berenger Zantangni',
      icon: <RightArrow />,
    },
    paymentMethod: {
      type: 'card',
      code: 'bnf_ifodfdofd',
      value: 'VISA ••0012',
      icon: <RightArrow />,
    },
    expiry: '09 / 25',
    attachedBudget: {
      type: 'attachedBudget',
      code: 'bnf_ifodfdofd',
      value: 'Dubai Retreeat ‘22',
      icon: <RightArrow />,
    },
    totalSpent: '$6,112.81',
    dateCreated: '29 Mar, 11.38 AM',
    status: { value: 'disabled', color: getColor('disabled') },
  },
  {
    type: { value: 'virtual', color: getColor('virtual') },
    cardHolder: {
      type: 'cardHolder',
      code: 'bnf_ifodfdofd',
      value: 'Adewale Akande',
      icon: <RightArrow />,
    },
    paymentMethod: {
      type: 'card',
      code: 'bnf_ifodfdofd',
      value: 'VISA ••5549',
      icon: <RightArrow />,
    },
    expiry: '02 / 26',
    attachedBudget: {
      type: 'attachedBudget',
      code: 'bnf_ifodfdofd',
      value: 'Engineering Tools',
      icon: <RightArrow />,
    },
    totalSpent: '$164.99',
    dateCreated: '21 Mar, 12.15 PM',
    status: { value: 'active', color: getColor('active') },
  },
  {
    type: { value: 'physical', color: getColor('physical') },
    cardHolder: {
      type: 'cardHolder',
      code: 'bnf_ifodfdofd',
      value: 'Chalanmadiya Solomon',
      icon: <RightArrow />,
    },
    paymentMethod: {
      type: 'card',
      code: 'bnf_ifodfdofd',
      value: 'Mastercard ••• •••',
      icon: null,
    },
    expiry: '05 / 22',
    attachedBudget: {
      type: 'attachedBudget',
      code: 'bnf_ifodfdofd',
      value: 'Office Maintenance',
      icon: <RightArrow />,
    },
    totalSpent: '$0.00',
    dateCreated: '5 mins ago',
    status: { value: 'processing', color: getColor('processing') },
  },
  {
    type: { value: 'virtual', color: getColor('virtual') },
    cardHolder: {
      type: 'cardHolder',
      code: 'bnf_ifodfdofd',
      value: 'Adewale Akande',
      icon: <RightArrow />,
    },
    paymentMethod: {
      type: 'card',
      code: 'bnf_ifodfdofd',
      value: 'VISA ••5549',
      icon: <RightArrow />,
    },
    expiry: '02 / 26',
    attachedBudget: {
      type: 'attachedBudget',
      code: 'bnf_ifodfdofd',
      value: 'Engineering Tools',
      icon: <RightArrow />,
    },
    totalSpent: '$1,254.93',
    dateCreated: '21 Mar, 12.15 PM',
    status: { value: 'active', color: getColor('active') },
  },

  {
    type: { value: 'virtual', color: getColor('virtual') },
    cardHolder: {
      type: 'cardHolder',
      code: 'bnf_ifodfdofd',
      value: 'Berenger Zantangni',
      icon: <RightArrow />,
    },
    paymentMethod: {
      type: 'card',
      code: 'bnf_ifodfdofd',
      value: 'VISA ••0012',
      icon: <RightArrow />,
    },
    expiry: '09 / 25',
    attachedBudget: {
      type: 'attachedBudget',
      code: 'bnf_ifodfdofd',
      value: 'Dubai Retreeat ‘22',
      icon: <RightArrow />,
    },
    totalSpent: '$6,112.81',
    dateCreated: '29 Mar, 11.38 AM',
    status: { value: 'disabled', color: getColor('disabled') },
  },
  {
    type: { value: 'virtual', color: getColor('virtual') },
    cardHolder: {
      type: 'cardHolder',
      code: 'bnf_ifodfdofd',
      value: 'Adewale Akande',
      icon: <RightArrow />,
    },
    paymentMethod: {
      type: 'card',
      code: 'bnf_ifodfdofd',
      value: 'VISA ••5549',
      icon: <RightArrow />,
    },
    expiry: '02 / 26',
    attachedBudget: {
      type: 'attachedBudget',
      code: 'bnf_ifodfdofd',
      value: 'Engineering Tools',
      icon: <RightArrow />,
    },
    totalSpent: '$164.99',
    dateCreated: '21 Mar, 12.15 PM',
    status: { value: 'active', color: getColor('active') },
  },
];

export const columnsBeneficiaries = [
  {
    Header: 'Name',
    accessor: 'name',
    type: 'double-string',
    withImg: true,
  },
  {
    Header: 'Role',
    accessor: 'role',
    type: 'badge',
  },
  {
    Header: 'Manager',
    accessor: 'manager',
    type: 'dotted-badge',
  },
  {
    Header: 'Assigned budget(s)',
    accessor: 'assignedBudget',
    withIcon: true,
    link: true,
    withNumber: true,
    numberList: true,
    isDropDown: true,
    type: 'string-badge',
  },
  {
    Header: 'Added on',
    accessor: 'dateAdded',
    type: 'string',
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
  {
    Header: 'Action',
    accessor: 'modify',
    withPopover: true,
    type: 'modify',
  },
];

export const temporaryColumnsBeneficiaries = [
  {
    Header: 'Name',
    accessor: 'name',
    type: 'double-string',
  },
  {
    Header: 'Role',
    accessor: 'role',
    type: 'badge',
  },
  {
    Header: 'Manager',
    accessor: 'manager',
    type: 'dotted-badge',
  },

  {
    Header: 'Team',
    accessor: 'team',
    type: 'dotted-badge',
  },
  {
    Header: 'Action',
    accessor: 'modify',
    withPopover: true,
    type: 'modify',
  },
];

export const columnsSubAccountMembers = [
  {
    Header: 'Full Name',
    accessor: 'name',
    type: 'string',
    withImg: true,
  },
  {
    Header: 'Role',
    accessor: 'role',
    type: 'string',
  },
  {
    Header: 'Last Activity',
    accessor: 'dateAdded',
    type: 'string',
  },
  {
    Header: '',
    accessor: 'modify',
    custom: <span>Remove</span>,
    withDelete: true,
    type: 'modify',
  },
];

export const columnsAccountStatement = [
  {
    Header: 'Date',
    accessor: 'created_at',
    type: 'string',
  },
  {
    Header: 'Narration',
    accessor: 'description',
    type: 'string',
  },
  {
    Header: 'Credit/Debit',
    accessor: 'amount',
    type: 'string-bold',
    withColor: true,
  },
  {
    Header: 'Type',
    accessor: 'type',
    type: 'string',
  },
  {
    Header: 'From',
    accessor: 'from',
    type: 'string',
  },
  {
    Header: 'To',
    accessor: 'to',
    type: 'string',
  },
  {
    Header: 'Balance after',
    accessor: 'balance',
    type: 'string',
  },
];

export const columnsSingleBudgetBeneficiaries = [
  {
    Header: 'Name',
    accessor: 'name',
    type: 'double-string',
    withImg: true,
  },
  {
    Header: 'Role',
    accessor: 'role',
    type: 'double-badge',
  },
  {
    Header: 'Spending limit',
    accessor: 'amount',
    type: 'string',
  },
  {
    Header: 'Date added',
    accessor: 'dateAdded',
    type: 'string',
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
  { Header: 'Action', accessor: 'modify', withPopover: true, type: 'modify' },
];

export const columnsReimbursement = [
  {
    Header: 'Claimed by',
    accessor: 'by',
    type: 'string',
    withImg: true,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    type: 'string-bold',
  },
  {
    Header: 'Description',
    accessor: 'description',
    type: 'string',
  },
  {
    Header: 'Files',
    accessor: 'evidence',
    type: 'string',
  },

  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
  {
    Header: 'Claim date',
    accessor: 'creationDate',
    type: 'string',
  },
  {
    Header: 'Action',
    accessor: 'modify',
    type: 'modify',
    withPopover: true,
  },
];

export const columnsRequests = [
  {
    Header: 'Requested by',
    accessor: 'by',
    type: 'string',
    withImg: true,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    type: 'string-bold',
  },
  {
    Header: 'Type',
    accessor: 'type',
    type: 'badge',
  },
  {
    Header: 'Reason',
    accessor: 'description',
    type: 'string',
  },
  {
    Header: 'Files',
    accessor: 'evidence',
    type: 'string',
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
  {
    Header: 'Requested on',
    accessor: 'creationDate',
    type: 'string',
  },
  {
    Header: 'Action',
    accessor: 'modify',
    type: 'modify',
    withPopover: true,
  },
];

export const employeeColumnsReimbursement = [
  {
    Header: 'Vendor',
    accessor: 'vendor',
    type: 'string-badge',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    type: 'string-bold',
  },
  {
    Header: 'Description',
    accessor: 'description',
    type: 'string',
  },
  {
    Header: 'Files',
    accessor: 'evidence',
    type: 'string',
  },
  {
    Header: 'Reviewed by ',
    accessor: 'reviewer',
    type: 'string-badge',
    //withIcon: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
  {
    Header: 'Claim date',
    accessor: 'creationDate',
    type: 'string',
  },
];

export const employeeColumnsRequest = [
  {
    Header: 'Destination',
    accessor: 'destination',
    type: 'string-badge',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    type: 'string-bold',
  },
  {
    Header: 'Type',
    accessor: 'type',
    type: 'badge',
  },
  {
    Header: 'Description',
    accessor: 'description',
    type: 'string',
  },
  {
    Header: 'Files',
    accessor: 'evidence',
    type: 'string',
  },
  {
    Header: 'Reviewed by',
    accessor: 'reviewer',
    type: 'string-badge',
    //withIcon: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
  {
    Header: 'Requested on',
    accessor: 'creationDate',
    type: 'string',
  },
  {
    Header: 'Action',
    accessor: 'modify',
    withPopover: true,
    type: 'modify',
  },
];

export const approvalColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    type: 'string',
  },
  { Header: 'Levels', accessor: 'approvalLevel', type: 'string' },
  { Header: 'Approvers', accessor: 'approvers', type: 'avatar-list' },
  {
    Header: 'Triggers ',
    accessor: 'triggers',
    type: 'string',
  },
  {
    Header: 'Date',
    accessor: 'date',
    type: 'string',
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
  {
    Header: 'Action',
    accessor: 'modify',
    withPopover: true,
    type: 'modify',
  },
];

export const pendingApprovalColumns = [
  {
    Header: 'Requested by',
    accessor: 'name',
    type: 'string',
    withImg: true,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    type: 'string-bold',
  },
  {
    Header: 'Description',
    accessor: 'description',
    type: 'string',
  },
  {
    Header: 'Type',
    accessor: 'type',
    type: 'string',
  },
  {
    Header: 'Date',
    accessor: 'date',
    type: 'string',
  },
  {
    Header: 'Files',
    accessor: 'evidence',
    type: 'string',
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
  {
    Header: 'Action',
    accessor: 'modify',
    withPopover: true,
    type: 'modify',
  },
];

export const pendingInvoiceApprovalColumns = [
  {
    Header: 'Requested by',
    accessor: 'name',
    type: 'string',
    withImg: true,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    type: 'string-bold',
  },
  {
    Header: 'Description',
    accessor: 'description',
    type: 'string',
  },

  {
    Header: 'Date',
    accessor: 'date',
    type: 'string',
  },
  {
    Header: 'Files',
    accessor: 'evidence',
    type: 'string',
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
  {
    Header: 'Action',
    accessor: 'modify',
    withPopover: true,
    type: 'modify',
  },
];

export const PendingApprovalTableData = [
  {
    name: 'David Johnson',
    amount: '$12.99',
    description: 'only for flexing that and jayejaye...',
    type: 'Reimbursement',
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Dubai Retreeat ‘22',
      icon: <RightArrow />,
    },
    date: 'Today 7:15 PM',
    status: { value: 'SUCCESS', color: getColor('success') },
  },
  {
    name: 'Rejoice Johnson',
    amount: '$13.99',
    description: 'Rejected this approval because the amount was too small',
    type: 'Reimbursement',
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Dubai Retreeat ‘22',
      icon: <RightArrow />,
    },
    date: 'Today 7:15 PM',
    status: { value: 'DECLINED', color: getColor('declined') },
  },
];

export const invoiceColumns = [
  {
    Header: 'Customer name',
    accessor: 'name',
    type: 'double-string',
    withImg: true,
  },
  {
    Header: 'Amount ',
    accessor: 'amount',
    type: 'string',
  },
  {
    Header: 'Issued date ',
    accessor: 'issued_date',
    type: 'string',
  },
  {
    Header: 'Due date ',
    accessor: 'due_date',
    type: 'string',
  },
  {
    Header: 'Paid on',
    accessor: 'date_paid',
    type: 'string',
  },

  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
  {
    Header: 'Action',
    accessor: 'modify',
    withPopover: true,
    type: 'modify',
  },
];

export const customerInvoiceColumns = [
  {
    Header: 'Customer name',
    accessor: 'name',
    type: 'double-string',
    withImg: true,
  },
  {
    Header: 'Amount ',
    accessor: 'amount',
    type: 'string',
  },
  {
    Header: 'Issued date ',
    accessor: 'issued_date',
    type: 'string',
  },
  {
    Header: 'Due date ',
    accessor: 'due_date',
    type: 'string',
  },
  {
    Header: 'Paid on',
    accessor: 'date_paid',
    type: 'string',
  },

  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
];

export const recurringInvoiceColumns = [
  {
    Header: 'Customer name',
    accessor: 'name',
    type: 'double-string',
    withImg: true,
  },
  {
    Header: 'Amount ',
    accessor: 'amount',
    type: 'string',
  },
  {
    Header: 'Creation date ',
    accessor: 'issued_date',
    type: 'string',
  },

  {
    Header: 'Next invoice date',
    accessor: 'next_due',
    type: 'string',
  },

  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
  {
    Header: 'Action',
    accessor: 'modify',
    withPopover: true,
    type: 'modify',
  },
];

export const customersColumn = [
  {
    Header: 'Customer name',
    accessor: 'name',
    type: 'string',
    withImg: true,
  },

  {
    Header: 'Contact information',
    accessor: 'email',
    type: 'string',
    withImg: true,
  },

  {
    Header: 'Type',
    accessor: 'type',
    type: 'string',
  },

  {
    Header: 'Outstanding',
    accessor: 'outstanding',
    type: 'string',
  },

  {
    Header: 'Date created ',
    accessor: 'date',
    type: 'string',
  },

  {
    Header: '',
    accessor: 'modify',
    withPopover: true,
    type: 'modify',
  },
];

export const rolesColumn = [
  {
    Header: 'Name',
    accessor: 'name',
    type: 'badge',
    width: 168,
  },
  {
    Header: 'Type',
    accessor: 'type',
    type: 'string',
    width: 132,
  },
  {
    Header: 'Description',
    accessor: 'description',
    type: 'string',
    truncate: false,
    width: 420,
  },
  {
    Header: 'Created by',
    accessor: 'created_by',
    type: 'string',
    width: 200,
  },
  {
    Header: 'Action',
    accessor: 'modify',
    withPopover: true,
    type: 'modify',
    width: 64,
  },
];

export const rolesTableData = [
  {
    name: {
      value: 'admin',
      color: getColor('success'),
    },
    type: 'Default',
    permissions:
      'Can manage, view and edit the organization details. Can manage the dashboard, manage bank accounts, add funds etc..',
    created_by: 'Bujeti',
  },
  {
    name: {
      value: 'Team lead',
      color: getColor('pending'),
    },
    type: 'Custom',
    permissions:
      'Can view dashboard, teams, requests, transactions, transfers, cards and budgets ',
    created_by: 'Juwon Daniel',
  },
];

export const invoiceTableData = [
  {
    name: 'Rukayyah Designs',
    invoice_id: '#001',
    amount: '14000',
    issued_date: 'Today 7:15 PM',
    due_date: 'Today 7:15 PM',
    date_paid: 'Today 10:15 PM',
    customers_email: 'juwonanthony@gmail.com',
    status: { value: 'SUCCESS', color: getColor('success') },
    modify: true,
  },
  {
    name: 'Timothy Emeka',
    invoice_id: '#021',
    amount: '14500',
    issued_date: 'Today 7:15 PM',
    due_date: 'Today 7:15 PM',
    date_paid: 'Today 10:15 PM',
    customers_email: 'juwonanthony@gmail.com',
    status: { value: 'PENDING', color: getColor('pending') },
    modify: true,
  },
];

export const InvoiceHistoryColumn = [
  {
    Header: 'Invoice',
    accessor: 'invoice',
    type: 'string',
  },
  {
    Header: 'Description',
    accessor: 'description',
    type: 'string',
    truncate: false,
    width: 420,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    type: 'string',
  },
  {
    Header: 'Date',
    accessor: 'date',
    type: 'string',
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
  {
    Header: '',
    accessor: 'modify',
    withDownload: true,
    type: 'modify',
  },
];

export const ReferralColumn = [
  {
    Header: 'Recipient email',
    accessor: 'email',
    type: 'string',
    truncate: false,
  },
  {
    Header: 'Valid until',
    accessor: 'validUntil',
    type: 'string',
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
];

export const InvoiceHistoryTableData = [
  {
    invoice: 'Invoice #002 - Jan 2023',
    description: '1-month subscription, Growth Plan',
    amount: '50,000',
    date: 'Today 7:15 PM',
    status: { value: 'SUCCESS', color: getColor('success') },
    modify: true,
  },
];

export const OwnersColumn = [
  {
    Header: 'Name',
    accessor: 'name',
    type: 'string',
    truncate: false,
  },
  {
    Header: 'Email',
    accessor: 'email',
    type: 'string',
    truncate: false,
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'string',
  },
];

export const exportVendorHeader = [
  'Vendor name',
  'Email',
  'Phone',
  'Description',
  'Website',
  'Country',
  'State',
  'City',
  'Street',
  'Tax identification number',
  'Tax withholding',
  'Bank name',
  'Bank code',
  'Account number',
  'Category',
  'Status',
  'Created at',
];

export const PreviewBatchPaymentList = [
  {
    Header: 'Recipient name',
    accessor: 'recipientName',
    type: 'string',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    type: 'string',
  },
  {
    Header: 'Account number',
    accessor: 'accountNumber',
    type: 'string',
  },
  {
    Header: 'Bank name',
    accessor: 'bankName',
    type: 'string',
  },
  {
    Header: 'Source',
    accessor: 'balance',
    type: 'string',
  },
  {
    Header: 'Category',
    accessor: 'category',
    type: 'badge',
  },
  {
    Header: 'Description',
    accessor: 'description',
    type: 'string',
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
  {
    Header: 'Action',
    accessor: 'modify',
    withPopover: true,
    type: 'modify',
  },
];
