import {
  BankIcon,
  BankNoteO1Icon,
  CalenderIcon,
  CheckIcon,
  FIlePlusIcon,
  SendIcon,
  SlashCircleIcon,
  TrashIcon,
} from 'assets/icons';
import FloatingCta from 'components/BulkAction/FloatingCta';
import ConfirmDialog from 'components/ConfirmDialog';
import {
  CurrencyType,
  ReimbursementStatusType,
} from 'components/FilterModal/FilterHelper';
import NoData from 'components/NoData';
import BulkApprovalDialog from 'components/ReimbursementView/ManagerModal/BulkApprovalDialog';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import SchedulePayment from 'components/TransactionModal/SchedulePayment';
import CustomDrawer from 'components/UI/CustomDrawer';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import Loading from 'components/UI/Loading';
import CustomModal from 'components/UI/Modal';
import { toastError, toastSuccess } from 'components/UI/toast';
import { useDebounce } from 'hooks/useDebounce';
import BatchPaymentDialog from 'pages/BatchPayment/BatchPaymentDialog';
import RequestEmptyStateData from 'pages/Requests/requestEmptystateData';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { reviewRequest } from 'redux/actions/ApprovalAction';
import { getBalances } from 'redux/actions/BudgetsAction';
import { createBulkPayment, payNowAction } from 'redux/actions/PaymentAction';
import {
  approveRequests,
  deleteMultipleRequests,
  deleteRequests,
  getRequests,
  getSingleRequest,
  reviewMultipleRequests,
  updateRequests,
} from 'redux/actions/RequestsAction';
import {
  RESET_BLOCK_REQUESTS,
  RESET_FLAGS_REQUESTS,
} from 'redux/reducers/RequestsReducer';
import { SELECTED_TABLE_ROWS } from 'redux/reducers/TableReducer';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { columnsRequests } from 'utils/mockData';
import { FEES, getQueryParams, updateStatus } from 'utils/utility';
import {
  buildRequestTableData,
  capitalizeFirstLetter,
  getAvailableBalance,
  getCurrency,
  groupSourceOptions,
} from '../../../utils/helper';
import RequestFund from '../RequesterModal/RequestFunds';
import ReviewerModal from '../ReviewerModal';
import DeclineRequest from '../ReviewerModal/DeclineRequest';
import RequestMoreDetails from '../ReviewerModal/RequestMoreDetails';
import { approvalState } from '../ReviewerModal/approvalState';
import '../style.less';

const ManageFundRequest = ({
  setIsOtherPopoverOpen,
  isOpen,
  toggleHandler,
  requestCode,
  onRowSelect,
}) => {
  const [selectRequest, setSelectRequest] = useState(null);
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState([
    ...ReimbursementStatusType,
    ...CurrencyType,
  ]);
  const [isFillData, setIsFillData] = useState(false);
  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;
  const [filteredQuery, setFilteredQuery] = useState({});
  const location = useLocation();
  const [search, setSearch] = useState('');
  const [selectedRowInformation, setSelectedRowInformation] = useState(null);
  const [filteredSelectedRows, setFilteredSelectedRows] = useState(null);
  const [additionalNote, setAdditionalNote] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const [action, setAction] = useState('');
  const [fees, setFees] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [reFetchData, setReFetchData] = useState(false);
  const [reSelectRows, setReSelectRows] = useState(false);
  const [fetchDataSuccess, setFetchDataSuccess] = useState(false);
  const debouncedValue = useDebounce(search, 600);

  const [loadActions, setLoadActions] = useState(false);
  const [isSchedule, setIsSchedule] = useState(false);
  const [declineRequest, setDeclineRequest] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [type, setType] = useState(null);
  const [payNow, setPayNow] = useState(false);
  const [requestInfo, setRequestInfo] = useState(false);
  const [source, setSource] = useState('');

  const { isAdmin } = allPermissions();

  const {
    downloadAsset: { data: { asset } = {}, success: assetSuccess },
  } = useSelector(({ assets }) => assets);

  const {
    getRequest: { data: { meta = {} } = {}, data = {}, loading, success },
    updateRequest: { success: updateSuccess, loading: updateLoading },
    reviewMultipleRequests: { success: reviewCompleted, loading: isReviewing },
    deleteMultipleRequests: {
      success: deleteMultipleSuccess,
      loading: deleteMultipleLoading,
    },
    approveRequest: { loading: loadingApprove, success: successApprove },
    declineRequest: { success: successDecline },
    getSingleRequest: { data: singleRequest = {}, error, success: successSingleRequest },
    deleteRequest: { loading: deleteLoading, success: deleteSuccess },
  } = useSelector(({ requests }) => requests);

  const { page, total, hasMore, perPage, nextPage } = meta;
  const { fundRequests: requests = [] } = data;

  const {
    selectedTableRows: { selectedRows, type: rowType },
  } = useSelector(({ table }) => table);

  const {
    payNowAction: { loading: loadingPayNow, success: successPayNow },
    createBulkPayment: { success: paymentCompleted, loading: isPaying },
  } = useSelector(({ payments }) => payments);

  const { user: { data: user = {} } = {} } = useSelector(({ auth }) => auth);

  const {
    reviewRequest: { loading: loadingReview, success: successReview },
  } = useSelector(({ approval }) => approval);

  const {
    getBalances: { data: balances, loading: loadingBalances },
  } = useSelector(({ budgets }) => budgets);

  const activeTxCode = useRef(null);

  const { permissions } = allPermissions();
  const canViewAdmin = hasPermission({
    permissions,
    scopes: ['request-*', 'request-view'],
  });

  const canCreate = hasPermission({
    permissions,
    scopes: ['request-*', 'request-create'],
  });

  const tableColumn = useMemo(() => columnsRequests, [requests]);

  const rows = useMemo(() => buildRequestTableData(requests), [requests]);

  useEffect(() => {
    if (assetSuccess) {
      window.open(asset.url, 'Download');
    }
  }, [assetSuccess]);

  useEffect(() => {
    if (reviewCompleted || deleteMultipleSuccess || paymentCompleted) {
      isFiltered.current = true;
      setIsSubmit(false);
      dispatch({ type: SELECTED_TABLE_ROWS, payload: { shouldClearRows: true } });
      if (reviewCompleted)
        dispatch({
          type: RESET_BLOCK_REQUESTS,
          blockType: 'reviewMultipleRequests',
        });
      if (deleteMultipleSuccess)
        dispatch({
          type: RESET_BLOCK_REQUESTS,
          blockType: 'deleteMultipleRequests',
        });
      setAdditionalNote('');
      dispatch(getRequests({ perPage, page, ...filteredQuery }));
    }
  }, [reviewCompleted, deleteMultipleSuccess, paymentCompleted]);

  useEffect(() => {
    if (!requests.length && canViewAdmin && !location?.search) dispatch(getRequests());

    return () => {
      if ((filtered || location?.search) && canViewAdmin) dispatch(getRequests());
    };
  }, [filtered]);

  useEffect(() => {
    if (debouncedValue && canViewAdmin) {
      filteredQuery.search = debouncedValue;
      dispatch(getRequests({ ...filteredQuery, search: debouncedValue }));
    }
    if (!debouncedValue && filtered && canViewAdmin) {
      delete filteredQuery.search;
      dispatch(getRequests({ ...filteredQuery }));
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  const handleRowClick = (row) => {
    setSelectRequest(row);
    activeTxCode.current = null;
  };

  useEffect(() => {
    if (requests.length > 0 && !isFillData) {
      setIsFillData(true);

      const allRequest = requests.map((data) => {
        return {
          value: data.user ? data.user.code : null,
          label: data.user ? `${data.user.firstName} ${data.user.lastName}` : 'N/A',
          isSelected: false,
        };
      });
      const uniqueArray = allRequest.filter(
        (v, i, a) => a.findIndex((v2) => v2.value === v.value) === i,
      );

      setFilterData([...filterData, { title: 'Beneficiary', list: uniqueArray }]);
      if (requestCode) {
        dispatch(getSingleRequest(requestCode));
      }
    }
  }, [requests]);

  useEffect(() => {
    if (successSingleRequest && requestCode) {
      const row = buildRequestTableData([singleRequest?.data?.fundRequest]);
      const element = row.find(({ requestData: { code } }) => code === requestCode);
      setSelectRequest(element);
    }
  }, [successSingleRequest]);

  const handleFilter = (query) => {
    if (reSelectRows) setReSelectRows(false);
    isFiltered.current = !!Object.keys(query).length;
    const { status: s, currency: c, beneficiary: b } = query;
    const status = s ? s.toString() : undefined;
    const currency = c ? c.toString() : undefined;
    const user = b ? b.toString() : undefined;
    dispatch(
      getRequests({
        status,
        currency,
        user,
      }),
    );
    setFilteredQuery({
      status,
      currency,
      user,
    });
  };

  useEffect(() => {
    if (location?.search && canViewAdmin) {
      const status = getQueryParams(location?.search, 'status');
      if (status) {
        dispatch(getRequests({ status }));
        setFilterData(updateStatus(filterData, 'Status', status));
      }
    }
  }, [location]);

  useEffect(() => {
    if (success && location?.search) isFiltered.current = true;
    if (success) {
      setFetchDataSuccess(true);
      dispatch({ type: RESET_FLAGS_REQUESTS, blockType: 'getRequest' });
    } else {
      setFetchDataSuccess(false);
    }
  }, [success]);

  useEffect(() => {
    if (updateSuccess && !reFetchData) setReFetchData(true);
  }, [updateSuccess]);

  const handlePreviousPage = (page) => {
    dispatch(getRequests({ perPage, page, ...filteredQuery }));
  };

  const handleNextPage = (page) => {
    dispatch(getRequests({ perPage, page, ...filteredQuery }));
  };

  const calculateSum = (requestLists) => {
    let totalSum = requestLists.reduce(
      (total, { requestData: { amount = 0 } }) => amount + total,
      0,
    );

    return totalSum / 100;
  };

  useEffect(() => {
    if (rowType === 'funds') {
      let totalAllSum = calculateSum(selectedRows);

      const filteredPendingRows = selectedRows.filter(
        ({ status: { value: rowStatus } }) => 'pending' === rowStatus,
      );
      const filteredDraftRows = selectedRows.filter(
        ({ status: { value: rowStatus } }) => 'draft' === rowStatus,
      );
      const filteredApprovedRows = selectedRows.filter(
        ({ status: { value: rowStatus } }) => 'approved' === rowStatus,
      );

      setSelectedRowInformation({
        all: {
          count: selectedRows.length,
          sum: totalAllSum,
        },
        pending: {
          count: filteredPendingRows?.length,
        },
        draft: {
          count: filteredDraftRows?.length,
        },
        approved: {
          count: filteredApprovedRows?.length,
        },
      });

      setFilteredSelectedRows({
        pending: filteredPendingRows,
        draft: filteredDraftRows,
        approved: filteredApprovedRows,
      });
    }
  }, [selectedRows.length]);

  const handlePay = () => {
    setReFetchData(false);

    if (['decline', 'approve'].includes(action)) {
      if (action === 'decline' && !additionalNote)
        toastError('Please input reason for decline');

      const payload = {
        requests: filteredSelectedRows?.['pending']?.map(
          ({ requestData: { code = '' } }) => code,
        ),
        decision: action === 'delete' ? undefined : action,
        ...(additionalNote && { reason: additionalNote }),
      };
      dispatch(reviewMultipleRequests(payload));
    } else if (action === 'pay') {
      let hasNoSource = 0;
      const payload = {
        items: filteredSelectedRows?.['approved']?.map(
          ({ requestData: { code = '', source } }) => {
            if (!source) hasNoSource += 1;
            return {
              code,
              source: source?.code,
            };
          },
        ),
      };
      if (hasNoSource > 0)
        return toastError(`${hasNoSource} of the selected items has no source`);
      dispatch(createBulkPayment(payload));
    } else if (action === 'delete') {
      const payload = {
        requests: [
          ...filteredSelectedRows?.['pending']?.map(({ requestData: { code } }) => code),
          ...filteredSelectedRows?.['draft']?.map(({ requestData: { code } }) => code),
        ],
      };
      dispatch(deleteMultipleRequests(payload));
    }
  };

  useEffect(() => {
    if (!loading && reFetchData) setReFetchData(false);
  }, [loading]);

  const handleAction = (data) => {
    const statusType = ['decline', 'approve'].includes(data) ? 'pending' : 'approved';

    if (['decline', 'approve', 'pay'].includes(data)) {
      setTotalAmount(calculateSum(filteredSelectedRows[statusType]) ?? 0);
      setFees(
        data === 'decline'
          ? 0
          : (selectedRowInformation?.[statusType]?.count ?? 0) * FEES,
      );
    }

    if (data === 'approve') {
      setFetchDataSuccess(false);
      setReSelectRows(false);
    }
    setAction(data);
    setIsSubmit(!isSubmit);
  };

  const handleUpdateRequest = (code, field, value) => {
    dispatch(
      updateRequests({ code, [field]: value, showSuccessToast: false, delay: 1000 }),
    );
  };

  useEffect(() => {
    if (!balances?.budgets?.length || !balances?.balances?.length)
      dispatch(getBalances());
  }, []);

  const [budgetList, setBudgetList] = useState([]);

  useEffect(() => {
    if (balances?.budgets?.length || balances?.balances?.length) {
      const budget = getAvailableBalance(balances?.budgets, 'budgets');
      const available_balance = getAvailableBalance(balances?.balances, 32, true, true);
      setBudgetList((prevOptions) => [...available_balance].concat([...budget]));
    }
  }, [balances?.budgets?.length, balances?.balances?.length]);

  async function loadOptions(search, loadedOptions, { page }) {
    let filteredOption = budgetList.filter((item) =>
      item?.name?.toLowerCase().includes(search?.toLowerCase()),
    );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore: false,
      additional: {
        page: page + 1,
      },
    };
  }

  const updateRequest = (value, code) => {
    dispatch(updateRequests({ code, source: value }));
  };

  const actionHandler = (event, type, value) => {
    event?.stopPropagation();
    event?.preventDefault();
    setIsPopoverOpen(true);
    setSelectedOption(value);
    setType(type.toLowerCase());

    if (!['approve_schedule', 'decline_request', 'request_info'].includes(type))
      return setConfirmModal(true);
  };

  const closeModal = () => {
    setIsPopoverOpen(false);
    setConfirmModal(false);
  };

  useEffect(() => {
    if (
      successPayNow ||
      successReview ||
      successApprove ||
      successDecline ||
      deleteSuccess
    ) {
      closeModal();
      isFiltered.current = true;
      dispatch(getRequests({ ...filteredQuery }));
      dispatch({ type: RESET_BLOCK_REQUESTS, blockType: 'getSingleRequest' });
      activeTxCode.current = null;
      setSource('');
      setIsSchedule(false);
      if (declineRequest) setIsLoaded(true);
    }
  }, [successPayNow, successReview, successApprove, successDecline, deleteSuccess]);

  useEffect(() => {
    if (error) {
      setIsPopoverOpen(false);
      activeTxCode.current = null;
    }
  }, [error]);

  const handleConfirm = ({ schedule }) => {
    const code = selectedOption?.code;
    if (type === 'pay') {
      if (selectedOption?.source?.code || selectedOption?.budget?.code) {
        return dispatch(payNowAction({ code }));
      } else if (!source) {
        return toastError('Select a source');
      }
      return dispatch(payNowAction({ code, source: source.value }));
    } else if (['approve and pay', 'approve', 'approve_schedule'].includes(type)) {
      handleApproveRequest({
        schedule,
        code: selectedOption?.approvalCode ?? selectedOption.code,
      });
    } else if (type === 'delete') {
      if (code) dispatch(deleteRequests(code));
    }
  };

  const getSelectedRowsCount = (rowStatusList = []) => {
    return rowStatusList.reduce((total, item) => {
      return total + filteredSelectedRows?.[item]?.length;
    }, 0);
  };

  const handleApproveRequest = ({ schedule, code }) => {
    if (code?.startsWith('apr')) {
      return dispatch(
        reviewRequest({
          code,
          status: 'approved',
          actionLater: schedule ? undefined : !payNow,
          schedule,
        }),
      );
    }
    return dispatch(
      approveRequests({
        code,
        actionLater: schedule ? undefined : !payNow,
        schedule,
      }),
    );
  };

  useEffect(() => {
    if (singleRequest?.data?.fundRequest) setLoadActions(true);
  }, [singleRequest?.data?.fundRequest]);

  const fetchSingleData = useCallback(
    (data) => {
      const { code = '' } = data?.requestData || {};

      activeTxCode.current = code;
      if (activeTxCode.current) dispatch(getSingleRequest(code));
    },
    [activeTxCode.current],
  );

  const Actions = useCallback(
    ({ list: selectedData }) => {
      const status = selectedData?.status?.value.toLowerCase();

      useMemo(() => {
        if (selectedData?.requestData?.code !== activeTxCode.current) {
          setLoadActions(false);
          if (['pending', 'approved'].includes(status)) fetchSingleData(selectedData);
        }
      }, []);

      const { canApprovePay, yourTurnToApprove, yourApprovalRequest } = approvalState({
        data: singleRequest?.data?.fundRequest,
        user,
      });

      if (!loadActions && ['pending', 'approved'].includes(status))
        return (
          <div className="p-2 export-wrap" style={{ transform: 'translateX(0%)' }}>
            <div className="spinner-3 mx-auto" style={{ width: '30px' }}></div>
          </div>
        );

      return (
        <div className="actions-dialog">
          {loadActions &&
            !yourTurnToApprove &&
            singleRequest?.data?.fundRequest?.status !== 'approved' &&
            !(
              isAdmin || user?.user?.code === singleRequest?.data?.fundRequest?.user?.code
            ) && (
              <div
                className="actionLink"
                onClick={(event) => {
                  setIsPopoverOpen(false);
                  event.preventDefault();
                  event.stopPropagation();
                }}
              >
                No options available
              </div>
            )}

          {singleRequest?.data?.fundRequest.status === 'approved' && (
            <div
              className="actionLink"
              onClick={(event) => {
                actionHandler(event, 'pay', {
                  ...singleRequest?.data?.fundRequest?.transaction,
                  source: singleRequest?.data?.fundRequest?.source,
                });
              }}
            >
              <BankNoteO1Icon stroke="#79716B" width={16} height={16} className="mr-4" />
              Make payment
            </div>
          )}

          {loadActions && yourTurnToApprove && (
            <>
              {canApprovePay && (
                <div
                  className="actionLink"
                  onClick={(event) => {
                    setPayNow(canApprovePay);
                    actionHandler(event, 'approve and pay', {
                      ...singleRequest?.data?.fundRequest,
                      approvalCode: yourApprovalRequest?.code,
                    });
                  }}
                >
                  <SendIcon stroke="#79716B" width={16} height={16} className="mr-4" />
                  Approve and pay
                </div>
              )}

              <div
                className="actionLink"
                onClick={(event) => {
                  setPayNow(false);
                  actionHandler(event, 'approve', {
                    ...singleRequest?.data?.fundRequest,
                    approvalCode: yourApprovalRequest?.code,
                  });
                }}
              >
                <CheckIcon stroke="#79716B" width={16} height={16} className="mr-4" />
                Approve only
              </div>

              {canApprovePay && (
                <div
                  className="actionLink"
                  onClick={(event) => {
                    setIsSchedule(true);
                    actionHandler(event, 'approve_schedule', {
                      ...singleRequest?.data?.fundRequest,
                      approvalCode: yourApprovalRequest?.code,
                    });
                  }}
                >
                  <CalenderIcon
                    stroke="#79716B"
                    width={16}
                    height={16}
                    className="mr-4"
                  />
                  Approve and schedule
                </div>
              )}

              <div
                className="actionLink"
                onClick={(event) => {
                  setDeclineRequest(true);
                  actionHandler(event, 'decline_request', {
                    ...singleRequest?.data?.fundRequest,
                    approvalCode: yourApprovalRequest?.code,
                  });
                }}
              >
                <SlashCircleIcon
                  stroke="#79716B"
                  width={16}
                  height={16}
                  className="mr-4"
                />
                Decline request
              </div>
              <div
                className="actionLink"
                onClick={(event) => {
                  setRequestInfo(true);
                  actionHandler(event, 'request_info', {
                    ...singleRequest?.data?.fundRequest,
                    approvalCode: yourApprovalRequest?.code,
                  });
                }}
              >
                <FIlePlusIcon stroke="#79716B" width={16} height={16} className="mr-4" />
                Request more info
              </div>
            </>
          )}

          {(isAdmin ||
            user?.user?.code === singleRequest?.data?.fundRequest?.user?.code) &&
            ['pending', 'draft'].includes(status) && (
              <div
                className="actionLink svg-danger text-danger"
                onClick={(event) => {
                  actionHandler(event, 'delete', {
                    ...singleRequest?.data?.fundRequest,
                  });
                }}
              >
                <TrashIcon stroke="#79716B" width={16} height={16} className="mr-4" />
                Delete request
              </div>
            )}
        </div>
      );
    },
    [singleRequest?.data?.fundRequest?.code, loadActions],
  );

  const show = !!requests?.length || (filtered && !requests?.length);

  if (loading && !filtered && !reFetchData) return <Loading isPage color="#D28B28" />;

  return (
    <div className="requests-wrapper position-relative">
      <TopBar
        showBarSearch={show}
        searchVal={search}
        setSearchVal={setSearch}
        showFilter={show}
        inputPlaceholder="Search"
        filterData={filterData}
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        withOutSearch
        handleFilterApply={handleFilter}
      />

      {!requests.length ? (
        <div>
          {filtered ? (
            <div className="tabinnerWrapper">
              <NoData
                headerText="You have no request matching these criteria"
                bodyText="Alter the filter to see your fund requests"
                withButton={false}
              />
            </div>
          ) : (
            <RequestEmptyStateData
              openRequestModal={toggleHandler}
              showAction={canCreate}
            />
          )}
        </div>
      ) : (
        <>
          <Container className="px-0">
            <Row className="py-4 spaced-table">
              <Col xs={12}>
                <Table
                  columns={tableColumn}
                  data={rows}
                  pagination
                  hasMore={hasMore}
                  currentPage={page}
                  nextPage={() => handleNextPage(nextPage)}
                  previousPage={() => handlePreviousPage(page - 1)}
                  totalPage={Math.ceil(total / perPage)}
                  onRowClick={handleRowClick}
                  onRowSelect={onRowSelect}
                  reSelectRows={reSelectRows}
                  fetchDataSuccess={fetchDataSuccess}
                  setReSelectRows={setReSelectRows}
                  popoverAction={Actions}
                  popoverState={isPopoverOpen}
                  setPopoverState={setIsPopoverOpen}
                />
              </Col>
            </Row>
            {selectedRows.length > 0 && rowType === 'funds' && (
              <FloatingCta
                hasDelete={isAdmin}
                selectedRowInformation={selectedRowInformation}
                selectedRows={selectedRows}
                handleAction={handleAction}
                hasPayment={false}
              />
            )}
          </Container>
        </>
      )}

      {selectedRows.length > 0 && rowType === 'funds' && (
        <>
          {action === 'approve' ? (
            <BulkApprovalDialog
              openModal={isSubmit}
              columns={['Requested by', 'Amount', 'Vendor', 'Category', 'Source']}
              rows={
                filteredSelectedRows?.['pending']?.map((item) => item?.requestData) ?? []
              }
              isLoading={isReviewing}
              onCancel={() => {
                if (reFetchData) {
                  setReSelectRows(true);
                  dispatch(getRequests({ ...filteredQuery, perPage, page }));
                }
                setIsSubmit(!isSubmit);
              }}
              handleUpdate={handleUpdateRequest}
              tab={'fund request'}
              onConfirm={handlePay}
              isSuccess={updateSuccess}
              amount={
                <CurrencyFormat
                  prefix={getCurrency('NGN')}
                  value={totalAmount.toFixed(2)}
                  displayType="text"
                  thousandSeparator={true}
                />
              }
              fee={
                <CurrencyFormat
                  prefix={getCurrency('NGN')}
                  value={fees.toFixed(2)}
                  displayType="text"
                  thousandSeparator={true}
                />
              }
              icon={<BankIcon width="24" height="24" />}
              title={'approval'}
              total={
                <CurrencyFormat
                  prefix={getCurrency('NGN')}
                  value={(totalAmount + fees).toFixed(2)}
                  displayType="text"
                  thousandSeparator={true}
                />
              }
            />
          ) : action === 'delete' ? (
            <Modal show={isSubmit} centered dialogClassName="custom-dialog">
              <ConfirmDialog
                title={`Delete ${getSelectedRowsCount(['pending', 'draft'])} ${
                  getSelectedRowsCount(['pending', 'draft']) === 1
                    ? 'request'
                    : 'requests'
                } `}
                subTitle={`Are you sure you want to delete ${getSelectedRowsCount([
                  'pending',
                  'draft',
                ])} ${
                  getSelectedRowsCount(['pending', 'draft']) === 1
                    ? 'request'
                    : 'requests'
                }? This action cannot be undone.`}
                onConfirm={handlePay}
                onCancel={() => setIsSubmit(!isSubmit)}
                isDeleteDialog={true}
                loading={deleteMultipleLoading}
              />
            </Modal>
          ) : (
            <Modal
              show={isSubmit}
              centered
              dialogClassName="custom-dialog batch-payment-confirm"
            >
              <BatchPaymentDialog
                disabled={isReviewing}
                onClick={handlePay}
                onCancel={() => {
                  setIsSubmit(!isSubmit);
                  setAdditionalNote('');
                }}
                onNoteChanged={setAdditionalNote}
                note={additionalNote}
                amount={
                  <CurrencyFormat
                    prefix={getCurrency('NGN')}
                    value={totalAmount.toFixed(2)}
                    displayType="text"
                    thousandSeparator={true}
                  />
                }
                description={
                  <>
                    You are about to {action}{' '}
                    <span className="fw-bolder">
                      {action === 'decline'
                        ? selectedRowInformation?.['pending']?.count
                        : action === 'pay'
                        ? selectedRowInformation?.['approved']?.count
                        : '0'}{' '}
                      requests.
                    </span>
                  </>
                }
                fee={
                  <CurrencyFormat
                    prefix={getCurrency('NGN')}
                    value={fees.toFixed(2)}
                    displayType="text"
                    thousandSeparator={true}
                  />
                }
                icon={<BankIcon width="24" height="24" />}
                title={`Please confirm your ${action === 'pay' ? 'payment' : 'decline'}.`}
                total={
                  <CurrencyFormat
                    prefix={getCurrency('NGN')}
                    value={(totalAmount + fees).toFixed(2)}
                    displayType="text"
                    thousandSeparator={true}
                  />
                }
                loading={isReviewing || isPaying}
                addNote={action === 'decline'}
              />
            </Modal>
          )}
        </>
      )}

      <ReviewerModal selectRequest={selectRequest} setSelectRequest={setSelectRequest} />
      <RequestFund
        isOpen={isOpen}
        toggleHandler={toggleHandler}
        setIsOtherPopoverOpen={setIsOtherPopoverOpen}
      />

      <Modal show={confirmModal} centered dialogClassName="custom-dialog">
        <ConfirmDialog
          title={`${capitalizeFirstLetter(type)} request`}
          subTitle={`Are you sure you want to ${type} this request?`}
          onConfirm={handleConfirm}
          extraChild={
            !(selectedOption?.source?.code || selectedOption?.budget?.code) &&
            !['approve', 'delete'].includes(type) && (
              <div className="border-top" style={{ maxWidth: '300px' }}>
                <Row className="align-items-center">
                  <CustomSelectRadio
                    label="Where are you paying from?"
                    name="source"
                    placeholder="Select a source"
                    onChange={(val) => {
                      if (type !== 'pay') updateRequest(val.value, selectedOption?.code);
                      else setSource(val);
                    }}
                    isLoading={loadingBalances}
                    isDisabled={loadingBalances}
                    loadOptions={loadOptions}
                  />
                </Row>
              </div>
            )
          }
          onCancel={closeModal}
          isDeleteDialog={type === 'cancel' || type === 'delete'}
          actionBtnText="Confirm"
          loading={loadingPayNow || loadingReview || loadingApprove || deleteLoading}
        />
      </Modal>

      <Modal show={isSchedule} centered dialogClassName="custom-dialog">
        <SchedulePayment
          onClose={() => {
            setIsSchedule(false);
          }}
          loading={loadingReview}
          setSchedule={handleConfirm}
          extraChild={
            !(selectedOption?.source?.code || selectedOption?.budget?.code) &&
            type !== 'approve' && (
              <div className="mt-3">
                <Row className="align-items-center">
                  <CustomSelectRadio
                    label="Where are you paying from?"
                    name="source"
                    placeholder="Select a source"
                    onChange={(val) => {
                      if (type !== 'pay') updateRequest(val.value, selectedOption?.code);
                      else setSource(val);
                    }}
                    isLoading={loadingBalances}
                    isDisabled={loadingBalances}
                    loadOptions={loadOptions}
                  />
                </Row>
              </div>
            )
          }
        />
      </Modal>
      <CustomModal show={declineRequest}>
        <DeclineRequest
          onClose={() => {
            setDeclineRequest(false);
            setIsLoaded(false);
          }}
          selectRequest={selectedOption}
          userCode={user?.user?.code}
          isLoaded={isLoaded}
          kebabAction
        />
      </CustomModal>
      <CustomDrawer show={requestInfo}>
        <div className="content">
          <RequestMoreDetails
            onClose={() => {
              setRequestInfo(false);
            }}
            selectRequest={selectedOption}
            kebabAction
          />
        </div>
      </CustomDrawer>
    </div>
  );
};

export default ManageFundRequest;
