import Loading from 'components/UI/Loading';
import { useEffect, useState } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Header from './components/Header';

import { format } from 'date-fns';
import { getCompanyStats, sendMonoAuthCode } from 'redux/actions/CompaniesAction';
import { RESET_BLOCK_DATE_RANGE } from 'redux/reducers/OverviewReducer';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { getBalances } from '../../../redux/actions/BudgetsAction';
import AccountDetailsModal from './AccountDetailsModal';
import DirectDebitModal from './AccountLinking/DirectDebitSetup';
import AllAccounts from './Accounts/AllAccounts';
import BujetiAccounts from './Accounts/BujetiAccounts';
import LinkedAccounts from './Accounts/LinkedAccounts';
import NewAccountModal from './NewAccountModal';
import StatementModal from './StatementModal';
import SwapModal from './components/SwapModal';
import { toastError } from 'components/UI/toast';

const Overview = ({ company }) => {
  // TransactionsAction top header
  const history = useHistory();
  const dispatch = useDispatch();
  const [monoAuthCode, setMonoAuthCode] = useState(null);
  const [statementModal, setStatementModal] = useState(false);
  const [openDirectDebit, setOpenDirectDebit] = useState(false);
  const [newAccountModal, setNewAccountModal] = useState(false);
  const [newAccountType, setNewAccountType] = useState(null);
  const [accountDetailsModal, setAccountDetailsModal] = useState(false);
  const [selectedAccountDetails, setSelectedAccountDetails] = useState(null);
  const [openSwapModal, setOpenSwapModal] = useState(false);

  const {
    getPaymentLink: { data: dataPaymentLink, loading: loadingPaymentLink },
    verifyPaymentReference: { loading: verifyingPayment },
    sendMonoAuthCode: { data: linkedBank, success },
  } = useSelector(({ companies }) => companies);

  let {
    getBalances: { data, loading },
  } = useSelector(({ budgets }) => budgets);

  const {
    user: { data: userData },
  } = useSelector(({ auth }) => auth);

  const { selectedDateRange } = useSelector(({ overview }) => overview);
  const [key, setKey] = useState('all');

  const [dateRange, setDateRange] = useState({ from: null, to: null });

  const { permissions } = allPermissions();

  const canViewDash = hasPermission({
    permissions,
    scopes: ['org-*', 'dash-*', 'dash-view', 'dash-edit'],
  });

  useEffect(() => {
    if (success) {
      history.push(`/accounts/${linkedBank?.code}`);
    }
  }, [success]);

  useEffect(() => {
    const { startDate, endDate } = selectedDateRange?.range[0];
    setDateRange({
      from: format(startDate, 'yyyy-MM-dd') + ' 00:00:00',
      to: format(endDate, 'yyyy-MM-dd') + ' 23:59:59',
    });
  }, [selectedDateRange]);

  // useEffect(() => {
  //   if (userData?.user?.company?.code) {
  //     dispatch(getBankAccount({ code: userData?.user?.company?.code }));
  //   }
  // }, [userData?.user?.company?.code]);

  useEffect(() => {
    if (!data?.balances?.length) dispatch(getBalances());
    dispatch({ type: RESET_BLOCK_DATE_RANGE, blockType: 'selectedDateRange' });
  }, []);

  useEffect(() => {
    if (company.code && dateRange?.from !== null) {
      const { from, to } = dateRange;
      if (canViewDash) {
        dispatch(
          getCompanyStats({
            id: company.code,
            params: { from, to },
          }),
        );
      }
    }
  }, [company.code, dateRange]);

  useEffect(() => {
    if (monoAuthCode) dispatch(sendMonoAuthCode({ code: company.code, monoAuthCode }));
  }, [monoAuthCode]);

  useEffect(() => {
    if (dataPaymentLink && dataPaymentLink?.payment_link) {
      window.location = dataPaymentLink?.payment_link;
    }
  }, [dataPaymentLink]);

  const handleRefresh = () => {
    const { from, to } = dateRange;
    if (canViewDash)
      dispatch(getCompanyStats({ id: company.code, params: { from, to } }));
  };

  const closeStatementModal = () => {
    setStatementModal(false);
    setSelectedAccountDetails(null);
  };
  const closeDetailsModal = () => {
    setAccountDetailsModal(false);
    setSelectedAccountDetails(null);
  };

  const openStatementModal = (event, payload) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedAccountDetails(payload);
    setStatementModal(true);
  };

  const openDetailsModal = (event, payload) => {
    event.stopPropagation();
    event.preventDefault();
    setSelectedAccountDetails(payload);
    setAccountDetailsModal(true);
  };

  const closeNewAccountModal = (reload = false) => {
    setNewAccountModal(false);
    if (reload) dispatch(getBalances());
  };

  const openNewAccountModal = (type) => {
    setNewAccountModal(true);
    setNewAccountType(type);
  };

  const handleSelect = (tabKey) => {
    setKey(tabKey);
  };

  const openBalance = (code, payload) => {
    if (payload?.number) {
      payload.isSubaccount
        ? history.push(`/accounts/subaccounts/${code}`)
        : history.push('/accounts/' + code);
    } else {
      toastError('Account is still being generated');
    }
  };

  const handleOpenDirectDebit = () => {
    setOpenDirectDebit(!openDirectDebit);
  };
  const handleSwapping = () => {
    setOpenSwapModal((prevValue) => !prevValue);
  };
  return (
    <div className="page-wrapper">
      <div className="w-100">
        <Header
          setMonoCode={setMonoAuthCode}
          handleRefresh={handleRefresh}
          openModal={openNewAccountModal}
          openDirectDebit={handleOpenDirectDebit}
          openSwapModal={handleSwapping}
          canViewDash={canViewDash}
        />

        <StatementModal
          account={selectedAccountDetails}
          isModalOpen={statementModal}
          closeHandler={closeStatementModal}
        />
        <NewAccountModal
          isModalOpen={newAccountModal}
          closeHandler={closeNewAccountModal}
          accountType={newAccountType}
        />
        <AccountDetailsModal
          details={selectedAccountDetails}
          isModalOpen={accountDetailsModal}
          closeHandler={closeDetailsModal}
        />

        <SwapModal
          companyCode={company.code}
          accounts={company.accounts}
          isOpen={openSwapModal}
          closeHandler={handleSwapping}
        />

        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          className="mb-3"
          onSelect={handleSelect}
          mountOnEnter={true}
        >
          {(loadingPaymentLink || verifyingPayment) && <Loading isPage color="#D28B28" />}
          <Tab
            eventKey="all"
            title={<div className="d-flex mb-1">All accounts</div>}
            tabClassName="new-tab"
          >
            <AllAccounts
              openBalance={openBalance}
              openStatementModal={openStatementModal}
              openDetailsModal={openDetailsModal}
              canViewDash={canViewDash}
            />
          </Tab>
          {canViewDash && (
            <Tab
              eventKey="bujeti"
              title={<div className="d-flex mb-1">Bujeti accounts</div>}
              tabClassName="new-tab"
            >
              <BujetiAccounts
                type={'accounts'}
                key={'accounts'}
                openBalance={openBalance}
                openStatementModal={openStatementModal}
                openDetailsModal={openDetailsModal}
              />
            </Tab>
          )}
          {canViewDash && (
            <Tab
              eventKey="subaccounts"
              title={<div className="d-flex mb-1">Subaccounts</div>}
              tabClassName="new-tab"
            >
              <BujetiAccounts
                key={'subaccounts'}
                openBalance={openBalance}
                openStatementModal={openStatementModal}
                openDetailsModal={openDetailsModal}
                type={'subaccounts'}
              />
            </Tab>
          )}
          {canViewDash && (
            <Tab
              eventKey="linked"
              title={<div className="d-flex mb-1">External accounts</div>}
              tabClassName="new-tab"
            >
              <LinkedAccounts
                openBalance={openBalance}
                openStatementModal={openStatementModal}
                openDetailsModal={openDetailsModal}
              />
            </Tab>
          )}
        </Tabs>
      </div>
      <Modal show={openDirectDebit} centered dialogClassName="custom-dialog">
        <DirectDebitModal onClose={handleOpenDirectDebit} />
      </Modal>
    </div>
  );
};
export default Overview;
