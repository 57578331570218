import { CancelICon, DeleteIcon } from 'assets/icons';
import classNames from 'classnames';
import CustomButton from '../UI/CustomButton';
import './styles.scss';

const DeleteDialog = ({
  onCancel,
  onDelete,
  styles,
  title = 'Delete',
  subTitle = '',
  isLoading,
  deleteText,
  cancelText,
  icon,
}) => {
  return (
    <>
      <div className="dialog-overlay">
        <div className="dialog-wrapper">
          <div className="dialog-body p-0" style={{ ...styles }}>
            <i onClick={onCancel} className="cancel-icon">
              <CancelICon />
            </i>
            <div className="px-4 pt-4">
              <span className={classNames({ ['delete-icon']: !icon })}>
                {icon || <DeleteIcon />}
              </span>
              <h2 className="text-start mt-2">{title}</h2>
              <p className="subtitle text-start">{subTitle}</p>
            </div>
            <div
              className="custom-modal_footer mt-4 d-flex align-items-center justify-content-between"
              style={{ width: '100%', gap: 12 }}
            >
              <CustomButton
                fullWidth
                className="cancel-btn"
                disabled={isLoading}
                onClick={onCancel}
              >
                {cancelText ? cancelText : 'Cancel'}
              </CustomButton>
              <CustomButton
                fullWidth
                loading={isLoading}
                disabled={isLoading}
                className="remove-btn"
                onClick={onDelete}
              >
                {deleteText ? deleteText : 'Delete'}
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DeleteDialog;
