import ItemInfo from 'components/TransactionModal/itemInfo';
import pdfImage from '../../../../assets/images/pdfIcon.png';
import FileUpload from 'components/UI/FileUpload';
import { useEffect } from 'react';
import classNames from 'classnames';

/**

 * @param {array} receiptAssets: array of object consisting of name, url
 * @param {function} viewImgUrl: a callback function to open the receipt viewer
 * @returns
 */
const ReceiptList = ({
  receiptAssets,
  viewImgUrl,
  removeFile,
  addMore,
  onChange,
  loading,
  showLabel = true,
}) => {
  // useEffect(() => {
  //   if (receiptAssets?.length) viewImgUrl(receiptAssets[0], 'receipt');
  // }, [receiptAssets?.length]);
  return (
    <div>
      {showLabel && <ItemInfo title="Receipts or Attachments" />}
      <div
        className={classNames('receipt-asset-wrapper', {
          'padding-top-xxs': showLabel,
        })}
      >
        {receiptAssets?.map((item, index) => (
          <div
            className={
              item?.name?.includes('pdf') ? 'receipt-asset pdf-small' : 'receipt-asset'
            }
            key={index}
            onClick={() => viewImgUrl(item, 'receipt')}
          >
            {item?.name.includes('pdf') ? (
              <img src={pdfImage} alt={item?.name} />
            ) : (
              <img src={item?.url} alt={item?.name} />
            )}
          </div>
        ))}
        <div style={{ marginTop: '-12px' }}>
          <FileUpload
            name="file"
            onChange={onChange}
            multiple
            removeFile={removeFile}
            wrapperClass="new-receipt"
            isRounded={true}
            uploadingFile={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default ReceiptList;
