import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import ExchangeRate from 'components/UI/ExchangeRate';
import Saving from 'components/UI/ModalSaving/Saving';
import Success from 'components/UI/ModalSaving/Success';
import { toastError } from 'components/UI/toast';
import numeral from 'numeral';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currencyConversionRate } from 'redux/actions/BudgetsAction';
import { fundDollarBalance } from 'redux/actions/CompaniesAction';
import { getCurrency } from 'utils/helper';
import MoreActions from './MoreActions';

const FundDollar = ({ setInsideModalClick, setFundStep, setIsSuccess, isSuccess }) => {
  const clickHandler = () => {
    setFundStep(5);
  };

  const [dollarWallet, setDollarWallet] = useState({
    amount: '',
    currency: 'USD',
    exchangeRate: undefined,
  });
  const [initalAmount, setInitialAmount] = useState({ currency: 'NGN', amount: 0 });
  const [startInterval, setStartInterval] = useState(false);
  const [currencyExchange, setCurrencyExchange] = useState({});

  const handleChangeDollarWallet = (data) => {
    const {
      target: { name, value, rawValue },
    } = data;
    setDollarWallet({ ...dollarWallet, [name]: rawValue ? rawValue : value });
  };

  const {
    getStatistics: { data: dataStats = {} },
    getCompany: { data: companyData = {} },
    fundDollarBalance: { data: dollarBalance, loading, success, error },
  } = useSelector(({ companies }) => companies);

  const {
    getCurrencyConversionRate: {
      data: currencyConversion = {},
      loading: loadingConversion,
    },
  } = useSelector(({ budgets }) => budgets);

  const dispatch = useDispatch();

  const { available: { NGN: available } = {} } = dataStats;

  const { rate: exchangeRate = 0 } = currencyExchange;

  const { amount, currency } = initalAmount;
  const totalamount = available / 100 + amount;

  // Budget Balance Left
  const BalaceLeft = () => {
    return <p>{`${getCurrency(currency)}${numeral(totalamount).format('0,0.00')}`}</p>;
  };

  const interval = useRef(null);
  useEffect(() => {
    interval.current = setInterval(() => {
      if (dollarWallet.amount) {
        if (dollarWallet.currency === 'USD') {
          dispatch(
            currencyConversionRate({
              baseCurrency: dollarWallet.currency.toLowerCase(),
              targetCurrency: 'ngn',
              amount: dollarWallet.amount,
            }),
          );
        }
      }
    }, 120000);

    return () => {
      clearInterval(interval.current);
    };
  }, [startInterval, error]);

  useEffect(() => {
    if (dollarWallet.amount && !startInterval) {
      if (dollarWallet.currency === 'USD') {
        dispatch(
          currencyConversionRate({
            baseCurrency: dollarWallet.currency.toLowerCase(),
            targetCurrency: 'ngn',
            amount: dollarWallet.amount,
          }),
        );
      }
      setStartInterval(!startInterval);
    }
  }, [dollarWallet.currency, dollarWallet.amount, error]);

  useEffect(() => {
    if (Object.keys(currencyConversion).length) {
      setCurrencyExchange(currencyConversion);
    }
  }, [currencyConversion]);

  useEffect(() => {
    if (Object.keys(currencyConversion).length)
      setDollarWallet({ ...dollarWallet, exchangeRate: currencyConversion.rate });
  }, [currencyConversion.rate]);

  const handleSubmit = () => {
    if (!dollarWallet.amount) return toastError('Please enter a valid amount');

    const payload = {
      amount: dollarWallet.amount * 100,
      rate: dollarWallet.exchangeRate,
    };
    dispatch(fundDollarBalance(payload));
  };

  useEffect(() => {
    if (success) setIsSuccess(!isSuccess);

    return () => {
      setIsSuccess(false);
    };
  }, [success]);

  const fundDollarWalletCard = (
    <>
      <div className="fundsTitle mb-5">
        <h2 className="card-title w-100">Fund USD Balance</h2>
      </div>
      <CustomInput
        label="Amount"
        type="number"
        isAmount
        setIsOtherPopoverOpen={setInsideModalClick}
        wrapperClass="mb-3"
        placeholder="0.00"
        balance={BalaceLeft()}
        otherCurrency={true}
        name="amount"
        onChange={handleChangeDollarWallet}
        value={dollarWallet.amount}
        existAction={'USD'}
        editCurrency={false}
      />
      {dollarWallet.currency === 'USD' && dollarWallet.amount && (
        <ExchangeRate
          exchangeRate={exchangeRate}
          amount={dollarWallet.amount}
          loading={loadingConversion}
        />
      )}
      <CustomButton
        fullWidth
        className="mt-4 mb-4"
        onClick={handleSubmit}
        disabled={loading || loadingConversion}
      >
        Fund Balance
      </CustomButton>
      <div className="pt-4">
        <MoreActions handleClick={clickHandler} fundCardMessage="By bank transfer" />
      </div>
    </>
  );

  return (
    <div>
      {loading ? (
        <Saving />
      ) : isSuccess ? (
        <Success
          title="Thank you!"
          message="Your USD balance has been funded successfully"
        />
      ) : (
        fundDollarWalletCard
      )}
    </div>
  );
};

export default FundDollar;
