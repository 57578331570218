import { CloseOutlined } from '@ant-design/icons';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomRoleSelect from 'components/UI/CustomRoleSelect';
import RoleParagraphs from 'components/UI/CustomRoleSelect/RoleParagraphTruncateor';
import Modal from 'components/UI/Modal';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  buildBulkBeneficiariesPayload,
  getBeneficiaries,
} from 'redux/actions/BeneficiariesAction';
import { getRoles } from 'redux/actions/RolesAction';
import { getTeams } from 'redux/actions/TeamsAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { capitalizeFirstLetter } from 'utils/helper';

const BulkMembersModal = ({ selectBeneficiaries, setSelectBeneficiaries }) => {
  const [modalForm, setModalForm] = useState({
    id: selectBeneficiaries.id,
    firstName: selectBeneficiaries.firstName,
    lastName: selectBeneficiaries.lastName,
    role: selectBeneficiaries?.role,
    email: selectBeneficiaries.email,
    team: selectBeneficiaries?.team,
    manager: selectBeneficiaries?.manager,
  });
  const dispatch = useDispatch();

  const {
    getBeneficiaries: { data: beneficiariesData = {}, loading: isFetchingBeneficiaries },
    bulkBeneficiariesPayload: { data: members },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const {
    getRoles: { data: rolesData = [], loading: isFetchingRoles },
  } = useSelector(({ roles }) => roles);

  const {
    getTeams: { data: teamsData = {}, loading: isFetchingTeams },
  } = useSelector(({ teams }) => teams);

  const { permissions } = allPermissions();
  const canEdit = hasPermission({
    permissions,
    scopes: ['employee-*', 'employee-edit'],
  });

  const [roleOptions, setRoleOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [beneficiaryOptions, setBeneficiaryOptions] = useState([]);

  const handleSave = () => {
    const filteredMemebers = members.filter(
      (option) => option.id !== selectBeneficiaries.id,
    );
    dispatch(
      buildBulkBeneficiariesPayload({ payload: [modalForm, ...filteredMemebers] }),
    );
    setSelectBeneficiaries(null);
  };

  const rolesOptionMapper = (data) => {
    return setRoleOptions(
      data?.map((datum) => {
        return {
          label: datum?.name,
          value: datum?.description ?? <RoleParagraphs datum={datum} />,
          code: datum?.code,
        };
      }),
    );
  };

  useEffect(() => {
    if (!isFetchingRoles && !rolesData?.length) dispatch(getRoles());
    else rolesOptionMapper(rolesData);
  }, [rolesData, isFetchingRoles]);

  useEffect(() => {
    if (!isFetchingTeams && !teamsData?.teams?.length) dispatch(getTeams());
    else
      setTeamOptions(
        teamsData?.teams?.map(({ code, name }) => ({
          value: name,
          code,
          label: capitalizeFirstLetter(name),
        })),
      );
  }, [teamsData, isFetchingTeams]);

  useEffect(() => {
    if (!isFetchingBeneficiaries && !beneficiariesData?.beneficiaries?.length)
      dispatch(getBeneficiaries());
    else
      setBeneficiaryOptions(
        beneficiariesData?.beneficiaries?.map(
          ({ user: { code, role, firstName, lastName } }) => ({
            value: role?.name,
            code,
            label: `${firstName} ${lastName}`,
          }),
        ),
      );
  }, [beneficiariesData, isFetchingBeneficiaries]);

  const closeHandler = () => {
    setSelectBeneficiaries(null);
  };

  if (!selectBeneficiaries) return <div />;
  return (
    <Modal show={selectBeneficiaries} onClose={closeHandler}>
      <div className="content">
        <div className="card-modal-header d-flex flex-row justify-space-between align-items-center">
          <div className="d-flex align-items-center cursor" onClick={closeHandler}>
            <CloseOutlined />
            <span className="ps-1">Close</span>
          </div>
        </div>
        <div className="card-modal-body">
          <div className="information-wrapper">
            <h2 className="card-title w-100">
              {selectBeneficiaries?.firstName} {selectBeneficiaries?.lastName}
            </h2>
            <div className="mb-5">
              <Row className="mb-3">
                <CustomInput
                  label="Their first name"
                  placeholder="Enter first name"
                  type="text"
                  value={modalForm.firstName}
                  onChange={({ target: { value } }) =>
                    setModalForm({ ...modalForm, firstName: value })
                  }
                />
              </Row>
              <Row className="mb-3">
                <CustomInput
                  label="Their last name"
                  placeholder="Enter last name"
                  type="text"
                  value={modalForm.lastName}
                  onChange={({ target: { value } }) =>
                    setModalForm({ ...modalForm, lastName: value })
                  }
                />
              </Row>
              <Row className="mb-3">
                <CustomInput
                  label="What is their email?"
                  placeholder="Enter email address"
                  type="email"
                  value={modalForm.email}
                  onChange={({ target: { value } }) =>
                    setModalForm({ ...modalForm, email: value })
                  }
                />
              </Row>
              <Row className="mb-3">
                <CustomRoleSelect
                  label="What is their role?"
                  options={roleOptions}
                  placeholder="Select a role"
                  value={modalForm.role}
                  onChange={(val) => setModalForm({ ...modalForm, role: val })}
                  truncateClass={'limit-paragraph'}
                />
              </Row>
              <Row className="mb-3">
                <CustomRoleSelect
                  label="Who is their manager?(Optional)"
                  options={beneficiaryOptions}
                  placeholder="Select a manager"
                  value={modalForm.manager}
                  onChange={(val) => setModalForm({ ...modalForm, manager: val })}
                />
              </Row>
              <Row className="mb-3">
                <CustomRoleSelect
                  label="What is their team?(Optional)"
                  options={teamOptions}
                  placeholder="Select a team"
                  value={modalForm.team}
                  showValue={false}
                  onChange={(val) => setModalForm({ ...modalForm, team: val })}
                />
              </Row>
            </div>
            <div className="modal-footer mt-3">
              {canEdit && (
                <>
                  <CustomButton
                    onClick={closeHandler}
                    fullWidth={true}
                    className="custom-button ghost-button"
                  >
                    Cancel
                  </CustomButton>
                  <CustomButton
                    onClick={handleSave}
                    fullWidth={true}
                    className="custom-button primary-button"
                  >
                    Continue
                  </CustomButton>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default BulkMembersModal;
