import {
  Document,
  Font,
  Image,
  Link,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from 'components/UIHooks/JsPDF';

import { addMonths, format } from 'date-fns';
import ArialUnicodeMS from '../../../assets/fonts/ArialFont1.ttf';
import LogoIcon from '../../../assets/logos/blackLogo.png';

Font.register(
  { family: 'Arial Unicode MS', src: ArialUnicodeMS },
  // { family: 'Arial Unicode MS', src: ArialUnicodeMSBold },
);

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    width: '100%',
    padding: '10px 10px 60px',
    fontWeight: 'normal',
  },

  header: {
    width: '100%',
    backgroundColor: '#fbf5ec',
    borderRadius: 12,
    padding: '18px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  totalContainer: {
    // borderTop: `0.5px solid #1C1917`,
    width: '242px',
    marginTop: 100,
    marginBottom: 80,
  },
  invoiceTitle: {
    // fontWeight: 'bold',
    fontSize: 10,
    color: '#1C1917',
  },
  invoiceSubTitle: {
    fontSize: 10,
    color: '#57534E',
  },
  dFlex: {
    display: 'flex',
  },
  topSectionContainer: {
    padding: '20px 50px 69px 50px',
  },
  flexCol: {
    flexDirection: 'column',
  },
  flexRow: {
    flexDirection: 'row',
  },

  noMargin: {
    marginBottom: 0,
  },
  title: {
    color: '#000',
    fontSize: '12px',
    marginBottom: 5,
  },
  image: {
    width: 150,
    height: 150,
  },
  rightAlign: {
    float: 'right',
    textAlign: 'right',
    alignSelf: 'flex-end',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  mt4: {
    paddingTop: 20,
  },
  mt3: {
    paddingTop: 10,
  },
  mt1: {
    paddingTop: 4,
  },
  p16: {
    padding: 16,
  },
  px2: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  px20: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  lightText: {
    color: '#586068',
    fontSize: '10px',
    fontWeight: 500,
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#EAECF0',
    marginTop: 50,
    marginBottom: 34,
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  footerText: {
    color: '#121722',
    fontSize: 12,
    fontWeight: 400,
  },
  table: {
    display: 'table',
    width: '100%',
    borderStyle: 'solid',
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCell: {
    width: '35%', // Adjust the width as needed
    margin: 5,
    fontSize: 10,
  },
  alignLeft: {
    textAlign: 'left',
  },

  borderB: { borderBottom: '0.5px', borderColor: '#1C1917' },
  main: {
    position: 'relative',
  },
  footerStyles: {
    marginTop: 'auto',
    width: '100%',
  },
  footer: {
    position: 'absolute',
    bottom: 10,
    left: 40,
    right: 40,
  },
  headerConst: {
    textAlign: 'center',
    marginBottom: 10,
    padding: '0 15 0 15',
  },
  imageContainer: {
    width: 60,
    height: 30,
    overflow: 'hidden',
  },
  logo: {
    width: '100%',
    height: '100%',
  },
  link: {
    color: '#D28B28',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  currency: {
    fontFamily: 'Arial Unicode MS',
  },
});

const FormatAmount = (amount) => {
  return Number(amount).toLocaleString('en', {
    useGrouping: true,
    minimumIntegerDigits: 2,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

function getOneMonthDiffFromDate(dateString) {
  const date = new Date(dateString);

  // Calculate the next month
  const nextMonthDate = addMonths(date, 1);

  // Format the result
  const result = `${format(date, 'MMM dd')} - ${format(nextMonthDate, 'MMM dd, yyyy')}`;

  return result;
}

const BillingInvoicePdf = ({ data }) => {
  const subTotal = data?.subscription?.amount / 100;

  return (
    <Document style={{ width: '100%', height: '100vh', margin: 0, padding: 0 }}>
      <Page size="A4" style={styles.page}>
        <Header data={data} />
        <Main style={{ flex: 1 }}>
          <View>
            <View>
              <View style={{ paddingTop: 15 }}>
                <Text
                  style={[
                    styles.invoiceSubTitle,
                    {
                      fontSize: '15px',
                      color: '#1C1917',
                      display: 'flex',
                      flexDirection: 'row',
                    },
                  ]}
                >
                  <Text style={[styles.currency]}>₦</Text>
                  {FormatAmount(subTotal)} paid on {data?.date}
                </Text>
              </View>
              <View style={{ paddingTop: 25 }}>
                <Table data={data} />
              </View>
              <View style={styles.rightAlign}>
                <TotalContainer data={data} />
              </View>
            </View>
          </View>
        </Main>

        <View style={styles.footer}>
          <View style={[styles.dFlex, styles.center]}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text
                style={[styles.footerText, styles.invoiceSubTitle, { marginTop: '5px' }]}
              >
                Powered by{' '}
                <Link style={styles.link} src="https://www.bujeti.com">
                  <Text>Bujeti.com</Text>
                </Link>{' '}
                - Take control of your finances today!
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const Header = ({ data }) => {
  return (
    <View style={styles.headerConst}>
      <View
        style={[
          styles.dFlex,
          styles.flexRow,
          styles.spaceBetween,
          styles.px2,
          styles.p16,
        ]}
      >
        <Image
          src={LogoIcon}
          style={{
            height: '40px',
          }}
          alt="logo"
        />

        {/* <View>
          <HeaderInfo title="Invoice" value={data?.code} css={styles.rightAlign} />
        </View> */}
      </View>
      <View style={[styles.header]}>
        <View
          style={[styles.dFlex, styles.flexRow, styles.spaceBetween, { maxWidth: '75%' }]}
        >
          <View style={{ maxWidth: '180px' }}>
            <HeaderInfo title="Receipt" css={styles.rightAlign} />
            <View style={{ maxWidth: '180px', marginTop: '42px' }}>
              <Text style={[styles.invoiceTitle]}>From</Text>
              <Text style={[styles.invoiceSubTitle, styles.mt1]}>Bujeti Inc.</Text>
              {/* <Text style={[styles.lightText, styles.mt1]}>70931 Senger Forges</Text>
              <Text style={[styles.lightText, styles.mt1]}>North Gideon, WY 21428</Text>
              <Text style={[styles.lightText, styles.mt1]}>United States</Text> */}
            </View>
          </View>

          <View style={{ maxWidth: '180px' }}>
            <View style={{ maxWidth: '180px' }}>
              <Text style={[styles.invoiceSubTitle, styles.mt1]}>
                Receipt number: {data?.invoice}
              </Text>
              <Text style={[styles.lightText, styles.mt1]}>Date paid: {data?.date}</Text>
              <Text style={[styles.lightText, styles.mt1]}>
                Payment method:{' '}
                {['balance', 'budget'].includes(data?.subscription?.method)
                  ? 'My Bujeti Balance'
                  : data?.subscription?.method === 'directdebit'
                  ? 'Bank account'
                  : 'None'}
              </Text>
            </View>

            <View style={{ maxWidth: '180px', marginTop: '20px' }}>
              <Text style={[styles.invoiceTitle]}>To</Text>
              <Text style={[styles.invoiceSubTitle, styles.mt1]}>
                {data?.company?.name}
              </Text>
              <Text style={[styles.lightText, styles.mt1]}>
                {data?.company?.contactEmail}
              </Text>
              <Text style={[styles.lightText, styles.mt1]}>
                {data?.company?.contact_phone}
              </Text>
              {/* <Text style={[styles.lightText, styles.mt1]}>France</Text> */}
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

const InvoiceInfo = ({ title, value, css }) => {
  return (
    <View style={[css]}>
      <Text style={[styles.invoiceTitle]}>{title}</Text>
      <Text style={[styles.invoiceSubTitle, styles.mt1]}>{value}</Text>
    </View>
  );
};

const HeaderInfo = ({ title, value }) => {
  return (
    <View>
      <Text
        style={[
          styles.invoiceTitle,
          {
            fontSize: '20px',
            margin: '0px',
            lineHeight: '0px',
            color: '#1C1917',
          },
        ]}
      >
        {title}
      </Text>
      <Text style={([styles.invoiceSubTitle], { fontSize: '10px' })}>{value}</Text>
    </View>
  );
};

const FlexWrapper = ({ children }) => {
  return (
    <View
      style={[
        styles.dFlex,
        styles.flexRow,
        styles.spaceBetween,
        styles.mt3,
        { borderTop: `0.5px solid #1C1917`, paddingBottom: 5 },
      ]}
    >
      {children}
    </View>
  );
};

const Table = ({ data }) => {
  return (
    <View style={styles.table}>
      <View style={[styles.tableRow, styles.borderB]}>
        <View style={[styles.tableCell, styles.alignLeft, { width: '60%' }]}>
          <Text style={[styles.invoiceTitle, { fontWeight: 500 }]}>Description</Text>
        </View>

        <View style={[styles.tableCell, styles.alignLeft, { width: '35%' }]}>
          <Text style={[styles.invoiceTitle, { fontWeight: 500 }]}>Unit Price</Text>
        </View>

        <View style={[styles.tableCell, styles.alignLeft]}>
          <Text style={[styles.invoiceTitle, { fontWeight: 500 }]}>Quantity</Text>
        </View>

        <View style={[styles.tableCell, styles.alignLeft, { width: '15%' }]}>
          <Text style={[styles.invoiceTitle, { fontWeight: 500 }]}>Total</Text>
        </View>
      </View>

      <View style={styles.tableRow}>
        <View style={[styles.tableCell, styles.alignLeft, { width: '60%' }]}>
          <Text style={{ marginBottom: 4 }}>
            Bujeti {data?.subscription?.paymentPlan?.name} Plan
          </Text>
          <Text>{getOneMonthDiffFromDate(data?.subscription?.created_at)}</Text>
        </View>

        <View style={[styles.tableCell, styles.alignLeft, { width: '35%' }]}>
          <Text style={[{ fontWeight: 500, display: 'flex', flexDirection: 'row' }]}>
            <Text style={[styles.currency]}>₦</Text>
            {`${FormatAmount(data?.subscription?.amount / 100)}`}
          </Text>
        </View>

        <View style={[styles.tableCell, styles.alignLeft]}>
          <Text style={[{ fontWeight: 500 }]}>{`1`}</Text>
        </View>

        <View style={[styles.tableCell, styles.alignLeft, { width: '15%' }]}>
          <Text style={[{ fontWeight: 500, display: 'flex', flexDirection: 'row' }]}>
            <Text style={[styles.currency]}>₦</Text>
            {`${FormatAmount(data?.subscription?.amount / 100)}`}
          </Text>
        </View>
      </View>
    </View>
  );
};

const TotalContainer = ({ data }) => {
  const subTotal = data?.subscription?.amount / 100;
  const actualAmount = data?.subscription?.paymentPlan?.amount / 100;
  const percentage = 7.5 / 100;
  const deduction = Math.ceil(actualAmount * percentage);

  return (
    <View style={styles.totalContainer}>
      <FlexWrapper>
        <Text style={[styles.invoiceSubTitle, { fontWeight: 600 }]}>Subtotal</Text>
        <Text
          style={[
            styles.invoiceSubTitle,
            { fontWeight: 600, display: 'flex', flexDirection: 'row' },
          ]}
        >
          <Text style={[styles.currency]}>₦</Text> {`${FormatAmount(actualAmount)}`}
        </Text>
      </FlexWrapper>

      <FlexWrapper>
        <Text style={[styles.invoiceSubTitle, { fontWeight: 600 }]}>VAT</Text>
        <Text
          style={[
            styles.invoiceSubTitle,
            { fontWeight: 600, display: 'flex', flexDirection: 'row' },
          ]}
        >
          <Text style={[styles.currency]}>₦</Text> {FormatAmount(deduction)}
        </Text>
      </FlexWrapper>

      <FlexWrapper>
        <Text style={[styles.invoiceSubTitle, { fontSize: '10px', color: '#1C1917' }]}>
          Total
        </Text>
        <Text
          style={[
            styles.invoiceSubTitle,
            { fontSize: '10px', color: '#1C1917', display: 'flex', flexDirection: 'row' },
          ]}
        >
          <Text style={[styles.currency]}>₦</Text> {`${FormatAmount(subTotal)}`}
        </Text>
      </FlexWrapper>
    </View>
  );
};

const Main = ({ children }) => {
  return <View style={[styles.px20, styles.main]}>{children}</View>;
};

export const BillingPDF = () => (
  <PDFViewer style={{ width: '1024px', height: '1024px' }}>
    <BillingInvoicePdf />
  </PDFViewer>
);

export default BillingInvoicePdf;
