import { Button } from 'react-bootstrap';
import cs from 'classnames';
import './styles.less';
import Loading from '../Loading';
const CustomButton = ({
  withoutBg = false,
  className,
  customClass = false,
  fullWidth = false,
  children,
  loading,
  ...props
}) => {
  return (
    <Button
      className={cs(
        withoutBg ? 'customButtonWithoutBg' : customClass ? '' : 'customButton',
        { [className]: className },
        { ['width-full']: fullWidth },
      )}
      {...props}
    >
      {loading ? <Loading color="#D28B28" size={18} /> : children}
    </Button>
  );
};
export default CustomButton;
