import classNames from 'classnames';
import TotalAmount from 'pages/Dashboard/Overview/components/TotalAmount';
import CurrencyFormat from 'react-currency-format';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'components/UIHooks/Rechart';
import { getCurrency } from 'utils/helper';
import { numFormatter } from 'utils/utility';

const yAxisStyle = {
  fontSize: 13,
  fontWeight: 400,
  fill: '#57534E',
};
const gridStyle = {
  stroke: '#ccc',
  strokeWidth: 0.5,
};

const COLORS = ['#885A1A', '#AF7421', '#D28B28', '#DEA250', '#ECCC9E', '#F4E0C5'];

const BASE_COLOR = '#E7E5E499';

const noChartData = [
  { name: 'No data', amount: 0 },
  { name: 'No data', amount: 0 },
  { name: 'No data', amount: 0 },
  { name: 'No data', amount: 0 },
  { name: 'No data', amount: 0 },
  { name: 'No data', amount: 0 },
];
const BarChartView = ({
  size = 'full',
  data,
  totalSpent,
  amountType,
  showTotalSpent = true,
  currency = 'NGN',
  noDataPlaceholder,
  layout = 'vertical',
}) => {
  const chartData = data.length ? data : noChartData;

  const barColors = chartData.map((item, index) =>
    data.length ? COLORS[index] : BASE_COLOR,
  );

  const CustomBar = ({ x, y, width, height, index }) => (
    <path
      d={`M${x},${y + height / 2 - 18} h${width} q6,0 6,6 v28 q0.5,6 -6,6 h${-width} Z `}
      fill={barColors[index % barColors.length]}
    />
  );

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip bg-white"
          style={{ fontSize: yAxisStyle.fontSize }}
        >
          <p className="label">{label}</p>
          <p className="label-data">
            <span>Spent:</span>
            {
              <CurrencyFormat
                prefix={getCurrency(currency)}
                value={numFormatter(payload[0].value)}
                displayType="text"
                thousandSeparator={true}
                isNumericString
              />
            }
          </p>
        </div>
      );
    }

    return null;
  };

  const formatXAxis = (value) => `${getCurrency(currency)}${numFormatter(value, true)}`;

  return (
    <div
      className={classNames(
        'position-relative w-100 h-100 d-flex flex-column justify-content-center',
      )}
    >
      {showTotalSpent && (
        <div className="chart-header">
          <TotalAmount
            type={totalSpent > 0 && amountType && amountType}
            title={
              totalSpent > 0 && (
                <div className="d-flex align-items-center">
                  <CurrencyFormat
                    prefix={getCurrency(currency)}
                    value={numFormatter((totalSpent || 0) / 100)}
                    displayType="text"
                    thousandSeparator={true}
                    isNumericString
                  />
                  <span className="arrow-wrapper-percentage active ms-2"></span>
                </div>
              )
            }
            subTitle={noDataPlaceholder && noDataPlaceholder}
          />
        </div>
      )}

      <ResponsiveContainer
        width="100%"
        height={
          size === 'small' ? 100 : size === 'big' ? 210 : size === 'full' ? '100%' : size
        }
      >
        <BarChart
          data={chartData}
          layout={layout}
          width={500}
          height={300}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          style={{ cursor: 'pointer' }}
          orientation="left"
        >
          <CartesianGrid
            strokeDasharray="2 2"
            stroke={gridStyle.stroke}
            strokeWidth={gridStyle.strokeWidth}
            horizontal={false}
          />
          <XAxis
            type="number"
            tickFormatter={formatXAxis}
            dataKey="amount"
            tick={yAxisStyle}
            tickLine={false}
          />
          <YAxis
            type="category"
            dataKey="name"
            tick={yAxisStyle}
            axisLine={false}
            tickLine={false}
          />
          <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />} />
          <Bar dataKey="amount" shape={<CustomBar />} radius={[0, 5, 5, 0]} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartView;
