import React, { useEffect, useMemo, useState } from 'react';
import VitrineSidebar from 'components/Auth/LeftVitrine';
import createVitrine from 'assets/images/create-vitrine.png';
import { AuthFormHeader, AuthFormWrapper } from 'components/Auth';
import { ReactComponent as SmileLogo } from 'assets/icons/smile.svg';
import '../../auth/style.less';
import { Form, Row } from 'react-bootstrap';
import CustomInput from 'components/UI/CustomInput';
import CustomButton from 'components/UI/CustomButton';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetBlockAuth, update_user_profile } from 'redux/actions/AuthAction';
import { isValidPhoneNumber } from 'react-phone-number-input';
import CustomPhoneNumberInput from 'components/UI/CustomPhoneNumberInput';
import { validateNigeriaNumber } from 'utils/helper';
import { toastError } from 'components/UI/toast';
import CustomSelect from 'components/UI/CustomSelect';
import { useDebounce } from 'hooks/useDebounce';
import { findCompanyName } from 'redux/actions/UtilsActions';

const EditUser = () => {
  const dispatch = useDispatch();
  const {
    userProfile: { loading: userLoading, success: userSuccess },
    user: { data = {} },
    updatedUser: { loading },
  } = useSelector(({ auth }) => auth);
  const { getRoles: { loading: loadingRoles, success: roleSuccess } = {} } = useSelector(
    ({ roles }) => roles,
  );

  const {
    findCompanyName: {
      data: compnayName,
      loading: loadingCompnayName,
      success: successCompnayName,
      error,
    } = {},
  } = useSelector(({ utils }) => utils);

  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, 500);
  const [newOptions, setNewOptions] = useState([]);

  const handleSelectChange = (val) => {
    setValue(val);
  };

  const leftImage = (
    <>
      <img className="vitrine-image w-75" src={createVitrine} />
    </>
  );

  useEffect(() => {
    if (roleSuccess && userSuccess) {
      history.push('/');
    }
  }, [roleSuccess, userSuccess]);

  useEffect(() => {
    dispatch(resetBlockAuth({ blockType: 'otp' }));
  }, []);

  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    company: '',
    localFormat: '',
    countryCode: '',
    internationalFormat: '',
  });
  const history = useHistory();
  const handleChange = ({ target: { name, value } }) => {
    setUser({ ...user, [name]: value });
  };

  const handlePhoneNumberChange = (localFormat, internationalFormat, countryCode) => {
    setUser({ ...user, internationalFormat, localFormat, countryCode });
  };

  useEffect(() => {
    const companylists = compnayName?.find((option) =>
      option.name.toLowerCase().includes(debouncedValue.toLowerCase()),
    );
    if (!companylists && debouncedValue?.length > 4)
      dispatch(findCompanyName({ search: debouncedValue?.toLowerCase() }));
  }, [debouncedValue]);

  const mappedDoc = compnayName?.map((item) => item.name);
  const companiesList = useMemo(() => {
    return compnayName?.map((item) => ({
      label: item.name,
      value: item.code,
    }));
  }, [successCompnayName, error, mappedDoc]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (!user.first_name) return toastError('Please enter first name');
    if (!user.last_name) return toastError('Please enter last name');
    if (
      !isValidPhoneNumber(String(user.internationalFormat)) ||
      !validateNigeriaNumber(String(user.internationalFormat))
    )
      return toastError('Please enter a valid phone number');
    if (!user.company) return toastError('Please enter your company name');

    if (form.checkValidity() === false) {
      return;
    }
    let payloadData = {
      firstName: user.first_name,
      lastName: user.last_name,
      phoneNumber: {
        countryCode: user.countryCode,
        localFormat: user.localFormat,
      },
    };

    if (!data?.user?.company) {
      // debouncedValue
      payloadData.company = user.company?.label;
      if (user.company?.value) payloadData.lookedUpCompany = user.company?.value;
    }

    dispatch(update_user_profile(payloadData));
    return false;
  };

  const handleNoOptionsMessage = () => {
    if (value.trim() !== '' && value.length > 4) {
      return loadingCompnayName
        ? 'Searching please wait...'
        : 'No options found. Press Enter to continue.';
    }
    return 'Type to search...';
  };

  const handleKeyDown = (event) => {
    event.stopPropagation();
    if (event.key === 'Enter' && value.trim() !== '') {
      // Create a new option and update the options list
      const newOption = { label: value, value: '' };
      setNewOptions([newOption]);
      setUser({ ...user, company: newOption });
      setValue('');
    }
  };

  const availableOptions = (companiesList || []).concat(newOptions);

  return (
    <div className="page-wrapper">
      <div className="vitrinesb-col">
        <VitrineSidebar
          message="Few clicks away from starting your journey of smart spending."
          imageComponent={leftImage}
          widthLink
        />
      </div>

      <div className="page-content with-vit">
        <AuthFormWrapper>
          <SmileLogo className="verify-logo" />
          <AuthFormHeader
            titlle="Tell us about yourself"
            subtitle="One last step to complete your registration"
          />
          <Form noValidate validated={false}>
            <Row className="mb-3">
              <CustomInput
                label="First name"
                error="enter valid first name"
                placeholder="Enter name"
                value={user.first_name}
                required
                name="first_name"
                onChange={handleChange}
                md={6}
              />
              <CustomInput
                label="Last name"
                error="enter valid last name"
                placeholder="Enter name"
                value={user.last_name}
                required
                name="last_name"
                onChange={handleChange}
                md={6}
              />
            </Row>
            <Row className="mb-3">
              <CustomPhoneNumberInput
                label="Phone number"
                placeholder="Enter number"
                onChange={(localFormat, international, countryCode) =>
                  handlePhoneNumberChange(localFormat, international, countryCode)
                }
                value={user.internationalFormat}
              />
            </Row>
            <Row className="mb-3">
              <CustomSelect
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                noOptionsMessage={handleNoOptionsMessage}
                isClearable
                // noOptionsMessage={() => null}
                label="Where do you work"
                name="company"
                placeholder="Company’s legal name e.g (Bujeti Limited)"
                onInputChange={handleSelectChange}
                onChange={(val) => setUser({ ...user, company: val })}
                value={user.company}
                onKeyDown={handleKeyDown}
                options={availableOptions}
                isLoading={loadingCompnayName && !debouncedValue}
                isDisabled={loadingCompnayName && !debouncedValue}
              />
            </Row>

            <CustomButton
              loading={loading}
              onClick={handleSubmit}
              disabled={loading || userLoading || loadingRoles}
              type="button"
            >
              Get started
            </CustomButton>
          </Form>
        </AuthFormWrapper>
      </div>
    </div>
  );
};

export default EditUser;
