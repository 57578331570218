import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import BillingAddress from '../components/BillingAddress';
import OrderSummary from '../components/OrderSummary';

import './checkout.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchplansAction,
  subscribeToPlan,
  viewPlan,
} from 'redux/actions/BillingActions';
import { getNotification } from 'redux/actions/NotificationsAction';
import Loading from 'components/UI/Loading';
import { toastError } from 'components/UI/toast';

const Checkout = () => {
  const [selectedPaymentOption, setSelectedPaymentOption] = useState('bujeti');
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const planType = location.state?.type;

  const [billing, setBilling] = useState({
    billingPeriod: planType
      ? { value: planType, label: `Paid ${planType}` }
      : { value: 'monthly', label: 'Paid monthly' },
    type: 'balance',
  });

  const { user } = useSelector(({ auth }) => auth);
  const { data: userData = {} } = user;

  const goBack = () => {
    history.push('/settings/plans');
  };

  const {
    fetchPlans: { data: planData },
    viewPlan: { loading },
    subscribeToPlan: { loading: isLoading, success },
  } = useSelector(({ billing }) => billing);

  useEffect(() => {
    if (!planData?.plans?.length) dispatch(fetchplansAction());
  }, []);

  useEffect(() => {
    if (params.planCode) dispatch(viewPlan(params.planCode));
  }, [params.planCode]);

  useEffect(() => {
    if (success) history.push('/settings/billings');
  }, [success]);

  const onHandleSelectedPayment = (option) => {
    setSelectedPaymentOption(option);
  };

  const planDetails = location.state?.plan;
  const activeType = userData?.user?.company?.paymentPlan?.name;

  const getAddonText = (data, selected, active) => {
    const activeIndex = data?.findIndex((item) => item.name === active);
    const selectedIndex = data?.findIndex((item) => item.name === selected);
    if (activeIndex > selectedIndex) return 'Downgrade';
    if (activeIndex < selectedIndex) return 'Upgrade';
  };

  const handlePayment = () => {
    if (!billing.firstName) return toastError('Please enter your first name');
    if (!billing.lastName) return toastError('Please enter your last name');

    if (!billing.country) return toastError('Please select a country');
    if (!billing.address) return toastError('Please enter an address');
    if (!billing.state) return toastError('Please select a state');
    if (!billing.city) return toastError('Please enter a city');
    if (!billing.balance) return toastError('Please select a source');

    const isAvailableBalance = billing?.balance?.value?.startsWith('blc_');
    const isAvailableBackAccount = billing?.balance?.value?.startsWith('bnk_');

    const budget =
      isAvailableBalance || isAvailableBackAccount ? undefined : billing?.balance?.value;
    const method =
      billing.type === 'balance' && budget
        ? 'budget'
        : billing.type === 'balance' && !budget && isAvailableBalance
        ? 'balance'
        : 'directdebit';

    const payload = {
      method, // directdebit, budget
      billingPeriod: billing.billingPeriod.value,
      contactEmail: billing?.email,
      directDebit:
        !budget && isAvailableBackAccount
          ? {
              bankAccount: billing?.balance?.value,
            }
          : undefined,
      budget,
      balance: !budget && isAvailableBalance ? billing?.balance?.value : undefined,

      address: billing?.address,
      state: billing?.state?.label,
      country: billing?.country?.label,
      companyName: billing?.companyName,
      city: billing?.city,
      firstName: billing?.firstName,
      lastName: billing?.lastName,
      code: params.planCode,
    };

    dispatch(subscribeToPlan(payload));
  };
  if (loading) return <Loading isPage={true} color="#d28b28" />;

  return (
    <section className="billing-checkout-wrapper position-relative">
      <div className="container">
        <div className="back">
          <span className="d-flex align-items-center gap-2" onClick={goBack}>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.8334 7.00008H1.16675M1.16675 7.00008L7.00008 12.8334M1.16675 7.00008L7.00008 1.16675"
                stroke="#D28B28"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Back
          </span>
        </div>
        <div className="header">
          <h1 className="title">
            {getAddonText(planData?.plans, params?.planType, activeType)} to{' '}
            {params?.planType} plan
          </h1>
          <p className="body">
            For teams that want an all-in-one solution with visibility and control
          </p>
        </div>
        <section className="row mt-4 pt-3">
          <div className="col-sm-12 col-md-8 border-right">
            <BillingAddress
              getSelectedOption={onHandleSelectedPayment}
              info={userData?.user}
              setBilling={setBilling}
              billing={billing}
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <OrderSummary
              selectedPayment={selectedPaymentOption}
              type={params?.planType}
              getAddonText={getAddonText(planData?.plans, params?.planType, activeType)}
              handlePayment={handlePayment}
              isLoading={isLoading}
              setBilling={setBilling}
              billing={billing}
            />
          </div>
        </section>
      </div>
    </section>
  );
};

export default Checkout;
