import Loading from 'components/UI/Loading';
import { toastSuccess } from 'components/UI/toast';
import { useEffect, useState } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toggleAction } from 'redux/actions/ToggleAction';
import LineChartView from './components/LineChartView';
import SubHeader from './components/SubHeader';

import classNames from 'classnames';
import BulkAssignTransactionAside from 'components/BulkAction/BulkAssignTransactionAside';
import RequestFunds from 'components/FundRequest/RequesterModal/RequestFunds';
import { format } from 'date-fns';
import {
  addFundsToAccount,
  getAccountReauthorizationCode,
  getCompanyStats,
  getMandateStatus,
  getPaymentLink,
  sendMonoAuthCode,
  syncBankAccountData,
  verifyPaymentReference,
} from 'redux/actions/CompaniesAction';
import { RESET_BLOCK_BUDGET } from 'redux/reducers/BudgetsReducer';
import { RESET_BLOCK_DATE_RANGE } from 'redux/reducers/OverviewReducer';
import { SELECTED_TABLE_ROWS } from 'redux/reducers/TableReducer';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { buildChartDataFromTransaction } from 'utils/helper';
import {
  getSingleBalance,
  getSubAccountTransactions,
} from '../../../redux/actions/BudgetsAction';
import TransactionTable from '../SubAccounts/Components/TransactionTable';
import SubAccountsTable from '../SubAccounts/SubAccountsTable';
import AccountDetailsModal from './AccountDetailsModal';
import MandateDialog from './AccountLinking/LinkedAccountMandate';
import PendingMandateNotice from './AccountLinking/PendingMandateNotice';
import CashEmptyStateData from './CashEmptyStateData';
import FundsModal from './FundsModal';
import NewAccountModal from './NewAccountModal';
import StatementModal from './StatementModal';
import SwapModal from './components/SwapModal';

const Overview = ({ company }) => {
  // TransactionsAction top header
  const location = useLocation();
  const { push } = useHistory();
  const { accountCode } = useParams();
  const dispatch = useDispatch();
  const [toggleBalance, setToggleBalance] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [monoAuthCode, setMonoAuthCode] = useState(null);
  const [fundStep, setFundStep] = useState(1);
  const [reauthorizationCode, setReauthorizationCode] = useState(null);
  const [statementModal, setStatementModal] = useState(false);
  const [mandateModal, setMandateModal] = useState(false);
  const [insideModalClick, setInsideModalClick] = useState(false);
  const [requestFunds, setRequestFunds] = useState(false);
  const [syncing, setSyncing] = useState(false);
  // const [viewType, setViewType] = useState('payouts');
  const [selectedAccountDetails, setSelectedAccountDetails] = useState(null);
  const [accountDetailsModal, setAccountDetailsModal] = useState(false);
  const [newAccountModal, setNewAccountModal] = useState(false);
  const [fetchNewData, setFetchNewData] = useState(false);
  const [openSwapModal, setOpenSwapModal] = useState(false);

  const toggleModalHandler = () => setIsModalOpen(true);
  const closeHandler = () => {
    if (!insideModalClick) setIsModalOpen(false);
    setFundStep(1);
    setPaymentFailed(false);
    setPaymentVerified(false);
  };

  const toggleNewAccountModal = () => {
    setNewAccountModal((prevValue) => !prevValue);
  };
  const [searchParams] = useState(new URLSearchParams(location.search));

  const {
    getStatistics: { data, loading },
    getPaymentLink: { data: dataPaymentLink, loading: loadingPaymentLink },
    verifyPaymentReference: {
      data: verifyPaymentReferenceData,
      loading: verifyingPayment,
    },
    getAccountReauthorizationCode: {
      data: reauthorizationCodeData,
      loading: fetchingReauthenticationCode,
    },
    addFundsToAccount: { loading: isAddingFunds, success: fundsAdded },
    getMandateStatus: { success: getMandateSuccess },
  } = useSelector(({ companies }) => companies);

  const {
    selectedTableRows: { selectedRows, type },
  } = useSelector(({ table }) => table);

  const {
    getSingleBalance: {
      data: singleBalance,
      loading: singleBalanceLoading,
      success: singleBalanceSuccess,
    },
    getSubAccountTransactions: {
      data: { meta: { total: totalTransactions } = {} } = {},
      pageSource,
      loading: transactionLoading,
    },
  } = useSelector(({ budgets }) => budgets);

  const {
    user: { data: userData },
  } = useSelector(({ auth }) => auth);

  const { open: openMonoConnect = false } = useSelector(
    ({ monoAccountLinking }) => monoAccountLinking,
  );

  const defaultCurrency = singleBalance?.currency;

  const { selectedDateRange } = useSelector(({ overview }) => overview);
  const [key, setKey] = useState('overview');

  const [chartData, setChartData] = useState({
    chart: [],
    totalSpent: 0,
    totalPercentage: 0,
  });
  const [dateRange, setDateRange] = useState({ from: null, to: null });

  useEffect(() => {
    const type = ['transactions'].includes(key) ? key : null;
    dispatch({
      type: SELECTED_TABLE_ROWS,
      payload: {
        selectedRows: [],
        type: type,
      },
    });
  }, [key]);

  useEffect(() => {
    if (singleBalanceSuccess) {
      if (!!singleBalance?.subaccount) {
        dispatch({ type: RESET_BLOCK_BUDGET, blockType: 'getSingleSubAccount' });
        push('/404');
      }
    }
  }, [singleBalanceSuccess]);

  const { permissions } = allPermissions();

  const canViewDash = hasPermission({
    permissions,
    scopes: ['org-*', 'dash-*', 'dash-view', 'dash-edit'],
  });

  const handleRange = (val) => {
    switch (val) {
      case 1:
        setChartData({
          chart: [],
          totalSpent: 0,
          totalPercentage: 0,
        });

        return;
      case 2:
        setChartData({
          chart: [],
          totalSpent: 0,
          totalPercentage: 0,
        });

        return;
      case 3:
        setChartData({
          chart: [],
          totalSpent: 0,
          totalPercentage: 0,
        });

        return;
    }
  };
  useEffect(() => {
    handleRange(Math.floor(Math.random() * 3) + 1);
    const { startDate, endDate } = selectedDateRange?.range[0];
    setDateRange({
      from: format(startDate, 'yyyy-MM-dd') + ' 00:00:00',
      to: format(endDate, 'yyyy-MM-dd') + ' 23:59:59',
    });
  }, [selectedDateRange]);

  useEffect(() => {
    if (userData?.user?.company?.code && singleBalance && !singleBalance?.number) {
      dispatch(getBankAccount({ code: userData?.user?.company?.code }));
    }
  }, [userData?.user?.company?.code, singleBalance?.number]);

  useEffect(() => {
    // dispatch(getBalances());
    setFetchNewData(accountCode !== singleBalance?.code);
    if (accountCode && accountCode !== singleBalance?.code) {
      dispatch(getSingleBalance(accountCode));
      dispatch({ type: RESET_BLOCK_DATE_RANGE, blockType: 'selectedDateRange' });
    }
  }, []);

  useEffect(() => {
    if (company.code && dateRange?.from !== null) {
      const { from, to } = dateRange;
      if (fetchNewData && canViewDash) {
        dispatch(
          getCompanyStats({
            id: company.code,
            params: { from, to, balance: accountCode },
          }),
        );
      }
    }
  }, [company.code, dateRange, fetchNewData]);

  useEffect(() => {
    if (monoAuthCode) dispatch(sendMonoAuthCode({ code: company.code, monoAuthCode }));
  }, [monoAuthCode]);

  useEffect(() => {
    if (data) {
      const { chart, totalSpent } = data;

      setChartData({
        chart: buildChartDataFromTransaction(chart[defaultCurrency] || []),
        totalSpent: (totalSpent && totalSpent[defaultCurrency]) || [],
        totalPercentage: 0,
      });
    }
  }, [data, defaultCurrency]);

  const initiatePayment = (payload) => {
    dispatch(getPaymentLink({ code: company.code, payload }));
  };

  const addFunds = (payload) => {
    dispatch(addFundsToAccount(payload));
  };

  const [paymentVerified, setPaymentVerified] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);

  const verifyPayment = (reference) => {
    dispatch(verifyPaymentReference({ code: company.code, reference }));
  };

  const getReauthorizationCode = (authorizationCode) => {
    dispatch(getAccountReauthorizationCode({ code: company.code, authorizationCode }));
  };

  useEffect(() => {
    const samePage = singleBalance?.code === accountCode;
    if (!samePage || pageSource !== 'accounts') {
      dispatch(getSubAccountTransactions({ code: accountCode, pageSource: 'accounts' }));
    }
  }, []);

  useEffect(() => {
    if (reauthorizationCodeData && reauthorizationCodeData?.token) {
      setReauthorizationCode(reauthorizationCodeData?.token);
    }
  }, [reauthorizationCodeData]);

  useEffect(() => {
    if (dataPaymentLink && dataPaymentLink?.payment_link) {
      window.location = dataPaymentLink?.payment_link;
    }
  }, [dataPaymentLink]);

  const handleMandateModal = () => {
    if (singleBalance?.code && !singleBalanceLoading) {
      if (
        ['pending'].includes(singleBalance?.mandateStatus) &&
        singleBalance?.mandateCode &&
        singleBalance?.mandateType !== 'signed'
      )
        dispatch(getMandateStatus(singleBalance?.mandateCode));
      if (
        ['linked', 'direct-debit'].includes(singleBalance?.accountType) &&
        (['rejected', 'failed'].includes(singleBalance?.mandateStatus) ||
          !singleBalance?.mandateCode)
      )
        setMandateModal(true);
    }
  };

  useEffect(() => {
    const delayTime = 2000;
    const timer = setTimeout(handleMandateModal, delayTime);

    return () => clearTimeout(timer);
  }, [singleBalance?.code, singleBalance?.mandateStatus, singleBalanceLoading]);

  useEffect(() => {
    if (getMandateSuccess) setMandateModal(true);
  }, [getMandateSuccess]);

  useEffect(() => {
    if (verifyPaymentReferenceData) {
      if (verifyPaymentReferenceData?.status === 'success') {
        setPaymentFailed(false);
        setPaymentVerified(true);
      } else {
        setPaymentVerified(false);
        setPaymentFailed(true);
      }
    }
  }, [verifyPaymentReferenceData]);

  useEffect(() => {
    if (searchParams.get('funding') === 'true') {
      setIsModalOpen(true);
      setFundStep(1);
    }
    if (searchParams.get('isGenerateStatement')) {
      setStatementModal(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (fundsAdded) setIsModalOpen(false);
    if (!openMonoConnect) setSyncing(false);
  }, [fundsAdded, openMonoConnect]);

  const handleRefresh = () => {
    const { from, to } = dateRange;
    if (canViewDash)
      dispatch(
        getCompanyStats({ id: company.code, params: { from, to, balance: accountCode } }),
      );
  };

  const closeStatementModal = () => {
    setStatementModal(false);
  };

  const closeDetailsModal = () => {
    setAccountDetailsModal(false);
    setSelectedAccountDetails(null);
  };

  const openDetailsModal = () => {
    setSelectedAccountDetails(singleBalance);
    setAccountDetailsModal(true);
  };

  const openModal = (modal) => {
    if (modal === 'request') setRequestFunds(true);
    else setStatementModal(true);
  };

  const handleSelect = (tabKey) => {
    setKey(tabKey);
  };

  const isDisabled =
    ['linked', 'direct-debit'].includes(singleBalance?.accountType) &&
    singleBalance?.mandateStatus !== 'granted';

  if (singleBalanceLoading) return <Loading isPage color="#D28B28" />;

  const triggerDataSync = () => {
    if (syncing) return;
    setSyncing(true);
    dispatch(syncBankAccountData({ code: singleBalance?.code }));
    toastSuccess('Data sync initiated...');
  };

  const handleRowSelect = (rowData, type) => {
    dispatch({
      type: SELECTED_TABLE_ROWS,
      payload: { selectedRows: rowData, type },
    });
  };

  const handleSwapping = () => {
    setOpenSwapModal((prevValue) => !prevValue);
  };

  return (
    <div
      className={`${
        selectedRows.length > 0 && key === 'transactions' && type === 'transactions'
          ? 'transaction-page-grid'
          : ''
      }`}
    >
      <div className="page-wrapper pb-3">
        <div className="w-100">
          <SubHeader
            disableActions={isDisabled}
            handleRefresh={handleRefresh}
            title={singleBalance?.name}
            account={{
              ...singleBalance,
              isLinked: !!singleBalance?.isLinked,
              isSubaccount: singleBalance?.subaccount,
            }}
            openModal={openModal}
            addFunds={toggleModalHandler}
            setMonoCode={setMonoAuthCode}
            openDetailsModal={openDetailsModal}
            toggleNewAccountModal={toggleNewAccountModal}
            triggerDataSync={triggerDataSync}
          />
          <FundsModal
            closeHandler={closeHandler}
            isModalOpen={isModalOpen}
            setInsideModalClick={setInsideModalClick}
            fundType={toggleBalance}
            fundStep={fundStep}
            selectedAccount={singleBalance}
            setFundStep={setFundStep}
            setMonoAuthCode={setMonoAuthCode}
            handleBack={() => setFundStep(1)}
            accounts={company.accounts || []}
            initiatePayment={initiatePayment}
            verifyPayment={verifyPayment}
            paymentVerified={paymentVerified}
            paymentFailed={paymentFailed}
            getReauthorizationCode={getReauthorizationCode}
            reauthorizationCode={reauthorizationCode}
            destination={singleBalance?.code}
            addFunds={addFunds}
            isAddingFunds={isAddingFunds}
          />

          <StatementModal
            isModalOpen={statementModal}
            account={{
              code: singleBalance?.code,
              name: singleBalance?.name,
              accountName: singleBalance?.accountName,
              balance: singleBalance?.balance,
              currency: singleBalance?.currency,
            }}
            closeHandler={closeStatementModal}
          />

          <AccountDetailsModal
            details={selectedAccountDetails}
            isModalOpen={accountDetailsModal}
            closeHandler={closeDetailsModal}
          />

          <NewAccountModal
            isModalOpen={newAccountModal}
            closeHandler={toggleNewAccountModal}
            accountType={2}
            balanceCode={accountCode}
          />

          <SwapModal
            companyCode={company.code}
            accounts={company.accounts}
            isOpen={openSwapModal}
            closeHandler={handleSwapping}
            defaultSelectedAccount={{
              destination: singleBalance?.code,
              isDestinationDisabled: false,
            }}
          />

          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            className="mb-3 mt-4"
            onSelect={handleSelect}
          >
            <Tab
              eventKey="overview"
              title={<div className="d-flex mb-1">Overview</div>}
              tabClassName="new-tab"
            >
              <div>
                {(loading ||
                  loadingPaymentLink ||
                  verifyingPayment ||
                  fetchingReauthenticationCode) && <Loading isPage color="#D28B28" />}
              </div>
              {['linked', 'direct-debit'].includes(singleBalance?.accountType) &&
                (['pending', 'rejected', 'failed'].includes(
                  singleBalance?.mandateStatus,
                ) ||
                  !singleBalance?.mandateCode ||
                  (!singleBalance?.isReadyForDebit &&
                    ['granted'].includes(singleBalance?.mandateStatus))) && (
                  <PendingMandateNotice
                    onClick={() => setMandateModal(true)}
                    status={singleBalance?.mandateStatus}
                    mandate={singleBalance?.mandateType}
                    isLinked={singleBalance?.isLinked}
                    isReadyForDebit={singleBalance?.isReadyForDebit}
                  />
                )}

              {chartData.totalSpent > 0 ? (
                <LineChartView
                  chart={chartData.chart}
                  totalSpent={chartData.totalSpent}
                  currency={defaultCurrency}
                  totalPercentage={chartData.totalPercentage}
                  noDataPlaceholder="A trend of your expenses within a period"
                />
              ) : (
                <div className="mt-3">
                  <CashEmptyStateData
                    view={'view'}
                    toggleHandler={() => dispatch(toggleAction())}
                    disabled={isDisabled}
                  />
                </div>
              )}
            </Tab>
            <Tab
              eventKey="transactions"
              title={
                <div className="d-flex mb-1">
                  Transactions{' '}
                  <div className="count">
                    <span className="m-auto">
                      {transactionLoading ? 0 : totalTransactions || 0}
                    </span>
                  </div>
                </div>
              }
              tabClassName="new-tab"
            >
              <TransactionTable
                accountCode={accountCode}
                handleSwapping={handleSwapping}
                permissionForOwnersAndManagers={canViewDash}
                pageSource={'accounts'}
                onRowSelect={(data) => {
                  handleRowSelect(data, 'transactions');
                }}
                openStatementModal={openModal}
              />
            </Tab>
            <Tab
              eventKey="subaccounts"
              title={
                <div className="d-flex mb-1">
                  Subaccounts
                  <div className="count">
                    <span className="m-auto">{singleBalance?.subaccounts}</span>
                  </div>
                </div>
              }
              tabClassName={classNames('new-tab')}
            >
              <SubAccountsTable
                parentAccount={{
                  code: singleBalance?.code,
                  name: singleBalance?.name,
                  accountType: singleBalance?.accountType,
                  mandateStatus: singleBalance?.mandateStatus,
                }}
                toggleNewAccountModal={toggleNewAccountModal}
                balanceCode={accountCode}
              />
            </Tab>
          </Tabs>
          <Modal show={mandateModal} centered dialogClassName="custom-dialog">
            <MandateDialog onClose={() => setMandateModal(false)} />
          </Modal>
          {requestFunds && (
            <RequestFunds
              isOpen={requestFunds}
              toggleHandler={() => setRequestFunds(!requestFunds)}
              source={singleBalance?.code}
              bankAccount={singleBalance?.code}
              bankAccountName={singleBalance?.name}
              topUpCard={false}
              newBudget={false}
              topUpBudget={false}
            />
          )}
        </div>
      </div>
      {key === 'transactions' && selectedRows.length > 0 && type === 'transactions' && (
        <BulkAssignTransactionAside />
      )}
    </div>
  );
};
export default Overview;
