import Loading from 'components/UI/Loading';
import { useEffect, useState } from 'react';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getBeneficiaries } from 'redux/actions/BeneficiariesAction';
import { getBudgets, getSelectedBudget } from 'redux/actions/BudgetsAction';
import { getSelectedCard } from 'redux/actions/CardAction';
import { getSelectedReimbursements } from 'redux/actions/ReimbursementsAction';
import { getRoles } from 'redux/actions/RolesAction';
import { getTeamMembers, teamOverview } from 'redux/actions/TeamsAction';
import { getSelectedTransaction } from 'redux/actions/TransactionsAction';
import SelectedTeamsHeader from './SelectedTeamsHeader';
import TeamBudgets from './TeamBudgets';
import TeamCard from './TeamCard';
import TeamMemberReimbursment from './TeamMemberReimbursment';
import TeamMembers from './TeamMembers';
import TeamOverview from './TeamOverview';
import TeamReimbursments from './TeamReimbursments';
import TeamTransaction from './TeamTransaction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';

const SelectedTeams = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const locationArray = location.pathname.split('/');
  const { teamsId } = useParams();

  const { permissions } = allPermissions();
  const canManage = hasPermission({
    permissions,
    scopes: ['team-*', 'team-edit'],
  });
  const canViewEmployee = hasPermission({
    permissions,
    scopes: ['employee-*', 'employee-view'],
  });

  const canViewBudget = hasPermission({
    permissions,
    scopes: ['budget-*', 'budget-view'],
  });

  const {
    getSelectedBudget: { data: selectedBudgets, loading },
  } = useSelector(({ budgets }) => budgets);

  const {
    getBeneficiaries: { data: beneficiaryData = {} },
  } = useSelector(({ beneficiaries }) => beneficiaries);
  const { beneficiaries = [] } = beneficiaryData;

  const {
    getBudget: { data: budgets = {} },
  } = useSelector(({ budgets }) => budgets);

  // const {
  //   getSelectedCard: { data: selectedCard, loading: loadingSelectedCard },
  // } = useSelector(({ card }) => card);

  const {
    teamOverview: {
      data: { team: teamData = {} } = {},
      data: stats = {},
      loading: loadingTeam,
    },
  } = useSelector(({ teams }) => teams);

  const {
    getRoles: { data: rolesData = [] },
  } = useSelector(({ roles }) => roles);

  const [key, setKey] = useState(locationArray[locationArray.length - 1]);

  useEffect(() => {
    if (teamsId) {
      dispatch(getSelectedBudget({ team: teamsId }));
      // dispatch(getSelectedCard({ team: teamsId }));
      dispatch(getSelectedTransaction({ team: teamsId }));
      dispatch(getSelectedReimbursements({ team: teamsId }));
      dispatch(getTeamMembers({ code: teamsId }));
      dispatch(teamOverview(teamsId));
      if (!beneficiaries.length && canViewEmployee) dispatch(getBeneficiaries());
      if (!budgets?.budgets?.length && canViewBudget) dispatch(getBudgets());
      if (!rolesData?.length) dispatch(getRoles());
    }
  }, [teamsId]);

  const loadingColor = '#D28B28';

  if (loadingTeam) return <Loading isPage color={loadingColor} />;

  const handleSelect = (tabKey) => {
    setKey(tabKey);
    history.push(`/teams//${teamsId}/${tabKey}`);
  };
  return (
    <div className="selected-budgets-wrapper">
      <SelectedTeamsHeader teamData={teamData} />
      <Container>
        <Row>
          <Col xs={12} className="mt-4">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={handleSelect}
              className="mb-3"
            >
              <Tab eventKey="overview" title="Overview" tabClassName="new-tab">
                <TeamOverview team={teamData} stats={stats} />
              </Tab>

              <Tab eventKey="members" title="Members" tabClassName="new-tab">
                <TeamMembers />
              </Tab>

              <Tab eventKey="budgets" title="Budgets" tabClassName="new-tab">
                <TeamBudgets />
              </Tab>

              <Tab eventKey="cards" title="Cards" tabClassName="new-tab">
                <TeamCard budget={selectedBudgets} />
              </Tab>
              <Tab eventKey="transactions" title="Transactions" tabClassName="new-tab">
                <TeamTransaction />
              </Tab>
              <Tab
                eventKey="reimbursements"
                title="Reimbursements"
                tabClassName="new-tab"
              >
                {canManage ? <TeamReimbursments /> : <TeamMemberReimbursment />}
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default SelectedTeams;
