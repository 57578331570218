import React from 'react';
import './style.less';
import { ArrowLeftOutlined } from '@ant-design/icons';

const AuthFormWrapper = ({ children }) => {
  return (
    <div className="auth-form-wrapper">
      <div className="contents">{children}</div>
    </div>
  );
};

const AuthFormHeader = ({ titlle, subtitle, backButton, goBack }) => {
  return (
    <div className="form-header" style={{ margin: ' 40px auto 0px', maxWidth: '28rem' }}>
      {backButton && (
        <div className="back-line mb-3 cursor" onClick={goBack}>
          <div className=" gap-2 d-flex align-items-center">
            <ArrowLeftOutlined />
            <span>Back</span>
          </div>
        </div>
      )}
      <h1>{titlle}</h1>
      {subtitle && <h4 className="text-lc mt-3">{subtitle} </h4>}
    </div>
  );
};

export { AuthFormWrapper, AuthFormHeader };
