import React, { useState } from 'react';
import '../../styles.scss';
import {
  HintAlertIcon,
  MailIcon,
  PlusIcon,
  ShieldAlertIcon,
  UserEditIcon,
  UserPlusIcon,
} from 'assets/icons';
import Table from 'components/Table';
import { OwnersColumn } from 'utils/mockData';
import { Form, Modal, Row } from 'react-bootstrap';
import ModalContainer from 'components/ModalContainer';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import CustomDatePicker from 'components/UI/CustomDatePicker';
import CustomPhoneNumberInput from 'components/UI/CustomPhoneNumberInput';
import FileUpload from 'components/UI/FileUpload';
import { idType } from 'utils/helper';

const BusinessOwner = () => {
  const [open, setOpen] = useState(false);
  const [openInvite, setOpenInvite] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [documents, setDocuments] = useState({ proofOfAddress: '', idDocument: '' });
  const [formList, setFormList] = useState([{ name: '', email: '' }]);

  const addForm = () => {
    setFormList([...formList, { name: '', email: '' }]);
  };
  return (
    <div className="business-owner-container">
      <div className="title">
        <h3>Business owners</h3>
        <p>Please provide information for anyone who owns 10% or more of the business.</p>
      </div>
      <div className="space-y-2 border-bottom bg-transparent pb-3 bg-transparent">
        <div className="business-owner-action ">
          <div>
            <UserEditIcon />
          </div>
          <div>
            <h6>Add owner details manually</h6>
            <p>Quickly input business owner details</p>
          </div>
          <button onClick={() => setOpen(true)} className="btn border xxs ms-auto">
            Add details
          </button>
        </div>
        {/*  */}
        <div className="business-owner-action">
          <div>
            <UserPlusIcon />
          </div>
          <div>
            <h6>Invite owners to provide details</h6>
            <p>Send a secure Bujeti link to request details</p>
          </div>
          <button onClick={() => setOpenInvite(true)} className="btn border xxs ms-auto">
            Request details
          </button>
        </div>
      </div>

      <div className="mt-3">
        <Table
          columns={OwnersColumn}
          data={[{ name: 'David Johnson', email: 'contact@mail.com', status: 'pending' }]}
        />
      </div>

      <Modal show={open} centered dialogClassName="custom-dialog">
        <ModalContainer
          lg
          onCancel={() => setOpen(false)}
          actionBtnText="Save"
          title="Add business owner"
          subTitle="Please provide information for anyone who owns 10% or more of the business."
        >
          <div className="border-bottom bg-transparent pb-3">
            <Row className="mb-2">
              <CustomInput
                type="text"
                label="Percentage owned"
                placeholder="Ex. 10%"
                name="percentageOwned"
                // onChange={handleChangePassword}
                // value={changePassword.new_password}
              />
            </Row>
          </div>

          <div className="pt-4 border-bottom bg-transparent pb-3">
            <h6 className="text-md p-0 m-0 fw-semibold">Personal details</h6>

            <Row className="mb-2 mt-2 align-items-center">
              <CustomInput
                type="text"
                label="First name"
                placeholder="Enter first name"
                name="state"
                // onChange={handleChangePassword}
                // value={changePassword.new_password}
                md={6}
              />

              <CustomInput
                type="text"
                label="Last name"
                placeholder="Enter last name"
                name="postcode"
                // onChange={handleChangePassword}
                // value={changePassword.new_password}
                md={6}
              />
            </Row>

            <Row className="mb-2">
              <CustomDatePicker
                label="Date of birth"
                name="dob"
                // onChange={handleChangeDate}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="DD / MM / YYYY"
                // selected={kycInfo.dob && dayjs(kycInfo.dob).toDate()}
              />
            </Row>

            <Row className="mb-2">
              <CustomSelect
                label="Country"
                name="country"
                placeholder="Select country"
                // onChange={(val) => handleChangeBusinessType({ val })}
                // value={businessDoc.type}
                options={[{ value: 'NG', label: 'Nigerian' }]}
              />
            </Row>

            <Row className="mb-2">
              <CustomInput
                type="text"
                label="Bank Verification Number (BVN)"
                placeholder="11234567890"
                name="bvn"
                // onChange={handleChangePassword}
                // value={changePassword.new_password}
              />
              <Form.Text className="text-muted gap-1 d-flex align-items-center">
                <ShieldAlertIcon />
                <span className="text-xs">
                  Your BVN is used for identify verification and secured with encryption.
                </span>
              </Form.Text>
            </Row>
          </div>

          <div className="pt-4 border-bottom bg-transparent pb-3">
            <h6 className="text-md p-0 m-0 fw-semibold">Contact information</h6>

            <Row className="mb-2 mt-2">
              <CustomPhoneNumberInput
                label="Phone number"
                placeholder="Enter your mobile number"
                // onChange={(localFormat, international, countryCode) =>
                //   handlePhoneNumberChange(localFormat, international, countryCode)
                // }
                // value={personalInfo.internationalFormat}
              />
            </Row>

            <Row className="mb-2">
              <CustomInput
                label="Home address"
                name="address"
                placeholder="Enter home address"
                // onChange={(val) => handleChangeBusinessType({ val })}
                // value={businessDoc.type}
              />
            </Row>

            <Row className="mb-2">
              <FileUpload
                label="Proof of Address"
                supportType="JPEG, PNG, and PDF formats, up to 5 MB."
                uploadedFile={documents.proofOfAddress}
                onChange={({ assetCode }) =>
                  setDocuments({ ...documents, proofOfAddress: assetCode })
                }
                setUploadingFile={setUploadingFile}
                uploadingFile={uploadingFile}
              />
              <Form.Text className="text-muted gap-1 d-flex align-items-start flex-column">
                <div className="p-0 m-0 d-flex gap-1 align-items-start pb-2 text-xs">
                  <div>
                    <HintAlertIcon />
                  </div>
                  Upload a copy of your bank statement, utility bill, phone bill, tax
                  assessment, or any government-issued document.
                </div>

                <ul className="py-0 my-0 text-xs">
                  <li> All documents must be less than 3 months old</li>
                  <li>
                    And must include the name of the identified individual or business.
                  </li>
                </ul>
              </Form.Text>
            </Row>
          </div>

          <div className="pt-4 pb-3">
            <h6 className="text-md p-0 m-0 fw-semibold">Identity verification</h6>

            <Row className="mb-2 mt-2">
              <CustomSelect
                label="Document type"
                name="documentType"
                placeholder="Select document type"
                // onChange={(val) => handleChangeBusinessType({ val })}
                // value={businessDoc.type}
                options={idType}
              />
            </Row>

            <Row className="mb-2">
              <CustomInput
                type="text"
                label="ID number"
                placeholder="11234567890"
                name="bvn"
                // onChange={handleChangePassword}
                // value={changePassword.new_password}
              />
            </Row>

            <Row className="mb-2">
              <FileUpload
                label="Upload ID"
                supportType="JPEG, PNG, and PDF formats, up to 5 MB."
                uploadedFile={documents.idDocument}
                onChange={({ assetCode }) =>
                  setDocuments({ ...documents, idDocument: assetCode })
                }
                setUploadingFile={setUploadingFile}
                uploadingFile={uploadingFile}
              />
            </Row>
          </div>
        </ModalContainer>
      </Modal>

      <Modal show={openInvite} centered dialogClassName="custom-dialog">
        <ModalContainer
          lg
          onCancel={() => setOpenInvite(false)}
          actionBtnText={
            <span className="d-flex align-items-center gap-2">
              <MailIcon width={18} height={18} stroke="#fff" /> Send email
            </span>
          }
          title="Request business owner details"
          subTitle="Send a secure Bujeti link to request details. Pro tip: Give them a heads-up first."
        >
          {formList.map((item, index) => (
            <Row key={index} className="mb-2 align-items-center">
              <CustomInput
                type="text"
                label="First name"
                placeholder="Enter first name"
                name={`${item.name}-${index}`}
                // onChange={handleChangePassword}
                // value={changePassword.new_password}
                md={6}
              />

              <CustomInput
                type="text"
                label="Email"
                placeholder="Enter email"
                name={`${item.email}-${index}`}
                // onChange={handleChangePassword}
                // value={changePassword.new_password}
                md={6}
              />
            </Row>
          ))}
          <div
            onClick={addForm}
            className="d-flex align-items-center gap-2 text-sm text-grey mt-3 cursor"
          >
            <PlusIcon stroke="#57534E" /> Add another
          </div>
        </ModalContainer>
      </Modal>
    </div>
  );
};

export default BusinessOwner;
