import {
  ApproveCheck,
  CancelICon,
  DownloadIcon,
  MailIcon,
  RetryIcon,
} from 'assets/icons';
import { ReactComponent as ReloadIcon } from 'assets/icons/retry-icon.svg';
import { TransactionLogo } from 'assets/images';
import ConfirmDialog from 'components/ConfirmDialog';
import { CurrencyType, TransactionStatusType } from 'components/FilterModal/FilterHelper';
import NoData from 'components/NoData';
import ReceiptPdf from 'components/ReceiptPdf';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import TransactionDrawer from 'components/TransactionModal/TransactionDrawer';
import Loading from 'components/UI/Loading';
import { toastSuccess } from 'components/UI/toast';
import { jsPDF, PDFDownloadLink } from 'components/UIHooks/JsPDF';
import { useDebounce } from 'hooks/useDebounce';
import 'jspdf-autotable';
import RetryTransactionModal from 'pages/Transactions/components/RetryTransactionModal';
import TransactionEmptyStateData from 'pages/Transactions/transactionEmptystateData';
import { useCallback, useEffect, useMemo, useRef, useState, memo } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getBudgets } from 'redux/actions/BudgetsAction';
import { getCard } from 'redux/actions/CardAction';
import {
  approveTransaction,
  cancelTransaction,
  exportTransaction,
  getSingleTransaction,
  getTransaction,
  requeryTransaction,
  requestTransactionDetails,
  retryTransaction,
} from 'redux/actions/TransactionsAction';
import { RESET_BLOCK_DATE_RANGE } from 'redux/reducers/OverviewReducer';
import { getDateRangeFromTitle } from 'utils';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import {
  buildExportTransactionData,
  buildExportTransactionQuickBooks,
  buildExportTransactionXero,
  buildTransactionTableData,
  capitalizeFirstLetter,
} from 'utils/helper';
import {
  categoryTransactionColumns,
  columns,
  columnsTransactionCSV,
  columnsTransactionQuickBooks,
  columnsTransactionXero,
} from 'utils/mockData';
import { getQueryParams, updateStatus } from 'utils/utility';
import SearchLoader from './Components/SearchLoader';

const CategoryTransactionTable = ({
  transactionCode = null,
  handleSelect = () => null,
  onRowSelect,
  queryParams,
  hasCustomEmptyState = false,
  customEmptyState,
  tabKey,
  category,
}) => {
  const [selectTransaction, setSelectTransaction] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const [search, setSearch] = useState('');
  const debouncedValue = useDebounce(search, 600);
  const history = useHistory();
  const [filterData, setFilterData] = useState([
    { title: 'Amount', type: 'slider' },
    ...TransactionStatusType,
    ...CurrencyType,
  ]);
  const [cardFilterData, setCardFilterData] = useState({});
  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;
  const [filteredQuery, setFilteredQuery] = useState({ ...queryParams });
  const [isRetryVisible, setIsRetryVisible] = useState(false);
  const [isFilteredByDate, setIsFilteredByDate] = useState(false);
  const [assets, setAssets] = useState({
    active: {},
    assetsList: [],
    visible: false,
  });
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [type, setType] = useState(null);
  const [csvPlatform, setCsvPlatform] = useState([]);

  const {
    getCard: { data: cardData, loading: isCardLoading },
  } = useSelector(({ card }) => card);

  const {
    getTransaction: { data: { meta = {} } = {}, data = {}, loading, success },
    exportTransaction: {
      data: { transactions: exportData = [] } = {},
      loading: exportLoading,
    },
    editTransactionById: { success: isEditSuccess },
    getSingleTransaction: { data: singleTransaction = {} },
    retryTransaction: { loading: retryLoading, success: retrySuccess },
    approveTransaction: { loading: approveLoading, success: approveSuccess },
    cancelTransaction: { loading: cancelLoading, success: cancelSuccess },

    requeryTransaction: { loading: loadingRequery, success: successRequery },
  } = useSelector(({ transaction }) => transaction);
  const {
    getCompany: { data: companyData = {} },
  } = useSelector(({ companies }) => companies);
  const { page, total, hasMore, perPage, nextPage } = meta;
  const { transactions = [] } = data;
  const {
    getBudget: { data: budgetData = {} },
  } = useSelector(({ budgets }) => budgets);

  const { permissions } = allPermissions();

  const canViewTransaction = hasPermission({
    permissions,
    scopes: ['transaction-*', 'transaction-view'],
  });

  const canEditTransaction = hasPermission({
    permissions,
    scopes: ['transaction-*', 'transaction-edit'],
  });

  const canViewBudget = hasPermission({
    permissions,
    scopes: ['budget-*', 'budget-view'],
  });

  const toggleHandler = () => {
    return history.push('/expenses/budgets');
  };

  const handleRowClick = (row) => {
    setSelectTransaction(row);
    setAssets({
      ...assets,
      assetsList: row?.transactionData?.receipts,
    });
  };

  useEffect(() => {
    dispatch(getCard());
  }, []);

  const onHandleAssetViewer = () => {
    setAssets({
      ...assets,
      visible: true,
    });
  };

  const rows = useMemo(() => buildTransactionTableData(transactions), [transactions]);

  useEffect(() => {
    if (transactions.length) {
      const allTransactions = transactions.map((transaction) => {
        return {
          value: transaction.payer ? transaction.payer.code : null,
          label: transaction.payer
            ? `${transaction.payer.firstName} ${transaction.payer.lastName}`
            : 'N/A',
          isSelected: false,
        };
      });
      const uniqueArray = allTransactions.filter(
        (initialValue, index, array) =>
          array.findIndex((v2) => v2.value === initialValue.value) === index,
      );

      const newItem = { title: 'Payer', list: uniqueArray };

      if (!filterData.some((item) => item.title === newItem.title)) {
        setFilterData([...filterData, newItem]);
      }

      if (transactionCode) {
        const element = rows.find(
          ({ transactionData: { code } }) => code === transactionCode,
        );
        setSelectTransaction(element);
      }
    }
  }, [transactions]);

  useEffect(() => {
    if (!!budgetData?.budgets?.length) {
      const data = [];
      budgetData?.budgets.forEach((budget) => {
        const { code, name } = budget;
        data.push({
          value: code,
          label: name,
          isSelected: false,
        });
      });
      setFilterData([...filterData, { title: 'Budget', list: data }]);
    }
  }, [budgetData?.budgets]);

  useEffect(() => {
    if (!isCardLoading && cardData?.cards?.length > 0) {
      const cardFilterOptions = cardData?.cards?.map((datum) => {
        return {
          label: datum.name,
          value: datum.code,
          isSelected: false,
        };
      });

      if (filterData?.find((item) => item.title === 'Card')) {
        return undefined;
      }
      setCardFilterData({ title: 'Card', list: cardFilterOptions });
      setFilterData([...filterData, { title: 'Card', list: cardFilterOptions }]);
    }
  }, [isCardLoading, cardData]);

  useEffect(() => {
    if (
      !location?.search &&
      !filtered &&
      canViewTransaction &&
      tabKey === 'transactions'
    ) {
      dispatch(getTransaction({ ...filteredQuery }));
    }
    if (!budgetData?.budgets && canViewBudget) dispatch(getBudgets());

    return () => {
      if (filtered && canViewTransaction) dispatch(getTransaction({ ...filteredQuery }));
    };
  }, [filtered]);

  useEffect(() => {
    if (debouncedValue && canViewTransaction) {
      filteredQuery.search = debouncedValue;
      dispatch(getTransaction({ ...filteredQuery, search: debouncedValue }));
    }
    if (!debouncedValue && filtered && canViewTransaction) {
      delete filteredQuery.search;
      dispatch(getTransaction({ ...filteredQuery }));
      // history.replace({ state: {} });
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  useEffect(() => {
    if (location?.search && canViewTransaction) {
      const code = getQueryParams(location?.search, 'card');
      if (code) dispatch(getTransaction({ card: [code] }));
      // isFiltered.current = true;

      const status = getQueryParams(location?.search, 'status');
      if (status) {
        setFilteredQuery({ status, ...queryParams });
        dispatch(getTransaction({ status, ...queryParams }));
        setFilterData(updateStatus(filterData, 'Status', status));
      }
    }
  }, [location]);

  useEffect(() => {
    if (success && location?.search) isFiltered.current = true;
    if (isEditSuccess) dispatch(getTransaction({ ...filteredQuery }));
  }, [success, isEditSuccess]);

  const handleFilter = (query) => {
    const amount = filterData.filter(({ title }) => title === 'Amount');
    const [min, max] = amount[0]?.value || [];
    const min_amount = min ? min * 100 : undefined;
    const max_amount = max ? max * 100 : undefined;
    const queryPayload = {
      ...filteredQuery,
      ...query,
      min_amount,
      max_amount,
    };

    setFilteredQuery(queryPayload);

    dispatch(getTransaction(queryPayload));
    isFiltered.current = !!Object.keys(query).length;
  };

  const handleExport = () => {
    dispatch(
      exportTransaction({ ...filteredQuery, search: debouncedValue ?? undefined }),
    );
  };

  const handleExportPlatform = (platform) => {
    dispatch(
      exportTransaction({ ...filteredQuery, search: debouncedValue ?? undefined }),
    );
    if (platform === 'quickbooks') setCsvPlatform(csvDataQuickBooks);
    if (platform === 'xero') setCsvPlatform(csvDataXero);
  };

  const exportPDF = () => {
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'portrait'; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const columnStyles = {};
    const title = 'Transactions Report';
    const headers = [
      columnsTransactionCSV
        .filter((item) => item.Header !== 'Files')
        .map((item) => item.Header),
    ];

    columns.forEach((column, index) => {
      columnStyles[index] = { cellWidth: 60 }; // Adjust the width as needed
    });

    const body = buildExportTransactionData(exportData);

    let content = {
      startY: 80,
      head: headers,
      body,
      columnStyles,
    };

    doc.addImage(TransactionLogo, 'JPEG', marginLeft, 25, 90, 50.5);
    // doc.text(title, 80, 40);
    doc.autoTable(content);
    doc.save('Transaction Statement.pdf');
  };
  const csvData = [
    columnsTransactionCSV
      .filter((item) => item.Header !== 'Files')
      .map((item) => item.Header),
    ...buildExportTransactionData(exportData),
  ];

  const csvDataXero = [
    columnsTransactionXero.map((item) => item.Header),
    ...buildExportTransactionXero(exportData),
  ];

  const csvDataQuickBooks = [
    columnsTransactionQuickBooks.map((item) => item.Header),
    ...buildExportTransactionQuickBooks(exportData),
  ];

  const clearFilters = () => {
    setFilteredQuery({ ...queryParams });
    setFilterData([
      { title: 'Amount', type: 'slider' },
      ...TransactionStatusType,
      ...CurrencyType,
      cardFilterData,
    ]);
  };

  const onHandleRetryClick = () => {
    setIsRetryVisible(true);
  };

  const clearLocationState = () => {
    history.replace({ state: {} });
    clearFilters();
    dispatch(getTransaction({ ...queryParams }));
    dispatch(getBudgets());
  };

  useEffect(() => {
    if (location?.search && isFiltered.current && cardFilterData) {
      const Card = getQueryParams(location?.search, 'card');
      if (Card) {
        setFilterData(updateStatus(filterData, 'Card', Card));
      }
    }
  }, [isFiltered, location, cardFilterData]);

  const tableColumn = useMemo(() => categoryTransactionColumns, [transactions]);

  const actionHandler = (event, type, value) => {
    event?.stopPropagation();
    event?.preventDefault();
    setIsPopoverOpen(true);
    setSelectedOption(value);
    setType(type.toLowerCase());

    return setConfirmModal(true);
  };

  const closeModal = () => {
    setIsPopoverOpen(false);
    setConfirmModal(false);
  };

  useEffect(() => {
    if (retrySuccess || cancelSuccess || successRequery || approveSuccess) {
      closeModal();
      isFiltered.current = true;
      dispatch(getTransaction({ ...filteredQuery }));
    }
  }, [retrySuccess, cancelSuccess, successRequery, approveSuccess]);

  const handleConfirm = () => {
    const code = selectedOption?.code;
    if (type === 'retry') {
      dispatch(retryTransaction(code));
    } else if (type === 'cancel') {
      dispatch(cancelTransaction(code));
    } else if (type === 'refresh') {
      dispatch(requeryTransaction(code));
    } else if (type === 'approve') {
      dispatch(approveTransaction(code));
    }
  };

  const generatePdfSuccess = async (event, instance) => {
    event?.stopPropagation();
    if (instance.loading || !instance.url) return;
    toastSuccess('Receipt downloaded');
  };

  const activeTxCode = useRef(null);

  const fetchSingleData = useCallback(
    (data) => {
      const { status = '', code = '' } = data?.transactionData || {};

      if (
        code === activeTxCode.current ||
        !['success', 'declined', 'failed', 'processed'].includes(status)
      )
        return;

      activeTxCode.current = code;
      if (activeTxCode.current) dispatch(getSingleTransaction(code));
    },
    [activeTxCode.current],
  );

  const Actions = useCallback(({ list: selectedData }) => {
    useMemo(() => {
      if (selectedData?.transactionData?.code !== activeTxCode.current)
        fetchSingleData(selectedData);
    }, []);

    const status = selectedData?.status?.value.toLowerCase();

    return (
      <div className="actions-dialog">
        {['processed', 'processing'].includes(status) && (
          <div
            className="actionLink"
            onClick={(event) =>
              actionHandler(event, 'refresh', selectedData?.transactionData)
            }
          >
            <RetryIcon stroke="#79716B" width={16} height={16} className="mr-4" /> Refresh
            transaction
          </div>
        )}

        {canEditTransaction && status === 'out-of-policy' && (
          <>
            <div
              className="actionLink"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                dispatch(requestTransactionDetails(selectedData?.transactionData?.code));
                setIsPopoverOpen(true);
              }}
            >
              <MailIcon width="18" height="15" stroke="#79716B" /> Request details
            </div>
            <div
              className="actionLink"
              onClick={(event) =>
                actionHandler(event, 'approve', selectedData?.transactionData)
              }
            >
              <ApproveCheck /> Resolve
            </div>
          </>
        )}

        {['failed'].includes(status) && (
          <div
            className="actionLink"
            onClick={(event) =>
              actionHandler(event, 'retry', selectedData?.transactionData)
            }
          >
            <RetryIcon stroke="#79716B" width={16} height={16} className="mr-4" /> Retry
            transaction
          </div>
        )}

        {['processing', 'pending'].includes(status) && (
          <div
            className="actionLink svg-danger text-danger"
            onClick={(event) =>
              actionHandler(event, 'cancel', selectedData?.transactionData)
            }
          >
            <CancelICon className="mr-4" width={10} height={10} /> Cancel transaction
          </div>
        )}

        {['success', 'declined', 'failed'].includes(status) && (
          <PDFDownloadLink
            document={
              <ReceiptPdf
                data={{
                  ...selectedData?.transactionData,
                  senderAccount: singleTransaction?.data?.senderAccount,
                }}
                companyData={companyData}
              />
            }
            fileName={`Transaction receipt for ${selectedData?.description}.pdf`}
            style={{
              textDecoration: 'none',
              height: 40,
              display: 'flex',
              width: '100%',
              fontFamily: 'Inter var !important',
              color: '#212529',
              alignItems: 'center',
            }}
            className="actionLink"
            onClick={(event, instance) => generatePdfSuccess(event, instance)}
          >
            <DownloadIcon className="mr-4" width={16} height={16} />
            Download receipt
          </PDFDownloadLink>
        )}
      </div>
    );
  }, []);

  const show = !!transactions?.length || (filtered && !transactions?.length);

  return (
    <>
      {loading && !filtered ? (
        <SearchLoader hasExport={true} hasAddbtn={false} />
      ) : (
        <TopBar
          showFilter={show}
          addOnClick={toggleHandler}
          inputPlaceholder="Search"
          filterData={filterData}
          handleFilterSelect={(updateVal) => {
            setFilterData(updateVal);
          }}
          searchVal={search}
          setSearchVal={setSearch}
          showBarSearch={show}
          addExport={show}
          exportLoading={exportLoading}
          handleFilterApply={handleFilter}
          withDate
          dateTitle="Transaction Date"
          handleExport={handleExport}
          handleExportPlatform={handleExportPlatform}
          csvData={csvData}
          csvPlatform={{ quickbooks: csvDataQuickBooks, xero: csvDataXero }}
          exportPDF={exportPDF}
          clearFilters={clearFilters}
          showRetry={false}
          onRetryClick={onHandleRetryClick}
          setIsFilteredByDate={setIsFilteredByDate}
          isFilteredByDate={isFilteredByDate}
        />
      )}

      {!transactions.length && !loading ? (
        <>
          {hasCustomEmptyState ? (
            customEmptyState
          ) : (
            <div>
              {isFiltered.current ? (
                <div className="tabinnerWrapper">
                  <NoData
                    headerText={`You have no transactions for this ${
                      location?.search ? 'card' : 'filter'
                    }`}
                    bodyText="Alter filter to see transactions"
                    withButton={true}
                    buttonLabel="View all transactions"
                    onClickHandler={clearLocationState}
                  />
                </div>
              ) : (
                <TransactionEmptyStateData
                  handleSelect={handleSelect}
                  selectedCategoryData={category}
                />
              )}
            </div>
          )}
        </>
      ) : (
        <>
          <Container className="px-0">
            <Row className="py-4">
              <Col xs={12} className="spaced-table">
                <Table
                  columns={tableColumn}
                  data={rows}
                  pagination
                  hasMore={hasMore}
                  hasCheckBox={false}
                  currentPage={page}
                  nextPage={() => handleNextPage(nextPage)}
                  previousPage={() => handlePreviousPage(page - 1)}
                  totalPage={Math.ceil(total / perPage)}
                  onRowClick={handleRowClick}
                  popoverAction={Actions}
                  popoverState={isPopoverOpen}
                  setPopoverState={setIsPopoverOpen}
                  // type="budgets"
                  modify={true}
                  loading={loading}
                  hasAvatar={false}
                />
              </Col>
            </Row>
          </Container>

          {selectTransaction && (
            <TransactionDrawer
              selectTransaction={selectTransaction}
              setSelectTransaction={setSelectTransaction}
              handlePdfViewer={onHandleAssetViewer}
            />
          )}
          {isRetryVisible && (
            <RetryTransactionModal
              show={isRetryVisible}
              onHide={() => setIsRetryVisible(false)}
            />
          )}
        </>
      )}

      <Modal show={confirmModal} centered dialogClassName="custom-dialog">
        <ConfirmDialog
          title={`${capitalizeFirstLetter(type)} transaction`}
          subTitle={`Are you sure you want to ${type} this transaction?`}
          onConfirm={handleConfirm}
          onCancel={closeModal}
          isDeleteDialog={type === 'cancel'}
          actionBtnText="Confirm"
          loading={retryLoading || cancelLoading || loadingRequery || approveLoading}
          dialogIcon={type === 'retry' ? <ReloadIcon /> : null}
        />
      </Modal>
    </>
  );
};

export default memo(CategoryTransactionTable);
