import { StatusIcon, TrashIcon, XlxCSV } from 'assets/icons';
import { ReactComponent as CloudArrowUp } from 'assets/icons/CloudArrowUp.svg';
import cs from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFileData } from 'redux/actions/AssetsAction';
import Loading from '../Loading';
import { toastError } from '../toast';
import './styles.scss';

const ClientFileUpload = ({
  label,
  wrapperClass,
  onChange,
  uploadingFile,
  supportType = 'Supported file types: jpeg, png, pdf. Max file size: 5mb',
  acceptedFile = {
    'image/jpeg': ['.jpeg', '.png'],
    'application/pdf': ['.pdf'],
  },
  isUploading,
  checkIsDisabled,
  removeFile,
  setRemoveFile,
  uploadedFile,
  name = 'file',
  multiple = false,
  uploadedFileComponent: UploadedFileComponent,
}) => {
  const dispatch = useDispatch();

  const { fileData: templateFile = {} } = useSelector(({ assets }) => assets);

  const [fileName, setFileName] = useState('');
  const [uploadedDiv, setUploadedDiv] = useState(false);
  const [uploadedItem, setUploadedItem] = useState();

  const onDrop = useCallback((acceptedFiles, rejectedFiles, e) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      toastError('File size exceeded');
      return;
    }
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    disabled: uploadingFile,
    maxFiles: multiple ? 10 : 1,
    accept: acceptedFile,
    maxSize: 5 * 1024 * 1024,
    onDrop,
  });

  useEffect(() => {
    if (Object.keys(templateFile).length || acceptedFiles.length === 1) {
      const file = Object.keys(templateFile).length ? templateFile : acceptedFiles[0];

      const handleFileChange = async (file) => {
        setUploadedItem({ file });

        if (setRemoveFile) setRemoveFile(false);
        setFileName(file.name);
        onChange({ file, fileSize: file.size, fileName: file.name });
        setUploadedItem({ file, fileSize: file.size, fileName: file.name });
        setUploadedDiv(true);
      };

      handleFileChange(file);
    }
  }, [acceptedFiles, templateFile]);

  const handleRemove = () => {
    setFileName('');
    onChange({
      fileName: '',
      fileType: '',
      key: '',
      originalFilename: '',
      signedUrl: '',
      url: '',
      assetCode: '',
      fileSize: '',
    });
    setUploadedItem({
      fileName: '',
      fileType: '',
      key: '',
      originalFilename: '',
      signedUrl: '',
      url: '',
      assetCode: '',
      fileSize: '',
    });

    setUploadedDiv(false);

    if (Object.keys(templateFile).length) dispatch(uploadFileData({}));
  };

  useEffect(() => {
    if (uploadedFile || Object.keys(templateFile).length) {
      setUploadedDiv(true);
    } else {
      setUploadedDiv(false);
    }
  }, [uploadedFile]);

  useEffect(() => {
    if (removeFile) handleRemove();
  }, [removeFile]);

  const UploadedFileComponents = ({
    isFileUploading,
    uploadedFileName,
    handleRemove,
    fileSize,
  }) => {
    return (
      <div className="py-3 px-3 rounded-4 d-flex justify-content-between uploaded-file-container">
        {isFileUploading ? (
          <div className="d-flex">
            <Loading color="#D28B28" size={20} />
            <div className="ms-3 placeholder-loading">
              <div className="mb-2"></div>
              <div className="mb-4"></div>
            </div>
          </div>
        ) : (
          <>
            <div className="d-flex">
              <XlxCSV />
              <div className="ms-3">
                <p className="mb-1 p-0 m-0 uploaded-file-container-name">
                  {uploadedFileName}
                </p>
                {fileSize && (
                  <p className="uploaded-file-container-details gap-1 d-flex align-items-center p-0 m-0">
                    {Math.round(fileSize / 1024)} KB •{' '}
                    <span className="d-flex align-items-center gap-1">
                      <StatusIcon />
                      Completed
                    </span>{' '}
                  </p>
                )}
              </div>
            </div>
            <div onClick={() => handleRemove()}>
              <TrashIcon />
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div
      className={cs('fileUpload groupWrapper cursor', { [wrapperClass]: wrapperClass })}
    >
      <div>
        <label className="uploadFileLabel">{label}</label>
        {!uploadedDiv && (
          <div>
            <div
              className="upload-container suploadFileBorder w-100"
              onClick={uploadingFile ? checkIsDisabled : null}
            >
              <div {...getRootProps({ className: 'dropzone w-100' })}>
                <input {...getInputProps()} id={name} name={name} />
                <div className="uploadFileIcon">
                  <CloudArrowUp />
                </div>
                <div className="uploadFileTextBorder">
                  <div className="text-sm fw-medium text-black">
                    Choose a file or drag & drop it here.
                  </div>
                  <div className="text-xs">{supportType}</div>

                  <button className="btn border xs">Browse file</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {uploadedDiv && (
        <>
          {UploadedFileComponent ? (
            <UploadedFileComponent
              isFileUploading={isUploading}
              uploadedFileName={fileName}
              handleRemove={handleRemove}
            />
          ) : (
            <UploadedFileComponents
              isFileUploading={isUploading}
              uploadedFileName={fileName}
              handleRemove={handleRemove}
              fileSize={uploadedItem?.fileSize}
            />
          )}
        </>
      )}
    </div>
  );
};
export default ClientFileUpload;
