import { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import TableLoading from './TableLoading';
import { Skeleton } from 'antd';

const ProfileTabs = ({ informationBasedOnUserType }) => {
  const dispatch = useDispatch();

  const [key, setKey] = useState('transactions');
  const [loadedTabs, setLoadedTabs] = useState(['transactions']);

  const handleSwitch = (key) => {
    const uniqueKeys = new Set([...loadedTabs, key]);

    setLoadedTabs(Array.from(uniqueKeys));
    setKey(key);
  };

  return (
    <>
      {informationBasedOnUserType.pageLoading ? (
        <>
          <Skeleton.Input
            active
            block={true}
            size={54}
            style={{ borderRadius: '10px', width: '100%', marginBottom: '10px' }}
          />
          <TableLoading />
        </>
      ) : (
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={handleSwitch}
          className="mb-2 profile-nav-bar rounded py-2 px-2"
        >
          {informationBasedOnUserType?.tabList?.map((option, index) => {
            return (
              <Tab
                key={index}
                eventKey={option.key}
                title={<div className="d-flex">{option.name}</div>}
                tabClassName="profile-new-tab"
              >
                {loadedTabs.includes(option.key) && <>{option.component}</>}
              </Tab>
            );
          })}
        </Tabs>
      )}
    </>
  );
};
export default ProfileTabs;
