import { Axios } from 'api/axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getSimplifiedError } from 'utils/error';

import { toastError } from 'components/UI/toast';
import {
  FETCH_BUDGET_REPORT_ERROR,
  FETCH_BUDGET_REPORT_REQUEST,
  FETCH_BUDGET_REPORT_SUCCESS,
  FETCH_CARD_REPORT_ERROR,
  FETCH_CARD_REPORT_REQUEST,
  FETCH_CARD_REPORT_SUCCESS,
  FETCH_CASH_FLOW_REPORT_ERROR,
  FETCH_CASH_FLOW_REPORT_REQUEST,
  FETCH_CASH_FLOW_REPORT_SUCCESS,
  FETCH_CATEGORY_REPORT_ERROR,
  FETCH_CATEGORY_REPORT_REQUEST,
  FETCH_CATEGORY_REPORT_SUCCESS,
  FETCH_SPENDER_REPORT_ERROR,
  FETCH_SPENDER_REPORT_REQUEST,
  FETCH_SPENDER_REPORT_SUCCESS,
  FETCH_TEAM_REPORT_ERROR,
  FETCH_TEAM_REPORT_REQUEST,
  FETCH_TEAM_REPORT_SUCCESS,
  FETCH_VENDOR_REPORT_ERROR,
  FETCH_VENDOR_REPORT_REQUEST,
  FETCH_VENDOR_REPORT_SUCCESS,
} from '../reducers/ReportsReducer';

async function getCategoryReports({ params }) {
  return await Axios.get(`reports/categories`, { params });
}

function* handleGetCategoriesReports({ payload }) {
  try {
    const response = yield call(getCategoryReports, payload);
    if (response) {
      yield put({ type: FETCH_CATEGORY_REPORT_SUCCESS, data: response.data });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_CATEGORY_REPORT_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getBudgetsReports({ params }) {
  return await Axios.get(`reports/budgets`, { params });
}

function* handleGetBudgetsReports({ payload }) {
  try {
    const response = yield call(getBudgetsReports, payload);
    if (response) {
      yield put({ type: FETCH_BUDGET_REPORT_SUCCESS, data: response.data });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_BUDGET_REPORT_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getSpendersReport({ params }) {
  return await Axios.get(`reports/spenders`, { params });
}

function* handleGetSpendersReports({ payload }) {
  try {
    const response = yield call(getSpendersReport, payload);
    if (response) {
      yield put({ type: FETCH_SPENDER_REPORT_SUCCESS, data: response.data });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_SPENDER_REPORT_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getVendorsReports({ params }) {
  return await Axios.get(`reports/vendors`, { params });
}

function* handleGetVendorsReports({ payload }) {
  try {
    const response = yield call(getVendorsReports, payload);
    if (response) {
      yield put({ type: FETCH_VENDOR_REPORT_SUCCESS, data: response.data });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_VENDOR_REPORT_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getTeamsReports({ params }) {
  return await Axios.get(`reports/teams`, { params });
}

function* handleGetTeamsReports({ payload }) {
  try {
    const response = yield call(getTeamsReports, payload);
    if (response) {
      yield put({ type: FETCH_TEAM_REPORT_SUCCESS, data: response.data });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_TEAM_REPORT_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getCardsReports({ params }) {
  return await Axios.get(`reports/cards`, { params });
}

function* handleGetCardsReports({ payload }) {
  try {
    const response = yield call(getCardsReports, payload);
    if (response) {
      yield put({ type: FETCH_CARD_REPORT_SUCCESS, data: response.data });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_CARD_REPORT_ERROR,
      error: getSimplifiedError(error),
    });
  }
}
async function getCashFlowReports({ params }) {
  return await Axios.get(`reports/cash-flow`, { params });
}

function* handleGetCashFlowReports({ payload }) {
  try {
    const response = yield call(getCashFlowReports, payload);
    if (response) {
      yield put({ type: FETCH_CASH_FLOW_REPORT_SUCCESS, data: response.data });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_CASH_FLOW_REPORT_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(FETCH_CATEGORY_REPORT_REQUEST, handleGetCategoriesReports),
  takeLatest(FETCH_BUDGET_REPORT_REQUEST, handleGetBudgetsReports),
  takeLatest(FETCH_VENDOR_REPORT_REQUEST, handleGetVendorsReports),
  takeLatest(FETCH_CARD_REPORT_REQUEST, handleGetCardsReports),
  takeLatest(FETCH_TEAM_REPORT_REQUEST, handleGetTeamsReports),
  takeLatest(FETCH_CASH_FLOW_REPORT_REQUEST, handleGetCashFlowReports),
  takeLatest(FETCH_SPENDER_REPORT_REQUEST, handleGetSpendersReports),
]);
