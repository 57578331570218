import { SearchOutlined } from '@ant-design/icons';
import NoData from 'components/NoData';
import Loading from 'components/UI/Loading';
import { AntInput } from 'components/UIHooks/AntDesign';
import { useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import CashEmptyStateData from '../CashEmptyStateData';
import { AccountCard, CurrencyBalance } from '../components/AccountCard';

const BujetiAccounts = ({
  openBalance,
  openStatementModal,
  openDetailsModal,
  type = 'accounts',
}) => {
  const [searchVal, setSearchVal] = useState('');
  const dispatch = useDispatch();

  let {
    getBalances: { data: balances, loading },
  } = useSelector(({ budgets }) => budgets);

  const showAccounts =
    !!balances ||
    !!balances?.balances.some(({ accountType, subaccount }) => {
      if (type === 'subaccounts')
        return !['linked', 'direct-debit'].includes(accountType) && subaccount;
      return !['linked', 'direct-debit'].includes(accountType);
    });

  const filteredAccount = useMemo(
    () =>
      balances?.balances
        .filter(({ accountType, subaccount }) => {
          if (type === 'subaccounts')
            return !['linked', 'direct-debit'].includes(accountType) && subaccount;
          return !['linked', 'direct-debit'].includes(accountType);
        })
        .filter(
          (item) =>
            item.name.toLowerCase().includes(searchVal.toLowerCase()) ||
            item.accountName.toLowerCase().includes(searchVal.toLowerCase()) ||
            item.bankName.toLowerCase().includes(searchVal.toLowerCase()),
        ),
    [searchVal, balances?.balances],
  );

  if (loading) return <Loading isPage color="#D28B28" />;
  return (
    <div>
      <div style={{ margin: '32px 0px 24px 0px', lineHeight: '30px' }}>
        <div className="d-flex" style={{ overflowX: 'auto' }}>
          <CurrencyBalance balance={balances?.summary || {}} />
        </div>
      </div>

      <div className="py-3 me-2 border-bottom bg-white d-flex gap-3 flex-wrap align-items-center justify-content-between">
        <div className="fw-semibold text-capitalize">{type}</div>
        <div className="search-input">
          <AntInput
            className="input"
            placeholder="Search"
            prefix={<SearchOutlined className="searchIcon" />}
            value={searchVal}
            onChange={({ target: { value } }) => setSearchVal(value)}
          />
        </div>
      </div>

      {showAccounts && (
        <Row className="px-2 pt-4">
          {filteredAccount?.map(
            (
              {
                name,
                balance,
                number,
                currency,
                code,
                bankName,
                accountName,
                routingNumber,
                logo,
                subaccount,
                mandateCode,
                mandateStatus,
              },
              index,
            ) => {
              return (
                <AccountCard
                  key={`account-${index}`}
                  code={code}
                  openBalance={openBalance}
                  openDetailsModal={(event) =>
                    openDetailsModal(event, {
                      name,
                      balance,
                      number,
                      currency,
                      code,
                      bankName,
                      accountName,
                      routingNumber,
                    })
                  }
                  openStatementModal={(event) =>
                    openStatementModal(event, { code, name, accountName })
                  }
                  name={name}
                  balance={balance}
                  number={number}
                  currency={currency}
                  bankName={bankName}
                  accountName={accountName}
                  logo={logo}
                  isSubaccount={subaccount}
                  isDirectDebit={!!mandateCode}
                  isDirectDebitActive={mandateStatus === 'granted'}
                />
              );
            },
          )}
        </Row>
      )}

      {!showAccounts ? (
        <CashEmptyStateData
          toggleHandler={() => {
            toastSuccess('Coming soon'); /*openNewAccountModal*/
          }}
        />
      ) : !filteredAccount?.length ? (
        <div className="tabinnerWrapper">
          <NoData
            headerText="You have no account for this filter"
            bodyText="Alter filter to see accounts"
            withButton={false}
          />
        </div>
      ) : null}
    </div>
  );
};

export default BujetiAccounts;
