import CustomInput from 'components/UI/CustomInput';
import CustomTextarea from 'components/UI/CustomTextarea';
import React, { useEffect } from 'react';
import { Row } from 'react-bootstrap';

const RequestInfo = ({ inviteDirectors, setInviteDirectors, editDirectorInfo }) => {
  const handleRequestInfo = ({ target: { name, value } }) => {
    setInviteDirectors({ ...inviteDirectors, [name]: value });
  };

  useEffect(() => {
    if (editDirectorInfo) {
      const { name, code, email, note } = editDirectorInfo;
      setInviteDirectors({ name, email, note, code });
    }

    return () => {
      setInviteDirectors({ name: '', email: '', note: '', code: undefined });
    };
  }, [editDirectorInfo]);

  return (
    <>
      <Row>
        <CustomInput
          label="Name *"
          placeholder="Enter name"
          type="text"
          name="name"
          onChange={handleRequestInfo}
          value={inviteDirectors.name}
        />
      </Row>
      <Row className="mb-3">
        <CustomInput
          label="Email address *"
          placeholder="Enter email"
          type="text"
          name="email"
          onChange={handleRequestInfo}
          value={inviteDirectors.email}
        />
      </Row>
      <Row className="mb-3">
        <CustomTextarea
          label="Add a personalised message (optional)"
          placeholder="Enter message"
          rowSize={3}
          name="note"
          onChange={handleRequestInfo}
          value={inviteDirectors.note}
        />
      </Row>
    </>
  );
};

export default RequestInfo;
