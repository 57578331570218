import classNames from 'classnames';
import DateRangeFilter from 'components/DateRangeFilter';
import LineStatus from 'components/LineStatus';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { numFormatter } from 'utils/utility';
import {
  buildChartDataFromTransaction,
  getCurrency,
  getPercentage,
  percentageValue,
} from '../../../utils/helper';
import LineChartView from '../../Dashboard/Overview/components/LineChartView';
import TeamsModal from '../TeamsModal';
import './styles.scss';

const TeamOverview = ({ team = {}, stats = {} }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [selectedRange, setSelectedRange] = useState('Custom date range');

  const handleSelectRange = (title, date) => () => {
    setSelectedRange(title);
  };
  if (!team) return <div />;
  const { currency = 'NGN', available = 0, spent = 0, total = 0 } = team;

  // const disbursed = getTotalAmount(budget?.beneficiaries, 'amount');
  // const available = amount - disbursed;
  const { chart = {} } = stats;

  const { permissions } = allPermissions();
  const canManage = hasPermission({
    permissions,
    scopes: ['team-*', 'team-create', 'team-edit'],
  });

  const toggleHandler = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Container className="px-0 py-1">
      <div className="pb-4 pt-2">
        {canManage && (
          <Col xs={6} md={3} lg={2} className="budget-overview-amount">
            <p>
              <span />
              Team goal
            </p>
            <div className="d-flex budget-goal">
              <h6 className="goal-amount">
                <CurrencyFormat
                  prefix={getCurrency(currency)}
                  value={numFormatter(total / 100)}
                  displayType="text"
                  thousandSeparator={true}
                  className="text-dark"
                  isNumericString
                />
              </h6>

              <div className="cursor" onClick={toggleHandler}>
                Edit
              </div>
            </div>
          </Col>
        )}
      </div>

      <Row className={classNames({ ['pt-2']: !canManage })}>
        <Col xs={12}>
          <LineStatus
            active={percentageValue(total, spent)}
            pending={percentageValue(available, spent)}
          />
        </Col>
      </Row>

      <Row className="pt-4 border-bottom">
        {canManage && (
          <Col xs={6} md={3} lg={2} className="budget-overview-amount mb-4">
            <p>
              <span className="dots active-line disbursed-dot" />
              <p>Total budget</p>
            </p>
            <h2 className="px-3">
              <CurrencyFormat
                prefix={getCurrency(currency)}
                value={numFormatter(total / 100)}
                displayType="text"
                thousandSeparator={true}
                isNumericString
              />
            </h2>
          </Col>
        )}

        <Col xs={6} md={3} lg={2} className="budget-overview-amount mb-4">
          <p>
            <span className="dots active-line" />
            Spent
          </p>
          <h2 className="px-3">
            <CurrencyFormat
              prefix={getCurrency(currency)}
              value={numFormatter(spent / 100)}
              displayType="text"
              thousandSeparator={true}
              isNumericString
            />
          </h2>
        </Col>

        <Col xs={6} md={3} lg={2} className="budget-overview-amount mb-4">
          <p>
            <span className="dots available-dot" />
            Available
          </p>
          <h2 className="px-3">
            <CurrencyFormat
              prefix={getCurrency(currency)}
              value={numFormatter(available / 100)}
              displayType="text"
              thousandSeparator={true}
              className="text-dark"
              isNumericString
            />
          </h2>
        </Col>
      </Row>

      <Col lg={12} className="chartSide">
        <Row>
          <Col xs={12} className="p-0">
            <LineChartView
              chartWrapperClass="border-top-0 border-end-0  border-start-0 border-bottom-0 "
              amountType="Total spent"
              chart={buildChartDataFromTransaction(chart)}
              totalSpent={`${spent}`}
              currency={currency}
              totalPercentage={getPercentage(total, spent)}
              widthFilter={
                <DateRangeFilter
                  handleSelectRange={handleSelectRange}
                  selectedRange={selectedRange}
                  zIndex="99999"
                />
              }
            />
          </Col>
        </Row>
      </Col>

      <TeamsModal
        selectTeam={team}
        isOpen={isOpen}
        handleClose={toggleHandler}
        Update
        singleTeam
      />
    </Container>
  );
};
export default TeamOverview;
