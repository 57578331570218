import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import { toastError } from 'components/UI/toast';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fundCreateAPI } from 'redux/actions/CompaniesAction';
import '../styles.scss';
const DebitCreditCard = ({
  setStep,
  setSelectStep,
  setActiveBankCard,
  setIsOtherPopoverOpen,
}) => {
  const [cardInfo, setCardInfo] = useState({
    amount: '',
  });
  const {
    fundCreate: { loading, success },
  } = useSelector(({ companies }) => companies);
  const {
    user: { data = {} },
  } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const [getLocalStorage, setGetLocalStorage] = useState();
  const history = useHistory();
  const handleChangeCard = ({ target: { name, value, rawValue } }) => {
    setCardInfo({ ...cardInfo, [name]: rawValue ? rawValue : value });
  };

  useEffect(() => {
    if (success) {
      setStep(4);
      setSelectStep(4);
      setActiveBankCard(4);
      localStorage.setItem('setStep', JSON.stringify(3));
    }
  }, [success]);

  const handleSubmit = () => {
    if (!cardInfo.amount) return toastError('Enter amount');

    const payload = {
      code: data.user.company.code,
      amount: +cardInfo.amount,
      currency: cardInfo.currency || 'USD',
    };
    dispatch(fundCreateAPI(payload));
  };

  useEffect(() => {
    if (getLocalStorage) {
      setGetLocalStorage({
        ...getLocalStorage,
        firstBudget: {
          ...getLocalStorage.firstBudget,
          ...cardInfo,
        },
      });
    }
  }, [cardInfo]);

  useEffect(() => {
    if (getLocalStorage) {
      localStorage.setItem('overviewInfo', JSON.stringify(getLocalStorage));
    }
  }, [getLocalStorage]);

  useEffect(() => {
    const getLocalData = JSON.parse(localStorage.getItem('overviewInfo'));
    setGetLocalStorage(getLocalData);
    if (getLocalData) {
      if (Object.keys(getLocalData.firstBudget).length > 0) {
        setCardInfo(getLocalData.firstBudget);
      }
    }
  }, []);
  return (
    <div className="information-wrapper">
      <h2 className="card-title w-100">Fund with debit/credit card</h2>
      <div className="form-normal">
        <Row className="mb-3 mt-3">
          <CustomInput
            label="Amount"
            isAmount
            placeholder="0.00"
            type="number"
            name="amount"
            onChange={handleChangeCard}
            value={cardInfo.amount}
            setIsOtherPopoverOpen={setIsOtherPopoverOpen}
            getTypeVal={(val) => setCardInfo({ ...cardInfo, currency: val })}
          />
        </Row>

        <CustomButton
          className="mb-4"
          onClick={handleSubmit}
          fullWidth={true}
          disabled={loading}
        >
          Continue
        </CustomButton>
      </div>
    </div>
  );
};
export default DebitCreditCard;
