import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RolesType } from 'components/FilterModal/FilterHelper';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import { rolesColumn, rolesTableData } from 'utils/mockData';
import './roles.styles.scss';

import { BlockOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import ConfirmDialog from 'components/ConfirmDialog';
import Loading from 'components/UI/Loading';
import PeopleEmptyStateData from 'pages/People/peopleEmptystateData';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { deleteRoleByCode, getRoles } from 'redux/actions/RolesAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { buildRolesData } from '../../utils/helper';
import { PermissionsModal } from './components/PermissionsModal';
import { useDebounce } from 'hooks/useDebounce';

const RolesAndPermission = ({ isOpen, setIsOpen, handleSelect }) => {
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState([...RolesType]);
  const location = useLocation();
  const history = useHistory();
  const [filteredQuery, setFilteredQuery] = useState({});
  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;
  const [isDelete, setIsDelete] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [editPermissions, setEditPermissions] = useState([]);
  const [duplicatePermissions, setDuplicatePermissions] = useState([]);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [search, setSearch] = useState('');
  const debouncedValue = useDebounce(search, 600);

  const { permissions } = allPermissions();
  const canCreateRole = hasPermission({
    permissions,
    scopes: ['role-*', 'role-create'],
  });
  const {
    getRoles: { data: rolesData = [], loading: isFetchingRoles, success: isRolesFetched },
    deleteRole: { loading: isDeleting, success: isDeleted },
  } = useSelector(({ roles }) => roles);

  const handleFilter = (query) => {
    // setFilteredQuery(query);
    isFiltered.current = !!Object.keys(query).length;
  };

  const toggleHandler = () => {
    if (selectedRole) {
      setSelectedRole(null);
    }
    setIsOpen(!isOpen);
    setIsDuplicate(false);
  };

  const onCancel = () => {
    setConfirmationModal(false);
  };

  const onEditClick = (value) => {
    setSelectedRole(value);
    setIsOpen(true);
  };
  const onDuplicateClick = (value) => {
    setSelectedRole(value);
    setIsOpen(true);
    setIsDuplicate(true);
  };

  const onHandleConfirmation = () => {
    setSelectedRole(null);
    setIsOpen(false);
    setConfirmationModal(false);
    setEditPermissions([]);
  };

  const handleEditPermissions = (permissions) => {
    setEditPermissions(permissions);
  };

  const handleDuplicatePermissions = (permissions) => {
    setDuplicatePermissions(permissions);
  };

  useEffect(() => {
    if (isDeleted) {
      setIsDelete(null);
    }
  }, [isDeleted]);

  useEffect(() => {
    if (!isFetchingRoles && !rolesData.length) dispatch(getRoles());
  }, [isFetchingRoles, isRolesFetched, rolesData]);

  const actionHandler = (event, type, value) => {
    event?.stopPropagation();
    event?.preventDefault();
    setIsPopoverOpen(true);
    if (type === 'edit') return onEditClick(value);
    if (type === 'duplicate') return onDuplicateClick(value);
    if (type === 'delete') return setIsDelete(value);
  };

  useEffect(() => {
    if (location.state?.isRole) {
      toggleHandler();
      history.replace({ state: {} });
    }
  }, [location]);

  // Handle search change after debounce
  useEffect(() => {
    if (debouncedValue) {
      filteredQuery.search = debouncedValue;
      dispatch(
        getRoles({
          ...filteredQuery,
          search: debouncedValue,
        }),
      );
    }
    if (!debouncedValue && filtered) {
      delete filteredQuery.search;
      dispatch(getRoles({ ...filteredQuery }));
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  const Actions = ({ list }) => {
    const isdefault = list?.type.toLowerCase() === 'default';
    return (
      <div className="actions-dialog">
        {!isdefault && (
          <div
            className="actionLink "
            onClick={(event) => actionHandler(event, 'edit', list)}
          >
            <EditOutlined style={{ fontSize: '16px', marginLeft: '-5px' }} /> Edit role
          </div>
        )}
        <div
          className="actionLink"
          onClick={(event) => actionHandler(event, 'duplicate', list)}
        >
          <BlockOutlined style={{ fontSize: '16px', marginLeft: '-5px' }} /> Duplicate
          role
        </div>
        {!isdefault && (
          <div
            className="actionLink  text-danger"
            onClick={(event) => actionHandler(event, 'delete', list)}
          >
            <DeleteOutlined style={{ fontSize: '16px', marginLeft: '-5px' }} /> Delete
            role
          </div>
        )}
      </div>
    );
  };

  const onHandleDelete = () => {
    dispatch(deleteRoleByCode(isDelete.code));
  };

  const show = !!rolesData?.length || (filtered && !rolesData?.length);

  if (isFetchingRoles && !filtered) return <Loading isPage color="#d28b28" />;

  return (
    <section className="roles_permissions">
      <TopBar
        showFilter={false}
        // showBarSearch
        // addOnClick={toggleHandler}
        setSearchVal={setSearch}
        withOutSearch
        searchVal={search}
        showBarSearch={show}
        filterData={filterData}
        inputPlaceholder="Search"
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        handleFilterApply={handleFilter}
        withDate
      />
      {!!rolesTableData?.length ? (
        <Container className="px-0 roles-table">
          <Row className="py-4">
            <Col xs={12}>
              <Table
                columns={rolesColumn}
                data={buildRolesData(rolesData)}
                hasMore={false}
                hasCheckBox={false}
                isCustomWidth={true}
                popoverAction={Actions}
                popoverState={isPopoverOpen}
                setPopoverState={setIsPopoverOpen}
                onRowClick={onEditClick}
              />
            </Col>
          </Row>
        </Container>
      ) : (
        <PeopleEmptyStateData
          setIsRolesOpen={toggleHandler}
          handleSelect={handleSelect}
          showAction={canCreateRole}
        />
      )}
      {/* <CreateModal
        isOpen={isOpen}
        toggleHandler={toggleHandler}
        selectedRole={selectedRole}
        showConfirmationModal={() => setConfirmationModal(true)}
        cancelState={cancelState}
      /> */}
      <PermissionsModal
        isOpen={isOpen}
        isDuplicate={isDuplicate}
        toggleHandler={toggleHandler}
        selectedRole={selectedRole}
        showConfirmationModal={() => setConfirmationModal(true)}
        setEditPermissions={handleEditPermissions}
        editPermissionList={editPermissions}
        setDuplicatePermissions={handleDuplicatePermissions}
        duplicatePermissionList={duplicatePermissions}
        confirmationModal={confirmationModal}
      />
      <Modal show={isDelete} centered dialogClassName="custom-dialog">
        <ConfirmDialog
          title={`Delete ${isDelete?.name?.value} Role`}
          subTitle="Are you sure you want to delete this role? This action cannot be undone"
          onConfirm={onHandleDelete}
          loading={isDeleting}
          onCancel={() => setIsDelete(null)}
        />
      </Modal>

      <Modal show={confirmationModal} centered dialogClassName="custom-dialog">
        <ConfirmDialog
          title="You changes aren't saved"
          subTitle="Are you sure you want to cancel this edition? your changes aren't saved yet"
          onConfirm={onHandleConfirmation}
          onCancel={onCancel}
          actionBtnText="Yes"
        />
      </Modal>
    </section>
  );
};

export default RolesAndPermission;
