import TopBar from 'components/TopBar';
import { useEffect, useState } from 'react';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
// import './styles.scss';
import {
  ArrowRight,
  BankNoteO1Icon,
  CalendarPlusIcon,
  LayersTwoIcon,
} from 'assets/icons';
import classNames from 'classnames';
import CustomButton from 'components/UI/CustomButton';
import CustomPopover from 'components/UI/Popover';
import Budgets from 'pages/Budgets';
import Policy from 'pages/Policy';
import Vendor from 'pages/Vendor';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAction } from 'redux/actions/ToggleAction';
import AllowedTo, { allPermissions, hasPermission } from 'utils/AllowedTo';
import { SELECTED_TABLE_ROWS } from 'redux/reducers/TableReducer';

const Expenses = () => {
  const location = useLocation();
  const locationArray = location.pathname.split('/');

  const history = useHistory();
  const dispatch = useDispatch();
  const [key, setKey] = useState(locationArray[locationArray.length - 1]);
  const [isOpen, setIsOpen] = useState(false);
  const [isVendorOpen, setIsVendorOpen] = useState(false);
  const [isPolicyOpen, setIsPolicyOpen] = useState(false);
  const [step, setStep] = useState(1);

  const {
    getBudget: { data: { meta: budgetMeta = {} } = {} },
  } = useSelector(({ budgets }) => budgets);
  const { permissions, onboardingStatus } = allPermissions();
  const canView = hasPermission({
    permissions,
    scopes: [
      'budget-*',
      'vendor-*',
      'policy-*',
      'budget-create',
      'vendor-create',
      'policy-create',
    ],
  });

  const canViewBudget = hasPermission({
    permissions,
    scopes: ['budget-*', 'budget-view', 'budget-create'],
  });

  const canCreateBudget = hasPermission({
    permissions,
    scopes: ['budget-create'],
  });

  const canViewVendor = hasPermission({
    permissions,
    scopes: ['vendor-*', 'vendor-view', 'vendor-create'],
  });

  const canViewPolicy = hasPermission({
    permissions,
    scopes: ['policy-*', 'policy-view', 'policy-create'],
  });

  const pendingOnbording = ['pending', 'rejected'].includes(onboardingStatus);

  const {
    getPolicies: { data: { meta: policyMeta = {} } = {} },
  } = useSelector(({ policies }) => policies);
  const {
    getVendor: { data: { meta: vendorsMeta = {} } = { vendors: [], meta: {} } },
  } = useSelector(({ vendors }) => vendors);

  useEffect(() => {
    if (key === 'expenses') {
      history.push(`/expenses/budgets`);
    }
  }, [key]);

  const handleSelect = (tabKey) => {
    setKey(tabKey);
    if (!tabKey.includes('budgets')) {
      history.push(`/expenses/${tabKey}`);
    } else {
      history.push(`/expenses/budgets`);
    }
  };

  const [isButtonToggle, setIsButtonToggle] = useState(false);

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  const isSchedule = () => {
    localStorage.setItem('schedule', 'on');
  };

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() => {
            dispatch(toggleAction());
            handleButtonToggle();
          }}
        >
          <BankNoteO1Icon /> Single payment
        </div>
        <AllowedTo
          scopes={[
            'batch-transaction-*',
            'batch-transaction-view',
            'batch-transaction-create',
          ]}
        >
          <div
            className="actionLink"
            onClick={() => {
              history.push('/transactions/batch-payment');
            }}
          >
            <LayersTwoIcon /> Batch payments
          </div>
        </AllowedTo>
        <div
          className="actionLink"
          onClick={() => {
            dispatch(toggleAction());
            handleButtonToggle();
            isSchedule();
          }}
        >
          <CalendarPlusIcon /> Schedule payment
        </div>
      </div>
    );
  };
  const toggleActionButton = () => {
    if (key === 'vendors') {
      setIsVendorOpen(!isVendorOpen);
    }
    //set tab to step 1 if key is equal to expenses or budgets
    if (key === 'budgets') {
      setStep(1);
      setIsOpen(!isOpen);
    }

    if (key === 'policies') {
      setIsPolicyOpen(!isPolicyOpen);
    }
  };
  const buttonName =
    key === 'expenses' || key === 'budgets'
      ? 'Create budget'
      : key === 'vendors'
      ? 'Create vendor'
      : 'Create policy';

  const handleRowSelect = (rowData, type) => {
    dispatch({
      type: SELECTED_TABLE_ROWS,
      payload: { selectedRows: rowData, type },
    });
  };

  return (
    <div>
      <TopBar
        headerText="Expenses"
        subText="Access all expense features: budgets, vendors, categories, and spending policies."
        isRightBar
        dropDownBtn={
          <AllowedTo scopes={['transaction-*', 'transaction-create']}>
            <CustomPopover
              zIndex="1"
              content={<Actions />}
              showPopover={isButtonToggle}
              clickOutside={handleButtonToggle}
            >
              <CustomButton className="add-button" onClick={handleButtonToggle}>
                Send funds <ArrowRight color="#ffffff" className="ms-1" />
              </CustomButton>
            </CustomPopover>
          </AllowedTo>
        }
        addButton={canCreateBudget}
        addActionButton={canView && buttonName}
        toggleActionButton={toggleActionButton}
      />

      <Container>
        <Row>
          <Col xs={12} className="mt-4">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={handleSelect}
              className="mb-2"
            >
              {/* Set eventKey to budgets  */}
              {canViewBudget && (
                <Tab
                  eventKey="budgets"
                  title={
                    <div className="d-flex">
                      Budgets
                      <div className="count">
                        <span className="m-auto">{budgetMeta?.total || 0}</span>
                      </div>
                    </div>
                  }
                  tabClassName="new-tab"
                >
                  <Budgets
                    setIsOpen={setIsOpen}
                    isOpen={isOpen}
                    setStep={setStep}
                    step={step}
                    handleSelect={setKey}
                  />
                </Tab>
              )}

              {canViewVendor && (
                <Tab
                  eventKey="vendors"
                  disabled={pendingOnbording}
                  title={
                    <div className="d-flex">
                      Vendors
                      <div className="count">
                        <span className="m-auto">{vendorsMeta?.total || 0}</span>
                      </div>
                    </div>
                  }
                  tabClassName={classNames('new-tab', {
                    ['disabled-tab opacity-50']: pendingOnbording,
                  })}
                >
                  <Vendor
                    setIsOpen={setIsVendorOpen}
                    isOpen={isVendorOpen}
                    handleSelect={setKey}
                    onRowSelect={(data) => {
                      handleRowSelect(data, 'vendors');
                    }}
                  />
                </Tab>
              )}
            </Tabs>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Expenses;
