import classNames from 'classnames';
import AccountName from 'components/UI/AccountName';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import Saving from 'components/UI/ModalSaving/Saving';
import Success from 'components/UI/ModalSaving/Success';
import { toastError } from 'components/UI/toast';
import { useDebounce } from 'hooks/useDebounce';
import { useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addBeneficiaryBank } from 'redux/actions/BeneficiariesAction';
import { getAllBanks, verifyBankAccount } from 'redux/actions/PaymentAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import '../styles.scss';

const BankInfo = ({ setStep, toggleHandler, isLoading }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { permissions, userStatus } = allPermissions();
  const canViewDash = hasPermission({ permissions, scopes: ['org-*', 'dash-view'] });
  const {
    addBeneficiaryBank: { loading },
    getBeneficiaryBank: { data = [] },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const {
    verifyBankAccount: {
      data: accName,
      loading: accountNameLoading,
      success: accountNameSuccess,
      error: accountNameError,
    },
    getAllBanks: {
      data: banksData,
      loading: loadingBanks,
      success: successBanks,
      error: errorBanks,
    },
  } = useSelector(({ payments }) => payments);

  const [bankValue, setBankValue] = useState('');
  const bankValuedebounced = useDebounce(bankValue, 200);

  const handleGetBankOnChange = (val) => setBankValue(val);

  const mappedBanks = banksData?.map((item) => item.name);

  const allBanks = useMemo(() => {
    return banksData?.map((item) => ({
      label: item.label,
      value: item.bankCode,
    }));
  }, [successBanks, errorBanks, mappedBanks]);

  useEffect(() => {
    const banks = allBanks?.find((option) =>
      option?.label?.toLowerCase().includes(bankValuedebounced?.toLowerCase()),
    );
    if (!banks && bankValuedebounced) {
      dispatch(getAllBanks({ search: bankValuedebounced?.toLowerCase() }));
    }
  }, [bankValuedebounced]);

  const onMenuCloseBanks = () => {
    if (bankValuedebounced) dispatch(getAllBanks());
  };

  const getLocalData = JSON.parse(localStorage.getItem('overviewInfo')) || {};
  const [getLocalStorage, setGetLocalStorage] = useState();
  const [kycBank, setKycBank] = useState({
    bankName: '',
    accountName: '',
    accountNumber: '',
  });
  const [isAccountSet, setIsAccountSet] = useState(false);

  useEffect(() => {
    if (!banksData) dispatch(getAllBanks());
    localStorage.setItem('setStep', JSON.stringify(1));
    setStep(2);
  }, []);

  const getUserBank = data.find((item) => item.ownerType === 'user');

  useEffect(() => {
    if (getUserBank) {
      const bankName = allBanks?.find((val) => val.value === getUserBank?.bankCode);
      setKycBank({
        ...kycBank,
        bankName: bankName,
        accountName: getUserBank?.accountName,
        accountNumber: getUserBank?.number,
      });
    }
  }, [getUserBank]);

  const handleSubmit = () => {
    if (!getUserBank?.accountName) {
      if (!kycBank.bankName) return toastError('Please select a bank');
      if (!kycBank.accountName) return toastError('Please enter your account name');
      if (!kycBank.accountNumber) return toastError('Please enter your account number');

      const payload = {
        bankCode: kycBank.bankName.value,
        bankName: kycBank.bankName.label,
        accountName: kycBank.accountName,
        number: kycBank.accountNumber,
      };
      dispatch(addBeneficiaryBank(payload));
    }
  };

  useEffect(() => {
    if (getLocalStorage)
      setGetLocalStorage({
        ...getLocalStorage,
        bankInfo: {
          ...getLocalStorage.bankInfo,
          ...kycBank,
        },
      });
  }, [kycBank]);

  useEffect(() => {
    if (getLocalStorage) {
      localStorage.setItem('overviewInfo', JSON.stringify(getLocalStorage));
    }
  }, [getLocalStorage]);

  useEffect(() => {
    setGetLocalStorage(getLocalData);
    if (getLocalData) {
      if (Object.keys(getLocalData).length > 0) {
        if (Object.keys(getLocalData?.bankInfo).length > 0)
          setKycBank(getLocalData?.bankInfo);
      }
    }
  }, []);
  // ['admin'].includes(role) &&
  const onSkipHandler = () => {
    toggleHandler();
    if (userStatus === 'active')
      if (!canViewDash) {
        history.push('/expenses/budgets');
      } else {
        window.setTimeout(function () {
          location.reload();
        }, 500);
      }
  };

  const handleChange = ({ target: { name, value, validity } }) => {
    if (['accountName', 'accountNumber'].includes(name))
      return validity.valid && setKycBank({ ...kycBank, [name]: value });
    return setKycBank({ ...kycBank, [name]: value });
  };
  const kycSuccess = () => {
    if (loading) {
      return <Saving message="Saving account information, please wait..." />;
    } else if (isLoading && !loading) {
      return <Success title="Success!" message="Your bank account has been saved." />;
    }
  };

  useEffect(() => {
    if (kycBank.accountNumber.length === 10 && kycBank.bankName.value) {
      const { accountNumber, bankName } = kycBank;
      dispatch(verifyBankAccount({ accountNumber, bankCode: bankName.value }));
    }
    if (kycBank.accountNumber.length < 10) {
      setIsAccountSet(false);
      setKycBank({ ...kycBank, accountName: undefined });
    }
  }, [kycBank.accountNumber, kycBank.bankName.value]);

  useEffect(() => {
    if (accountNameSuccess) {
      setKycBank({ ...kycBank, accountName: accName.account_name });
      setIsAccountSet(true);
    }
    if (accountNameError) setKycBank({ ...kycBank, accountName: undefined });
    if (accountNameLoading) setKycBank({ ...kycBank, accountName: undefined });
  }, [accountNameSuccess, accountNameError, accountNameLoading]);

  const visible = accountNameLoading || accountNameError;

  const bankInputs = () => {
    return (
      <div className="bank-wrapper">
        <h2 className="card-title w-100">Bank information</h2>
        <div className="form-normal">
          <Row className="mb-3  mt-3">
            <CustomSelect
              label="Bank"
              name="bankName"
              placeholder="Select Bank"
              onChange={(val) => setKycBank({ ...kycBank, bankName: val })}
              value={kycBank.bankName}
              options={allBanks}
              onMenuClose={onMenuCloseBanks}
              onInputChange={handleGetBankOnChange}
              isDisabled={loadingBanks && !bankValuedebounced}
              isLoading={loadingBanks && !bankValuedebounced}
            />
          </Row>

          <Row className="mb-3">
            <div className={classNames('relative', { ['mb-5']: visible })}>
              <CustomInput
                type="text"
                label="Account number"
                placeholder="Enter number"
                name="accountNumber"
                onChange={handleChange}
                value={kycBank.accountNumber}
                maxLength="10"
                pattern="[0-9]*"
              />
              <AccountName
                setValue={(value) => setKycBank({ ...kycBank, accountName: value })}
                visible={visible}
                accountNameError={accountNameError}
                accountNameLoading={accountNameLoading}
              />
            </div>
          </Row>

          <Row className="mb-5">
            <CustomInput
              type="text"
              label="Account name"
              placeholder="Add account name"
              name="accountName"
              onChange={handleChange}
              value={kycBank.accountName}
              maxLength="50"
              pattern="[a-zA-Z -]*"
              disabled
            />
          </Row>

          <CustomButton
            className="mb-4"
            onClick={handleSubmit}
            fullWidth={true}
            disabled={loading || !isAccountSet}
          >
            Save and continue
          </CustomButton>
          <div className="text-center mt-3 cursor" onClick={onSkipHandler}>
            <span>{"I'll do later"}</span>
          </div>
        </div>
      </div>
    );
  };

  return <>{isLoading ? kycSuccess() : bankInputs()}</>;
};
export default BankInfo;
