import CustomButton from 'components/UI/CustomButton';
import CustomDatePicker from 'components/UI/CustomDatePicker';
import CustomInput from 'components/UI/CustomInput';
import CustomPhoneNumberInput from 'components/UI/CustomPhoneNumberInput';
import CustomSelect from 'components/UI/CustomSelect';
import { toastError } from 'components/UI/toast';
import { addYears, format } from 'date-fns';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { update_user_profile } from 'redux/actions/AuthAction';
import { getState } from 'redux/actions/CompaniesAction';
import { getInternationalFormat, validateNigeriaNumber } from 'utils/helper';
import { ReactComponent as ArrowRight } from '../../../../assets/icons/arrow-right.svg';
import '../styles.scss';

const Informations = ({ setStep, setIsOtherPopoverOpen }) => {
  const dispatch = useDispatch();

  const {
    updatedUser: { loading, success },
    user: { data = {} },
  } = useSelector(({ auth }) => auth);
  const {
    getCountry: { data: countryData = {} },
    getState: { data: states = {} },
  } = useSelector(({ companies }) => companies);

  const {
    getBeneficiaryBank: { data: banks = [] },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const getLocalData = JSON.parse(localStorage.getItem('overviewInfo')) || {};
  const [getLocalStorage, setGetLocalStorage] = useState();
  const [countries, setCountries] = useState([]);
  const [stateData, setStateData] = useState([]);

  const [kycInfo, setKycInfo] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    country: '',
    address: '',
    state: '',
    city: '',
    localFormat: '',
    internationalFormat: '',
    countryCode: '',
  });

  const getUserBank = banks.find((item) => item.ownerType === 'user');

  useEffect(() => {
    if (success) {
      if (!getUserBank?.accountName) {
        localStorage.setItem('setStep', JSON.stringify(1));
        setStep(2);
      } else {
        window.setTimeout(function () {
          location.reload();
        }, 200);
      }
    }
  }, [success]);

  useEffect(() => {
    if (countryData.length > 0) {
      const countryOptions = countryData?.map(({ alpha: value, name: label, code }) => ({
        value,
        label,
        code,
        isDisabled: label !== 'NIGERIA' ? true : false,
      }));

      setCountries(countryOptions);
    }
  }, [countryData]);

  useEffect(() => {
    if (states) {
      const stateOptions = states.states?.map((value) => ({
        label: value,
        value: value,
      }));

      setStateData(stateOptions);
    }
  }, [states]);

  const handleChangeDate = (value) => {
    setKycInfo({ ...kycInfo, dob: format(value, 'yyyy-MM-dd') });
  };

  // dispatch state is value change
  useEffect(() => {
    if (kycInfo?.country?.value && countryData.length > 0) {
      const country = countryData?.find((item) =>
        item.alpha.includes(kycInfo?.country?.value),
      );

      if (country?.code) dispatch(getState(country?.code));
    }
  }, [countryData, kycInfo]);

  // dispatch state if data found
  useEffect(() => {
    if (data?.user?.address?.countryIso && countryData.length > 0) {
      const country = countryData?.find((item) =>
        item.alpha.includes(data?.user?.address?.countryIso),
      );
      if (country?.code) dispatch(getState(country?.code));
    }
  }, [countryData]);

  // Set fields with data from db
  useEffect(() => {
    localStorage.setItem('setStep', JSON.stringify(0));
    setStep(1);
    if (data?.user) {
      setKycInfo({
        ...kycInfo,
        firstName: data?.user?.firstName || '',
        lastName: data?.user?.lastName || '',
        dob: data?.user?.dob || '',
        localFormat: data?.user?.phoneNumber?.localFormat || '',
        countryCode: data?.user?.phoneNumber?.countryCode || '',
        internationalFormat:
          getInternationalFormat(
            data?.user?.phoneNumber?.countryCode,
            data?.user?.phoneNumber?.localFormat,
          ) || '',
        country: data?.user?.address?.country
          ? {
              value: data?.user?.address?.countryIso,
              label: data?.user?.address?.country,
            }
          : '',
        address: data?.user.address?.street || '',
        state: data?.user?.address?.state
          ? {
              value: data?.user?.address?.state,
              label: data?.user?.address?.state,
            }
          : '',
        city: data?.user?.address?.city || '',
      });
    }
  }, [data?.user]);

  useMemo(() => {
    if (data?.user?.phoneNumber?.countryCode)
      setKycInfo({
        ...kycInfo,
        countryCode: `+${data?.user?.phoneNumber?.countryCode}` || '',
      });
  }, [data?.user]);

  const handleChange = ({ target: { name, value } }) => {
    setKycInfo({ ...kycInfo, [name]: value });
  };

  const handlePhoneNumberChange = (localFormat, internationalFormat, countryCode) => {
    setKycInfo({ ...kycInfo, internationalFormat, localFormat, countryCode });
  };
  // Handle form submit
  const handleSubmit = () => {
    if (!kycInfo.firstName) return toastError('Please enter your first name');
    if (!kycInfo.lastName) return toastError('Please enter your last name');
    if (!kycInfo.dob) return toastError('Please enter your date of birth');
    if (
      !isValidPhoneNumber(kycInfo.internationalFormat) ||
      !validateNigeriaNumber(kycInfo.internationalFormat)
    )
      return toastError('Please enter a valid phone number');
    if (!kycInfo.country) return toastError('Please select a country');
    if (!kycInfo.address) return toastError('Please enter an address');
    if (!kycInfo.state) return toastError('Please select a state');
    if (!kycInfo.city) return toastError('Please enter a city');

    const payload = {
      firstName: kycInfo.firstName,
      lastName: kycInfo.lastName,
      dob: kycInfo.dob,
      address: {
        country: kycInfo.country.code,
        countryIso: kycInfo.country?.value,
        street: kycInfo.address,
        state: kycInfo.state.label,
        city: kycInfo.city,
      },
      phoneNumber: {
        countryCode: String(kycInfo.countryCode),
        localFormat: kycInfo.localFormat,
      },
    };
    dispatch(update_user_profile(payload));
  };

  // Persist data in local storage and retrive when modal closes and open
  useEffect(() => {
    if (getLocalStorage)
      setGetLocalStorage({
        ...getLocalStorage,
        kycInfo: {
          ...getLocalStorage.kycInfo,
          ...kycInfo,
        },
      });
  }, [kycInfo]);

  useEffect(() => {
    if (getLocalStorage) {
      localStorage.setItem('overviewInfo', JSON.stringify(getLocalStorage));
    }
  }, [getLocalStorage]);

  useEffect(() => {
    setGetLocalStorage(getLocalData);
    if (getLocalData) {
      if (Object.keys(getLocalData)?.length > 0) {
        if (Object.keys(getLocalData?.kycInfo)?.length > 0)
          setKycInfo(getLocalData?.kycInfo);
      }
    }
  }, []);

  // useMemo(() => {
  //   setGetLocalStorage(getLocalData);
  //   if (getLocalData) {
  //     if (Object.keys(getLocalData).length > 0) {
  //       if (Object.keys(getLocalData.kycInfo).length > 0)
  //         setKycInfo({ ...kycInfo, countryCode: getLocalData.kycInfo.countryCode });
  //     }
  //   }
  // }, []);

  return (
    <div className="information-wrapper">
      <h2 className="card-title w-100">Personal Information</h2>
      <div className="form-normal">
        <Row className="mb-3 mt-3 align-items-center">
          <CustomInput
            label="First Name *"
            name="firstName"
            type="text"
            placeholder="First Name"
            onChange={handleChange}
            value={kycInfo.firstName}
            maxLength="20"
            md={6}
          />
          <CustomInput
            label="Last Name *"
            name="lastName"
            type="text"
            placeholder="Last Name"
            onChange={handleChange}
            value={kycInfo.lastName}
            maxLength="20"
            md={6}
          />
        </Row>

        <Row className="mb-3">
          <CustomDatePicker
            label="Date of Birth *"
            onChange={handleChangeDate}
            peekNextMonth
            maxDate={addYears(new Date(), -18)}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            placeholderText="Date of Birth"
            selected={kycInfo.dob && dayjs(kycInfo.dob).toDate()}
          />
        </Row>

        <Row className="mb-3">
          <CustomPhoneNumberInput
            label="Phone number *"
            placeholder="Enter your phone number"
            onChange={(localFormat, international, countryCode) =>
              handlePhoneNumberChange(localFormat, international, countryCode)
            }
            value={kycInfo.internationalFormat}
          />

          {/* <CustomInput
            label="Phone number *"
            placeholder="Enter phone number"
            type="number"
            name="phoneNumber"
            onChange={handleChange}
            value={kycInfo.phoneNumber}
            existAction={kycInfo.countryCode}
            maxLength="15"
            isNumber
            getTypeVal={getCountryCode}
            setIsOtherPopoverOpen={setIsOtherPopoverOpen}
          /> */}
        </Row>

        <Row className="mb-3">
          <CustomSelect
            label="Country *"
            name="country"
            placeholder="Select country"
            value={kycInfo.country}
            onChange={(val) => {
              setKycInfo({ ...kycInfo, country: val });
            }}
            options={countries}
          />
        </Row>

        <Row className="mb-1">
          <CustomInput
            type="text"
            label="Address *"
            placeholder="Enter Address"
            name="address"
            onChange={handleChange}
            value={kycInfo.address}
            maxLength="50"
          />
          <p className="mt-2 fs-6 text-secondary">
            This address will be used to deliver your physical card.
          </p>
        </Row>

        <Row className="mb-4 align-items-center">
          <CustomSelect
            isDisabled={!kycInfo.country?.label}
            label="State or Region *"
            name="state"
            placeholder="State or Region"
            onChange={(val) => setKycInfo({ ...kycInfo, state: val })}
            value={kycInfo.state}
            options={stateData}
            md={6}
          />
          <CustomInput
            label="City *"
            placeholder="Enter name"
            type="text"
            name="city"
            onChange={handleChange}
            value={kycInfo.city}
            md={6}
          />
        </Row>

        <CustomButton onClick={handleSubmit} fullWidth={true} disabled={loading}>
          Continue <ArrowRight />
        </CustomButton>
      </div>
    </div>
  );
};
export default Informations;
