import React from 'react';
import './index.scss';
import EmptyTaskCard from 'components/UI/EmptyTaskCard';

const OverviewCard = ({
  title,
  children,
  isEmpty,
  placeHolderText = null,
  minHeight = 350,
}) => {
  return (
    <div className="px-3 py-2 border overview-card-view" style={{ minHeight }}>
      {title && (
        <div className="card-title justify-content-between w-100 border-bottom">
          {title}
        </div>
      )}
      <div className="card-list h-100 overflow-x-scroll pt-4">
        {isEmpty ? <EmptyTaskCard text={placeHolderText} /> : children}
      </div>
    </div>
  );
};

export default OverviewCard;
