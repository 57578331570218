import '../styles.scss';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/arrow-left.svg';
import { ReactComponent as OnboardingCompleted } from '../../../assets/icons/onboarding-completed.svg';
import CustomButton from 'components/UI/CustomButton';
import { useSelector } from 'react-redux';
// import * as fs from 'fs';

const OnboardingSaved = ({
  onClick,
  buttonName,
  backButtonName,
  onClickBackButton,
  showBackButton,
  showContinueButton,
}) => {
  return (
    <>
      <div className="d-flex justify-content-center pt-5">
        <div className="text-center" style={{ marginTop: '50%', marginBottom: '40px' }}>
          <OnboardingCompleted />
          <div className="onboardingSavedHeader">Thank you!</div>
          <div className="savingText">
            Your details was submitted successfully and is currenty being reviewed by our
            compliance team.
          </div>
        </div>
      </div>
      <div>
        {showContinueButton && (
          <CustomButton
            onClick={onClick}
            fullWidth
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            className="allSetButton"
          >
            {buttonName}
          </CustomButton>
        )}

        {showBackButton && (
          <CustomButton
            onClick={onClickBackButton}
            fullWidth
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            className="edit-info-btn mt-3"
            withoutBg
          >
            <ArrowLeft className="mr-3" /> {backButtonName}
          </CustomButton>
        )}
      </div>
    </>
  );
};
export default OnboardingSaved;
