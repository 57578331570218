import cs from 'classnames';
import './index.scss';

const RevenueCard = ({
  label,
  value,
  variable = false,
  variation,
  initialValue = null,
}) => {
  return (
    <div className="metrics__wrapper">
      <div className="metrics__wrapper-flex">
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center text-center label">
            <p style={{ marginBottom: 0 }}>{label}</p>
          </div>
          <div className="d-flex text-center">
            <div className="value">
              <h2
                className={cs(
                  { gain: variable && variation >= 0 },
                  { loss: variable && variation < 0 },
                )}
              >
                {value}
              </h2>
            </div>
          </div>

          {/* <div className="d-flex text-center align-items-center" /* style={{ borderTop: '1px solid #dee2e6' }} >

            <span className={cs("arrow-wrapper-percentage active ms-2 text left", { loss: variation < 0 })} style={{ display: "contents", padding: 5 }}>
              {variation > 0 ? <ArrowUp /> : <ArrowDown />} {Math.abs(variation)}%
              {!!initialValue && `from ${initialValue}`}
            </span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default RevenueCard;
