import { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import './approvalSelect.scss';
import { capitalizeFirstLetter } from 'utils/helper';
import Loading from '../Loading';
import { Checkbox } from 'antd';

const ApprovalSelect = ({ users, setApprovers, approvers, loading }) => {
  const approversSet = new Set(approvers);
  const [showSelect, setShowSelect] = useState(false);
  const onHandleToggle = () => {
    setShowSelect(!showSelect);
  };

  const onHandleOptionClick = (code, addToApprovers) => {
    if (addToApprovers) approversSet.add(code);
    else approversSet.delete(code);
    setApprovers([...approversSet]);
  };

  const onHandleSelectAll = (role, addToApprovers) => {
    const roleUsers = users?.filter((user) => user.role === role);
    if (addToApprovers) {
      roleUsers.forEach((user) => approversSet.add(user.code));
    } else {
      roleUsers.forEach((user) => approversSet.delete(user.code));
    }

    setApprovers([...approversSet]);
  };

  const userMap = users?.reduce((objectItem, user) => {
    return { ...objectItem, [user?.code]: user };
  }, {});
  const userGroups = users?.reduce((groups, user) => {
    if (!groups[user?.role]) groups[user?.role] = [];
    groups[user?.role].push(user);
    return groups;
  }, {});
  const titles = Object.keys(userGroups);

  return (
    <OutsideClickHandler onOutsideClick={() => setShowSelect(false)}>
      <section className="approval-wrapper">
        <div className="selectInput-wrapper" onClick={loading ? null : onHandleToggle}>
          {approvers.length === 0 && <span style={{ color: '#BDBDBD' }}>approvers</span>}
          {loading ? (
            <Loading size={12} color="#D28B28" />
          ) : (
            <div className="selectedApproval-wrapper">
              {approvers?.map((code) => {
                return (
                  <span key={code}>
                    {`${userMap[code]?.firstName} ${userMap[code]?.lastName}`}
                    <svg
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => onHandleOptionClick(code, false)}
                    >
                      <path
                        d="M7 1L1 7M1 1L7 7"
                        stroke="#9DA4AE"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                );
              })}
            </div>
          )}
          <svg
            width="14"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="dropdownIcon"
          >
            <path
              d="M1 1L7 7L13 1"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        {showSelect && (
          <section className="select">
            {Object.entries(userGroups).length === 0 ? (
              <div className="text-center py-2 px-1 no-option-container">No options</div>
            ) : (
              <>
                {Object.entries(userGroups).map(([title, groupUsers]) => {
                  const allSelected = groupUsers.every((user) => user.isSelected);
                  return (
                    <div
                      className="option-wrapper manager"
                      key={title}
                      style={{ marginTop: 10 }}
                    >
                      <header className="d-flex align-items-center justify-content-between">
                        <h1>{capitalizeFirstLetter(title)}</h1>
                        <span onClick={() => onHandleSelectAll(title, !allSelected)}>
                          {allSelected ? 'Unselect all' : 'Select all'}
                        </span>
                      </header>

                      {groupUsers.map((user, index) => {
                        const isSelected = approversSet.has(user.code);
                        return (
                          <div
                            className="options"
                            key={user.code}
                            onClick={() => onHandleOptionClick(user.code, !isSelected)}
                          >
                            <Checkbox
                              name="manager"
                              value={user.name || `${user?.firstName} ${user?.lastName}`}
                              checked={isSelected}
                              onChange={() => onHandleOptionClick(user.code, !isSelected)}
                            ></Checkbox>
                            <span>{`${user?.firstName} ${user?.lastName}`}</span>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </>
            )}
          </section>
        )}
      </section>
    </OutsideClickHandler>
  );
};

export default ApprovalSelect;
