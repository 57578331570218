import { Checkbox } from 'antd';
import signImage from 'assets/images/sign-image.png';
import { AuthFormHeader, AuthFormWrapper } from 'components/Auth';
import VitrineSidebar from 'components/Auth/LeftVitrine';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import { toastError } from 'components/UI/toast';
import React, { useEffect } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { login } from 'redux/actions/AuthAction';
import {
  encryptAndSaveToLocalStorage,
  retrieveAndDecryptFromLocalStorage,
} from 'utils/utility';
import '../style.less';

const Login = () => {
  const leftImage = (
    <>
      <img src={signImage} className="login-vitrine-img" />
    </>
  );
  const history = useHistory();
  const location = useLocation();

  const {
    login: { error = {}, success, loading },
  } = useSelector(({ auth }) => auth);

  useEffect(() => {
    const token = retrieveAndDecryptFromLocalStorage('app-session');

    const searchParams = new URLSearchParams(location.search);
    const redirect = searchParams.get('redirect');
    if (redirect) {
      if (token) {
        history.push(redirect);
      } else {
        retrieveAndDecryptFromLocalStorage('redirectPath', redirect);
      }
    }
  }, [location.search]);

  useEffect(() => {
    if (error) {
      if (error === 'Please verify your email/phone number') {
        history.push({
          pathname: '/verification',
          state: { authData: authData.email },
        });
      }
    }
    if (success) {
      history.push({
        pathname: '/verification',
        state: { authData: authData.email, action: 'login' },
      });
    }
  }, [error, success]);

  const dispatch = useDispatch();
  const [authData, setAuthData] = React.useState({
    email: '',
    password: '',
    rememberMe: false,
  });

  const handleChange = ({ target: { name, value } }) => {
    setAuthData({ ...authData, [name]: value });

    if (name === 'rememberMe') encryptAndSaveToLocalStorage('exp-auth-recall', !value);
  };

  const rememberMe = retrieveAndDecryptFromLocalStorage('exp-auth-recall') === false;
  const refreshToken = retrieveAndDecryptFromLocalStorage('auth-recall');

  useEffect(() => {
    setAuthData({
      ...authData,
      rememberMe,
    });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!authData.email) return toastError('Please enter your email');
    if (!authData.password) return toastError('Please enter a password');
    if (authData.rememberMe || rememberMe) {
      if (refreshToken) {
        dispatch(login({ ...authData, rememberMe, refreshToken }));
      } else {
        dispatch(login(authData));
      }
      return;
    }

    delete authData.rememberMe;
    return dispatch(login(authData));
  };

  return (
    <div className="page-wrapper">
      <div className="vitrinesb-col">
        <VitrineSidebar
          message="Take charge of your business finances with integrated financial tools — seamless expense management and corporate banking all in one."
          imageComponent={leftImage}
          widthLink="You’re only a few clicks away."
        />
      </div>

      <div className="page-content with-vit bg ">
        <h4 className="secondary-action ms-auto">
          <span className="font-weight-normal"> No account yet?</span>
          <Link to="/register"> Sign Up </Link>
        </h4>
        <AuthFormWrapper>
          <AuthFormHeader titlle="Log in to your account" />
          <Form>
            <Row className="mb-3">
              <CustomInput
                label="Your work email"
                error="enter valid email"
                placeholder="Enter email"
                type="email"
                name="email"
                onChange={handleChange}
                value={authData.email}
                required
              />
            </Row>
            <Row className="mb-3">
              <CustomInput
                label="Enter your password"
                error="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                placeholder="Enter password"
                type="password"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                value={authData.password}
                onChange={handleChange}
                name="password"
                maxLength={70}
                required
              />
            </Row>

            <div className="d-flex align-items-start gap-2 w-100 my-3 ">
              <Checkbox
                onChange={(event) =>
                  handleChange({
                    target: { name: 'rememberMe', value: event.target.checked },
                  })
                }
                checked={authData?.rememberMe}
              ></Checkbox>
              <div className="d-flex flex-column w-100 fs-6">Keep me logged in</div>
            </div>
            <CustomButton
              type="submit"
              onClick={handleSubmit}
              loading={loading}
              disabled={loading}
            >
              Sign in
            </CustomButton>
          </Form>

          <h4 className="secondary-action">
            <span className="font-weight-normal"> Forgot your password?</span>
            <Link to="/reset-request"> Request reset </Link>
          </h4>
        </AuthFormWrapper>
      </div>
    </div>
  );
};

export default Login;
