import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { ReactComponent as Checked } from '../../../assets/icons/onboarding/green-check.svg';
import './style.less';
import IconWrap from 'components/UI/IconWrap';

const TodoItems = ({ menus, handleSelect }) => {
  const handleClick = (item) => {
    handleSelect(item);
  };
  const {
    getCompany: { loading, data: companyData = {} },
  } = useSelector(({ companies }) => companies);
  const { isAdmin, userStatus, onboardingStatus, permissions } = allPermissions();
  const { user } = useSelector(({ auth }) => auth);
  const canViewDash = hasPermission({
    permissions,
    scopes: ['dash-*', 'dash-view', 'dash-edit'],
  });
  const {
    getBeneficiaries: { data: beneficiariesData = {} },
    getBeneficiaryBank: { data: getbankData = [] },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const {
    getBudget: { data: budgetData = {} },
  } = useSelector(({ budgets }) => budgets);

  const kybcompleted =
    companyData?.dateOfRegistration &&
    companyData?.director?.phoneNumber?.localFormat &&
    companyData?.director?.dob;

  const beneficiarycompleted = beneficiariesData?.beneficiaries?.length > 0;
  const budgetCompleted = budgetData?.budgets?.length > 0;
  const getUserBank = getbankData.find((item) => item.ownerType === 'user');

  return (
    <div className="todo__container">
      <div className="py-1 border-bottom">
        <h3>Get Started</h3>
        <p>
          Ready to get going? Just follow these easy steps or dive right in and explore
          the features
        </p>
      </div>
      <div>
        {menus.map((item, index) => {
          const { icon, title, bntName, desc } = item;
          const isDisabled =
            index === 3 ||
            index === 4 ||
            loading ||
            (index > 0 && !companyData?.address && isAdmin);

          const checkedAdmin =
            isAdmin &&
            ['pending', 'rejected'].includes(onboardingStatus) &&
            userStatus === 'active' &&
            ((index === 0 && kybcompleted) ||
              (index === 1 && beneficiarycompleted) ||
              (index === 2 && budgetCompleted));

          const checkedBeneficiary =
            (!isAdmin ||
              (canViewDash && !['pending', 'rejected'].includes(onboardingStatus))) &&
            ((index === 0 && user?.data?.user?.dob) ||
              (index === 1 && getUserBank?.accountName));

          return (
            <div
              key={index}
              className={classNames(
                'd-flex align-items-center w-100 py-4',
                index === menus.length - 1 ? '' : 'border-bottom',
              )}
            >
              <div>
                <IconWrap icon={icon} />
              </div>

              <div>
                <h4
                  style={{ color: '#1C1917' }}
                  className={classNames('ms-3', {
                    ['text-secondary']: checkedAdmin || checkedBeneficiary,
                  })}
                >
                  {title}
                </h4>
                {desc && (
                  <div
                    className={classNames('ms-3 sub-text', {
                      ['text-secondary']: checkedAdmin || checkedBeneficiary,
                    })}
                  >
                    {desc}
                  </div>
                )}
              </div>

              <div className="ms-auto">
                {checkedAdmin || checkedBeneficiary ? (
                  <Checked />
                ) : !checkedAdmin || !checkedBeneficiary ? (
                  <div
                    className={classNames('add-button add-action h-45 w-100', {
                      ['disabled opacity-50 not-allowed']: isDisabled,
                    })}
                    onClick={() => (isDisabled ? null : handleClick(item))}
                  >
                    {bntName}
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TodoItems;
