import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import AccordionCard from '../../../UI/AccordionCard';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import { Row } from 'react-bootstrap';
import '../styles.scss';
import CustomSelect from 'components/UI/CustomSelect';
import FileUpload from 'components/UI/FileUpload';
import Loading from 'components/UI/Loading';
import { updateCompany } from 'redux/actions/CompaniesAction';
import { useDispatch, useSelector } from 'react-redux';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { states, validateNigeriaNumber } from 'utils/helper';
import CustomPhoneNumberInput from 'components/UI/CustomPhoneNumberInput';
import { toastError } from 'components/UI/toast';

const BankAccount = ({ setActiveCard }) => {
  const [activeKey, setActiveKey] = useState('0');
  const [getLocalStorage, setGetLocalStorage] = useState();
  const {
    user: { data = {} },
  } = useSelector(({ auth }) => auth);

  const {
    updateCompany: { loading, success },
  } = useSelector(({ companies }) => companies);

  const dispatch = useDispatch();
  const handleSelect = (key) => {
    if (activeKey === key) return setActiveKey('999999999');
    setActiveKey(key);
  };

  useEffect(() => {
    if (success) {
      setActiveCard(1);
    }
  }, [success]);
  // const [countryCode, setCountryCode] = useState('+234');

  const [bankInfo, setBankInfo] = React.useState({
    firstName: '',
    lastName: '',
    nationality: '',
    countryCode: '',
    localFormat: '',
    internationalFormat: '',
    bvn: '',
    country: {
      label: '',
      value: '',
    },
    state: '',
    city: '',
    streetAddress: '',
  });

  const [incorporation, setIncorporation] = React.useState({
    idType: { value: 'nin', label: 'National ID Card' },
    id_number: '',
    url: '',
    file: '',
  });

  const handleBankInfo = ({ target: { name, value } }) => {
    setBankInfo({ ...bankInfo, [name]: value });
  };

  const handleChangeIncorporation = ({ target: { name, value } }) => {
    setIncorporation({ ...incorporation, [name]: value });
  };

  const handlePhoneNumberChange = (localFormat, internationalFormat, countryCode) => {
    setBankInfo({ ...bankInfo, internationalFormat, localFormat, countryCode });
  };

  const handleSubmit = () => {
    if (activeKey === '0') {
      if (!bankInfo.firstName) return toastError('Enter first name');
      if (!bankInfo.lastName) return toastError('Enter last name');
      if (!bankInfo.nationality) return toastError('Enter nationality');

      if (
        !isValidPhoneNumber(bankInfo.internationalFormat) ||
        !validateNigeriaNumber(bankInfo.internationalFormat)
      )
        return toastError('Please enter a valid phone number');
      if (!bankInfo.bvn) return toastError('Enter a BVN');
      if (!bankInfo.country) {
        return toastError('Select a country');
      }
      if (!bankInfo.state) {
        return toastError('Select state');
      }
      if (!bankInfo.streetAddress) {
        return toastError('Enter street address');
      }
      return setActiveKey('1');
    } else {
      if (!incorporation.idType.value) return toastError('Select ID type');
      if (!incorporation.id_number)
        return toastError(`Enter ${incorporation.idType.value} number`);
      if (!incorporation.url)
        return toastError(`Upload your ${incorporation.idType.label} file`);
      const payload = {
        code: data.user.company.code,
        director: {
          firstName: bankInfo.firstName,
          lastName: bankInfo.lastName,
          nationality: bankInfo.nationality.value,
          bvn: bankInfo.bvn,
          address: {
            state: bankInfo.state?.label,
            street: bankInfo.streetAddress,
            city: bankInfo.city,
            country: bankInfo.country?.label,
            countryIso: bankInfo.country?.value,
          },
          phoneNumber: {
            countryCode: bankInfo.countryCode,
            localFormat: bankInfo.localFormat,
          },
          document: {
            type: incorporation.idType.value,
            number: incorporation.id_number,
            url: incorporation.url,
          },
        },
      };
      dispatch(updateCompany(payload));
    }
  };

  useEffect(() => {
    if (getLocalStorage) {
      setGetLocalStorage({
        ...getLocalStorage,
        fundBalance: {
          ...getLocalStorage.fundBalance,
          bankInfo: {
            ...bankInfo,
          },
          incorporation: {
            ...incorporation,
          },
        },
      });
    }
  }, [bankInfo, incorporation]);

  useEffect(() => {
    if (getLocalStorage) {
      localStorage.setItem('overviewInfo', JSON.stringify(getLocalStorage));
    }
  }, [getLocalStorage]);

  useEffect(() => {
    const getLocalData = JSON.parse(localStorage.getItem('overviewInfo'));
    setGetLocalStorage(getLocalData);
    if (getLocalData) {
      if (Object.keys(getLocalData.fundBalance).length > 0) {
        if (Object.keys(getLocalData.fundBalance.bankInfo).length > 0)
          setBankInfo(getLocalData.fundBalance.bankInfo);
        if (Object.keys(getLocalData.fundBalance.incorporation).length > 0)
          setIncorporation(getLocalData.fundBalance.incorporation);
      }
    }
  }, []);
  // const getCountryCode = (data) => {
  //   const code = data.substring(1);
  //   setCountryCode(`+${code}`);
  // };
  return (
    <div className="information-wrapper">
      <h2 className="card-title w-100">Get a bank account</h2>
      {loading ? (
        <div className="d-flex justify-content-center pt-5">
          <div className="text-center">
            <Loading />
            <p className="pt-5 fw-bold">Generating a bank account, please wait...</p>
          </div>
        </div>
      ) : (
        <div className="form-normal">
          <Accordion defaultActiveKey="0" activeKey={activeKey}>
            <AccordionCard
              eventKey="0"
              activeKey={activeKey}
              title="Director information"
              handleClick={handleSelect}
            >
              <Row>
                <CustomInput
                  label="First name"
                  placeholder="Enter name"
                  type="text"
                  name="firstName"
                  onChange={handleBankInfo}
                  md={6}
                  value={bankInfo.firstName}
                />
                <CustomInput
                  label="Last name"
                  placeholder="Enter name"
                  type="text"
                  name="lastName"
                  onChange={handleBankInfo}
                  value={bankInfo.lastName}
                  md={6}
                />
              </Row>
              <Row className="mb-3">
                <CustomSelect
                  label="Nationality"
                  name="nationality"
                  onChange={(nationality) =>
                    setBankInfo({ ...bankInfo, nationality: nationality })
                  }
                  value={bankInfo.nationality}
                  options={[
                    { value: 'Nigeria', label: 'Nigeria' },
                    { value: 'USA', label: 'USA' },
                    { value: 'UK', label: 'United Kingdom' },
                    { value: 'France', label: 'France' },
                    { value: 'Germany', label: 'Germany' },
                    { value: 'Benin', label: 'Bénin' },
                    { value: 'Senegal', label: 'Sénégal' },
                  ]}
                />
              </Row>
              <Row className="mb-3">
                <CustomPhoneNumberInput
                  label="Phone number"
                  placeholder="Enter your phone number"
                  onChange={(localFormat, international, countryCode) =>
                    handlePhoneNumberChange(localFormat, international, countryCode)
                  }
                  value={bankInfo.internationalFormat}
                />

                {/* <CustomInput
                  label="Phone number"
                  placeholder="Enter phone number"
                  type="number"
                  name="phoneNumber"
                  onChange={handleBankInfo}
                  value={bankInfo.phoneNumber}
                  existAction={countryCode}
                  isNumber
                  getTypeVal={getCountryCode}
                  setIsOtherPopoverOpen={setIsOtherPopoverOpen}
                /> */}
              </Row>
              <Row className="mb-3">
                <CustomInput
                  label="BVN"
                  placeholder="Enter BVN"
                  type="text"
                  name="bvn"
                  onChange={handleBankInfo}
                  value={bankInfo.bvn}
                />
              </Row>

              <Row className="mb-3">
                <CustomSelect
                  label="Country"
                  name="country"
                  placeholder="Select country"
                  onChange={(val) => setBankInfo({ ...bankInfo, country: val })}
                  value={bankInfo.country}
                  options={[{ value: 'NGA', label: 'Nigeria' }]}
                />
              </Row>
              <Row className="mb-3 align-items-center">
                <CustomSelect
                  isDisabled={!bankInfo.country?.label}
                  label="State or Region"
                  name="state"
                  placeholder="State or Region"
                  onChange={(val) => setBankInfo({ ...bankInfo, state: val })}
                  value={bankInfo.state}
                  options={states}
                  md={6}
                />
                <CustomInput
                  label="City"
                  placeholder="Enter name"
                  type="text"
                  name="city"
                  onChange={handleBankInfo}
                  value={bankInfo.city}
                  md={6}
                />
              </Row>

              <Row className="mb-3">
                <CustomInput
                  label="Street address"
                  placeholder="Enter address"
                  type="text"
                  name="streetAddress"
                  onChange={handleBankInfo}
                  value={bankInfo.streetAddress}
                />
              </Row>
            </AccordionCard>

            {/******************** ID Verification ***************/}
            <AccordionCard
              eventKey="1"
              activeKey={activeKey}
              title="ID Verification"
              handleClick={handleSelect}
            >
              <Row className="mb-3 mt-3">
                <CustomSelect
                  label="Select ID type"
                  name="idType"
                  placeholder="Select"
                  onChange={(val) => setIncorporation({ ...incorporation, idType: val })}
                  value={incorporation.idType}
                  options={[
                    { value: 'nin', label: 'National ID Card' },
                    { value: 'passport', label: 'International Passeport' },
                    { value: 'vid', label: 'Voter ID' },
                    { value: 'driverlicense', label: 'Driver License' },
                  ]}
                />
              </Row>

              <Row className="mb-3">
                <CustomInput
                  type="text"
                  label="ID number"
                  placeholder="Enter number"
                  name="id_number"
                  onChange={handleChangeIncorporation}
                  value={incorporation.id_number}
                />
              </Row>
              <Row className="mb-3">
                <FileUpload
                  label="Upload identification document cursor"
                  placeholder={'Upload (PDF, JPEG, PNG)'}
                  name="file"
                  onChange={({ url }) => setIncorporation({ ...incorporation, url })}
                />
              </Row>
            </AccordionCard>
          </Accordion>

          <CustomButton className="mb-4" onClick={handleSubmit} fullWidth={true}>
            Continue
          </CustomButton>
        </div>
      )}
    </div>
  );
};
export default BankAccount;
