import { CloseOutlined } from '@ant-design/icons';
import CustomButton from 'components/UI/CustomButton';
import CustomCreatableSelect from 'components/UI/CustomCreatableSelect';
import CustomDatePicker from 'components/UI/CustomDatePicker';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import CategoryDropdown from 'components/UI/CustomSelect/CategoryDropdown';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import CustomTextarea from 'components/UI/CustomTextarea';
import FileUpload from 'components/UI/FileUpload';
import Loading from 'components/UI/Loading';
import Modal from 'components/UI/Modal';
import LowBalanceUi from 'components/UI/low-balance-ui';
import { toastError } from 'components/UI/toast';
import { setHours, setMinutes } from 'date-fns';
import { useDebounce } from 'hooks/useDebounce';
import useTextCounter from 'hooks/useTextCounter';
import { useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { getCountries, getState } from 'redux/actions/CompaniesAction';
import { getAllBanks, verifyBankAccount } from 'redux/actions/PaymentAction';
import {
  createReimbursements,
  getReimbursements,
} from 'redux/actions/ReimbursementsAction';
import { RESET_BLOCK_REIMBURSEMENTS } from 'redux/reducers/ReimbursementsReducer';
import { MAXLENGTH } from 'utils';
import { getAvailableBalance, groupSourceOptions } from 'utils/helper';
import { getBalances, getBudgets } from '../../../redux/actions/BudgetsAction';
import { createVendors, getVendors } from '../../../redux/actions/VendorsAction';

const MAX_LENGTH = MAXLENGTH.reimbursementDescription;

const AddReimbursement = ({
  isOpen,
  toggleHandler,
  setIsOtherPopoverOpen = () => null,
  setAddBankDetails,
}) => {
  if (!isOpen) return <div />;

  const dispatch = useDispatch();
  const { push } = useHistory();

  const {
    getVendor: { loading, data: vendorData = {} },
    createVendor: { success: successVendors, loading: loadingCreateVendor },
    updateVendor: { success: successUpdateVendor, loading: loadingUpdateVendor },
  } = useSelector(({ vendors }) => vendors);

  const {
    getBeneficiaryBank: { data: beneficiaryBankData = {} },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const { vendors = [] } = vendorData;

  const {
    createReimbursement: {
      loading: loadingCreateReimbursement,
      success: successCreateReimbursement,
    },
  } = useSelector(({ reimbursement }) => reimbursement);

  const {
    verifyBankAccount: {
      data: accName,
      loading: accountNameLoading,
      success: accountNameSuccess,
      error: accountNameError,
    },
    getAllBanks: {
      data: banksData,
      loading: loadingBanks,
      success: successBanks,
      error: errorBanks,
    },
  } = useSelector(({ payments }) => payments);

  useEffect(() => {
    if (successCreateReimbursement) {
      if (beneficiaryBankData.length > 0) {
        toggleHandler();
        dispatch(getReimbursements());
      } else {
        setAddBankDetails(true);
      }
    }
  }, [successCreateReimbursement]);

  const [uploadingFile, setUploadingFile] = useState(true);
  const [hasAccount, setHasAccount] = useState(false);
  const [addNewVendor, setAddNewVendor] = useState(false);
  const [isAccountSet, setIsAccountSet] = useState(false);
  const [vendorDetails, setVendorDetails] = useState({
    name: '',
    city: '',
    country: '',
    state: '',
    street: '',
    accountNumber: '',
    accountName: '',
    bankName: '',
    currency: 'NGN',
  });

  const [getVendorCode, setGetVendorCode] = useState(false);
  const [reimbursmentDetails, setReimbursmentDetails] = useState({
    amount: '',
    currency: '',
    description: '',
    budget: '',
    date: setHours(setMinutes(new Date(), 30), 16),
    receipt: undefined,
    vendor: '',
  });

  const handleDescriptionChange = (value) => {
    setReimbursmentDetails({
      ...reimbursmentDetails,
      description: value,
    });
  };

  const { text, charCount, handleCharChange } = useTextCounter(
    reimbursmentDetails?.description,
    MAX_LENGTH,
    handleDescriptionChange,
  );

  const [countries, setCountries] = useState([]);

  const {
    getCountry: { data: countryData = {} },
    getState: { data: states = {} },
    getCompany: { data: companyData = {} },
  } = useSelector(({ companies }) => companies);

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  const [stateData, setStateData] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      setVendorDetails({
        ...vendorDetails,
        country: countries.find(
          (country) => country.label === companyData?.address?.country,
        ),
      });
    }, 1000);
    const countryDetails = countries.find(
      (country) => country.label === companyData?.address?.country,
    );
    if (countryDetails) {
      dispatch(getState(countryDetails.value));
    }
  }, []);

  useEffect(() => {
    if (states) {
      const stateOptions = states.states?.map((value) => ({
        label: value,
        value: value,
      }));

      setStateData(stateOptions);
    }
  }, [states]);

  const getCountrySates = async (val) => {
    dispatch(getState(val.value));
  };

  useEffect(() => {
    if (budgets.length === 0) dispatch(getBudgets({ status: 'active' }));
  }, []);

  const {
    getBudget: { data: budgetData = {} },
    getBalances: { data: balances, loading: loadingBalances },
  } = useSelector(({ budgets }) => budgets);
  const { budgets = [] } = budgetData;

  useEffect(() => {
    dispatch(getVendors());
    dispatch(getBalances());
    if (!banksData) dispatch(getAllBanks());
  }, []);

  const updateAccount = reimbursmentDetails.vendor && !hasAccount;

  // Get All banks starts here

  const [bankValue, setBankValue] = useState('');
  const bankValuedebounced = useDebounce(bankValue, 200);

  const handleGetBankOnChange = (val) => setBankValue(val);

  const mappedBanks = banksData?.map((item) => item.name);

  const allBanks = useMemo(() => {
    return banksData?.map((item) => ({
      label: item.label,
      value: item.bankCode,
    }));
  }, [successBanks, errorBanks, mappedBanks]);

  useEffect(() => {
    dispatch(getAllBanks());
  }, []);

  useEffect(() => {
    const banks = allBanks?.find((option) =>
      option?.label?.toLowerCase().includes(bankValuedebounced?.toLowerCase()),
    );
    if (!banks && bankValuedebounced) {
      dispatch(getAllBanks({ search: bankValuedebounced?.toLowerCase() }));
    }
  }, [bankValuedebounced]);

  // Get All banks ends here

  //ends here

  const handleClose = () => {
    toggleHandler();
    dispatch({ type: RESET_BLOCK_REIMBURSEMENTS, blockType: 'createReimbursement' });
  };

  const handleGetSign = (currency) => {
    setReimbursmentDetails({ ...reimbursmentDetails, currency: currency });
  };

  useEffect(() => {
    if (countryData.length > 0) {
      const countryOptions = countryData?.map(({ code: value, name: label }) => ({
        value,
        label,
        isDisabled: label !== 'NIGERIA' ? true : false,
      }));

      setCountries(countryOptions);
    }
  }, [countryData]);

  useEffect(() => {
    if (vendors.length > 0) {
      const vendorsOptions = vendors?.map(({ code: value, name: label }) => ({
        value,
        label,
      }));

      const selected = vendorsOptions.find(
        (vendor) => vendor.label === vendorDetails.name,
      );
      if (selected !== undefined) {
        setReimbursmentDetails({ ...reimbursmentDetails, vendor: selected.value });
      }
    }
  }, [vendors]);

  const vendorsList = useMemo(
    () =>
      vendors?.map(({ code: value, name: label, ...rest }) => ({
        value,
        label,
        rest,
      })),
    [vendors],
  );

  useEffect(() => {
    if (successVendors || successUpdateVendor) {
      setAddNewVendor(false);
      setGetVendorCode(true);
      setHasAccount(true);
    }
  }, [successVendors, successUpdateVendor]);

  const handleChange = (newValue, actionMeta) => {
    if (actionMeta.action === 'create-option') {
      setAddNewVendor(true);
      setVendorDetails({
        ...vendorDetails,
        name: newValue.value,
        accountName: undefined,
        accountNumber: '',
        bank: '',
      });
      setReimbursmentDetails((prevPaymentData) => ({
        ...prevPaymentData,
        vendor: '',
      }));

      setHasAccount(false);
    } else {
      if (newValue) {
        const filterBeneficiary = vendors?.find(({ code }) => code === newValue.value);
        if (filterBeneficiary && filterBeneficiary?.bankAccounts?.[0]?.bankCode) {
          const { bankCode, number, accountName } = filterBeneficiary?.bankAccounts[0];
          const [bank] = allBanks?.filter(({ value }) => value === bankCode);
          const { label = '' } = bank || {};
          setReimbursmentDetails((prevPaymentData) => ({
            ...prevPaymentData,
            vendor: newValue.value,
            accountNumber: number,
            accountName,
            bank: { value: bankCode, label },
          }));
          setHasAccount(true);
        } else {
          setReimbursmentDetails((prevPaymentData) => ({
            ...prevPaymentData,
            vendor: newValue.value,
            accountName: undefined,
            accountNumber: '',
            bank: '',
          }));

          setHasAccount(false);
        }
      } else {
        setReimbursmentDetails({ ...reimbursmentDetails, vendor: '' });
      }
      setAddNewVendor(false);
    }
  };

  const handleChangeVendorDetails = ({ target: { name, value, validity, rawValue } }) => {
    if (['accountName', 'accountNumber'].includes(name))
      return validity.valid && setVendorDetails({ ...vendorDetails, [name]: value });
    return setVendorDetails({ ...vendorDetails, [name]: rawValue ? rawValue : value });
  };

  useEffect(() => {
    if (reimbursmentDetails) {
      const { date, amount, vendor, description, currency } = reimbursmentDetails;
      setUploadingFile(date && amount && vendor && description && currency);
    }
  }, [reimbursmentDetails]);

  const createNewVendor = () => {
    if (!vendorDetails.name) return toastError('Please enter name');
    const { country, city, state, street } = vendorDetails;
    const hasAddress = country?.value && city && state?.value && street;

    if (vendorDetails.accountNumber) {
      if (vendorDetails.accountNumber.length < 10)
        return toastError('Account number must be 10 digits');
    }
    const hasAccount =
      vendorDetails.bankName.value &&
      vendorDetails.accountName &&
      vendorDetails.accountNumber;

    const payload = {
      name: vendorDetails.name ? vendorDetails.name : undefined,
      ...(hasAccount && {
        bankAccount: {
          bankName: vendorDetails.bankName.label,
          bankCode: vendorDetails.bankName.value,
          accountName: vendorDetails.accountName,
          number: vendorDetails.accountNumber,
          currency: vendorDetails.currency,
        },
      }),
      ...(hasAddress && {
        address: {
          country: vendorDetails.country.value,
          city: vendorDetails.city,
          stateOrProvince: vendorDetails.state.value,
          street: vendorDetails.street,
        },
      }),
    };

    dispatch(createVendors(payload));
  };

  const handleChangeDate = (value) => {
    setReimbursmentDetails({ ...reimbursmentDetails, date: value });
  };

  const handleChangeReimbursmentDetails = ({ target: { name, value, rawValue } }) => {
    setReimbursmentDetails({
      ...reimbursmentDetails,
      [name]: rawValue ? rawValue : value,
    });
  };

  const isAvailableBalance = reimbursmentDetails.budget?.value?.startsWith('blc_');

  const createReimbursment = () => {
    if (getVendorCode) {
      const vendor = vendorData.vendors.find(
        (vendor) => vendor.name === vendorDetails.name,
      );
      setReimbursmentDetails({ ...reimbursmentDetails, vendor: vendor?.code });
      setGetVendorCode(false);
    }

    if (!reimbursmentDetails.date) return toastError('Please select expense date');
    if (!reimbursmentDetails.amount) return toastError('Please enter amount');
    if (+reimbursmentDetails.amount <= 0)
      return toastError('Please enter a valid amount');
    if (!reimbursmentDetails.currency) return toastError('Please select currency');
    if (!reimbursmentDetails.description)
      return toastError('Please explain what you are asking a reimbursement for');

    const budget = isAvailableBalance ? undefined : reimbursmentDetails.budget.value;

    const payload = {
      amount: reimbursmentDetails.amount * 100,
      currency: reimbursmentDetails.currency,
      description: reimbursmentDetails.description
        ? reimbursmentDetails.description
        : undefined,
      receipt: reimbursmentDetails.receipt,
      source: reimbursmentDetails.budget.value,
      expense_date: reimbursmentDetails.date,
      category: reimbursmentDetails?.category?.value,
    };

    if (reimbursmentDetails.vendor) {
      payload.vendor = reimbursmentDetails.vendor;
    }

    dispatch(createReimbursements(payload));
  };

  //
  const [loadPage, setLoadPage] = useState(0);

  useEffect(() => {
    if (loadPage > 1) dispatch(getBalances({ page: loadPage }));
  }, [loadPage]);

  const [hasMore, setHasMore] = useState(false);
  const [budgetList, setBudgetList] = useState([]);

  useEffect(() => {
    if (balances?.budgets?.length || balances?.balances?.length) {
      const budget = getAvailableBalance(balances?.budgets, 'budgets');
      const available_balance = getAvailableBalance(balances?.balances, 32, true, true);
      setBudgetList((prevOptions) => [...available_balance].concat([...budget]));
    }
  }, [balances?.budgets]);

  async function loadOptions(search, loadedOptions, { page }) {
    setLoadPage(page);

    let filteredOption = budgetList.filter((item) =>
      item?.name?.toLowerCase().includes(search?.toLowerCase()),
    );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }
  const handleRoute = () => {
    push({
      pathname: `/expenses/budgets/${reimbursmentDetails.budget.value}/overview`,
      state: { fund: true },
    });
    toggleHandler();
  };

  useEffect(() => {
    if (vendorDetails.accountNumber.length === 10 && vendorDetails.bankName.value) {
      const { accountNumber, bankName } = vendorDetails;
      dispatch(verifyBankAccount({ accountNumber, bankCode: bankName.value }));
    }
    if (vendorDetails.accountNumber.length < 10) {
      setIsAccountSet(false);
      setVendorDetails({ ...vendorDetails, accountName: undefined });
    }
  }, [vendorDetails.accountNumber, vendorDetails.bankName.value]);

  useEffect(() => {
    if (accountNameSuccess) {
      setVendorDetails({ ...vendorDetails, accountName: accName.account_name });
      setIsAccountSet(true);
    }
    if (accountNameError) setVendorDetails({ ...vendorDetails, accountName: undefined });
    if (accountNameLoading)
      setVendorDetails({ ...vendorDetails, accountName: undefined });
  }, [accountNameSuccess, accountNameError, accountNameLoading]);

  const visible = accountNameLoading || accountNameError;

  const cancelAddVendor = () => {
    setAddNewVendor(false);
    setVendorDetails({
      name: '',
      city: '',
      country: '',
      state: '',
      street: '',
      accountNumber: '',
      accountName: '',
      bankName: '',
      currency: 'NGN',
    });
    setReimbursmentDetails((prev) => ({ ...prev, vendor: '' }));
  };
  return (
    <div>
      <Modal show={isOpen} onClose={toggleHandler}>
        <div className="content">
          <div className="card-modal-header">
            <>
              <div className="d-flex align-items-center cursor" onClick={handleClose}>
                <CloseOutlined />
                <span className="ps-1">Cancel</span>
              </div>
            </>
          </div>

          <div className="card-modal-body">
            <div className="beneficiaries">
              <h2 className="card-title w-100">Claim a reimbursement</h2>

              {loading ? (
                <Loading />
              ) : (
                <>
                  {reimbursmentDetails.budget.amount == 0 && (
                    <LowBalanceUi
                      currency={reimbursmentDetails.currency}
                      amount={reimbursmentDetails.amount}
                      isBalanceLow={true}
                      route={handleRoute}
                    />
                  )}
                  <Row className="mb-3 mt-4">
                    <CustomDatePicker
                      label="Expense date *"
                      onChange={handleChangeDate}
                      selected={reimbursmentDetails.date}
                    />
                  </Row>
                  <Row className="mb-3">
                    <CustomInput
                      label="How much have you spent? *"
                      placeholder="0.00"
                      type="number"
                      isAmount
                      name="amount"
                      onChange={handleChangeReimbursmentDetails}
                      value={reimbursmentDetails.amount}
                      setIsOtherPopoverOpen={setIsOtherPopoverOpen}
                      getTypeVal={handleGetSign}
                    />
                  </Row>
                  <Row className="mb-3">
                    <CategoryDropdown
                      label="Category"
                      placeholder="Select a category"
                      onChange={(value) =>
                        handleChangeReimbursmentDetails({
                          target: { name: 'category', value },
                        })
                      }
                      value={reimbursmentDetails.category}
                      name="category"
                    />
                  </Row>
                  <Row className="mb-3">
                    <CustomTextarea
                      label="Describe the expense *"
                      name="description"
                      placeholder="Enter a description of the expense"
                      onChange={handleCharChange}
                      value={text || reimbursmentDetails?.description}
                      maxLength={MAX_LENGTH}
                      showCounter={true}
                      charCount={charCount}
                    />
                  </Row>
                  <Row className="mb-3">
                    {!addNewVendor && (
                      <CustomCreatableSelect
                        isClearable
                        label="Which vendor?"
                        placeholder="Enter vendor/merchant's name"
                        onChange={handleChange}
                        defaultValue={
                          vendorDetails.name ? { label: vendorDetails.name } : ''
                        }
                        options={vendorsList}
                        noOptionsMessage={'No vendor, you can type and create a new one'}
                      />
                    )}
                    {addNewVendor && (
                      <div className="container margin-top">
                        <div className="card">
                          <div className="card-body">
                            <Row style={{ placeContent: 'center' }}>
                              <h6>
                                {updateAccount
                                  ? 'Update vendor bank account'
                                  : 'Create New Vendor/Merchant'}
                              </h6>
                              <Row className="mb-2">
                                <hr style={{ width: '94%' }} />
                              </Row>
                            </Row>
                            {!updateAccount && (
                              <>
                                <Row className="mb-2">
                                  <CustomInput
                                    label="Vendor or merchant name *"
                                    placeholder="Enter name"
                                    type="text"
                                    name="name"
                                    onChange={handleChangeVendorDetails}
                                    value={vendorDetails.name}
                                    maxLength="100"
                                  />
                                </Row>
                                <Row className="mb-2">
                                  <CustomSelect
                                    label="Country"
                                    name="country"
                                    placeholder="Select country"
                                    // onChange={(val) => setVendorDetails({ ...vendorDetails, country: val })}
                                    onChange={(val) => {
                                      setVendorDetails({
                                        ...vendorDetails,
                                        country: val,
                                      });
                                      getCountrySates(val);
                                    }}
                                    // value={vendorDetails.country}
                                    options={countries}
                                  />
                                </Row>
                                <Row className="mb-2">
                                  <CustomInput
                                    label="Address"
                                    placeholder="Enter street address"
                                    type="text"
                                    name="street"
                                    onChange={handleChangeVendorDetails}
                                    value={vendorDetails.vendorDetails}
                                    maxLength="100"
                                  />
                                </Row>
                                <Row className="mb-2 align-items-center">
                                  <CustomSelect
                                    isDisabled={!vendorDetails.country?.label}
                                    label="State"
                                    name="state"
                                    placeholder="State or Region"
                                    // onChange={handleChangeVendorDetails}
                                    onChange={(val) =>
                                      setVendorDetails({ ...vendorDetails, state: val })
                                    }
                                    // value={vendorDetails.state}
                                    options={stateData}
                                    md={6}
                                  />
                                  <CustomInput
                                    label="City"
                                    placeholder="Enter name"
                                    type="text"
                                    name="city"
                                    onChange={handleChangeVendorDetails}
                                    // onChange={handleChangeBusinessAddress}
                                    // value={vendorDetails.city}
                                    maxLength="50"
                                    md={6}
                                  />
                                </Row>
                              </>
                            )}

                            <div className="w-100 mt-3 batch-footer d-flex gap-2 justify-content-end">
                              <button
                                onClick={cancelAddVendor}
                                className="add-custom add-button button-dimension-fit-content cursor"
                                disabled={loadingCreateVendor || loadingUpdateVendor}
                              >
                                Cancel
                              </button>

                              <CustomButton
                                className="add-button button-dimension-fit-content"
                                onClick={createNewVendor}
                                loading={loadingCreateVendor || loadingUpdateVendor}
                              >
                                Save
                              </CustomButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Row>
                  <Row className="mb-3">
                    <CustomSelectRadio
                      label="Which budget?"
                      name="budget"
                      placeholder="Select a budget"
                      onChange={(val) =>
                        setReimbursmentDetails({ ...reimbursmentDetails, budget: val })
                      }
                      value={reimbursmentDetails.budget}
                      isLoading={loadingBalances}
                      isDisabled={loadingBalances}
                      loadOptions={loadOptions}
                    />
                  </Row>
                  <Row className="mb-5">
                    <FileUpload
                      label="Receipt"
                      placeholder="Upload certificate (PDF, JPEG, PNG)"
                      name="file"
                      setUploadingFile={setUploadingFile}
                      onChange={(value) =>
                        setReimbursmentDetails({
                          ...reimbursmentDetails,
                          receipt: value?.map((item) => item.assetCode),
                        })
                      }
                      multiple
                    />
                  </Row>
                  <div className="modal-footer mt-3">
                    <CustomButton
                      onClick={toggleHandler}
                      disabled={loadingCreateReimbursement}
                      fullWidth={true}
                      className="custom-button ghost-button"
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton
                      fullWidth={true}
                      className="custom-button primary-button"
                      loading={loadingCreateReimbursement}
                      disabled={loadingCreateReimbursement}
                      onClick={createReimbursment}
                    >
                      Continue
                    </CustomButton>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default AddReimbursement;
