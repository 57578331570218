import { ArrowLeftOutlined } from '@ant-design/icons';
import { ReactComponent as VerifyLogo } from 'assets/icons/verifyLogo.svg';
import createVitrine from 'assets/images/create-vitrine.png';
import { AuthFormHeader, AuthFormWrapper } from 'components/Auth';
import VitrineSidebar from 'components/Auth/LeftVitrine';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import { useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { resendVerificationCode, verifyOTP } from 'redux/actions/AuthAction';
import { RESET_BLOCK_AUTH } from 'redux/reducers/AuthReducer';
import { retrieveAndDecryptFromLocalStorage } from 'utils/utility';
import '../style.less';
const VerifyOTP = () => {
  const dispatch = useDispatch();
  const {
    otp: { success, loading },
    loginUser: { data: { user: logedIn } = {} },
  } = useSelector(({ auth }) => auth);
  const { getRoles: { loading: loadingRoles } = {} } = useSelector(({ roles }) => roles);

  const location = useLocation();
  const history = useHistory();
  const hash = retrieveAndDecryptFromLocalStorage('hash');
  const [email, setEmail] = useState('');

  const [redirectPath, setRedirectPath] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const redirect = searchParams.get('redirect');
    if (redirect) {
      setRedirectPath(redirect);
    }
  }, [location.search]);

  const loginAction = location?.state?.action === 'login';

  useEffect(() => {
    if (success) {
      if (!logedIn?.company?.code && logedIn?.role.toLowerCase() === 'admin')
        history.push('/user/edit');
    }
  }, [success]);

  useEffect(() => {
    if (redirectPath) {
      history.push(redirectPath);
    }
  }, [success, redirectPath]);

  useEffect(() => {
    dispatch({ type: RESET_BLOCK_AUTH, blockType: 'login' });
  }, []);

  useEffect(() => {
    if (!email) {
      if (!location.state) history.push('/');
      const updateEmail = location.state?.authData;
      setEmail(
        updateEmail?.replace(
          updateEmail?.substring(updateEmail?.length - 10, updateEmail?.length - 7),
          '••••',
        ),
      );
    }
  }, [location]);

  const [code, setCode] = useState('');
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setCode(value.replace(/\W+/g, ''));
  };

  useEffect(() => {
    if (code.length === 6) handleSubmit();
  }, [code]);

  const handleSubmit = (event) => {
    const rememberMe = retrieveAndDecryptFromLocalStorage('exp-auth-recall') === false;
    if (event) {
      const form = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();
      if (form.checkValidity() === false) {
        return;
      }
    }
    const data = {
      hash,
      code,
    };
    if (rememberMe) {
      dispatch(verifyOTP({ ...data, rememberMe }));
    } else {
      dispatch(verifyOTP(data));
    }

    return false;
  };
  const leftImage = (
    <>
      <img src={createVitrine} className="w-75" />
    </>
  );

  const handleResendCode = () => {
    const updateEmail = location.state?.authData;
    dispatch(resendVerificationCode({ email: updateEmail }));
  };
  return (
    <div className="page-wrapper">
      <div className="vitrinesb-col">
        <VitrineSidebar
          message="Few clicks away from starting your journey of smart spending."
          imageComponent={leftImage}
          widthLink
        />
      </div>

      <div className="page-content with-vit">
        <AuthFormWrapper>
          <VerifyLogo className="verify-logo" />
          <AuthFormHeader
            titlle="Verify your email"
            subtitle={`Please enter the code sent to ${email || 'adewal••••ail.com'}`}
          />
          <Form noValidate validated={false} onSubmit={handleSubmit}>
            <Row className="mb-3">
              <CustomInput
                label="Verification code"
                error="enter valid code"
                placeholder="Enter code"
                value={code}
                required
                name="code"
                onChange={handleChange}
                autoComplete="off"
              />
            </Row>
            <CustomButton
              type="submit"
              disabled={loading || loadingRoles}
              loading={loading || loadingRoles}
            >
              Verify email
            </CustomButton>
          </Form>
          <h4 className="secondary-action">
            <span className="font-weight-normal"> I didn’t get verification email? </span>
            <span className="fw-bold cursor" onClick={handleResendCode}>
              Resend code
            </span>
          </h4>
          <h3 className="back-line">
            <Link to={loginAction ? 'login' : '/register'} className="link-dark">
              <ArrowLeftOutlined /> {loginAction ? 'Back to Login' : 'Back to Sign up'}
            </Link>
          </h3>
        </AuthFormWrapper>
      </div>
    </div>
  );
};

export default VerifyOTP;
