import { CloseOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import AccountName from 'components/UI/AccountName';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import Loading from 'components/UI/Loading';
import Modal from 'components/UI/Modal';
import { useDebounce } from 'hooks/useDebounce';
import { useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addBeneficiaryBank } from 'redux/actions/BeneficiariesAction';
import { getAllBanks, verifyBankAccount } from 'redux/actions/PaymentAction';
import { RESET_BLOCK_REIMBURSEMENTS } from 'redux/reducers/ReimbursementsReducer';

const AddReimbursementBankDetails = ({ isOpen, toggleHandler, setAddBankDetails }) => {
  if (!isOpen) return <div />;

  const dispatch = useDispatch();

  const {
    addBeneficiaryBank: { loading, success },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const [reimbursmentDetails, setReimbursmentDetails] = useState({
    bankName: '',
    accountName: '',
    accountNumber: '',
  });
  const [isAccountSet, setIsAccountSet] = useState(false);

  const {
    verifyBankAccount: {
      data: accName,
      loading: accountNameLoading,
      success: accountNameSuccess,
      error: accountNameError,
    },
    getAllBanks: {
      data: banksData,
      loading: loadingBanks,
      success: successBanks,
      error: errorBanks,
    },
  } = useSelector(({ payments }) => payments);

  const [bankValue, setBankValue] = useState('');
  const bankValuedebounced = useDebounce(bankValue, 200);

  const handleGetBankOnChange = (val) => setBankValue(val);

  const mappedBanks = banksData?.map((item) => item.name);

  const allBanks = useMemo(() => {
    return banksData?.map((item) => ({
      label: item.label,
      value: item.bankCode,
    }));
  }, [successBanks, errorBanks, mappedBanks]);

  useEffect(() => {
    const banks = allBanks?.find((option) =>
      option?.label?.toLowerCase().includes(bankValuedebounced?.toLowerCase()),
    );
    if (!banks && bankValuedebounced) {
      dispatch(getAllBanks({ search: bankValuedebounced?.toLowerCase() }));
    }
  }, [bankValuedebounced]);

  const onMenuCloseBanks = () => {
    if (bankValuedebounced) dispatch(getAllBanks());
  };

  const handleClose = () => {
    toggleHandler();
    setAddBankDetails(false);
    dispatch({ type: RESET_BLOCK_REIMBURSEMENTS, blockType: 'createReimbursement' });
  };

  const handleChangeReimbursmentDetails = ({ target: { name, value, validity } }) => {
    if (['accountName', 'accountNumber'].includes(name))
      return (
        validity.valid &&
        setReimbursmentDetails({ ...reimbursmentDetails, [name]: value })
      );
    return setReimbursmentDetails({ ...reimbursmentDetails, [name]: value });
  };

  const createReimbursment = () => {
    const payload = {
      bankName: reimbursmentDetails.bankName.label,
      bankCode: reimbursmentDetails.bankName.value,
      accountName: reimbursmentDetails.accountName,
      number: reimbursmentDetails.accountNumber,
    };

    dispatch(addBeneficiaryBank(payload));
  };

  useEffect(() => {
    if (success) {
      toggleHandler();
    }
  }, [success]);

  useEffect(() => {
    if (
      reimbursmentDetails.accountNumber.length === 10 &&
      reimbursmentDetails.bankName.value
    ) {
      const { accountNumber, bankName } = reimbursmentDetails;
      dispatch(
        verifyBankAccount({
          accountNumber,
          bankCode: bankName.value,
        }),
      );
    }
    if (reimbursmentDetails.accountNumber.length < 10) {
      setIsAccountSet(false);
      setReimbursmentDetails({ ...reimbursmentDetails, accountName: undefined });
    }
  }, [reimbursmentDetails.accountNumber, reimbursmentDetails.bankName.value]);

  useEffect(() => {
    if (accountNameSuccess) {
      setReimbursmentDetails({
        ...reimbursmentDetails,
        accountName: accName.account_name,
      });
      setIsAccountSet(true);
    }
    if (accountNameError)
      setReimbursmentDetails({ ...reimbursmentDetails, accountName: undefined });
    if (accountNameLoading)
      setReimbursmentDetails({ ...reimbursmentDetails, accountName: undefined });
  }, [accountNameSuccess, accountNameError, accountNameLoading]);

  const visible = accountNameLoading || accountNameError;

  return (
    <div>
      <Modal show={isOpen} onClose={toggleHandler}>
        <div className="content">
          <div className="card-modal-header">
            <>
              <div className="d-flex align-items-center cursor" onClick={handleClose}>
                <CloseOutlined />
                <span className="ps-1">Cancel</span>
              </div>
            </>
          </div>

          <div className="card-modal-body">
            <div className="beneficiaries">
              <h2 className="card-title w-100">Add Bank Information</h2>
              <p className="modal-sub-header">
                {' '}
                Add your banking information to be able to receive your refund once it has
                been validated by your manager
              </p>
              <>
                <Row className="mb-3">
                  <CustomSelect
                    label="Bank name"
                    name="bankName"
                    placeholder="Enter bank name"
                    onChange={(val) =>
                      setReimbursmentDetails({ ...reimbursmentDetails, bankName: val })
                    }
                    // value={vendorDetails.state}
                    options={allBanks}
                    onMenuClose={onMenuCloseBanks}
                    onInputChange={handleGetBankOnChange}
                    isDisabled={loadingBanks && !bankValuedebounced}
                    isLoading={loadingBanks && !bankValuedebounced}
                  />
                </Row>
                <Row className="mb-3">
                  <div className={classNames('relative', { ['mb-5']: visible })}>
                    <CustomInput
                      label="Account number"
                      placeholder="Enter bank account number"
                      type="text"
                      name="accountNumber"
                      onChange={handleChangeReimbursmentDetails}
                      maxLength="10"
                      pattern="[0-9]*"
                    />
                    <AccountName
                      setValue={(value) =>
                        setReimbursmentDetails({
                          ...reimbursmentDetails,
                          accountName: value,
                        })
                      }
                      visible={visible}
                      accountNameError={accountNameError}
                      accountNameLoading={accountNameLoading}
                    />
                  </div>
                </Row>
                <Row className="mb-5">
                  <CustomInput
                    label="Account name"
                    placeholder="Enter bank account name"
                    type="text"
                    name="accountName"
                    value={reimbursmentDetails?.accountName}
                    maxLength="50"
                    pattern="[a-zA-Z -]*"
                    disabled
                  />
                </Row>
                <CustomButton
                  className="mt-3"
                  onClick={createReimbursment}
                  fullWidth={true}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  disabled={loading || !isAccountSet}
                >
                  {`Save and continue `}
                  {loading ? <Loading color="#D28B28" size={30} /> : ''}
                </CustomButton>
              </>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddReimbursementBankDetails;
