import { toastError } from 'components/UI/toast';

const { default: CustomButton } = require('components/UI/CustomButton');
const { default: CustomTextarea } = require('components/UI/CustomTextarea');
const { useState, useEffect } = require('react');
const { Row } = require('react-bootstrap');
const { useDispatch, useSelector } = require('react-redux');
const { reviewRequest } = require('redux/actions/ApprovalAction');

export const DeclineStateComponent = ({ goBack, code, setData }) => {
  const [input, setInput] = useState({ reason: '' });
  const dispatch = useDispatch();
  const onHandleValueChange = (event) => {
    setInput({
      reason: event.target.value,
    });
  };

  const {
    reviewRequest: { loading, success },
  } = useSelector(({ approval }) => approval);

  useEffect(() => {
    if (success) {
      setData(null);
    }
  }, [success]);

  const handleSubmit = () => {
    if (!input.reason) return toastError('Please enter a reason');
    dispatch(reviewRequest({ code, status: 'declined', reason: input.reason }));
  };

  return (
    <section className="information-wrapper decline-request-container">
      <div className="d-flex flex-column justify-content-between">
        <h2 className="color-grey-900 text-ds-xs font-semibold">Reason for decline</h2>
        <Row className="mb-3">
          <CustomTextarea
            row={5}
            name="reason"
            value={input?.reason}
            onChange={onHandleValueChange}
            placeholder="Specify the reason for the decline"
          />
        </Row>
        <CustomButton
          fullWidth
          className="mt-3"
          onClick={handleSubmit}
          disabled={loading}
        >
          Decline
        </CustomButton>
        <CustomButton withoutBg fullWidth className="mt-3" onClick={goBack}>
          Cancel
        </CustomButton>
      </div>
    </section>
  );
};
