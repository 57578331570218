import { DeleteIcon, EditIcon } from 'assets/icons';
import ConfirmDialog from 'components/ConfirmDialog';
import Table from 'components/Table';
import CustomButton from 'components/UI/CustomButton';
import { useEffect, useMemo, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createBatchPayment, getBatchPayment } from 'redux/actions/PaymentAction';
import {
  buildBatchPaymentTableData,
  getCurrency,
  getTotalAmount,
  removeEmptyString,
} from 'utils/helper';
import { BatchColumn } from 'utils/mockData';
import { rowInitialState } from '.';
import { ReactComponent as BankIcon } from '../../assets/icons/gray-bank-icon.svg';
import { calculateFees } from '../../redux/actions/FeesAction';
import BatchModal from './BatchModal';
import BatchPaymentDialog from './BatchPaymentDialog';

const GetRowAction = ({
  rows,
  unSetRows,
  selectAllRow,
  isFile,
  tableData,
  setIsFile,
}) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [checkall, setCheckAll] = useState(true);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const {
    createBatchPayment: { loading, success },
  } = useSelector(({ payments }) => payments);

  const {
    calculateFees: { data: feesData, loading: isFeesLoading },
  } = useSelector(({ fees }) => fees);

  useEffect(() => {
    if (tableData.length && checkall) {
      selectAllRow();
      setCheckAll(false);
    }
  }, []);

  useEffect(() => {
    if (!isFile) {
      setCheckAll(true);
    }
  }, [isFile]);

  const transactions = rows.map((item) => {
    const isAvailableBalance = item.sourceCode?.startsWith('blc_');
    const isAvailableBackAccount = item.sourceCode?.startsWith('bnk_');
    const budgetValue =
      isAvailableBalance || isAvailableBackAccount ? undefined : item.sourceCode;

    return {
      amount: Number((Number(item.unformatedAmount) * 100).toFixed(2)),
      recipient: item.recipientName,
      description: item.description,
      bank_account: {
        ...item.bank_account,
        bankCode: String(item.bank_account.bankCode),
        number: String(item.bank_account.number),
      },
      currency: item.currency,
      category: item.category.value,
      directDebit:
        !budgetValue && isAvailableBackAccount
          ? {
              bankAccount: item.sourceCode,
            }
          : undefined,
      budget: budgetValue,
      balance: !budgetValue && isAvailableBalance ? item.sourceCode : undefined,
    };
  });

  const { batchName = '', currency = 'NGN' } = rows.length && rows[0]?.batchData;

  const payload = {
    name: batchName,
    currency,
    transactions,
  };

  const checkFailed = rows.map((item) => item.error);

  useEffect(() => {
    if (success) {
      setIsSubmit(false);
      push('/transactions/batch');
      dispatch(getBatchPayment());
    }
  }, [success]);

  const handlePay = () => {
    const rewritePayload = removeEmptyString(payload);
    dispatch(createBatchPayment(rewritePayload));
  };
  const calculateFee = {
    currency,
    amount: rows.map(({ unformatedAmount }) =>
      Number((Number(unformatedAmount) * 100).toFixed(2)),
    ),
  };

  const handleSubmit = () => {
    setIsSubmit(!isSubmit);
    dispatch(calculateFees(calculateFee));
  };

  return (
    <>
      <div className="information-wrapper batch-footer position-sticky bottom-0 right-0 bg-black border-top h-auto w-100 bg-white px-5 py-3 d-flex justify-content-between align-items-center">
        {!!rows.length && (
          <div>
            Total amount{' '}
            <CurrencyFormat
              prefix={getCurrency(currency)}
              value={getTotalAmount(rows, 'unformatedAmount').toFixed(2)}
              displayType="text"
              thousandSeparator={true}
            />{' '}
            ({rows.length}){''} selected transfers
          </div>
        )}

        <div className="position-static modal-footer border-0 px-0 ms-auto">
          <CustomButton
            fullWidth={true}
            onClick={() => setIsFile(false)}
            className="custom-button text-nowrap ghost-button"
          >
            Back to edit
          </CustomButton>
          {!!rows.length && (
            <CustomButton
              fullWidth={true}
              onClick={handleSubmit}
              disabled={!!checkFailed.includes(true)}
              className="custom-button primary-button"
            >
              Submit
            </CustomButton>
          )}
        </div>
      </div>

      <Modal
        show={isSubmit}
        centered
        dialogClassName="custom-dialog batch-payment-confirm"
      >
        <BatchPaymentDialog
          disabled={loading || isFeesLoading}
          onClick={handlePay}
          onCancel={() => setIsSubmit(!isSubmit)}
          amount={
            <CurrencyFormat
              prefix={getCurrency(currency)}
              value={getTotalAmount(rows, 'unformatedAmount').toFixed(2)}
              displayType="text"
              thousandSeparator={true}
            />
          }
          description={
            <>
              You are about to proceed a batch payment to{' '}
              <span className="fw-bolder">{rows.length} beneficiaries.</span>
            </>
          }
          fee={
            isFeesLoading ? (
              <p className="spinner-3"></p>
            ) : (
              <CurrencyFormat
                prefix={getCurrency(feesData?.currency)}
                value={(feesData?.fee / 100).toFixed(2)}
                displayType="text"
                thousandSeparator={true}
              />
            )
          }
          icon={<BankIcon />}
          title="Please confirm your transfer."
          total={
            isFeesLoading ? (
              <p className="spinner-3"></p>
            ) : (
              <CurrencyFormat
                prefix={getCurrency(currency)}
                value={(
                  getTotalAmount(rows, 'unformatedAmount') +
                  feesData?.fee / 100
                ).toFixed(2)}
                displayType="text"
                thousandSeparator={true}
              />
            )
          }
        />
      </Modal>
    </>
  );
};

const BatchPayementTable = ({
  tableData,
  setTableData,
  isFile,
  setBatch,
  setRemoveFile,
  isUploaded,
  setIsUploaded,
  formRows,
  setFormRows,
  preview,
  setPreview,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOtherPopoverOpen, setIsOtherPopoverOpen] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [isDelete, setIsDelete] = useState(null);
  const { push } = useHistory();
  const toggleHandler = () => {
    setIsOpen(!isOpen);
  };
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleClose = () => {
    if (!isOtherPopoverOpen) return toggleHandler(), setSelectedBatch(null);
  };

  const handleEditClick = (data) => {
    const updatedRows = tableData.filter((_, index) => index != data.id);
    const selectedRow = tableData.filter((_, index) => index === data.id);
    updatedRows.splice(0, 0, selectedRow[0]);
    setFormRows(updatedRows);
    setIsUploaded(false);
  };

  const handleDeleteClick = () => {
    const newTableData = tableData.filter((_, index) => index !== isDelete.id);
    const newTableRowData = formRows.filter((_, index) => index !== isDelete.id);
    setFormRows(newTableRowData);
    setTableData(newTableData);
    setIsDelete(null);
  };

  useEffect(() => {
    if (preview && !tableData?.length) {
      setPreview(false);
      setFormRows(rowInitialState);
      setIsUploaded(false);
      setRemoveFile(false);
    }
  }, [tableData?.length]);

  const handleDeleteModal = (data) => {
    setIsDelete(data);
  };

  const actionHandler = (event, type, data) => {
    event?.stopPropagation();
    event?.preventDefault();
    if (type === 'edit') handleEditClick(data);
    if (type === 'delete') handleDeleteModal(data);
    setIsPopoverOpen(true);
  };

  const Actions = ({ list: selectedData }) => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={(event) => actionHandler(event, 'edit', selectedData)}
        >
          <EditIcon stroke="#79716B" width={16} height={16} className="mr-4" /> Edit
          transaction
        </div>

        <div
          className="actionLink svg-danger text-danger"
          onClick={(event) => actionHandler(event, 'delete', selectedData)}
        >
          <DeleteIcon className="mr-4" width={15} height={16} /> Delete transaction
        </div>
      </div>
    );
  };

  const tableColumns = useMemo(() => {
    return BatchColumn;
  }, []);

  const tablesData = useMemo(() => {
    return buildBatchPaymentTableData(tableData);
  }, [tableData]);

  return (
    <div className="budgets-wrapper my-5">
      {!!tableData?.length && isUploaded && (
        <>
          {/* <TopBar headerText="Transactions review" /> */}
          <h5 className="mb-3">Transactions review</h5>
          <Container className="p-0">
            <Row>
              <Col xs={12}>
                <Table
                  columns={tableColumns}
                  data={tablesData}
                  popoverAction={Actions}
                  popoverState={isPopoverOpen}
                  setPopoverState={setIsPopoverOpen}
                  onClick={handleEditClick}
                  type="budgets"
                  getSelectedRows={GetRowAction}
                  isFile={isFile}
                  setIsFile={setIsUploaded}
                />
              </Col>
            </Row>
          </Container>
        </>
      )}

      <BatchModal
        isOpen={isOpen}
        IsOtherPopoverOpen={isOtherPopoverOpen}
        setIsOtherPopoverOpen={setIsOtherPopoverOpen}
        handleClose={handleClose}
        selectedbatch={selectedBatch}
        setTableData={setTableData}
        tableData={tableData}
      />

      <Modal show={isDelete} centered dialogClassName="custom-dialog">
        <ConfirmDialog
          title="Delete item from list"
          subTitle="Are you sure you want to delete this item? This action cannot be undone."
          onConfirm={handleDeleteClick}
          // loading={deleteLoading}
          onCancel={() => setIsDelete(null)}
        />
      </Modal>
    </div>
  );
};
export default BatchPayementTable;
