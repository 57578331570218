import { LaptopIcon, TripleLayers } from 'assets/icons';
import pdfFileType from 'assets/images/pdf-filetype-icon.png';
import cs from 'classnames';
import CircularProgressBar from 'components/CircularProgressBar';
import CustomPopover from 'components/CustomPopover';
import format from 'date-fns/format';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { awsUploadNew } from 'utils/FileUploadUtil';
import { truncateMiddle } from 'utils/helper';
import './styles.scss';

const FileUpload = ({
  wrapperClass,
  uploadingFile,
  supportType = 'JPEG, PNG, and PDF formats, up to 10 MB.',
  cloud = true,
  acceptedFile = {
    'image/jpeg': ['.jpeg', '.png', '.pdf'],
  },
  placement = 'left-start',
  multiple = false,
  callbackAction,
  callbackPayload,
  getDocuments,
  addDocument,
}) => {
  const dispatch = useDispatch();

  const {
    [getDocuments]: { data: documents },
    [addDocument]: { error: addDocumentError },
  } = useSelector(({ policies }) => policies);

  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);

  const [isButtonToggle, setIsButtonToggle] = useState(false);
  const [showExistingFiles, setShowExistingFiles] = useState(false);

  const target = useRef(null);

  const handleSelect = async ({ assetCode }) => {
    dispatch(callbackAction({ ...callbackPayload, assetCode }));
  };

  const addExisitingFile = (existingFile) => {
    setUploading(true);
    dispatch(callbackAction({ ...callbackPayload, assetCode: existingFile.code }));
    setProgress(100);
  };

  useEffect(() => {
    if (addDocumentError) {
      setUploading(false);
      setProgress(0);
      setShowExistingFiles(false);
      setIsButtonToggle(false);
    }
  }, [addDocumentError]);

  const onDrop = useCallback((acceptedFiles, rejectedFiles, event) => {
    setIsButtonToggle(false);
    if (rejectedFiles?.length) {
      toastError('File size exceeds 5MB limit');
      return;
    }
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    disabled: uploadingFile,
    maxFiles: multiple ? 3 : 1,
    accept: acceptedFile,
    maxSize: 5 * 1024 * 1024,
    onDrop,
  });

  useEffect(() => {
    if (acceptedFiles.length === 1) {
      const file = acceptedFiles[0];

      const handleFileChange = async (file) => {
        if (cloud) {
          setUploading(true);

          const payload = {
            fileName: file.name,
            fileMime: file.type,
          };

          const upload = await awsUploadNew(
            payload,
            file,
            setProgress,
            setUploading,
            handleSelect,
          );

          if (upload === 'error') {
            setUploading(false);
            handleRemove();
            throw new Error(error.message);
          }
        }
      };

      handleFileChange(file);
    }
  }, [acceptedFiles]);

  const handleButtonToggle = () => {
    setIsButtonToggle(!isButtonToggle);
  };

  const actions2 = (
    <div className="popover-actions-dialog">
      {documents && documents?.length ? (
        documents.map((item) => (
          <div
            key={item.code}
            className="actionLink"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              addExisitingFile(item);
            }}
          >
            <div className="d-flex align-items-start gap-3">
              <img width="30" src={pdfFileType} alt="Document Icon" />
              <div>
                <p style={{ whiteSpace: 'normal' }} className="text-sm mb-1 gray-500">
                  {truncateMiddle(item.name, 33)}
                </p>
                <div className="text-xs">
                  <span>Uploaded {format(new Date(item.created_at), 'd/MM/yyyy')}</span>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="noLink">No documents found</div>
      )}
    </div>
  );

  const actions = (
    <CustomPopover
      zIndex="1"
      content={actions2}
      showPopover={showExistingFiles}
      clickOutside={() => setShowExistingFiles(!showExistingFiles)}
      placement={placement}
    >
      <div className="popover-actions-dialog">
        <div
          className="actionLink"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setShowExistingFiles(!showExistingFiles);
          }}
        >
          <TripleLayers /> Existing files
        </div>

        <div
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
          className="actionLink"
        >
          <div
            {...getRootProps({
              className: 'dropzone w-100',
            })}
          >
            <input {...getInputProps()} id={'file'} name={'file'} />
            <LaptopIcon className="me-1" /> Upload from your computer
          </div>
        </div>
      </div>
    </CustomPopover>
  );

  return (
    <div>
      {!uploading && (
        <>
          <div
            className={cs('fileUpload__table groupWrapper cursor', {
              [wrapperClass]: wrapperClass,
            })}
          >
            <div>
              <CustomPopover
                zIndex="1"
                content={actions}
                showPopover={isButtonToggle}
                clickOutside={handleButtonToggle}
                placement={placement}
              >
                <div
                  className="add-more"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    handleButtonToggle();
                  }}
                >
                  <div>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.9999 7.3335V16.6668M7.33325 12.0002H16.6666"
                        stroke="#A9A29D"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </CustomPopover>
            </div>
          </div>
        </>
      )}

      {uploading && (
        <CircularProgressBar strokeWidth="3" sqSize="30" percentage={progress} />
      )}
    </div>
  );
};
export default FileUpload;
