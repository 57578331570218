import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'hooks/useDebounce';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

import { Modal } from 'react-bootstrap';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import NoData from 'components/NoData';
import Loading from 'components/UI/Loading';
import DeleteDialog from 'components/DeleteDialog';
import CollectionEmptyState from 'pages/Collections/CollectionEmptyState';
import InvoiceDetailModal from './components/InvoiceDetailModal';
import { FullPageLoader } from 'components/UI/Loading';

import { buildInvoiceTableData } from 'utils/helper';
import { recurringInvoiceColumns } from '../../utils/mockData';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { ScheduledInvoiceStatusType } from 'components/FilterModal/FilterHelper';
import {
  fetchScheduledInvoice,
  deleteScheduledInvoice,
  updateScheduledInvoice,
} from '../../redux/actions/InvoiceAction';

import { DeleteOutlined, EyeOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { EditIcon } from 'assets/icons';

const ScheduledInvoices = ({ handleSelect }) => {
  const [search, setSearch] = useState(null);
  const [deleteModal, setDeleteModal] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [filteredQuery, setFilteredQuery] = useState();
  const [invoiceSelected, setInvoiceSelected] = useState();
  const [isInvoiceOpen, setIsInvoiceOpen] = useState(null);
  const [filterData, setFilterData] = useState([
    // { title: 'Amount', type: 'slider' },
    ...ScheduledInvoiceStatusType,
  ]);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { permissions } = allPermissions();

  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;
  const debouncedValue = useDebounce(search, 300);
  const pdfDownloadRef = useRef(null);

  const {
    fetchScheduledInvoice: { loading, success, data: { meta = {} } = {}, data = {} },
    deleteScheduledInvoice: { loading: isDeleting, success: deleteSuccess },
    updateScheduledInvoice: {
      success: updateScheduleSucces,
      loading: updateScheduleLoading,
    },
  } = useSelector(({ invoices }) => invoices);
  const { page, total, hasMore, perPage, nextPage } = meta || {};

  const canViewInvoice = hasPermission({
    permissions,
    scopes: ['invoice-*', 'invoice-view'],
  });

  const canDeleteInvoice = hasPermission({
    permissions,
    scopes: ['invoice-*', 'invoice-view', 'invoice-delete'],
  });

  const rows = buildInvoiceTableData(data?.scheduledInvoices);

  useEffect(() => {
    if (!data?.scheduledInvoices?.length) fetchInvoices();
  }, []);

  useEffect(() => {
    if (success && location?.search) isFiltered.current = true;
  }, [success]);

  useEffect(() => {
    if (deleteSuccess || updateScheduleSucces) {
      setDeleteModal(null);
      dispatch(fetchScheduledInvoice({ ...filteredQuery }));
    }
  }, [deleteSuccess, updateScheduleSucces]);

  useEffect(() => {
    if (debouncedValue && canViewInvoice) {
      dispatch(fetchScheduledInvoice({ ...filteredQuery, search: debouncedValue }));
    }
    if (!debouncedValue && filtered && canViewInvoice) {
      delete filteredQuery.search;
      dispatch(fetchScheduledInvoice({ ...filteredQuery }));
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  const fetchInvoices = () => {
    if (canViewInvoice) {
      dispatch(fetchScheduledInvoice());
    }
  };

  const clearLocationState = () => {
    history.replace({ state: {} });
    fetchInvoices();
  };

  const toggleInvoiceModal = () => {
    setIsInvoiceOpen(!isInvoiceOpen);

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('recurring');
    history.push({ search: searchParams.toString() });
  };

  const handleRowClick = (row) => {
    setInvoiceSelected(row);
    setIsInvoiceOpen(true);
    history.push(`${window.location.pathname}?recurring=true`);
  };

  const onHandleNextPage = (page) => {
    dispatch(fetchScheduledInvoice({ ...filteredQuery, perPage, page }));
    setFilteredQuery({ ...filteredQuery, perPage, page });
  };

  const onHandlePreviousPage = (page) => {
    dispatch(fetchScheduledInvoice({ ...filteredQuery, perPage, page }));
    setFilteredQuery({ ...filteredQuery, perPage, page });
  };

  const onHandleFilter = (query) => {
    const amount = filterData.filter(({ title }) => title === 'amount');
    const [min, max] = amount[0]?.value || [];
    const min_amount = min ? min * 100 : undefined;
    const max_amount = max ? max * 100 : undefined;
    setFilteredQuery({ ...query, min_amount, max_amount });
    dispatch(fetchScheduledInvoice({ ...query, min_amount, max_amount }));
    isFiltered.current = !!Object.keys(query).length;
  };

  const clearFilters = () => {
    setFilteredQuery({});
    // setFilterData([{ title: 'Amount', type: 'slider' }, ...ScheduledInvoiceStatusType]);
    setFilterData([...ScheduledInvoiceStatusType]);
    dispatch(fetchScheduledInvoice());
  };

  const handleDraft = (code) => {
    history.push({
      pathname: '/receivables/invoices/create',
      search: `?isEdit=${code}`,
      state: { isRecurring: true },
    });
  };

  const toggleScheduleStatus = (code, status) => {
    dispatch(updateScheduledInvoice({ status, code }));
  };

  const actionHandler = (event, type, value) => {
    const { code = '' } = value?.details;
    event?.stopPropagation();
    event?.preventDefault();

    if (type === 'edit') return handleDraft(code);
    setIsPopoverOpen(true);

    switch (type) {
      case 'delete':
        return setDeleteModal(code);

      case 'pause':
        return toggleScheduleStatus(code, 'pause');

      case 'resume':
        return toggleScheduleStatus(code, 'active');

      case 'view':
        handleRowClick(value);
    }
  };

  const handleDelete = () => {
    dispatch(deleteScheduledInvoice(deleteModal));
  };

  const Actions = ({ list }) => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink mx-1"
          onClick={(event) => actionHandler(event, 'view', { ...list })}
        >
          <EyeOutlined
            style={{
              fontSize: '16px',
              marginLeft: '-8px',
              marginRight: '-5px',
              color: '#57534E',
            }}
          />{' '}
          View invoice
        </div>

        {/* {!['draft', 'pause'].includes(list.details.status) && (
          <div
            className="actionLink mx-1"
            onClick={(event) => actionHandler(event, 'pause', { ...list })}
          >
            <PauseCircleOutlined
              style={{
                fontSize: '16px',
                marginLeft: '-8px',
                marginRight: '-5px',
                color: '#57534E',
              }}
            />{' '}
            Pause schedule
          </div>
        )} */}

        {['pause'].includes(list.details.status) && (
          <div
            className="actionLink mx-1"
            onClick={(event) => actionHandler(event, 'resume', { ...list })}
          >
            <PlayCircleOutlined
              style={{
                fontSize: '16px',
                marginLeft: '-8px',
                marginRight: '-5px',
                color: '#57534E',
              }}
            />{' '}
            Resume schedule
          </div>
        )}

        <>
          {list.details.status === 'draft' && (
            <div
              className="actionLink mx-1"
              onClick={(event) => actionHandler(event, 'edit', { ...list })}
            >
              <EditIcon
                stroke="#79716B"
                width={16}
                height={16}
                style={{ fontSize: '16px', marginLeft: '-5px', marginRight: '8px' }}
              />{' '}
              Edit invoice
            </div>
          )}
        </>

        {canDeleteInvoice && (
          <div
            className="actionLink text-danger mx-1"
            onClick={(event) => actionHandler(event, 'delete', list)}
          >
            <DeleteOutlined
              style={{ fontSize: '16px', marginLeft: '-8px', marginRight: '-3px' }}
            />{' '}
            Delete invoice
          </div>
        )}
      </div>
    );
  };

  const showBarSearch = !!data?.scheduledInvoices?.length;

  if ((loading && !filtered) || (loading && location?.search))
    return <Loading isPage={true} color="#d28b28" />;

  return (
    <div className="mt-2">
      <TopBar
        showBarSearch={showBarSearch}
        showFilter={showBarSearch}
        searchVal={search}
        setSearchVal={setSearch}
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        filterData={filterData}
        handleFilterApply={onHandleFilter}
        clearFilters={clearFilters}
        inputPlaceholder="search"
        withDate
        dateTitle={'Invoice Date'}
      />

      {!data?.scheduledInvoices?.length && (
        <div>
          {isFiltered.current || location?.search ? (
            <div className="tabinnerWrapper">
              <NoData
                headerText="You have no invoice for this filter"
                bodyText="There are no invoice for this filter, use the button below to show all your available invoices"
                withButton={true}
                buttonLabel={
                  <span className="d-flex justify-content-center">Show all Invoices</span>
                }
                onClickHandler={clearLocationState}
              />
            </div>
          ) : (
            <CollectionEmptyState
              onClickHandler={() =>
                history.push('/receivables/invoices/create?isRecurring=true')
              }
              handleSelect={handleSelect}
            />
          )}
        </div>
      )}

      {!!data?.scheduledInvoices?.length && (
        <Container className="px-0">
          <Row className="pt-4 pb-3">
            <Col xs={12} className="spaced-table">
              <Table
                columns={recurringInvoiceColumns}
                pagination
                data={rows}
                onRowClick={handleRowClick}
                hasCheckBox={false}
                popoverAction={Actions}
                popoverState={isPopoverOpen}
                setPopoverState={setIsPopoverOpen}
                hasMore={hasMore}
                currentPage={page}
                nextPage={() => onHandleNextPage(nextPage)}
                previousPage={() => onHandlePreviousPage(page - 1)}
                totalPage={Math.ceil(total / perPage)}
              />
            </Col>
          </Row>
        </Container>
      )}

      <InvoiceDetailModal
        isOpen={isInvoiceOpen}
        handleClose={toggleInvoiceModal}
        code={invoiceSelected?.details?.code}
        canDeleteInvoice={canDeleteInvoice}
      />

      <Modal show={!!deleteModal} centered dialogClassName="custom-dialog">
        <DeleteDialog
          title="Delete invoice"
          subTitle="Are you sure you want delete this invoice?"
          onCancel={() => setDeleteModal(null)}
          onDelete={handleDelete}
          deleteText="Delete"
          isLoading={isDeleting}
        />
      </Modal>

      {updateScheduleLoading && <FullPageLoader />}
    </div>
  );
};

export default ScheduledInvoices;
