import { BudgetsStatusType, CurrencyType } from 'components/FilterModal/FilterHelper';
import NoData from 'components/NoData/index.js';
import TopBar from 'components/TopBar';
import Loading from 'components/UI/Loading';
import { useDebounce } from 'hooks/useDebounce';
import ExpensesEmptyStateData from 'pages/Expenses/expensesEmptystateData';
import { useEffect, useRef, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getBudgets, resetBlockBudget } from 'redux/actions/BudgetsAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { getQueryParams, updateStatus } from 'utils/utility';
import BudgetModal from './BudgetModal';
import BudgetsCard from './BudgetsCard/index.js';
import PendingOnboardingNotice from './PendingOnboardingNotice';
import './style.less';
const Budgets = ({ setIsOpen, isOpen, setStep, step, handleSelect }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState([...CurrencyType, ...BudgetsStatusType]);
  const { permissions } = allPermissions();
  const canViewBudgets = hasPermission({
    permissions,
    scopes: ['budget-view'],
  });

  const canCreateBudgets = hasPermission({
    permissions,
    scopes: ['budget-*', 'budget-create'],
  });
  const {
    getBudget: { data: { budgets: budgetsCard = [], meta = {} } = {}, loading, success },
  } = useSelector(({ budgets }) => budgets);

  const [isAdvance, setIsAdvance] = useState(false);
  const [search, setSearch] = useState('');
  const debouncedValue = useDebounce(search, 600);
  const [filteredQuery, setFilteredQuery] = useState({});
  const [budgetStatus, setBudgetStatus] = useState('active');
  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;

  const { hasMore, perPage } = meta;

  useEffect(() => {
    if (location.state?.isModal) {
      toggleHandler();
      history.replace({ state: {} });
    }
  }, [location]);

  const toggleHandler = () => {
    setStep(1);
    setIsOpen(!isOpen);
  };
  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const status = budgetStatus === 'active' ? 'active' : ['inactive', 'pause'];

  useEffect(() => {
    if (canViewBudgets && !location?.search) {
      dispatch(getBudgets({ status }));
      if (budgetStatus !== 'active') isFiltered.current = true;
    }
  }, [budgetStatus]);

  useEffect(() => {
    if (location?.search && canViewBudgets) {
      const status = getQueryParams(location?.search, 'status');
      if (status) {
        dispatch(getBudgets({ status }));
        setFilterData(updateStatus(filterData, 'Status', status));
      }
    }
  }, [location]);

  useEffect(() => {
    if (success && location?.search) isFiltered.current = true;
  }, [success]);

  useEffect(() => {
    if (!budgetsCard.length) {
      dispatch(resetBlockBudget({ blockType: 'getSingleBudget' }));
    }

    return () => {
      if ((filtered || location?.search) && canViewBudgets)
        dispatch(getBudgets({ status }));
    };
  }, [filtered]);

  const handleFilter = (query) => {
    dispatch(getBudgets(query));
    setFilteredQuery(query);
    isFiltered.current = !!Object.keys(query).length;
  };

  // Handle search change after debounce
  useEffect(() => {
    if (debouncedValue && canViewBudgets) {
      filteredQuery.search = debouncedValue;
      dispatch(getBudgets({ ...filteredQuery, status, search: debouncedValue }));
    }
    if (!debouncedValue && filtered && canViewBudgets) {
      delete filteredQuery.search;
      dispatch(getBudgets({ status, perPage, ...filteredQuery }));
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  const handleMore = (perPage) => {
    setTimeout(() => {
      if (!loading) {
        dispatch(getBudgets({ perPage, ...filteredQuery, status }));
        isFiltered.current = true;
      }
    }, 1500);
  };

  const budgetList = () => {
    return (
      <>
        {!!budgetsCard.length ? (
          <div className="pt-4 w-100 px-0 mx-0">
            <Container className="px-0 w-100 mx-0">
              <InfiniteScroll
                pageStart={0}
                loadMore={() => handleMore(perPage + 50)}
                hasMore={hasMore}
                className="w-100"
                // loader={<h4></h4>}
              >
                <Row className="budgets-card-wrapper w-100">
                  {budgetsCard.map((budget, index) => (
                    <BudgetsCard budget={budget} key={`budgets-${index}`} />
                  ))}
                </Row>
              </InfiniteScroll>
            </Container>
          </div>
        ) : (
          <div>
            {filtered ? (
              <div className="tabinnerWrapper">
                <NoData
                  headerText="You have no budgets for this filter"
                  bodyText="Alter filter to see budgets"
                  withButton={false}
                />
              </div>
            ) : (
              <>
                {budgetStatus !== 'inactive' ? (
                  <ExpensesEmptyStateData
                    openBudgetModal={toggleHandler}
                    handleSelect={handleSelect}
                    showAction={canCreateBudgets}
                  />
                ) : (
                  <div className="tabinnerWrapper">
                    <NoData
                      headerText="You have no inactive budgets yet"
                      bodyText="Alter filter to see active budgets"
                      withButton={false}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </>
    );
  };

  // if (loading && !filtered) return <Loading isPage color="#D28B28" />;

  const show = !!budgetsCard?.length || (filtered && !budgetsCard?.length);

  return (
    <div className="budgets-wrapper w-100">
      <PendingOnboardingNotice />

      <BudgetModal
        step={step}
        setStep={setStep}
        handleBack={handleBack}
        isOpen={isOpen}
        toggleHandler={toggleHandler}
        setIsAdvance={setIsAdvance}
        isAdvance={isAdvance}
      />

      <TopBar
        // addOnClick={toggleHandler}
        showFilter={show}
        setSearchVal={setSearch}
        searchVal={search}
        filterData={filterData}
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        inputPlaceholder="Search for budgets"
        handleFilterApply={handleFilter}
        withDate
        withOutSearch
        showBarSearch={show}
        exportLoading={true}
        setStatus={setBudgetStatus}
        status={budgetStatus}
        budgetStatus={show}
      />
      {loading && !filtered ? <Loading isPage color="#D28B28" /> : budgetList()}
    </div>
  );
};

export default Budgets;
