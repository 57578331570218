import { CloseOutlined } from '@ant-design/icons';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import Modal from 'components/UI/Modal';
import Saving from 'components/UI/ModalSaving/Saving';
import MultipleSelect from 'components/UI/MultipleSelect';
import { toastError } from 'components/UI/toast';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createTeam, getTeams, updateTeam } from 'redux/actions/TeamsAction';
import { capitalizeFirstLetter } from 'utils/helper';
import './styles.scss';

const teamValue = {
  name: '',
  managers: '',
  members: '',
  budgets: '',
  status: '',
  teamCode: '',
};

const TeamsModal = ({ isOpen, handleClose, selectTeam, Update, singleTeam }) => {
  if (!isOpen) return <div />;
  const dispatch = useDispatch();
  const { teamsId } = useParams();

  const {
    getBudget: { data: budgetData = {} },
  } = useSelector(({ budgets }) => budgets);

  const {
    createTeam: { success, loading },
    updateTeam: { success: updateSuccess, loading: updateLoading },
  } = useSelector(({ teams }) => teams);

  const {
    getBeneficiaries: { data = {} },
  } = useSelector(({ beneficiaries }) => beneficiaries);
  const { beneficiaries = [] } = data;
  const { budgets = [] } = budgetData;

  const [team, setTeam] = useState(teamValue);

  const { managers, members } = team;

  const seletedManagers = managers ? managers?.map((item) => item.value) : [];
  const seletedMembers = members ? members?.map((item) => item.value) : [];

  const activeBeneficiaries = beneficiaries
    .filter(
      ({ status, user }) =>
        ['active', 'invited', 'inactive'].includes(status) &&
        !seletedManagers?.includes(user?.code) &&
        !seletedMembers?.includes(user?.code),
    )
    .map(({ user }) => ({
      label: `${user.firstName} ${user.lastName}`,
      value: user.code,
    }));

  // filter budget on selected currency type
  const filteredBudget = budgets
    .filter(({ status }) => status === 'active')
    .map((budget) => ({
      value: budget.code,
      label: budget.name,
    }));

  const handleChange = ({ name, value }) => {
    setTeam({ ...team, [name]: value });
  };

  // set input state if update = true
  useEffect(() => {
    if (Update) {
      const {
        status,
        name,
        code,
        budgets = [],
        members = [],
        managers = [],
      } = selectTeam;

      if (singleTeam) {
        const filteredMembers = members.filter(({ role }) => role === 'Employee');
        const filteredManagers = members.filter(({ role }) => role === 'Manager');
        // setTeam if on Selected Team page
        setTeam({
          ...team,
          name,
          managers: filteredManagers.map(({ user }) => ({
            label: `${user.firstName} ${user.lastName}`,
            value: user.code,
          })),
          members: filteredMembers.map(({ user }) => ({
            label: `${user.firstName} ${user.lastName}`,
            value: user.code,
          })),
          budgets:
            budgets?.length &&
            budgets?.map(({ name: label, code: value }) => ({ label, value })),
          status: { value: status, label: capitalizeFirstLetter(status) },
          teamCode: code,
        });
      } else {
        // setTeam if on Teams Listing page
        setTeam({
          ...team,
          name,
          managers:
            managers?.length &&
            managers.map(({ user }) => ({
              label: `${user.firstName} ${user.lastName}`,
              value: user.code,
            })),
          members:
            members?.length &&
            members.map(({ user }) => ({
              label: `${user.firstName} ${user.lastName}`,
              value: user.code,
            })),
          budgets:
            budgets?.length &&
            budgets?.map(({ name: label, code: value }) => ({ label, value })),
          status: { value: status, label: capitalizeFirstLetter(status) },
          teamCode: code,
        });
      }
    }
  }, []);

  const handleSubmit = () => {
    if (!team.name) return toastError('Please enter team name');
    if (!team.managers) return toastError('Please select team manager');

    const budgets = team?.budgets?.length
      ? team.budgets.map((item) => item.value)
      : undefined;

    const managers = team?.managers?.length
      ? team.managers.map((item) => item.value)
      : undefined;

    const members = team?.members?.length
      ? team.members.map((item) => item.value)
      : undefined;

    const payload = {
      name: team.name,
      managers,
      members,
      budgets,
      status: Update ? team.status.value : undefined,
      code: Update ? team.teamCode : undefined,
    };

    if (Update) {
      dispatch(
        updateTeam({
          ...payload,
          budgets: budgets || [],
          managers: managers || [],
          members: members || [],
        }),
      );
    } else {
      dispatch(createTeam(payload));
    }
  };

  useEffect(() => {
    if (success || updateSuccess) {
      handleClose();
      dispatch(getTeams());
    }
  }, [success, updateSuccess]);

  const title = Update ? 'Update team' : 'Create team';
  const teamForm = () => (
    <div className="beneficiaries">
      <h2 className="team-title">{title}</h2>

      <>
        <Row className="mb-3 ">
          <CustomInput
            label="Team name *"
            placeholder="Enter team name"
            type="text"
            name="name"
            onChange={({ target: { name, value } }) => handleChange({ name, value })}
            value={team.name}
            maxLength="50"
          />
        </Row>

        <Row className="mb-3">
          <MultipleSelect
            placeholder="Select team manager(s)"
            label="Team manager(s) *"
            name="managers"
            options={activeBeneficiaries}
            onChange={(val) => handleChange({ name: 'managers', value: val })}
            value={team.managers}
          />
        </Row>

        <Row className="mb-3">
          <MultipleSelect
            label="Team member(s)"
            placeholder="Select team members"
            name="members"
            options={activeBeneficiaries}
            onChange={(val) => handleChange({ name: 'members', value: val })}
            value={team.members}
          />
        </Row>

        {Update && (
          <Row className="mb-3">
            <CustomSelect
              label="Status"
              placeholder="Set status"
              name="status"
              options={[
                { value: 'active', label: 'Active' },
                { value: 'pause', label: 'Pause' },
              ]}
              onChange={(val) => handleChange({ name: 'status', value: val })}
              value={team.status}
            />
          </Row>
        )}
        <Row className="mb-3">
          <MultipleSelect
            placeholder="Select budget(s)"
            label="Budgets"
            name="budgets"
            options={filteredBudget}
            onChange={(val) => handleChange({ name: 'budgets', value: val })}
            value={team.budgets}
          />
        </Row>
      </>

      <div className="modal-footer mt-3">
        <CustomButton
          onClick={handleClose}
          fullWidth={true}
          className="custom-button ghost-button"
        >
          Cancel
        </CustomButton>
        <CustomButton
          onClick={handleSubmit}
          fullWidth={true}
          className="custom-button primary-button"
          loading={loading || updateLoading}
          disabled={loading || updateLoading}
        >
          {Update ? 'Save changes' : 'Create team'}
        </CustomButton>
      </div>
    </div>
  );

  const isLoading = loading || updateLoading;

  return (
    <div>
      <Modal show={isOpen} onClose={handleClose}>
        <div className="content">
          <div className="card-modal-header">
            <div className="d-flex align-items-center cursor" onClick={handleClose}>
              <CloseOutlined />
              <span className="ps-1">Close</span>
            </div>
          </div>
          <div className="card-modal-body">{isLoading ? <Saving /> : teamForm()}</div>
        </div>
      </Modal>
    </div>
  );
};
export default TeamsModal;
