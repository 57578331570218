import ConfirmDialog from 'components/ConfirmDialog';
import { toastSuccess } from 'components/UI/toast';
import { subYears } from 'date-fns';
import VendorModal from 'pages/Vendor/VendorModal';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getProfileStats } from 'redux/actions/MemberProfileAction';
import { getVendorProfile } from 'redux/actions/VendorProfileAction';
import { deleteVendors, vendorInfo } from 'redux/actions/VendorsAction';
import { RESET_BLOCK_MEMBER_PROFILE } from 'redux/reducers/MemberProfileReducer';
import { RESET_BLOCK_TRANSACTIONS } from 'redux/reducers/TransactionsReducer';
import { RESET_BLOCK_VENDOR_PROFILE } from 'redux/reducers/VendorProfileReducer';
import {
  asciiToHex,
  buildVendorsTableData,
  getColor,
  getFormattedDate,
} from 'utils/helper';
import ApproversTable from './Components/ApproversTable';
import ProfileComponent from './Components/ProfileComponent';
import TransactionTable from './Components/TransactionTable';
import dayjs from 'dayjs';
import CreateNewVendor from 'pages/Vendor/CreateNewVendor';
import { PlusIcon } from 'assets/icons';

const VendorProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams();

  const [bankInformation, setBankInformation] = useState([]);
  const [basicInformation, setBasicInformation] = useState([]);
  const [userInformation, setUserInformation] = useState({});

  const [chartData, setChartData] = useState(null);

  const [pageLoading, setPageLoading] = useState(true);
  const [isRequest, setIsRequest] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [vendorData, setVendorData] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [informationBasedOnUserType, setInformationBasedOnUserType] = useState({
    userType: 'vendor',
    tabList: [
      {
        name: 'Transaction',
        key: 'transactions',
      },
      {
        name: 'Approvers',
        key: 'approvers',
      },
    ],
    pageLoading,
  });

  const {
    getVendorProfile: { loading, data: profileData = {} },
  } = useSelector(({ vendorProfile }) => vendorProfile);

  const {
    deleteVendor: { loading: deleteLoading, success: deleteSuccess },
    updateVendor: { success: successUpdateVendors },
  } = useSelector(({ vendors }) => vendors);

  const {
    getProfileStats: { loading: chartLoading, data: profileStats = {} },
  } = useSelector(({ memberProfile }) => memberProfile);

  const today = new Date();
  const lastYearStartDate = subYears(today, 1);
  const lastYearEndDate = today;

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_BLOCK_VENDOR_PROFILE, blockType: 'getVendorProfile' });
      dispatch({ type: RESET_BLOCK_MEMBER_PROFILE, blockType: 'getProfileStats' });
      dispatch({ type: RESET_BLOCK_TRANSACTIONS, blockType: 'getTransaction' });
      dispatch({ type: RESET_BLOCK_VENDOR_PROFILE, blockType: 'getVendorApprovers' });
    };
  }, []);

  useEffect(() => {
    if (deleteSuccess) {
      dispatch(getVendorProfile({ code: id }));
      toastSuccess('Vendor deleted Successfully');
      setIsDelete(null);
      history.push('/expenses/vendors');
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (successUpdateVendors) {
      dispatch(getVendorProfile({ code: id }));
    }
  }, [successUpdateVendors]);

  useEffect(() => {
    setInformationBasedOnUserType({
      userType: 'vendor',
      tabList: [
        {
          name: 'Transaction',
          key: 'transactions',
          component: (
            <TransactionTable
              id={id}
              type={'vendor'}
              userName={profileData?.vendor?.name}
            />
          ),
        },
        {
          name: 'Approvers',
          key: 'approvers',
          component: (
            <ApproversTable
              id={id}
              type={'vendor'}
              userName={profileData?.vendor?.name}
            />
          ),
        },
      ],
      pageLoading,
    });
  }, [profileData?.vendor?.name, pageLoading]);

  useEffect(() => {
    dispatch(
      getProfileStats({
        vendor: id,
        from: dayjs(lastYearStartDate).format('YYYY-MM-DD'),
        to: dayjs(lastYearEndDate).format('YYYY-MM-DD'),
      }),
    );
    dispatch(getVendorProfile({ code: id }));
  }, []);

  useEffect(() => {
    // this is to ensure uniformity of loading when the page is first loaded
    if (!chartLoading && !loading) {
      setPageLoading(false);
    }
  }, [chartLoading, loading]);

  useEffect(() => {
    setPageLoading(true);
  }, []);

  useEffect(() => {
    const { spent } = profileStats;

    if (spent) {
      let totalSpent = 0;

      let data = Object.values(spent)?.map((option) => {
        totalSpent += option.totalAmount;

        const year = option.year.toString();

        return {
          name: `${option.month} ${year.substring(year.length - 2, year.length)}`,
          spent: option.totalAmount / 100,
        };
      });

      setChartData({ totalSpent, data });
    }
  }, [profileStats]);

  const additionalInfo = () => {
    handleEditVendor();
    setIsRequest(true);
    // dispatch(vendorInfo({ code: id, fields: ['phoneNumber'] }));
  };

  useEffect(() => {
    const { vendor } = profileData;

    if (vendor) {
      const { street, country, city, state } = vendor?.address ?? {};
      const filteredArray = [street, city, state, country].filter((item) => !!item);
      const companyAddress = !!filteredArray.length ? filteredArray.join(', ') : '-';

      const uniqueCategory = new Set();

      const categories = vendor?.categories
        ?.map((option) => {
          if (!uniqueCategory.has(option.name)) {
            uniqueCategory.add(option.name);
            return {
              code: option.code,
              slug: option.slug,
              name: option.name,
              color: asciiToHex(option.name),
            };
          }
        })
        ?.filter((option) => !!option);

      setUserInformation({
        name: vendor.name,
        type: `Vendor ${
          categories?.[0]?.name
            ? `- ${categories?.[0]?.name} ${
                !!(categories.length - 1) ? `+${categories.length - 1}` : ''
              }`
            : ''
        }`,
        subText: categories?.[0]?.name
          ? `${categories?.[0]?.name} ${
              !!(categories.length - 1) ? `+${categories.length - 1}` : ''
            }`
          : '',
        avatar: null,
        code: vendor.code,
        canSendFunds: vendor.status === 'active' ? true : false,
        canEdit: vendor.status !== 'deleted' ? true : false,
        canDelete: vendor.status !== 'deleted' ? true : false,
      });

      setBankInformation([
        {
          label: 'Bank account number',
          value: vendor?.bankAccounts?.[0]?.number,
          action: {
            label: (
              <span
                className="d-flex align-items-center gap-2"
                style={{ color: '#D28B28' }}
              >
                <PlusIcon width="12" height="12" /> Add account number
              </span>
            ),
            onClick: () => additionalInfo(),
          },
        },
        {
          label: 'Routing number',
          value: vendor?.bankAccounts?.[0]?.routingNumber,
          action: {
            label: (
              <span
                className="d-flex align-items-center gap-2"
                style={{ color: '#D28B28' }}
              >
                <PlusIcon width="12" height="12" /> Add routing number
              </span>
            ),
            onClick: () => additionalInfo(),
          },
        },
        {
          label: 'Bank name',
          value: vendor?.bankAccounts?.[0]?.bankName,
          action: {
            label: (
              <span
                className="d-flex align-items-center gap-2"
                style={{ color: '#D28B28' }}
              >
                <PlusIcon width="12" height="12" /> Add bank name
              </span>
            ),
            onClick: () => additionalInfo(),
          },
        },
      ]);

      setBasicInformation([
        {
          label: 'Status',
          value: vendor.status,
          type: 'badge',
          color: getColor(vendor.status),
        },
        {
          label: 'Name',
          value: vendor.name,
          type: vendor.status ? 'text' : 'action',
          action: {
            label: (
              <span
                className="d-flex align-items-center gap-2"
                style={{ color: '#D28B28' }}
              >
                <PlusIcon width="12" height="12" /> Add name
              </span>
            ),
            onClick: () => additionalInfo(),
          },
        },
        {
          label: 'Email',
          value: vendor?.email ?? '-',
          type: vendor.email ? 'text' : 'action',
          action: {
            label: (
              <span
                className="d-flex align-items-center gap-2"
                style={{ color: '#D28B28' }}
              >
                <PlusIcon width="12" height="12" /> Add email
              </span>
            ),
            onClick: () => additionalInfo(),
          },
        },
        {
          label: 'Description',
          value: vendor?.description ?? '-',
          type: vendor.description ? 'text' : 'action',
          action: {
            label: (
              <span
                className="d-flex align-items-center gap-2"
                style={{ color: '#D28B28' }}
              >
                <PlusIcon width="12" height="12" /> Add description
              </span>
            ),
            onClick: () => additionalInfo(),
          },
        },
        {
          label: 'Phone number',
          value: vendor?.phoneNumber ?? '-',
          type: vendor.phoneNumber ? 'text' : 'action',
          action: {
            label: (
              <span
                className="d-flex align-items-center gap-2"
                style={{ color: '#D28B28' }}
              >
                <PlusIcon width="12" height="12" /> Add phone number
              </span>
            ),
            onClick: () => additionalInfo(),
          },
        },
        {
          label: 'Company address',
          value: companyAddress,
          type: companyAddress != '-' ? 'text' : 'action',
          action: {
            label: (
              <span
                className="d-flex align-items-center gap-2"
                style={{ color: '#D28B28' }}
              >
                <PlusIcon width="12" height="12" /> Add address
              </span>
            ),
            onClick: () => additionalInfo(),
          },
        },
        {
          label: 'Category',
          value: categories,
          type: vendor?.categories?.length ? 'badge list' : 'action',
          action: {
            label: (
              <span
                className="d-flex align-items-center gap-2"
                style={{ color: '#D28B28' }}
              >
                <PlusIcon width="12" height="12" /> Add category
              </span>
            ),
            onClick: () => additionalInfo(),
          },
        },
        {
          label: 'Added On',
          value: getFormattedDate(vendor.created_at),
          type: 'text',
        },
      ]);
    }
  }, [profileData]);

  const handleDateRangeSelection = (from, to) => {
    dispatch(
      getProfileStats({
        vendor: id,
        from: dayjs(from).format('YYYY-MM-DD'),
        to: dayjs(to).format('YYYY-MM-DD'),
      }),
    );
  };

  const handleEditVendor = () => {
    setIsOpen(!isOpen);
    setVendorData(buildVendorsTableData([{ ...profileData?.vendor }]));
  };

  const handleClose = () => {
    setIsOpen(!isOpen);
    setVendorData(null);
    setIsRequest(false);
  };

  return (
    <ProfileComponent
      basicInformation={basicInformation}
      bankInformation={bankInformation}
      informationBasedOnUserType={informationBasedOnUserType}
      chartData={chartData}
      handleDateRangeSelection={handleDateRangeSelection}
      userInformation={userInformation}
      onEdit={handleEditVendor}
      onDelete={() => setIsDelete(true)}
      loading={loading}
      chartLoading={chartLoading}
    >
      <CreateNewVendor
        isOpen={isOpen}
        isUpdate={true}
        toggleHandler={handleClose}
        data={vendorData?.[0]}
        isRequest={isRequest}
      />

      {isDelete && (
        <Modal show={isDelete} centered dialogClassName="custom-dialog">
          <ConfirmDialog
            title={`Delete ${isDelete?.name || ''}`}
            subTitle="Are you sure you want to delete this vendor? This action cannot be undone."
            onConfirm={() => {
              dispatch(deleteVendors(profileData.vendor.code));
            }}
            loading={deleteLoading}
            onCancel={() => {
              setIsDelete(null);
            }}
          />
        </Modal>
      )}
    </ProfileComponent>
  );
};
export default VendorProfile;
