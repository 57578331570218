import React, { useEffect, useState } from 'react';
import PasswordLabel from './PasswordLabel';
import './styles.scss';

const CheckPasswordStrength = ({ password, validate, validated }) => {
  const [passwordStrength, setPasswordStrength] = useState({});

  const passwordStrengths = (password) => {
    const checkLength = password.length > 7;
    const checkLowerCase = !!password.match(/[a-z]+/);
    const checkUppercase = !!password.match(/[A-Z]+/);
    const checkNumber = !!password.match(/[0-9]+/);
    const checkCharacter = !!password.match(/[!@#$%^&*~_+-]+/);

    const validatePassword =
      checkLowerCase && checkUppercase && checkNumber && checkCharacter && checkLength;
    validate(validatePassword);

    return { checkLowerCase, checkUppercase, checkNumber, checkCharacter, checkLength };
  };

  const { checkLowerCase, checkUppercase, checkNumber, checkCharacter, checkLength } =
    passwordStrength;

  useEffect(() => {
    setPasswordStrength(passwordStrengths(password));
  }, [password]);

  return (
    <div className="password-strength-wrapper space-y-4">
      {password && !validated ? (
        <>
          <PasswordLabel checker={checkLength} label="Contains at least 8 characters" />
          <PasswordLabel
            checker={checkCharacter}
            label="Must have at least one symbol (!@#$%^&*~_+-)"
          />
          <PasswordLabel
            checker={checkLowerCase}
            label="Must have at least one lowercase letter"
          />
          <PasswordLabel
            checker={checkUppercase}
            label="Must have at least one uppercase letter"
          />
          <PasswordLabel checker={checkNumber} label="Must have at least one number" />
        </>
      ) : null}
    </div>
  );
};

export default CheckPasswordStrength;
