import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { allPermissions } from './AllowedTo';

const VerifcationRoute = ({ children }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { onboardingStatus, isAdmin, userStatus, userRole } = allPermissions();
  const {
    loginUser: { data: { user: logedIn } = {} },
  } = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (
      userStatus === 'active' &&
      !isAdmin &&
      !['pending', 'rejected'].includes(onboardingStatus)
    )
      return;
    if (
      !isAdmin &&
      userStatus === 'active' &&
      ['pending', 'rejected'].includes(onboardingStatus) &&
      ['/expenses', '/people', '/profile'].includes(pathname)
    )
      return;
    if (
      isAdmin &&
      onboardingStatus &&
      ['pending', 'rejected'].includes(onboardingStatus) &&
      ['/expenses', '/people', '/profile'].includes(pathname)
    )
      return;
    if (
      isAdmin &&
      onboardingStatus &&
      !['pending', 'rejected'].includes(onboardingStatus)
    )
      return;

    if (userRole === 'admin' && !onboardingStatus) return history.push('/user/edit');

    return history.push('/');
  }, [pathname, logedIn]);
  return <>{children}</>;
};

export default VerifcationRoute;
