import {
  ArrowRight,
  BankNoteO1Icon,
  CalendarPlusIcon,
  CoinSwap,
  CoinsHand,
  CreditCard02,
  FileCheck,
  LayersTwoIcon,
  Move,
  PieChart02,
  PlusDropDownIcon,
  User03,
} from 'assets/icons';
import CustomButton from 'components/UI/CustomButton';
import CustomPopover from 'components/UI/Popover';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toggleAction } from 'redux/actions/ToggleAction';
import AllowedTo from 'utils/AllowedTo';

const SubHeader = ({ openSwapModal, openModal }) => {
  const history = useHistory();
  const [isPopoverOpen, setIsPopoverOpen] = useState();
  const dispatch = useDispatch();

  const openStatementModal = () => {
    setIsPopoverOpen(false);
    openModal();
  };
  const Actions = () => {
    const navigateHandler = (screenType) => () => {
      history.push({
        pathname: screenType,
        state: { isModal: true },
      });
    };
    return (
      <div className="actions-dialog">
        <AllowedTo scopes={['budget-*', 'budget-create']}>
          <div onClick={navigateHandler('/expenses/budgets')} className="actionLink">
            <PieChart02 width={16} height={16} className="ms-1  me-2" /> Create a budget
          </div>
        </AllowedTo>
        <AllowedTo scopes={['employee-create', 'employee-*']}>
          <div onClick={navigateHandler('/people')} className="actionLink">
            <User03 width={16} height={16} className="ms-1  me-2" /> Add a member
          </div>
        </AllowedTo>
        <AllowedTo scopes={['card-*', 'card-create']}>
          <div onClick={navigateHandler('/cards')} className="actionLink">
            <CreditCard02 width={16} height={16} className="ms-1  me-2" /> Create a card
          </div>
        </AllowedTo>
        <AllowedTo scopes={['statement-view', 'statement-*', 'statement-export']}>
          <div onClick={openStatementModal} className="actionLink">
            <FileCheck width={16} height={16} stroke="#79716B" className="ms-1 me-2" />{' '}
            Generate statement
          </div>
        </AllowedTo>
        <AllowedTo scopes={['reimbursement-*', 'reimbursement-create']}>
          <div
            onClick={navigateHandler('/requests/reimbursements')}
            className="actionLink"
          >
            <CoinSwap width={16} height={16} className="ms-1  me-2" />
            Claim a reimbursement
          </div>
        </AllowedTo>
        <AllowedTo scopes={['reimbursement-*', 'reimbursement-create']}>
          <div onClick={navigateHandler('/requests/funds')} className="actionLink">
            <CoinsHand stroke="#79716B" width={16} height={16} className="ms-1 me-2" />{' '}
            Request funds
          </div>
        </AllowedTo>
      </div>
    );
  };

  const [isButtonToggle, setIsButtonToggle] = useState(false);

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  const isSchedule = () => {
    localStorage.setItem('schedule', 'on');
  };

  const PaymentActions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() => {
            dispatch(toggleAction());
            handleButtonToggle();
          }}
        >
          <BankNoteO1Icon /> Single payment
        </div>
        <AllowedTo
          scopes={[
            'batch-transaction-*',
            'batch-transaction-view',
            'batch-transaction-create',
          ]}
        >
          <div
            className="actionLink"
            onClick={() => {
              history.push('/transactions/batch-payment');
            }}
          >
            <LayersTwoIcon /> Batch payments
          </div>
        </AllowedTo>
        <div
          className="actionLink"
          onClick={() => {
            dispatch(toggleAction());
            handleButtonToggle();
            isSchedule();
          }}
        >
          <CalendarPlusIcon /> Schedule payment
        </div>
      </div>
    );
  };

  const handleTogglePopover = () => setIsPopoverOpen(!isPopoverOpen);

  return (
    <div className="d-md-flex align-items-center w-100 justify-content-between pt-5 pb-5 home-actions">
      <h1 className="page-title">Inbox</h1>
      <ul className="overview-action p-0">
        <li className="gap-3">
          <CustomPopover
            zIndex="1"
            content={<Actions />}
            showPopover={isPopoverOpen}
            clickOutside={handleTogglePopover}
          >
            <div
              className="add-button add-action quick-actions"
              onClick={handleTogglePopover}
            >
              <PlusDropDownIcon className="me-1" /> <span>Quick actions</span>
            </div>
          </CustomPopover>
          <AllowedTo scopes={['dash-*', 'dash-edit']}>
            <div className="add-button add-action" onClick={openSwapModal}>
              <Move width={16} height={16} color="#000000" className="me-1" /> Move funds
            </div>
          </AllowedTo>
          <AllowedTo scopes={['transaction-*', 'transaction-create']}>
            <CustomPopover
              zIndex="1"
              content={<PaymentActions />}
              showPopover={isButtonToggle}
              clickOutside={handleButtonToggle}
            >
              <CustomButton className="add-button" onClick={handleButtonToggle}>
                Send funds <ArrowRight color="#ffffff" className="ms-1" />
              </CustomButton>
            </CustomPopover>
          </AllowedTo>
        </li>
      </ul>
    </div>
  );
};

export default SubHeader;
