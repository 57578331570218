import { CloseOutlined } from '@ant-design/icons';
import AssetViewer from 'components/AssetViewer';
import CustomButton from 'components/UI/CustomButton';
import CustomDrawer from 'components/UI/CustomDrawer';
import ReceiptList from 'components/UI/CustomDrawer/components/ReceiptList';
import FileUpload from 'components/UI/FileUpload';
import Loading from 'components/UI/Loading';
import Modal from 'components/UI/Modal';
import ItemsDetails from 'components/bujetiPayModal/ItemsDetails';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllTransactionAsset,
  getScheduledTransactions,
  getSingleScheduledTransaction,
  updateScheduledTransactionReceipt,
  updateScheduledTransactions,
} from 'redux/actions/TransactionsAction';
import { RESET_BLOCK_TRANSACTIONS } from 'redux/reducers/TransactionsReducer';
import { Banks } from 'utils/banks';
import {
  capitalizeFirstLetter,
  getCurrency,
  getFormattedDate,
  getPaidTo,
} from 'utils/helper';

const ScheduledModal = ({ selectedTransaction, setSelectedTransaction }) => {
  const [file, setFile] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [assetViewer, setAssetViewer] = useState(false);

  const dispatch = useDispatch();
  const handleClose = () => {
    setSelectedTransaction(null);
    dispatch({
      type: RESET_BLOCK_TRANSACTIONS,
      blockType: 'getSingleScheduledTransaction',
    });
    dispatch({ type: RESET_BLOCK_TRANSACTIONS, blockType: 'transactionReceipts' });
  };

  const {
    updateScheduledTransaction: { loading, success },
    getSingleScheduledTransaction: {
      data: singleTransaction = {},
      loading: singleLoading,
      success: singleSuccess,
      error: singleError,
    },
    transactionReceipts: { data: receiptAssets, loading: loadingReceipt },
    updateScheduledTransactionReceipt: { success: isUpdatingReceiptSuccess },
  } = useSelector(({ transaction }) => transaction);

  const { transactionData: selectedData = {} } = selectedTransaction ?? {};

  const toggleAssetViewer = () => {
    setAssetViewer(!assetViewer);
  };

  const viewImgUrl = (asset) => {
    setSelectedAsset(asset);
    setAssetViewer(true);
  };

  useEffect(() => {
    if (file?.length) {
      const data = {
        receipt: file,
        code: selectedData.code,
      };
      dispatch(updateScheduledTransactionReceipt(data));
    }
  }, [file]);

  useEffect(() => {
    if (!singleSuccess && !singleLoading && selectedData?.code) {
      dispatch(getSingleScheduledTransaction(selectedData?.code));
    }
  }, [selectedData?.code]);

  const cancelTransaction = () => {
    dispatch(
      updateScheduledTransactions({ code: selectedData?.code, status: 'cancelled' }),
    );
  };

  useEffect(() => {
    if (success) {
      setSelectedTransaction(null);
      dispatch(getScheduledTransactions());
    }
  }, [success]);

  useEffect(() => {
    if (!singleLoading && singleSuccess) {
      dispatch(getAllTransactionAsset(singleTransaction?.receipts));
    }
    if (!singleLoading && !singleSuccess && singleError) {
      handleClose();
    }
  }, [singleLoading, singleSuccess]);

  const transactionData = singleTransaction;

  const getBankName = Banks.find(
    (item) => item.value === transactionData?.bankAccount?.bankCode,
  );

  useEffect(() => {
    if (isUpdatingReceiptSuccess && transactionData?.code) {
      dispatch(getSingleScheduledTransaction(transactionData?.code));
      dispatch(getScheduledTransactions());
      dispatch({
        type: RESET_BLOCK_TRANSACTIONS,
        blockType: 'updateScheduledTransactionReceipt',
      });
    }
  }, [isUpdatingReceiptSuccess]);

  // This is for the asset viewer to handle multple asset download
  const assetSingleData = {
    ...selectedTransaction?.transactionData,
    user: selectedTransaction?.transactionData?.payer,
  };

  return (
    <Modal show={selectedTransaction}>
      <CustomDrawer show>
        {assetViewer && (
          <AssetViewer
            toggleVisibility={toggleAssetViewer}
            data={receiptAssets && receiptAssets}
            selectedAsset={selectedAsset}
            singleData={assetSingleData}
          />
        )}
        <div className="content d-flex flex-column">
          {singleLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: '100vh' }}
            >
              <Loading color="#d2b28b" size={32} />
            </div>
          ) : (
            <>
              <div className="card-modal-header">
                <div className="d-flex align-items-center cursor" onClick={handleClose}>
                  <CloseOutlined />
                  <span className="ps-1">Close</span>
                </div>
              </div>
              <div className="card-modal-body">
                <div className="information-wrapper">
                  <h2 className="card-title w-100 mb-5">Transaction details</h2>
                  <div className="w-100 mb-3">
                    <ItemsDetails
                      className="mb-1"
                      title={<span className="header__transfer">Transfer details</span>}
                    />

                    <ItemsDetails
                      title="Total amount we'll send"
                      value={
                        <CurrencyFormat
                          prefix={getCurrency(transactionData?.currency)}
                          value={transactionData?.amount / 100}
                          displayType="text"
                          thousandSeparator={true}
                        />
                      }
                    />
                    <ItemsDetails
                      title="Transaction fees"
                      value={
                        <CurrencyFormat
                          prefix={getCurrency(transactionData?.currency)}
                          value={(transactionData?.processor_fee ?? 0) / 100}
                          displayType="text"
                          thousandSeparator={true}
                        />
                      }
                    />
                    <ItemsDetails
                      className="mt-1"
                      title="Amount"
                      value={
                        <span className="fs-5 text-dark">
                          <CurrencyFormat
                            prefix={getCurrency(transactionData?.currency)}
                            value={
                              ((transactionData?.processor_fee ?? 0) +
                                transactionData?.amount) /
                              100
                            }
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </span>
                      }
                    />
                    <div className="divider"></div>
                    <ItemsDetails
                      className="mb-1"
                      title={<span className="header__transfer">Recipient details</span>}
                    />
                    <ItemsDetails title="Name" value={getPaidTo(transactionData)} />
                    <ItemsDetails title="Bank name" value={getBankName?.label ?? '-'} />
                    <ItemsDetails
                      title="Account number"
                      value={transactionData?.bankAccount?.number ?? '-'}
                    />

                    <div className="divider"></div>

                    <ItemsDetails
                      className="mb-1"
                      title={<span className="header__transfer">Schedule details</span>}
                    />
                    <ItemsDetails
                      title="Frequency"
                      value={
                        capitalizeFirstLetter(transactionData?.frequency) ?? 'One time'
                      }
                    />
                    <ItemsDetails
                      title="Next transfer"
                      value={getFormattedDate(transactionData?.schedule?.nextDate)}
                    />
                    <ItemsDetails
                      title="Start date"
                      value={getFormattedDate(transactionData?.start_date)}
                    />
                    <ItemsDetails
                      title="End date"
                      value={getFormattedDate(transactionData?.expiry_date)}
                    />

                    <div className="divider"></div>

                    <ItemsDetails
                      className="mb-1"
                      title={<span className="header__transfer">Other</span>}
                    />
                    {transactionData?.balance && (
                      <ItemsDetails
                        title={<span className="text-dark">Account</span>}
                        isLink={
                          transactionData?.balance?.code
                            ? `/accounts/${transactionData?.balance.code}`
                            : false
                        }
                        withAction
                        value={
                          <span className="value__transfer">
                            {transactionData?.balance?.name ?? 'N/A'}
                          </span>
                        }
                      />
                    )}
                    {transactionData?.budget && (
                      <ItemsDetails
                        title={<span className="text-dark">Budget</span>}
                        isLink={
                          transactionData?.budget?.code
                            ? `/expenses/budgets/${transactionData?.budget.code}/overview`
                            : false
                        }
                        withAction
                        value={
                          <span className="value__transfer">
                            {transactionData?.budget?.name ?? 'N/A'}
                          </span>
                        }
                      />
                    )}
                    <ItemsDetails
                      title="Expense category"
                      value={transactionData?.category?.name}
                    />
                    <ItemsDetails
                      title="Description"
                      value={transactionData?.description}
                    />
                    <ItemsDetails
                      title="Schedule ID"
                      value={transactionData?.code ?? '-'}
                    />

                    {loadingReceipt && !receiptAssets?.length && (
                      <Loading color="#D28B28" size={24} />
                    )}
                    {!loadingReceipt && receiptAssets?.length > 0 && (
                      <div className="padding-top-base">
                        <ReceiptList
                          receiptAssets={receiptAssets}
                          viewImgUrl={viewImgUrl}
                          addMore={true}
                          onChange={(value) =>
                            setFile(value?.map((item) => item.assetCode))
                          }
                        />
                      </div>
                    )}
                    {(!loadingReceipt && !transactionData?.receipts) ||
                      (!transactionData?.receipts?.length &&
                        receiptAssets?.length <= 0 && (
                          <Row className="my-4">
                            <FileUpload
                              label="Receipt"
                              placeholder="Upload receipts or invoices (PDF, JPEG, PNG)"
                              name="file"
                              onChange={(value) =>
                                setFile(value?.map((item) => item.assetCode))
                              }
                              multiple
                              wrapperClass="new-receipt"
                            />
                          </Row>
                        ))}
                  </div>
                </div>
              </div>
              {transactionData?.status === 'active' && (
                <div className="card-modal-footer justify-content-end">
                  <CustomButton
                    onClick={cancelTransaction}
                    className="base-button text-sm font-medium danger-button"
                    disabled={loading}
                  >
                    Cancel transaction
                  </CustomButton>
                </div>
              )}
            </>
          )}
        </div>
      </CustomDrawer>
    </Modal>
  );
};

export default ScheduledModal;
