import EmptyState from 'components/UI/EmptyState';
import ExpenseImage from '../../assets/images/emptystate/expense-image.png';

import CustomButton from 'components/UI/CustomButton';

import { CreateTeams, FundRequest } from 'assets/icons/empty-state-icons';
import { useLocation } from 'react-router-dom';

const RequestEmptyStateData = ({
  openRequestModal,
  openReimbursementModal,
  showAction,
}) => {
  const location = useLocation();
  const locationArray = location.pathname.split('/');

  const key = locationArray[locationArray.length - 1];

  const isReimbursement = key === 'reimbursements';
  const isFundRequest = key === 'funds';

  const handleOpenModal = () => {
    if (isReimbursement) openReimbursementModal();
    if (isFundRequest) openRequestModal();
  };

  const handleSupport = () => {
    window.open('https://www.bujeti.com/reimbursements', '_blank');
  };

  const buttonName = 'Claim a reimbursement';

  const childrenData = [
    {
      title: 'Manage Reimbursement',
      body: 'Manage all reimbursement request from your employees',
      actionTitle: 'View more',
      action: handleSupport,
      icon: <CreateTeams />,
    },

    {
      title: 'View Fund Requests',
      body: 'Monitor and manage fund requests in alignment with your budgetary guidelines.',
      actionTitle: 'View more',
      action: handleSupport,
      icon: <FundRequest />,
    },
  ];

  //The complete list of your reimbursements will be displayed here. Request your first reimbursement
  const mainData = {
    title: 'Create, manage and track expense requests and reimbursements.',
    body: `Manage your company's expenses by organising transactions, tracking vendors, and implementing spending policies.`,
    image: ExpenseImage,
    action: () =>
      showAction && (
        <CustomButton className="add-button w-auto" onClick={handleOpenModal}>
          {buttonName}
        </CustomButton>
      ),
  };

  return <EmptyState main={mainData} childrenData={childrenData} />;
};

export default RequestEmptyStateData;
