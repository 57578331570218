/* eslint-disable no-console */
import { Skeleton } from 'antd';
import { ArrowRight } from 'assets/icons';
import Vslide from 'assets/images/slider/v_slider.png';
import vendorCompletedIcon from 'assets/images/vendor-completed.png';
import classNames from 'classnames';
import { AuthFormHeader } from 'components/Auth';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomPhoneNumberInput from 'components/UI/CustomPhoneNumberInput';
import CustomSelect from 'components/UI/CustomSelect';
import CustomTextarea from 'components/UI/CustomTextarea';
import { toastError } from 'components/UI/toast';
import { useDebounce } from 'hooks/useDebounce';
import useTextCounter from 'hooks/useTextCounter';
import Layout, { OverflowWrapLayout } from 'pages/Authentication/Layout';
import { memo, useEffect, useMemo, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getCountries, getState } from 'redux/actions/CompaniesAction';
import { getAllBanks, verifyBankAccount } from 'redux/actions/PaymentAction';
import { updateExternalVendor } from 'redux/actions/VendorsAction';
import { getInternationalFormat, removeEmptyString } from 'utils/helper';
import { retrieveAndDecryptFromLocalStorage } from 'utils/utility';

const MAX_LENGTH = '100';

const vendorInitial = {
  name: '',
  email: '',
  city: '',
  description: '',
  taxIdentificationNumber: '',
  country: '',
  state: '',
  street: '',
  taxWithHolding: '',
  accountNumber: '',
  accountName: '',
  bankName: '',
  currency: 'NGN',
};

const contents = [
  {
    title: 'Impactful title',
    desc: 'Powerful, self-serve product and growth analytics to help you convert.',
    imgSrc: Vslide,
  },
  {
    title: 'Another Title',
    desc: 'Additional description goes here. Make sure to tailor it to your audience.',
    imgSrc: Vslide,
  },
  {
    title: 'Final Slide',
    desc: 'Your last piece of information should be impactful and memorable.',
    imgSrc: Vslide,
  },
];

const InvitedVendorsForm = () => {
  const dispatch = useDispatch();
  const { push, replace } = useHistory();
  const vendorToken = retrieveAndDecryptFromLocalStorage('vendor-app-session');
  const location = useLocation();
  const [steps, setSteps] = useState(0);
  const [stateIsSet, setStateIsSet] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [vendors, setVendors] = useState(vendorInitial);

  const [menus, setMenus] = useState([
    { name: 'Security', isActive: false, completed: true },
    { name: 'Vendor details', isActive: true, completed: false },
    { name: 'Payment details', isActive: false, completed: false },
    { name: 'Contact information', isActive: false, completed: false },
  ]);

  const {
    getCountry: { data: countryData = {} },
    getState: { data: states = {}, success: successState, loading: loadingState },
  } = useSelector(({ companies }) => companies);

  const {
    updateExternalVendor: { loading: updatingVendor, success: successUpdateVendors },
  } = useSelector(({ vendors }) => vendors);

  const vendorState = location?.state?.vendor;
  const {
    verifyBankAccount: {
      data: accName,
      loading: accountNameLoading,
      success: accountNameSuccess,
      error: accountNameError,
    },
  } = useSelector(({ payments }) => payments);

  const {
    fetchCategories: { data: categoryData },
  } = useSelector(({ categories }) => categories);

  const {
    getAllBanks: {
      data: banksData,
      loading: loadingBanks,
      success: successBanks,
      error: errorBanks,
    },
  } = useSelector(({ payments }) => payments);
  // Get All banks starts here

  const [bankValue, setBankValue] = useState('');
  const bankValuedebounced = useDebounce(bankValue, 200);

  const handleGetBankOnChange = (val) => setBankValue(val);

  const mappedBanks = banksData?.map((item) => item.name);

  const allBanks = useMemo(() => {
    return banksData?.map((item) => ({
      label: item.label,
      value: item.bankCode,
    }));
  }, [successBanks, errorBanks, mappedBanks]);

  const onMenuCloseBanks = () => {
    if (bankValuedebounced) dispatch(getAllBanks());
  };

  useEffect(() => {
    dispatch(getAllBanks());
  }, []);

  useEffect(() => {
    const banks = allBanks?.find((option) =>
      option?.label?.toLowerCase().includes(bankValuedebounced?.toLowerCase()),
    );
    if (!banks && bankValuedebounced) {
      dispatch(getAllBanks({ search: bankValuedebounced?.toLowerCase() }));
    }
  }, [bankValuedebounced]);

  useEffect(() => {
    if (!countryData?.length) dispatch(getCountries());
  }, []);

  const generateCountry = useMemo(() => {
    if (countryData?.length > 0) {
      return countryData?.map(({ code: value, name: label }) => ({
        value,
        label,
        isDisabled: label !== 'NIGERIA' ? true : false,
      }));
    }
  }, [countryData]);

  const generateState = useMemo(() => {
    setStateIsSet(true);
    return states.states?.map((value) => ({
      label: value,
      value: value,
    }));
  }, [successState]);

  const getCountryStates = async (val) => {
    dispatch(getState(val.value));
  };

  useEffect(() => {
    if (vendorState && generateCountry?.length) {
      const { address } = vendorState;

      if (address?.country) {
        const country = generateCountry?.find((item) => item?.label === address?.country);
        dispatch(getState(country?.value));
      }
    }
  }, [generateCountry?.length]);

  useMemo(() => {
    if (vendorState && !vendors.name) {
      const {
        name,
        email,
        tin,
        description,
        taxWithHolding,
        bankAccounts,
        phoneNumber,
        address,
      } = vendorState;

      setVendors({
        ...vendors,
        name,
        email,
        taxIdentificationNumber: tin,
        description,
        city: address?.city,
        street: address?.street,
        state: address?.state
          ? { label: address?.state, value: address?.state }
          : undefined,
        taxWithHolding,
        country: generateCountry?.find(
          (item) => item?.label?.toLowerCase() === address?.country?.toLowerCase(),
        ),
        postCode: address?.postalCode,
        bankName:
          allBanks?.find((item) => item?.value === bankAccounts?.[0]?.bankCode) ?? '',
        accountNumber: bankAccounts?.[0]?.number || '',
        localFormat: phoneNumber?.localFormat || '',
        countryCode: phoneNumber?.countryCode || '',
        internationalFormat:
          getInternationalFormat(phoneNumber?.countryCode, phoneNumber?.localFormat) ||
          '',
      });
    }
  }, [stateIsSet]);

  const handleDescriptionChange = (value) => {
    setVendors({
      ...vendors,
      description: value,
    });
  };

  const { text, charCount, handleCharChange } = useTextCounter(
    vendors?.description,
    MAX_LENGTH,
    handleDescriptionChange,
  );

  const handleChange = ({ name, value, validity, rawValue }) => {
    if (['accountName', 'accountNumber'].includes(name))
      return validity.valid && setVendors({ ...vendors, [name]: value });
    return setVendors({ ...vendors, [name]: rawValue ? rawValue : value });
  };

  const handlePhoneNumberChange = (localFormat, internationalFormat, countryCode) => {
    setVendors({ ...vendors, internationalFormat, localFormat, countryCode });
  };

  useEffect(() => {
    if (vendors.accountNumber.length === 10 && vendors.bankName.value) {
      const { accountNumber, bankName } = vendors;
      dispatch(verifyBankAccount({ accountNumber, bankCode: bankName.value }));
    }

    if (vendors.accountNumber.length < 10 && vendors.bankName.value) {
      setVendors({ ...vendors, accountName: undefined });
    }
  }, [vendors.accountNumber, vendors.bankName.value]);

  useEffect(() => {
    if (accountNameSuccess) {
      setVendors({ ...vendors, accountName: accName.account_name });
    }
    if (accountNameError) setVendors({ ...vendors, accountName: undefined });
    if (accountNameLoading) setVendors({ ...vendors, accountName: undefined });
  }, [accountNameSuccess, accountNameError, accountNameLoading]);

  const visible = accountNameLoading || accountNameError;

  const handleSubmit = () => {
    if (steps === 0) {
      if (!vendors.name) return toastError('Please enter name');
      // if (!vendors.rcNuber) return toastError('Please enter name');
      if (!vendors.description) return toastError('Please enter description');

      const payload = {
        name: vendors.name,
        email: vendors?.email,
        description: vendors?.description,
      };

      const payloads = removeEmptyString(payload);
      return dispatch(updateExternalVendor({ ...payloads, code: vendorState.code }));
    }

    if (steps === 1) {
      if (!vendors.bankName.value) return toastError('Please select a bank');
      if (vendors.accountNumber) {
        if (vendors.accountNumber.length < 10)
          return toastError('Account number must be 10 digits');
      }
      // if (!vendors.taxIdentificationNumber) return toastError('Please enter tin');
      if (vendors.taxWithHolding) {
        if (!(vendors.taxWithHolding > 0 && vendors.taxWithHolding <= 100))
          return toastError('Please enter a number between 1 and 100');
      }

      const hasAccount =
        vendors?.bankName?.value && vendors?.accountName && vendors?.accountNumber;

      const payload = {
        ...(hasAccount && {
          bankAccount: {
            bankName: vendors?.bankName?.label,
            bankCode: vendors?.bankName?.value,
            accountName: vendors?.accountName,
            number: vendors?.accountNumber,
            currency: vendors?.currency,
          },
        }),

        taxIdentificationNumber: vendors?.taxIdentificationNumber || undefined,
        taxWithHolding: vendors?.taxWithHolding || undefined,
      };

      const payloads = removeEmptyString(payload);
      return dispatch(updateExternalVendor({ ...payloads, code: vendorState.code }));
    }

    if (steps === 2) {
      if (!vendors.localFormat) return toastError('Please enter phone number');
      if (!vendors.street) return toastError('Please enter address');
      if (!vendors.country) return toastError('Please select country');
      if (!vendors.state) return toastError('Please select state');
      if (!vendors.city) return toastError('Please enter city');
      if (!vendors.postCode) return toastError('Please post code');
    }

    const payload = {
      address: {
        country: vendors?.country?.value,
        city: vendors?.city,
        stateOrProvince: vendors?.state?.value,
        street: vendors?.street,
      },
      phoneNumber: {
        countryCode: vendors?.countryCode,
        localFormat: vendors?.localFormat,
      },
    };

    const payloads = removeEmptyString(payload);
    return dispatch(updateExternalVendor({ ...payloads, code: vendorState.code }));
  };

  const updateMenus = (val) => {
    const updateMenu = menus?.map((menu, index) => {
      return {
        ...menu,
        isActive: index === val,
        completed: index < val,
      };
    });
    setMenus(updateMenu);
  };

  const nextScreen = (step) => {
    setSteps(step);
    updateMenus(step + 1);
  };

  const goback = () => {
    setSteps((prev) => {
      updateMenus(prev);
      return prev - 1;
    });
  };

  useEffect(() => {
    if (successUpdateVendors) {
      const payload = {
        name: vendors?.name,
        email: vendors?.email,
        description: vendors?.description,
        bankAccounts: [
          {
            bankName: vendors?.bankName?.label,
            bankCode: vendors?.bankName?.value,
            accountName: vendors?.accountName,
            number: vendors?.accountNumber,
            currency: vendors?.currency,
          },
        ],
        tin: vendors?.taxIdentificationNumber,
        taxWithHolding: vendors?.taxWithHolding,
        address: {
          country: vendors?.country?.label,
          city: vendors?.city,
          stateOrProvince: vendors?.state?.value,
          street: vendors?.street,
        },
        phoneNumber: {
          countryCode: String(vendors?.countryCode),
          localFormat: vendors?.localFormat,
        },
      };

      let state = { ...location.state };
      state.vendor = { ...state.vendor, ...payload };
      replace({ ...location, state });
      if (steps < 2) nextScreen(steps + 1);
      else {
        setCompleted(true);
        setTimeout(() => {
          localStorage.removeItem('vendor-app-session');
          window.open('https://www.bujeti.com/', '_self');
        }, 10000);
      }
    }
  }, [successUpdateVendors]);

  const StepOneForm = (
    <>
      <Row className="mb-2">
        <CustomInput
          type="text"
          label="Vendor name *"
          placeholder="Bujeti Ltd."
          name="name"
          value={vendors.name}
          onChange={({ target: { name, value } }) => handleChange({ name, value })}
        />
      </Row>
      <Row className="mb-2">
        <CustomInput
          type="text"
          label="Vendor email"
          placeholder="contact@bujeti.com"
          name="email"
          value={vendors.email}
          onChange={({ target: { name, value } }) => handleChange({ name, value })}
          disabled
        />
      </Row>

      <Row className="mb-2">
        <CustomTextarea
          rowSize={3}
          label="Description *"
          name="description"
          onChange={handleCharChange}
          value={text || vendors.description}
          placeholder="e.g. Secure online payment gateway for transactions"
          maxLength={MAX_LENGTH}
          showCounter={true}
          charCount={charCount}
        />
      </Row>
      <CustomButton
        type="button"
        fullWidth
        className="mt-3"
        onClick={handleSubmit}
        loading={updatingVendor}
        disabled={updatingVendor}
      >
        Continue <ArrowRight className="ms-1" color="#fff" />
      </CustomButton>
    </>
  );

  const StepTwoForm = (
    <>
      <Row className="mb-2">
        <CustomSelect
          label="Bank name *"
          name="bankName"
          placeholder="Select bank"
          onChange={(value) => handleChange({ name: 'bankName', value: value })}
          value={vendors.bankName}
          options={allBanks}
          onMenuClose={onMenuCloseBanks}
          onInputChange={handleGetBankOnChange}
          isDisabled={loadingBanks && !bankValuedebounced}
          isLoading={loadingBanks && !bankValuedebounced}
        />
      </Row>
      <Row className="mb-2 ">
        <CustomInput
          type="text"
          label="Account number *"
          placeholder="0584932020"
          name="accountNumber"
          onChange={({ target: { name, value, validity, rawValue } }) =>
            handleChange({ name, value, validity, rawValue })
          }
          value={vendors.accountNumber}
          maxLength="10"
          pattern="[0-9]*"
        />
      </Row>

      {!visible && vendors.accountName && (
        <Row className="mb-2 ">
          <CustomInput
            type="text"
            label="Full name of account holder"
            placeholder="Bujeti Limited"
            name="accountName"
            onChange={({ target: { name, value, validity, rawValue } }) =>
              handleChange({ name, value, validity, rawValue })
            }
            value={vendors.accountName}
            maxLength="50"
            disabled
          />
        </Row>
      )}

      {accountNameLoading && (
        <Row className="mb-2 ">
          <Skeleton.Input
            size="large"
            active
            className="w-100"
            style={{ height: '38px', borderRadius: '4px' }}
          />
        </Row>
      )}

      <Row className="mb-2 align-items-center">
        <CustomInput
          type="text"
          label="Tax identification number"
          placeholder="2234561234"
          name="taxIdentificationNumber"
          onChange={({ target: { name, value, validity, rawValue } }) =>
            handleChange({ name, value, validity, rawValue })
          }
          value={vendors.taxIdentificationNumber}
          md={6}
        />

        <CustomInput
          label="Tax witholding percentage"
          placeholder="Tax withholding %"
          name="taxWithHolding"
          onChange={({ target: { name, value, validity, rawValue } }) =>
            handleChange({ name, value, validity, rawValue })
          }
          value={vendors.taxWithHolding}
          type="number"
          isAmount
          maxLength="5"
          useCurrency={false}
          md={6}
        />
      </Row>
      <CustomButton
        type="button"
        fullWidth
        className="mt-3"
        onClick={handleSubmit}
        loading={updatingVendor}
        disabled={updatingVendor}
      >
        Continue <ArrowRight className="ms-1" color="#fff" />
      </CustomButton>
    </>
  );

  const StepThreeForm = (
    <>
      <Row className="mb-2">
        <CustomPhoneNumberInput
          label="Phone number *"
          placeholder="Enter your mobile number"
          onChange={(localFormat, international, countryCode) =>
            handlePhoneNumberChange(localFormat, international, countryCode)
          }
          value={vendors.internationalFormat}
        />
      </Row>
      <Row className="mb-2">
        <CustomInput
          type="text"
          label="Address *"
          placeholder="Plot 3 osolo way"
          name="street"
          onChange={({ target: { name, value } }) => handleChange({ name, value })}
          value={vendors.street}
        />
      </Row>
      <Row className="mb-2">
        <CustomInput
          type="text"
          label="City *"
          placeholder="Ikeja"
          name="city"
          onChange={({ target: { name, value } }) => handleChange({ name, value })}
          value={vendors.city}
        />
      </Row>

      <Row className="mb-2">
        <CustomSelect
          label="Country *"
          name="country"
          placeholder="Select country"
          value={vendors.country}
          options={generateCountry}
          onChange={(value) => {
            handleChange({ name: 'country', value: value });
            getCountryStates(value);
          }}
        />
      </Row>

      <Row className="mb-2 align-items-center">
        <CustomSelect
          label="State / Province *"
          placeholder="Enter state"
          name="state"
          value={vendors.state}
          isDisabled={loadingState}
          options={generateState}
          onChange={(value) => handleChange({ name: 'state', value: value })}
          md={6}
        />

        <CustomInput
          label="Postcode *"
          placeholder="Enter your post code"
          name="postCode"
          onChange={({ target: { name, value } }) => handleChange({ name, value })}
          value={vendors.postCode}
          md={6}
        />
      </Row>
      <CustomButton
        type="button"
        fullWidth
        className="mt-3"
        onClick={handleSubmit}
        loading={updatingVendor}
        disabled={updatingVendor}
      >
        Submit <ArrowRight className="ms-1" color="#fff" />
      </CustomButton>
    </>
  );

  const completedView = (
    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
      <div className="d-flex flex-column text-center  justify-content-center align-items-center">
        <img src={vendorCompletedIcon} alt="completed" />
        <div className="completed-text mt-4">
          <h1>You&apos;re all set and ready to get paid!</h1>
          <p>
            Congratulations! You have successfully completed your vendor account setup
            with us.
          </p>
        </div>
      </div>
    </div>
  );

  const renderScreen = {
    [0]: StepOneForm,
    [1]: StepTwoForm,
    [2]: StepThreeForm,
  };

  const title = 'Set up vendor account';

  return (
    <Layout menus={menus} slider={contents} hideSlider={completed} hideSteps={completed}>
      <AuthFormHeader
        backButton={!completed && steps > 0}
        goBack={goback}
        titlle={completed ? null : title}
        subtitle={null}
      />
      <OverflowWrapLayout
        height={completed ? '100%' : undefined}
        maxWidth={completed ? '580px' : undefined}
      >
        <Form className={classNames('w-100', { ['h-100']: completed })}>
          {completed ? completedView : renderScreen[steps]}
        </Form>
      </OverflowWrapLayout>
    </Layout>
  );
};

export default memo(InvitedVendorsForm);
