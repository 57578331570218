import ConfirmDialog from 'components/ConfirmDialog';
import { BudgetsStatusType } from 'components/FilterModal/FilterHelper';
import NoData from 'components/NoData';
import TopBar from 'components/TopBar';
import Loading from 'components/UI/Loading';
import { toastError } from 'components/UI/toast';
import { useDebounce } from 'hooks/useDebounce';
import BeneficiariesModal from 'pages/Beneficiaries/BeneficiariesModal';
import PendingOnboardingNotice from 'pages/Budgets/PendingOnboardingNotice';
import PeopleEmptyStateData from 'pages/People/peopleEmptystateData';
import { useEffect, useRef, useState } from 'react';
import { Container, Modal, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { deleteTeam, getTeams } from 'redux/actions/TeamsAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import TeamsCard from './TeamsCard';
import TeamsModal from './TeamsModal';

const Teams = ({ toggleHandler, isOpen }) => {
  const dispatch = useDispatch();

  const { permissions } = allPermissions();

  const canView = hasPermission({
    permissions,
    scopes: ['team-*', 'team-view'],
  });

  const createTeam = hasPermission({
    permissions,
    scopes: ['team-*', 'team-create'],
  });

  const location = useLocation();
  const history = useHistory();
  const [filterData, setFilterData] = useState([...BudgetsStatusType]);
  // const [isOpen, setIsOpen] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [isDelete, setIsDelete] = useState(null);
  const [isFillData, setIsFillData] = useState(false);
  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;
  const debouncedValue = useDebounce(search, 600);
  const [filteredQuery, setFilteredQuery] = useState({});

  const {
    getTeams: { data: teamData = {}, loading },
    deleteTeam: { success: deleteTeamSuccess, loading: deleteLoading },
  } = useSelector(({ teams }) => teams);

  const {
    getBeneficiaries: { data = {} },
  } = useSelector(({ beneficiaries }) => beneficiaries);
  const { beneficiaries = [] } = data;

  const {
    getBudget: { data: { budgets = [] } = {} },
  } = useSelector(({ budgets }) => budgets);

  const { teams = [], meta = {} } = teamData;
  const { hasMore, perPage } = meta;

  useEffect(() => {
    if (budgets?.length && beneficiaries?.length) {
      if (!isFillData) {
        setIsFillData(true);
        const budgetList = [];
        const beneficairyList = [];
        budgets.forEach((budget) => {
          const { code, name } = budget;
          budgetList.push({
            value: code,
            label: name,
            isSelected: false,
          });
        });

        beneficiaries.forEach((beneficiary, i) => {
          const {
            user: { firstName, lastName, code },
          } = beneficiary;

          beneficairyList.push({
            value: code,
            label: `${firstName} ${lastName}`,
            isSelected: false,
          });
        });

        setFilterData([
          ...filterData,
          { title: 'Budget', list: budgetList },
          { title: 'Member', list: beneficairyList },
          { title: 'Manager', list: beneficairyList },
        ]);
      }
    }
  }, [budgets, beneficiaries]);

  const handleFilter = (query) => {
    isFiltered.current = !!Object.keys(query).length;
    const {
      status: statusQ,
      budget: budgetQ,
      from: fromQ,
      to: toQ,
      member: memberQ,
      manager: managerQ,
    } = query;
    const member = memberQ ? memberQ.toString() : undefined;
    const manager = managerQ ? managerQ.toString() : undefined;
    const status = statusQ ? statusQ.toString() : undefined;
    const budget = budgetQ ? budgetQ.toString() : undefined;
    const to = toQ ? toQ.toString() : undefined;
    const from = fromQ ? fromQ.toString() : undefined;

    if ((!from && to) || (from && !to)) {
      return toastError('To filter by date, please set from and to date');
    }

    dispatch(
      getTeams({
        status,
        budget,
        member,
        manager,
        from,
        to,
      }),
    );

    setFilteredQuery({
      status,
      budget,
      member,
      manager,
      from,
      to,
    });
  };

  useEffect(() => {
    return () => {
      if (filtered && canView) dispatch(getTeams());
    };
  }, [filtered]);
  // Handle search change after debounce
  useEffect(() => {
    if (debouncedValue && canView) {
      filteredQuery.search = debouncedValue;
      dispatch(getTeams({ ...filteredQuery, search: debouncedValue }));
    }
    if (!debouncedValue && filtered && canView) {
      delete filteredQuery.search;
      dispatch(getTeams({ perPage, ...filteredQuery }));
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  // const toggleHandler = (val) => {
  //   setIsOpen(val);
  // };

  const handleClose = () => {
    return toggleHandler(0), setSelectedTeam(null), setIsUpdate(false);
  };

  const handleEditModal = (teamVal) => {
    setIsUpdate(true);
    setSelectedTeam(teamVal);
  };

  useEffect(() => {
    if (deleteTeamSuccess) {
      setIsDelete(null);
      dispatch(getTeams());
    }
  }, [deleteTeamSuccess]);

  const handleDeleteClick = () => {
    dispatch(deleteTeam(isDelete.code));
  };

  const handleDeleteModal = (teamVal) => {
    setIsDelete(teamVal);
  };

  const handleMore = (perPage) => {
    setTimeout(() => {
      if (!loading) {
        dispatch(getTeams({ perPage, ...filteredQuery }));
        isFiltered.current = true;
      }
    }, 1500);
  };

  useEffect(() => {
    if (location.state?.isTeam) {
      toggleHandler(2);
      history.replace({ state: {} });
    }
  }, [location]);

  const teamList = () => {
    return (
      <>
        {!teams.length ? (
          <div>
            {filtered ? (
              <div className="tabinnerWrapper">
                <NoData
                  headerText="You have no teams for this filter"
                  bodyText="Alter filter to see teams."
                  withButton={false}
                />
              </div>
            ) : (
              <PeopleEmptyStateData
                setIsTeamOpen={() => toggleHandler(2)}
                showAction={createTeam}
              />
            )}
          </div>
        ) : (
          <div className="pt-4">
            <Container className="p-0">
              <InfiniteScroll
                pageStart={0}
                loadMore={() => handleMore(perPage + 50)}
                hasMore={hasMore}
                className="w-100"
                // loader={<h4></h4>}
              >
                <Row className="budgets-card-wrapper w-100">
                  {teams.map((team, index) => (
                    <TeamsCard
                      teams={team}
                      key={`teams-${index}`}
                      onDelete={handleDeleteModal}
                      onEdit={handleEditModal}
                    />
                  ))}
                </Row>
              </InfiniteScroll>
            </Container>
          </div>
        )}
      </>
    );
  };

  const show = !!teams?.length || (isFiltered.current && !teams?.length);
  return (
    <div className="budgets-wrapper w-100">
      <PendingOnboardingNotice />

      <TopBar
        showFilter={show}
        showBarSearch={show}
        withOutSearch
        searchVal={search}
        setSearchVal={setSearch}
        inputPlaceholder="search for teams"
        filterData={filterData}
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        handleFilterApply={handleFilter}
        withDate
      />

      {loading && !filtered ? <Loading isPage color="#D28B28" /> : teamList()}

      <BeneficiariesModal isOpen={isOpen == 1} toggleHandler={handleClose} />

      <TeamsModal
        isOpen={isOpen == 2 || selectedTeam}
        handleClose={handleClose}
        selectTeam={selectedTeam}
        Update={isUpdate}
      />

      <Modal show={isDelete} centered dialogClassName="custom-dialog">
        <ConfirmDialog
          title="Delete Team"
          subTitle="Are you sure you want to delete this team? This action cannot be undone."
          onConfirm={handleDeleteClick}
          loading={deleteLoading}
          onCancel={() => handleDeleteModal(null)}
        />
      </Modal>
    </div>
  );
};

export default Teams;
