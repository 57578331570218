export const FETCH_CATEGORY_REQUEST = 'FETCH_CATEGORY_REQUEST';
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS';
export const FETCH_CATEGORY_ERROR = 'FETCH_CATEGORY_ERROR';

export const FETCH_CATEGORIZATION_RULES_REQUEST = 'FETCH_CATEGORIZATION_RULES_REQUEST';
export const FETCH_CATEGORIZATION_RULES_SUCCESS = 'FETCH_CATEGORIZATION_RULES_SUCCESS';
export const FETCH_CATEGORIZATION_RULES_ERROR = 'FETCH_CATEGORIZATION_RULES_ERROR';

export const CREATE_BULK_CATEGORIES_REQUEST = 'CREATE_BULK_CATEGORIES_REQUEST';
export const CREATE_BULK_CATEGORIES_SUCCESS = 'CREATE_BULK_CATEGORIES_SUCCESS';
export const CREATE_BULK_CATEGORIES_ERROR = 'CREATE_BULK_CATEGORIES_ERROR';

export const EXPORT_CATEGORY_REQUEST = 'EXPORT_CATEGORY_REQUEST';
export const EXPORT_CATEGORY_SUCCESS = 'EXPORT_CATEGORY_SUCCESS';
export const EXPORT_CATEGORY_ERROR = 'EXPORT_CATEGORY_ERROR';

export const FETCH_SINGLE_CATEGORY_REQUEST = 'FETCH_SINGLE_CATEGORY_REQUEST';
export const FETCH_SINGLE_CATEGORY_SUCCESS = 'FETCH_SINGLE_CATEGORY_SUCCESS';
export const FETCH_SINGLE_CATEGORY_ERROR = 'FETCH_SINGLE_CATEGORY_ERROR';

export const FETCH_SINGLE_CATEGORIZATION_RULE_REQUEST =
  'FETCH_SINGLE_CATEGORIZATION_RULE_REQUEST';
export const FETCH_SINGLE_CATEGORIZATION_RULE_SUCCESS =
  'FETCH_SINGLE_CATEGORIZATION_RULE_SUCCESS';
export const FETCH_SINGLE_CATEGORIZATION_RULE_ERROR =
  'FETCH_SINGLE_CATEGORIZATION_RULE_ERROR';

export const CREATE_CATEGORY_REQUEST = 'CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_ERROR = 'CREATE_CATEGORY_ERROR';

export const CREATE_CATEGORIZATION_RULES_REQUEST = 'CREATE_CATEGORIZATION_RULES_REQUEST';
export const CREATE_CATEGORIZATION_RULES_SUCCESS = 'CREATE_CATEGORIZATION_RULES_SUCCESS';
export const CREATE_CATEGORIZATION_RULES_ERROR = 'CREATE_CATEGORIZATION_RULES_ERROR';

export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR';

export const DELETE_CATEGORIZATION_RULES_REQUEST = 'DELETE_CATEGORIZATION_RULES_REQUEST';
export const DELETE_CATEGORIZATION_RULES_SUCCESS = 'DELETE_CATEGORIZATION_RULES_SUCCESS';
export const DELETE_CATEGORIZATION_RULES_ERROR = 'DELETE_CATEGORIZATION_RULES_ERROR';

export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_ERROR = 'UPDATE_CATEGORY_ERROR';

export const UPDATE_CATEGORIZATION_RULES_REQUEST = 'UPDATE_CATEGORIZATION_RULES_REQUEST';
export const UPDATE_CATEGORIZATION_RULES_SUCCESS = 'UPDATE_CATEGORIZATION_RULES_SUCCESS';
export const UPDATE_CATEGORIZATION_RULES_ERROR = 'UPDATE_CATEGORIZATION_RULES_ERROR';

export const GET_CATEGORY_STATISTICS_REQUEST = 'GET_CATEGORY_STATISTICS_REQUEST';
export const GET_CATEGORY_STATISTICS_SUCCESS = 'GET_CATEGORY_STATISTICS_SUCCESS';
export const GET_CATEGORY_STATISTICS_ERROR = 'GET_CATEGORY_STATISTICS_ERROR';

export const RESET_FLAG_CATEGORY = 'RESET_FLAG_CATEGORY';
export const RESET_BLOCK_CATEGORY = 'RESET_BLOCK_CATEGORY';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  fetchCategories: { ...block },
  fetchCategorizationRules: { ...block },
  createBulkCategories: { ...block },
  exportCategories: { ...block },
  getCategoryStatistics: { ...block },
  fetchSingleCategory: { ...block },
  fetchSingleCategorizationRule: { ...block },
  createCategories: { ...block },
  createCategorizationRules: { ...block },
  deleteCategories: { ...block },
  deleteCategorizationRule: { ...block },
  updateCategories: { ...block },
  updateCategorizationRule: { ...block },
};

export const CategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORY_REQUEST:
      return { ...state, fetchCategories: { ...state.fetchCategories, loading: true } };
    case FETCH_CATEGORY_SUCCESS:
      return {
        ...state,
        fetchCategories: {
          ...state.fetchCategories,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case FETCH_CATEGORY_ERROR:
      return {
        ...state,
        fetchCategories: {
          ...state.fetchCategories,
          loading: false,
          error: action.error,
        },
      };

    case FETCH_CATEGORIZATION_RULES_REQUEST:
      return {
        ...state,
        fetchCategorizationRules: { ...state.fetchCategorizationRules, loading: true },
      };
    case FETCH_CATEGORIZATION_RULES_SUCCESS:
      return {
        ...state,
        fetchCategorizationRules: {
          ...state.fetchCategorizationRules,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case FETCH_CATEGORIZATION_RULES_ERROR:
      return {
        ...state,
        fetchCategorizationRules: {
          ...state.fetchCategorizationRules,
          loading: false,
          error: action.error,
        },
      };

    case CREATE_BULK_CATEGORIES_REQUEST:
      return {
        ...state,
        createBulkCategories: { ...state.createBulkCategories, loading: true },
      };
    case CREATE_BULK_CATEGORIES_SUCCESS:
      return {
        ...state,
        createBulkCategories: {
          ...state.createBulkCategories,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CREATE_BULK_CATEGORIES_ERROR:
      return {
        ...state,
        createBulkCategories: {
          ...state.createBulkCategories,
          loading: false,
          error: action.error,
        },
      };

    case EXPORT_CATEGORY_REQUEST:
      return { ...state, exportCategories: { ...state.exportCategories, loading: true } };
    case EXPORT_CATEGORY_SUCCESS:
      return {
        ...state,
        exportCategories: {
          ...state.exportCategories,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case EXPORT_CATEGORY_ERROR:
      return {
        ...state,
        exportCategories: {
          ...state.exportCategories,
          loading: false,
          error: action.error,
        },
      };

    case GET_CATEGORY_STATISTICS_REQUEST:
      return {
        ...state,
        getCategoryStatistics: { ...state.getCategoryStatistics, loading: true },
      };
    case GET_CATEGORY_STATISTICS_SUCCESS:
      return {
        ...state,
        getCategoryStatistics: {
          ...state.getCategoryStatistics,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_CATEGORY_STATISTICS_ERROR:
      return {
        ...state,
        getCategoryStatistics: {
          ...state.getCategoryStatistics,
          loading: false,
          error: action.error,
        },
      };

    case FETCH_SINGLE_CATEGORY_REQUEST:
      return {
        ...state,
        fetchSingleCategory: { ...state.fetchSingleCategory, loading: true },
      };
    case FETCH_SINGLE_CATEGORY_SUCCESS:
      return {
        ...state,
        fetchSingleCategory: {
          ...state.fetchSingleCategory,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case FETCH_SINGLE_CATEGORY_ERROR:
      return {
        ...state,
        fetchSingleCategory: {
          ...state.fetchSingleCategory,
          loading: false,
          error: action.error,
        },
      };

    case FETCH_SINGLE_CATEGORIZATION_RULE_REQUEST:
      return {
        ...state,
        fetchSingleCategorizationRule: {
          ...state.fetchSingleCategorizationRule,
          loading: true,
        },
      };
    case FETCH_SINGLE_CATEGORIZATION_RULE_SUCCESS:
      return {
        ...state,
        fetchSingleCategorizationRule: {
          ...state.fetchSingleCategorizationRule,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case FETCH_SINGLE_CATEGORIZATION_RULE_ERROR:
      return {
        ...state,
        fetchSingleCategorizationRule: {
          ...state.fetchSingleCategorizationRule,
          loading: false,
          error: action.error,
        },
      };

    case CREATE_CATEGORY_REQUEST:
      return { ...state, createCategories: { ...state.createCategories, loading: true } };
    case CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        createCategories: {
          ...state.createCategories,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CREATE_CATEGORY_ERROR:
      return {
        ...state,
        createCategories: {
          ...state.createCategories,
          loading: false,
          error: action.error,
        },
      };

    case CREATE_CATEGORIZATION_RULES_REQUEST:
      return {
        ...state,
        createCategorizationRules: { ...state.createCategorizationRules, loading: true },
      };
    case CREATE_CATEGORIZATION_RULES_SUCCESS:
      return {
        ...state,
        createCategorizationRules: {
          ...state.createCategorizationRules,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CREATE_CATEGORIZATION_RULES_ERROR:
      return {
        ...state,
        createCategorizationRules: {
          ...state.createCategorizationRules,
          loading: false,
          error: action.error,
        },
      };

    case DELETE_CATEGORY_REQUEST:
      return { ...state, deleteCategories: { ...state.deleteCategories, loading: true } };
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        deleteCategories: {
          ...state.deleteCategories,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case DELETE_CATEGORY_ERROR:
      return {
        ...state,
        deleteCategories: {
          ...state.deleteCategories,
          loading: false,
          error: action.error,
        },
      };

    case DELETE_CATEGORIZATION_RULES_REQUEST:
      return {
        ...state,
        deleteCategorizationRule: { ...state.deleteCategorizationRule, loading: true },
      };
    case DELETE_CATEGORIZATION_RULES_SUCCESS:
      return {
        ...state,
        deleteCategorizationRule: {
          ...state.deleteCategorizationRule,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case DELETE_CATEGORIZATION_RULES_ERROR:
      return {
        ...state,
        deleteCategorizationRule: {
          ...state.deleteCategorizationRule,
          loading: false,
          error: action.error,
        },
      };

    case UPDATE_CATEGORY_REQUEST:
      return { ...state, updateCategories: { ...state.updateCategories, loading: true } };
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        updateCategories: {
          ...state.updateCategories,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };

    case UPDATE_CATEGORY_ERROR:
      return {
        ...state,
        updateCategories: {
          ...state.updateCategories,
          loading: false,
          error: action.error,
        },
      };

    case UPDATE_CATEGORIZATION_RULES_REQUEST:
      return {
        ...state,
        updateCategorizationRule: { ...state.updateCategorizationRule, loading: true },
      };
    case UPDATE_CATEGORIZATION_RULES_SUCCESS:
      return {
        ...state,
        updateCategorizationRule: {
          ...state.updateCategorizationRule,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };

    case UPDATE_CATEGORIZATION_RULES_ERROR:
      return {
        ...state,
        updateCategorizationRule: {
          ...state.updateCategorizationRule,
          loading: false,
          error: action.error,
        },
      };

    //reset block with flag and data
    case RESET_BLOCK_CATEGORY:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };

    //rese
    case RESET_FLAG_CATEGORY:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};
