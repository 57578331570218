import CustomDatePicker from 'components/UI/CustomDatePicker';
import CustomInput from 'components/UI/CustomInput';
import FileUpload from 'components/UI/FileUpload';
import { addYears } from 'date-fns';
import dayjs from 'dayjs';
import { Row } from 'react-bootstrap';
import '../../styles.scss';

const SoleProprietorship = ({
  handleChangeBusinessDoc,
  businessDoc,
  setUploadingFile,
  uploadingFile,
  setBusinessDoc,
  setDocuments,
  documents,
}) => {
  return (
    <>
      <div className="information">Company Information</div>
      <Row className="mb-3">
        <CustomDatePicker
          label="Company Registration Date *"
          onChange={(value) =>
            setBusinessDoc({
              ...businessDoc,
              company_registration_date: dayjs(value).format('YYYY-MM-DD'),
            })
          }
          peekNextMonth
          maxDate={addYears(new Date(), 0)}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          placeholderText="Company Registration Date"
          name="company_registration_date"
          selected={
            businessDoc.company_registration_date &&
            dayjs(businessDoc.company_registration_date).toDate()
          }
        />
      </Row>
      <Row className="mb-3">
        <CustomInput
          label="BN Number *"
          placeholder="Enter number"
          type="text"
          name="bnNumber"
          onChange={handleChangeBusinessDoc}
          value={businessDoc?.bnNumber}
          maxLength="11"
        />
      </Row>
      <Row className="mb-3">
        <FileUpload
          label="Certificate of Business Name *"
          placeholder="Upload certificate (PDF, JPEG, PNG)"
          name="file"
          uploadedFile={documents.cac}
          onChange={({ assetCode }) => setDocuments({ ...documents, cac: assetCode })}
          setUploadingFile={setUploadingFile}
          uploadingFile={uploadingFile}
        />
      </Row>
      <Row className="mb-3">
        <FileUpload
          label="CAC-BN1 *"
          placeholder="Upload document (PDF, JPEG, PNG)"
          name="file"
          uploadedFile={documents.cacBn1}
          onChange={({ assetCode }) => setDocuments({ ...documents, cacBn1: assetCode })}
          setUploadingFile={setUploadingFile}
          uploadingFile={uploadingFile}
        />
      </Row>
    </>
  );
};
export default SoleProprietorship;
