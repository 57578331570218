/* eslint-disable no-console */
import { AuthFormHeader } from 'components/Auth';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CheckPasswordStrength from 'components/UI/PasswordStrength';
import { toastError } from 'components/UI/toast';
import React, { useEffect, useMemo, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  setBeneficiaryPassword,
  setInvitedUserPasswordAction,
  signup,
  verifyInvitedUserAction,
} from 'redux/actions/AuthAction';
import { getInvitedBeneficiaryDetails } from 'redux/actions/BeneficiariesAction';
import Layout, { OverflowWrapLayout } from '../Layout';
import CustomPhoneNumberInput from 'components/UI/CustomPhoneNumberInput';
import { Checkbox } from 'antd';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import CustomSelect from 'components/UI/CustomSelect';
import { findCompanyName } from 'redux/actions/UtilsActions';
import { useDebounce } from 'hooks/useDebounce';
import { PlusOutlined } from '@ant-design/icons';

const FindCompany = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    user: { data = {} },
    updatedUser: { loading },
  } = useSelector(({ auth }) => auth);

  const {
    findCompanyName: {
      data: compnayName,
      loading: loadingCompnayName,
      success: successCompnayName,
      error,
    } = {},
  } = useSelector(({ utils }) => utils);

  const [addNewCompany, setAddNewCompany] = useState(false);

  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, 500);
  const [newOptions, setNewOptions] = useState([]);

  const handleSelectChange = (val) => {
    setValue(val);
  };

  // useEffect(() => {
  //   if (roleSuccess && userSuccess) {
  //     history.push('/');
  //   }
  // }, [roleSuccess, userSuccess]);

  // useEffect(() => {
  //   dispatch(resetBlockAuth({ blockType: 'otp' }));
  // }, []);

  const [user, setUser] = useState({
    company: '',
    country: '',
  });

  useEffect(() => {
    const companylists = compnayName?.find((option) =>
      option.name.toLowerCase().includes(debouncedValue.toLowerCase()),
    );
    if (!companylists && debouncedValue?.length > 4)
      dispatch(findCompanyName({ search: debouncedValue?.toLowerCase() }));
  }, [debouncedValue]);

  const mappedDoc = compnayName?.map((item) => item.name);
  const companiesList = useMemo(() => {
    return compnayName?.map((item) => ({
      label: item.name,
      value: item.code,
    }));
  }, [successCompnayName, error, mappedDoc]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (!user.first_name) return toastError('Please enter first name');
    if (!user.last_name) return toastError('Please enter last name');
    if (
      !isValidPhoneNumber(String(user.internationalFormat)) ||
      !validateNigeriaNumber(String(user.internationalFormat))
    )
      return toastError('Please enter a valid phone number');
    if (!user.company) return toastError('Please enter your company name');

    if (form.checkValidity() === false) {
      return;
    }
    let payloadData = {
      firstName: user.first_name,
      lastName: user.last_name,
      phoneNumber: {
        countryCode: user.countryCode,
        localFormat: user.localFormat,
      },
    };

    if (!data?.user?.company) {
      // debouncedValue
      payloadData.company = user.company?.label;
      if (user.company?.value) payloadData.lookedUpCompany = user.company?.value;
    }

    dispatch(update_user_profile(payloadData));
    return false;
  };

  const handleNoOptionsMessage = () => {
    if (value.trim() !== '' && value.length > 4) {
      return loadingCompnayName
        ? 'Searching please wait...'
        : 'No options found. Press Enter to continue.';
    }
    return 'Type to search...';
  };

  const handleKeyDown = (event) => {
    event.stopPropagation();
    if (event.key === 'Enter' && value.trim() !== '') {
      // Create a new option and update the options list
      const newOption = { label: value, value: '' };
      setNewOptions([newOption]);
      setUser({ ...user, company: newOption });
      setValue('');
    }
  };

  const availableOptions = (companiesList || []).concat(newOptions);

  const findCompany = (
    <>
      <Row className="mb-3">
        <CustomSelect
          label="Where is your company registered?"
          name="country"
          placeholder="Select country"
          onChange={(val) => setUser({ ...user, country: val })}
          value={user.country}
          options={[]}
          // isLoading={loadingCompnayName && !debouncedValue}
          // isDisabled={loadingCompnayName && !debouncedValue}
        />
      </Row>

      <Row className="mb-3 d-flex align-items-end">
        <div className="w-75">
          <CustomSelect
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            noOptionsMessage={handleNoOptionsMessage}
            isClearable
            // noOptionsMessage={() => null}
            label="Legal name or registration number"
            name="company"
            onclickExtended={() => setAddNewCompany(true)}
            extendComponent={
              <span className="d-flex align-items-center gap-2">
                <PlusOutlined />
                Add company manually
              </span>
            }
            placeholder="Company’s legal name"
            onInputChange={handleSelectChange}
            onChange={(val) => setUser({ ...user, company: val })}
            value={user.company}
            onKeyDown={handleKeyDown}
            options={availableOptions}
            isLoading={loadingCompnayName && !debouncedValue}
            isDisabled={loadingCompnayName && !debouncedValue}
          />
        </div>
        <botton className="primary-button cursor">Search</botton>
      </Row>
    </>
  );

  const createCompany = (
    <>
      <Row className="mb-3">
        <CustomInput
          label="Legal business name"
          placeholder="Legal business name"
          type="text"
          name="businessName"
          // onChange={handleChange}
          // value={authData.email}
          required
        />
      </Row>

      <Row className="mb-3">
        <CustomSelect
          label="Registration type"
          name="type"
          placeholder="Select type"
          onChange={(val) => setUser({ ...user, country: val })}
          value={user.country}
          options={[]}
          // isLoading={loadingCompnayName && !debouncedValue}
          // isDisabled={loadingCompnayName && !debouncedValue}
        />
      </Row>

      <Row className="mb-3">
        <CustomInput
          label="Registration number (RC number)"
          placeholder="Enter number"
          type="text"
          name="rcNumber"
          // onChange={handleChange}
          // value={authData.email}
          required
        />
      </Row>

      <Row className="mb-3">
        <CustomInput
          label="Legal business address"
          placeholder="Plot 1B, Block 129, Jide Sawyerr Drive"
          type="text"
          name="businessAddress"
          // onChange={handleChange}
          // value={authData.email}
          required
        />
      </Row>

      <Row className="mb-3">
        <CustomSelect
          label="Country"
          name="country"
          placeholder="Select country"
          onChange={(val) => setUser({ ...user, country: val })}
          value={user.country}
          options={[]}
          // isLoading={loadingCompnayName && !debouncedValue}
          // isDisabled={loadingCompnayName && !debouncedValue}
        />
      </Row>

      <Row className="mb-3">
        <CustomInput
          label="City"
          placeholder="Enter city"
          type="text"
          name="city"
          // onChange={handleChange}
          // value={authData.email}
          required
        />
      </Row>

      <Row className="mb-4 align-items-center">
        <CustomSelect
          placeholder="Select a state"
          type="text"
          name="state"
          // value={card.state}
          label="State / Province"
          // isDisabled={loadingState}
          options={[]}
          // onChange={(value) => handleChange({ name: 'state', value: value })}
          md={6}
        />
        <CustomInput
          label="Postcode"
          name="postCode"
          type="text"
          placeholder="100001"
          // onChange={({ target: { name, value } }) => handleChange({ name, value })}
          // value={card.postCode}
          maxLength="20"
          md={6}
        />
      </Row>

      <CustomButton
        type="submit"
        fullWidth

        // onClick={handleSubmit}
        // loading={loading}
        // disabled={loading}
      >
        Confirm company details
      </CustomButton>
    </>
  );

  const title = addNewCompany ? 'Provide company information' : 'Find your company';
  const subtitle = addNewCompany
    ? 'Ensure the information below matches your Corporate Affairs Commission (CAC) certificate of registration.'
    : `Search your company by legal name or registration number. We'll auto-fill the required information.`;
  return (
    <Layout>
      <AuthFormHeader
        backButton={addNewCompany}
        goBack={() => setAddNewCompany(false)}
        titlle={title}
        subtitle={subtitle}
      />
      <OverflowWrapLayout>
        <Form className="w-100">{addNewCompany ? createCompany : findCompany}</Form>
      </OverflowWrapLayout>
    </Layout>
  );
};

export default FindCompany;
