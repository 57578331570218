import React from 'react';
const TotalAmount = ({ type, title, subTitle, widthLink, handleClick, onClick }) => {
  return (
    <div className="total-amount-wrapper" onClick={onClick}>
      <p className="card-type-title">{type}</p>
      <h2 className="card-title-amount">{title}</h2>
      <p className="card-disabled-title">{subTitle}</p>
      <span className="card-disabled-link" onClick={handleClick}>
        {widthLink}
      </span>
    </div>
  );
};
export default TotalAmount;
