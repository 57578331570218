import React from 'react';
import cs from 'classnames';
import './styles.scss';

const SpaceWrapper = ({ className, children, ...res }) => {
  return (
    <div className={cs('space-wrapper', { [className]: className })} {...res}>
      {children}
    </div>
  );
};
export default SpaceWrapper;
