import {
  CheckOutlined,
  EditOutlined,
  MailOutlined,
  DeleteOutlined,
  DownOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { Checkbox } from 'antd';
import CustomButton from 'components/UI/CustomButton';
import CustomPopover from 'components/UI/Popover';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  deleteInvitedDirector,
  getInvitedDirectors,
  resendOnboardingInvite,
} from 'redux/actions/CompaniesAction';
import { ReactComponent as Mail } from '../../../assets/icons/mail.svg';

const OnboardingNotice = ({
  businessStep,
  localStep,
  setBusinessStep,
  setLocalStep,
  setCheckReviewed,
  setIsPopoverOpen,
  isPopoverOpen,
  setInviteDirectors,
  setEditAddress,
}) => {
  const {
    getInvitedDirectors: { data: invitedDirectors },
    deleteInvitedDirector: { success },
    getCompany: { data: companyData = {} },
  } = useSelector(({ companies }) => companies);
  const dispatch = useDispatch();
  const [selectdIndex, setSelectedIndex] = useState('');

  const handleTogglePopover = (index) => {
    setIsPopoverOpen(!isPopoverOpen);
    setSelectedIndex(index);
  };

  const handleDelete = (code) => {
    dispatch(deleteInvitedDirector(code));
  };

  const handleResendLink = (invite) => {
    dispatch(resendOnboardingInvite({ invite }));
  };

  useEffect(() => {
    if (success) dispatch(getInvitedDirectors());
  }, [success]);

  const Actions = ({ items }) => {
    const { code, name, email, note } = items;

    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() => {
            handleResendLink(code);
          }}
        >
          <ReloadOutlined /> Resend invitation link
        </div>
        <div
          className="actionLink"
          onClick={() => {
            setBusinessStep(3);
            setInviteDirectors({ name, email, note, code });
          }}
        >
          <EditOutlined /> Update request info
        </div>

        <div
          className="actionLink"
          onClick={() => {
            handleDelete(code);
          }}
        >
          <DeleteOutlined /> Delete request
        </div>

        <div
          className="actionLink"
          onClick={() => {
            setBusinessStep(3);
          }}
        >
          <MailOutlined />
          Request from another owner
        </div>
        {/* <div
          className="actionLink"
          onClick={() => {
            setBusinessStep(2);
          }}
        >
          <FileTextOutlined /> Add information yourself
        </div> */}
      </div>
    );
  };

  return (
    <>
      {businessStep === 0 && localStep === 3 ? (
        <div className="subbusiness-section">
          <div className="d-flex justify-content-between align-items-center subbusiness">
            <p className="subbusiness-list mt-3 text-orange">
              Contact details added <CheckOutlined className="subbusiness__check-icon" />
            </p>
            <p
              className="d-flex align-items-center cursor"
              onClick={() => {
                setLocalStep(2);
                setEditAddress(true);
              }}
            >
              <EditOutlined className="mr-3" /> Edit
            </p>
          </div>
          <div className="border-top pt-3">
            <p className="stage-text">Incorporation details</p>
          </div>
        </div>
      ) : null}

      {[1, 2].includes(businessStep) && localStep === 3 ? (
        <div className="business-notice mt-3">
          {[1].includes(businessStep) && (
            <>
              <h6>NOTE!</h6>
              <p>
                The information required here is meant for a registered major shareholder
                (atleast 5%) of your business. However, you can request this information
                directly from the business owner if you don’t have them.{' '}
              </p>

              <CustomButton
                onClick={() => setBusinessStep(2)}
                fullWidth
                style={{ height: '45px' }}
                className="fs-6"
              >
                Continue
              </CustomButton>
            </>
          )}

          <CustomButton
            fullWidth
            withoutBg
            style={{ height: '45px' }}
            className="mt-1 text-black fs-6 d-flex justify-content-center align-items-center"
            onClick={() => setBusinessStep(3)}
          >
            <Mail className="mr-3" /> Request details
          </CustomButton>
        </div>
      ) : null}

      {[4].includes(businessStep) && localStep === 3 ? (
        <div className="subbusiness-section">
          <div className="d-flex justify-content-between align-items-center subbusiness mt-3">
            <p className="stage-text">Business Information</p>
            <p className="text-gray">3 of 3 provided</p>
          </div>
          <div className="subbsusiness">
            <p className="subbusiness-list text-gray">
              Company details added <CheckOutlined className="subbusiness__check-icon" />
            </p>
            <p className="subbusiness-list text-gray">
              Company contact added <CheckOutlined className="subbusiness__check-icon" />
            </p>
            <p className="subbusiness-list text-gray">
              Certificate of Incorporation added{' '}
              <CheckOutlined className="subbusiness__check-icon" />
            </p>
          </div>
          <div className="border-top pt-3">
            <div className="d-flex justify-content-between align-items-center subbusiness">
              <p className="stage-text">Business Owner</p>
              <p className="text-gray mr-3 subbusiness-list">
                <span className="mt-1">{`${invitedDirectors?.length} invited`}</span>
              </p>
            </div>
            {invitedDirectors.map((items, index) => (
              <div
                key={index}
                className="d-flex justify-content-between align-items-center subbusiness mb-2"
              >
                <div className="subbusiness-list text-gray mt-2">
                  <span className="mr-3">Contact infromation</span>
                  <div className="d-flex justify-content-center align-items-center mail">
                    <Mail className="mail-icon" /> Requested
                  </div>
                </div>
                <span>
                  <CustomPopover
                    clickOutside={handleTogglePopover}
                    showPopover={index === selectdIndex}
                    content={<Actions index={selectdIndex} items={items} />}
                  >
                    <div
                      onClick={() => handleTogglePopover(index)}
                      className="text-gray mr-3 subbusiness-list cursor"
                    >
                      <span className="mr-3">Edit</span>
                      <DownOutlined />
                    </div>
                  </CustomPopover>
                </span>
              </div>
            ))}

            <p className="subbusiness-list text-gray my-3">
              <Checkbox
                onChange={({ target: { checked } }) => setCheckReviewed(checked)}
              />
              <span className="ms-2 fs-6">
                I confirm all the information provided here are accurate{' '}
              </span>
            </p>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default OnboardingNotice;
