import React, { useState } from 'react';
import '../styles.scss';
import { ArrowLeft, BankIcon, BuildingIcon, ListIcon, User3Icon } from 'assets/icons';
import BusinessKycTabs from '../components/BusinessKycTabs';
import BusinessKycTabsView from '../components/BusinessKycTabView';
import BusinessDetails from './BusinessDetails';
import BusinessOwner from './BusinessOwner';
import AdditionalDetails from './AdditionalDetails';
import { useHistory } from 'react-router-dom';

const tabs = [
  { name: 'Business details', icon: <BuildingIcon />, key: 'businessDetails' },
  { name: 'Business owners', icon: <User3Icon />, key: 'businessOwner' },
  { name: 'Additional details', icon: <ListIcon />, key: 'additionalDetails' },
];

const BusinessKyc = () => {
  const { push } = useHistory();
  const [key, setKey] = useState('businessDetails');

  const activeIndex = tabs?.findIndex((tab) => tab.key === key);

  const handleSelect = (tabKey) => {
    setKey(tabKey);
  };

  return (
    <section className="onboarding-section">
      <div className="business-kyc-section">
        <div className="back-btn" onClick={() => push('/get-started')}>
          <ArrowLeft color="#D28B28" /> <div>Back</div>
        </div>
        <div className="business-kyc-title">
          <h1>Complete Business KYC</h1>
          <p>
            Get ready to use Bujeti by verifying a few organisational details about your
            company.
          </p>
        </div>

        <div className="forms-tab-wrapper">
          <div>
            <BusinessKycTabs tabs={tabs} active={key} onSelect={handleSelect} />
            <p className="text-xs mt-4" style={{ width: ' 242px', color: '#79716B' }}>
              Your documents are stored securely and in compliance with NDPR regulations.
              <br />
              <br />
              Your progress is automatically saved.
            </p>
          </div>

          <div className="business-tab-view">
            <BusinessKycTabsView
              index={activeIndex}
              tabs={[
                <BusinessDetails key="businessDetails" />,
                <BusinessOwner key="businessOwner" />,
                <AdditionalDetails key="additionalDetails" />,
              ]}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BusinessKyc;
