import { ReactComponent as ArrowUp } from 'assets/icons/arrowUp-green.svg';
import classNames from 'classnames';
import TotalAmount from 'pages/Dashboard/Overview/components/TotalAmount';
import { useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'components/UIHooks/Rechart';
import { getCurrency } from 'utils/helper';
import { numFormatter } from 'utils/utility';

const yAxisStyle = {
  fontSize: 13,
  fill: '#696C80',
};
const gridStyle = {
  stroke: '#ccc',
  strokeWidth: 0.5,
};

const COLORS = ['#885A1A', '#AF7421', '#D28B28', '#DEA250', '#ECCC9E', '#F4E0C5'];

const BASE_COLOR = '#E7E5E499';

const noChartData = [
  { name: 'No data', amount: 0, previousPeriod: 0 },
  { name: 'No data', amount: 0, previousPeriod: 0 },
  { name: 'No data', amount: 0, previousPeriod: 0 },
  { name: 'No data', amount: 0, previousPeriod: 0 },
  { name: 'No data', amount: 0, previousPeriod: 0 },
  { name: 'No data', amount: 0, previousPeriod: 0 },
];
const BarChartMultiple = ({
  size = 'big',
  data,
  totalPercentage,
  totalSpent,
  amountType,
  showTotalSpent = true,
  currency = 'NGN',
  noDataPlaceholder,
  extra = { period: 'This year', previousPeriod: 'Last year' },
  groupBy = null,
  compare = false,
}) => {
  let chartData = data.length
    ? data.map(({ amount, previousPeriod, ...rest }) => ({
        amount: amount / 100,
        previousPeriod: Number(previousPeriod) / 100,
        ...rest,
      }))
    : noChartData;

  if (groupBy) {
    const elements = {};
    chartData.forEach(({ name, ...rest }) => {
      if (!elements[name]) elements[name] = { name };
      const property = rest[groupBy];
      elements[name][property] = rest['amount'];
    });
    chartData = Object.values(elements);
  }

  const barColors = chartData.map((item, index) =>
    data.length ? COLORS[index] : BASE_COLOR,
  );

  const CustomBar = ({ x, y, width, height, index, type, fill, ...rest }) => {
    const fillColor = type ? barColors[(type === 1 ? 4 : 0) % barColors.length] : fill;
    return (
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={fillColor}
        stroke={fillColor}
        rx={5 / 2}
      />
    );
  };

  const CustomTooltip = ({ active, payload, label, groupBy = null }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip bg-white"
          style={{ fontSize: yAxisStyle.fontSize }}
        >
          <p className="label">{label}</p>
          {groupBy ? (
            payload.map((element, index) => {
              return (
                <p className="label-data" key={index}>
                  <span>{element.name}</span>
                  <CurrencyFormat
                    prefix={getCurrency(currency)}
                    value={numFormatter(element?.value)}
                    displayType="text"
                    thousandSeparator={true}
                    isNumericString
                  />
                </p>
              );
            })
          ) : (
            <>
              <p className="label-data">
                <span>Transactions: {payload[0]?.payload?.transactionsCount}</span>
              </p>
              <p className="label-data">
                <span>{payload[0]?.name}:</span>
                {
                  <CurrencyFormat
                    prefix={getCurrency(currency)}
                    value={numFormatter(payload[0]?.value)}
                    displayType="text"
                    thousandSeparator={true}
                    isNumericString
                  />
                }
              </p>
              <p className="label-data" style={{ color: payload[1].fill }}>
                <span>{payload[1]?.name}:</span>
                {
                  <CurrencyFormat
                    prefix={getCurrency(currency)}
                    value={numFormatter(payload[1]?.value)}
                    displayType="text"
                    thousandSeparator={true}
                    isNumericString
                  />
                }
              </p>
            </>
          )}
        </div>
      );
    }

    return null;
  };

  const [opacity, setOpacity] = useState(1);

  const handleMouseEnter = (o) => {
    const { dataKey } = o;

    setOpacity({
      opacity: { ...opacity, [dataKey]: 0.1 },
    });
  };

  const handleMouseLeave = (o) => {
    const { dataKey } = o;

    setOpacity({
      opacity: { ...opacity, [dataKey]: 1 },
    });
  };

  const formatXAxis = (value) =>
    `${getCurrency(currency)}${numFormatter(value, true, true)}`;

  const bars = () => {
    const t = chartData.map((element, index) => {
      if (index !== 0) return;
      const { name, ...rest } = element;
      const __keys = Object.keys(rest);
      __keys.forEach((entity) => {
        if (!element[entity]) element[entity] = 0;
      });
      return __keys.map((_element, jindex) => {
        return (
          <Bar
            name={_element}
            key={`${index}-${jindex}`}
            dataKey={_element}
            stackId={'a'}
            minPointSize={1}
            fill={COLORS[jindex]}
          />
        );
      });
    });
    return t;
  };

  return (
    <div
      className={classNames(
        'position-relative w-100 h-100 d-flex flex-column justify-content-center',
      )}
    >
      {showTotalSpent && (
        <div className="chart-header">
          <TotalAmount
            type={totalSpent > 0 && amountType}
            title={
              <div className="d-flex align-items-center">
                <CurrencyFormat
                  prefix={getCurrency(currency)}
                  value={numFormatter((totalSpent || 0) / 100)}
                  displayType="text"
                  thousandSeparator={true}
                  isNumericString
                />
                <span className="arrow-wrapper-percentage active ms-2">
                  <ArrowUp className="mr-3" /> {totalPercentage}%
                </span>
              </div>
            }
            subTitle={noDataPlaceholder && noDataPlaceholder}
          />
        </div>
      )}

      <ResponsiveContainer
        width="100%"
        height={
          size === 'small' ? 100 : size === 'big' ? 210 : size === 'full' ? '100%' : size
        }
      >
        <ComposedChart
          data={chartData}
          width={600}
          height={300}
          margin={{ top: 15, right: 0, left: 20, bottom: 0 }}
          style={{ cursor: 'pointer' }}
          orientation="left"
          barGap={4}
          barSize={20}
        >
          <CartesianGrid
            strokeDasharray="5 5"
            stroke={gridStyle.stroke}
            strokeWidth={gridStyle.strokeWidth}
            vertical={false}
          />
          <YAxis
            type="number"
            tickFormatter={formatXAxis}
            tick={yAxisStyle}
            tickLine={false}
            axisLine={false}
            yAxisId={'left'}
            domain={[0, 'dataMax']}
            label={{
              value: 'Transactions volume',
              angle: -90,
              position: 'insideLeft',
              dx: -12,
              dy: 80,
              fill: '#79716b99',
            }}
            allowDataOverflow={true}
          />
          <YAxis
            type="number"
            tickFormatter={(value) => Math.round(value)}
            tick={yAxisStyle}
            tickLine={false}
            axisLine={false}
            yAxisId={'right'}
            orientation="right"
            allowDataOverflow={true}
            // label={{ value: 'Transactions Count', angle: -90, position: 'insideRight', dx: -10, dy: -60, fill: "#79716b99" }}
          />

          <XAxis
            type="category"
            dataKey="name"
            tick={false}
            axisLine={false}
            tickLine={true}
          />
          <Legend
            wrapperStyle={{ fontSize: 12, top: 0, marginRight: 20 }}
            align="right"
            iconType="circle"
            iconSize={10}
            verticalAlign="top"
          />
          <Tooltip
            cursor={{ fill: '#E7E5E499' }}
            content={<CustomTooltip groupBy={groupBy} />}
          />

          {groupBy ? (
            bars()
          ) : (
            <>
              {compare ? (
                <Bar
                  name={extra.previousPeriod}
                  dataKey="previousPeriod"
                  shape={<CustomBar type={1} />}
                  minPointSize={1}
                  fill={COLORS[3]}
                  yAxisId={'left'}
                />
              ) : null}
              <Bar
                name={extra.period}
                dataKey={'amount'}
                stackId={'a'}
                shape={<CustomBar type={2} />}
                minPointSize={1}
                fill={COLORS[0]}
                yAxisId={'left'}
              />
            </>
          )}
          <Line
            key={'dotted-line' + totalSpent}
            type="linear"
            dataKey="transactionsCount"
            stroke="#d28b28"
            strokeWidth={1.5}
            yAxisId={'right'}
            name="Transactions"
            dot={{ r: 3 }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartMultiple;
