import MonoConnect from '@mono.co/connect.js';
import { ReactComponent as Card } from 'assets/icons/CardLogo.svg';
import { ReactComponent as Bank } from 'assets/icons/bank.svg';
import cs from 'classnames';
import CustomButton from 'components/UI/CustomButton';
import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import NotificationModal from '../../../NotificationModal/NotificationModal';
import '../styles.scss';

const Balance = ({ setActiveCard, handleSubmit }) => {
  const [showErrorModal, setShowErrorModal] = useState(false);

  const toggleModal = () => setShowErrorModal(!showErrorModal);

  const dataArray = [
    {
      image: <Bank />,
      headerText: 'Get a bank account',
      subHeaderText: 'Wire your fund to this account',
      tag: 'VIRTUAL',
    },
    {
      image: <Card />,
      headerText: 'Debit/Credit Card',
      subHeaderText: 'Fund whenever you feel like',
      tag: '',
    },
    {
      image: <Bank />,
      headerText: 'Link your bank account',
      subHeaderText: 'Better for automated top up',
      tag: '',
    },
  ];
  const [active, setActive] = useState(0);

  const monoConnect = React.useMemo(() => {
    const monoInstance = new MonoConnect({
      onClose: () => {
        console.log('Widget closed');
      },
      onLoad: () => {
        console.log('Widget loaded successfully');
      },
      onSuccess: ({ code }) => {
        console.log(`Linked successfully`);
        monoInstance.close();
      },
      key: process.env.REACT_APP_MONO_CONNECT_KEY,
    });

    monoInstance.setup();

    return monoInstance;
  }, []);
  const onCardPress = (item) => () => {
    const { headerText } = item;
    switch (headerText) {
      case 'Get a bank account':
        return setActive(0);
      case 'Debit/Credit Card':
        return setActive(1);
      default:
        return setActive(2);
    }
  };

  const handleContinue = () => {
    if (active === 2) {
      //Link your bank account selected
      //toggleModal();
      monoConnect.open();
    } else {
      handleSubmit(active);
      setActiveCard(active === 0 ? 0 : 2);
    }
  };

  return (
    <>
      {showErrorModal && (
        <NotificationModal
          handleClose={toggleModal}
          title="Uh oh!"
          message="Coming soon..."
        />
      )}
      <h2 className="card-title w-100">Fund your balance</h2>
      <span className="subHeaderText">
        How would you like to add funds for your budgets?
      </span>
      <Row className="cardWrapper">
        {dataArray.map((item, index) => {
          return (
            <div
              key={index}
              className={cs('balance-card-wrapper', {
                ['active-card-bg']: active === index,
              })}
              onClick={onCardPress(item)}
            >
              <div className="pe-4 ps-2">{item.image}</div>
              <div>
                <div className="d-flex align-items-center">
                  <span className="balance-title">{item.headerText}</span>
                  {item.tag && (
                    <span
                      className={cs('balance-tag', {
                        ['dark-bg']: item.tag !== 'VIRTUAL',
                      })}
                    >
                      {item.tag}
                    </span>
                  )}
                </div>
                <span className="balance-sub-title">{item.subHeaderText}</span>
              </div>
            </div>
          );
        })}
      </Row>

      <CustomButton className="mb-4" onClick={handleContinue} fullWidth={true}>
        Continue
      </CustomButton>
    </>
  );
};
export default Balance;
