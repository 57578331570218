import OnboardingSaved from 'components/Onboarding/BusinessDetails/OnboardingSaved';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import FileUpload from 'components/UI/FileUpload';
import Loading from 'components/UI/Loading';
import Saving from 'components/UI/ModalSaving/Saving';
import { toastError } from 'components/UI/toast';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRejectedDocumentDetails,
  reSubmitRejectedDocumentDetails,
  reSubmitRejectedIsCompleted,
} from 'redux/actions/CompaniesAction';
import { getDocmentTypes, idType, validateRCNumber } from 'utils/helper';
import './styles.scss';

const initailState = {
  docsCode: '',
  number: '',
  type: '',
  url: undefined,
  rcNumber: '',
};
const ResubmitDocument = ({
  toggleHandler,
  setSelectStep,
  moveToAddBeneficiaries,
  setResubmitIsCompleted,
}) => {
  const dispatch = useDispatch();

  const {
    getBeneficiaries: { data: beneficiariesData = {} },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const {
    getRejectedDocumentDetails: { data: rejectedDocuments = {} },
    reSubmitRejectedDocumentDetails: { loading, success },
    reSubmitRejectedIsCompleted: { success: successIsCompleted },
  } = useSelector(({ companies }) => companies);
  const { docs: documents = [] } = rejectedDocuments;

  const {
    getBudget: { data: budgetData = {}, success: loadingSuccess },
  } = useSelector(({ budgets }) => budgets);

  const [uploadingFile, setUploadingFile] = useState(false);
  const [idName, setIdName] = useState('ID Number');
  const [disableIdNumber, setDisableIdNumber] = useState(true);
  const [totalDoc, setTotalDoc] = useState(0);
  const [totalSubmited, setTotalSubmited] = useState(0);

  const [documentData, setDocumentData] = useState(initailState);

  const [isLoadingDoc, setIsLoadingDoc] = useState(true);

  useEffect(() => {
    if (documents.length) setTotalDoc(documents.length);
  }, [documents]);

  const showIdNumber = (val) => {
    setIdName(val.label + ' Number');
    setDisableIdNumber(false);
  };

  const handleChangeDocumentDetails = ({ target: { name, value } }) => {
    setDocumentData({ ...documentData, [name]: value });
  };

  const handleContinue = (index) => {
    if (
      documentData?.rcNumber &&
      documentData?.rcNumber.length > 0 &&
      !validateRCNumber(documentData?.rcNumber)
    )
      return toastError('Please enter a valid RC number-RC0000000');
    const payload = {
      docsCode: documents[index].code,
      newDocument: {
        number: documentData.number || documentData.rcNumber || undefined,
        type: documentData?.type?.value ?? undefined,
        url: documentData.url,
      },
    };

    dispatch(reSubmitRejectedDocumentDetails(payload));
  };

  useEffect(() => {
    if (documents?.length) setIsLoadingDoc(false);

    return () => {
      setIsLoadingDoc(true);
      setTotalSubmited(0);
    };
  }, [documents]);

  useEffect(() => {
    if (success) {
      setTotalSubmited((totalSubmited) => totalSubmited + 1);
      setDocumentData(initailState);
    }
  }, [success]);

  const hasBeneficiaries = beneficiariesData?.beneficiaries?.length > 0;
  const hasBudgets = budgetData?.budgets?.length > 0;
  const buttonLabel = !hasBeneficiaries
    ? 'Add Members'
    : hasBudgets
    ? 'Close'
    : 'Create a budget';

  const closeOnFinish = () => {
    if (!hasBeneficiaries) {
      moveToAddBeneficiaries();
      setSelectStep(2);
    } else if (hasBudgets) {
      toggleHandler();
      localStorage.setItem('setStep', JSON.stringify(3));
      setSelectStep(4);
    } else {
      toggleHandler();
      localStorage.setItem('setStep', JSON.stringify(2));
      setSelectStep(3);
    }
  };

  useEffect(() => {
    dispatch(getRejectedDocumentDetails());
  }, []);

  useEffect(() => {
    if (totalSubmited + 1 === totalDoc + 1 && totalDoc !== 0) {
      dispatch(reSubmitRejectedIsCompleted());
      setResubmitIsCompleted(true);
    }
  }, [totalSubmited]);

  return (
    <div>
      {isLoadingDoc && <Saving />}
      {!isLoadingDoc && (
        <div className="card-modal-body ">
          {totalSubmited + 1 === totalDoc + 1 ? (
            <OnboardingSaved onClick={closeOnFinish} buttonName={buttonLabel} />
          ) : (
            <>
              <div className="d-flex justify-content-between align-items-center">
                <h2 className="card-title w-100">Edit Details</h2>
                <div>
                  {totalSubmited + 1} of {totalDoc}
                </div>
              </div>

              {documents?.map((document, index) => (
                <div key={index}>
                  {index === totalSubmited && (
                    <>
                      {[
                        'utility-Bill',
                        'incorp_C',
                        's_Doc',
                        'C_of_T',
                        'Scum_L_C',
                        'cacITForm1',
                        'cac',
                      ].includes(document.type) && (
                        <Row className="mb-3">
                          <FileUpload
                            label={getDocmentTypes(document.type)}
                            placeholder={`Upload a ${getDocmentTypes(
                              document.type,
                            )} (PDF, JPEG, PNG)`}
                            name="file"
                            onChange={({ url }) => setDocumentData({ url })}
                            // onChange={({ url }) => setUtilityBill(url)}
                            setUploadingFile={setUploadingFile}
                            uploadingFile={uploadingFile}
                          />
                        </Row>
                      )}

                      {['rcNumber'].includes(document.type) && (
                        <>
                          <Row className="mb-3">
                            <CustomInput
                              label={getDocmentTypes(document.type)}
                              placeholder="Enter number"
                              type="text"
                              name="rcNumber"
                              onChange={handleChangeDocumentDetails}
                              value={documentData?.rcNumber}
                              maxLength="9"
                            />
                          </Row>
                        </>
                      )}
                      {['bnNumber'].includes(document.type) && (
                        <>
                          <Row className="mb-3">
                            <CustomInput
                              label={getDocmentTypes(document.type)}
                              placeholder="Enter number"
                              type="text"
                              name="number"
                              onChange={handleChangeDocumentDetails}
                              value={documentData?.number}
                              maxLength="11"
                            />
                          </Row>
                        </>
                      )}
                      {['bvn'].includes(document.type) && (
                        <Row className="mb-3">
                          <CustomInput
                            label="BVN"
                            placeholder="Enter number"
                            type="text"
                            name="number"
                            onChange={handleChangeDocumentDetails}
                            value={documentData?.number}
                            maxLength="11"
                          />
                        </Row>
                      )}
                      {['nin', 'ip', 'vi', 'dl'].includes(document.type) && (
                        <>
                          <Row className="mb-3 align-items-center">
                            <CustomSelect
                              label="ID Type"
                              name="idType"
                              placeholder="Select ID Type"
                              onChange={(val) => {
                                setDocumentData({ ...documentData, type: val });
                                showIdNumber(val);
                              }}
                              // value={documentData.type}
                              options={idType}
                            />
                          </Row>
                          <Row className="mb-3 align-items-center">
                            <CustomInput
                              label={idName}
                              placeholder={'Enter ' + idName}
                              type="text"
                              name="number"
                              onChange={handleChangeDocumentDetails}
                              value={documentData?.number}
                              maxLength="11"
                              disabled={disableIdNumber}
                            />
                          </Row>
                          <Row className="mb-3">
                            <FileUpload
                              label="Copy of ID"
                              placeholder="Upload certificate (PDF, JPEG, PNG)"
                              name="file"
                              onChange={({ url }) =>
                                setDocumentData({ ...documentData, url })
                              }
                              setUploadingFile={setUploadingFile}
                              uploadingFile={uploadingFile}
                            />
                          </Row>
                        </>
                      )}
                      <CustomButton
                        onClick={() => handleContinue(totalSubmited)}
                        fullWidth={true}
                        disabled={loading || uploadingFile}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        Submit
                        {loading ? <Loading color="#D28B28" size={30} /> : ''}
                      </CustomButton>
                    </>
                  )}
                </div>
              ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ResubmitDocument;
