import React from 'react';
import './styles.scss';
import { metal3d } from 'assets/images';

const QuickActionBanner = ({ onClick }) => {
  return (
    <div className="quickaction_banner-wrapper">
      <img src={metal3d} alt="metal" className="image-banner" />
      <div className="banner-text">
        <h1>Get started now and unlock basic features</h1>
        <p>
          Upload your CAC Certificate of Incorporation for basic access. Unlock full
          features later with additional documents.
        </p>
      </div>
      <div className="actions pt-2">
        <button onClick={onClick} className="btn-border btn">
          Upload certificate
        </button>
        <button className="btn">Learn more</button>
      </div>
    </div>
  );
};

export default QuickActionBanner;
