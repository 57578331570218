import './custom.styles.scss';

const CustomDrawer = ({ show, children }) => {
  return (
    <section className={show ? 'show-drawer custom-drawer' : 'custom-drawer'}>
      <div className="drawer-content-wrapper">{children}</div>
    </section>
  );
};

export default CustomDrawer;
