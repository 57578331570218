import { ReactComponent as LeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as AppLogo } from 'assets/logos/appLogo.svg';
import { AuthFormHeader, AuthFormWrapper } from 'components/Auth';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import React, { useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetPasswordAction } from 'redux/actions/AuthAction';
import '../style.less';

const ResetRequest = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    resetPassword: { success, loading },
  } = useSelector(({ auth }) => auth);
  const [validated, setValidated] = useState(false);
  const [authData, setAuthData] = React.useState({
    email: '',
  });
  const handleChange = ({ target: { name, value } }) => {
    setAuthData({ ...authData, [name]: value });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;

    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      return;
    }
    dispatch(resetPasswordAction({ email: authData.email }));
    setValidated(true);
  };
  // useEffect(() => {
  //   if (success) {
  //     history.push('/reset-password');
  //   }
  // }, [success]);
  const handleBack = () => {
    history.push('/login');
  };
  return (
    <div className="page-wrapper grey">
      <div className="page-content">
        <div className="auth-header logoSpace">
          <AppLogo className="logo" />
        </div>
        <div className="page-content with-vit password-request">
          <AuthFormWrapper>
            <div className="text-center">
              <AuthFormHeader titlle="Request password reset" />
              {/* <div className="subHeader">
                {
                  " Enter your Bujeti account email and we'll send you instrsutions to reset your password"
                }
              </div> */}
            </div>
            <Form
              className="mt-3"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <Row className="mb-3">
                {success ? (
                  <h4 className="m-0 text-center">
                    A reset link will be sent to your email
                  </h4>
                ) : (
                  <>
                    <CustomInput
                      label="Your email"
                      error="enter valid email"
                      placeholder="Enter email"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      value={authData.email}
                      required
                      c
                    />
                    <CustomButton
                      className={'buttonStyle'}
                      type="submit"
                      loading={loading}
                      disabled={loading}
                    >
                      Reset password
                    </CustomButton>
                  </>
                )}
              </Row>
            </Form>

            <h4
              className="secondary-action cursor
          d-flex align-items-center justify-content-center"
              onClick={handleBack}
            >
              <LeftIcon className="me-2" />
              <span className="font-weight-normal"> Return to Log in </span>
            </h4>
          </AuthFormWrapper>
        </div>
      </div>
    </div>
  );
};

export default ResetRequest;
