import AccordionCard from 'components/UI/AccordionCard';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomPhoneNumberInput from 'components/UI/CustomPhoneNumberInput';
import CustomSelect from 'components/UI/CustomSelect';
import FileUpload from 'components/UI/FileUpload';
import Loading from 'components/UI/Loading';
import { toastError, toastSuccess } from 'components/UI/toast';
import React, { useEffect, useState } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCompanyLogoAsset,
  getCountries,
  getState,
  updateCompany,
} from 'redux/actions/CompaniesAction';
import { RESET_BLOCK_COMPANY } from 'redux/reducers/CompaniesReducer';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { getInternationalFormat, validateNigeriaNumber } from 'utils/helper';

const CompanyProfile = () => {
  const [uploadingFile, setUploadingFile] = useState(false);
  const { permissions } = allPermissions();
  const canEdit = hasPermission({
    permissions,
    scopes: ['org-*', 'org-edit'],
  });
  const {
    getCompany,
    updateCompany: { success, loading: isCompanyUpdating },
    getCountry: { data: countryData = {} },
    getState: { data: states = [] },
    getCompanyAsset: { data: logoAsset },
  } = useSelector(({ companies }) => companies);
  const { data: companyData = {}, loading: isFetchingCompany } = getCompany;
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState('0');
  const handleSelect = (key) => {
    if (activeKey === key) return setActiveKey('999999999');
    setActiveKey(key);
  };
  const [statesData, setStatesData] = useState([]);
  const [businessInfo, setBusinessInfo] = React.useState({
    companyName: companyData.name,
    employeeSize: {},
    businessLocation: '',
    country: {
      label: companyData.address?.country,
      value: companyData.address?.countryIso,
    },
    state: '',
    city: companyData.address?.city,
    businessAddress: companyData.address?.street,
    website: companyData.website,
    logo: null,
  });

  const [isReupload, setIsUpload] = useState(false);

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  useEffect(() => {
    if (countryData.length) {
      const country = countryData?.find((country) => country.value === countryData?.name);
      dispatch(getState(country.code));
    }
  }, [countryData]);

  useEffect(() => {
    if (states) {
      const stateOptions = states.states?.map((value) => ({
        label: value,
        value: value,
      }));

      if (stateOptions) {
        setStatesData(stateOptions);
      }
    }
  }, [states]);

  useEffect(() => {
    if (statesData) {
      setBusinessInfo({
        ...businessInfo,
        state: statesData.find((state) => state.label === companyData.address?.state),
      });
    }
  }, [statesData]);

  const [directorInfo, setDirectorInfo] = React.useState({
    firstName: '',
    lastName: '',
    nationality: '',
    bvn: '',
    localFormat: '',
    internationalFormat: '',
    countryCode: '',
    country: '',
    state: '',
    city: '',
    streetAddress: '',
    idType: { value: 'nin', label: 'National ID Card' },
    id_number: '',
    url: '',
    file: '',
    dob: '',
  });

  const handleChangeBusinessInfo = ({ target: { name, value } }) => {
    setBusinessInfo({ ...businessInfo, [name]: value });
  };

  const handledirectorInfo = ({ target: { name, value } }) => {
    setDirectorInfo({ ...directorInfo, [name]: value });
  };

  const handlePhoneNumberChange = (localFormat, internationalFormat, countryCode) => {
    setDirectorInfo({ ...directorInfo, internationalFormat, localFormat, countryCode });
  };

  const handleSubmit = () => {
    if (activeKey === '0') {
      if (!businessInfo.companyName) return toastError('Please enter your company name');
      if (!businessInfo.employeeSize.value)
        return toastError('Please select your employee size');
      if (!businessInfo.website) return toastError('Please enter your website');
      if (!businessInfo.country) {
        return toastError('Please select your country');
      }
      if (!businessInfo.state) {
        return toastError('Please select state');
      }
      if (!businessInfo.businessAddress) {
        return toastError('Please enter your street address');
      }

      const payloadData = {
        code: companyData.code,
        name: businessInfo.companyName,
        size: businessInfo.employeeSize.value,
        address: {
          state: businessInfo.state.value,
          street: businessInfo.businessAddress,
          city: businessInfo.city,
          country: businessInfo.country?.label,
          countryIso: businessInfo.country?.value,
        },
        website: businessInfo.website,
        logo: businessInfo?.logo ? businessInfo?.logo : undefined,
      };
      dispatch(updateCompany(payloadData));
    } else if (activeKey === '2') {
      if (!directorInfo.firstName) return toastError('Please enter your first name');
      if (!directorInfo.lastName) return toastError('Please enter your last name');
      if (!directorInfo.dob) return toastError('Please enter your date of birth');
      if (!directorInfo.nationality) return toastError('Please select your nationality');
      if (
        !isValidPhoneNumber(directorInfo.internationalFormat) ||
        !validateNigeriaNumber(directorInfo.internationalFormat)
      )
        return toastError('Please enter a valid phone number');
      if (!directorInfo.country) {
        return toastError("Please select your director's country");
      }
      if (!directorInfo.state) {
        return toastError("Please select your director's state");
      }
      if (!directorInfo.streetAddress) {
        return toastError("Please enter your director's street address");
      }
      // if (!directorInfo.bvn)
      //   return toast("Please enter your director's BVN");
      // if (!directorInfo.idType.value)
      //   return toast("Please select your director's ID type");
      // if (!directorInfo.id_number)
      //   return toast("Please enter your director's ID number");
      // if (!directorInfo.url)
      //   return toast(`Please upload your director's ${directorInfo.idType.label} file`, {
      //     type: 'error',
      //   });
      const payload = {
        code: companyData.code,
        director: {
          firstName: directorInfo.firstName,
          lastName: directorInfo.lastName,
          nationality: directorInfo.nationality.value,
          // dob: directorInfo.dob,
          address: {
            state: directorInfo.state?.label,
            street: directorInfo.streetAddress,
            city: directorInfo.city,
            country: directorInfo.country?.label,
            countryIso: directorInfo.country?.value,
          },
          phoneNumber: {
            countryCode: String(directorInfo.countryCode),
            localFormat: directorInfo.localFormat,
          },
          // document: {
          //   type: directorInfo.idType.value,
          //   number: directorInfo.id_number,
          //   url: directorInfo.url,
          // },
        },
      };
      dispatch(updateCompany(payload));
    }
  };

  function getEmpSize(size) {
    switch (size) {
      case 'micro':
        return { value: 'micro', label: 'Micro (0-10 employees)' };
      case 'medium':
        return { value: 'medium', label: 'Medium (10-50 employees)' };
      case 'small':
        return { value: 'small', label: 'Small (50-200 employees)' };
      case 'large':
        return { value: 'large', label: 'Large (200+ employees' };
      default:
        return {};
    }
  }

  useEffect(() => {
    if (Object.keys(companyData).length > 0 && !isFetchingCompany) {
      const { address, name, size, website, director, logo } = companyData;
      setBusinessInfo({
        ...businessInfo,
        companyName: name,
        employeeSize: getEmpSize(size),
        website: website,
        businessLocation: address?.street,
        country: {
          label: address?.country,
          value: address?.countryIso,
        },
        state: '',
        city: address?.city,
        businessAddress: address?.street,
        logo,
      });
      setDirectorInfo({
        ...directorInfo,
        firstName: director?.firstName,
        lastName: director?.lastName,
        nationality: {
          value: director?.address?.country,
          label: director?.address?.country,
        },
        bvn: director?.document?.number,
        // dob: director?.dob,
        state: { value: director?.address?.state, label: director?.address?.state },
        streetAddress: director?.address?.street,
        city: director?.address?.city,
        country: {
          value: director?.address?.countryIso,
          label: director?.address?.country,
        },
        countryIso: director?.address?.countryIso,
        localFormat: director?.phoneNumber?.localFormat || '',
        countryCode: director?.phoneNumber?.countryCode || '',
        internationalFormat:
          getInternationalFormat(
            director?.phoneNumber?.countryCode,
            director?.phoneNumber?.localFormat,
          ) || '',
      });
      if (!logoAsset?.asset?.url) getCompanyLogo(logo);
    }
  }, [companyData, isFetchingCompany, logoAsset]);

  const getCompanyLogo = (logo) => {
    if (logo?.code) dispatch(getCompanyLogoAsset(logo?.code));
  };

  useEffect(() => {
    if (success) {
      toastSuccess('Company profile update successful');
      setIsUpload(false);
      // dispatch({ type: RESET_FLAGS_COMPANY, blockType: 'getCompanyAsset' });
      dispatch({ type: RESET_BLOCK_COMPANY, blockType: 'getCompanyAsset' });
      dispatch(getCompany(companyData?.code));
    }
  }, [success]);

  if (isFetchingCompany) return <Loading isPage={true} color="#d28b28" />;

  return (
    <Row className="information-wrapper">
      <Col md={6} className="form-normal">
        <Accordion defaultActiveKey="0" activeKey={activeKey}>
          <AccordionCard
            eventKey="0"
            activeKey={activeKey}
            title="Business details"
            handleClick={handleSelect}
          >
            {canEdit && (
              <Row className="mb-3 mt-3">
                {logoAsset?.asset?.url && (
                  <div className="companyLogo-wrapper">
                    <img src={logoAsset?.asset?.url} alt="company logo" />
                    <div>
                      <button onClick={() => setIsUpload(!isReupload)}>
                        Update logo
                      </button>
                    </div>
                  </div>
                )}
                {isReupload && (
                  <div className="col-md-8">
                    <FileUpload
                      label="Company Logo"
                      placeholder="Upload your company logo (JPEG, PNG)"
                      name="logo"
                      onChange={(value) =>
                        setBusinessInfo({ ...businessInfo, logo: value?.assetCode })
                      }
                      wrapperClass=""
                      setUploadingFile={setUploadingFile}
                    />
                  </div>
                )}
              </Row>
            )}

            <Row className="mb-3">
              <CustomInput
                label="Company name"
                placeholder="Enter company name"
                type="text"
                name="companyName"
                onChange={handleChangeBusinessInfo}
                value={businessInfo.companyName}
                md={8}
                disabled={!canEdit}
              />
            </Row>
            <Row className="mb-3">
              <CustomSelect
                label="Employee size"
                name="employeeSize"
                onChange={(val) =>
                  setBusinessInfo({ ...businessInfo, employeeSize: val })
                }
                value={businessInfo.employeeSize}
                md={8}
                options={[
                  { value: 'micro', label: 'Micro (1 to 10 employees)' },
                  { value: 'small', label: 'Small (10 to 50 employees)' },
                  { value: 'medium', label: 'Medium (50 to 200 employees)' },
                  { value: 'large', label: 'Large (more than 200 employees)' },
                ]}
                isDisabled={!canEdit}
              />
            </Row>

            <Row className="mb-3">
              <CustomInput
                label="Website"
                placeholder="Enter website"
                type="text"
                name="website"
                onChange={handleChangeBusinessInfo}
                value={businessInfo.website}
                md={8}
                disabled={!canEdit}
              />
            </Row>

            <Row className="mb-3">
              <CustomSelect
                label="Country"
                name="country"
                placeholder="Select country"
                onChange={(val) => setBusinessInfo({ ...businessInfo, country: val })}
                value={businessInfo.country}
                options={[{ value: 'NGA', label: 'Nigeria' }]}
                md={8}
                isDisabled={!canEdit}
              />
            </Row>
            <Row className="mb-3 align-items-center">
              <CustomSelect
                isDisabled={!businessInfo.country?.label || !canEdit}
                label="State or Region"
                name="state"
                placeholder="State or Region"
                onChange={(val) => setBusinessInfo({ ...businessInfo, state: val })}
                value={businessInfo.state}
                options={statesData}
                md={4}
              />
              <CustomInput
                label="City"
                placeholder="Enter name"
                type="text"
                name="city"
                onChange={handleChangeBusinessInfo}
                value={businessInfo.city}
                md={4}
                disabled={!canEdit}
              />
            </Row>

            <Row className="mb-3">
              <CustomInput
                label="Street address"
                placeholder="Enter address"
                type="text"
                name="businessAddress"
                onChange={handleChangeBusinessInfo}
                value={businessInfo.businessAddress}
                md={8}
                disabled={!canEdit}
              />
            </Row>
          </AccordionCard>

          {/******************** Director information ***************/}
          <AccordionCard
            eventKey="2"
            activeKey={activeKey}
            title="Director information"
            handleClick={handleSelect}
          >
            <Row>
              <Col md={8}>
                <Row className="mb-3">
                  <CustomInput
                    label="First name"
                    placeholder="Enter name"
                    type="text"
                    name="firstName"
                    onChange={handledirectorInfo}
                    md={6}
                    value={directorInfo.firstName}
                  />
                  <CustomInput
                    label="Last name"
                    placeholder="Enter name"
                    type="text"
                    name="lastName"
                    onChange={handledirectorInfo}
                    value={directorInfo.lastName}
                    md={6}
                  />
                </Row>
              </Col>
            </Row>

            <Row className="mb-3">
              <CustomSelect
                label="Nationality"
                name="nationality"
                onChange={(nationality) =>
                  setDirectorInfo({ ...directorInfo, nationality: nationality })
                }
                value={directorInfo.nationality}
                options={[{ value: 'Nigeria', label: 'Nigeria' }]}
                md={8}
                isDisabled={!canEdit}
              />
            </Row>
            <Row className="mb-3">
              <CustomPhoneNumberInput
                label="Phone number"
                placeholder="Enter your phone number"
                onChange={(localFormat, international, countryCode) =>
                  handlePhoneNumberChange(localFormat, international, countryCode)
                }
                value={directorInfo.internationalFormat}
                col="col-md-8"
                disabled={!canEdit}
                md={8}
              />
            </Row>
            <Row className="mb-3">
              <CustomInput
                label="BVN"
                placeholder="Enter BVN"
                type="text"
                name="bvn"
                onChange={handledirectorInfo}
                value={directorInfo.bvn}
                md={8}
                disabled
              />
            </Row>
            <Row className="mb-3">
              <CustomSelect
                label="Country"
                name="country"
                placeholder="Select country"
                onChange={(val) => setDirectorInfo({ ...directorInfo, country: val })}
                value={directorInfo.country}
                options={[{ value: 'NGA', label: 'Nigeria' }]}
                md={8}
                isDisabled={!canEdit}
              />
            </Row>
            <Row className="mb-3 align-items-center">
              <CustomSelect
                isDisabled={!directorInfo.country?.label || !canEdit}
                label="State or Region"
                name="state"
                placeholder="State or Region"
                onChange={(val) => setDirectorInfo({ ...directorInfo, state: val })}
                value={directorInfo.state}
                options={statesData}
                md={4}
              />
              <CustomInput
                label="City"
                placeholder="Enter name"
                type="text"
                name="city"
                onChange={handledirectorInfo}
                value={directorInfo.city}
                md={4}
                disabled={!canEdit}
              />
            </Row>

            <Row className="mb-3">
              <CustomInput
                label="Street address"
                placeholder="Enter address"
                type="text"
                name="streetAddress"
                onChange={handledirectorInfo}
                value={directorInfo.streetAddress}
                md={8}
                disabled={!canEdit}
              />
            </Row>
          </AccordionCard>
        </Accordion>

        {canEdit && (
          <Row>
            <Col md={8}>
              <CustomButton
                onClick={handleSubmit}
                fullWidth={true}
                disabled={uploadingFile || isCompanyUpdating}
                loading={isCompanyUpdating}
              >
                Save changes
              </CustomButton>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};
export default CompanyProfile;
