import CustomSelect from 'components/UI/CustomSelect';
import { Row } from 'react-bootstrap';
import { businessTypes } from 'utils/helper';
import '../styles.scss';
import Enterprise from './BussinessType/Enterprise';
import LimitedLiability from './BussinessType/LimitedLiability';
import NGO from './BussinessType/NGO';
import Partnership from './BussinessType/Partnership';
import SoleProprietorship from './BussinessType/SoleProprietorship';
import Saving from './Saving';
import FileUpload from 'components/UI/FileUpload';
// import * as fs from 'fs';

const StepThree = ({
  setBusinessStep,
  businessStep,
  businessDoc,
  handleChangeBusinessDoc,
  businessType,
  handleChangeBusinessType,
  setBusinessDoc,
  setIsOtherPopoverOpen,
  setUploadingFile,
  uploadingFile,
  setDirectorsDocuments,
  handlePhoneNumberChange,
  loadingBusinessDoc,
  setDocuments,
  documents,
}) => {
  return (
    <>
      <div style={{ display: loadingBusinessDoc ? 'block' : 'none' }}>
        <Saving />
      </div>
      <div
        className="onboardingStepDiv"
        style={{ display: !loadingBusinessDoc ? 'block' : 'none' }}
      >
        <p style={{ fontSize: '14px' }}>
          Upload business documents and verify your incorporation details.
        </p>
        <Row className="mb-3">
          <FileUpload
            label="Proof of Address / Utility Bill *"
            placeholder="Upload document (PDF, JPEG, PNG)"
            name="file"
            onChange={({ assetCode }) =>
              setDocuments({ ...documents, utilityBill: assetCode })
            }
            uploadedFile={documents.utilityBill}
            setUploadingFile={setUploadingFile}
            uploadingFile={uploadingFile}
          />
        </Row>
        <div className="proof-of-address">
          Upload a copy of your bank statement, utility bill, phone bill, tax assessment,
          or any government-issued document.
          <ul>
            <li>All documents must be less than 3 months old </li>
            <li>And must include the name of the identified individual or business</li>
          </ul>
        </div>

        {businessStep === 0 ? (
          <Row className="mb-3">
            <CustomSelect
              label="Business Type *"
              name="businessType"
              placeholder="Select business type"
              onChange={(val) => handleChangeBusinessType({ val })}
              value={businessDoc.type}
              options={businessTypes}
            />
          </Row>
        ) : null}

        {businessType === 'sole proprietorship' ? (
          <SoleProprietorship
            setDocuments={setDocuments}
            documents={documents}
            handleChangeBusinessDoc={handleChangeBusinessDoc}
            businessDoc={businessDoc}
            setBusinessDoc={setBusinessDoc}
            setUploadingFile={setUploadingFile}
            uploadingFile={uploadingFile}
            setDirectorsDocuments={setDirectorsDocuments}
            handlePhoneNumberChange={handlePhoneNumberChange}
          />
        ) : businessType === 'limited liability' ? (
          <LimitedLiability
            setDocuments={setDocuments}
            documents={documents}
            businessStep={businessStep}
            setBusinessStep={setBusinessStep}
            handleChangeBusinessDoc={handleChangeBusinessDoc}
            setBusinessDoc={setBusinessDoc}
            businessDoc={businessDoc}
            setIsOtherPopoverOpen={setIsOtherPopoverOpen}
            setUploadingFile={setUploadingFile}
            uploadingFile={uploadingFile}
            handlePhoneNumberChange={handlePhoneNumberChange}
          />
        ) : businessType === 'ngo' ? (
          <NGO
            setDocuments={setDocuments}
            documents={documents}
            handleChangeBusinessDoc={handleChangeBusinessDoc}
            businessDoc={businessDoc}
            setBusinessDoc={setBusinessDoc}
            setUploadingFile={setUploadingFile}
            uploadingFile={uploadingFile}
            handlePhoneNumberChange={handlePhoneNumberChange}
          />
        ) : businessType === 'enterprise' ? (
          <Enterprise
            setDocuments={setDocuments}
            documents={documents}
            handleChangeBusinessDoc={handleChangeBusinessDoc}
            businessDoc={businessDoc}
            setBusinessDoc={setBusinessDoc}
            setUploadingFile={setUploadingFile}
            uploadingFile={uploadingFile}
            handlePhoneNumberChange={handlePhoneNumberChange}
          />
        ) : businessType === 'partnership' ? (
          <Partnership
            setDocuments={setDocuments}
            documents={documents}
            handleChangeBusinessDoc={handleChangeBusinessDoc}
            businessDoc={businessDoc}
            setBusinessDoc={setBusinessDoc}
            setUploadingFile={setUploadingFile}
            uploadingFile={uploadingFile}
          />
        ) : (
          ''
        )}
      </div>
      {/* } */}
    </>
  );
};
export default StepThree;
