import { CloseOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import Modal from 'components/UI/Modal';
import MultipleSelect from 'components/UI/MultipleSelect';
import { toastError, toastSuccess } from 'components/UI/toast';
import { useDebounce } from 'hooks/useDebounce';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBeneficiaries } from 'redux/actions/BeneficiariesAction';
import { getBalances } from 'redux/actions/BudgetsAction';
import { createBankAccount } from 'redux/actions/CompaniesAction';
import { RESET_BLOCK_COMPANY } from 'redux/reducers/CompaniesReducer';
import { getAvailableBalance, getInitials, groupSourceOptions } from 'utils/helper';

const NewAccountModal = ({
  closeHandler,
  isModalOpen,
  accountType = null,
  balanceCode = null,
}) => {
  if (!isModalOpen) return <div />;

  const handleBack = () => {
    return closeHandler();
  };

  const dispatch = useDispatch();

  const {
    user: { data: userData },
  } = useSelector(({ auth }) => auth);

  const {
    getBalances: {
      data: { balances = [] } = {},
      loading: loadingBalances,
      success: successBalance,
    },
    getSubAccountMembers: { data: { members: membersSubAccount = [] } = {} },
  } = useSelector(({ budgets }) => budgets);

  const {
    getBeneficiaries: { data: { beneficiaries = [] } = {}, loading: beneficiaryLoading },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const {
    createBankAccount: { success: accountCreated, loading: creatingAccount },
  } = useSelector(({ companies }) => companies);

  const [data, setData] = useState({
    name: '',
    bankCode: '',
    purpose: '',
    currency: '',
  });

  const [balanceList, setBalanceList] = useState([]);

  const [loadPage, setLoadPage] = useState(0);

  const [hasMore] = useState(false);

  const [subAccountMembers, setSubAccountMembers] = useState([]);
  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, 200);
  const handleSelectChange = (val) => setValue(val);

  useEffect(() => {
    if (loadPage > 1 || balanceCode) dispatch(getBalances({ page: loadPage }));
  }, [loadPage]);

  useEffect(() => {
    if (balances?.length) {
      const parenBalances = [...balances].filter((item) => !item.subaccount);
      const available_balance = getAvailableBalance(parenBalances, 'balance');
      setBalanceList(available_balance);

      if (balanceCode) {
        const selectedParentBalance = getAvailableBalance(
          [...balances].filter((item) => item.code === balanceCode),
          'balance',
        );
        setData({
          ...data,
          parent: selectedParentBalance?.[0],
        });
      }
    }
  }, [balances?.length]);

  const onChange = (value, type) => {
    setData({
      ...data,
      [type]: value,
    });
  };

  const handleSubmit = () => {
    const { parent, name, bank, purpose } = data;

    if (accountType && !parent) {
      toastError('Please select the parent account');
      return;
    }

    if (!name) {
      toastError('Please give the account a name');
      return;
    }

    if (!accountType && !bank) {
      toastError('Please select a preferred bank');
      return;
    }

    if (!purpose) {
      toastError('Please select the account type');
      return;
    }

    const transformedMangersList =
      subAccountMembers?.managers?.map((item) => {
        return {
          beneficiary: item.value,
          designation: 'Manager',
        };
      }) || undefined;

    const payload = {
      parent: parent?.bankAccount,
      name,
      // bankCode: bank.value,
      purpose: purpose?.value,
      currency: accountType ? parent?.currency : bank.currency,
      beneficiaries: transformedMangersList,
    };

    dispatch(createBankAccount(payload));
  };

  const banksList = useMemo(() => {
    const banks = [
      // { label: '🇳🇬 Providus Bank', value: 'providus', currency: 'NGN' },
      // { label: '🇳🇬 Wema Bank', value: 'wema', currency: 'NGN' },
      { label: '🇳🇬 9PS Bank', value: '9psb', currency: 'NGN' },
      // { label: '🇺🇸 First Bank of Indiana', value: 'fbi', currency: 'USD' },
    ];

    return banks;
  }, [successBalance]);

  async function loadOptions(search, loadedOptions, { page }) {
    setLoadPage(page);

    let filteredOption = balanceList.filter((item) =>
      item?.name?.toLowerCase().includes(search?.toLowerCase()),
    );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  useEffect(() => {
    if (accountCreated) {
      closeHandler(true);
      toastSuccess('Account is being generated');
      dispatch(getBalances());
      dispatch({ type: RESET_BLOCK_COMPANY, blockType: 'createBankAccount' });
    }
  }, [accountCreated]);

  useEffect(() => {
    if (!beneficiaries.length) dispatch(getBeneficiaries());
  }, []);

  useEffect(() => {
    const beneficiaries = beneficiaryList?.find((option) =>
      option?.label?.toLowerCase()?.includes(debouncedValue?.toLowerCase()),
    );

    if (!beneficiaries && debouncedValue) {
      dispatch(getBeneficiaries({ search: debouncedValue?.toLowerCase() }));
    }
  }, [debouncedValue]);

  const handleBeneficiarySelect = (value, name) => {
    setSubAccountMembers({
      ...subAccountMembers,
      [name]: value,
    });
  };

  const onMenuClose = () => {
    if (debouncedValue) dispatch(getBeneficiaries());
  };

  const { managers } = subAccountMembers;

  const seletedMangers = managers ? managers?.map((item) => item.value) : [];

  const subAccountMembersList = membersSubAccount?.map(({ user: { code } }) => code);

  const beneficiaryList = beneficiaries
    .filter(
      ({ code, role, user: { code: userCode } }) =>
        !seletedMangers?.includes(code) &&
        ![...subAccountMembersList, userData?.user?.code].includes(userCode),
    )
    .map(({ user, code }) => ({
      label: `${user.firstName} ${user.lastName}`,
      value: code,
    }));

  const formatSelectedOptionLabel = (selectedOption) => {
    if (!selectedOption) {
      return null;
    }
    return (
      <div className="d-flex align-items-center gap-2">
        <div className="avatar__card-initials">
          <h5>{getInitials(null, null, selectedOption.label)}</h5>
        </div>
        <div>{selectedOption.label}</div>
      </div>
    );
  };

  const handleSelectAllBeneficiary = (list, name) => {
    const newList = !beneficiaryList?.length ? [] : list;
    const formerList =
      !beneficiaryList?.length && subAccountMembers?.[name]
        ? []
        : subAccountMembers?.[name] ?? [];
    setSubAccountMembers({
      ...subAccountMembers,
      [name]: [...newList, ...formerList],
    });
  };
  const [isLengthEqualManagers, setIsLengthEqualManagers] = useState(false);

  useEffect(() => {
    setIsLengthEqualManagers(
      beneficiaryList?.length === 0 && !!subAccountMembers?.managers?.length,
    );
  }, [subAccountMembers?.managers?.length]);

  return (
    <Modal show={isModalOpen} onClose={closeHandler}>
      <div className="content">
        <div className="card-modal-header">
          <div className="d-flex align-items-center cursor" onClick={handleBack}>
            <>
              <CloseOutlined />
              <span className="ps-1">Close</span>
            </>
          </div>
        </div>
        <div className="card-modal-body">
          <div className="beneficiaries">
            <h2 className="card-title w-100">
              Create a new {accountType ? 'sub' : ''}account
            </h2>

            <CustomInput
              label={`${accountType ? 'Subaccount' : 'Account'} name`}
              name="name"
              className="mb-2"
              type="text"
              placeholder="VAT, Salaries, Marketing team..."
              onChange={({ target: { value } }) => onChange(value, 'name')}
              value={data.name}
              maxLength="50"
            />

            {accountType && (
              <CustomSelectRadio
                label="Parent account"
                name="parent"
                className="mb-2"
                placeholder="Select parent account"
                onChange={(value) => onChange(value, 'parent')}
                value={data.parent}
                isLoading={loadingBalances}
                isDisabled={loadingBalances || balanceCode}
                loadOptions={loadOptions}
              />
            )}

            {!accountType && (
              <CustomSelect
                label="Preferred bank"
                placeholder="Select a bank"
                isDisabled={loadingBalances}
                isLoading={loadingBalances}
                className="mb-2"
                name="bank"
                options={banksList}
                onChange={(val) => onChange(val, 'bank')}
                value={data?.bank}
              />
            )}

            <CustomSelect
              label="Account type"
              placeholder="Select account type"
              isDisabled={false}
              name="purpose"
              className="mb-2"
              options={[
                { value: 'expenses', label: 'Expenses' },
                { value: 'revenue', label: 'Revenue' },
                { value: 'taxes', label: 'Taxes' },
              ]}
              onChange={(val) => onChange(val, 'purpose')}
              value={data?.purpose}
            />

            {accountType && (
              <MultipleSelect
                label="Account managers"
                placeholderText="Select managers"
                name="managers"
                options={beneficiaryList}
                onChange={(value) => handleBeneficiarySelect(value, 'managers')}
                value={subAccountMembers?.managers}
                onInputChange={handleSelectChange}
                extendComponent={
                  <span className="d-flex align-items-center gap-2">
                    {beneficiaryList?.length === 0 ? <MinusOutlined /> : <PlusOutlined />}
                    {isLengthEqualManagers
                      ? 'Unselect all managers'
                      : 'Select all managers'}
                  </span>
                }
                selectAllBudgets={() =>
                  handleSelectAllBeneficiary(beneficiaryList, 'managers')
                }
                isLoading={beneficiaryLoading}
                formatOptionLabel={formatSelectedOptionLabel}
                onMenuClose={onMenuClose}
              />
            )}

            <div className="modal-footer border-top">
              <CustomButton
                onClick={handleBack}
                fullWidth={true}
                className="custom-button ghost-button"
              >
                Cancel
              </CustomButton>
              <CustomButton
                onClick={handleSubmit}
                fullWidth={true}
                className="custom-button primary-button"
                loading={creatingAccount}
                disabled={creatingAccount}
              >
                Create {accountType ? 'sub' : ''}account
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default NewAccountModal;
