import React from 'react';
import './styles.scss';
import { InfoCircle } from 'assets/icons';
import classNames from 'classnames';

const NotificationBanner = ({ isError = false }) => {
  return (
    <div className={classNames('notification-banner-wrap', isError ? 'error' : 'notice')}>
      <p className="p-0 m-0">
        <InfoCircle fill={isError ? '#D92D20' : '#2970FF'} />
      </p>
      <p className="m-0 p-0">
        To unlock Bujeti&apos;s basic features, simply upload your CAC Certificate of
        Incorporation. Other documents can be submitted later for full access.
      </p>
    </div>
  );
};

export default NotificationBanner;
