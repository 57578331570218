import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import './timeline.styles.scss';

const STATUS_TITLE = {
  approved: 'Approved by',
  declined: 'Declined by',
  pending: 'Awaiting approval from',
};

const Timeline = ({ data, goToRule, multipleRule = false, code, pageFrom }) => {
  const history = useHistory();
  const newData = Object.values(
    data.reduce((acc, obj) => {
      const userCode = obj?.user?.code;
      const status = obj?.status;

      if (!acc[userCode] || status !== 'pending') {
        acc[userCode] = obj;
      }

      return acc;
    }, {}),
  );

  const uniqueData = newData
    .sort((firstItem, nextItem) => {
      if (firstItem.status === 'approved' && nextItem.status !== 'approved') {
        return -1;
      } else if (firstItem.status !== 'approved' && nextItem.status === 'approved') {
        return 1;
      } else if (firstItem.status === 'declined' && nextItem.status !== 'declined') {
        return 1;
      } else if (firstItem.status !== 'declined' && nextItem.status === 'declined') {
        return -1;
      }
      return 0;
    })
    .filter((item) => item?.approvers?.length || item?.user);

  const setHistory = (ruleCode) => {
    history.push({
      pathname: `/transactions/rules/${ruleCode}`,
      state: { previousUrl: `${pageFrom}/${code}/details` },
    });
  };

  return (
    <div className="timeline-container">
      {uniqueData?.length <= 0 ? (
        <h6 className="timeline-title">Admins or Direct managers can approve/decline</h6>
      ) : (
        <ul className="timeline">
          {uniqueData?.map((item, uniqueIndex) => {
            const singleItemClass = uniqueData?.length === 1 && 'hide-dot';
            const classNames =
              item?.status === 'approved' ? 'timeline-item active' : 'timeline-item';
            const noDescription = item?.message
              ? 'd-flex timeline-details'
              : 'd-flex timeline-details center';
            return (
              <li className={`${classNames} ${singleItemClass}`} key={uniqueIndex}>
                <div>
                  <h6 className="timeline-title">
                    {STATUS_TITLE[item?.status]}

                    {multipleRule && item.rule && item.status === 'pending' && (
                      <span
                        className="timeline-rulename"
                        onClick={() => setHistory(item?.rule.code)}
                      >
                        <>
                          (due to #{item.rule.name})
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.8335 14.1663L14.1668 5.83301M14.1668 5.83301H5.8335M14.1668 5.83301V14.1663"
                              stroke="#D28B28"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </>
                      </span>
                    )}
                  </h6>

                  <div className="d-flex name-wrapper">
                    {renderTimeLineItems(item, noDescription, uniqueIndex)}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

const renderTimeLineItems = (item, noDescription, uniqueIndex) => {
  return item?.approvers?.length ? (
    item?.approvers?.map((approver, index) => {
      return (
        <div className="item-wrapper" key={index}>
          <div className={`${noDescription}`}>
            <span className="timeline-thumbnail thumbnail-small thumbnail-primary text-xs">
              {approver?.user?.firstName.split('')[0]}
            </span>
            <div>
              <h6 className="timeline-item-name">
                {approver?.user?.firstName} {approver?.user?.lastName}
                {item?.date && <span>{item?.date}</span>}
              </h6>
              {item?.message && (
                <p className="timeline-item-description">{item?.message}</p>
              )}
            </div>
          </div>
          <span className="timeline-or">or</span>
        </div>
      );
    })
  ) : (
    <div className={`${noDescription}`} key={uniqueIndex}>
      <span className="timeline-thumbnail thumbnail-small thumbnail-primary text-xs">
        {item?.user?.firstName.split('')[0]}
      </span>
      <div>
        <h6 className="timeline-item-name">
          {item?.user?.firstName} {item?.user?.lastName}
          {item?.status === 'pending' ? null : <span>{item?.date}</span>}
        </h6>
        {item?.message && <p className="timeline-item-description">{item?.message}</p>}
      </div>
    </div>
  );
};

export default Timeline;
