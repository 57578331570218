import { CloseOutlined } from '@ant-design/icons';
import { ReactComponent as LeftIcon } from 'assets/icons/arrow-left.svg';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getCountries, getInvitedDirectors } from 'redux/actions/CompaniesAction';
import BudgetModal from '../../pages/Budgets/BudgetModal';
import {
  getBeneficiaries,
  getBeneficiaryBank,
} from '../../redux/actions/BeneficiariesAction';
import Modal from '../UI/Modal';
import AccountInfo from './Card/Bank/AccountInfo';
import Balance from './Card/Bank/Balance';
import BankAccount from './Card/Bank/BankAccount';
import DebitCreditCard from './Card/Bank/DebitCreditCard';
import Beneficiaries from './Card/Beneficiaries';
import Information from './Card/Information';
import BankInfo from './Card/KYC/BankInfo';
import Informations from './Card/KYC/Informations';
import ResubmitDocument from './ResubmitDocument';
import './styles.scss';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { getBudgets } from 'redux/actions/BudgetsAction';

const BusinessCard = ({
  isOpen,
  selectedItem,
  toggleHandler: toggleModal,
  setSelectStep,
}) => {
  const { user } = useSelector(({ auth }) => auth);
  const history = useHistory();

  const { onboardingStatus, isAdmin, companyStatus, userStatus, permissions } =
    allPermissions();

  const {
    getCompany: { data: companyData = {} },
    getInvitedDirectors: { data: invitedDirectors },
  } = useSelector(({ companies }) => companies);
  const {
    addBeneficiaryBank: { data: bankData = {}, success, loading, error },
    getBeneficiaryBank: { data: getbankData = [] },
    getBeneficiaries: { data: beneficiariesData = {} },
    createBeneficiaries: { data: createData },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const {
    createBudget: { success: createBudgetSucces },
    getBudget: { data: budgetData = {}, success: loadingSuccess },
  } = useSelector(({ budgets }) => budgets);

  const hasBeneficiaries = beneficiariesData?.beneficiaries?.length > 1;
  const hasBudgets = budgetData?.budgets?.length > 0;

  const [step, setStep] = useState(1);
  const [localStep, setLocalStep] = useState(1);
  const [localTotalStep] = useState(3);
  const [businessStep, setBusinessStep] = useState(0);
  const [isClose, setIsClose] = useState(false);
  const [activeBankCard, setActiveBankCard] = useState('');
  const [isOtherPopoverOpen, setIsOtherPopoverOpen] = useState(false);
  const [onboardingSaving, setOnboardingSaving] = useState(false);
  const [isOtherPopoverOpenwithModal, setIsOtherPopoverOpenwithModal] = useState(false);
  const [budgetStep, setBudgetStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [onboardingSaved, setOnboardingSaved] = useState(false);
  const [isAdvance, setIsAdvance] = useState(false);
  const [resubmitIsCompleted, setResubmitIsCompleted] = useState(false);
  const [stepOneCompleted, setStepOneCompleted] = useState(false);
  const [editAddress, setEditAddress] = useState(false);

  const handleSubmit = (activeId) => () => {
    setIsClose(true);
  };
  const dispatch = useDispatch();

  const canViewDash = hasPermission({
    permissions,
    scopes: ['org-*', 'dash-*', 'dash-view', 'dash-edit'],
  });

  useEffect(() => {
    if (selectedItem) {
      setStep(selectedItem.step);
    }
  }, [selectedItem]);

  useEffect(() => {
    const processStep = localStorage.getItem('setStep');
    setStep(+processStep + 1);
    if (isAdmin) {
      dispatch(getBeneficiaries());
      dispatch(getBudgets());
      dispatch(getInvitedDirectors());
    }
    dispatch(getCountries());
    dispatch(getBeneficiaryBank());
  }, []);

  useEffect(() => {
    if (loading || success) {
      setIsLoading(true);
      localStorage.setItem('setStep', JSON.stringify(2));
      setStep(3);
    }
    if (!loading && error) setIsLoading(false);
  }, [loading, success]);

  const checkAdminOnboardingStage = async () => {
    const stageFour = await onboardingStageFour();
    if (stageFour) {
      localStorage.setItem('setStep', JSON.stringify(3));
      setStep(4);
      return;
    } else if (onRejected()) {
      localStorage.setItem('setStep', JSON.stringify(0));
      setStep(0);
    } else {
      if (onboardingStageThree()) {
        localStorage.setItem('setStep', JSON.stringify(2));
        setStep(3);
        return;
      } else {
        if (onboardingStageTwo()) {
          setStep(2);
          localStorage.setItem('setStep', JSON.stringify(1));
          return;
        } else {
          if (onboardingStageOne()) {
            localStorage.setItem('setStep', JSON.stringify(0));
            setStep(1);
            return;
          }
        }
      }
    }
  };

  const onboardingStageFour = () => {
    if (
      (beneficiariesData?.beneficiaries?.length > 1 &&
        companyStatus !== 'rejected' &&
        companyData?.dateOfRegistration &&
        companyData?.utilityBill?.code &&
        companyData?.director?.percentageOwned &&
        companyData?.director?.dob) ||
      (createBudgetSucces &&
        companyStatus !== 'rejected' &&
        companyData?.dateOfRegistration &&
        companyData?.utilityBill?.code &&
        companyData?.director?.percentageOwned &&
        companyData?.director?.dob)
    ) {
      return true;
    }
  };

  const onboardingStageThree = () => {
    if (
      beneficiariesData?.beneficiaries?.length > 1 &&
      companyData.businessType &&
      companyData.director &&
      !createData &&
      companyStatus !== 'rejected' &&
      companyData?.dateOfRegistration &&
      companyData?.utilityBill?.code &&
      companyData?.director?.percentageOwned &&
      companyData?.director?.dob
    ) {
      return true;
    }
  };

  const onRejected = () => {
    if (companyStatus === 'rejected') return true;
  };

  const onboardingStageTwo = () => {
    if (
      companyData?.website &&
      companyData?.address &&
      companyData?.businessType &&
      companyData?.director &&
      companyStatus !== 'rejected' &&
      companyData?.dateOfRegistration &&
      companyData?.utilityBill?.code &&
      companyData?.director?.percentageOwned &&
      companyData?.director?.dob
    ) {
      return true;
    }
  };

  const onboardingStageOne = () => {
    if (
      !user?.data?.user?.company?.description &&
      !companyData?.dateOfRegistration &&
      // companyData?.utilityBill?.code &&
      !companyData?.director?.percentageOwned &&
      !companyData?.director?.dob
    ) {
      return true;
    }
  };

  const getUserBank = getbankData.find((item) => item.ownerType === 'user');

  useEffect(() => {
    if (isAdmin && ['pending', 'rejected'].includes(onboardingStatus)) {
      checkAdminOnboardingStage();
    }

    if (
      user?.data?.user?.dob &&
      user?.data?.user?.address?.street &&
      (!isAdmin || (isAdmin && !['pending', 'rejected'].includes(onboardingStatus)))
    ) {
      localStorage.setItem('setStep', JSON.stringify(1));
      setStep(2);
    }

    if (
      user?.data?.user?.dob &&
      user?.data?.user?.address?.street &&
      getUserBank?.accountName &&
      (!isAdmin || (isAdmin && !['pending', 'rejected'].includes(onboardingStatus)))
    ) {
      localStorage.setItem('setStep', JSON.stringify(2));
      setStep(3);
    }

    if (['pending'].includes(userStatus) && !isAdmin) {
      localStorage.setItem('setStep', JSON.stringify(0));
      setStep(1);
    }
  }, [
    companyData?.address?.street,
    user?.data?.user?.company?.description,
    isLoading,
    getUserBank?.accountName,
    budgetData?.budgets,
    loadingSuccess,
    createBudgetSucces,
  ]);

  useEffect(() => {
    if (isLoading && success) {
      if (!['pending', 'rejected'].includes(onboardingStatus))
        window.setTimeout(function () {
          if (!canViewDash) {
            history.push('/expenses/budgets');
          } else {
            history.push('/');
          }
        }, 1000);
    }
    if (
      !canViewDash &&
      getUserBank?.accountName &&
      !['pending', 'rejected'].includes(onboardingStatus)
    ) {
      if (!canViewDash) {
        history.push('/expenses/budgets');
      } else {
        history.push('/');
      }
    }
  }, [success, getUserBank?.accountName]);

  if (!isOpen) return <div />;
  const handleStep = (val) => {
    setStep(val);
    if (step < 3 && isAdmin && ['pending', 'rejected'].includes(onboardingStatus))
      setSelectStep(val);
    if (
      step < 2 &&
      (!isAdmin || (isAdmin && !['pending', 'rejected'].includes(onboardingStatus)))
    )
      setSelectStep(val);
  };

  const toggleHandler = () => {
    if (!isOtherPopoverOpen) toggleModal();
  };

  const kycCloseLogic =
    (step === 2 && budgetData?.budgets?.length) ||
    (step === 3 && beneficiariesData?.beneficiaries?.length > 1);

  const renderAdminCard = () => {
    switch (step) {
      case 0:
        return (
          <ResubmitDocument
            toggleHandler={toggleHandler}
            setSelectStep={handleStep}
            moveToAddBeneficiaries={moveToAddBeneficiaries}
            setResubmitIsCompleted={setResubmitIsCompleted}
          />
        );
      case 1:
        return (
          <Information
            setStep={handleStep}
            setIsOtherPopoverOpen={setIsOtherPopoverOpen}
            localStep={localStep}
            setLocalStep={setLocalStep}
            localTotalStep={localTotalStep}
            setOnboardingSaving={setOnboardingSaving}
            onboardingSaved={onboardingSaved}
            setOnboardingSaved={setOnboardingSaved}
            moveToAddBeneficiaries={moveToAddBeneficiaries}
            businessStep={businessStep}
            setBusinessStep={setBusinessStep}
            editAddress={editAddress}
            setEditAddress={setEditAddress}
            setIsPopoverOpen={setIsOtherPopoverOpen}
            isPopoverOpen={isOtherPopoverOpen}
          />
        );
      case 2:
        return (
          <Beneficiaries
            hideContinue={kycCloseLogic}
            setStep={handleStep}
            toggleHandler={toggleHandler}
          />
        );
      case 3:
        return (
          <BudgetModal
            toggleHandler={toggleHandler}
            withoutModal={true}
            isOpen={isOpen}
            handleBack={handleBack}
            step={budgetStep}
            setStep={setBudgetStep}
            setIsAdvance={setIsAdvance}
            isAdvance={isAdvance}
          />
        );
      case 4:
        return renderBank();

      default:
        return <Beneficiaries setStep={handleStep} toggleHandler={toggleHandler} />;
    }
  };

  const renderEmployeeCard = () => {
    switch (step) {
      case 1:
        return (
          <Informations
            setStep={handleStep}
            setIsOtherPopoverOpen={setIsOtherPopoverOpen}
          />
        );
      case 2:
        return (
          <BankInfo
            setStep={handleStep}
            toggleHandler={toggleHandler}
            isLoading={isLoading}
          />
        );
      default:
        return (
          <BankInfo
            setStep={handleStep}
            toggleHandler={toggleHandler}
            isLoading={isLoading}
          />
        );
    }
  };

  const renderCard = () => {
    if (isAdmin && ['pending', 'rejected'].includes(onboardingStatus)) {
      return renderAdminCard();
    } else if (
      !isAdmin ||
      (isAdmin && !['pending', 'rejected'].includes(onboardingStatus))
    ) {
      return renderEmployeeCard();
    }
  };

  const renderBank = () => {
    switch (activeBankCard) {
      case 0:
      case 1:
        // BankAccount Last Screen
        return (
          <div className="balance">
            <AccountInfo
              toggleHandler={toggleHandler}
              setStep={setStep}
              setSelectStep={setSelectStep}
              setActiveBankCard={setActiveBankCard}
            />
          </div>
        );
      case 3:
        return (
          <div className="balance">
            <BankAccount
              setActiveCard={setActiveBankCard}
              setIsOtherPopoverOpen={setIsOtherPopoverOpen}
            />
          </div>
        );
      case 2:
        return (
          <div className="balance">
            <DebitCreditCard
              setActiveBankCard={setActiveBankCard}
              setStep={setStep}
              setSelectStep={setSelectStep}
              setIsOtherPopoverOpen={setIsOtherPopoverOpen}
            />
          </div>
        );
      default:
        return (
          <div className="balance">
            <Balance setActiveCard={setActiveBankCard} handleSubmit={handleSubmit} />
          </div>
        );
    }
  };

  const handleBack = () => {
    if (companyStatus === 'rejected' && step < 1 && resubmitIsCompleted)
      return closeOnFinish();
    if (!isAdmin || (isAdmin && !['pending', 'rejected'].includes(onboardingStatus))) {
      if (['pending', 'rejected'].includes(onboardingStatus) || step === 1)
        handleBackFunction();
    } else if (isAdvance) {
      if (budgetStep > 1) {
        setBudgetStep(budgetStep - 1);
      }
    } else {
      if (step === 2 && stepOneCompleted) {
        return;
      } else {
        if (
          step === 2 &&
          (!user?.data?.user?.company?.businessType || !stepOneCompleted)
        ) {
          handleBackFunction();
        }
        if (step === 3) {
          handleBackFunction();
        }
        if (step === 0) {
          handleBackFunction();
        }
      }
    }
  };

  const handleBackFunction = () => {
    const processStep = localStorage.getItem('setStep');

    if (step < 2 || isLoading) return toggleHandler();

    if (activeBankCard >= 0) {
      if (activeBankCard >= 2) {
        if (activeBankCard === 2) {
          setActiveBankCard(-1);
        } else {
          setActiveBankCard(activeBankCard - 1);
        }
      } else {
        setActiveBankCard(activeBankCard - 1);
      }
    }

    if (!isAdmin || (isAdmin && !['pending', 'rejected'].includes(onboardingStatus))) {
      if (step > 1 && processStep > 0) {
        setStep(step - 1);
        setSelectStep(step - 1);
        // localStorage.setItem('setStep', JSON.stringify(+processStep - 1));
      }
    } else {
      setTimeout(() => {
        if (step > 1 && processStep > 0) {
          if (
            step === 2 &&
            user?.data?.user?.company?.businessType &&
            companyData?.dateOfRegistration &&
            companyData?.utilityBill?.code &&
            companyData?.director?.percentageOwned &&
            companyData?.director?.dob
          ) {
            return;
          } else {
            setStep(step - 1);
            setSelectStep(step - 1);
            // localStorage.setItem('setStep', JSON.stringify(+processStep - 1));
          }
        }
      }, 500);
    }
  };
  const handleBusinessDetailsBack = () => {
    if (
      businessStep === 1 &&
      companyData.businessType &&
      companyData?.dateOfRegistration &&
      companyData?.utilityBill?.code &&
      companyData?.director?.percentageOwned &&
      companyData?.director?.dob
    )
      return;
    if (
      businessStep === 4 &&
      companyData.director &&
      companyData?.dateOfRegistration &&
      companyData?.utilityBill?.code &&
      companyData?.director?.percentageOwned &&
      companyData?.director?.dob
    )
      return;
    if (
      businessStep === 4 &&
      invitedDirectors?.length &&
      companyData?.dateOfRegistration &&
      companyData?.utilityBill?.code &&
      companyData?.director?.percentageOwned &&
      companyData?.director?.dob
    )
      return;
    if (
      businessStep === 3 &&
      invitedDirectors?.length &&
      companyData?.dateOfRegistration &&
      companyData?.utilityBill?.code &&
      companyData?.director?.percentageOwned &&
      companyData?.director?.dob
    )
      return setBusinessStep(4);
    if (
      localStep === 3 &&
      companyData?.address?.city &&
      ![1, 2, 3, 4].includes(businessStep)
    )
      return;
    if (localStep === 3 && ![1, 2, 3, 4].includes(businessStep))
      return setLocalStep(localStep - 1);
    if ([3].includes(businessStep)) return setBusinessStep(1);
    return setBusinessStep(businessStep - 1);
  };

  const fullStep = () => {
    if (isAdmin && ['pending', 'rejected'].includes(onboardingStatus)) {
      return 4;
    } else if (
      !isAdmin ||
      (isAdmin && !['pending', 'rejected'].includes(onboardingStatus))
    ) {
      return 2;
    }
  };

  const handleClose = () => {
    if (!isOtherPopoverOpenwithModal) return toggleHandler();
    if (onboardingSaved) {
      if (hasBeneficiaries) {
        localStorage.setItem('setStep', JSON.stringify(2));
        setStep(3);
      } else if (hasBudgets && hasBeneficiaries) {
        return;
      } else {
        localStorage.setItem('setStep', JSON.stringify(1));
        setStep(2);
      }
    }
  };

  const moveToAddBeneficiaries = () => {
    if (beneficiariesData?.beneficiaries?.length > 1) {
      localStorage.setItem('setStep', JSON.stringify(2));
      setStep(3);
      handleStep(3);
      setStepOneCompleted(true);
    } else {
      localStorage.setItem('setStep', JSON.stringify(1));
      setStep(2);
      handleStep(2);
      setStepOneCompleted(true);
    }
  };

  // Rejected Modal
  const closeOnFinish = () => {
    if (!hasBeneficiaries) {
      localStorage.setItem('setStep', JSON.stringify(1));
      setSelectStep(2);
    } else if (hasBudgets) {
      localStorage.setItem('setStep', JSON.stringify(3));
      setSelectStep(4);
    } else {
      localStorage.setItem('setStep', JSON.stringify(2));
      setSelectStep(3);
    }
  };

  const handleKycClose = () => {
    if (!isAdmin && userStatus === 'active')
      window.setTimeout(function () {
        location.reload();
      }, 500);
    toggleHandler();
  };

  return (
    <div>
      <Modal show={isOpen} onClose={toggleHandler}>
        <div className="content">
          {step !== 1 ||
          (step === 1 &&
            (!isAdmin ||
              (isAdmin && !['pending', 'rejected'].includes(onboardingStatus)))) ? (
            <div className="card-modal-header d-flex flex-row">
              <>
                {isClose ? (
                  <div className="d-flex align-items-center cursor" onClick={handleBack}>
                    <LeftIcon />
                    <span className="ps-1">Back</span>
                  </div>
                ) : (
                  <>
                    <div className="d-flex align-items-center cursor">
                      {step < 2 || isLoading || kycCloseLogic ? (
                        <CloseOutlined onClick={handleKycClose} />
                      ) : (
                        <LeftIcon className="cursor" onClick={handleBack} />
                      )}
                      <span
                        className="ps-1"
                        onClick={
                          step < 2 || isLoading || kycCloseLogic
                            ? handleKycClose
                            : handleBack
                        }
                      >
                        {step < 2 || isLoading || kycCloseLogic ? 'Close' : 'Back'}
                      </span>
                    </div>
                    {!isLoading && (
                      <div className="modal-pagination ms-auto">
                        {step !== 0 && (
                          <>
                            <span className="active-text">{step}</span>
                            of
                            <span>{fullStep()}</span>
                          </>
                        )}
                      </div>
                    )}
                  </>
                )}
              </>
            </div>
          ) : (
            <div className="card-modal-header d-flex flex-row">
              <>
                {isClose ? (
                  <div
                    className="d-flex align-items-center cursor"
                    onClick={handleBusinessDetailsBack}
                  >
                    <LeftIcon />
                    <span className="ps-1">Back</span>
                  </div>
                ) : (
                  <>
                    <div className="d-flex align-items-center cursor">
                      {localStep === 1 || onboardingSaving || onboardingSaved ? (
                        <CloseOutlined onClick={handleClose} />
                      ) : (
                        <LeftIcon
                          className="cursor"
                          onClick={handleBusinessDetailsBack}
                        />
                      )}
                      {localStep === 1 || onboardingSaving || onboardingSaved ? (
                        <span className="ps-1" onClick={handleClose}>
                          Close
                        </span>
                      ) : (
                        <span className="ps-1" onClick={handleBusinessDetailsBack}>
                          Back
                        </span>
                      )}
                    </div>
                    {!onboardingSaved && (
                      <div className="modal-pagination ms-auto">
                        <span className="active-text">{localStep}</span>
                        of
                        <span>{localTotalStep}</span>
                      </div>
                    )}
                  </>
                )}
              </>
            </div>
          )}

          <div className="card-modal-body">{renderCard()}</div>
        </div>
      </Modal>
    </div>
  );
};
export default BusinessCard;
