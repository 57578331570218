import { ReactComponent as ApprovedTransaction } from 'assets/icons/budget-analytics/approved-transactions.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/budget-analytics/chevron-right.svg';
import { ReactComponent as Invoices } from 'assets/icons/budget-analytics/invoices.svg';
import { ReactComponent as PendingCards } from 'assets/icons/budget-analytics/pending-cards.svg';
import { ReactComponent as PendingRequest } from 'assets/icons/budget-analytics/pending-requests.svg';
import classNames from 'classnames';
import OverviewCard from 'components/OverviewCards';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import AreaChartView from '../../../components/charts/AreaChart';
import BarChartView from '../../../components/charts/BarChart';
import PieChartView from '../../../components/charts/PieChart';
import SubHeader from './components/SubHeader';
import TotalAmount from './components/TotalAmount';
import OverviewLoader from './Loader';

import { Tooltip } from 'antd';
import { format } from 'date-fns';
import CurrencyFormat from 'react-currency-format';
import {
  getAccountReauthorizationCode,
  getCompanyStats,
  getPaymentLink,
  sendMonoAuthCode,
  verifyPaymentReference,
} from 'redux/actions/CompaniesAction';
import { RESET_BLOCK_DATE_RANGE } from 'redux/reducers/OverviewReducer';
import { allPermissions } from 'utils/AllowedTo';
import {
  buildChartDataForExpenseHead,
  buildChartDataFromSpenders,
  buildChartDataFromTransaction,
  getCurrency,
  getPercentage,
} from 'utils/helper';
import { getFromAndTo, numFormatter } from 'utils/utility';
import FundsModal from './FundsModal';
import StatementModal from './StatementModal';

import DateRangeFilter from 'components/DateRangeFilter';
import SwapModal from 'pages/Cash/Overview/components/SwapModal';
import { getCategoryStats, getVendorStats } from 'redux/actions/AnalyticsAction';

const Overview = ({ company, isLoading, accounts }) => {
  // TransactionsAction top header
  const history = useHistory();
  const dispatch = useDispatch();
  const [toggleBalance, setToggleBalance] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [insideModalClick, setInsideModalClick] = useState(false);
  const [openSwapModal, setOpenSwapModal] = useState(false);
  const [monoAuthCode, setMonoAuthCode] = useState(null);
  const [fundStep, setFundStep] = useState(1);
  const [reauthorizationCode, setReauthorizationCode] = useState(null);
  const [statementModal, setStatementModal] = useState(false);
  const seeBreakDown = () => history.push('/accounts');
  const closeHandler = () => {
    if (!insideModalClick) setIsModalOpen(false);
    setFundStep(1);
    history.push('/');
    setPaymentFailed(false);
    setPaymentVerified(false);
  };
  const location = useLocation();
  const [searchParams] = useState(new URLSearchParams(location.search));

  const {
    getStatistics: { data, loading },
    getPaymentLink: { data: dataPaymentLink, loading: loadingPaymentLink },
    verifyPaymentReference: {
      data: verifyPaymentReferenceData,
      loading: verifyingPayment,
    },
    getAccountReauthorizationCode: {
      data: reauthorizationCodeData,
      loading: fetchingReauthenticationCode,
    },
  } = useSelector(({ companies }) => companies);

  const {
    getCategoryStats: {
      data: categoryStats,
      loading: categoryStatsLoading,
      success: categorySuccess,
    },
  } = useSelector(({ analytics }) => analytics);

  const {
    user: {
      data: {
        user: { firstName, lastName, company: { code: companyCode } = {} } = {},
      } = {},
    },
  } = useSelector(({ auth }) => auth);

  const {
    getVendorStats: {
      data: vendorStats,
      loading: vendorStatsLoading,
      success: vendorSuccess,
    },
  } = useSelector(({ analytics }) => analytics);

  const { isAdmin } = allPermissions();

  // const handleToggleBalance = () => {
  //   if (toggleBalance === 0) return setToggleBalance(1);
  //   return setToggleBalance(0);
  // };
  const defaultCurrency = toggleBalance === 0 ? 'NGN' : 'USD';
  const balanceName = toggleBalance === 0 ? 'Naira' : 'Dollar';

  const { selectedDateRange } = useSelector(({ overview }) => overview);

  const [selectedRange, setSelectedRange] = useState('Today');

  useEffect(() => {
    setSelectedRange(selectedDateRange?.val);
  }, [selectedDateRange.range]);

  const handleSelectRange = (title, date) => {
    const { from, to } = getFromAndTo(date);
    const payload = {
      id: companyCode,
      params: { from, to },
    };
    if (company.onboardingStatus === 'approved') {
      dispatch(getCompanyStats(payload));
      setSelectedRange(title);
    }
  };

  const [overviewTotal, setOverviewTotal] = useState({
    totalDisbursed: {
      amount: 0,
      percentage: 0,
    },
    totalBalance: {
      amount: 0,
      percentage: 0,
    },
    topSpenders: {
      [defaultCurrency]: [
        {
          amount: 0,
          percentage: 0,
          firstName: '',
        },
      ],
    },
  });
  const [chartData, setChartData] = useState({
    chart: [],
    totalSpent: 0,
    totalPercentage: 0,
  });
  const [dateRange, setDateRange] = useState({ from: null, to: null });

  const handleSwapping = () => {
    setOpenSwapModal((prevValue) => !prevValue);
  };

  const handleRange = (val) => {
    switch (val) {
      case 1:
        setChartData({
          chart: [],
          totalSpent: 0,
          totalPercentage: 0,
        });
        setOverviewTotal({
          totalDisbursed: { amount: 0, percentage: 0 },
          totalBalance: { amount: 0, percentage: 0 },
          topSpenders: [],
        });
        return;
      case 2:
        setChartData({
          chart: [],
          totalSpent: 0,
          totalPercentage: 0,
        });
        setOverviewTotal({
          totalDisbursed: { amount: 0, percentage: 0 },
          totalBalance: { amount: 0, percentage: 0 },
          topSpenders: [],
        });
        return;
      case 3:
        setChartData({
          chart: [],
          totalSpent: 0,
          totalPercentage: 0,
        });
        setOverviewTotal({
          totalDisbursed: { amount: 0, percentage: 0 },
          totalBalance: { amount: 0, percentage: 0 },
          topSpenders: [],
        });
        return;
    }
  };
  useEffect(() => {
    handleRange(Math.floor(Math.random() * 3) + 1);
    const { startDate, endDate } = selectedDateRange?.range[0];
    setDateRange({
      from: format(startDate, 'yyyy-MM-dd') + ' 00:00:00',
      to: format(endDate, 'yyyy-MM-dd') + ' 23:59:59',
    });
  }, [selectedDateRange]);

  let pendingTasks = [
    data?.pendingAmountsAndCounts?.transactions?.totalCount && {
      description:
        'Payments awaiting to be reviewed, you can request for more information from the requesters',
      icon: ApprovedTransaction,
      total: (
        <CurrencyFormat
          prefix={getCurrency(defaultCurrency)}
          value={numFormatter(
            data?.pendingAmountsAndCounts?.transactions?.[defaultCurrency]?.amount /
              100 || 0,
          )}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      title:
        data?.pendingAmountsAndCounts?.transactions?.totalCount +
        ` Transaction${
          data?.pendingAmountsAndCounts?.transactions?.totalCount > 1 ? 's' : ''
        } to review`,
      link: '/transactions/requests',
    },
    data?.pendingAmountsAndCounts?.fundRequests?.totalCount && {
      description:
        'Fund request awaiting to be reviewed, you can request for more information from the requesters',
      icon: PendingRequest,
      total: (
        <CurrencyFormat
          prefix={getCurrency(defaultCurrency)}
          value={numFormatter(
            data?.pendingAmountsAndCounts?.fundRequests?.[defaultCurrency]?.amount /
              100 || 0,
          )}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      title:
        data.pendingAmountsAndCounts?.fundRequests?.totalCount +
        ` Fund request${
          data.pendingAmountsAndCounts?.fundRequests?.totalCount > 1 ? 's' : ''
        } to review`,
      link: '/requests/funds?status=pending',
    },
    data?.pendingAmountsAndCounts?.cards?.totalCount && {
      description: 'Physical or virtual cards requests awaiting to be reviewed',
      icon: PendingCards,
      total: (
        <CurrencyFormat
          prefix={getCurrency(defaultCurrency)}
          value={numFormatter(
            data?.pendingAmountsAndCounts?.cards?.[defaultCurrency]?.amount / 100 || 0,
          )}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      title:
        data?.pendingAmountsAndCounts?.cards?.totalCount +
        ` card Request${
          data?.pendingAmountsAndCounts?.cards?.totalCount > 1 ? 's' : ''
        } pending`,
      link: '/cards/all-cards?status=pending',
    },
    data?.pendingAmountsAndCounts?.reimbursements?.totalCount && {
      description:
        'Reimbursement requests awaiting to be reviewed, you can request for more information from the requesters',
      icon: PendingRequest,
      total: (
        <CurrencyFormat
          prefix={getCurrency(defaultCurrency)}
          value={numFormatter(
            data?.pendingAmountsAndCounts?.reimbursements?.[defaultCurrency]?.amount /
              100 || 0,
          )}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      title:
        data?.pendingAmountsAndCounts?.reimbursements?.totalCount +
        ` Reimbursement request${
          data?.pendingAmountsAndCounts?.reimbursements?.totalCount > 1 ? 's' : ''
        } pending`,
      link: '/requests/reimbursements?status=pending',
    },
    data?.pendingAmountsAndCounts?.invoices?.totalCount && {
      description:
        'Invoices due awaiting payment, you can send a reminder to the customers or mark as paid',
      icon: Invoices,
      total: (
        <CurrencyFormat
          prefix={getCurrency(defaultCurrency)}
          value={numFormatter(
            data?.pendingAmountsAndCounts?.invoices?.[defaultCurrency]?.amount / 100 || 0,
          )}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      title:
        data?.pendingAmountsAndCounts?.invoices?.totalCount +
        ` Invoice${
          data.pendingAmountsAndCounts?.invoices?.totalCount > 1 ? 's' : ''
        } pending payment`,
      link: '/receivables/invoices?status=pending',
    },
    data?.pendingAmountsAndCounts?.bills?.totalCount && {
      description:
        'Your bills due, you can pay now or mark as paid if they are already paid',
      icon: Invoices,
      total: (
        <CurrencyFormat
          prefix={getCurrency(defaultCurrency)}
          value={numFormatter(
            data?.pendingAmountsAndCounts?.bills?.[defaultCurrency]?.amount / 100 || 0,
          )}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      title: ' bills(s) due',
      link: '/expenses/bills?status=pending',
    },
    data?.pendingAmountsAndCounts?.payments?.totalCount && {
      description: 'Your schedule transaction that are close to their payments date',
      icon: PendingRequest,
      total: (
        <CurrencyFormat
          prefix={getCurrency(defaultCurrency)}
          value={numFormatter(
            data?.pendingAmountsAndCounts?.payments?.[defaultCurrency]?.amount / 100 || 0,
          )}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      title:
        data?.pendingAmountsAndCounts?.payments?.totalCount +
        ` Payments${
          data.pendingAmountsAndCounts?.payments?.totalCount > 1 ? 's' : ''
        } due tomorrow`,
      link: '/transactions/scheduled?status=active&next_transfer=' + new Date(),
    }, // should be tomorrow tho + 1
  ].filter((item) => !!item);

  let alerts = [
    data?.alerts?.uncategorizedTransactions && {
      description:
        'Uncategorized transactions, you can categorize them to have better insights on your finances',
      icon: PendingCards,
      title:
        data.alerts?.uncategorizedTransactions +
        ` Uncategorized transaction${
          data.alerts?.uncategorizedTransactions > 1 ? 's' : ''
        }`,
      link: '/transactions/payments?status=uncategorized',
    },
    data?.alerts?.policiesViolated && {
      description:
        'Payments or operations that violate the rules and policies put in place, click to review them and take actions',
      icon: PendingRequest,
      title:
        data.alerts?.policiesViolated +
        ` Polic${data.alerts?.policiesViolated > 1 ? 'ies' : 'y'} violated`,
      link: '/requests/reimbursements?status=pending',
    },
    data?.alerts?.activeBudgets && {
      description: 'Your current active budgets',
      icon: ApprovedTransaction,
      title:
        data.alerts?.activeBudgets +
        ` Active budget${data.alerts?.activeBudgets > 1 ? 's' : ''}`,
      link: '/expenses?status=active',
    },
    data?.alerts?.lowBudgets && {
      description:
        'Budgets low on funds can make your teams or payments fail disrupting your work and your teams work',
      icon: ApprovedTransaction,
      title: `Budget${data?.alerts?.lowBudgets > 1 ? 's are' : ' is'} low on fund`,
      link: '/expenses?status=low',
    },
  ].filter((item) => !!item);

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_BLOCK_DATE_RANGE, blockType: 'selectedDateRange' });
    };
  }, []);

  useEffect(() => {
    if (
      company.code &&
      dateRange?.from !== null &&
      company.onboardingStatus === 'approved'
    ) {
      const { from, to } = dateRange;
      dispatch(
        getCompanyStats({
          id: company.code,
          params: { from, to },
        }),
      );
    }
  }, [company.code, dateRange]);

  const createDatePayload = (date) => {
    let { from, to } = getFromAndTo(date);
    return {
      params: { from, to },
    };
  };

  useEffect(() => {
    const payload = createDatePayload([selectedDateRange?.range[0]]);
    if (company.onboardingStatus === 'approved') {
      dispatch(getCategoryStats(payload));
      dispatch(getVendorStats(payload));
    }
  }, []);

  useEffect(() => {
    if (monoAuthCode) dispatch(sendMonoAuthCode({ code: company.code, monoAuthCode }));
  }, [monoAuthCode]);

  useEffect(() => {
    if (data) {
      const { disbursed, available, topSpenders, chart, totalSpent } = data;
      setOverviewTotal({
        totalDisbursed: {
          amount: disbursed[defaultCurrency]?.amount,
          percentage: getPercentage(1000, disbursed[defaultCurrency]?.amount),
        },
        totalBalance: {
          amount: (available && available[defaultCurrency]) || [],
          percentage: getPercentage(1000, available[defaultCurrency]),
        },
        topSpenders: topSpenders || [],
      });
      setChartData({
        chart: buildChartDataFromTransaction(chart[defaultCurrency] || []),
        totalSpent: (totalSpent && totalSpent[defaultCurrency]) || [],
        totalPercentage: 0,
      });
    }
  }, [data, defaultCurrency]);

  const initiatePayment = (payload) => {
    dispatch(getPaymentLink({ code: company.code, payload }));
  };

  const [paymentVerified, setPaymentVerified] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);

  const verifyPayment = (reference) => {
    dispatch(verifyPaymentReference({ code: company.code, reference }));
  };

  const getReauthorizationCode = (authorizationCode) => {
    dispatch(getAccountReauthorizationCode({ code: company.code, authorizationCode }));
  };

  useEffect(() => {
    if (reauthorizationCodeData && reauthorizationCodeData?.token) {
      setReauthorizationCode(reauthorizationCodeData?.token);
    }
  }, [reauthorizationCodeData]);

  useEffect(() => {
    if (dataPaymentLink && dataPaymentLink?.payment_link) {
      window.location = dataPaymentLink?.payment_link;
    }
  }, [dataPaymentLink]);

  useEffect(() => {
    if (verifyPaymentReferenceData) {
      if (verifyPaymentReferenceData?.status === 'success') {
        setPaymentFailed(false);
        setPaymentVerified(true);
      } else {
        setPaymentVerified(false);
        setPaymentFailed(true);
      }
    }
  }, [verifyPaymentReferenceData]);

  useEffect(() => {
    if (searchParams.get('funding') === 'true') {
      setIsModalOpen(true);
      setFundStep(1);
    }
    if (searchParams.get('isGenerateStatement')) {
      setStatementModal(true);
    }
  }, [searchParams]);

  if (
    loading ||
    loadingPaymentLink ||
    verifyingPayment ||
    fetchingReauthenticationCode ||
    isLoading
  ) {
    return <OverviewLoader />;
  }

  const goTo = (path) => {
    history.push(path);
  };

  const handleRefresh = () => {
    const { from, to } = dateRange;
    if (company.onboardingStatus === 'approved')
      dispatch(getCompanyStats({ id: company.code, params: { from, to } }));
  };

  const closeStatementModal = () => {
    setStatementModal(false);
  };

  const openStatementModal = () => {
    setStatementModal(true);
  };

  const getSpendersIcons = (spenders) => {
    if (!(Array.isArray(spenders) && spenders.length)) return null;
    return [
      ...spenders.slice(0, 2).map((spender, index) => (
        <Tooltip
          placement="top"
          title={`${spender.firstName} ${spender.lastName}`}
          key={spender.firstName}
          color={'#000'}
        >
          <div className="icon-wrappers" key={index}>
            <span className={`arrow-wrapper ${index === 0 ? ' active' : ''}`}>
              {spender.firstName.charAt(0)}
              {spender.lastName.charAt(0)}
            </span>
          </div>
        </Tooltip>
      )),
      spenders.length > 2 && (
        <Tooltip
          placement="top"
          title={spenders
            .slice(2)
            .map((spender) => `${spender.firstName} ${spender.lastName}`)
            .join(', ')}
          key={'the-rest90'}
          color={'#000'}
        >
          <div className="icon-wrappers counter">
            <span className="arrow-wrapper">+{spenders.length - 2}</span>
          </div>
        </Tooltip>
      ),
    ];
  };

  return (
    <div className="page-wrapper pb-5">
      <div className="w-100">
        <SubHeader
          handleRefresh={handleRefresh}
          openModal={openStatementModal}
          openSwapModal={handleSwapping}
        />

        <FundsModal
          closeHandler={closeHandler}
          isModalOpen={isModalOpen}
          setInsideModalClick={setInsideModalClick}
          fundType={toggleBalance}
          fundStep={fundStep}
          setFundStep={setFundStep}
          setMonoAuthCode={setMonoAuthCode}
          handleBack={() => setFundStep(1)}
          accounts={company.accounts}
          initiatePayment={initiatePayment}
          verifyPayment={verifyPayment}
          paymentVerified={paymentVerified}
          paymentFailed={paymentFailed}
          getReauthorizationCode={getReauthorizationCode}
          reauthorizationCode={reauthorizationCode}
        />
        <StatementModal isModalOpen={statementModal} closeHandler={closeStatementModal} />

        <SwapModal
          companyCode={company.code}
          accounts={accounts}
          isOpen={openSwapModal}
          closeHandler={handleSwapping}
        />
        <div className="overview-data position-relative onDesktop">
          <TotalAmount
            type={'Good day'}
            title={`${firstName} ${lastName}`}
            subTitle={'Stay in control'}
          />

          <TotalAmount
            type={`${balanceName} total balance`}
            title={
              <>
                <CurrencyFormat
                  prefix={getCurrency(defaultCurrency)}
                  value={numFormatter(overviewTotal.totalBalance.amount / 100 || 0)}
                  displayType="text"
                  thousandSeparator={true}
                  isNumericString
                />
                {/* <div className="link-icon">
                    <NairaSign
                      className={cs('icon-btn', { ['not-active']: toggleBalance === 0 })}
                      onClick={handleToggleBalance}
                    />

                    <DollarSign
                      className={cs('icon-btn', { ['not-active']: toggleBalance === 1 })}
                      onClick={handleToggleBalance}
                    />
                  </div> */}
              </>
            }
            handleClick={isAdmin ? seeBreakDown : null}
            widthLink={isAdmin && 'See breakdown'}
          />
          <>
            <div className="total-amount-wrapper open-box-announcement pinky black-lines">
              <p className="card-type-title text-grey">New ✨</p>
              <h2 className="card-title-amount">Bujeti smart cards</h2>
              <p className="card-disabled-title"></p>
              <span
                className="card-disabled-link"
                onClick={() => {
                  window.open('https://www.bujeti.com/corporate-cards', '_blank');
                }}
              >
                Learn more
              </span>
            </div>
          </>
        </div>

        <div className="overview-data position-relative onMobile">
          <TotalAmount
            type={`${balanceName} total balance`}
            title={
              <>
                <CurrencyFormat
                  prefix={getCurrency(defaultCurrency)}
                  value={numFormatter(overviewTotal.totalBalance.amount / 100 || 0)}
                  displayType="text"
                  thousandSeparator={true}
                  isNumericString
                />
                {/* <div className="link-icon">
                    <NairaSign
                      className={cs('icon-btn', { ['not-active']: toggleBalance === 0 })}
                      onClick={handleToggleBalance}
                    />

                    <DollarSign
                      className={cs('icon-btn', { ['not-active']: toggleBalance === 1 })}
                      onClick={handleToggleBalance}
                    />
                  </div> */}
              </>
            }
            handleClick={isAdmin ? seeBreakDown : null}
            widthLink={isAdmin && 'See breakdown'}
          />
        </div>

        <Row>
          <div className="budget-analytics mt-4">
            <div className="grid-col-2 gap-4">
              <OverviewCard
                title={
                  <div className="d-flex align-items-center gap-2">
                    <span className="text">Pending task</span>
                    <span className="pill">
                      {data?.pendingAmountsAndCounts
                        ? Object.values(data?.pendingAmountsAndCounts).reduce(
                            (total, currentValue) =>
                              total + Number(currentValue?.totalCount ?? 0),
                            0,
                          )
                        : 0}
                    </span>
                  </div>
                }
                isEmpty={!pendingTasks?.length}
              >
                {pendingTasks.map((item, index) => {
                  const Icon = item.icon;
                  return (
                    <div
                      className={classNames('card-list__item', {
                        [' border-bottom']: index !== pendingTasks.length - 1,
                      })}
                      key={index}
                      onClick={() => {
                        return goTo(item.link);
                      }}
                    >
                      <div className="card-list__content d-flex align-items-center ">
                        <Icon style={{ width: 28 }} />
                        <span className="card-list__text">
                          {item.title}
                          <br />
                          <div className="card-list__subtext">
                            For a total amount of {item.total}
                          </div>
                        </span>
                      </div>

                      <ChevronRight />
                    </div>
                  );
                })}
              </OverviewCard>

              <OverviewCard
                title={
                  <div className="d-flex align-items-center gap-2 justify-content-between w-100">
                    <div className="d-flex gap-2 align-items-center">
                      <span className="text">Things you should know</span>
                    </div>
                  </div>
                }
                isEmpty={!alerts?.length}
              >
                {alerts?.map(({ icon: Icon, link, title, description }, index) => (
                  <div
                    className={classNames('card-list__item', {
                      [' border-bottom']: index !== alerts.length - 1,
                    })}
                    key={'alert-' + index}
                    onClick={() => {
                      return goTo(link);
                    }}
                  >
                    <div className="card-list__content">
                      <Icon style={{ width: 28 }} />
                      <span className="card-list__text">{title}</span>
                    </div>
                    <ChevronRight />
                  </div>
                ))}
              </OverviewCard>
            </div>

            <div className="d-flex flex-wrap justify-content-between align-items-center insights-title">
              <div>
                <span className="text">Expenses insights</span>
                <div className="small-text">
                  Get a detailed view of your spendings over a given period of time.
                </div>
              </div>

              <div>
                <DateRangeFilter
                  handleSelectRange={handleSelectRange}
                  selectedRange={selectedRange}
                />
              </div>
            </div>

            <div className="grid-col-2 gap-4 mt-4">
              <OverviewCard
                title={
                  <div className="d-flex align-items-center gap-2">
                    <span className="text">Top spenders</span>
                  </div>
                }
              >
                <BarChartView
                  totalSpent={2000}
                  currency={defaultCurrency}
                  totalPercentage={100}
                  data={buildChartDataFromSpenders(
                    overviewTotal?.topSpenders[defaultCurrency] || [],
                  )}
                  showTotalSpent={false}
                  // size='big'
                />
              </OverviewCard>

              <OverviewCard
                title={
                  <div className="d-flex align-items-center gap-2">
                    <span className="text">Expenses trend</span>
                  </div>
                }
              >
                <AreaChartView
                  totalSpent={data?.totalSpent[defaultCurrency]}
                  currency={defaultCurrency}
                  totalPercentage={100}
                  data={buildChartDataFromTransaction(chartData?.chart)}
                />
              </OverviewCard>

              <OverviewCard
                title={
                  <div className="d-flex align-items-center gap-2">
                    <span className="text">Top vendors</span>
                  </div>
                }
              >
                <PieChartView
                  isLoading={vendorStatsLoading}
                  success={vendorSuccess}
                  data={{
                    analytics: buildChartDataForExpenseHead(
                      vendorStats?.analytics || [],
                      'vendors',
                    ),
                  }}
                  title="Vendors"
                  totalSpent={
                    (vendorStats?.totalSpent ||
                      vendorStats?.analytics.reduce(
                        (total, { transactionVolume }) =>
                          total + Number(transactionVolume),
                        0,
                      )) / 100
                  }
                  currency={defaultCurrency}
                />
              </OverviewCard>

              <OverviewCard
                title={
                  <div className="d-flex align-items-center gap-2">
                    <span className="text">Top categories</span>
                  </div>
                }
              >
                <PieChartView
                  isLoading={categoryStatsLoading}
                  success={categorySuccess}
                  data={{
                    analytics: buildChartDataForExpenseHead(
                      categoryStats?.analytics?.slice(0, 6) || [],
                      'categories',
                    ),
                  }}
                  title="Categories"
                  totalSpent={
                    (categoryStats?.totalSpent ||
                      categoryStats?.analytics.reduce(
                        (total, { transactionVolume }) =>
                          total + Number(transactionVolume),
                        0,
                      )) / 100
                  }
                  currency={defaultCurrency}
                />
              </OverviewCard>
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
};
export default Overview;
