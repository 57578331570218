import { subYears } from 'date-fns';

export const SELECT_DATE_RANGE = 'SELECT_DATE_RANGE';
export const SELECT_DATE_RANGE_TOPBAR = 'SELECT_DATE_RANGE_TOPBAR';

export const RESET_BLOCK_DATE_RANGE = 'RESET_BLOCK_DATE_RANGE';
export const RESET_FLAGS_CARD = 'RESET_FLAGS_CARD';

Date.prototype.addDays = function (days) {
  // Add days to given date
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

const today = new Date();
const initialState = {
  selectedDateRange: {
    range: [{ startDate: today.addDays(-30), endDate: today }],
    val: 'Last 30 days',
  },
  selectedDateRangeTopbar: {
    range: [{ startDate: subYears(today, 1), endDate: today }],
    val: 'Last 12 months',
  },
};

export const OverviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_DATE_RANGE:
      return { ...state, selectedDateRange: action.payload };
    case SELECT_DATE_RANGE_TOPBAR:
      return { ...state, selectedDateRangeTopbar: action.payload };
    //reset only flags(block)
    case RESET_BLOCK_DATE_RANGE:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };

    default:
      return state;
  }
};
