import React, { useEffect, useRef } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import Portal from '../Portal';
import styles from './modal.module.css';

import { Skeleton } from 'antd';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download-icon-borderless.svg';

const Modal = ({
  modalStyle,
  children,
  show,
  onClose,
  backdropStyle,
  hasAsset = false,
  assetData,
  hasDownload = false,
  handleAssetDownload,
  loading = false,
}) => {
  const modalRef = useRef(null);

  useEffect(() => {
    if (show) {
      modalRef.current.classList.add(styles.visible);
    } else {
      modalRef.current.classList.remove(styles.visible);
    }
  }, [show]);

  const handleOutsideClick = () => {
    const toastList = document.getElementsByClassName('Toastify__toast');
    setTimeout(() => {
      if (toastList.length > 0) return;
      if (onClose) onClose();
    }, 200);
  };

  return (
    <React.Fragment>
      <Portal className="modal-portal">
        <div ref={modalRef} style={backdropStyle} className={`${styles.modal__wrap}`}>
          {/* Asset Region */}
          {hasAsset && (
            <section className={`fade-in ${styles.asset__holder}`}>
              <div className="asset-viewer">
                <header className="head-region">
                  {loading ? (
                    <>
                      <span>
                        {' '}
                        <Skeleton.Input
                          active
                          style={{
                            borderRadius: '4px',
                            width: '12rem',
                            minWidth: '12rem',
                            height: 12,
                          }}
                        />
                      </span>

                      <span className="ms-auto">
                        <Skeleton.Avatar
                          active
                          size={20}
                          shape="square"
                          style={{ borderRadius: 4 }}
                        />
                      </span>
                    </>
                  ) : (
                    <>
                      <h6>{assetData?.title}</h6>
                      {hasDownload && (
                        <span className="ms-auto" onClick={handleAssetDownload}>
                          <DownloadIcon />
                        </span>
                      )}
                    </>
                  )}
                </header>

                <div className="mt-5 pt-5">{assetData?.component}</div>
              </div>
            </section>
          )}

          <div className="ms-auto d-flex position-relative">
            <OutsideClickHandler
              onOutsideClick={() => {
                handleOutsideClick();
              }}
              display={'flex'}
            >
              <div style={modalStyle} className={`${styles.modal}`}>
                {children}
              </div>
            </OutsideClickHandler>
          </div>
        </div>
      </Portal>
    </React.Fragment>
  );
};

export default Modal;
