import React from 'react';
import Layout, { OverflowWrapLayout } from '../Layout';
import { AuthFormHeader } from 'components/Auth';
import { Form } from 'react-bootstrap';

const CompanySolution = () => {
  return (
    <Layout>
      <AuthFormHeader
        titlle="How would you like to use Bujeti?"
        subtitle="To help improve your experience, tell us what features you plan to use on the platform."
      />
      <OverflowWrapLayout>
        <Form className="w-100">
          <div></div>
        </Form>
      </OverflowWrapLayout>
    </Layout>
  );
};

export default CompanySolution;
