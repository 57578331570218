import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import Loading from 'components/UI/Loading';
import { toastError } from 'components/UI/toast';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import { getBalances, topUpBudget } from 'redux/actions/BudgetsAction';
import { getCompanyStats } from 'redux/actions/CompaniesAction';
import { getAvailableBalance, getCurrency, groupSourceOptions } from 'utils/helper';
// import { ReactComponent as BankIcon } from '../../../../assets/icons/bank-icon2.svg';
import FundTransfer from '../payment-method/FundTransfer';

const BudgetFundMethod = ({
  budgetInfo,
  setBudgetInfo,
  setIsOtherPopoverOpen = () => null,
  isUpdate,
  handleClose,
  setFundingStep,
  fundingStep,
  closeModal,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const {
    getStatistics: { data: dataStats = {}, loading: loadingStat },
    getCompany: { data: companyData = {} },
  } = useSelector(({ companies }) => companies);
  const { budgetId } = useParams();

  const {
    createBudget: { data: budgetData },
    topUpBudget: { success: topUpsuccess, loading: topUpLoading },
    getSubAccount: { error },
    getBalances: { data: balances, loading: loadingBalances },
    getSingleBudget: { data: getSingleBudgetData },
  } = useSelector(({ budgets }) => budgets);

  useEffect(() => {
    dispatch(getCompanyStats({ id: companyData.code }));
  }, [companyData.code]);

  useEffect(() => {
    if (error === 'Budget Account not found') setFundingStep(1);
  }, [error]);

  // const payOptions = [
  //   // {
  //   //   id: 'bujeti_balance',
  //   //   label: 'Bujeti Balance',
  //   //   value: (
  //   //     <div>
  //   //       Available balance:{' '}
  //   //       <CurrencyFormat
  //   //         prefix={getCurrency('NGN')}
  //   //         value={availableBalance / 100}
  //   //         displayType="text"
  //   //         thousandSeparator={true}
  //   //       />
  //   //     </div>
  //   //   ),
  //   //   icon: WalletIcon,
  //   // },
  //   {
  //     id: 'debit_bank',
  //     label: 'Bank transfer',
  //     value: 'Wire funds to this account',
  //     icon: BankIcon,
  //   },
  //   // {
  //   //   id: 'debit_bank',
  //   //   label: 'Debit bank account',
  //   //   value: 'Pull money from linked bank accounts',
  //   //   icon: BankIcon,
  //   // },
  // ];

  const [selected, setSelected] = useState('');

  const handleChangeBudgetInfo = (data) => {
    const {
      target: { name, value, rawValue },
    } = data;
    setBudgetInfo({ ...budgetInfo, [name]: rawValue ? rawValue : value });
  };

  const handleGetSign = (currency) => {
    !isUpdate && setBudgetInfo({ ...budgetInfo, currency });
  };

  useEffect(() => {
    dispatch(getBalances());
  }, []);

  useEffect(() => {
    if (location?.state?.fund) {
      const state = { ...location.state };
      state.fund = false;
      history.replace({ ...location, state });
    }
  }, []);

  const handleContinue = () => {
    if (!budgetInfo.amount) return toastError('Please enter an amount');
    if (+budgetInfo.amount <= 0) return toastError('Please enter a valid amount');
    if (!selected) return toastError('Please select one of the funding method');
    if (selected?.value) {
      const payload = {
        amount: parseInt(budgetInfo.amount, 10) * 100,
        balance: selected?.value,
        code: budgetId ?? budgetData?.code,
      };

      dispatch(topUpBudget(payload));
    }
    if (selected === 'debit_bank') {
      setFundingStep(2);
    }
  };

  const [loadPage, setLoadPage] = useState(0);

  useEffect(() => {
    if (loadPage > 1) dispatch(getBalances({ page: loadPage }));
  }, [loadPage]);

  const [hasMore, setHasMore] = useState(false);
  const [budgetList, setBudgetList] = useState([]);

  useEffect(() => {
    if (balances?.balances?.length) {
      const available_balance = getAvailableBalance(balances?.balances, 32, true, true);
      setBudgetList((prevOptions) => [...available_balance]);
    }
  }, [balances?.balances]);

  async function loadOptions(search, loadedOptions, { page }) {
    setLoadPage(page);

    let filteredOption = budgetList.filter((item) =>
      item?.name?.toLowerCase().includes(search?.toLowerCase()),
    );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  const getSelectedSource = budgetList.find(
    (item) => item?.value === getSingleBudgetData?.source?.code,
  );

  const defaultValue = {
    value: getSelectedSource?.value,
    name: getSelectedSource?.name,
    amount: getSelectedSource?.amount,
    label: (
      <div className="w-100 d-flex justify-content-between align-items-center">
        <div className="text-sm">{getSelectedSource?.name}</div>
        <span className="text-sm text-start px-2" style={{ marginLeft: 'auto' }}>
          <CurrencyFormat
            prefix={getCurrency(getSelectedSource?.currency)}
            value={getSelectedSource?.amount}
            displayType="text"
            thousandSeparator={true}
          />
        </span>
      </div>
    ),
  };

  useEffect(() => {
    if (defaultValue?.value) {
      setSelected(defaultValue);
    }
  }, [defaultValue?.value]);

  const fundingOption = (
    <>
      <h2 className="card-title w-100">Fund budget</h2>
      <p className="fund__budget-subtext mt-4">
        How would you like to fund your budget balance?
      </p>
      <div className="form-normal">
        <Row className="mb-3 mt-3">
          <CustomSelectRadio
            label="Funding Source"
            name="source"
            value={selected}
            placeholder="Select a source"
            onChange={(val) => setSelected(val)}
            // defaultValue={getSingleBudgetData?.source?.code && defaultValue}
            isLoading={loadingBalances}
            isDisabled={loadingBalances}
            loadOptions={loadOptions}
          />
        </Row>

        {/* <div className="space-y-4 fund__budget-options pt-2">
          {payOptions.map(({ label, value, icon: Icon, id }, index) => (
            <div
              className={classNames('py-3', selected === id ? 'active' : 'inactive')}
              key={index}
              onClick={() => setSelected(id)}
            >
              <Icon className="icon-wrap" />
              <div className="text-wrap">
                <h3>{label}</h3>
                <p>{value}</p>
              </div>
              <div className="check-wrap">
                {selected === id ? <CheckIcon /> : <UnCheckedIcon />}
              </div>
            </div>
          ))}
        </div> */}

        <Row className="mb-5 mt-1">
          <CustomInput
            placeholder="0.00"
            type="number"
            label="Amount *"
            isAmount
            otherCurrency={true}
            name="amount"
            onChange={handleChangeBudgetInfo}
            value={budgetInfo.amount}
            setIsOtherPopoverOpen={setIsOtherPopoverOpen}
            getTypeVal={handleGetSign}
            existAction={budgetInfo.currency || null}
            isUpdate
          />
        </Row>

        <CustomButton
          loading={topUpLoading}
          disabled={topUpLoading}
          onClick={handleContinue}
          fullWidth={true}
        >
          Fund budget
        </CustomButton>

        {!isUpdate && (
          <div className="text-center mt-3 cursor" onClick={handleClose}>
            <span>Skip for now</span>
          </div>
        )}
      </div>
    </>
  );

  const renderCard = () => {
    switch (fundingStep) {
      case 1:
        return fundingOption;
      case 2:
        return (
          <FundTransfer
            closeHandler={closeModal}
            setFundStep={setFundingStep}
            handleBack={handleClose}
            amount={budgetInfo.amount}
          />
        );

      default:
    }
  };

  return (
    <div className="information-wrapper fund__budget-modal">
      {loadingStat ? (
        <div className="d-flex">
          <div className="m-auto">
            <Loading isPage />
          </div>
        </div>
      ) : (
        renderCard()
      )}
    </div>
  );
};

export default BudgetFundMethod;
