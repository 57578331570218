import CustomButton from 'components/UI/CustomButton';
import React from 'react';
import { Container, Row } from 'react-bootstrap';

import './styles.scss';

const NoData = ({
  headerText,
  bodyText,
  onClickHandler,
  buttonLabel,
  withButton = true,
  withTwoButton = false,
  multipleButtonLabel = [],
  handleButtonOne,
  handleButtonTwo,
}) => {
  return (
    <>
      <Container className="no-data">
        <Row>
          <span className="textHeader">{headerText}</span>
        </Row>
        <Row>
          <span className="subHeaderText">{bodyText && bodyText}</span>
        </Row>
        {withButton && (
          <Row className="mt-4">
            <CustomButton fullWidth onClick={onClickHandler}>
              {buttonLabel}
            </CustomButton>
          </Row>
        )}
      </Container>
      {withTwoButton && multipleButtonLabel.length && (
        <Container className="no-data w-auto">
          <Row className="mt-4">
            <div className="d-flex with-two-button w-auto">
              <CustomButton
                onClick={handleButtonOne}
                className="mr-8 px-md-5 border-btn w-auto"
              >
                {multipleButtonLabel[0]}
              </CustomButton>
              <CustomButton onClick={handleButtonTwo} className="px-md-5 w-auto">
                {multipleButtonLabel[1]}
              </CustomButton>
            </div>
          </Row>
        </Container>
      )}
    </>
  );
};
export default NoData;
