import LineStatus from 'components/LineStatus';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { getBudgetStats } from 'redux/actions/BudgetsAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { getFromAndTo, numFormatter } from 'utils/utility';
import { getCurrency, getPercentage, percentageValue } from '../../../utils/helper';
import BudgetModal from '../BudgetModal';
import './styles.scss';

import TooltipContent from 'components/UI/tooltip-content';
import { useDispatch, useSelector } from 'react-redux';
import MigrateOldBudgetInfo from '../MigrateOldBudgetInfo';
import BudgetOverviewAnalytics from './BudgetOverviewAnalytics';

const BudgetOverview = () => {
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isAdvance, setIsAdvance] = useState(false);

  const { permissions } = allPermissions();
  const [selectedRange, setSelectedRange] = useState('Custom date range');
  const canManage = hasPermission({
    permissions,
    scopes: ['budget-*', 'budget-create', 'budget-edit'],
  });
  const { selectedDateRange } = useSelector(({ overview }) => overview);

  const {
    getSingleBudget: { data: budget = {} },
    getBudgetStat: { data: stats = {} },
  } = useSelector(({ budgets }) => budgets);

  useEffect(() => {
    setSelectedRange(selectedDateRange?.val);
  }, [selectedDateRange.range]);

  // useEffect(() => {
  //   budget.owners = [];
  //   budget.beneficiaries = [];
  //   if (beneficiariesData && budget.beneficiaries && budget.owners) {
  //     budget.beneficiaries =
  //       beneficiariesData?.beneficiaries &&
  //       beneficiariesData?.beneficiaries?.filter(({ user, isBudgetOwner }) => {
  //         if (!isBudgetOwner) return { user };
  //       });
  //     budget.owners = beneficiariesData?.owners?.filter(({ user }) => {
  //       return { user };
  //     });
  //   }
  // }, [beneficiariesData]);

  const handleSelectRange = (title, date) => {
    setSelectedRange(title);
    const { from, to } = getFromAndTo(date);
    const payload = {
      id: budget?.code,
      params: { from, to },
    };
    dispatch(getBudgetStats(payload));
  };

  const toggleHandler = () => {
    setStep(1);
    setIsOpen(!isOpen);
  };

  if (!budget) return <div />;

  const isClosed = budget.status === 'closed';

  const {
    currency,
    amount = 0,
    disbursed = 0,
    spent = 0,
    available = 0,
    bufferAmount,
  } = budget;

  const { chart = {} } = stats;

  const totalSpent = Object.values(chart).reduce((acc, next) => {
    return acc + next.amount;
  }, 0);

  // if (loading || statsLoading) return <Loading isPage color="#D28B28" />;

  return (
    <Container className="px-0 py-1">
      <div className="pb-4 pt-2">
        {canManage && (
          <Col xs={12} md={3} lg={2} className="budget-overview-amount">
            <p>Budget goal</p>
            <div className="d-flex budget-goal">
              <h6 className="goal-amount">
                <CurrencyFormat
                  prefix={getCurrency(currency)}
                  value={numFormatter(amount / 100)}
                  displayType="text"
                  thousandSeparator={true}
                  className="text-dark"
                  isNumericString
                />
              </h6>

              {bufferAmount > 0 && (
                <>
                  <span className="distance-between">+</span>
                  <h6 className="bufferamount">
                    <CurrencyFormat
                      prefix={getCurrency(currency)}
                      value={numFormatter(bufferAmount / 100)}
                      displayType="text"
                      thousandSeparator={true}
                      className="text-dark"
                      isNumericString
                    />
                    {'  '}
                    buffer
                  </h6>
                </>
              )}
              {!isClosed && (
                <div className="cursor" onClick={toggleHandler}>
                  Edit
                </div>
              )}
            </div>
          </Col>
        )}
      </div>
      <Row>
        <Col xs={12}>
          <LineStatus
            active={percentageValue(amount, spent)}
            pending={percentageValue(amount, disbursed)}
            exceeded={available < 0}
          />
        </Col>
      </Row>

      <Row className="pt-4">
        <Col xs={12} md={3} lg={2} className="budget-overview-amount mb-4">
          <TooltipContent tooltip="This is the amount you have spent">
            <p className="mt-1">
              <span className="dots active-line" />
              Spent
            </p>
          </TooltipContent>

          <h2 className="px-3">
            <CurrencyFormat
              prefix={getCurrency(currency)}
              value={numFormatter(spent / 100)}
              displayType="text"
              thousandSeparator={true}
              isNumericString
              className="currency-amount"
            />
          </h2>
        </Col>
        <Col xs={12} md={3} lg={2} className="budget-overview-amount mb-4">
          <TooltipContent tooltip="This is the amount in your card">
            <p className="mt-1">
              <span className="dots active-line disbursed-dot " />
              {canManage ? 'Allocated' : 'Total budget'}
            </p>
          </TooltipContent>

          <h2 className="px-3">
            {disbursed === 0 && !canManage ? (
              'Unlimited'
            ) : (
              <CurrencyFormat
                prefix={getCurrency(currency)}
                value={numFormatter(disbursed / 100)}
                displayType="text"
                thousandSeparator={true}
                isNumericString
                className="currency-amount"
              />
            )}
          </h2>
        </Col>
        <Col xs={12} md={3} lg={2} className="budget-overview-amount mb-4">
          <TooltipContent tooltip="The available shows your spendable balance">
            <p className="mt-1">
              <span className="dots available-dot" />
              Available
            </p>
          </TooltipContent>

          <h2 className="px-3">
            <CurrencyFormat
              prefix={getCurrency(currency)}
              value={numFormatter(available < 0 ? 0 : available / 100)}
              displayType="text"
              thousandSeparator={true}
              className="text-dark currency-amount"
              isNumericString
            />
          </h2>
        </Col>

        {available < 0 && (
          <Col xs={12} md={3} lg={2} className="budget-overview-amount mb-4">
            <TooltipContent tooltip="This is the amount you have overspent">
              <p className="mt-1">
                <span className="dots overdraft" />
                Overspent
              </p>
            </TooltipContent>

            <h2 className="px-3">
              <CurrencyFormat
                prefix={getCurrency(currency)}
                value={numFormatter((-1 * available) / 100)}
                displayType="text"
                thousandSeparator={true}
                isNumericString
                className="currency-amount"
              />
            </h2>
          </Col>
        )}
      </Row>

      <MigrateOldBudgetInfo />

      <Col lg={12} className="chartSide p-2 border-top">
        <Row>
          <Col xs={12} className="p-0">
            <BudgetOverviewAnalytics
              chartData={chart}
              totalSpent={`${totalSpent}`}
              currency={currency}
              totalPercentage={getPercentage(amount, spent)}
              beneficiaries={budget?.beneficiaries}
              budgetCode={budget?.code}
              budget={budget || {}}
              stats={stats || {}}
              budgetOwners={budget?.owners}
              selectedRange={selectedRange}
              handleSelectRange={handleSelectRange}
            />
          </Col>
        </Row>
      </Col>

      <BudgetModal
        step={step}
        setStep={setStep}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        toggleHandler={toggleHandler}
        isUpdate
        setIsAdvance={setIsAdvance}
        isAdvance={isAdvance}
      />
    </Container>
  );
};
export default BudgetOverview;
