import { Checkbox } from 'antd';
import CustomInput from 'components/UI/CustomInput';
import CustomPhoneNumberInput from 'components/UI/CustomPhoneNumberInput';
import CustomSelect from 'components/UI/CustomSelect';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getState } from 'redux/actions/CompaniesAction';
import '../styles.scss';
// import * as fs from 'fs';

const StepTwo = ({
  handleChangeCheckBox,
  handleChangeBusinessAddress,
  setBusinessAddress,
  businessAddress,
  countries,
  // states,
  // companyData,
  handleBusinessAddressPhoneNumberChange,
}) => {
  const dispatch = useDispatch();

  const {
    getCompany: { data: companyData = {} },
  } = useSelector(({ companies }) => companies);

  const [stateData, setStateData] = useState([]);
  const {
    getState: { data: states = {} },
  } = useSelector(({ companies }) => companies);

  useEffect(() => {
    if (businessAddress.country.value) dispatch(getState(businessAddress.country.value));
  }, [businessAddress.country.value]);

  useEffect(() => {
    if (states) {
      const stateOptions = states.states?.map((value) => ({
        label: value,
        value: value,
      }));

      setStateData(stateOptions);
    }
  }, [states]);

  useEffect(() => {
    if (stateData) {
      if (!businessAddress.state) {
        setBusinessAddress({
          ...businessAddress,
          state: stateData.find((i) => i.label === companyData.address?.state),
        });
      }
    }
  }, [stateData, businessAddress.country]);

  const getCountrySates = async (val) => {
    dispatch(getState(val.value));
  };
  return (
    <>
      <div className="onboardingStepDiv">
        <p className="mt-4 stage-text">Contact Details</p>

        <Row className="mb-3">
          <CustomSelect
            label="Country *"
            name="country"
            placeholder="Select country"
            onChange={(val) => {
              setBusinessAddress({ ...businessAddress, country: val });
              getCountrySates(val);
            }}
            value={businessAddress.country}
            options={countries}
          />
        </Row>
        <Row className="mb-3">
          <CustomInput
            label="Address *"
            placeholder="Enter address"
            type="text"
            name="businessAddress"
            onChange={handleChangeBusinessAddress}
            value={businessAddress.businessAddress}
            maxLength="100"
          />
        </Row>
        <Row className="mb-3 align-items-center">
          <CustomSelect
            isDisabled={!businessAddress.country?.label}
            label="State *"
            name="state"
            placeholder="State or Region"
            onChange={(val) => setBusinessAddress({ ...businessAddress, state: val })}
            value={businessAddress.state}
            options={stateData}
            md={6}
          />
          <CustomInput
            label="City *"
            placeholder="Enter name"
            type="text"
            name="city"
            onChange={handleChangeBusinessAddress}
            value={businessAddress.city}
            maxLength="50"
            md={6}
          />
        </Row>
        <Row className="mb-3">
          <CustomInput
            label="Postal code *"
            placeholder="Enter postal code"
            type="text"
            name="postalCode"
            onChange={handleChangeBusinessAddress}
            value={businessAddress.postalCode}
            maxLength="100"
          />
        </Row>
        <Row className="mb-3 margin-top">
          <Checkbox onChange={({ target: { checked } }) => handleChangeCheckBox(checked)}>
            Use my Personal contact details
          </Checkbox>
        </Row>
        <Row className="mb-3">
          <CustomPhoneNumberInput
            label="Business phone number"
            placeholder="Enter phone number"
            onChange={(localFormat, international, countryCode) =>
              handleBusinessAddressPhoneNumberChange(
                localFormat,
                international,
                countryCode,
              )
            }
            value={businessAddress.internationalFormat}
          />
        </Row>
        <Row className="mb-3">
          <CustomInput
            label="Business email address"
            placeholder="Enter email"
            type="text"
            name="businessEmail"
            onChange={handleChangeBusinessAddress}
            value={businessAddress.businessEmail}
            maxLength="250"
          />
        </Row>
      </div>
    </>
  );
};
export default StepTwo;
