import {
  BankOutlined,
  GroupOutlined,
  MoreOutlined,
  ScheduleOutlined,
} from '@ant-design/icons';
import { ArrowRight, MinusCircle } from 'assets/icons';
import DeleteDialog from 'components/DeleteDialog';
import RequestFunds from 'components/FundRequest/RequesterModal/RequestFunds';
import TopBar from 'components/TopBar';
import CustomButton from 'components/UI/CustomButton';
import CustomPopover from 'components/UI/Popover';
import { toastSuccess } from 'components/UI/toast';
import BujetiPayModal from 'components/bujetiPayModal';
import { useEffect, useState } from 'react';
import { Badge, Container } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { getCurrency, getFormattedDate } from 'utils/helper';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-icon-red.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/settings-icon.svg';
import { ReactComponent as ToggleIcon } from '../../../assets/icons/toggle-icon.svg';
import {
  activateBudget,
  deactivateBudget,
  getBudgets,
  getSingleBudgets,
} from '../../../redux/actions/BudgetsAction';
import BudgetModal from '../BudgetModal';
import WithdrawFundsModal from '../BudgetModal/WithdrawModal';
import LiquidateBudget from './LiquidateBudgetModal';
import './styles.scss';

const SelectedBudgetsHeader = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const { permissions, onboardingStatus } = allPermissions();
  const canManage = hasPermission({
    permissions,
    scopes: ['budget-*', 'budget-create', 'budget-edit'],
  });

  const canCreatePayment = hasPermission({
    permissions,
    scopes: ['transaction-*', 'transaction-create'],
  });

  const {
    getSingleBudget: { data = {} },
    deactivateBudget: { data: act, success: deactivateSuccess, loading: isDeactivating },
    activateBudget: { success: activateSuccess },
  } = useSelector(({ budgets }) => budgets);

  const [step, setStep] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isActive, setIsActive] = useState();
  const [isAdvance, setIsAdvance] = useState(false);
  const [isFunding, setIsFunding] = useState(false);
  const [selectedBudget, setSelectedBudget] = useState('');
  const [isWithdraw, setWithdraw] = useState(false);
  const [editExpiry, setEditExpiry] = useState(false);
  const [requestingFunds, setRequestingFunds] = useState(false);

  useEffect(() => {
    if (data) {
      setIsActive(data.status === 'active');
    }
    if (deactivateSuccess || activateSuccess) {
      dispatch(getBudgets());
      dispatch(getSingleBudgets({ id: data.code }));
    }
  }, [activateSuccess, deactivateSuccess, data]);

  useEffect(() => {
    if (deactivateSuccess && deleteModal) {
      toastSuccess('Budget deleted successfully');
      setTimeout(() => {
        history.push('/expenses/budgets');
        deleteToggleHandler();
      }, 1000);
    }
  }, [deactivateSuccess]);

  const toggleHandler = () => {
    setStep(1);
    setIsOpen(!isOpen);
    setEditExpiry(!editExpiry);
  };

  const toggleWithdrawHandler = () => {
    setWithdraw(!isWithdraw);
  };

  const fundHandler = () => {
    setStep(4);
    setIsOpen(!isOpen);
    setIsFunding(!isFunding);
  };

  useEffect(() => {
    if (location?.state?.fund && canManage) {
      fundHandler();
    }
  }, [location?.state?.fund]);

  const handleDelete = (balance) => {
    const payload = {
      code: data?.code,
      action: 'close',
      settlementBalance: balance ? balance : undefined,
    };
    dispatch(deactivateBudget(payload));
  };

  const deleteToggleHandler = () => setDeleteModal(!deleteModal);
  const onSwitchHandler = () => {
    if (isActive) {
      dispatch(deactivateBudget({ code: data?.code, action: 'pause' }));
    } else {
      dispatch(activateBudget(data?.code));
    }
  };

  const isClosed = data.status === 'closed';

  const handlePayment = () => {
    setSelectedBudget({
      value: data?.code,
      label: (
        <div>
          {data?.name} <br />
          <span>
            <CurrencyFormat
              prefix={getCurrency(data?.currency)}
              value={data?.available / 100}
              displayType="text"
              thousandSeparator={true}
            />
          </span>
        </div>
      ),
      name: data.name,
      amount: data.available / 100,
    });
  };

  const [isButtonToggle, setIsButtonToggle] = useState(false);

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  const [isPaymentButtonToggle, setIsPaymentButtonToggle] = useState(false);

  const handlePaymentButtonToggle = () =>
    setIsPaymentButtonToggle(!isPaymentButtonToggle);

  const isSchedule = () => {
    localStorage.setItem('schedule', 'on');
  };

  const PaymentActions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() => {
            handlePayment();
            handlePaymentButtonToggle();
          }}
        >
          <BankOutlined /> Single payment
        </div>
        <div
          className="actionLink"
          onClick={() => {
            history.push({
              pathname: '/transactions/batch-payment',
              state: { value: data.code, label: data.name, amount: data.amount / 100 },
            });
          }}
        >
          <GroupOutlined /> Batch payments
        </div>
        <div
          className="actionLink"
          onClick={() => {
            handlePayment();
            handlePaymentButtonToggle();
            isSchedule();
          }}
        >
          <ScheduleOutlined /> Schedule payment
        </div>
      </div>
    );
  };
  const Actions = () => {
    return (
      <div className="actions-dialog" style={{ marginTop: 25 }}>
        {data?.available > 0 && (
          <div
            className="actionLink"
            onClick={() => {
              toggleWithdrawHandler();
              handleButtonToggle();
            }}
          >
            <MinusCircle /> Withdraw funds
          </div>
        )}

        <div
          className="actionLink"
          onClick={() => {
            ['pending', 'rejected'].includes(onboardingStatus) ? null : onSwitchHandler(),
              handleButtonToggle();
          }}
        >
          <ToggleIcon /> {isActive ? 'Deactivate budget' : 'Activate budget'}
        </div>
        <div
          className="actionLink"
          onClick={() => {
            toggleHandler();
            handleButtonToggle();
          }}
        >
          <SettingsIcon /> Update budget
        </div>
        <div
          className="actionLink text-danger"
          onClick={() => {
            deleteToggleHandler();
            handleButtonToggle();
          }}
        >
          <DeleteIcon /> Delete budget
        </div>
      </div>
    );
  };
  const RightBar = (
    <>
      <div className="d-flex align-items-center">
        {isActive && !canCreatePayment && !isClosed && (
          <div className="ps-3 cursor">
            <CustomButton
              className="add-button"
              onClick={() => setRequestingFunds(!requestingFunds)}
            >
              Request funds
            </CustomButton>
          </div>
        )}

        {canManage && !isClosed && (
          <div className="ps-3 cursor">
            {!isActive ? (
              <CustomButton className="add-button" onClick={onSwitchHandler}>
                Activate budget
              </CustomButton>
            ) : (
              <div className="add-button add-action w-100" onClick={fundHandler}>
                Fund budget
              </div>
            )}
          </div>
        )}
        {isActive && canCreatePayment && !isClosed && (
          <div className="ps-3 cursor">
            <CustomPopover
              zIndex="1"
              content={<PaymentActions />}
              showPopover={isPaymentButtonToggle}
              clickOutside={handlePaymentButtonToggle}
            >
              <div
                className="add-button  black-button add-action "
                onClick={handlePaymentButtonToggle}
              >
                Send funds <ArrowRight color="#ffffff" className="ms-1" />
              </div>
            </CustomPopover>
          </div>
        )}

        {canManage && !isClosed && (
          <div className="ps-3 cursor mb-2 onDesktop">
            <CustomPopover
              content={<Actions />}
              showPopover={isButtonToggle}
              clickOutside={handleButtonToggle}
            >
              <div onClick={handleButtonToggle} className="cursor">
                <MoreOutlined style={{ fontSize: '110%' }} />
              </div>
            </CustomPopover>
          </div>
        )}
      </div>
    </>
  );

  return (
    <div className="headerBudgets">
      <TopBar
        headerText={
          <div>
            <div className="d-flex align-items-center fw-semibold">
              {data?.name}
              {!isActive && (
                <Badge className="text-uppercase balance-tag dark-bg text-white text-dark">
                  {isClosed ? 'closed' : 'inactive'}
                </Badge>
              )}
              {data?.schedule?.occurrence && isActive && (
                <div className="ms-2 recurring-tag ">{data?.schedule?.occurrence}</div>
              )}
            </div>
          </div>
        }
        largeTitle
        breadcrumbs={[
          { title: 'Budgets', action: () => history.push('/expenses/budgets') },
          {
            title: data?.name,
            action: () => {
              null;
            },
          },
        ]}
        isRightBar={RightBar}
      />
      <Container className="budget-overview-amount ">
        <div className="d-flex align-items-center expire-label fw-normal">
          <div className="onDesktop">
            Expires:{' '}
            {data?.schedule?.expiry_date
              ? getFormattedDate(data.schedule.expiry_date)
              : 'Never expires'}
          </div>{' '}
          {!isClosed && data?.schedule?.expiry_date && (
            <span className="cursor" onClick={toggleHandler}>
              Edit
            </span>
          )}
        </div>
      </Container>

      <BudgetModal
        editExpiry={editExpiry}
        step={step}
        setStep={setStep}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        toggleHandler={toggleHandler}
        isUpdate
        setIsAdvance={setIsAdvance}
        isAdvance={isAdvance}
        isFunding={isFunding}
        setIsFunding={setIsFunding}
      />

      {requestingFunds && (
        <RequestFunds
          isOpen={requestingFunds}
          toggleHandler={() => setRequestingFunds(!requestingFunds)}
          source={data?.code}
          newBudget={false}
          topUpAccount={false}
        />
      )}

      {isWithdraw && (
        <WithdrawFundsModal handleClose={() => setWithdraw(false)} data={data} />
      )}

      {deleteModal &&
        (data?.available > 0 ? (
          <LiquidateBudget
            isOpen={deleteModal}
            onCancel={deleteToggleHandler}
            onClick={handleDelete}
            isLoading={isDeactivating}
          />
        ) : (
          <DeleteDialog
            title="Delete budget"
            subTitle=" Are you sure you want to delete this budget? This action can not be undone."
            onCancel={deleteToggleHandler}
            onDelete={() => handleDelete()}
            isLoading={isDeactivating}
          />
        ))}

      {selectedBudget && (
        <BujetiPayModal
          selectedBudget={selectedBudget}
          setSelectedBudget={setSelectedBudget}
        />
      )}
    </div>
  );
};
export default SelectedBudgetsHeader;
