import { BankFilled, FileDoneOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { NigeriaCrest, UnitedStateCrest } from 'assets/icons';
import BanksIcons from 'assets/icons/banks';
import CurrencyFormat from 'react-currency-format';
import { FormattedCurrency, getCurrency } from 'utils/helper';

const CURRENCY_TITLE = {
  NGN: 'Naira balance',
  USD: 'Dollar balance',
};

export const ICONS = {
  NGN: <NigeriaCrest />,
  USD: <UnitedStateCrest />,
};

export const AccountCard = ({
  code,
  name,
  balance,
  number,
  currency,
  bankName,
  accountName,
  openBalance,
  openDetailsModal,
  openStatementModal,
  logo,
  isSubaccount = false,
  isDirectDebit = false,
  isDirectDebitActive = false,
}) => {
  return (
    <div
      className="bjt-col-md-4 account col-12"
      onClick={() =>
        openBalance(code, {
          name,
          balance,
          number,
          currency,
          bankName,
          accountName,
          code,
          isSubaccount,
        })
      }
    >
      <div className="account-header d-flex">
        <Tooltip placement="bottom" title={bankName} color={'#000'}>
          <img
            src={BanksIcons[bankName?.toLowerCase()] ?? logo}
            style={{
              padding: 3,
              width: 35,
              height: 35,
              borderStyle: 'solid',
              borderWidth: 2,
              borderRadius: 8,
              borderColor: '#E7E5E4',
              color: '#44403C',
              marginRight: 10,
            }}
            alt={bankName}
          />
        </Tooltip>
        <div style={{ display: 'grid' }}>
          <span className="account-name">{name}</span>
          <span className="account-number">{number}</span>
        </div>
      </div>
      <div className="account-balance mt-3 mb-3">
        <CurrencyFormat
          prefix={getCurrency(currency)}
          value={balance / 100}
          displayType="text"
          isNumericString
          thousandSeparator={true}
          style={{
            fontSize: 20,
          }}
        />
      </div>
      <div className="account-actions d-flex flex-wrap gap-1 align-items-center">
        <Tooltip
          placement="bottom"
          title={'View transfers'}
          key={'transfers'}
          color={'#000'}
        >
          <UnorderedListOutlined className="account-action-item"></UnorderedListOutlined>
        </Tooltip>

        <Tooltip
          placement="bottom"
          title={'See account details'}
          key={'details'}
          color={'#000'}
        >
          <BankFilled
            onClick={openDetailsModal}
            className="account-action-item"
          ></BankFilled>
        </Tooltip>

        <Tooltip
          placement="bottom"
          title={'Generate account statement'}
          key={'statement'}
          color={'#000'}
        >
          <FileDoneOutlined
            onClick={openStatementModal}
            className="account-action-item"
          ></FileDoneOutlined>
        </Tooltip>

        {isDirectDebit && (
          <Tooltip
            placement="bottom"
            title={`Direct debit is ${isDirectDebitActive ? 'active' : 'not active'}`}
            key={'isDirectDebit'}
            color={'#000'}
          >
            <span className="ms-auto account-badge">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="8"
                viewBox="0 0 9 8"
                fill="none"
              >
                <circle
                  cx="4.33008"
                  cy="4"
                  r="3"
                  fill={isDirectDebitActive ? '#12B76A' : '#D94F00'}
                />
              </svg>
              Direct debit
            </span>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export const CurrencyBalance = ({ balance }) => {
  return (
    <>
      {Object.entries(balance)?.map(([key, value]) => (
        <div
          key={'account-summary' + key}
          className="d-flex gap-2 font-bold"
          style={{
            backgroundColor: '#F5F5F4',
            padding: 15,
            marginRight: 15,
            width: 300,
            borderRadius: 10,
          }}
        >
          {ICONS[key]}
          <div>
            <p style={{ marginBottom: 0, color: '#79716B', fontWeight: 500 }}>
              {CURRENCY_TITLE[key]}
            </p>

            <FormattedCurrency
              value={value}
              prefix={getCurrency(key)}
              style={{
                fontSize: 25,
                color: '#1C1917',
                fontWeight: 600,
                fontStyle: 'normal',
              }}
            />
          </div>
        </div>
      ))}
    </>
  );
};
