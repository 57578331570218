import { Checkbox } from 'antd';
import AccordionCard from 'components/UI/AccordionCard';
import CustomDatePicker from 'components/UI/CustomDatePicker';
import CustomInput from 'components/UI/CustomInput';
import CustomPhoneNumberInput from 'components/UI/CustomPhoneNumberInput';
import CustomSelect from 'components/UI/CustomSelect';
import FileUpload from 'components/UI/FileUpload';
import { addYears } from 'date-fns';
import NotificationBanner from 'pages/Onboarding/components/NotificationBanner';
import React, { useState } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { businessTypes } from 'utils/helper';

const BusinessDetails = () => {
  const [activeKey, setActiveKey] = useState('0');
  const [isChecked, setIsChecked] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);

  const [documents, setDocuments] = useState({
    incorporationCertificate: '',
    memorandum: '',
    utilityBill: '',
  });

  const history = useHistory();
  const handleSelect = (key) => {
    if (activeKey === key) return setActiveKey('999999999');
    setActiveKey(key);
  };

  const handleChangeCheckBox = (checked) => {
    setIsChecked(checked);
  };
  return (
    <Row className="w-100 p-0 m-0">
      <Col className="p-0 m-0">
        <Accordion defaultActiveKey="0" className="p-0 m-0" activeKey={activeKey}>
          <AccordionCard
            eventKey="0"
            activeKey={activeKey}
            title="Incorporation details"
            handleClick={handleSelect}
            wrapperClass="p-0 m-0 border-bottom pb-3 bg-transparent"
          >
            <Col className="border py-2 rounded-3 mt-3">
              <div className="px-3">
                <Row className="mb-2">
                  <CustomInput
                    type="text"
                    label="Legal business name"
                    placeholder="Enter legal business name"
                    name="businessName"
                    // value={personalInfo.firstName}
                    // onChange={handleChangePersonalInfo}
                  />
                </Row>
                <Row className="mb-2">
                  <CustomSelect
                    label="Registration type"
                    name="registrationType"
                    placeholder="Select registration type"
                    // onChange={(val) => handleChangeBusinessType({ val })}
                    // value={businessDoc.type}
                    options={businessTypes}
                  />
                </Row>

                <Row className="mb-3 align-items-center">
                  <CustomInput
                    type="text"
                    label="Registration number"
                    placeholder="Enter registration number"
                    name="rcNumber"
                    // onChange={handleChangeBusinessInfo}
                    // value={businessInfo.city}
                    md={6}
                  />
                  <CustomDatePicker
                    label="Registration date"
                    name="registrationDate"
                    // onChange={handleChangeDate}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Registration date"
                    // selected={kycInfo.dob && dayjs(kycInfo.dob).toDate()}
                    md={6}
                  />
                </Row>
              </div>
              <div className="border-top button-container w-100 px-3 pt-3 me-md-auto pb-2 d-md-flex align-items-center gap-3">
                <button className="btn border sm ms-md-auto mb-md-0 mb-2">Cancel</button>
                <button className="btn black sm">Save and submit</button>
              </div>
            </Col>
          </AccordionCard>

          {/******************** Contact information  ***************/}
          <AccordionCard
            eventKey="1"
            activeKey={activeKey}
            title="Contact information"
            handleClick={handleSelect}
            wrapperClass=" m-0 border-bottom bg-transparent"
          >
            <Col className="border py-2 rounded-3 mt-3">
              <div className="px-3">
                <Row className="mb-2 align-items-center">
                  <CustomInput
                    type="text"
                    label="Company email"
                    placeholder="Enter company email"
                    name="companyEmail"
                    // onChange={handleChangeBusinessInfo}
                    // value={businessInfo.city}
                    md={6}
                  />

                  <CustomPhoneNumberInput
                    label="Phone number"
                    placeholder="Enter your mobile number"
                    md={6}
                    // onChange={(localFormat, international, countryCode) =>
                    //   handlePhoneNumberChange(localFormat, international, countryCode)
                    // }
                    // value={personalInfo.internationalFormat}
                  />
                </Row>
                <Row className="mb-2">
                  <CustomInput
                    type="text"
                    label="Registred business address"
                    placeholder="Enter business address"
                    name="businessAddress"
                    // onChange={handleChangePassword}
                    // value={changePassword.new_password}
                  />
                </Row>
                <Row className="mb-2">
                  <CustomInput
                    type="text"
                    label="City"
                    placeholder="Enter city"
                    name="city"
                    // onChange={handleChangePassword}
                    // value={changePassword.new_password}
                  />
                </Row>
                <Row className="mb-2 align-items-center">
                  <CustomInput
                    type="text"
                    label="State / Province"
                    placeholder="Enter state"
                    name="state"
                    // onChange={handleChangePassword}
                    // value={changePassword.new_password}
                    md={6}
                  />

                  <CustomInput
                    label="Postcode"
                    placeholder="Enter your post code"
                    name="postcode"
                    // onChange={handleChangePassword}
                    // value={changePassword.new_password}
                    md={6}
                  />
                </Row>
                <Row className="mb-2">
                  <CustomSelect
                    label="Country"
                    name="country"
                    placeholder="Select country"
                    // onChange={(val) => handleChangeBusinessType({ val })}
                    // value={businessDoc.type}
                    options={businessTypes}
                  />
                </Row>
                <Row className="my-3">
                  <Checkbox
                    onChange={({ target: { checked } }) => handleChangeCheckBox(checked)}
                  >
                    Operating address differs from registered address
                  </Checkbox>
                </Row>
                {isChecked && (
                  <>
                    <div className="mb-2 fw-medium">Operating business address</div>

                    <Row className="mb-2">
                      <CustomInput
                        type="text"
                        label="Operating business address"
                        placeholder="Enter operating address"
                        name="operatingAddress"
                        // onChange={handleChangePassword}
                        // value={changePassword.new_password}
                      />
                    </Row>
                    <Row className="mb-2">
                      <CustomInput
                        type="text"
                        label="City"
                        placeholder="Enter city"
                        name="city"
                        // onChange={handleChangePassword}
                        // value={changePassword.new_password}
                      />
                    </Row>
                    <Row className="mb-2 align-items-center">
                      <CustomInput
                        type="text"
                        label="State / Province"
                        placeholder="Enter state"
                        name="state"
                        // onChange={handleChangePassword}
                        // value={changePassword.new_password}
                        md={6}
                      />

                      <CustomInput
                        label="Postcode"
                        placeholder="Enter your post code"
                        name="postcode"
                        // onChange={handleChangePassword}
                        // value={changePassword.new_password}
                        md={6}
                      />
                    </Row>
                    <Row className="mb-3">
                      <CustomSelect
                        label="Country"
                        name="country"
                        placeholder="Select country"
                        // onChange={(val) => handleChangeBusinessType({ val })}
                        // value={businessDoc.type}
                        options={businessTypes}
                      />
                    </Row>
                  </>
                )}
              </div>
              <div className="border-top button-container w-100 px-3 pt-3 me-md-auto pb-2 d-md-flex align-items-center gap-3">
                <button className="btn border sm ms-md-auto mb-md-0 mb-2">Cancel</button>
                <button className="btn black sm">Save and submit</button>
              </div>
            </Col>
          </AccordionCard>

          {/******************** Business documents  ***************/}
          <AccordionCard
            eventKey="2"
            activeKey={activeKey}
            title="Business documents"
            handleClick={handleSelect}
          >
            <div className="py-2 mt-3">
              <NotificationBanner />
            </div>
            <Col className="border py-2 rounded-3 mt-3">
              <div className="px-3">
                <Row className="mb-2">
                  <FileUpload
                    label="CAC Certificate of Incorporation *"
                    supportType="JPEG, PNG, and PDF formats, up to 5 MB."
                    uploadedFile={documents.incorporationCertificate}
                    onChange={({ assetCode }) =>
                      setDocuments({ ...documents, incorporationCertificate: assetCode })
                    }
                    setUploadingFile={setUploadingFile}
                    uploadingFile={uploadingFile}
                  />
                </Row>
                <Row className="mb-2">
                  <FileUpload
                    label="Memorandum and Articles of Association"
                    supportType="JPEG, PNG, and PDF formats, up to 5 MB."
                    uploadedFile={documents.memorandum}
                    onChange={({ assetCode }) =>
                      setDocuments({ ...documents, memorandum: assetCode })
                    }
                    setUploadingFile={setUploadingFile}
                    uploadingFile={uploadingFile}
                  />
                </Row>
                <Row className="mb-3">
                  <FileUpload
                    label="Proof of Address / Utility Bill"
                    supportType="JPEG, PNG, and PDF formats, up to 5 MB."
                    uploadedFile={documents.utilityBill}
                    onChange={({ assetCode }) =>
                      setDocuments({ ...documents, utilityBill: assetCode })
                    }
                    setUploadingFile={setUploadingFile}
                    uploadingFile={uploadingFile}
                  />
                </Row>
              </div>
              <div className="border-top button-container w-100 px-3 pt-3 me-md-auto pb-2 d-md-flex align-items-center gap-3">
                <button className="btn border sm ms-md-auto mb-md-0 mb-2">Cancel</button>
                <button className="btn black sm">Save and submit</button>
              </div>
            </Col>
          </AccordionCard>
        </Accordion>
      </Col>
    </Row>
  );
};

export default BusinessDetails;
