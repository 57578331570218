import { SELECT_DATE_RANGE, SELECT_DATE_RANGE_TOPBAR } from '../reducers/OverviewReducer';

export const selectDateRnage = (payload) => ({
  type: SELECT_DATE_RANGE,
  payload,
});

export const selectDateRnageTopbar = (payload) => ({
  type: SELECT_DATE_RANGE_TOPBAR,
  payload,
});
