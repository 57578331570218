import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomPhoneNumberInput from 'components/UI/CustomPhoneNumberInput';
import CustomSelect from 'components/UI/CustomSelect';
import FileUpload from 'components/UI/FileUpload';
import Loading from 'components/UI/Loading';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  getSingleInvitedDirector,
  uploadInvitedDirectorInfo,
} from 'redux/actions/CompaniesAction';
import { idType, validateNigeriaNumber } from 'utils/helper';
import BusinessOwnerNavBar from './BusinessOwnerNavBar';
import { ReactComponent as OnboardingCompleted } from '../../assets/icons/onboarding-completed.svg';
import cs from 'classnames';
import CustomDatePicker from 'components/UI/CustomDatePicker';
import { addYears } from 'date-fns';
import { toastError } from 'components/UI/toast';
import { encryptAndSaveToLocalStorage } from 'utils/utility';
import dayjs from 'dayjs';
import { format } from 'date-fns';
import { isValidPhoneNumber } from 'react-phone-number-input';

const BusinessOwner = () => {
  const { code } = useParams();
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const history = useHistory();
  const {
    uploadDirectorInfo: { success, loading },
    getSingleInvitedDirector: { data, loading: loadsingleInvite },
  } = useSelector(({ companies }) => companies);

  const [businessDoc, setBusinessDoc] = useState({
    type: '',
    bvn: '',
    idType: '',
    idNumber: '',
    rcNumber: '',
    bnNumber: '',
    directorPercentage: '',
    firstName: '',
    dob: '',
    lastName: '',
    directorsEmail: '',
    countryCode: '',
    localFormat: '',
    internationalFormat: '',
    checked: '',
  });

  const [idName, setIdName] = useState('ID Number');
  const [disableIdNumber, setDisableIdNumber] = useState(true);
  const [idCopy, setIdCopy] = useState('');
  const [uploadingFile, setUploadingFile] = useState(false);
  const [marginTop, setMarginTop] = useState('0px');

  const showIdNumber = (idType) => {
    if (idType.value === 'ip') {
      setMarginTop('20px');
    } else {
      setMarginTop('0px');
    }
    if (idType.value === 'nin') {
      setIdName(`NIN`);
    } else {
      setIdName(`${idType.label} Number`);
    }
    setDisableIdNumber(false);
  };

  const handleSubmit = () => {
    if (!businessDoc.firstName) return toastError('Please enter your first name');
    if (!businessDoc.lastName) return toastError('Please enter your last name');
    if (!businessDoc.directorsEmail) return toastError('Please enter your email');
    if (
      !isValidPhoneNumber(businessDoc.internationalFormat) ||
      !validateNigeriaNumber(businessDoc.internationalFormat)
    )
      return toastError('Please enter a valid phone number');
    if (!businessDoc.dob) return toastError('Please enter your  date of birth');
    if (!businessDoc.bvn) return toastError('Please enter your  BVN');
    if (!businessDoc.directorPercentage)
      return toastError('Please enter percentage owned');
    if (+businessDoc.directorPercentage < 5)
      return toastError('Percentage owned must be at least 5%');
    if (!businessDoc.idType) return toastError('Please select your ID type');
    if (!businessDoc.idNumber) return toastError('Please enter your ID number');

    if (!idCopy) return toastError('Please upload a copy of your ID');

    const payload = {
      invite: data.code,
      firstName: businessDoc.firstName,
      lastName: businessDoc.lastName,
      directorPercentage: businessDoc.directorPercentage,
      email: businessDoc.directorsEmail,
      phoneNumber: {
        countryCode: businessDoc.countryCode,
        localFormat: businessDoc.localFormat,
      },
      documents: {
        dateOfBirth: businessDoc.dob,
        bvn: businessDoc.bvn,
        idType: businessDoc.idType.value,
        idNumber: businessDoc.idNumber,
        idCopy: idCopy,
        directorPercentage: businessDoc.directorPercentage,
      },
    };
    dispatch(uploadInvitedDirectorInfo(payload));
  };

  const handleChangeBusinessDoc = ({ target: { name, value } }) => {
    setBusinessDoc({ ...businessDoc, [name]: value });
  };

  const handlePhoneNumberChange = (localFormat, internationalFormat, countryCode) => {
    setBusinessDoc({ ...businessDoc, internationalFormat, localFormat, countryCode });
  };

  const closePage = () => {
    history.push('/');
  };

  useEffect(() => {
    if (code) {
      const options = { path: '/' };
      encryptAndSaveToLocalStorage('app-session', code);
      localStorage.removeItem('exp-app-session');
      // localStorage.clear();
      if (!submitted) dispatch(getSingleInvitedDirector(code));
    }
  }, [code]);

  useEffect(() => {
    if (success) {
      setSubmitted(true);
      window.setTimeout(function () {
        window.location.replace('https://bujeti.com/');
      }, 5000);
    }
  }, [success]);

  const showOwner = !data || loadsingleInvite;

  const ownerInfo = () => (
    <div className={cs({ 'disabled-owners-input': showOwner })}>
      <h2 className="card-title w-100">Business owner Information</h2>

      <Row className="mt-4 mb-3 align-items-center">
        <CustomInput
          label="First Name *"
          name="firstName"
          placeholder="Enter first name"
          type="text"
          onChange={handleChangeBusinessDoc}
          value={businessDoc.firstName}
          md={6}
        />
        <CustomInput
          label="Last Name *"
          placeholder="Enter last name"
          type="text"
          name="lastName"
          onChange={handleChangeBusinessDoc}
          value={businessDoc.lastName}
          md={6}
        />
      </Row>

      <Row className="mb-3">
        <CustomInput
          label="Email address *"
          placeholder="Enter email"
          type="text"
          name="directorsEmail"
          onChange={handleChangeBusinessDoc}
          value={businessDoc.directorsEmail}
        />
      </Row>
      <Row className="mb-3">
        <CustomPhoneNumberInput
          label="Business phone number"
          placeholder="Enter phone number"
          onChange={(localFormat, international, countryCode) =>
            handlePhoneNumberChange(localFormat, international, countryCode)
          }
          value={businessDoc.internationalFormat}
        />
      </Row>
      <Row className="mb-3">
        <CustomDatePicker
          label="Date of birth *"
          onChange={(value) =>
            setBusinessDoc({
              ...businessDoc,
              dob: format(value, 'yyyy-MM-dd'),
            })
          }
          peekNextMonth
          maxDate={addYears(new Date(), 0)}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          placeholderText="Date of birth"
          name="dob"
          selected={businessDoc.dob && dayjs(businessDoc?.dob).toDate()}
        />
      </Row>
      <Row className="mb-3">
        <CustomInput
          label="BVN *"
          placeholder="Enter BVN"
          type="text"
          name="bvn"
          onChange={handleChangeBusinessDoc}
          value={businessDoc.bvn}
        />
      </Row>

      <Row className="mb-3">
        <CustomInput
          label="Percentage Owned *"
          placeholder="Enter percentage owned"
          type="text"
          name="directorPercentage"
          onChange={handleChangeBusinessDoc}
          value={businessDoc?.directorPercentage}
          isNumber={false}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
      </Row>

      <Row className="mb-3 align-items-center">
        <CustomSelect
          label="ID Type *"
          name="state"
          placeholder="Select ID Type"
          onChange={(val) => {
            setBusinessDoc({ ...businessDoc, idType: val });
            showIdNumber(val);
          }}
          value={businessDoc?.idType}
          options={idType}
          marginTop={marginTop}
          md={6}
        />
        <CustomInput
          label={`${idName} *`}
          placeholder={'Enter ' + idName}
          type="text"
          name="idNumber"
          onChange={handleChangeBusinessDoc}
          value={businessDoc?.idNumber}
          maxLength="11"
          disabled={disableIdNumber}
          md={6}
        />
      </Row>

      <Row className="mb-3">
        <FileUpload
          label="Copy of ID *"
          placeholder="Upload a Copy of ID (PDF, JPEG, PNG)"
          name="file"
          onChange={({ assetCode }) => setIdCopy(assetCode)}
          setUploadingFile={setUploadingFile}
          uploadingFile={uploadingFile}
        />
      </Row>
      <Row className="my-4 px-3">
        <CustomButton
          onClick={handleSubmit}
          fullWidth={true}
          disabled={loading}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Submit
          {loading ? <Loading color="#D28B28" size={30} /> : ''}
        </CustomButton>
        <CustomButton onClick={closePage} fullWidth={true} withoutBg className="mt-3">
          Cancel
        </CustomButton>
      </Row>
    </div>
  );

  const successPage = () => (
    <div className="d-flex justify-content-center pt-5">
      <div className="text-center" style={{ marginTop: '50%', marginBottom: '40px' }}>
        <OnboardingCompleted />
        <div className="onboardingSavedHeader">Thank you!</div>
        <div className="savingText">
          Your information has been submitted successfully and a mail has been sent to the
          admin.
        </div>
        <div className="savingText mt-4">
          <span className="opacity-50">Returning back to </span>bujeti.com in 5 sec.
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <BusinessOwnerNavBar />
      <div className="business-owner">{submitted ? successPage() : ownerInfo()}</div>
    </div>
  );
};

export default BusinessOwner;
