import EmptyState from 'components/UI/EmptyState';
import PeopleImage from '../../assets/images/emptystate/people-image.png';

import CustomButton from 'components/UI/CustomButton';

import { AddTeamMember, CreateTeams, ManageRoles } from 'assets/icons/empty-state-icons';
import { useLocation } from 'react-router-dom';

const PeopleEmptyStateData = ({
  setIsBeneficiaryOpen,
  setIsTeamOpen,
  setIsRolesOpen,
  showAction,
}) => {
  const location = useLocation();
  const locationArray = location.pathname.split('/');

  const key = locationArray[locationArray.length - 1];

  const isTeams = key === 'teams';
  const isBeneficiary = key === 'people';
  const isRole = key === 'roles';

  const handleOpenModal = () => {
    if (isBeneficiary) setIsBeneficiaryOpen(true);
    if (isTeams) setIsTeamOpen();
    if (isRole) setIsRolesOpen();
  };

  const handleSupport = () => {
    window.open('https://www.bujeti.com/employees', '_blank');
  };

  const buttonName = isTeams
    ? 'Create team'
    : isBeneficiary
    ? 'Add member'
    : isRole
    ? 'Create role'
    : null;

  const childrenData = [
    {
      title: 'Add your team members',
      body: 'Add and manage your team members for expense and payment purposes.',
      actionTitle: 'View more',
      action: handleSupport,
      icon: <AddTeamMember />,
    },

    {
      title: 'Create teams',
      body: 'Form teams within your organisation to streamline budget management and collaboration.',
      actionTitle: 'View more',
      action: handleSupport,
      icon: <CreateTeams />,
    },
    {
      title: 'Manage roles',
      body: 'Fine-tune team member access and permissions to ensure seamless cooperation.',
      actionTitle: 'View more',
      action: handleSupport,
      icon: <ManageRoles />,
    },
  ];

  const mainData = {
    title: 'Collaborate with team members or employees on budget management.',
    body: 'Add members, manage teams, manage team member roles and access.. See how it works here.',
    image: PeopleImage,
    action: () =>
      showAction && (
        <CustomButton className="add-button w-auto" onClick={handleOpenModal}>
          {buttonName}
        </CustomButton>
      ),
  };

  return <EmptyState main={mainData} childrenData={childrenData} />;
};

export default PeopleEmptyStateData;
