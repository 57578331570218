import CustomButton from 'components/UI/CustomButton';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SELECTED_TABLE_ROWS } from 'redux/reducers/TableReducer';
import { formatNumThousandSeparator } from 'utils/utility';

const FloatingCta = ({
  selectedRowInformation,
  handleAction,
  hasDelete = false,
  hasApproval = true,
  hasPayment = true,
}) => {
  const dispatch = useDispatch();

  const {
    selectedTableRows: { shouldClearRows },
  } = useSelector(({ table }) => table);

  useEffect(() => {
    return () => {
      if (!!shouldClearRows) {
        dispatch({ type: SELECTED_TABLE_ROWS, payload: { shouldClearRows: false } });
      }
    };
  }, []);

  return (
    <>
      <div className="floating-cta-container mx-auto d-flex border rounded">
        <div className="selected-information">
          <p className="mb-0">{selectedRowInformation?.['all']?.count} selected</p>
          <p className="mb-0">
            ₦{formatNumThousandSeparator(selectedRowInformation?.['all']?.sum)} total
          </p>
        </div>
        <div className="cta-divider"></div>
        {hasApproval && (
          <div className="custom-btn-container">
            <button
              className="btn border me-2"
              disabled={selectedRowInformation?.['pending']?.count === 0}
              onClick={() => {
                handleAction('decline');
              }}
            >
              Decline ({selectedRowInformation?.['pending']?.count ?? 0})
            </button>

            <button
              className="btn black me-2"
              disabled={selectedRowInformation?.['pending']?.count === 0}
              onClick={() => {
                handleAction('approve');
              }}
            >
              Approve ({selectedRowInformation?.['pending']?.count ?? 0})
            </button>
          </div>
        )}
        {hasApproval && hasPayment && <div className="cta-divider"></div>}
        {hasPayment && (
          <div className="custom-btn-container">
            <button
              className="btn border me-2"
              disabled={selectedRowInformation?.['approved']?.count === 0}
              onClick={() => {
                handleAction('pay');
              }}
            >
              Make payment ({selectedRowInformation?.['approved']?.count ?? 0})
            </button>
          </div>
        )}
        {hasPayment && hasDelete && <div className="cta-divider"></div>}
        {hasDelete && (
          <div className="custom-btn-container">
            <CustomButton
              className="add-button add-action delete-cta floating-cta"
              customClass={true}
              disabled={
                selectedRowInformation?.['pending']?.count === 0 &&
                selectedRowInformation?.['draft']?.count === 0
              }
              onClick={() => {
                handleAction('delete');
              }}
            >
              Delete (
              {(selectedRowInformation?.['pending']?.count ?? 0) +
                (selectedRowInformation?.['draft']?.count ?? 0)}
              )
            </CustomButton>
          </div>
        )}
      </div>
    </>
  );
};

export default FloatingCta;
