import {
  ADD_FUNDS_REQUEST,
  CREATE_BANK_ACCOUNT_REQUEST,
  SYNC_BANK_ACCOUNT_REQUEST,
  CREATE_MANDATE_REQUEST,
  DELETE_INVITED_DIRECTOR_REQUEST,
  FUND_DOLLAR_BALANCE_REQUEST,
  FUND_WIDTH_CARDS_REQUEST,
  GET_BANK_ACCOUNT_REQUEST,
  GET_COMPANY_ASSET_REQUEST,
  GET_COMPANY_REQUEST,
  GET_COMPANIES_REQUEST,
  GET_COMPANY_STATS_REQUEST,
  GET_COUNTRY_REQUEST,
  GET_DOLLAR_ACCOUNT_REQUEST,
  GET_INVITED_DIRECTORS_REQUEST,
  GET_MONO_PAYMENT_LINK_REQUEST,
  GET_REJECTED_DOCUMENTS_REQUEST,
  GET_SINGLE_INVITED_DIRECTOR_REQUEST,
  GET_STATE_REQUEST,
  INVITE_DIRECTOR_REQUEST,
  ONBOARDING_BUSINESS_ADDRESS_REQUEST,
  ONBOARDING_BUSINESS_DOC_REQUEST,
  ONBOARDING_BUSINESS_INFORMATION_REQUEST,
  RESEND_ONBOARDING_INVITE_REQUEST,
  REVIEW_COMPANY_REQUEST,
  RE_SUBMIT_REJECTED_DOCUMENTS_IS_COMPLETE_REQUEST,
  RE_SUBMIT_REJECTED_DOCUMENTS_REQUEST,
  SEND_MONO_AUTH_CODE_REQUEST,
  UPDATE_COMPANY_REQUEST,
  UPDATE_INVITED_DIRECTOR_REQUEST,
  UPLOAD_DIRECTOR_INFO_REQUEST,
  UPLOAD_INVITED_DIRECTOR_INFO_REQUEST,
  VERIFY_MONO_PAYMENT_REFERENCE_REQUEST,
  ADD_COMPANY_REQUEST,
  SWITCH_COMPANY_REQUEST,
  GET_MANDATE_STATUS_REQUEST,
  CANCEL_MANDATE_REQUEST,
} from '../reducers/CompaniesReducer';

export const getCompany = (payload) => ({ type: GET_COMPANY_REQUEST, payload });

export const getCountries = (payload) => ({ type: GET_COUNTRY_REQUEST, payload });

export const getState = (payload) => ({ type: GET_STATE_REQUEST, payload });

export const getCompanyStats = (payload) => ({
  type: GET_COMPANY_STATS_REQUEST,
  payload,
});
export const getRejectedDocumentDetails = (payload) => ({
  type: GET_REJECTED_DOCUMENTS_REQUEST,
  payload,
});
export const reSubmitRejectedDocumentDetails = (payload) => ({
  type: RE_SUBMIT_REJECTED_DOCUMENTS_REQUEST,
  payload,
});
export const reSubmitRejectedIsCompleted = () => ({
  type: RE_SUBMIT_REJECTED_DOCUMENTS_IS_COMPLETE_REQUEST,
});
export const getBankAccount = (payload) => ({ type: GET_BANK_ACCOUNT_REQUEST, payload });
export const createBankAccount = (payload) => ({
  type: CREATE_BANK_ACCOUNT_REQUEST,
  payload,
});
export const syncBankAccountData = (payload) => ({
  type: SYNC_BANK_ACCOUNT_REQUEST,
  payload,
});
export const onboardingBusinessInformation = (payload) => ({
  type: ONBOARDING_BUSINESS_INFORMATION_REQUEST,
  payload,
});
export const onboardingBusinessAddress = (payload) => ({
  type: ONBOARDING_BUSINESS_ADDRESS_REQUEST,
  payload,
});
export const onboardingBusinessDoc = (payload) => ({
  type: ONBOARDING_BUSINESS_DOC_REQUEST,
  payload,
});
export const updateCompany = (payload) => ({ type: UPDATE_COMPANY_REQUEST, payload });
export const reviewCompany = (payload) => ({ type: REVIEW_COMPANY_REQUEST, payload });
export const fundCreateAPI = (payload) => ({ type: FUND_WIDTH_CARDS_REQUEST, payload });
export const fundDollarBalance = (payload) => ({
  type: FUND_DOLLAR_BALANCE_REQUEST,
  payload,
});
export const getDollarAccount = (payload) => ({
  type: GET_DOLLAR_ACCOUNT_REQUEST,
  payload,
});
export const inviteDirector = (payload) => ({ type: INVITE_DIRECTOR_REQUEST, payload });
export const getInvitedDirectors = () => ({ type: GET_INVITED_DIRECTORS_REQUEST });
export const uploadDirectorInfo = (payload) => ({
  type: UPLOAD_DIRECTOR_INFO_REQUEST,
  payload,
});
export const uploadInvitedDirectorInfo = (payload) => ({
  type: UPLOAD_INVITED_DIRECTOR_INFO_REQUEST,
  payload,
});
export const getSingleInvitedDirector = (payload) => ({
  type: GET_SINGLE_INVITED_DIRECTOR_REQUEST,
  payload,
});
export const resendOnboardingInvite = (payload) => ({
  type: RESEND_ONBOARDING_INVITE_REQUEST,
  payload,
});
export const deleteInvitedDirector = (payload) => ({
  type: DELETE_INVITED_DIRECTOR_REQUEST,
  payload,
});
export const updateInvitedDirector = (payload) => ({
  type: UPDATE_INVITED_DIRECTOR_REQUEST,
  payload,
});
export const sendMonoAuthCode = (payload) => ({
  type: SEND_MONO_AUTH_CODE_REQUEST,
  payload,
});
export const getPaymentLink = (payload) => ({
  type: GET_MONO_PAYMENT_LINK_REQUEST,
  payload,
});
export const verifyPaymentReference = (payload) => ({
  type: VERIFY_MONO_PAYMENT_REFERENCE_REQUEST,
  payload,
});
export const getAccountReauthorizationCode = (payload) => ({
  type: MONO_ACCOUNT_REAUTHORIZATION_CODE_REQUEST,
  payload,
});

export const getCompanyLogoAsset = (payload) => ({
  type: GET_COMPANY_ASSET_REQUEST,
  payload,
});

export const addFundsToAccount = (payload) => ({
  type: ADD_FUNDS_REQUEST,
  payload,
});

export const createMandate = (payload) => ({
  type: CREATE_MANDATE_REQUEST,
  payload,
});

export const cancelMandate = (payload) => ({
  type: CANCEL_MANDATE_REQUEST,
  payload,
});

export const getMandateStatus = (payload) => ({
  type: GET_MANDATE_STATUS_REQUEST,
  payload,
});

export const getCompanies = (payload) => ({
  type: GET_COMPANIES_REQUEST,
  payload,
});

export const addEntity = (payload) => ({
  type: ADD_COMPANY_REQUEST,
  payload,
});

export const switchCompany = (payload) => ({
  type: SWITCH_COMPANY_REQUEST,
  payload,
});

export const authenticate = (payload) => ({
  type: SWITCH_COMPANY_REQUEST,
  payload,
});
