import CardView from 'components/CardView';
import NoData from 'components/NoData';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import Loading from 'components/UI/Loading';
import PendingOnboardingNotice from 'pages/Budgets/PendingOnboardingNotice';
import { useEffect, useRef, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { buildCardsTableData, capitalizeFirstLetter } from 'utils/helper';
import { columnsCards } from 'utils/mockData';
import {
  CardStatusType,
  CardType,
  CurrencyType,
} from '../../components/FilterModal/FilterHelper';
import { useDebounce } from '../../hooks/useDebounce';
import { getBudgets } from '../../redux/actions/BudgetsAction';
import {
  deleteCard,
  freezeUnfreezeCard,
  getMyCardsAction,
  reassignCard,
} from '../../redux/actions/CardAction';
import CardsEmptyState from './CardsEmptyState';
import CardsTableModal from './CardsTableModal';
import { toastError } from 'components/UI/toast';
import {
  CreditCardLock,
  DeleteIcon,
  DeleteUserIcon,
  EyeIcon,
  TrashIcon,
  UserIcon02,
} from 'assets/icons';
import ConfirmDialog from 'components/ConfirmDialog';
import { useHistory } from 'react-router-dom';
import { getBeneficiaries } from 'redux/actions/BeneficiariesAction';
import CustomSelect from 'components/UI/CustomSelect';
import { allPermissions, hasPermission } from 'utils/AllowedTo';

const MyCardsTable = ({ toggleHandler }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = new URLSearchParams(location.search);

  const { permissions, isAdmin } = allPermissions();

  const canViewBudget = hasPermission({
    permissions,
    scopes: ['budget-view'],
  });

  const {
    freezeUnfreezeCard: { loading: isTogglingBlockState, success: blockStateStatus },
    deleteCard: { loading: isDeleteLoading, success: isDeleteSuccess },
    reassignCard: { loading: reassigning, success: reassigned },
  } = useSelector(({ card }) => card);

  const [selectCards, setSelectCards] = useState(null);
  const payerCode = query.get('payer');
  const [activeView, setActiveView] = useState('list');

  const [filterData, setFilterData] = useState([
    ...CardType,
    ...CardStatusType,
    ...CurrencyType,
  ]);

  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;
  const [filteredQuery, setFilteredQuery] = useState({});
  const [value, setValue] = useState(null);
  const [search, setSearch] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [isFillData, setIsFillData] = useState(false);
  const [holder, setHolder] = useState({});

  const debouncedValue = useDebounce(search, 300);
  const debouncedBeneficiary = useDebounce(value, 300);

  const {
    getBudget: { data: budgetData = {} },
  } = useSelector(({ budgets }) => budgets);

  const {
    getMyCards: {
      data: { meta = {} },
      data = {},
      loading,
    },
  } = useSelector(({ card }) => card);

  const {
    user: { data: userData },
  } = useSelector(({ auth }) => auth);

  const { cards = [] } = data;
  const { page, total, hasMore, perPage, nextPage } = meta;

  const {
    getBeneficiaries: { loading: loadingBeneficiaries, data: beneficiariesData = {} },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const { beneficiaries = [] } = beneficiariesData;

  const beneficiaryList = beneficiaries.map(({ user, code }) => ({
    label: `${user.firstName} ${user.lastName}`,
    value: code,
  }));
  useEffect(() => {
    const foundBeneficiaries = beneficiaryList?.find((option) =>
      option?.label?.toLowerCase().includes(debouncedBeneficiary?.toLowerCase()),
    );
    if (!foundBeneficiaries && debouncedBeneficiary) {
      dispatch(getBeneficiaries({ search: debouncedBeneficiary?.toLowerCase() }));
    }
  }, [debouncedBeneficiary]);

  const onMenuCloseBeneficiary = () => {
    if (debouncedBeneficiary) dispatch(getBeneficiaries());
  };

  const handleSelectChange = (val) => setValue(val);

  const handleChange = ({ name, value }) => {
    setHolder({
      ...holder,
      [name]: value,
    });
  };

  useEffect(() => {
    if (userData?.user.code && !cards?.length)
      dispatch(getMyCardsAction({ owner: userData?.user?.code, payer: payerCode }));
    if (!budgetData?.budgets?.length && canViewBudget) dispatch(getBudgets());
    return () => {
      if (filtered)
        dispatch(getMyCardsAction({ owner: userData?.user?.code, payer: payerCode }));
    };
  }, [userData?.user.code, filtered]);

  useEffect(() => {
    if (debouncedValue) {
      filteredQuery.search = debouncedValue;
      dispatch(
        getMyCardsAction({
          ...filteredQuery,
          owner: userData?.user?.code,
          search: debouncedValue,
        }),
      );
      isFiltered.current = true;
    }
    if (!debouncedValue && filtered) {
      delete filteredQuery.search;
      dispatch(getMyCardsAction({ ...filteredQuery, owner: userData?.user?.code }));
    }
  }, [debouncedValue]);

  const handleFilter = (query) => {
    query['owner'] = userData?.user?.code;
    setFilteredQuery(query);
    dispatch(getMyCardsAction(query));
    isFiltered.current = !!Object.keys(query).length;
  };

  const handleRowClick = (row) => {
    if (
      ['processing'].includes(
        row?.status?.value ? row?.status?.value.toLowerCase() : row?.status,
      )
    )
      return toastError(`Your card is still processing please refresh and try again.`);
    setSelectCards(row);
  };

  const handlePreviousPage = (page) => {
    dispatch(
      getMyCardsAction({
        owner: userData?.user?.code,
        perPage,
        page,
        payer: payerCode,
        ...filteredQuery,
      }),
    );
  };

  const handleNextPage = (page) => {
    dispatch(
      getMyCardsAction({
        owner: userData?.user?.code,
        perPage,
        page,
        payer: payerCode,
        ...filteredQuery,
      }),
    );
  };

  useEffect(() => {
    if (blockStateStatus || isDeleteSuccess || reassigned) {
      dispatch(getMyCardsAction({ owner: userData?.user?.code, payer: payerCode }));
      closeModal();
    }
  }, [blockStateStatus, isDeleteSuccess, reassigned]);

  useEffect(() => {
    if (cards.length > 0 && budgetData?.budgets?.length > 0) {
      if (!isFillData) {
        const data = [];
        budgetData?.budgets.forEach((budget) => {
          const { code, name } = budget;
          data.push({
            value: code,
            label: name,
            isSelected: false,
          });
        });
        setFilterData([{ title: 'Budget', list: data }, ...filterData]);
        setIsFillData(true);
      }
    }
  }, [cards, budgetData?.budgets]);

  const closeModal = () => {
    setIsPopoverOpen(false);
    setShowModal(false);
    setSelectedData(null);
    setActionType(null);
  };

  const onHandleToggleView = (option) => {
    setActiveView(option);
  };

  const actionHandler = (event, action, data) => {
    event?.stopPropagation();
    event?.preventDefault();
    setIsPopoverOpen(true);
    if (action === 'activate') return handleRowClick(data);
    setActionType(action);
    setSelectedData(data);
    setShowModal(true);
  };

  const Actions = ({ list }) => {
    const isCardBlocked = list?.status?.value.toLowerCase() === 'blocked';
    return (
      <div className="actions-dialog">
        {['active', 'blocked'].includes(list?.status?.value.toLowerCase()) && (
          <>
            <div
              className="actionLink"
              onClick={() => history.push(`/transactions/payments?card=${list?.code}`)}
            >
              <EyeIcon width="16" height="16" stroke="#57534E" className="mr-3" /> View
              transactions
            </div>

            <div
              className="actionLink"
              onClick={(event) =>
                actionHandler(event, isCardBlocked ? 'unblock' : 'block', list)
              }
            >
              <CreditCardLock width="18" height="18" stroke="#57534E" className="mr-3" />{' '}
              {isCardBlocked ? 'Unblock card' : 'Block card'}
            </div>
          </>
        )}
        {['active'].includes(list?.status?.value.toLowerCase()) && isAdmin && (
          <>
            <div
              className="actionLink"
              onClick={(event) => actionHandler(event, 'reassign', list)}
            >
              <UserIcon02 width="18" height="18" stroke="#57534E" className="me-1" />{' '}
              Reassign card
            </div>
          </>
        )}

        {list?.status?.value.toLowerCase() === 'inactive' && (
          <div
            className="actionLink"
            onClick={(event) => actionHandler(event, 'activate', list)}
          >
            <CreditCardLock width="18" height="18" stroke="#57534E" className="mr-3" />{' '}
            Activate card
          </div>
        )}

        <div
          className="actionLink svg-danger text-danger"
          onClick={(event) => actionHandler(event, 'delete', list)}
        >
          <TrashIcon width="16" height="16" className="mr-4" /> Delete card
        </div>
      </div>
    );
  };

  const handleAction = () => {
    if (actionType === 'block' || actionType === 'unblock') {
      const payload = {
        code: selectedData?.code,
        type: actionType,
      };
      dispatch(freezeUnfreezeCard(payload));
    }
    if (actionType === 'delete') {
      dispatch(deleteCard(selectedData?.code));
    }
    if (actionType === 'reassign') {
      const payload = {
        beneficiary: holder?.beneficiary?.value,
        code: selectedData?.code,
      };
      return dispatch(reassignCard(payload));
    }
  };

  const handleMore = (perPage) => {
    setTimeout(() => {
      if (!loading) {
        dispatch(
          getMyCardsAction({
            owner: userData?.user?.code,
            payer: payerCode,
            perPage,
            ...filteredQuery,
          }),
        );
        isFiltered.current = true;
      }
    }, 1500);
  };

  const show = !!cards?.length || (filtered && !cards?.length);
  if (!userData?.user?.code || (loading && !filtered))
    return <Loading isPage color="#D28B28" />;

  return (
    <div>
      <PendingOnboardingNotice />

      <TopBar
        showBarSearch={show}
        searchVal={search}
        inputPlaceholder="Search by name "
        showFilter={show}
        filterData={filterData}
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        setSearchVal={setSearch}
        handleFilterApply={handleFilter}
        toggleView={show}
        handleToggleView={onHandleToggleView}
        activeView={activeView}
      />
      {!loading && userData?.user?.code && cards.length > 0 ? (
        <>
          <Container className="px-0 my-4">
            {activeView === 'grid' && (
              <InfiniteScroll
                pageStart={0}
                loadMore={() => handleMore(perPage + 50)}
                hasMore={hasMore}
                className="w-100"
              >
                <Row className="budgets-card-wrapper w-100">
                  {cards?.map((card) => {
                    return (
                      <Col md={6} lg={4} className="mb-3" key={`card-${card.code}`}>
                        <CardView
                          card={card}
                          isActive={card?.type !== 'physical'}
                          handleSelect={() => handleRowClick(card)}
                          key={card?.code}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </InfiniteScroll>
            )}
            {activeView === 'list' && (
              <Row>
                <Col xs={12}>
                  <Table
                    hasCheckBox={false}
                    isCustomWidth={true}
                    stringType={false}
                    columns={columnsCards}
                    data={buildCardsTableData(cards)}
                    onRowClick={handleRowClick}
                    pagination
                    hasMore={hasMore}
                    currentPage={page}
                    nextPage={() => handleNextPage(nextPage)}
                    previousPage={() => handlePreviousPage(page - 1)}
                    totalPage={Math.ceil(total / perPage)}
                    popoverAction={Actions}
                    popoverState={isPopoverOpen}
                    setPopoverState={setIsPopoverOpen}
                  />
                </Col>
              </Row>
            )}
          </Container>
        </>
      ) : (
        <div>
          {filtered ? (
            <div className="tabinnerWrapper">
              <NoData
                headerText="You have no cards for this filter yet"
                buttonLabel="Go back to my cards"
                bodyText="You currently don't have any cards created for this particular filter yet, use the button to go back your card listing"
                onClickHandler={() => dispatch(getMyCardsAction(query))}
                withButton={true}
              />
            </div>
          ) : (
            <CardsEmptyState toggleHandler={toggleHandler} />
          )}
        </div>
      )}

      <CardsTableModal selectCards={selectCards} setSelectCards={setSelectCards} />

      <Modal show={showModal} centered dialogClassName="custom-dialog">
        <ConfirmDialog
          title={`${capitalizeFirstLetter(actionType)} ${selectedData?.name?.value}`}
          subTitle={
            <>
              {actionType !== 'reassign' && (
                <>
                  Are you sure you want to {actionType} this card?
                  {actionType === 'delete' && (
                    <>
                      <br />
                      This action cannot be undone.
                    </>
                  )}
                </>
              )}
              {actionType === 'reassign' && (
                <>
                  <CustomSelect
                    label="Who are you reassigning this card to?"
                    placeholder="New card holder"
                    name="holder"
                    options={beneficiaryList}
                    onChange={(value) => handleChange({ name: 'beneficiary', value })}
                    value={holder.value}
                    onInputChange={(value) => handleSelectChange(value, 'holder')}
                    isDisabled={false}
                    isLoading={loadingBeneficiaries}
                    onMenuClose={onMenuCloseBeneficiary}
                  />
                </>
              )}
            </>
          }
          onConfirm={handleAction}
          loading={isTogglingBlockState || isDeleteLoading || reassigning}
          onCancel={closeModal}
          isDeleteDialog={actionType === 'delete'}
        />
      </Modal>
    </div>
  );
};

export default MyCardsTable;
