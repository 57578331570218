import { Axios } from 'api/axios';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { getSimplifiedError } from 'utils/error';

import { toastError, toastSuccess } from 'components/UI/toast';
import {
  CREATE_BULK_CATEGORIES_ERROR,
  CREATE_BULK_CATEGORIES_REQUEST,
  CREATE_BULK_CATEGORIES_SUCCESS,
  CREATE_CATEGORIZATION_RULES_ERROR,
  CREATE_CATEGORIZATION_RULES_REQUEST,
  CREATE_CATEGORIZATION_RULES_SUCCESS,
  CREATE_CATEGORY_ERROR,
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  DELETE_CATEGORIZATION_RULES_ERROR,
  DELETE_CATEGORIZATION_RULES_REQUEST,
  DELETE_CATEGORIZATION_RULES_SUCCESS,
  DELETE_CATEGORY_ERROR,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  EXPORT_CATEGORY_ERROR,
  EXPORT_CATEGORY_REQUEST,
  EXPORT_CATEGORY_SUCCESS,
  FETCH_CATEGORIZATION_RULES_ERROR,
  FETCH_CATEGORIZATION_RULES_REQUEST,
  FETCH_CATEGORIZATION_RULES_SUCCESS,
  FETCH_CATEGORY_ERROR,
  FETCH_CATEGORY_REQUEST,
  FETCH_CATEGORY_SUCCESS,
  FETCH_SINGLE_CATEGORIZATION_RULE_ERROR,
  FETCH_SINGLE_CATEGORIZATION_RULE_REQUEST,
  FETCH_SINGLE_CATEGORIZATION_RULE_SUCCESS,
  FETCH_SINGLE_CATEGORY_ERROR,
  FETCH_SINGLE_CATEGORY_REQUEST,
  FETCH_SINGLE_CATEGORY_SUCCESS,
  GET_CATEGORY_STATISTICS_ERROR,
  GET_CATEGORY_STATISTICS_REQUEST,
  GET_CATEGORY_STATISTICS_SUCCESS,
  RESET_BLOCK_CATEGORY,
  UPDATE_CATEGORIZATION_RULES_ERROR,
  UPDATE_CATEGORIZATION_RULES_REQUEST,
  UPDATE_CATEGORIZATION_RULES_SUCCESS,
  UPDATE_CATEGORY_ERROR,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
} from '../reducers/CategoryReducer';

async function fetchCategories(params) {
  return await Axios.get('/categories', { params });
}

function* handleFetchCategories({ payload }) {
  try {
    const response = yield call(fetchCategories, payload);
    if (response) {
      yield put({
        type: FETCH_CATEGORY_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_CATEGORY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function fetchCategorizationRules({ code, ...params }) {
  return await Axios.get(`/categories/${code}/rules`, { params });
}

function* handleFetchCategorizationRules({ payload }) {
  try {
    const response = yield call(fetchCategorizationRules, payload);
    if (response) {
      yield put({
        type: FETCH_CATEGORIZATION_RULES_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_CATEGORIZATION_RULES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function createBulkCategories(payload) {
  return await Axios.post('/categories/bulk-create', payload);
}

function* handleCreateBulkCategories({ payload }) {
  try {
    const response = yield call(createBulkCategories, payload);
    if (response) {
      yield put({
        type: CREATE_BULK_CATEGORIES_SUCCESS,
        data: response.data,
      });

      yield delay(300);

      yield put({
        type: RESET_BLOCK_CATEGORY,
        blockType: 'createBulkCategories',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CREATE_BULK_CATEGORIES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function exportCategories(params) {
  return await Axios.get('/categories', { params });
}

function* handleExportCategories({ payload }) {
  try {
    const response = yield call(exportCategories, payload);
    if (response) {
      yield put({
        type: EXPORT_CATEGORY_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: EXPORT_CATEGORY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function fetchSingleCategory(code) {
  return await Axios.get(`/categories/${code}`);
}

function* handleFetchSingleCategory({ payload }) {
  try {
    const response = yield call(fetchSingleCategory, payload);
    if (response) {
      yield put({
        type: FETCH_SINGLE_CATEGORY_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_SINGLE_CATEGORY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function fetchSingleCategorizationRule({ code, categoryCode }) {
  return await Axios.get(`/categories/${categoryCode}/rules/${code}`);
}

function* handleFetchSingleCategorizationRule({ payload }) {
  try {
    const response = yield call(fetchSingleCategorizationRule, payload);
    if (response) {
      yield put({
        type: FETCH_SINGLE_CATEGORIZATION_RULE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_SINGLE_CATEGORIZATION_RULE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getCategoryStatistics({ code, ...params }) {
  return await Axios.get(`/categories/${code}/analytics`, { params });
}

function* handleGetCategoryStatistics({ payload }) {
  try {
    const response = yield call(getCategoryStatistics, payload);
    if (response) {
      yield put({
        type: GET_CATEGORY_STATISTICS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_CATEGORY_STATISTICS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function createCategories({ payload }) {
  return await Axios.post('/categories', payload);
}
function* handleCreateCategories({ payload }) {
  try {
    const response = yield call(createCategories, { payload });
    if (response) {
      yield put({
        type: CREATE_CATEGORY_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_CATEGORY,
        blockType: 'createCategories',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CREATE_CATEGORY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function createCategorizationRules({ code, ...restPayload }) {
  return await Axios.post(`/categories/${code}/rules`, restPayload);
}
function* handleCreateCategorizationRules({ payload }) {
  try {
    const response = yield call(createCategorizationRules, payload);
    if (response) {
      yield put({
        type: CREATE_CATEGORIZATION_RULES_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_CATEGORY,
        blockType: 'createCategorizationRules',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CREATE_CATEGORIZATION_RULES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateCategories({ payload }) {
  const code = payload.code;
  delete payload.code;

  return await Axios.put(`/categories/${code}`, payload);
}

function* handleUpdateCategories({ payload }) {
  try {
    const response = yield call(updateCategories, { payload });
    if (response) {
      yield put({
        type: UPDATE_CATEGORY_SUCCESS,
        data: response.data,
      });

      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_CATEGORY,
        blockType: 'updateCategories',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: UPDATE_CATEGORY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateCategorizationRule({ code, categoryCode, ...payload }) {
  return await Axios.put(`/categories/${categoryCode}/rules/${code}`, payload);
}

function* handleUpdateCategorizationRule({ payload }) {
  try {
    const response = yield call(updateCategorizationRule, payload);
    if (response) {
      yield put({
        type: UPDATE_CATEGORIZATION_RULES_SUCCESS,
        data: response.data,
      });

      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_CATEGORY,
        blockType: 'updateCategorizationRule',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: UPDATE_CATEGORIZATION_RULES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteCategories({ id }) {
  return await Axios.delete(`/categories/${id}`);
}

function* handleDeleteCategories({ id }) {
  try {
    const response = yield call(deleteCategories, { id: id?.id ?? id });
    if (response) {
      yield put({
        type: DELETE_CATEGORY_SUCCESS,
        data: response.data,
      });

      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_CATEGORY,
        blockType: 'deleteCategories',
      });

      yield put({
        type: FETCH_CATEGORY_REQUEST,
        payload: id?.params ?? undefined,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DELETE_CATEGORY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteCategorizationRule({ code, categoryCode }) {
  return await Axios.delete(`/categories/${categoryCode}/rules/${code}`);
}

function* handleDeleteCategorizationRule({ payload }) {
  try {
    const response = yield call(deleteCategorizationRule, payload);
    if (response) {
      yield put({
        type: DELETE_CATEGORIZATION_RULES_SUCCESS,
        data: response.data,
      });

      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_CATEGORY,
        blockType: 'deleteCategorizationRule',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DELETE_CATEGORIZATION_RULES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(FETCH_CATEGORY_REQUEST, handleFetchCategories),
  takeLatest(FETCH_CATEGORIZATION_RULES_REQUEST, handleFetchCategorizationRules),
  takeLatest(CREATE_BULK_CATEGORIES_REQUEST, handleCreateBulkCategories),
  takeLatest(EXPORT_CATEGORY_REQUEST, handleExportCategories),
  takeLatest(GET_CATEGORY_STATISTICS_REQUEST, handleGetCategoryStatistics),
  takeLatest(FETCH_SINGLE_CATEGORY_REQUEST, handleFetchSingleCategory),
  takeLatest(
    FETCH_SINGLE_CATEGORIZATION_RULE_REQUEST,
    handleFetchSingleCategorizationRule,
  ),
  takeLatest(CREATE_CATEGORY_REQUEST, handleCreateCategories),
  takeLatest(CREATE_CATEGORIZATION_RULES_REQUEST, handleCreateCategorizationRules),
  takeLatest(UPDATE_CATEGORY_REQUEST, handleUpdateCategories),
  takeLatest(DELETE_CATEGORY_REQUEST, handleDeleteCategories),
  takeLatest(UPDATE_CATEGORIZATION_RULES_REQUEST, handleUpdateCategorizationRule),
  takeLatest(DELETE_CATEGORIZATION_RULES_REQUEST, handleDeleteCategorizationRule),
]);
