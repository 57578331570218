import { CreditCardLock } from 'assets/icons';
import OverviewCard from 'components/OverviewCards';
import CustomButton from 'components/UI/CustomButton';
import IconWrap from 'components/UI/IconWrap';
import Loading from 'components/UI/Loading';
import React from 'react';
import { useSelector } from 'react-redux';

const ViewDefualtPin = ({ onDone, dataActivate }) => {
  const defaultPin = Array.from(dataActivate?.defaultPin ?? '');
  const {
    getDefaultPin: { loading: loadingPin },
  } = useSelector(({ card }) => card);

  return (
    <>
      {loadingPin ? (
        <Loading isPage color="#D28B28" />
      ) : (
        <div className="content">
          <div className="card-modal-body">
            <section className="card__typeSelector">
              <div className="information-wrapper">
                <h2 className="card-title w-100 border-hidden p-0">
                  Update PIN to secure your card
                </h2>
                <p className="text-xs">
                  This is your Default PIN. You can change it to something more secure and
                  memorable at any ATM.
                </p>

                <div className="my-4">
                  <p className="text-xs m-0 mb-1">Default PIN</p>
                  <div className="d-flex gap-2">
                    {defaultPin.map((item, index) => (
                      <div key={index} className="default__pin--box">
                        {item}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="activate-card activate-card-overview">
                  <OverviewCard minHeight={0}>
                    <div className="p-2 track__card">
                      <div className="mb-2">
                        <IconWrap bg="#F4E0C5" icon={<CreditCardLock />} />
                      </div>
                      <div className="heading">
                        <h3>It&apos;s time to change your PIN!</h3>
                        <p>Here&apos;s how to change your PIN at any ATM:</p>
                      </div>
                      <ul className="activate-card activate-card-overview -list">
                        <li>Insert your card and enter your default PIN.</li>
                        <li>{`Select the "Change PIN" option.`}</li>
                        <li>Enter your new PIN and confirm it.</li>
                      </ul>
                    </div>
                  </OverviewCard>
                </div>
              </div>

              <footer className="d-flex card-footer">
                <div className="footer__action">
                  {/* <CustomButton className="cancel button" onClick={closeHandler}>
              Cancel
            </CustomButton> */}
                  <CustomButton className="continue button" onClick={onDone}>
                    Done
                  </CustomButton>
                </div>
              </footer>
            </section>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewDefualtPin;
