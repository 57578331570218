import OwnerInfo from 'components/Onboarding/BusinessDetails/BussinessType/OwnersInfo';
import RequestInfo from 'components/Onboarding/BusinessDetails/BussinessType/RequestInfo';
import OnboardingSaved from 'components/Onboarding/BusinessDetails/OnboardingSaved';
import CustomButton from 'components/UI/CustomButton';
import Loading from 'components/UI/Loading';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCompany,
  getInvitedDirectors,
  inviteDirector,
  onboardingBusinessAddress,
  onboardingBusinessDoc,
  onboardingBusinessInformation,
  updateInvitedDirector,
  uploadDirectorInfo,
} from 'redux/actions/CompaniesAction';
import {
  businessTypes,
  getInternationalFormat,
  idType,
  states,
  validateNigeriaNumber,
  validateRCNumber,
} from 'utils/helper';
import { isValidPhoneNumber } from 'react-phone-number-input';
import isURL from 'validator/lib/isURL';
import { ReactComponent as RightIcon } from '../../../assets/icons/arrow-right.svg';
import StepOne from '../../Onboarding/BusinessDetails/StepOne';
import StepThree from '../../Onboarding/BusinessDetails/StepThree';
import StepTwo from '../../Onboarding/BusinessDetails/StepTwo';
import OnboardingNotice from './OnboardingNotice';
import './styles.scss';
import { allPermissions } from 'utils/AllowedTo';
import { toastError } from 'components/UI/toast';

const Information = ({
  setStep,
  setIsOtherPopoverOpen,
  localStep,
  setLocalStep,
  onboardingSaved,
  setOnboardingSaved,
  moveToAddBeneficiaries,
  businessStep,
  setBusinessStep,
  setIsPopoverOpen,
  isPopoverOpen,
  setEditAddress,
  editAddress,
}) => {
  const dispatch = useDispatch();
  const {
    user: { data = {} },
  } = useSelector(({ auth }) => auth);
  const {
    onboardingBusinessInformation: { loading, success },
    onboardingBusinessAddress: {
      loading: loadingBusinessAddress,
      success: successBusinessAddress,
    },
    onboardingBusinessDoc: { loading: loadingBusinessDoc, success: successBusinessDoc },
    getCompany: { data: companyData = {} },
    getCountry: { data: countryData = {} },
    inviteDirector: {
      success: successNewInvitedDirector,
      loading: loadingNewInvitedDirector,
    },
    updateInvitedDirector: {
      success: successUpdateInvitedDirector,
      loading: loadingUpdateInvitedDirector,
    },
    uploadDirectorInfo: { success: successDirectorInfo, loading: loadingDirectorInfo },
    getInvitedDirectors: { data: invitedDirectors },
  } = useSelector(({ companies }) => companies);
  const {
    getBeneficiaries: { data: beneficiariesData = {} },
  } = useSelector(({ beneficiaries }) => beneficiaries);
  const {
    getBudget: { data: budgetData = {}, success: loadingSuccess },
  } = useSelector(({ budgets }) => budgets);
  const moveToBudget = beneficiariesData?.beneficiaries?.length > 1;
  const hideContinueButton =
    beneficiariesData?.beneficiaries?.length > 1 && !!budgetData?.budgets?.length;

  const { isAdmin, onboardingStatus } = allPermissions();

  const getLocalData = JSON.parse(localStorage.getItem('overviewInfo')) || {};
  const [getLocalStorage, setGetLocalStorage] = useState();
  const [countries, setCountries] = useState([]);
  const [usedPersonalDetails, setUsedPersonalDetails] = useState(false);

  const [uploadingFile, setUploadingFile] = useState(false);

  const companyCode = data?.user?.company?.code;

  useEffect(() => {
    if (companyCode) dispatch(getCompany(companyCode));
  }, [localStep, companyCode]);

  useEffect(() => {
    if (success || successBusinessDoc) {
      if (localStep === 3 && businessStep === 0) {
        setBusinessStep(1);
      } else {
        setLocalStep(localStep + 1);
      }
    }
    if (
      successUpdateInvitedDirector ||
      successNewInvitedDirector ||
      successDirectorInfo
    ) {
      if (successNewInvitedDirector || successUpdateInvitedDirector)
        dispatch(getInvitedDirectors());
      setBusinessStep(4);
    }
  }, [
    success,
    successBusinessDoc,
    successNewInvitedDirector,
    successDirectorInfo,
    successUpdateInvitedDirector,
  ]);

  useEffect(() => {
    if (isAdmin && ['pending'].includes(onboardingStatus)) {
      if (
        invitedDirectors?.length > 0 &&
        companyData?.dateOfRegistration &&
        companyData?.utilityBill?.code &&
        companyData?.director?.percentageOwned &&
        companyData?.director?.dob &&
        companyData?.address?.postalCode
      ) {
        return setBusinessStep(4);
      }

      if (
        companyData?.businessType &&
        companyData?.dateOfRegistration &&
        companyData?.address?.postalCode &&
        !editAddress
      ) {
        setBusinessStep(1);
        setLocalStep(3);
        return;
      }

      if (
        companyData?.address?.city &&
        companyData?.address?.postalCode &&
        !editAddress
      ) {
        setBusinessStep(0);
        setLocalStep(3);
        return;
      }

      if (
        companyData?.name &&
        companyData?.industry &&
        companyData?.description &&
        companyData?.size &&
        companyData?.website
      ) {
        return setLocalStep(2);
      }
    }
  }, [companyData]);

  useEffect(() => {
    if (successBusinessAddress) {
      setEditAddress(false);
      if (localStep === 3) {
        localStorage.setItem('setStep', JSON.stringify(1));
        setStep(2);
      } else {
        setLocalStep(localStep + 1);
      }
    }
  }, [successBusinessAddress]);

  useEffect(() => {
    if (countryData.length > 0) {
      const countryOptions = countryData?.map(({ code: value, name: label }) => ({
        value,
        label,
        isDisabled: label !== 'NIGERIA' ? true : false,
      }));

      setCountries(countryOptions);
    }
  }, [countryData]);

  const skipKyb = () => {
    setStep(2);
    localStorage.setItem('setStep', JSON.stringify(1));
  };
  const getCompanySize = (val) => {
    switch (val) {
      case 'micro':
        return { value: 'micro', label: 'Micro (1 to 10 employees)' };
      case 'small':
        return { value: 'small', label: 'Small (11 to 50 employees)' };
      case 'medium':
        return { value: 'medium', label: 'Medium (51 to 200 employees)' };
      case 'large':
        return { value: 'large', label: 'Large (more than 200 employees)' };
      default:
        return { value: '', label: 'Select number of employees' };
    }
  };

  const [businessInfo, setBusinessInfo] = useState({
    companyName: companyData?.name || '',
    industry: companyData?.industry || '',
    companyDescription: companyData?.description || '',
    employeeSize: getCompanySize(companyData?.size) || '',
    // employeeSize: companyData?.size,
    website: companyData?.website || '',
    countryCode: '',
    localFormat: '',
    internationalFormat: '',
  });

  const [businessAddress, setBusinessAddress] = useState({
    country: '',
    businessAddress: '',
    state: '',
    city: '',
    businessEmail: '',
    postalCode: '',
    utilityBill: '',
    countryCode: '',
    localFormat: '',
    internationalFormat: '',
  });

  const [businessType, setBusinessType] = useState('');
  const [businessDoc, setBusinessDoc] = useState({
    type: '',
    bvn: '',
    idType: '',
    idNumber: '',
    rcNumber: '',
    cacITNumber: '',
    bnNumber: '',
    dob: '',
    directorPercentage: '',
    firstName: '',
    lastName: '',
    company_registration_date: '',
    tin: '',
    directorsEmail: '',
    countryCode: '',
    localFormat: '',
    internationalFormat: '',
    checked: '',
    idCopy: '',
  });
  const [checkReviewed, setCheckReviewed] = useState('');

  const [inviteDirectors, setInviteDirectors] = useState({
    name: '',
    email: '',
    note: '',
    code: undefined,
  });

  const [documents, setDocuments] = useState({
    cac: '',
    idCopy: '',
    utilityBill: '',
    incorporationCertificate: '',
    cacForm2: '',
    cacForm3: '',
    cacForm1: '',
    cacForm7: '',
    cacStatusReport: '',
    memorandumOfAssociation: '',
    cacBn1: '',
    cacITForm1: '',
    scumlCertificate: '',
    bnDocument: '',
  });

  const handleChangeBusinessInfo = ({ target: { name, value } }) => {
    setBusinessInfo({ ...businessInfo, [name]: value });
  };

  const handleChangeBusinessDoc = ({ target: { name, value } }) => {
    setBusinessDoc({ ...businessDoc, [name]: value });
  };

  const handleChangeBusinessAddress = ({ target: { name, value } }) => {
    setBusinessAddress({ ...businessAddress, [name]: value });
  };

  const handleChangeBusinessType = ({ val, val: { value, label } }) => {
    if (
      value === 'sole proprietorship' ||
      value === 'limited liability' ||
      value === 'ngo' ||
      value === 'enterprise' ||
      value === 'partnership'
    ) {
      setBusinessType(value);

      setBusinessDoc({ ...businessDoc, type: val });
    }
  };

  useEffect(() => {
    if (getLocalStorage)
      setGetLocalStorage({
        ...getLocalStorage,
        businessDetails: {
          ...getLocalStorage.businessDetails,
          businessInfo: {
            ...getLocalStorage.businessDetails.businessInfo,
            ...businessInfo,
          },
          businessDoc: {
            ...getLocalStorage.businessDetails.businessDoc,
            ...businessDoc,
          },
        },
      });
  }, [businessInfo, businessDoc]);

  useEffect(() => {
    if (getLocalStorage) {
      localStorage.setItem('overviewInfo', JSON.stringify(getLocalStorage));
    }
  }, [getLocalStorage]);

  const handleChangeCheckBox = (checked) => {
    if (checked) {
      setBusinessAddress({
        ...businessAddress,
        businessEmail: data?.user?.email,
        internationalFormat: getInternationalFormat(
          data?.user?.phoneNumber?.countryCode,
          data?.user?.phoneNumber?.localFormat,
        ),
        localFormat: data?.user?.phoneNumber?.localFormat,
        countryCode: data?.user?.phoneNumber?.countryCode,
      });
    } else {
      setBusinessAddress({
        ...businessAddress,
        businessEmail: '',
        internationalFormat: '',
        localFormat: '',
        countryCode: '',
      });
    }
  };

  const handleBusinessAddressPhoneNumberChange = (
    localFormat,
    internationalFormat,
    countryCode,
  ) => {
    setBusinessAddress({
      ...businessAddress,
      internationalFormat,
      localFormat,
      countryCode,
    });
  };

  const handlePhoneNumberChange = (localFormat, internationalFormat, countryCode) => {
    setBusinessDoc({ ...businessDoc, internationalFormat, localFormat, countryCode });
  };

  const getDocuments = (key) => {
    return companyData?.documents.find((item) => item.type === key);
  };

  useEffect(() => {
    if (Object.keys(companyData).length > 0) {
      if (localStep === 2)
        setBusinessAddress({
          ...businessAddress,
          internationalFormat:
            getInternationalFormat(
              companyData?.PhoneNumber?.countryCode,
              companyData?.PhoneNumber?.localFormat,
            ) || '',
          localFormat: companyData?.PhoneNumber?.localFormat || '',
          countryCode: companyData?.PhoneNumber?.countryCode || '',
          country: countries.find((i) => i.label === companyData.address?.country) || '',
          businessAddress: companyData.address?.street || '',
          state: states.find((i) => i.label === companyData.address?.state) || '',
          city: companyData.address?.city || '',
          businessEmail: companyData?.contactEmail || '',
          utilityBill: companyData?.utilityBill?.code || '',
          postalCode: companyData?.address?.postalCode || '',
        });

      //prefill of doc exist
      const idtypesOption = 'nin' || 'ip' || 'vi' || 'dl';
      const getIdTypes = getDocuments(idtypesOption);

      if (localStep === 3) {
        setDocuments({
          ...documents,
          cac: getDocuments('cac')?.asset?.code || '',
          idCopy: getIdTypes?.asset?.code || '',
          utilityBill: getDocuments('utility-Bill')?.asset?.code || '',
          incorporationCertificate: getDocuments('incorp_C')?.asset?.code || '',
          cacForm2: getDocuments('cacForm2')?.asset?.code || '',
          cacForm3: getDocuments('cacForm3')?.asset?.code || '',
          cacForm1: getDocuments('cacForm1')?.asset?.code || '',
          cacForm7: getDocuments('cacForm7')?.asset?.code || '',
          cacStatusReport: getDocuments('cacStatusRep')?.asset?.code || '',
          memorandumOfAssociation: getDocuments('moa')?.asset?.code || '',
          cacBn1: getDocuments('cacBn1')?.asset?.code || '',
          cacITForm1: getDocuments('cacITForm1')?.asset?.code || '',
          scumlCertificate: getDocuments('Scum_L_C')?.asset?.code || '',
          bnDocument: getDocuments('bnDocument')?.asset?.code || '',
        });

        setBusinessType(companyData?.businessType || '');
        setBusinessDoc({
          ...businessDoc,
          type: companyData?.businessType
            ? {
                value: companyData?.businessType,
                label: businessTypes.find(
                  (item) => item.value === companyData?.businessType,
                ).label,
              }
            : '',
          bvn: getDocuments('bvn')?.number || '',
          idType: getIdTypes?.type
            ? {
                value: getIdTypes?.type,
                label: idType.find((item) => item.value === getIdTypes?.type).label,
              }
            : '',
          idNumber: getIdTypes?.number || '',
          rcNumber: getDocuments('rcNumber')?.number || '',
          cacITNumber: getDocuments('cacITNumber')?.number || '',
          bnNumber: getDocuments('bnNumber')?.number || '',
          dob: companyData?.director?.dob || '',
          directorPercentage: companyData?.director?.percentageOwned || '',
          firstName: companyData?.director?.firstName || '',
          lastName: companyData?.director?.lastName || '',
          company_registration_date: companyData?.dateOfRegistration || '',
          tin: getDocuments('tin')?.number || '',
          directorsEmail: companyData?.director?.email || '',
          countryCode: companyData?.director?.phoneNumber?.countryCode || '',
          localFormat: companyData?.director?.phoneNumber?.localFormat || '',
          internationalFormat: companyData?.director?.phoneNumber?.internationalFormat
            ? `+${companyData?.director?.phoneNumber?.internationalFormat}`
            : '',
          checked: '',
          idCopy: '',
        });
      }
    }
  }, [companyData]);
  const handleContinue = () => {
    const splitUrl = businessInfo.website.split('.').length;
    if (localStep === 1) {
      if (!businessInfo.companyName) return toastError('Please enter your company name');
      if (!businessInfo.industry) return toastError('Please select your industry');
      if (!businessInfo.companyDescription)
        return toastError("Please enter your company's description");
      if (businessInfo.companyDescription.length < 15)
        return toastError("Your company's description must be more than 15 characters", {
          type: 'error',
        });
      if (!businessInfo.employeeSize)
        return toastError('Please select your employee size');
      if (!businessInfo.website) return toastError('Please enter your website');
      if (!isURL(businessInfo.website, { protocols: ['http', 'https'] })) {
        return toastError('Please enter a valid website');
      }
      if (businessInfo.website.includes('www.') && splitUrl < 3)
        return toastError('Please enter a valid website (e.g www.example.com', {
          type: 'error',
        });

      submitBusinessInformation();
    }

    if (localStep === 2) {
      if (!businessAddress.country) return toastError('Please select your country');
      if (!businessAddress.businessAddress)
        return toastError('Please enter your business address');
      if (!businessAddress.state) return toastError('Please select your state');
      if (!businessAddress.city) return toastError('Please enter your city');
      if (!businessAddress.postalCode) return toastError('Please enter your postal code');
      if (
        !isValidPhoneNumber(String(businessAddress?.internationalFormat)) ||
        !validateNigeriaNumber(String(businessAddress?.internationalFormat))
      )
        return toastError('Please enter a valid phone number');

      submitBusinessAddress();
    }

    if (localStep === 3 && [0].includes(businessStep)) {
      if (businessType === 'enterprise') {
        if (!documents.utilityBill) return toastError('Please upload a proof of address');
        if (!businessType) return toastError('Please select your business type');
        if (!businessDoc.company_registration_date)
          return toastError('Please enter your business registration date');
        if (!documents.utilityBill) return toastError('Please upload your utility bill');

        submitBusinessEnterpriceDoc();
      }

      if (businessType === 'sole proprietorship') {
        if (!documents.utilityBill) return toastError('Please upload a proof of address');
        if (!businessType) return toastError('Please select your business type');
        if (!businessDoc.company_registration_date)
          return toastError('Please enter your business registration date');
        if (!businessDoc.bnNumber) return toastError('Please enter your BN number');
        if (!documents.cac) return toastError('Please upload your CAC document');
        if (!documents.cacBn1) return toastError('Please upload your CAC-BN1 document');

        submitBusinessSolePDoc();
      }

      if (businessType === 'ngo') {
        if (!documents.utilityBill) return toastError('Please upload a proof of address');
        if (!businessType) return toastError('Please select your business type');
        if (!businessDoc.company_registration_date)
          return toastError('Please enter your business registration date');
        if (!businessDoc.cacITNumber)
          return toastError('Please enter your CAC IT Number');
        if (!documents.cacITForm1)
          return toastError('Please upload your CAC IT Form 1 document');

        submitBusinessNGODoc();
      }

      if (businessType === 'partnership') {
        if (!documents.utilityBill) return toastError('Please upload a proof of address');
        if (!businessType) return toastError('Please select your business type');
        if (!businessDoc.company_registration_date)
          return toastError('Please enter your business registration date');
        if (!businessDoc.bnNumber) return toastError('Please enter your RC Number');
        if (!documents.bnDocument)
          return toastError('Please upload your BN document from CAC');
        if (!documents.cacBn1) return toastError('Please upload your CAC-BN1 document');

        submitBusinessPartnershipDoc();
      }

      if (businessType === 'limited liability') {
        const year = new Date(businessDoc.company_registration_date);
        const pre2017Date = new Date('2016-12-31');
        const post2017Date = new Date('2020-11-30');
        const pre2017 = year <= pre2017Date && !!businessDoc.company_registration_date;
        const post2017 =
          year > pre2017Date &&
          year <= post2017Date &&
          !!businessDoc.company_registration_date;
        const post2020 = year > post2017Date && !!businessDoc.company_registration_date;

        if (!documents.utilityBill) return toastError('Please upload a proof of address');
        if (!businessType) return toastError('Please select your business type');
        if (!businessDoc.rcNumber) return toastError('Please enter your RC Number');
        if (!validateRCNumber(businessDoc.rcNumber))
          return toastError('Please enter a valid RC number-RC0000000');
        if (!businessDoc.company_registration_date)
          return toastError('Please enter your business registration date');
        if (!businessDoc.tin)
          return toastError('Please enter your tax identification number');

        if (!documents.incorporationCertificate)
          return toastError('Please upload your CAC Document');
        if (pre2017) {
          if (!documents.cacForm2)
            return toastError('Please upload your share holders document');
          if (!documents.cacForm3)
            return toastError(
              'Please upload your notification of registered address document',
              { type: 'error' },
            );
          if (!documents.cacForm7)
            return toastError('Please upload your particulars of directors document', {
              type: 'error',
            });
        }
        if (post2017) {
          if (!documents.cacForm1)
            return toastError(
              'Please upload your application for registration document',
              {
                type: 'error',
              },
            );
        }
        if (post2020) {
          if (!documents.cacStatusReport)
            return toastError('Please upload your CAC status report');
        }
        if (!documents.memorandumOfAssociation)
          return toastError('Please upload your MOA document');
        submitBusinessLLCDoc();
      }
    }

    if (localStep === 3 && [3].includes(businessStep)) {
      if (!inviteDirectors.name) return toastError('Please enter director fullname');
      if (!inviteDirectors.email) return toastError('Please select director email');

      const payload = {
        ...inviteDirectors,
        note: inviteDirectors.note ? inviteDirectors.note : undefined,
      };
      if (inviteDirectors.code) {
        dispatch(updateInvitedDirector(payload));
      } else {
        dispatch(inviteDirector(payload));
      }
    }

    if (localStep === 3 && [2].includes(businessStep)) {
      if (!businessDoc.firstName)
        return toastError("Please enter your director's first name");
      if (!businessDoc.lastName)
        return toastError("Please enter your director's last name");
      if (!businessDoc.directorsEmail)
        return toastError("Please enter your director's email");
      if (
        !isValidPhoneNumber(String(businessDoc?.internationalFormat)) ||
        !validateNigeriaNumber(String(businessDoc?.internationalFormat))
      )
        return toastError('Please enter a valid phone number');
      if (!businessDoc.dob) return toastError('Please enter your date of birth');
      if (!businessDoc.bvn) return toastError("Please enter your director's BVN");
      if (!businessDoc.directorPercentage)
        return toastError('Please enter percentage owned');
      if (+businessDoc.directorPercentage < 5)
        return toastError('Percentage owned must be at least 5%');
      if (!businessDoc.idType) return toastError('Please select your ID type');
      if (!businessDoc.idNumber)
        return toastError(
          `Please enter your director's ${businessDoc.idType.value} number`,
        );
      if (
        (businessDoc.idNumber.length < 11 || businessDoc.idNumber.length > 11) &&
        businessDoc.idType.value === 'nin'
      )
        return toastError('ID number must be 11 digit');

      if (!documents.idCopy) return toastError('Please upload a copy of your ID');
      if (!businessDoc.checked)
        return toastError(
          'Please confirm all the information provided here are accurate',
          {
            type: 'error',
          },
        );

      const payload = {
        firstName: businessDoc.firstName,
        lastName: businessDoc.lastName,
        email: businessDoc.directorsEmail,
        phoneNumber: {
          countryCode: businessDoc.countryCode,
          localFormat: businessDoc.localFormat,
        },
        documents: {
          dateOfBirth: businessDoc.dob,
          bvn: businessDoc.bvn,
          idType: businessDoc.idType.value,
          idNumber: businessDoc.idNumber,
          idCopy: documents.idCopy,
          directorPercentage: businessDoc.directorPercentage,
        },
      };
      dispatch(uploadDirectorInfo(payload));
    }

    if (localStep === 3 && [4].includes(businessStep)) {
      if (!checkReviewed)
        return toastError(
          'Please confirm all the information provided here are accurate',
          {
            type: 'error',
          },
        );
      setOnboardingSaved(true);
    }
  };

  const submitBusinessInformation = () => {
    const payload = {
      name: businessInfo.companyName,
      industry: businessInfo.industry.value,
      description: businessInfo.companyDescription,
      url: businessInfo.website,
      business_size: businessInfo.employeeSize.value,
    };

    dispatch(onboardingBusinessInformation(payload));
  };

  const submitBusinessAddress = () => {
    const payload = {
      country_code: businessAddress.country.value,
      state: businessAddress.state.label,
      city: businessAddress.city,
      address: businessAddress.businessAddress,
      contact_email: businessAddress.businessEmail,
      postalCode: businessAddress.postalCode,
      phone: {
        countryCode: String(businessAddress.countryCode),
        localFormat: businessAddress.localFormat,
      },
      contact_details: usedPersonalDetails,
    };
    dispatch(onboardingBusinessAddress(payload));
  };

  const submitBusinessEnterpriceDoc = () => {
    const { company_registration_date } = businessDoc;
    const { utilityBill } = documents;
    const payload = {
      type: businessType,
      document: {
        companyRegistrationDate: company_registration_date,
        utilityBill,
      },
    };
    dispatch(onboardingBusinessDoc(payload));
  };

  const submitBusinessSolePDoc = () => {
    const { bnNumber, company_registration_date } = businessDoc;
    const { cac, cacBn1, utilityBill } = documents;
    const payload = {
      type: businessType,
      document: {
        companyRegistrationDate: company_registration_date,
        bnNumber: bnNumber,
        cac,
        cacBn1,
        utilityBill,
      },
    };

    dispatch(onboardingBusinessDoc(payload));
  };

  const submitBusinessNGODoc = () => {
    const { cacITNumber, company_registration_date } = businessDoc;
    const { scumlCertificate, cacITForm1, utilityBill } = documents;
    const payload = {
      type: businessType,
      document: {
        companyRegistrationDate: company_registration_date,
        cacITForm1,
        cacITNumber,
        scumlCertificate,
        utilityBill,
      },
    };

    dispatch(onboardingBusinessDoc(payload));
  };

  const submitBusinessPartnershipDoc = () => {
    const { bnNumber, company_registration_date } = businessDoc;
    const { bnDocument, cacBn1, utilityBill } = documents;
    const payload = {
      type: businessType,
      document: {
        companyRegistrationDate: company_registration_date,
        bnNumber: bnNumber,
        bnDocument,
        cacBn1,
        utilityBill,
      },
    };

    dispatch(onboardingBusinessDoc(payload));
  };

  const submitBusinessLLCDoc = () => {
    const { rcNumber, company_registration_date, tin } = businessDoc;
    const {
      incorporationCertificate,
      cacForm2,
      cacForm1,
      cacForm3,
      cacForm7,
      cacStatusReport,
      memorandumOfAssociation,
      utilityBill,
    } = documents;
    const payload = {
      type: businessType,
      document: {
        rcNumber,
        companyRegistrationDate: company_registration_date,
        taxIdentificationNumber: tin,
        incorporationCertificate,
        cacForm2: cacForm2 ? cacForm2 : undefined,
        memorandumOfAssociation,
        cacForm3: cacForm3 ? cacForm3 : undefined,
        cacForm7: cacForm7 ? cacForm7 : undefined,
        cacForm1: cacForm1 ? cacForm1 : undefined,
        cacStatusReport: cacStatusReport ? cacStatusReport : undefined,
        utilityBill,
      },
    };
    dispatch(onboardingBusinessDoc(payload));
  };

  useEffect(() => {
    setGetLocalStorage(getLocalData);
    if (getLocalData) {
      if (Object.keys(getLocalData?.businessDetails)?.length > 0) {
        if (Object.keys(getLocalData?.businessDetails?.businessInfo)?.length > 0)
          setBusinessInfo(getLocalData?.businessDetails?.businessInfo);
        if (Object.keys(getLocalData?.businessDetails?.businessDoc)?.length > 0)
          setBusinessDoc(getLocalData?.businessDetails?.businessDoc);
        // if (Object.keys(getLocalData.businessDetails.incorporation).length > 0)
        //   setIncorporation(getLocalData.businessDetails.incorporation);
      }
    }
  }, []);

  useEffect(() => {
    if (successDirectorInfo) {
      setOnboardingSaved(true);
    }
  }, [successDirectorInfo]);

  const headerTitle =
    localStep < 3
      ? 'Business Information'
      : [0, 1, 2].includes(businessStep) && localStep === 3
      ? 'Business Incorporation'
      : [3].includes(businessStep) && localStep === 3
      ? 'Request information from business owner'
      : 'Review & Submit';

  const buttonTitle =
    [0].includes(businessStep) && localStep === 3 ? (
      'Add Incorporation details'
    ) : [1, 2, 4].includes(businessStep) && localStep === 3 ? (
      'Submit'
    ) : [3].includes(businessStep) && localStep === 3 ? (
      inviteDirectors.code ? (
        'Update request'
      ) : (
        'Send request'
      )
    ) : (
      <>
        Continue &nbsp; <RightIcon className="ml-2" />
      </>
    );

  const disableButton =
    loading ||
    (!checkReviewed && [4].includes(businessStep)) ||
    (!businessDoc.checked && [1, 2].includes(businessStep)) ||
    loadingBusinessAddress ||
    uploadingFile ||
    loadingNewInvitedDirector ||
    loadingUpdateInvitedDirector ||
    loadingDirectorInfo ||
    loadingBusinessDoc;

  const showButtonLoader =
    loading ||
    loadingBusinessAddress ||
    loadingNewInvitedDirector ||
    loadingUpdateInvitedDirector ||
    loadingDirectorInfo ||
    loadingBusinessDoc;

  const handleEditInfo = () => {
    setOnboardingSaved(false);
  };

  const showSkipforNow =
    localStep === 3 && ![1, 2, 3, 4].includes(businessStep) && !hideContinueButton;

  return (
    <div className="information-wrapper">
      {loadingBusinessDoc || onboardingSaved ? (
        ''
      ) : (
        <h2 className="card-title w-100">{headerTitle}</h2>
      )}
      {!onboardingSaved && (
        <OnboardingNotice
          businessStep={businessStep}
          localStep={localStep}
          setBusinessStep={setBusinessStep}
          setEditAddress={setEditAddress}
          setCheckReviewed={setCheckReviewed}
          setLocalStep={setLocalStep}
          setIsPopoverOpen={setIsPopoverOpen}
          isPopoverOpen={isPopoverOpen}
          setInviteDirectors={setInviteDirectors}
        />
      )}

      <Row>
        <Col xs={12}>
          <div className="form-normal">
            {onboardingSaved ? (
              <OnboardingSaved
                onClick={moveToAddBeneficiaries}
                buttonName={moveToBudget ? 'Add Budgets' : 'Add Members'}
                showBackButton={!companyData.director}
                showContinueButton={!hideContinueButton}
                backButtonName="Edit information"
                onClickBackButton={handleEditInfo}
              />
            ) : (
              <>
                {localStep === 1 && (
                  <StepOne
                    handleChangeBusinessInfo={handleChangeBusinessInfo}
                    businessInfo={businessInfo}
                    setBusinessInfo={setBusinessInfo}
                    companyData={companyData}
                  />
                )}

                {localStep === 2 && (
                  <StepTwo
                    handleChangeCheckBox={handleChangeCheckBox}
                    handleChangeBusinessAddress={handleChangeBusinessAddress}
                    setBusinessAddress={setBusinessAddress}
                    businessAddress={businessAddress}
                    countries={countries}
                    setIsOtherPopoverOpen={setIsOtherPopoverOpen}
                    companyData={companyData}
                    setUploadingFile={setUploadingFile}
                    uploadingFile={uploadingFile}
                    handleBusinessAddressPhoneNumberChange={
                      handleBusinessAddressPhoneNumberChange
                    }
                  />
                )}

                {localStep === 3 && ![1, 2, 3, 4].includes(businessStep) && (
                  <StepThree
                    setBusinessStep={setBusinessStep}
                    businessStep={businessStep}
                    businessDoc={businessDoc}
                    handleChangeBusinessDoc={handleChangeBusinessDoc}
                    businessType={businessType}
                    handleChangeBusinessType={handleChangeBusinessType}
                    setBusinessDoc={setBusinessDoc}
                    setIsOtherPopoverOpen={setIsOtherPopoverOpen}
                    setUploadingFile={setUploadingFile}
                    uploadingFile={uploadingFile}
                    setDocuments={setDocuments}
                    documents={documents}
                    handlePhoneNumberChange={handlePhoneNumberChange}
                    loadingBusinessDoc={loadingBusinessDoc}
                  />
                )}

                {localStep === 3 && [1, 2].includes(businessStep) && (
                  <OwnerInfo
                    businessDoc={businessDoc}
                    businessStep={businessStep}
                    handleChangeBusinessDoc={handleChangeBusinessDoc}
                    handlePhoneNumberChange={handlePhoneNumberChange}
                    setBusinessDoc={setBusinessDoc}
                    setUploadingFile={setUploadingFile}
                    uploadingFile={uploadingFile}
                    setDocuments={setDocuments}
                    documents={documents}
                  />
                )}

                {localStep === 3 && [3].includes(businessStep) && (
                  <RequestInfo
                    inviteDirectors={inviteDirectors}
                    setInviteDirectors={setInviteDirectors}
                  />
                )}
                {!loadingBusinessDoc && (
                  <CustomButton
                    onClick={handleContinue}
                    fullWidth={true}
                    disabled={disableButton}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: '24px',
                    }}
                  >
                    {buttonTitle}
                    {showButtonLoader ? <Loading color="#D28B28" size={30} /> : ''}
                  </CustomButton>
                )}
                {showSkipforNow && (
                  <div
                    className="text-center mt-3 cursor"
                    onClick={moveToAddBeneficiaries}
                  >
                    <span>Skip for now</span>
                  </div>
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default Information;
