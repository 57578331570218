import CustomDatePicker from 'components/UI/CustomDatePicker';
import FileUpload from 'components/UI/FileUpload';
import { addYears } from 'date-fns';
import dayjs from 'dayjs';
import { Row } from 'react-bootstrap';
import '../../styles.scss';
const Enterprise = ({
  setUploadingFile,
  uploadingFile,
  businessDoc,
  setBusinessDoc,
  setDocuments,
  documents,
}) => {
  return (
    <>
      <div className="information">Company Information</div>
      <Row className="mb-3">
        <CustomDatePicker
          label="Company Registration Date *"
          onChange={(value) =>
            setBusinessDoc({
              ...businessDoc,
              company_registration_date: dayjs(value).format('YYYY-MM-DD'),
            })
          }
          peekNextMonth
          maxDate={addYears(new Date(), 0)}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          placeholderText="Company Registration Date"
          name="company_registration_date"
          selected={
            businessDoc.company_registration_date &&
            dayjs(businessDoc.company_registration_date).toDate()
          }
        />
      </Row>
      <Row className="mb-3">
        <FileUpload
          label="Utility Bill *"
          placeholder="Upload an utility bill(PDF, JPEG, PNG)"
          name="file"
          uploadedFile={documents.utilityBill}
          onChange={({ assetCode }) =>
            setDocuments({ ...documents, utilityBill: assetCode })
          }
          setUploadingFile={setUploadingFile}
          uploadingFile={uploadingFile}
        />
      </Row>
    </>
  );
};
export default Enterprise;
