import { FETCH_CATEGORY_STATS_REQUEST } from '../reducers/AnalyticsReducer';
import { FETCH_BENEFICIARY_STATS_REQUEST } from '../reducers/AnalyticsReducer';
import { FETCH_VENDOR_STATS_REQUEST } from '../reducers/AnalyticsReducer';

export const getCategoryStats = (payload) => ({
  type: FETCH_CATEGORY_STATS_REQUEST,
  payload,
});

export const getBeneficiaryStats = (payload) => ({
  type: FETCH_BENEFICIARY_STATS_REQUEST,
  payload,
});

export const getVendorStats = (payload) => ({
  type: FETCH_VENDOR_STATS_REQUEST,
  payload,
});
