import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import LineStatus from 'components/LineStatus';
import OverlayIconsName from 'components/Teams/OverlayIconsName';
import CustomPopover from 'components/UI/Popover';
import { useState } from 'react';
import { Badge, Col } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { useHistory } from 'react-router-dom';
import { getCurrency, percentageValue } from 'utils/helper';
import './styles.scss';
import { numFormatter } from 'utils/utility';
import { allPermissions, hasPermission } from 'utils/AllowedTo';

const TeamsCard = ({ teams, role, onEdit, onDelete }) => {
  const {
    budgets = [],
    code,
    managers = [],
    members = [],
    name,
    status,
    spent = 0,
    available = 0,
    total = 0,
    currency = 'NGN',
  } = teams || {};
  const history = useHistory();
  const [isPopoverOpen, setIsPopoverOpen] = useState();

  const { permissions } = allPermissions();
  const canManage = hasPermission({
    permissions,
    scopes: ['team-*', 'team-create', 'team-edit'],
  });

  const handleTogglePopover = () => {
    if (status !== 'deleted') setIsPopoverOpen(!isPopoverOpen);
  };

  const overview = () => {
    if (
      status === 'deleted' ||
      (status === 'inactive' && ['employee', 'manager', 'teamLead'].includes(role))
    )
      return;
    return history.push(`/teams/${code}/overview`);
  };

  const ListActions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() => {
            onEdit(teams);
            setIsPopoverOpen(false);
          }}
        >
          <EditOutlined />
          Edit Team
        </div>
        <div
          className="actionLink"
          onClick={() => {
            onDelete(teams);
            setIsPopoverOpen(false);
          }}
        >
          <DeleteOutlined /> Delete Team
        </div>
      </div>
    );
  };

  return (
    <Col md={6}>
      <div className="border-full p-4 mb-4 rounded">
        <div className="d-flex align-items-center justify-content-between pb-4">
          <div className="teams-header w-100">
            <div
              className="d-flex justify-content-between align-items-center cursor"
              onClick={() => overview()}
            >
              <h2>
                {name}
                {status === 'inactive' && (
                  <Badge className="text-uppercase balance-tag dark-bg text-white text-dark">
                    inactive
                  </Badge>
                )}
                {status === 'deleted' && (
                  <Badge className="text-uppercase balance-tag dark-bg text-white text-dark">
                    Closed
                  </Badge>
                )}
              </h2>
            </div>

            {canManage && (
              <div className="more-outline">
                <CustomPopover
                  content={status !== 'deleted' && <ListActions />}
                  showPopover={isPopoverOpen}
                  clickOutside={handleTogglePopover}
                >
                  <div onClick={handleTogglePopover} className="cursor">
                    <MoreOutlined />
                  </div>
                </CustomPopover>
              </div>
            )}

            <div className="d-flex space-x-6 mt-2">
              <OverlayIconsName name="Managers" value={managers?.length && managers} />
              <OverlayIconsName name="Members" value={members?.length && members} />
              <OverlayIconsName
                name="Budgets"
                value={budgets?.length && budgets}
                budget
              />
            </div>

            {/* )} */}
          </div>
        </div>
        <LineStatus
          active={percentageValue(total, spent)}
          pending={percentageValue(available, spent)}
        />
        <div className="d-flex flex-wrap pt-4">
          <div className="teams-amount">
            <p>
              Spent <span className="dots spent-dot" />
            </p>
            <h3 className="fw-200">
              <CurrencyFormat
                prefix={getCurrency(currency)}
                value={numFormatter(spent / 100)}
                displayType="text"
                thousandSeparator={true}
                isNumericString
              />
            </h3>
          </div>
          <div className="teams-amount">
            <p>
              {'Total budget'}
              <span className="dots active-line disbursed-dot" />
            </p>
            <h3 className="fw-200">
              <CurrencyFormat
                prefix={getCurrency(currency)}
                value={numFormatter(total / 100)}
                displayType="text"
                thousandSeparator={true}
                isNumericString
              />
            </h3>
          </div>
          <div className="teams-amount">
            <p>
              Available <span className="dots available-dot" />
            </p>
            <h3 className="text-dark">
              <CurrencyFormat
                prefix={getCurrency(currency)}
                value={numFormatter(available / 100)}
                displayType="text"
                thousandSeparator={true}
                isNumericString
              />
            </h3>
          </div>
        </div>
      </div>
    </Col>
  );
};
export default TeamsCard;
