import '../styles.scss';
import { Row } from 'react-bootstrap';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import { useEffect } from 'react';
import CustomTextarea from 'components/UI/CustomTextarea';
// import * as fs from 'fs';

const StepOne = ({
  handleChangeBusinessInfo,
  businessInfo,
  setBusinessInfo,
  companyData,
}) => {
  const industry = [
    {
      label: 'Agriculture',
      value: 'idt_gnxq4u7ccooy8m9e3',
    },
    {
      label: 'Cryptocurrency',
      value: 'idt_k2nuc6sg5c5owf0b4',
    },
    {
      label: 'Digital Services',
      value: 'idt_h1e1cjaz3go3iriwg',
    },
    {
      label: 'E-Commerce',
      value: 'idt_sfw1e9kb7qdgeih11',
    },
    {
      label: 'Entertainment',
      value: 'idt_uqfwghq792l7vcjbq',
    },
    {
      label: 'Financial Services',
      value: 'idt_j0aw4kgpp892bdni6',
    },
    {
      label: 'FinTech',
      value: 'idt_ijzrxjc79buq7m1y8',
    },
    {
      label: 'General Services',
      value: 'idt_c9tsgmqlk1jmzg7fd',
    },
    {
      label: 'Hospitals and Health',
      value: 'idt_ulgokj2792daxoyq5',
    },
    {
      label: 'Software',
      value: 'idt_yrrm6eegi79zydea0',
    },
    {
      label: 'Health Tech',
      value: 'idt_720l8ku3o3hl5j8pn',
    },
    {
      label: 'Leisure and Entertainment',
      value: 'idt_8d2296vhdwsr95vmx',
    },
    {
      label: 'Membership Groups',
      value: 'idt_it2kqy662vtk0hku2',
    },
    {
      label: 'NGOs',
      value: 'idt_hfjs6gn144afoszx8',
    },
    {
      label: 'Press and Media',
      value: 'idt_2sh70zvi4znmxz41c',
    },
    {
      label: 'Religious Organizations',
      value: 'idt_ibtnhcfi9ckbmmbss',
    },
    {
      label: 'Restaurant and Food',
      value: 'idt_v6wkolf7w7gdukh43',
    },
    {
      label: 'Schools',
      value: 'idt_rv0v1e0yoosntnqmg',
    },
    {
      label: 'Travel & Hospitality',
      value: 'idt_jssjr619lqvijhrol',
    },
    {
      label: 'Utilities',
      value: 'idt_ov4qia857qkjvtax1',
    },
    {
      label: 'Others',
      value: 'idt_dcs19euw61k4hdsye',
    },
  ];

  useEffect(() => {
    const setIndustry = async () => {
      const industryValue = {};

      if (companyData.industry) {
        industryValue['value'] = await companyData.industry['code'];
        industryValue['label'] = await companyData.industry['name'];

        setBusinessInfo({ ...businessInfo, industry: industryValue });
      }
    };

    setIndustry();
  }, []);

  return (
    <>
      <div className="onboardingStepDiv">
        <Row className="mb-3 mt-3">
          <CustomInput
            label="Legal business name *"
            placeholder="Enter company name"
            type="text"
            name="companyName"
            maxLength="50"
            onChange={handleChangeBusinessInfo}
            value={businessInfo.companyName}
          />
        </Row>
        <Row className="mb-3">
          <CustomSelect
            label="Industry *"
            name="industry"
            placeholder="Select industry"
            onChange={(val) => setBusinessInfo({ ...businessInfo, industry: val })}
            value={businessInfo.industry}
            options={industry}
          />
        </Row>
        <Row className="mb-3">
          <CustomTextarea
            label="Company's description *"
            name="companyDescription"
            placeholder="E.g. What products or services does your company offer?"
            onChange={handleChangeBusinessInfo}
            value={businessInfo.companyDescription}
            maxLength="250"
          />
        </Row>
        <Row className="mb-3">
          <CustomSelect
            label="Number of employees *"
            name="employeeSize"
            placeholder="Select number of employees"
            onChange={(val) => setBusinessInfo({ ...businessInfo, employeeSize: val })}
            value={businessInfo.employeeSize}
            options={[
              { value: 'micro', label: 'Micro (1 to 10 employees)' },
              { value: 'small', label: 'Small (11 to 50 employees)' },
              { value: 'medium', label: 'Medium (51 to 200 employees)' },
              { value: 'large', label: 'Large (more than 200 employees)' },
            ]}
          />
        </Row>
        <Row className="mb-3">
          <CustomInput
            label="Website *"
            placeholder="Enter URL"
            type="text"
            name="website"
            maxLength="50"
            onChange={handleChangeBusinessInfo}
            value={businessInfo.website}
          />
        </Row>
      </div>
    </>
  );
};
export default StepOne;
