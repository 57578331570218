import React, { useEffect, useMemo, useState } from 'react';
import './styles.scss';
import { ReactComponent as Cancel } from 'assets/icons/cancel.svg';
import cs from 'classnames';
import CustomButton from 'components/UI/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { toastError } from 'components/UI/toast';
import { getSingleBalance } from 'redux/actions/BudgetsAction';
import OverviewCard from 'components/OverviewCards';
import FileUpload from 'components/UI/FileUpload';
import { createMandate, getMandateStatus } from 'redux/actions/CompaniesAction';
import { addYears, format } from 'date-fns';
import { addDays } from 'date-fns';
import CustomSelect from 'components/UI/CustomSelect';
import CustomRoleSelect from 'components/UI/CustomRoleSelect';
import BanksIcons from 'assets/icons/banks';
import CustomBanksSelect from 'components/UI/CustomBanksSelec';
import LabeledInfo from 'components/bujetiPayModal/LabeledInfo';
import classNames from 'classnames';
import { RESET_BLOCK_COMPANY } from 'redux/reducers/CompaniesReducer';

const MandateDialog = ({ onClose }) => {
  const [removeFile, setRemoveFile] = useState(false);
  const [imgBase64, setImgBase64] = useState(undefined);
  const [isFile, setSetFile] = useState(false);
  const { accountCode } = useParams();
  const dispatch = useDispatch();
  const [type, setType] = useState('e-mandate');

  const {
    createMandate: { data, loading, success },
    getMandateStatus: {
      success: getMandateSuccess,
      data: getmandate,
      loading: getMandateLoading,
    },
  } = useSelector(({ companies }) => companies);

  const {
    getSingleBalance: { data: singleBalance, loading: singleBalanceLoading },
  } = useSelector(({ budgets }) => budgets);

  useEffect(() => {
    if (!getMandateLoading && !data && singleBalance?.mandateCode)
      dispatch(getMandateStatus(singleBalance?.mandateCode));
  }, [data]);

  const readUploadFile = (files, fileName) => {
    if (files) {
      // Ensure files is not null or empty
      const reader = new FileReader();

      reader.onload = async (e) => {
        try {
          const image = new Image();

          image.onload = function () {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            const maxWidth = 700; // Max width for the image
            const maxHeight = 700; // Max height for the image
            let width = image.width;
            let height = image.height;

            // Calculate new dimensions to fit within the max width and height
            if (width > height) {
              if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
              }
            } else {
              if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
              }
            }

            // Set canvas dimensions
            canvas.width = width;
            canvas.height = height;

            // Draw image on canvas
            context.drawImage(image, 0, 0, width, height);

            // Convert canvas to base64
            const base64String = canvas.toDataURL('image/jpeg', 0.8); // Adjust quality as needed

            setImgBase64(base64String.split(',')[1]);
          };

          // Set image source
          image.src = e.target.result;
        } catch (error) {
          toastError('Error reading file:', error);
        }
      };

      reader.readAsDataURL(files);
    } else {
      setImgBase64(undefined);
    }
    setSetFile(!!fileName);
  };

  const handleOnClose = () => {
    onClose();
    if (type === 'e-mandate' && success) dispatch(getSingleBalance(accountCode));
    dispatch({ type: RESET_BLOCK_COMPANY, blockType: 'createMandate' });
    dispatch({ type: RESET_BLOCK_COMPANY, blockType: 'getMandateStatus' });
  };

  useEffect(() => {
    if (success) {
      if (type === 'e-mandate') handleOnClose();
      if (type === 'signed-mandate') {
        handleOnClose();
        dispatch(getSingleBalance(accountCode));
      }
    }
  }, [success]);

  useEffect(() => {
    if (singleBalance?.mandateStatus === 'pending') {
      if (singleBalance?.mandateType === 'signed') setType('signed-mandate');
      else setType('e-mandate');
    }
  }, []);

  const mandataData = data ?? getmandate;
  const bankDestination = mandataData?.transferDestinations?.find(
    (item) => item.bank_name === 'Paystack Titan',
  );

  const emandateRequest = () => {
    const today = new Date();
    const startDate = addDays(today, 3); // Start date is 3 days from now
    const endDate = addYears(today, 1); // End date is 1 year from now

    const payload = {
      accountNumber: singleBalance?.number,
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      bankCode: singleBalance?.bankCode,
      amount: 200000000 * 100,
      accountName: singleBalance?.accountName,
      bankName: singleBalance?.bankName,
    };
    dispatch(createMandate(payload));
  };

  const handleCreate = () => {
    if (!isFile && !bankDestination) return toastError('Please upload your signature');

    const today = new Date();
    const startDate = addDays(today, 3); // Start date is 3 days from now
    const endDate = addYears(today, 1); // End date is 1 year from now

    const payload = {
      accountNumber: singleBalance?.number,
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      signature: imgBase64,
      bankCode: singleBalance?.bankCode,
      amount: 200000000 * 100,
      accountName: singleBalance?.accountName,
      bankName: singleBalance?.bankName,
    };

    if (bankDestination) return handleOnClose();
    return dispatch(createMandate(payload));
  };

  const handleSelectMandate = (type) => {
    setType(type);
  };
  return (
    <div>
      <div className="mandate-modal-overlay" />
      <div className="mandate-modal-wrapper position-relative">
        <div className="mandate-modal-body">
          <i onClick={handleOnClose} className="cancel-icon">
            <Cancel />
          </i>
          <div>
            <div className="mt-0 w-100 mandate-wrapper">
              <h4 className="text-start">
                Direct debit {type === 'e-mandate' ? 'e-Mandate ' : 'signed mandate '}
                authorisation
              </h4>
              <p className="text-start text-xs desc">
                This step confirms you&apos;ve authorized us to securely access your
                external account.
                <br />
                <br />
                <b>Note:</b> You can spend up to 200 million Naira via direct debit. If
                your total transfers exceed this limit, you cannot use direct debit until
                you renew your mandate.
              </p>
              <div className={classNames('swtich-wrapper mt-3')}>
                <div
                  aria-label={type}
                  className={classNames(
                    'swtich-text',
                    {
                      ['active-switch']: type === 'e-mandate',
                    },
                    {
                      ['disabled bg-transparent']:
                        singleBalance?.mandateStatus === 'pending' &&
                        singleBalance?.mandateType === 'signed',
                    },
                  )}
                  onClick={() => {
                    bankDestination ? null : handleSelectMandate('e-mandate');
                  }}
                >
                  e-Mandate
                </div>
                <div
                  aria-label={type}
                  className={classNames(
                    'swtich-text',
                    {
                      ['active-switch']: type === 'signed-mandate',
                    },
                    { ['disabled bg-transparent']: bankDestination },
                  )}
                  onClick={() => {
                    bankDestination ? null : handleSelectMandate('signed-mandate');
                  }}
                >
                  Signed mandate
                </div>
              </div>

              {type === 'e-mandate' ? (
                <>
                  <div className="activate-card activate-card-overview mt-3">
                    <OverviewCard minHeight={0}>
                      <div className="p-2 track__card">
                        <h4 className="text-start">Instructions</h4>

                        <p className="space-y-2 text-start text-gray  px-0 activate-card-overview m-0 text-sm">
                          Kindly authorise this mandate by transferring ₦50.00 from your{' '}
                          <b>
                            {singleBalance?.bankName} {singleBalance?.number}
                          </b>{' '}
                          to the bank account that will be provided below.
                        </p>
                      </div>
                    </OverviewCard>
                  </div>

                  {bankDestination && (
                    <div className="form-normal mt-3">
                      <div className="preview-card__transfer mb-3">
                        <div className="px-4 pt-1 pb-2 w-100">
                          <LabeledInfo
                            label="Bank name"
                            value={bankDestination?.bank_name}
                          />
                          <LabeledInfo
                            label="Account number"
                            value={bankDestination?.account_number}
                          />
                          <LabeledInfo className="mt-1" label="Amount" value="₦50.00" />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="activate-card activate-card-overview mt-3">
                    <OverviewCard minHeight={0}>
                      <div className="p-2 track__card">
                        <h4 className="text-start">Instructions</h4>
                        <p className="text-gray text-sm my-2 text-start">
                          For enhanced security and to verify your request, we need your
                          digital signature.
                        </p>
                        <ul className="space-y-2 text-gray activate-card px-0 activate-card-overview m-0 text-sm">
                          <li>
                            <span className="fw-medium">Prepare your signature:</span> If
                            you haven’t already, please prepare a digital version of your
                            signature. This can be done by signing on a blank piece of
                            paper and taking a clear photo of it, or by using a digital
                            drawing tool.
                          </li>
                          <li>
                            <span className="fw-medium">Upload your signature: </span>{' '}
                            Click the {`"Upload"`} button below to select your signature
                            file from your device. We accept images in JPEG, PNG, or PDF
                            formats.
                          </li>
                          <li>
                            <span className="fw-medium">Confirm upload: </span>
                            After selecting your file, click the {`"Confirm" `} button to
                            upload your signature.
                          </li>
                        </ul>
                      </div>
                    </OverviewCard>
                  </div>

                  <div>
                    <div>
                      <FileUpload
                        supportType="JPEG, PNG formats, up to 3 MB."
                        cloud={false}
                        onChange={({ file, fileName }) => readUploadFile(file, fileName)}
                        removeFile={removeFile}
                        label="Upload signature *"
                        setRemoveFile={setRemoveFile}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="mt-4 d-flex w-100 gap-3">
                {type === 'e-mandate' ? null : (
                  <CustomButton
                    fullWidth
                    disabled={loading}
                    className="border-btn"
                    onClick={handleOnClose}
                  >
                    Cancel
                  </CustomButton>
                )}

                {!bankDestination && type === 'e-mandate' ? null : (
                  <CustomButton
                    fullWidth
                    disabled={loading}
                    loading={loading}
                    className={cs('confirm-btn')}
                    onClick={handleCreate}
                  >
                    {`${type === 'e-mandate' ? `I've sent the money` : 'Confirm'}`}
                  </CustomButton>
                )}

                {type === 'e-mandate' && !bankDestination && (
                  <CustomButton
                    fullWidth
                    disabled={loading || getMandateLoading}
                    loading={loading || getMandateLoading}
                    className={cs('confirm-btn')}
                    onClick={emandateRequest}
                  >
                    Generate account number
                  </CustomButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MandateDialog;
