import classNames from 'classnames';
import { useSelector } from 'react-redux';
import './styles.scss';
import IconWrap from 'components/UI/IconWrap';
import { useState } from 'react';
import { ArrowRight } from 'assets/icons';
import { useHistory } from 'react-router-dom';

const TodoItems = ({ menus, handleSelect }) => {
  const [activeBtn, setActiveBtn] = useState();
  const { push } = useHistory();
  const handleClick = (item) => {
    push(item.link);
  };
  const {
    getCompany: { loading, data: companyData = {} },
  } = useSelector(({ companies }) => companies);

  return (
    <div className="todo-wrapper">
      <div className="setup-title mb-4">
        <h1>Complete dashboard setup</h1>
        <p>
          Get ready to use Bujeti by verifying a few organisational details about your
          company
        </p>
      </div>
      <div className="todo-items">
        {menus.map((item, index) => {
          const { isComplete, icon, title, isActive, bntName, desc } = item;

          return (
            <div
              key={index}
              className={classNames(
                'd-flex align-items-center w-100 todo-list',
                index === menus.length - 1 ? '' : 'border-b',
              )}
            >
              <div className="d-flex align-items-center ">
                <div>
                  <IconWrap icon={icon} bg="#F5F5F4" />
                </div>

                <div className="list-title">
                  <h4 className={classNames('ms-3')}>{title}</h4>
                  {desc && <p className={classNames('ms-3')}>{desc}</p>}
                </div>
              </div>
              <div className="ms-auto">
                <button
                  className={classNames(
                    'btn btn-border',
                    { ['active-btn']: index === 0 },
                    {
                      // ['disabled opacity-50 not-allowed']: isDisabled,
                    },
                  )}
                  // onMouseOver={() => setActiveBtn(index)}
                  // onMouseLeave={() => setActiveBtn()}
                  onClick={() => handleClick(item)}
                >
                  {index === 0 ? (
                    <span className="gap-2 d-flex align-items-center">
                      Continue <ArrowRight color="#fff" />
                    </span>
                  ) : (
                    bntName
                  )}
                </button>
              </div>
            </div>
          );
        })}
      </div>
      {/* <Tod */}
    </div>
  );
};

export default TodoItems;
