import ImgCard from 'components/UI/ImgCard';
import React, { useMemo, useState } from 'react';
import './styles.scss';
import QuickActionBanner from './components/QuickActionBanner';
import {
  BankIcon,
  CoinsHand,
  CreditCardPlus,
  FileCheck,
  PieChart,
  SafeIcon,
  ShieldAlertIcon,
  UserPlus,
} from 'assets/icons';
import TodoItems from './components/TodoItems';
import ModalContainer from 'components/ModalContainer';
import { Modal, Row } from 'react-bootstrap';
import FileUpload from 'components/UI/FileUpload';

const Onboarding = () => {
  const [menus, setMenus] = useState([]);
  const [selectedItem, setSectedItem] = useState();
  const [documents, setDocuments] = useState({ incorporationCertificate: '' });
  const [uploadingFile, setUploadingFile] = useState(false);
  const [open, setOpen] = useState(false);

  useMemo(() => {
    setMenus([
      {
        bntName: 'Complete KYC',
        icon: <ShieldAlertIcon width="20" height="20" stroke="#79716B" />,
        title: 'Complete business KYC',
        desc: 'This will take less than 10 mins',
        withAction: false,
        isActive: true,
        isComplete: false,
        link: '/onboarding/business-kyc',
      },
      {
        bntName: 'Invite member',
        icon: <UserPlus color="#79716B" />,
        title: 'Invite team members',
        desc: 'Get your people set up with Bujeti',
        withAction: false,
        isActive: false,
        isComplete: false,
        link: '/people',
      },
      {
        bntName: 'Add funds',
        icon: <SafeIcon />,
        title: 'Deposit funds into your Bujeti account',
        desc: 'Add money to your account in just a few clicks',
        withAction: false,
        isActive: false,
        isComplete: false,
        link: '/account',
      },
      {
        bntName: 'Connect bank',
        icon: <BankIcon width="20" height="20" stroke="#79716B" />,
        title: 'Create or connect a bank account',
        // desc: '',
        withAction: false,
        isActive: false,
        isComplete: false,
        link: '/account',
      },
      {
        bntName: 'Create card',
        icon: <CreditCardPlus stroke="#79716B" />,
        title: 'Create a virtual card',
        // desc: '',
        withAction: false,
        isActive: false,
        isComplete: false,
        link: '/cards',
      },
    ]);
  }, []);

  const handleSelect = (title) => {
    setSectedItem(title);
  };

  return (
    <section className="onboarding-section">
      <div className="welcome-header">
        <ImgCard initials={'B'} fullWidth={false} />
        <h1>
          Welcome to Bujeti, <span>Berenger</span>
        </h1>
      </div>

      <QuickActionBanner onClick={() => setOpen(true)} />

      <TodoItems menus={menus} handleSelect={handleSelect} />

      <Modal show={open} centered dialogClassName="custom-dialog">
        <ModalContainer
          onCancel={() => setOpen(false)}
          title="Upload your certificate"
          subTitle="Upload your Corporate Affairs Commission (CAC) certificate of registration for basic access."
        >
          <Row className="mb-2">
            <FileUpload
              label="CAC Certificate of Incorporation *"
              supportType="JPEG, PNG, and PDF formats, up to 5 MB."
              uploadedFile={documents.incorporationCertificate}
              onChange={({ assetCode }) =>
                setDocuments({ ...documents, incorporationCertificate: assetCode })
              }
              setUploadingFile={setUploadingFile}
              uploadingFile={uploadingFile}
            />
          </Row>
        </ModalContainer>
      </Modal>
    </section>
  );
};

export default Onboarding;
