import { CloseOutlined } from '@ant-design/icons';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import Loading from 'components/UI/Loading';
import Modal from 'components/UI/Modal';
import Success from 'components/UI/ModalSaving/Success';

import { toastError } from 'components/UI/toast';
import { useDebounce } from 'hooks/useDebounce';
import { getAllBanks } from 'redux/actions/PaymentAction';
import {
  addSettlementAccount,
  verifyAccountAction,
  verifyAccountOtp,
} from 'redux/actions/SettlementAccount';
import { RESET_FLAGS_SETTLEMENT } from '../../../redux/reducers/SettlementAccount';

const StateContext = createContext();

const AddBankAccount = ({ isOpen, handleClose }) => {
  if (!isOpen) {
    return <div />;
  }

  const [active, setActive] = useState('create');
  const [accountData, setAccountData] = useState({});
  const [otp, setOtp] = useState(null);
  const dispatch = useDispatch();
  const debounceAccountNumber = useDebounce(accountData?.accountNumber);
  const debounceBank = useDebounce(accountData?.bank?.value);

  const {
    createSettlementAccount: { loading, success, data },
    verifySettlementAccountOtp: { loading: isVerifyingOtp, success: isOtpVerified },
    verifyAccount: {
      data: accountVerifiedData,
      loading: verifyingAccount,
      success: isVerified,
    },
  } = useSelector(({ settlementAccount }) => settlementAccount);

  useEffect(() => {
    dispatch(getAllBanks());
  }, []);

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setAccountData({ ...accountData, [name]: value });
  };

  const handleCloseModal = () => {
    setActive('create');
    handleClose();
    dispatch({ type: RESET_FLAGS_SETTLEMENT, blockType: 'createSettlementAccount' });
    dispatch({ type: RESET_FLAGS_SETTLEMENT, blockType: 'verifyAccount' });
  };

  const onVerify = () => {
    const payload = {
      hash: data?.hash,
      code: otp,
    };
    dispatch(verifyAccountOtp(payload));
  };

  useEffect(() => {
    if (!isVerifyingOtp && isOtpVerified) {
      setActive('success');
    }
  }, [isVerifyingOtp, isOtpVerified]);

  const onSubmit = () => {
    const { accountNumber, bank } = accountData;
    if (!accountNumber) return toastError('Please enter your bank account number');
    if (!bank) return toastError('Please select your bank');
    const { account_name = '', account_number = '' } = accountVerifiedData || {};
    const payload = {
      accountName: account_name,
      number: account_number,
      bankCode: accountData?.bank?.value,
    };
    dispatch(addSettlementAccount(payload));
  };

  useEffect(() => {
    if (debounceAccountNumber?.length >= 9 && debounceBank) {
      const payload = {
        accountNumber: accountData?.accountNumber,
        bankCode: accountData?.bank?.value,
      };
      dispatch(verifyAccountAction(payload));
    }
  }, [debounceAccountNumber, debounceBank]);

  useEffect(() => {
    if (!loading && success) {
      setActive('verify');
    }
  }, [loading, success]);

  const ModalComponent = (state) => {
    switch (state) {
      case 'create':
        return <CreateAccountForm />;
      case 'verify':
        return <VerifyOTP />;
      case 'success':
        return (
          <Success title="Thank you!" message="Your settlement account has been added" />
        );
      default:
        return <CreateAccountForm />;
    }
  };

  return (
    <section>
      <StateContext.Provider
        value={{
          accountData,
          setAccountData,
          active,
          setActive,
          onHandleChange,
          otp,
          setOtp,
          onVerify,
          onSubmit,
          verifyingAccount,
          accountVerifiedData,
          loading,
          isVerifyingOtp,
          data,
          isVerified,
        }}
      >
        <Modal show={isOpen} onClose={handleCloseModal}>
          <div className="content">
            <div className="card-modal-header">
              <div
                className="d-flex align-items-center cursor"
                onClick={handleCloseModal}
              >
                <CloseOutlined />
                <span className="ps-1">Close</span>
              </div>
            </div>
            <div className="card-modal-body">{ModalComponent(active)}</div>
          </div>
        </Modal>
      </StateContext.Provider>
    </section>
  );
};

export default AddBankAccount;

const CreateAccountForm = () => {
  const {
    accountData,
    setAccountData,
    onHandleChange,
    onSubmit,
    verifyingAccount,
    accountVerifiedData,
    loading,
    isVerified,
  } = useContext(StateContext);

  const dispatch = useDispatch();

  const {
    getAllBanks: {
      data: banksData,
      loading: loadingBanks,
      success: successBanks,
      error: errorBanks,
    },
  } = useSelector(({ payments }) => payments);
  // Get All banks starts here

  const [bankValue, setBankValue] = useState('');
  const bankValuedebounced = useDebounce(bankValue, 200);

  const handleGetBankOnChange = (val) => setBankValue(val);

  const mappedBanks = banksData?.map((item) => item.name);

  const allBanks = useMemo(() => {
    return banksData?.map((item) => ({
      label: item.label,
      value: item.bankCode,
    }));
  }, [successBanks, errorBanks, mappedBanks]);

  const onMenuCloseBanks = () => {
    if (bankValuedebounced) dispatch(getAllBanks());
  };

  useEffect(() => {
    const banks = allBanks?.find((option) =>
      option?.label?.toLowerCase().includes(bankValuedebounced?.toLowerCase()),
    );
    if (!banks && bankValuedebounced) {
      dispatch(getAllBanks({ search: bankValuedebounced?.toLowerCase() }));
    }
  }, [bankValuedebounced]);

  return (
    <div className="information-wrapper">
      <h1 className="title text-black">Add bank Account</h1>
      <div className="mt-4">
        <Row className="mb-3">
          <CustomInput
            type="text"
            label="Account number *"
            placeholder="Enter number"
            name="accountNumber"
            onChange={onHandleChange}
            value={accountData.accountNumber}
            maxLength="10"
            pattern="[0-9]*"
          />
        </Row>
        <Row>
          <div>
            <CustomSelect
              label="Select Bank *"
              name="bankName"
              placeholder="Select Bank"
              onChange={(val) => setAccountData({ ...accountData, bank: val })}
              value={accountData?.bank}
              options={allBanks}
              onMenuClose={onMenuCloseBanks}
              onInputChange={handleGetBankOnChange}
              isDisabled={loadingBanks && !bankValuedebounced}
              isLoading={loadingBanks && !bankValuedebounced}
            />
            {verifyingAccount && <Loading color="#D28B28" size="18" />}
            {!verifyingAccount && isVerified && accountVerifiedData && (
              <span className="verify-account-wrapper">
                {accountVerifiedData?.account_name}
              </span>
            )}
          </div>
        </Row>
      </div>

      <div className="modal-footer mt-3">
        <CustomButton
          fullWidth={true}
          className="custom-button primary-button"
          onClick={onSubmit}
          disabled={loading}
          loading={loading}
        >
          Add Bank Account
        </CustomButton>
      </div>
    </div>
  );
};

const VerifyOTP = () => {
  const { otp, setOtp, onVerify, isVerifyingOtp, data } = useContext(StateContext);
  const otpEmail =
    data?.name === 'you' ? 'Your email address' : `${data?.name}'s email address`;

  const handleChange = (event) => {
    setOtp(event.target.value.replace(/\W+/g, ''));
  };
  return (
    <div className="information-wrapper">
      <h1 className="title">Verify Account</h1>
      <span className="subTitle">
        Enter the OTP sent to <span className="otp-email">{otpEmail}</span>.
      </span>
      <section className="mt-4">
        <Row className="mb-3">
          <CustomInput
            type="text"
            label="Enter OTP"
            placeholder="012345"
            name="otp"
            value={otp === null ? '' : otp}
            onChange={handleChange}
          />
        </Row>
      </section>

      <div className="modal-footer mt-3">
        <CustomButton
          fullWidth={true}
          className="custom-button primary-button"
          onClick={onVerify}
          disabled={isVerifyingOtp}
          loading={isVerifyingOtp}
        >
          Add Bank Account
        </CustomButton>
      </div>
    </div>
  );
};
