import {
  ArrowRight,
  BankNoteO1Icon,
  CalendarPlusIcon,
  LayersTwoIcon,
} from 'assets/icons';
import TopBar from 'components/TopBar';
import CustomButton from 'components/UI/CustomButton';
import CustomPopover from 'components/UI/Popover';
import 'jspdf-autotable';
import ApprovalRules from 'pages/Approvals/ApprovalRules';
import PendingApprovals from 'pages/Approvals/PendingApprovals';
import { useEffect, useState } from 'react';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { fetchPendingApprovals } from 'redux/actions/ApprovalAction';
import { toggleAction } from 'redux/actions/ToggleAction';
import { SELECTED_TABLE_ROWS } from 'redux/reducers/TableReducer';
import AllowedTo, { allPermissions, hasPermission } from 'utils/AllowedTo';
import './style.less';

const Compliances = () => {
  const dispatch = useDispatch();
  const { permissions, isAdmin } = allPermissions();
  const { transactionCode, requestCode, code } = useParams();

  const location = useLocation();
  const locationArray = location.pathname.split('/');
  const history = useHistory();

  const [key, setKey] = useState(locationArray[locationArray.length - 1]);

  const canEditTransaction = hasPermission({
    permissions,
    scopes: ['transaction-*', 'transaction-edit'],
  });

  const {
    fetchPendingApprovals: { data: pendingApproval, meta: approvalsMeta },
    fetchApprovalRules: { data: { meta: approvalMeta } = {} },
  } = useSelector(({ approval }) => approval);

  const {
    getTransaction: { data: { meta = {} } = {} },
    getScheduledTransaction: { data: { meta: scheduledMeta } = {} },
  } = useSelector(({ transaction }) => transaction);

  const {
    getBatchPayment: { data: Batchtransactions = [] },
  } = useSelector(({ payments }) => payments);

  const {
    selectedTableRows: { selectedRows, type },
  } = useSelector(({ table }) => table);

  const { total } = meta;

  useEffect(() => {
    const type = ['payments', 'requests'].includes(key) ? key : null;
    dispatch({
      type: SELECTED_TABLE_ROWS,
      payload: {
        selectedRows: [],
        type: type,
      },
    });
  }, [key]);

  const handleSelect = (tabKey) => {
    setKey(tabKey);
    if (tabKey != 'payments') {
      history.push(`/transactions/${tabKey}`);
    } else {
      history.push(`/transactions/payments`);
    }
  };

  useEffect(() => {
    if (requestCode) {
      setKey('requests');
    }
    if (code) {
      setKey('rules');
    }

    if (transactionCode) {
      setKey('payments');
    }
  }, [requestCode, code, transactionCode]);

  useEffect(() => {
    if (!pendingApproval?.length) dispatch(fetchPendingApprovals());
  }, []);

  const [isButtonToggle, setIsButtonToggle] = useState(false);

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);
  const isSchedule = () => {
    localStorage.setItem('schedule', 'on');
  };

  const handleRowSelect = (rowData, type) => {
    dispatch({
      type: SELECTED_TABLE_ROWS,
      payload: { selectedRows: rowData, type },
    });
  };

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() => {
            dispatch(toggleAction());
            handleButtonToggle();
          }}
        >
          <BankNoteO1Icon /> Single payment
        </div>
        <AllowedTo
          scopes={[
            'batch-transaction-*',
            'batch-transaction-view',
            'batch-transaction-create',
          ]}
        >
          <div
            className="actionLink"
            onClick={() => {
              history.push('/transactions/batch-payment');
            }}
          >
            <LayersTwoIcon /> Batch payments
          </div>
        </AllowedTo>
        <div
          className="actionLink"
          onClick={() => {
            dispatch(toggleAction());
            handleButtonToggle();
            isSchedule();
          }}
        >
          <CalendarPlusIcon /> Schedule payment
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!isAdmin && locationArray.includes('rules')) {
      history.push('/404');
    }
  }, []);

  return (
    <div
      className={`${
        selectedRows.length > 0 &&
        canEditTransaction &&
        key === 'payments' &&
        type === 'payments'
          ? 'transaction-page-grid'
          : ''
      }`}
    >
      <div className="position-relative">
        <TopBar
          headerText="Approval rules"
          subText="Enforce your companies expenses and accounting rules"
          isRightBar
          dropDownBtn={
            <AllowedTo scopes={['transaction-*', 'transaction-create']}>
              <CustomPopover
                zIndex="1"
                showPopover={isButtonToggle}
                clickOutside={handleButtonToggle}
                content={<Actions />}
              >
                <CustomButton className="add-button" onClick={handleButtonToggle}>
                  Send funds <ArrowRight color="#ffffff" className="ms-1" />
                </CustomButton>
              </CustomPopover>
            </AllowedTo>
          }
          addButton
        />
        <Container>
          <Row>
            <Col xs={12} className="mt-4">
              <ApprovalRules ruleCode={code} handleSelect={setKey} />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Compliances;
