import React from 'react';
import './style.scss';
import EmptyTaskImg from 'assets/images/emptystate/empty-task.png';

const EmptyTaskCard = ({ image = EmptyTaskImg, text = 'Well done, all caught up!' }) => {
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center w-100"
      style={{ marginTop: 90 }}
    >
      <div className="d-flex flex-column align-items-center empty-task-card">
        <img src={image} />
        <h4>{text}</h4>
      </div>
    </div>
  );
};

export default EmptyTaskCard;
