import Table from 'components/Table';
import 'jspdf-autotable';
import { useEffect, useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getTransaction } from 'redux/actions/TransactionsAction';
import { profileTransactionColumns } from 'utils/mockData';
import { buildProfileTransactionTableData } from '../../../utils/helper';
import NoData from './NoData';
import TableLoading from './TableLoading';

const TransactionTable = ({ id, type, userName, isCustomer = false }) => {
  const dispatch = useDispatch();

  const {
    getTransaction: { data: { meta = {}, transactions = [] } = {}, loading },
  } = useSelector(({ transaction }) => transaction);
  const { page, total, hasMore, perPage, nextPage } = meta;

  const rows = useMemo(
    () => buildProfileTransactionTableData(transactions),
    [transactions],
  );

  const handlePagination = (page) => {
    dispatch(getTransaction({ [type]: [id], perPage, page }));
  };

  const tableColumn = useMemo(
    () =>
      profileTransactionColumns.filter((option) => {
        if (type === 'vendor') {
          return option.accessor !== 'to';
        } else if (type === 'payer') {
          return option.accessor !== 'by';
        }
      }),
    [transactions, type],
  );

  useEffect(() => {
    if (!isCustomer) dispatch(getTransaction({ [type]: [id] }));
  }, []);

  // if (loading) return <Loading size={45} className={'loading-state'} color="#D28B28" />;

  return (
    <>
      {!loading && !transactions.length ? (
        <div className="tabinnerWrapper">
          <NoData
            headerText="No transaction"
            bodyText={`${userName} does not have any transaction yet`}
            withButton={true}
            buttonLabel={
              <span className="d-flex justify-content-center">Show all Invoices</span>
            }
            // onClickHandler={clearLocationState}
          />
        </div>
      ) : (
        <Container className="px-0">
          {loading ? (
            <TableLoading />
          ) : (
            <Row className="py-4">
              <Col xs={12} className="spaced-table">
                <Table
                  columns={tableColumn}
                  data={rows}
                  pagination
                  hasMore={hasMore}
                  hasCheckBox={false}
                  currentPage={page}
                  nextPage={() => handlePagination(nextPage)}
                  previousPage={() => handlePagination(page - 1)}
                  totalPage={Math.ceil(total / perPage)}
                />
              </Col>
            </Row>
          )}
        </Container>
      )}
    </>
  );
};

export default TransactionTable;
