import Table from 'components/Table';
import 'jspdf-autotable';
import { useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { buildSubAccountMembersTableData } from 'utils/helper';
import { columnsSubAccountMembers } from 'utils/mockData';
// import NoData from './NoData';
import { PlusOutlined } from '@ant-design/icons';
import { RolesType, SubAccountRoleType } from 'components/FilterModal/FilterHelper';
import TopBar from 'components/TopBar';
import Loading from 'components/UI/Loading';
import { useDebounce } from 'hooks/useDebounce';
import useIsFiltered from 'hooks/useIsFiltered';
import NoData from 'pages/Cash/Overview/components/NoData';
import {
  getSubAccountMembers,
  removeSubAccountMember,
} from 'redux/actions/BudgetsAction';
import AddMembersModal from './AddMembersModal';
import DeleteDialog from 'components/DeleteDialog';
import { RESET_BLOCK_BUDGET } from 'redux/reducers/BudgetsReducer';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
// import TableLoading from './TableLoading';

const MemberTable = ({ subAccountCode, permissionForOwnersAndManagers, designation }) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [filterData, setFilterData] = useState([...SubAccountRoleType]);
  const [search, setSearch] = useState('');
  const debouncedValue = useDebounce(search, 600);
  const { filtered, isFiltered, filteredQuery, setFilteredQuery } = useIsFiltered();
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const {
    getSubAccountMembers: { data: { meta = {}, members = [] } = {}, loading },
    removeSubAccountMember: { loading: isDeleteLoading, success: isDeleteSuccess },
  } = useSelector(({ budgets }) => budgets);
  const {
    user: { data: userData },
  } = useSelector(({ auth }) => auth);
  const { page, total, hasMore, perPage, nextPage } = meta;

  const rows = useMemo(() => buildSubAccountMembersTableData(members), [members]);

  const { isAdmin, permissions } = allPermissions();

  const canViewTransaction = hasPermission({
    permissions,
    scopes: ['transaction-*', 'transaction-view'],
  });

  const handlePagination = (page) => {
    console.log(page);
    // dispatch(getTransaction({ source, perPage, page }));
  };

  const toggleModalHandler = () => {
    setIsOpen(!isOpen);
  };

  const tableColumn = useMemo(
    () =>
      columnsSubAccountMembers.filter((item) =>
        !['manager', 'member'].includes(designation) ? item : item.accessor !== 'modify',
      ),
    [members],
  );

  const show = !!members?.length || (filtered && !members?.length);

  const handleFilterApply = (query) => {
    dispatch(getSubAccountMembers({ code: subAccountCode, params: { ...query } }));
    setFilteredQuery({ ...query });
    isFiltered.current = !!Object.keys(query).length;
  };

  const clearFilters = () => {
    setFilteredQuery({});
    setFilterData([...SubAccountRoleType]);
  };

  const handleDelete = () => {
    dispatch(
      removeSubAccountMember({ code: subAccountCode, memberCode: selectedRow.code }),
    );
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      setDeleteModal(false);
      dispatch(
        getSubAccountMembers({ code: subAccountCode, params: { ...filteredQuery } }),
      );
      dispatch({ type: RESET_BLOCK_BUDGET, blockType: 'removeSubAccountMember' });
    }
  }, [isDeleteSuccess]);

  useEffect(() => {
    if (debouncedValue && canViewTransaction) {
      filteredQuery.search = debouncedValue;
      dispatch(
        getSubAccountMembers({
          code: subAccountCode,
          params: { ...filteredQuery, search: debouncedValue },
        }),
      );
    }
    if (!debouncedValue && filtered && canViewTransaction) {
      delete filteredQuery.search;
      dispatch(
        getSubAccountMembers({ code: subAccountCode, params: { ...filteredQuery } }),
      );
      // history.replace({ state: {} });
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  // if (loading) return <Loading size={45} className={'loading-state'} color="#D28B28" />;

  if (loading && !filtered) return <Loading isPage color="#D28B28" />;

  return (
    <>
      <TopBar
        addOnClick={designation === 'owner' || isAdmin ? toggleModalHandler : null}
        searchVal={search}
        setSearchVal={setSearch}
        filterData={filterData}
        withOutSearch
        showBarSearch={show}
        inputPlaceholder="Search"
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        handleFilterApply={handleFilterApply}
        showFilter={show}
        addIcon={designation === 'owner' || isAdmin ? show : null}
        clearFilters={clearFilters}
        customAddButton={
          <button onClick={toggleModalHandler} className="add-button add-action">
            <PlusOutlined
              style={{
                verticalAlign: 0,
                fontSize: 14,
                paddingLeft: 5,
              }}
            />
            <span className="ms-1">Add members</span>
          </button>
        }
      />
      {!members.length ? (
        <div className="tabinnerWrapper">
          <NoData
            headerText="Your members will show here."
            buttonLabel="Add members"
            bodyText="Find here all your members, you can add members with different role on this subaccount."
            onClickHandler={permissionForOwnersAndManagers ? toggleModalHandler : null}
            withButton={permissionForOwnersAndManagers ? true : false}
          />
        </div>
      ) : (
        <Container className="px-0">
          <Row className="py-4">
            <Col xs={12} className="spaced-table">
              <Table
                columns={tableColumn}
                data={rows}
                pagination
                hasMore={hasMore}
                hasCheckBox={false}
                currentPage={page}
                nextPage={() => handlePagination(nextPage)}
                previousPage={() => handlePagination(page - 1)}
                totalPage={Math.ceil(total / perPage)}
                customClass={(row) => {
                  const {
                    memberData: { user, designation: subAccountRole },
                  } = row;
                  if (
                    userData?.user?.code === user?.code ||
                    subAccountRole?.toLowerCase() === 'owner'
                  ) {
                    return 'table-action-btn inactive';
                  } else {
                    return 'table-action-btn';
                  }
                }}
                onDeleteClick={(row) => {
                  const {
                    memberData: { user, designation: subAccountRole },
                  } = row;
                  if (
                    userData?.user?.code === user?.code ||
                    subAccountRole?.toLowerCase() === 'owner'
                  ) {
                    return;
                  } else {
                    setSelectedRow(row.memberData);
                    setDeleteModal(true);
                  }
                }}
              />
            </Col>
          </Row>
        </Container>
      )}

      <AddMembersModal
        isModalOpen={isOpen}
        closeHandler={toggleModalHandler}
        subAccountCode={subAccountCode}
      />

      {deleteModal && (
        <DeleteDialog
          title={`Remove Member`}
          subTitle={`Are you sure you want to remove this member?`}
          onCancel={() => {
            setDeleteModal(false);
          }}
          onDelete={handleDelete}
          deleteText={`Remove`}
          isLoading={isDeleteLoading}
        />
      )}
    </>
  );
};

export default MemberTable;
