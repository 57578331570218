import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { BankIcon, WalletIcon } from 'assets/icons';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import { useDispatch, useSelector } from 'react-redux';
import { getBalances } from 'redux/actions/BudgetsAction';
import { getCountries, getState } from 'redux/actions/CompaniesAction';
import { getAvailableBalance, groupSourceOptions } from 'utils/helper';

const options = [
  {
    id: 1,
    name: 'My Bujeti Balance',
    icons: WalletIcon,
    type: 'balance',
  },
  {
    id: 2,
    name: 'Bank Account',
    icons: BankIcon,
    type: 'directdebit',
  },
  // {
  //   id: 3,
  //   name: 'Credit or Debit Card',
  //   icons: [VisaIcon, MastercardIcon, AmexIcon, DinnersClubIcon, DiscoverIcon],
  //   type: 'card',
  // },
];

const BillingAddress = ({ getSelectedOption, info, billing, setBilling }) => {
  const [active, setActive] = useState('balance');

  const dispatch = useDispatch();

  const {
    getCountry: { data: countryData = {} },
    getState: { data: states = {}, success: successState, loading: loadingState },
  } = useSelector(({ companies }) => companies);

  const {
    getBalances: { data: balances, loading: loadingBalances },
  } = useSelector(({ budgets }) => budgets);

  useEffect(() => {
    if (!countryData?.length) dispatch(getCountries());
  }, []);

  const generateCountry = useMemo(() => {
    if (countryData?.length > 0) {
      return countryData?.map(({ code: value, name: label }) => ({
        value,
        label,
        isDisabled: label !== 'NIGERIA' ? true : false,
      }));
    }
  }, [countryData]);

  const generateState = useMemo(() => {
    return states.states?.map((value) => ({
      label: value,
      value: value,
    }));
  }, [successState]);

  const getCountryStates = async (val) => {
    dispatch(getState(val.value));
  };

  // dispatch state is value change
  useEffect(() => {
    if (billing?.country?.value && countryData.length > 0) {
      const country = countryData?.find((item) =>
        item.alpha.includes(billing?.country?.value),
      );

      if (country?.code) dispatch(getState(country?.code));
    }
  }, [countryData, billing]);

  // dispatch state if data found
  useEffect(() => {
    if (info?.address?.countryIso && countryData.length > 0) {
      const country = countryData?.find((item) =>
        item.alpha.includes(info?.address?.countryIso),
      );
      if (country?.code) dispatch(getState(country?.code));
    }
  }, [countryData]);

  const onHandleClick = (type) => {
    if (type === active) return;
    setActive(type);
    getSelectedOption(type);
    setBilling({
      ...billing,
      balance: '',
      method: type,
    });
  };

  const handleChange = ({ name, value }) => {
    setBilling({
      ...billing,
      [name]: value,
    });
  };

  useEffect(() => {
    if (info) {
      const { firstName, lastName, email, company } = info;
      setBilling({
        ...billing,
        firstName,
        email,
        lastName,
        companyName: company.name,
        country: info?.address?.country
          ? {
              value: info?.address?.countryIso,
              label: info?.address?.country,
            }
          : '',
        address: info?.address?.street || '',
        state: info?.address?.state
          ? {
              value: info?.address?.state,
              label: info?.address?.state,
            }
          : '',
        city: info?.address?.city || '',
      });
    }
  }, [info]);

  useEffect(() => {
    dispatch(getBalances());
  }, []);

  const [loadPage, setLoadPage] = useState(0);

  useEffect(() => {
    if (loadPage > 1) dispatch(getBalances({ page: loadPage }));
  }, [loadPage]);

  const [hasMore, setHasMore] = useState(false);
  const [budgetList, setBudgetList] = useState([]);

  useEffect(() => {
    if (balances?.budgets?.length || balances?.balances?.length) {
      const budget = getAvailableBalance(balances?.budgets);
      const available_balance = getAvailableBalance(balances?.balances);
      setBudgetList((prevOptions) => [...budget, ...available_balance]);
    }
  }, [balances?.budgets]);

  async function loadOptions(search, loadedOptions, { page }) {
    setLoadPage(page);

    let filteredOption = budgetList.filter((item) =>
      item?.name?.toLowerCase().includes(search?.toLowerCase()),
    );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  async function loadAcctOptions(search, loadedOptions, { page }) {
    setLoadPage(page);

    let filteredOption = budgetList.filter(
      (item) =>
        item?.name?.toLowerCase().includes(search?.toLowerCase()) &&
        item?.value?.startsWith('bnk_'),
    );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  //

  return (
    <section className="billing-address">
      <section className="payment-options">
        <h1 className="payment-title">Your payment method</h1>
        {options.map((option) => {
          const Icon = option.icons;
          return (
            <div
              key={option.id}
              className={`payment-item ${option.type == active && 'active'} d-flex gap-3`}
              onClick={() => onHandleClick(option.type)}
            >
              <div
                className={`radio ${option.type === active ? 'checked' : 'unchecked'}`}
              >
                {active && (
                  <svg
                    width="10"
                    height="7"
                    viewBox="0 0 10 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.33317 1.5L3.74984 6.08333L1.6665 4"
                      stroke="white"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </div>
              <div className="w-100">
                <h6>{option.name}</h6>
                <div className="d-flex gap-2">
                  <span className="icon-wrapper">
                    <Icon />
                  </span>
                </div>
                {option.type == active && (
                  <div className="row mt-2">
                    <div className="col-lg-6">
                      <CustomSelectRadio
                        label="From"
                        placeholderText="Select a source"
                        name="from"
                        onChange={(val) => handleChange({ name: 'balance', value: val })}
                        value={billing.balance}
                        isLoading={loadingBalances}
                        loadOptions={
                          option.type === 'balance' ? loadOptions : loadAcctOptions
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </section>
      <section className="mt-4">
        <h1 className="payment-title">Billing address</h1>
        <section>
          <div className="row">
            <div className="col-sm-6 col-lg-6">
              <CustomInput
                label="First name"
                value={billing?.firstName}
                onChange={(value) =>
                  handleChange({ name: 'firstName', value: value.target.value })
                }
              />
            </div>
            <div className="col-sm-6 col-lg-6">
              <CustomInput
                label="Last name"
                value={billing?.lastName}
                onChange={(value) =>
                  handleChange({ name: 'lastName', value: value.target.value })
                }
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-sm-6 col-lg-6">
              <CustomInput
                label="Contact email"
                value={billing?.email}
                onChange={(value) =>
                  handleChange({ name: 'email', value: value.target.value })
                }
              />
            </div>
            <div className="col-sm-6 col-lg-6">
              <CustomInput
                label="Company name (optional)"
                value={billing?.companyName}
                onChange={(value) =>
                  handleChange({ name: 'companyName', value: value.target.value })
                }
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-sm-6 col-lg-6">
              <CustomSelect
                placeholder="Select a country"
                type="text"
                name="country"
                value={billing.country}
                label="Country *"
                options={generateCountry}
                onChange={(value) => {
                  handleChange({ name: 'country', value: value });
                  getCountryStates(value);
                }}
              />
            </div>
            <div className="col-sm-6 col-lg-6">
              <CustomInput
                label="Address"
                value={billing?.address}
                onChange={(value) =>
                  handleChange({ name: 'address', value: value.target.value })
                }
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-sm-6 col-lg-6">
              <CustomSelect
                placeholder="Select a state"
                type="text"
                name="state"
                value={billing.state}
                label="State *"
                isDisabled={loadingState}
                options={generateState}
                onChange={(value) => handleChange({ name: 'state', value: value })}
              />
            </div>
            <div className="col-sm-6 col-lg-6">
              <CustomInput
                label="City"
                onChange={(value) =>
                  handleChange({ name: 'city', value: value.target.value })
                }
                value={billing?.city}
              />
            </div>
          </div>
        </section>
      </section>
      <section className="mt-4">
        <p className="billing-footer-text">
          Your subscription will automatically renew every month using your default
          payment method. After your first month, you will be charged ₦50,000, plus
          applicable taxes, on each renewal until you cancel your subscription. You can
          cancel at any time on the <Link to="/settings/billings">Billing page </Link>of
          your account. If you cancel, previous charges will not be refunded, but you may
          continue to use the service until the end of the term you paid for.
        </p>
      </section>
    </section>
  );
};

export default BillingAddress;
