import React, { useEffect, useState } from 'react';
import { ReactComponent as AlertTrangle } from '../../assets/icons/alert-triangle.svg';
import { Container } from 'react-bootstrap';
import BudgetModal from './BudgetModal';
import { useSelector } from 'react-redux';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
const MigrateOldBudgetInfo = () => {
  const [step, setStep] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isAdvance, setIsAdvance] = useState(false);
  const [isFunding, setIsFunding] = useState(false);

  const [showOnboarding, setShowOnboarding] = useState(false);

  const {
    getSingleBudget: { data },
  } = useSelector(({ budgets }) => budgets);

  const { permissions } = allPermissions();
  const canManage = hasPermission({
    permissions,
    scopes: ['budget-*', 'budget-create', 'budget-edit'],
  });

  useEffect(() => {
    if (canManage && data?.status !== 'closed')
      setShowOnboarding(!data?.isNewBudget || !data?.available);
  }, [canManage]);

  const toggleHandler = () => {
    setStep(1);
    setIsOpen(!isOpen);
  };

  const fundHandler = () => {
    setStep(4);
    setIsOpen(!isOpen);
    setIsFunding(!isFunding);
  };

  return (
    <>
      {showOnboarding && (
        <Container className="px-0 mt-4 mb-3">
          <div className="budget__notice">
            <div className="alert-triangle-container">
              <AlertTrangle />
            </div>
            <div>
              <h4>Add funds to this budget</h4>
              <p>
                This budget is not funded yet, you will need to fund this budget to make
                payments. <br />
                Click on the button below to fund your budget.
              </p>
              <div className="add-button add-action bg-white" onClick={fundHandler}>
                Fund budget
              </div>
            </div>
            {/* <CloseOutlined className="ms-auto cursor" onClick={removeStatus} /> */}
          </div>
        </Container>
      )}

      <BudgetModal
        step={step}
        setStep={setStep}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        toggleHandler={toggleHandler}
        isUpdate
        setIsAdvance={setIsAdvance}
        isAdvance={isAdvance}
        isFunding={isFunding}
        setIsFunding={setIsFunding}
      />
    </>
  );
};

export default MigrateOldBudgetInfo;
