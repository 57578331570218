import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomRoleSelect from 'components/UI/CustomRoleSelect';
import { toastError } from 'components/UI/toast';
import { useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createBeneficiaries, getBeneficiaries } from 'redux/actions/BeneficiariesAction';
import '../styles.scss';

const Beneficiaries = ({ setStep, toggleHandler, hideContinue }) => {
  const initial = {
    name: '',
    lastName: '',
    role: { value: 'admin', label: 'Admin' },
    email: '',
    manager: { value: null, label: 'Select a manager' },
  };
  const dispatch = useDispatch();

  const {
    createBeneficiaries: { success, loading },
    getBeneficiaries: { data = {} },
  } = useSelector(({ beneficiaries }) => beneficiaries);
  const { getRoles: { data: roles, success: roleSuccess } = {} } = useSelector(
    ({ roles }) => roles,
  );
  const [getLocalStorage, setGetLocalStorage] = useState();
  const [beneficiaries, setBeneficiaries] = useState(initial);
  const [beneficiariesList, setBeneficiariesList] = useState([]);
  const [beneficiaryAdded, setbeneficiaryAdded] = useState(false);
  const [isContinue, setIsContinue] = useState(false);

  const handleChange = ({ target: { name, value } }) => {
    setBeneficiaries({ ...beneficiaries, [name]: value });
  };

  const handleSubmit = () => {
    if (
      !beneficiaries.name &&
      !beneficiaries.lastName &&
      !beneficiaries.email &&
      (beneficiaryAdded || data?.beneficiaries.length > 0)
    ) {
      localStorage.setItem('setStep', JSON.stringify(2));
      setStep(3);
    }

    if (beneficiaries.name || beneficiaries.lastName || beneficiaries.email) {
      const getStatus = handleAdd();
      if (getStatus === 'true') {
        setIsContinue(true);
        return;
      }
      return;
    }
  };

  useEffect(() => {
    if (data?.beneficiaries?.length > 0) {
      const listData = data?.beneficiaries.map((b) => {
        const { user = {} } = b;
        return {
          name: user.firstName,
          lastName: user.lastName,
          role: { value: user?.role?.name, label: user?.role?.name },
          email: user.email,
        };
      });
      setBeneficiariesList(listData);
    }
  }, [data?.beneficiaries]);

  const onSkipHandler = () => {
    localStorage.setItem('setStep', JSON.stringify(2));
    // toggleHandler();
    setStep(3);
  };

  useEffect(() => {
    setStep(2);
    localStorage.setItem('setStep', JSON.stringify(1));
  }, []);

  const handleAdd = () => {
    if (!beneficiaries.name) return toastError('Enter first name');
    if (!beneficiaries.lastName) return toastError('Enter last name');
    if (!beneficiaries.email) return toastError('Enter email');
    const payload = {
      firstName: beneficiaries.name,
      lastName: beneficiaries.lastName,
      role: beneficiaries.role.code,
      budgets: [],
      email: beneficiaries.email,
      manager: beneficiaries.manager.code,
    };
    dispatch(createBeneficiaries(payload));
    setbeneficiaryAdded(true);
    return 'true';
  };

  useEffect(() => {
    if (getLocalStorage) {
      setGetLocalStorage({
        ...getLocalStorage,
        addBeneficiaries: {
          ...getLocalStorage.addBeneficiaries,
          beneficiaries: {
            ...beneficiaries,
          },
        },
      });
    }
  }, [beneficiaries, beneficiariesList]);

  useEffect(() => {
    if (success) {
      setBeneficiariesList((previous) => [...previous, beneficiaries]);
      setGetLocalStorage({
        ...getLocalStorage,
        beneficiariesList: [...beneficiariesList],
      });
      setBeneficiaries(initial);

      if (isContinue) {
        window.setTimeout(function () {
          localStorage.setItem('setStep', JSON.stringify(2));
          setStep(3);
        }, 100);
      }
      dispatch(getBeneficiaries());
    }
  }, [success, isContinue]);

  useEffect(() => {
    if (getLocalStorage) {
      localStorage.setItem('overviewInfo', JSON.stringify(getLocalStorage));
    }
  }, [getLocalStorage]);

  useEffect(() => {
    const getLocalData = JSON.parse(localStorage.getItem('overviewInfo'));
    dispatch(getBeneficiaries());
    setGetLocalStorage(getLocalData);
    if (getLocalData) {
      if (Object.keys(getLocalData.addBeneficiaries).length > 0) {
        if (Object.keys(getLocalData.addBeneficiaries.beneficiaries).length > 0)
          setBeneficiaries(getLocalData.addBeneficiaries.beneficiaries);
        if (getLocalData.addBeneficiaries.beneficiariesList?.length > 0)
          setBeneficiariesList(getLocalData.addBeneficiaries.beneficiariesList);
      }
    }
  }, []);

  const generateRoles = useMemo(() => {
    return roles?.map((datum) => {
      return {
        label: datum?.name,
        value:
          datum?.permissions
            ?.map((permission) => permission.description.split('-')[0])
            .join(', ')
            .slice(0, 150) + '...',
        code: datum?.code,
      };
    });
  }, [roleSuccess]);

  const generateOtherUsers = useMemo(() => {
    const { beneficiaries = [] } = data;
    return beneficiaries.map(({ user: { code, firstName, lastName, role } }) => ({
      value: `${role?.name}`,
      code,
      label: `${firstName} ${lastName}`,
    }));
  }, [data]);

  const UserView = ({ data }) => {
    return (
      <div className="user-view-wrapper">
        <div className="d-flex align-items-center">
          <span className="userIcon">{data.name.charAt(0)}</span>
          <span className="userName">{data.name}</span>
        </div>
        <span className="useRole">{data.role.value}</span>
      </div>
    );
  };

  return (
    <div className="beneficiaries">
      <h2 className="card-title w-100">Add members</h2>

      <Row className="mb-3 mt-3">
        <CustomInput
          label="First name"
          placeholder="Enter first name"
          type="text"
          name="name"
          onChange={handleChange}
          value={beneficiaries.name}
        />
      </Row>
      <Row className="mb-3 mt-3">
        <CustomInput
          label="Last name"
          placeholder="Enter last name"
          type="text"
          name="lastName"
          onChange={handleChange}
          value={beneficiaries.lastName}
        />
      </Row>

      <Row className="mb-3">
        <CustomInput
          label="Email"
          placeholder="Enter email"
          type="email"
          name="email"
          onChange={handleChange}
          value={beneficiaries.email}
        />
      </Row>
      <Row className="mb-3">
        <CustomRoleSelect
          label="Role *"
          options={generateRoles}
          placeholder="Member Roles"
          name="role"
          onChange={(val) => {
            setBeneficiaries({ ...beneficiaries, role: val });
          }}
          value={beneficiaries.role}
        />
      </Row>
      <Row className="mb-3">
        <CustomRoleSelect
          label="Manager"
          options={generateOtherUsers}
          placeholder="Select the manager"
          name="manager"
          onChange={(val) => {
            setBeneficiaries({ ...beneficiaries, manager: val });
          }}
          value={beneficiaries.manager}
        />
      </Row>
      <Row className="mb-3">
        <div className="d-flex align-items-center">
          <span className="cursor" onClick={handleAdd}>
            <Plus className="ms-1" /> <span className="ps-2">Add another</span>
          </span>
        </div>
      </Row>
      {!hideContinue && (
        <CustomButton
          className="mb-4"
          onClick={handleSubmit}
          fullWidth={true}
          disabled={loading}
        >
          Continue
        </CustomButton>
      )}

      <div className="text-center mt-3 cursor" onClick={onSkipHandler}>
        <span>{'Skip for now'}</span>
      </div>

      {beneficiariesList.length > 0 && (
        <>
          <div className="py-4 ">
            <hr />
            <div className="pt-4">
              {beneficiariesList.map((data, i) => (
                <UserView data={data} key={i} />
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default Beneficiaries;
