import { AlertCircleIcon } from 'assets/icons';
import ConfirmDialog from 'components/ConfirmDialog';
import CustomButton from 'components/UI/CustomButton';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBalances } from 'redux/actions/BudgetsAction';
import { Modal } from 'react-bootstrap';
import { getAvailableBalance, groupSourceOptions } from 'utils/helper';

const LiquidateBudget = ({ isOpen, onCancel, onClick, isLoading }) => {
  const dispatch = useDispatch();
  const {
    getBalances: { data: balances, loading: loadingBalances },
  } = useSelector(({ budgets }) => budgets);

  const [selected, setSelected] = useState('');

  useEffect(() => {
    dispatch(getBalances());
  }, []);

  const [loadPage, setLoadPage] = useState(0);

  useEffect(() => {
    if (loadPage > 1) dispatch(getBalances({ page: loadPage }));
  }, [loadPage]);

  const [hasMore, setHasMore] = useState(false);
  const [budgetList, setBudgetList] = useState([]);

  useEffect(() => {
    if (balances?.balances?.length) {
      const available_balance = getAvailableBalance(balances?.balances);
      setBudgetList(() => [...available_balance]);
    }
  }, [balances?.balances]);

  async function loadOptions(search, _, { page }) {
    setLoadPage(page);

    let filteredOption = budgetList.filter((item) =>
      item?.name?.toLowerCase().includes(search?.toLowerCase()),
    );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  return (
    <Modal show={isOpen} centered dialogClassName="custom-dialog batch-payment-confirm">
      <ConfirmDialog onCancel={onCancel} style={{ width: 450 }}>
        <span className="w-100">
          <AlertCircleIcon />
        </span>

        <div className="mt-3 w-100 heading-confirm">
          <h5>Close this budget</h5>
          <h6 className="text-xs text-gray">
            Before closing this budget, make sure your balance is equal to zero and there
            are no pending transactions. <br />
            <br />
            Keep in mind that this action is not reversible. By deleting this budget, any
            cards, scheduled transfers or active transactions related to this budget will
            be canceled. <br />
            <br />
            Need to review past transactions? Access your history and budget statements at
            any time.
          </h6>
        </div>

        <div className="w-100 heading-confirm">
          <div className="mb-2 mt-1">
            <CustomSelectRadio
              label="Transfer remaining funds to"
              name="source"
              value={selected}
              placeholder="Select a source"
              onChange={(val) => setSelected(val)}
              // defaultValue={getSingleBudgetData?.source?.code && defaultValue}
              isLoading={loadingBalances}
              isDisabled={loadingBalances}
              loadOptions={loadOptions}
            />
          </div>
        </div>

        <div className="mt-4 d-flex w-100 gap-3">
          <CustomButton fullWidth className="border-btn text-sm" onClick={onCancel}>
            Cancel
          </CustomButton>
          <CustomButton
            fullWidth
            className="remove-btn text-sm"
            loading={isLoading}
            disabled={!selected?.value}
            onClick={() => onClick(selected?.value)}
          >
            Close this budget
          </CustomButton>
        </div>
      </ConfirmDialog>
    </Modal>
  );
};

export default LiquidateBudget;
