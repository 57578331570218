import { Checkbox } from 'antd';
import CustomInput from 'components/UI/CustomInput';
import CustomPhoneNumberInput from 'components/UI/CustomPhoneNumberInput';
import CustomSelect from 'components/UI/CustomSelect';
import FileUpload from 'components/UI/FileUpload';
import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import cs from 'classnames';
import { idType } from 'utils/helper';
import CustomDatePicker from 'components/UI/CustomDatePicker';
import { addYears, format } from 'date-fns';
import dayjs from 'dayjs';
const OwnerInfo = ({
  handleChangeBusinessDoc,
  businessDoc,
  setBusinessDoc,
  setUploadingFile,
  uploadingFile,
  handlePhoneNumberChange,
  businessStep,
  setDocuments,
  documents,
}) => {
  const [idName, setIdName] = useState('ID Number');
  const [disableIdNumber, setDisableIdNumber] = useState(true);
  const [marginTop, setMarginTop] = useState('0px');

  const showIdNumber = (idType) => {
    if (idType.value === 'ip') {
      setMarginTop('0px');
    } else {
      setMarginTop('0px');
    }
    if (idType.value === 'nin') {
      setIdName(`NIN`);
    } else {
      setIdName(`${idType.label} Number`);
    }
    setDisableIdNumber(false);
  };

  return (
    <div className={cs({ 'disabled-owners-input': businessStep === 1 })}>
      <div className="information">Owner&apos;s Information</div>
      <Row className="mb-3 align-items-center">
        <CustomInput
          label="Director's first name *"
          placeholder="Enter first name"
          type="text"
          name="firstName"
          onChange={handleChangeBusinessDoc}
          value={businessDoc?.firstName}
          md={6}
        />
        <CustomInput
          label="Director's last name"
          placeholder="Enter last name"
          type="text"
          name="lastName"
          onChange={handleChangeBusinessDoc}
          value={businessDoc.lastName}
          md={6}
        />
      </Row>
      <Row className="mb-3">
        <CustomInput
          label="Email address *"
          placeholder="Enter email"
          type="text"
          name="directorsEmail"
          onChange={handleChangeBusinessDoc}
          value={businessDoc.directorsEmail}
        />
      </Row>
      <Row className="mb-3">
        <CustomPhoneNumberInput
          label="Phone number *"
          placeholder="Enter number"
          onChange={(localFormat, international, countryCode) =>
            handlePhoneNumberChange(localFormat, international, countryCode)
          }
          value={businessDoc.internationalFormat}
        />
      </Row>
      <Row className="mb-3">
        <CustomDatePicker
          label="Date of birth *"
          onChange={(value) =>
            setBusinessDoc({
              ...businessDoc,
              dob: format(value, 'yyyy-MM-dd'),
            })
          }
          peekNextMonth
          maxDate={addYears(new Date(), -18)}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          placeholderText="Date of birth"
          name="dob"
          selected={businessDoc.dob && dayjs(businessDoc.dob).toDate()}
        />
      </Row>
      <Row className="mb-3">
        <CustomInput
          label="BVN *"
          placeholder="Enter director's BVN"
          type="text"
          name="bvn"
          onChange={handleChangeBusinessDoc}
          value={businessDoc?.bvn}
          isNumber={false}
          maxLength="11"
        />
      </Row>

      <Row className="mb-3">
        <CustomInput
          label="Percentage Owned *"
          placeholder="Enter percentage owned"
          type="text"
          name="directorPercentage"
          onChange={handleChangeBusinessDoc}
          value={businessDoc?.directorPercentage}
          isNumber={false}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
      </Row>

      <Row className="mb-3">
        <CustomSelect
          label="ID Type *"
          name="idType"
          placeholder="Select ID Type"
          onChange={(val) => {
            setBusinessDoc({ ...businessDoc, idType: val });
            showIdNumber(val);
          }}
          value={businessDoc?.idType}
          options={idType}
          marginTop={marginTop}
        />
      </Row>
      <Row className="mb-3">
        <CustomInput
          label={`${idName} *`}
          placeholder={'Enter ' + idName}
          type="text"
          name="idNumber"
          onChange={handleChangeBusinessDoc}
          value={businessDoc?.idNumber}
          // disabled={disableIdNumber}
        />
      </Row>
      <Row className="mb-3">
        <FileUpload
          label="Copy of ID *"
          placeholder="Upload copy of your ID"
          name="file"
          uploadedFile={documents.idCopy}
          onChange={({ assetCode }) => setDocuments({ ...documents, idCopy: assetCode })}
          setUploadingFile={setUploadingFile}
          uploadingFile={uploadingFile}
        />
      </Row>
      <Row className="mb-3">
        <p className="subbusiness-list text-gray">
          <Checkbox
            onChange={({ target: { checked } }) =>
              setBusinessDoc({ ...businessDoc, checked })
            }
          />
          <span className="ms-2 fs-6">
            I confirm all the information provided here are accurate{' '}
          </span>
        </p>
      </Row>
    </div>
  );
};

export default OwnerInfo;
