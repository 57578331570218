export const FILES_UPLOAD_REQUEST = 'FILES_UPLOAD_REQUEST';
export const FILES_UPLOAD_PROGRESS = 'FILES_UPLOAD_PROGRESS';
export const FILES_UPLOAD_SUCCESS = 'FILES_UPLOAD_SUCCESS';
export const FILES_UPLOAD_FAILURE = 'FILES_UPLOAD_FAILURE';
export const FILES_UPLOAD_CANCEL = 'FILES_UPLOAD_CANCEL';
export const FILES_PLOAD_CANCELLED = 'FILES_PLOAD_CANCELLED';
export const RETRY_FILES_UPLOAD = 'RETRY_FILES_UPLOAD';
export const REMOVE_FILES = 'REMOVE_FILES';
export const FILES_UPLOAD_COMPLETED = 'FILES_UPLOAD_COMPLETED';
export const RESET_FILES_UPLOAD_STATE = 'RESET_FILES_UPLOAD_STATE';

const initialState = {
  files: [],
  progress: {},
  error: {},
  status: {},
  assetData: [],
  completed: false,
};

const ProgressUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILES_UPLOAD_REQUEST:
      return {
        ...state,
        files: [...state.files, ...action.payload.files],
        progress: action.payload.files.reduce(
          (acc, file) => ({ ...acc, [file.id]: 0 }),
          state.progress,
        ),
        status: action.payload.files.reduce(
          (acc, file) => ({ ...acc, [file.id]: 'uploading' }),
          state.status,
        ),
      };

    case FILES_UPLOAD_PROGRESS:
      return {
        ...state,
        progress: {
          ...state.progress,
          [action.payload?.file?.id]: action.payload.progress,
        },
      };

    case FILES_UPLOAD_SUCCESS:
      return {
        ...state,
        progress: {
          ...state.progress,
          ...action.payload.reduce((acc, data) => ({ ...acc, [data.id]: 100 }), {}),
        },
        status: {
          ...state.status,
          ...action.payload.reduce((acc, data) => ({ ...acc, [data.id]: 'success' }), {}),
        },
        assetData: [...state.assetData, ...action.payload],
      };

    case FILES_UPLOAD_FAILURE:
      return {
        ...state,
        error: { ...state.error, [action.payload.file.id]: action.payload.error },
        status: { ...state.status, [action.payload.file.id]: 'failure' },
      };

    case FILES_PLOAD_CANCELLED:
      return {
        ...state,
        status: { ...state.status, [action.payload.file.id]: 'cancelled' },
      };

    case RETRY_FILES_UPLOAD:
      return {
        ...state,
        status: { ...state.status, [action.payload.file.id]: 'uploading' },
        progress: { ...state.progress, [action.payload.file.id]: 0 },
        error: { ...state.error, [action.payload.file.id]: undefined },
      };

    case REMOVE_FILES:
      const { id } = action.payload;
      const { [id]: removedFile, ...remainingFiles } = state.files;
      const { [id]: removedProgress, ...remainingProgress } = state.progress;
      const { [id]: removedError, ...remainingErrors } = state.error;
      const { [id]: removedStatus, ...remainingStatus } = state.status;

      return {
        ...state,
        files: state.files.filter((file) => file.id !== id),
        progress: remainingProgress,
        error: remainingErrors,
        status: remainingStatus,
      };

    case FILES_UPLOAD_COMPLETED:
      return {
        ...state,
        completed: true,
      };

    case RESET_FILES_UPLOAD_STATE:
      return initialState;

    default:
      return state;
  }
};

export default ProgressUploadReducer;
