import { CloseOutlined } from '@ant-design/icons';
import { ReactComponent as LeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as GrayBgCalender } from 'assets/icons/background-calender.svg';
import cs from 'classnames';
import { getHours, getMinutes, setHours, setMinutes } from 'date-fns';
import dayjs from 'dayjs';
import numeral from 'numeral';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrency, recurrencePaymentOption } from 'utils/helper';
import CustomButton from '../../../components/UI/CustomButton';
import CustomDatePicker from '../../../components/UI/CustomDatePicker';
import CustomInput from '../../../components/UI/CustomInput';
import CustomSelect from '../../../components/UI/CustomSelect';
import CustomSwitch from '../../../components/UI/CustomSwitch/inedx';
import CustomTextarea from '../../../components/UI/CustomTextarea';
import Loading from '../../../components/UI/Loading/index';
import Modal from '../../../components/UI/Modal';
import Saving from '../../../components/UI/ModalSaving/Saving';
import Success from '../../../components/UI/ModalSaving/Success';
import { toastError } from '../../../components/UI/toast';
import ItemsDetails from '../../../components/bujetiPayModal/ItemsDetails';
import useTextCounter from '../../../hooks/useTextCounter';
import { calculateFees } from '../../../redux/actions/FeesAction';
import {
  getScheduledTransactions,
  updateScheduledTransactions,
} from '../../../redux/actions/TransactionsAction';
import { MAXLENGTH } from '../../../utils';

const MAX_LENGTH = MAXLENGTH?.singlePaymentDescription;

const initailState = {
  currency: '',
  amount: '',
  description: undefined,
  startDate: undefined,
  repeat: '',
  endDate: undefined,
  schedule: false,
};

const EditScheduledTransactionModal = ({
  selectedOption,
  setSelectedOption,
  filter,
  setToggle,
  toggle,
}) => {
  const dispatch = useDispatch();

  const {
    calculateFees: { data: feesData, loading: isFeesLoading },
  } = useSelector(({ fees }) => fees);

  const {
    updateScheduledTransaction: { loading, success },
  } = useSelector(({ transaction }) => transaction);

  const [paymentData, setPaymentData] = useState(initailState);
  const [previousPaymentData, setPreviousPaymentData] = useState(initailState);
  const [isLoaded, setIsLoaded] = useState(false);
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (selectedOption) {
      const {
        currency,
        code,
        description,
        status,
        amount,
        start_date,
        expiry_date,
        frequency,
      } = selectedOption;

      const recurringFrequencyOption = recurrencePaymentOption(start_date);
      const transactionFrequency = recurrencePaymentOption(start_date)?.filter(
        (recurrenceOption) => recurrenceOption.value === frequency,
      );

      const initial = {
        code: code,
        currency: currency,
        amount: amount / 100,
        description: description,
        startDate: dayjs(start_date).toDate(),
        repeat:
          transactionFrequency.length > 0
            ? transactionFrequency[0]
            : recurringFrequencyOption[0],
        endDate: expiry_date ? dayjs(expiry_date).toDate() : undefined,
        reason: '',
        status: status,
        schedule: true,
      };
      setPaymentData(initial);
      setPreviousPaymentData(initial);
    }
  }, [selectedOption]);

  const handleDescriptionChange = (value) => {
    setPaymentData((prevPaymentData) => ({
      ...prevPaymentData,
      description: value,
    }));
  };

  const handleReasonChange = (value) => {
    setPaymentData((prevPaymentData) => ({
      ...prevPaymentData,
      reason: value,
    }));
  };

  const { text, charCount, handleCharChange } = useTextCounter(
    paymentData?.description,
    MAX_LENGTH,
    handleDescriptionChange,
  );

  const {
    text: reasonText,
    charCount: reasonCharCount,
    handleCharChange: reasonHandleCharChange,
  } = useTextCounter(paymentData?.reason, MAX_LENGTH, handleReasonChange);

  const currency = paymentData?.currency;

  //ends here

  //goback
  const goBack = () => {
    setStep(step - 1);
  };

  // Toggle action
  const payToggleHandler = () => {
    if (step === 2 && !isLoaded) return goBack();
    if (selectedOption) {
      setSelectedOption(null);
    }
    setToggle(false);
    setIsLoaded(false);
  };

  useEffect(() => {
    if (success) {
      setIsLoaded(true);
      setPaymentData(initailState);
      setPreviousPaymentData(initailState);
      dispatch(getScheduledTransactions({ ...filter }));
    }
  }, [success]);

  // handle input state changes
  const handleChange = ({ target: { name, value, validity, rawValue } }) => {
    if (name === 'accountNumber')
      return (
        validity.valid &&
        setPaymentData((prevPaymentData) => ({ ...prevPaymentData, [name]: value }))
      );
    return setPaymentData((prevPaymentData) => ({
      ...prevPaymentData,
      [name]: rawValue ? rawValue : value,
    }));
  };

  const isNotOneTime = paymentData.repeat && paymentData.repeat?.value !== 'one-time';
  // handle next step
  const handleNextStep = () => {
    if (!paymentData.amount) return toastError('Please enter an amount');
    if (+paymentData.amount <= 0) return toastError('Please enter a valid amount');

    if (paymentData.schedule) {
      if (!paymentData.startDate) return toastError('Please enter a start date');
      if (!paymentData.repeat) return toastError('Please select a repeat pattern');
      if (!paymentData.endDate && isNotOneTime)
        return toastError('Please enter a end date');

      if (paymentData.endDate) {
        const isEndDateBeforeStartDate = dayjs(paymentData.endDate).isBefore(
          dayjs(paymentData.startDate),
        );
        if (isEndDateBeforeStartDate)
          return toastError('Start date should be ahead of end date!');
      }
    }

    const data = {
      amount: paymentData?.amount * 100,
      currency: paymentData?.currency,
    };
    dispatch(calculateFees(data));
    setStep(2);
  };
  // handle submit
  const handleSubmit = () => {
    if (!paymentData.description) return toastError('Please enter a description');
    if (!paymentData.reason && paymentData.status === 'pause')
      return toastError('Please enter a reason');

    let payload;

    const isScheduleTransactionEdited =
      JSON.stringify(paymentData) === JSON.stringify(previousPaymentData);

    if (isScheduleTransactionEdited) {
      payload = {
        code: paymentData.code,
      };
    } else {
      let scheduleTransaction;
      const isScheduleTransactionObjSame =
        JSON.stringify(paymentData.startDate) ===
          JSON.stringify(previousPaymentData.startDate) &&
        JSON.stringify(paymentData.endDate) ===
          JSON.stringify(previousPaymentData.endDate) &&
        paymentData.repeat.value == previousPaymentData.repeat.value;
      if (!isScheduleTransactionObjSame) {
        const startDateObject = dayjs(paymentData.startDate);
        const endDateObject = dayjs(paymentData.endDate);

        const minutes = startDateObject.format('mm');
        const hours = startDateObject.format('HH');
        const dayOfMonth = startDateObject.format('DD');
        const month = startDateObject.format('MMMM').toLowerCase();
        const dayOfWeek = startDateObject.format('dddd').toLowerCase();
        const startDateFormatted = startDateObject.format('YYYY-MM-DD');
        const startTimestamp = startDateObject.format('HH:mm:ss');

        const endDateFormatted = endDateObject.format('YYYY-MM-DD');
        const endTimestamp = endDateObject.format('HH:mm:ss');

        scheduleTransaction = {
          schedule: isNotOneTime
            ? paymentData?.repeat?.value
            : {
                minutes,
                hours,
                dayOfMonth,
                month,
                dayOfWeek,
              },
          startDate: {
            date: startDateFormatted,
            timestamp: startTimestamp,
          },
          expiryDate: isNotOneTime
            ? {
                date: endDateFormatted,
                timestamp: endTimestamp,
              }
            : undefined,

          recurring: isNotOneTime,
        };
      }

      payload = {
        code: paymentData.code,
        amount:
          paymentData.amount !== previousPaymentData.amount
            ? Number(Number(+paymentData.amount * 100).toFixed(2))
            : undefined,
        description:
          paymentData.description !== previousPaymentData.description
            ? paymentData.description
            : undefined,
        reason:
          paymentData.status === 'pause' &&
          paymentData.reason !== previousPaymentData.reason
            ? paymentData.reason
            : undefined,
        status:
          paymentData.status !== previousPaymentData.status
            ? paymentData.status
            : undefined,
        scheduleTransaction: paymentData.schedule ? scheduleTransaction : undefined,
      };
    }
    dispatch(updateScheduledTransactions(payload));
  };
  //

  const formatOptionLabel = (props) => {
    const { label, details, isDisabled } = props;
    return (
      <div className={cs('format-recurrence-option')}>
        <h1 className={cs({ ['text-muted']: isDisabled })}>{label}</h1>
        <span className={cs({ ['text-muted']: isDisabled })}>{details}</span>
      </div>
    );
  };

  const handleDateChange = (date, name) => {
    const currentHours = getHours(new Date());
    const currentMinutes = getMinutes(new Date());
    const value = setMinutes(setHours(date, currentHours), currentMinutes + 5);

    if (!paymentData[name]) {
      handleChange({ target: { name: name, value } });
    } else {
      handleChange({ target: { name: name, value: date } });
    }
  };

  const budgetCard = () => (
    <div className="information-wrapper">
      <h2 className="card-title w-100">Edit scheduled transaction</h2>
      <div className="form-normal">
        <Row className="mb-3">
          <CustomInput
            label="How much are you paying?"
            isAmount
            placeholder="100,000.00"
            type="text"
            name="amount"
            className="amount"
            onChange={handleChange}
            existAction={paymentData.currency}
            editCurrency={false}
            value={paymentData.amount}
            getTypeVal={(val) => setPaymentData({ ...paymentData, currency: val })}
          />
        </Row>

        <Row className="mb-3 mx-0">
          <div className="payment__schedule-wrapper">
            <div className="d-flex justify-content-between align-items-start w-100">
              <div className="d-flex gap-3 align-items-center">
                <GrayBgCalender />
                <div className="schedule-label">
                  <h6>Schedule</h6>
                  <p>or set up recurring payment</p>
                </div>
              </div>
              <div>
                <CustomSwitch
                  onChange={(value) =>
                    handleChange({
                      target: { name: 'schedule', value: value },
                    })
                  }
                  disabled={true}
                  checked={paymentData?.schedule}
                ></CustomSwitch>
              </div>
            </div>
            {paymentData.schedule && (
              <div className="form-normal w-100 mx-0 px-0">
                <Row className="my-2 mx-0 px-0">
                  <CustomDatePicker
                    wrapperClass="px-0 mx-0"
                    label="Date"
                    onChange={(value) => handleDateChange(value, 'startDate')}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    showTimeSelect
                    timeCaption="Time"
                    name="startDate"
                    peekNextMonth
                    minDate={new Date()}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Start date"
                    selected={
                      paymentData.startDate && dayjs(paymentData.startDate).toDate()
                    }
                  />
                </Row>

                <Row className="my-2 mx-0 px-0">
                  <CustomSelect
                    label="Repeats"
                    name="repeat"
                    placeholder="Repeats"
                    isDisabled={!paymentData.startDate}
                    options={recurrencePaymentOption(paymentData.startDate)}
                    formatOptionLabel={formatOptionLabel}
                    onChange={(repeat) => {
                      setPaymentData({ ...paymentData, repeat });
                    }}
                    value={paymentData.repeat}
                    wrapperClass="px-0 mx-0"
                  />
                </Row>
                {isNotOneTime && (
                  <Row className="my-3 mx-0">
                    <CustomDatePicker
                      label="End date"
                      onChange={(value) => handleDateChange(value, 'endDate')}
                      disabled={paymentData.repeat === 'one-time'}
                      timeInputLabel="Time:"
                      dateFormat="MMMM d, yyyy h:mm aa"
                      showTimeSelect
                      timeCaption="Time"
                      name="endDate"
                      peekNextMonth
                      minDate={
                        paymentData.startDate
                          ? dayjs(paymentData.startDate).toDate()
                          : new Date()
                      }
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="End date"
                      selected={
                        paymentData.endDate && dayjs(paymentData.endDate).toDate()
                      }
                    />
                  </Row>
                )}
              </div>
            )}
          </div>
        </Row>

        <CustomButton onClick={handleNextStep} fullWidth={true} disabled={loading}>
          Review details
        </CustomButton>
      </div>
    </div>
  );

  const PreviewCard = () => {
    return (
      <div className="information-wrapper">
        <h2 className="card-title w-100 mb-4">Review your details</h2>
        <div className="form-normal">
          <div className="preview-card__transfer mb-3">
            <div className="px-4 pt-1 pb-2 w-100">
              <ItemsDetails
                className="mb-1"
                title={<span className="header__transfer">Transfer details</span>}
                value={
                  <span
                    className="edit-action__transfer cursor"
                    onClick={payToggleHandler}
                  >
                    Edit
                  </span>
                }
              />

              <ItemsDetails
                title="Total amount we'll send"
                value={`${getCurrency(currency)}${numeral(paymentData.amount).format(
                  '0,0.00',
                )}`}
              />
              {isFeesLoading && <Loading color="#D28B28" size={18} />}
              {!isFeesLoading && feesData && (
                <>
                  <ItemsDetails
                    title="Transaction fees"
                    value={
                      <CurrencyFormat
                        prefix={!feesData.fee ? '' : getCurrency(currency)}
                        value={!feesData.fee ? 'Free' : feesData.fee / 100}
                        displayType="text"
                        isNumericString
                        thousandSeparator={true}
                      />
                    }
                  />

                  {!feesData.fee ? null : (
                    <ItemsDetails
                      className="mt-1"
                      title="Amount"
                      value={
                        <span className="fs-5 text-dark">
                          <CurrencyFormat
                            prefix={getCurrency(currency)}
                            value={Number(
                              feesData.fee / 100 + Number(paymentData.amount),
                            ).toFixed(2)}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </span>
                      }
                    />
                  )}
                </>
              )}

              {paymentData.schedule && (
                <>
                  <hr />
                  <ItemsDetails
                    className="mb-1"
                    title={<span className="header__transfer">Schedule details</span>}
                    value={
                      <span
                        className="edit-action__transfer cursor"
                        onClick={payToggleHandler}
                      >
                        Edit
                      </span>
                    }
                  />
                  <ItemsDetails
                    title="Date"
                    value={dayjs(paymentData.startDate).format('MMMM DD, YYYY, HH:mm:ss')}
                  />
                  <ItemsDetails title="Repeats" value={paymentData.repeat.details} />
                  {isNotOneTime && (
                    <ItemsDetails
                      title="End date"
                      value={dayjs(paymentData.endDate).format('MMMM DD, YYYY, HH:mm:ss')}
                    />
                  )}
                </>
              )}
              <hr />
            </div>
          </div>

          <div>
            <Row className="mb-3">
              <CustomTextarea
                rowSize={3}
                label="Description *"
                name="description"
                onChange={handleCharChange}
                value={text || paymentData.description}
                placeholder="Description"
                maxLength={MAX_LENGTH}
                showCounter={true}
                charCount={charCount}
              />
            </Row>
            {paymentData?.status === 'pause' && (
              <Row className="mb-3">
                <CustomTextarea
                  rowSize={3}
                  label="Reason for the change *"
                  name="reason"
                  onChange={reasonHandleCharChange}
                  value={reasonText || paymentData.reason}
                  placeholder="Reason"
                  maxLength={MAX_LENGTH}
                  showCounter={true}
                  charCount={reasonCharCount}
                />
              </Row>
            )}
          </div>

          <CustomButton onClick={handleSubmit} fullWidth={true}>
            Confirm and update
          </CustomButton>
          <CustomButton withoutBg fullWidth className="mt-3" onClick={() => setStep(1)}>
            Cancel
          </CustomButton>
        </div>
      </div>
    );
  };

  const PageView = () => {
    if (step > 1) return PreviewCard();
    return budgetCard();
  };

  const msg = 'Your scheduled transaction has been updated successfully';
  return (
    <>
      <Modal show={toggle}>
        <div className="content">
          <div className="card-modal-header">
            <div className="d-flex align-items-center cursor" onClick={payToggleHandler}>
              {step === 1 || isLoaded ? (
                <CloseOutlined />
              ) : (
                <LeftIcon className="cursor" onClick={payToggleHandler} />
              )}
              <span className="ps-1">{step === 1 || isLoaded ? 'Close' : 'Back'}</span>
            </div>
          </div>
          <div className="card-modal-body">
            {loading ? (
              <Saving />
            ) : isLoaded ? (
              <Success title="Thank you!" message={msg} />
            ) : (
              PageView()
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditScheduledTransactionModal;
